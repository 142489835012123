<template>
  <div class="d-phone-input" :class="classList">
    <div class="d-phone-input__inner">
      <d-select-phone :mod="mod" class="d-phone-input__code" v-if="codeList" :data-options="codeList" v-model="currentCode" @update:modelValue="updateCode"/>
      <input ref="tel"
             type="tel"
             v-model="currentPhone"
             name="phone"
             placeholder=""
             class="d-input__field _tel"
             inputmode="numeric"
             v-maska
             :data-maska="currentMask"
             @mouseover="hoverPhone"
             @mouseout="unHoverPhone"
             @input="setMask($event)"
             @focus="setMask($event)"
             @blur="setMask($event)"
      >
    </div>
  </div>
</template>

<script>
import DSelectPhone from "@components/molecules/DSelectPhone.vue";
import {maskList} from "@utils/maskList";
import { vMaska } from "maska"
import additionalClassCSS from '@utils/additionalClassCSS';
export default {
  name: "DPhoneInput",
  components: {
    DSelectPhone
  },
  directives: { maska: vMaska },
  props: {
    reset: Boolean,
    mod: {
      type: [Array, String],
      default: '',
    },
    error: {
      type: Boolean,
      default: false
    },
    shortMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentCode: window.dStrings && window.dStrings.phoneCode ? window.dStrings.phoneCode: '+7',
      currentMask: null,
      currentPhone: ''
    }
  },
  watch: {
    reset(value) {
      if (value) {
        this.currentPhone = ''
        this.$emit('changePhone', this.fullPhone)
      }
    }
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    errorClass() {
      return additionalClassCSS(this.error ? 'error' : '');
    },
    classList() {
      return Object.assign({}, this.modClass, this.errorClass);
    },
    codeList() {
      const result = [];
      maskList.forEach((item) => {
        result.push({
          "id": item.code,
          "value": item.code,
          "name": this.shortMode ? `${item.slug.toUpperCase()}`: `${item.name} (${item.slug.toUpperCase()})`,
          "slug": item.slug.toLowerCase()
        })
      })
      return result
    },
    maxLengthInput() {
      if (this.currentMask) return this.currentMask.length
      return null
    },
    fullPhone() {
      return `${this.currentCode} ${this.currentPhone.slice(0, this.maxLengthInput)}`
    },
  },
  mounted() {
    this.updateCode()
  },
  methods: {
    setMask(e) {
      this.$emit('changePhone', this.fullPhone)

      if (e.type === 'blur') {
        this.$emit('blur')
      }
    },
    updateCode() {
      this.currentPhone = ''
      const currentItem = maskList.find((item) => item.code === this.currentCode)

      if (currentItem) this.currentMask = currentItem.mask
      else this.currentMask = null;

      this.$emit('changeMask', {
        mask: this.currentMask,
        code: this.currentCode
      })
      this.$emit('changePhone', this.fullPhone)
    },
    hoverPhone(e) {
      let value = e.target.value;
      const placeholder = this.currentMask.replaceAll('#', '_')
      setTimeout(() => {
        if (!value) {
          e.target.placeholder = placeholder;
        }
        else {
          e.target.placeholder = '';
        }
      }, 0);
    },
    unHoverPhone(e) {
      e.target.placeholder = '';
    }
  }
}
</script>

<style scoped>

</style>
