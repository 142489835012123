<template>
  <div class="d-commercial-purposes">
    <template v-if="isTablet">
      <div v-for="item in data" :key="item.pk" class="d-checkbox _full">
        <input class="d-checkbox__input"
               type="checkbox" name="furnish"
               :id="`filter-project-${item.pk}-id${id}`"
               v-model="selectedPurpose"
               :value="item.pk"
               @change="sendData('projects')"
        >
        <label class="d-checkbox__label" :for="`filter-project-${item.pk}-id${id}`">
          {{ item.filter_title }}
          <svg v-if="itemIsActive(item.pk)" class="d-checkbox__close">
            <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-close'></use>
          </svg>
        </label>
      </div>
    </template>
    <template v-else>
      <div v-for="purpose in data" :key="purpose.pk" class="d-commercial-purposes__item">
        <div class="d-checkbox">
          <input
              class="d-checkbox__input"
              type="checkbox" name="furnish"
              :id="`filter-purpose${purpose.pk}-id${id}`"
              v-model="selectedPurpose"
              :value="purpose.pk"
              @change="sendData"
          >
          <label class="d-checkbox__label" :for="`filter-purpose${purpose.pk}-id${id}`">{{ purpose.filter_title }}</label>
        </div>
        <div class="d-commercial-purposes__count">{{ purpose.count }}</div>
      </div>
    </template>

  </div>

</template>

<script>
import { intersection as _intersection, uniqueId as _uniqueId } from "lodash";
export default {
  name: "DCommercialPurposes",
  props: {
    data: {
      type: Array,
      default: () => {},
      require: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      id: null,
      selectedPurpose: [],
      isTablet: false
    }
  },
  watch: {
    modelValue(data) {
      this.selectedPurpose = _intersection(data, this.selectedPurpose)
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.id = _uniqueId()
    if (this.modelValue.length) {
      this.selectedPurpose = this.modelValue;
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    sendData() {
      this.$emit('update:modelValue', this.selectedPurpose)
    },
    itemIsActive(pk) {
      return this.selectedPurpose.includes(pk)
    },
    checkScreenSize() {
      this.isTablet = window.matchMedia("(max-width: 1279px)").matches && window.matchMedia("(pointer:coarse)").matches;
    },
  }
}
</script>

<style scoped>

</style>