<template>
  <div class="d-disclaimer" v-html="text"></div>
</template>

<script>
export default {
  name: 'DDisclaimer',
  props: {
    text: {
      type: String,
      default: ''
    }
  }

};
</script>

<style scoped></style>