<template>
  <div>
    <div class="d-map-infra d-object-single-location__map">
      <d-map-infra-legend v-if="categories.length" :visible-types="uniqTypePoint > 1" :data="categories" @changeCategory="changeCategory" mod="desktop"/>
      <div v-if="uniqTypePoint > 1" class="d-object-single-location-types _tablet">
        <div class="d-object-single-location-types__item _outside">Внешняя</div>
        <div class="d-object-single-location-types__item _inside">Внутренняя</div>
      </div>
      <div class="d-object-single-location-map" >
<!--        <div class="d-object-single-location-map__lock" :class="{ _show: lockMap }"></div>-->
        <div ref="infraMap" class="d-object-single-location-map__map"></div>
        <d-map-zoom-buttons @changeZoom="zoomMap"/>
        <div class="d-object-single-location-map__buttons">
          <button @click="toggleLock" class="d-object-single-location-map__button d-button _white _withImg _left _lock">
            <template v-if="lockMap">
              <svg>
                <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-lock'></use>
              </svg>
              <span>Разблокировать карту</span>
            </template>
            <template v-else>
              <svg>
                <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-unlock'></use>
              </svg>
              <span>Заблокировать карту</span>
            </template>

          </button>
          <button class="d-object-single-location-map__button d-button _blue _withImg _left _filter" @click="showFilterMobile = true">
            <img src="/assets/blueant/assets/img/d/icon_filter.svg"/>
            <span>Фильтры</span>
            <span class="mobile">Фильтр</span>
            <div class="d-object-single-location-map__count">3</div>
          </button>
        </div>
        <div v-if="!swipeInfoHide" class="d-object-single-location-map__note">
          <div class="d-swipe-info"><img src="/assets/blueant/assets/img/d/icon_swipe2.svg"/>
            <div>Используйте жесты для прокрутки и масштабирования карты</div>
            <button @click="closeSwipeInfo"><img src="/assets/blueant/assets/img/d/icon_close_pen.svg"/></button>
          </div>
        </div>
        <button class="d-object-single-location-map__close d-button _blue _close"><img src="/assets/blueant/assets/img/d/icon_close.svg"/></button>
      </div>
      <d-popup-simple v-model="showFilterMobile" :with-close="false">
        <d-map-infra-legend v-if="categories.length" :data="categories" @changeCategory="changeCategory" mod="mobile" @close="showFilterMobile = false"/>
      </d-popup-simple>
    </div>
    <div class="d-object-single-location__mobileButton">
      <button class="d-button _white _withImg" @click="showMobileMap = true">
        <img src="/assets/blueant/assets/img/d/icon_pin.svg"/>
        <span>Смотреть на карте</span>
      </button>
    </div>
    <d-popup-simple v-model="showMobileMap">
      <d-map-infra-mobile v-if="infraData && showMobileMap" :infra-data="infraData" :point-project="pointProject" :categories="categories"/>
    </d-popup-simple>
  </div>
</template>

<script>
import {toRaw} from "vue";
import DMapZoomButtons from "../atoms/DMapZoomButtons.vue";
import DMapInfraLegend from "../molecules/DMapInfraLegend.vue";
import DPopupSimple from "../molecules/DPopupSimple.vue";
import DMapInfraMobile from "../molecules/DMapInfraMobile.vue";
import Cookies from "js-cookie";
import { uniqBy as  _uniqBy } from "lodash";

export default {
  name: "DMapInfra",
  components: {
    DMapZoomButtons,
    DMapInfraLegend,
    DPopupSimple,
    DMapInfraMobile
  },
  props: {
    infraApiUrl: {
      type: String,
      default: '',
    },
    pointProjectData: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      mapInstance: null,
      points: [],
      infraData: null,
      categories: [],
      filteredPoints: [],
      lockMap: true,
      showFilterMobile: false,
      showMobileMap: false,
      swipeInfoHide: null,
      uniqTypePoint: null
    }
  },
  computed: {
    pointProject() {
      return JSON.parse(this.pointProjectData)
    },
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    }
  },
  created() {
    if (this.infraApiUrl) {
      this.getInfraData()
    }
  },

  mounted() {
    this.$nextTick(() => {
      console.log('init')
      // ymaps.ready(this.mapInit);
    })
    this.swipeInfoHide = Number(Cookies.get('closeSwipeInfraTablet')) === 1
  },
  methods: {
    async getInfraData() {
      try {
        let response = await this.axios.get(this.infraApiUrl);
        this.infraData = response.data
        // this.setPoints(this.infraData)
        this.categories = this.setCategories(this.infraData)

        this.uniqTypePoint = _uniqBy(this.setPoints(this.infraData), 'type').length

        ymaps.ready(this.mapInit);

        console.log('get data')
      } catch(err) {
        console.log('error load infra map points', err);
      }
    },
    setPoints(data) {
      const result = []
      data.forEach((category) => {
        category.points.forEach((point) => {
          result.push(Object.assign({}, point, { icon: category.icon }))
        })
      })
      return result
    },
    setCategories(data) {
      const result = []
      data.forEach((category) => {
        result.push({
          id: category.id,
          title: category.title,
          label: category.label,
          icon: category.icon,
          count: category.points.length,
          init: category.init
        })
      })
      return result
    },
    mapInit() {
      this.mapInstance = new ymaps.Map(this.$refs.infraMap, {
        center: [55.769761, 37.562889],
        zoom: 17,
        controls: []
      })

      const rawMap = toRaw(this.mapInstance)

      if (this.lockMap) {
        rawMap.behaviors.disable('scrollZoom');
        rawMap.behaviors.disable('drag');
      } else {
        rawMap.behaviors.enable('scrollZoom');
        rawMap.behaviors.enable('drag');
      }

      this.addMarkers()
    },
    createMarker(point) {
      const coords = Object.values(point.coords);

      let marker_html;

      const html = `
        <div class="d-map-infra-point ${point.type === 'inside' ? '_inside' : ''}">
            <div class="d-map-infra-point__img">${point.icon}</div>
            <div class="d-map-infra-point__title">
                ${point.title}
                <button class="d-map-infra-point__close">
                    <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" alt="">
                </button>
            </div>

        </div>
      `

      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-infra-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-infra-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });

      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [48, 48],
            iconImageOffset: [-24, -48],
            zIndex: 730,
            zIndexActive:  731,
            zIndexHover: 731,
            iconPane: 'overlaps',
          });

      marker.events
          .add('mouseenter', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            const $inner_el = $(marker_html);
            $inner_el.removeClass('_hover');
          })
          .add('click', (e) => {
            if (this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.toggleClass('_active');
              if ($inner_el.hasClass('_active')) {
                e.get('target').options.set('zIndex', 733)
              } else {
                e.get('target').options.set('zIndex', 731)
              }
            }
          })

      return marker
    },
    createMarkerProject(point) {
      const coords = Object.values(point.coords)
      let marker_html;

      const html = `
        <div class="d-map-point _object">
            <div class="d-map-point__img">
                <img src="${point.img}" alt="">
                <div class="d-map-point-info">
                <div class="d-map-point-info__name">${point.name}</div>
                <div class="d-map-point-info__text">${point.text}</div>
                <div class="d-map-point-info__price">${point.price}</div>
              </div>
              </div>
        </div>
      `
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });
      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [8, 8],
            iconImageOffset: [-4, -8],
            zIndex: 730,
            zIndexActive:  1300,
            zIndexHover: 731,
            iconPane: 'overlaps'
          });

      marker.events
          .add('mouseenter', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            const $inner_el = $(marker_html);
            $inner_el.removeClass('_hover');
          })
          .add('click', () => {
            if (this.isTouchDevice) {
              const $inner_el = $(marker_html);

              $('.d-map-point').removeClass('_active')
              this.showMarkerInfoMobile = true

              $inner_el.addClass('_active').removeClass('_hover');

              for (const p in this.markerInfoMobileData) {
                this.markerInfoMobileData[p] = point[p]
              }
            }
          })
      return marker
    },
    addMarkers(setBounds = true) {
      const placemarks = []
      const clusterer = new ymaps.Clusterer({
        clusterIcons: [
          {
            size: [32, 32],
            offset: [-32, -32],
          },
        ],
        clusterBalloonContentLayout: null,
        groupByCoordinates: false,
        clusterDisableClickZoom: false,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
            '{% if properties.geoObjects.length> 9 %}' +
            '<div class="d-map-infra-cluster">9+</div>' +
            '{% else %}' +
            '<div class="d-map-infra-cluster">{{ properties.geoObjects.length }}</div>' +
            '{% endif %}'
        ),
      });

      clusterer.options.set({
        gridSize: 170,
        clusterDisableClickZoom: false,
        zIndex: 1200,
      });

      const rawMap = toRaw(this.mapInstance)

      if (this.infraData) {
        this.points.forEach((point) => {
          placemarks.push(this.createMarker(point))
        })

        clusterer.add(placemarks);

        rawMap.geoObjects
            .add(clusterer);

        if (setBounds) {
          rawMap.setBounds(rawMap.geoObjects.getBounds(), {
            zoomMargin: 50
          });
        }

      }
      rawMap.geoObjects.add(this.createMarkerProject(this.pointProject))
      const currentZoom = rawMap.getZoom();
      if(currentZoom > 17) rawMap.setZoom(17)
    },
    zoomMap(value) {
      const rawMap = toRaw(this.mapInstance)
      const currentZoom = rawMap.getZoom()
      rawMap.setZoom(currentZoom + value)
    },
    changeCategory(categories) {
      this.filterPoints(categories)
    },
    filterPoints(categories) {
      this.filteredPoints = categories.length ? this.infraData.filter((category) => categories.includes(category.label)) : this.infraData
      this.points = this.setPoints(this.filteredPoints)

      const rawMap = toRaw(this.mapInstance)

      if (rawMap) {
        rawMap.geoObjects
            .removeAll()
        this.addMarkers(false)
      }
    },
    toggleLock() {
      this.lockMap = !this.lockMap
      const rawMap = toRaw(this.mapInstance)
      if (this.lockMap) {
        rawMap.behaviors.disable('scrollZoom');
        rawMap.behaviors.disable('drag');
      } else {
        rawMap.behaviors.enable('scrollZoom');
        rawMap.behaviors.enable('drag');
      }
    },
    closeSwipeInfo() {
      Cookies.set('closeSwipeInfraTablet', 1)
      this.swipeInfoHide = true
    }
  }
}
</script>

<style scoped>

</style>
