<template>
  <div v-if="formData" class="d-input-file" :class="{ _error: error }">
    <div class="d-input-file__inner">
      <input ref="files" class="d-input-file__input" type="file" name="cv" id="cv" @change="getFiles" >
      <label class="d-input-file__button" :class="{ _hide: uploadedFiles, _error: error}" for="cv">
        Файл резюме
        <div class="d-input-file__icon">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-clip"></use>
          </svg>
        </div>
      </label>
      <div class="d-input-file__name" :class="{ _show: uploadedFiles }">
        <template v-if="uploadedFiles">
          {{ uploadedFiles.name }}
          <button type="button" class="d-input-file__icon" @click="removeFile">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-trash"></use>
            </svg>
          </button>
        </template>
      </div>
      <div v-if="error" class="d-input__error">{{ error }}</div>
      <div v-if="uploadError" class="d-input__error">{{ uploadError.error_txt }}</div>
    </div>

    <div class="d-input-file__info">Файл {{ formData.extensions }} размером до {{ formData.size }} Мб</div>
  </div>
</template>

<script>
export default {
  name: "DInputFile",
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    error: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    dataUploadApiUrl: {
      type: String,
      default: 'http://api.donstroy-a3.dev.blue-ant.ru/ajax/upload/'
    }
  },
  data() {
    return {
      uploadedFiles: null,
      rawFile: null,
      cancelToken: undefined,
      uploadError: null
    };
  },
  watch: {
    file(value) {
      if (!value) this.removeFile()
      console.log('rawFile', this.rawFile)
      console.log('uploadedFiles', this.uploadedFiles)
    }
  },
  computed: {
  },
  methods: {
    getFiles() {
      this.rawFile = this.$refs.files.files[0];
      console.log('fileList', this.rawFile)
      this.sendFile()
    },
    addFile(fileObj) {
      this.$refs.files.value = '';
      this.uploadedFiles = fileObj;
      this.$emit('update:file', this.uploadedFiles)
    },
    sendFile() {
      this.$emit('loading', true)
      this.uploadError = null
      const formData = new FormData();

      formData.append('file', this.rawFile, this.rawFile.name);
      this.axios
          .post(this.dataUploadApiUrl, formData)
          .then((response) => {
            if (response.data.file.error) {
              this.uploadError = response.data.file;
            } else {
              console.log('response file', response)
              const fileObj = response.data.file
              this.addFile(fileObj)
            }
            this.$emit('loading', false)
          })
          .catch((_) => {
            this.uploadError = {
              error: 1,
              error_txt: 'Ошибка при загрузке файла'
            };
            this.$emit('loading', false)
          });
    },
    removeFile() {
      this.rawFile = null;
      this.uploadedFiles = null;
      this.$emit('update:file', null)
    },
  }
}
</script>

<style scoped>

</style>