<template>
  <div class="d-visual-quarters">
    <d-visual-header v-if="headerDataConvertToObject" :chess-legend="chessLegendData"  :data="headerDataConvertToObject" @changeType="changeType"/>
    <div v-if="type === '3d'"  class="d-visual-quarters__slider"  ref="genplan">
      <button v-if="prevSlideText" ref="prev" @click="navSlide('prev')" class="d-visual-quarters__section-btn _prev">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
        <span>{{ prevSlideText }}</span>
      </button>
      <button v-if="nextSlideText" ref="next" @click="navSlide('next')" class="d-visual-quarters__section-btn _next">
        <span>{{ nextSlideText }}</span>
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
      </button>
      <swiper
          v-if="genplanData3d"
          ref="slider"
          v-bind="swiperOpts"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          @afterInit="onSlideChange"
      >
        <swiper-slide v-for="section in genplanData3d.sections" :data-section-title="section.title" :key="section.id" class="swiper-slide">
          <div class="d-visual-genplan _floors" :style="`${heightPlan}`">
            <d-disclaimer v-if="disclaimer" class="d-visual-genplan__disclaimer" :text="disclaimer"/>
            <img v-if="section" :src="section.render.compass" class="d-visual-genplan__compass"/>
            <d-fitblock :render="section.render" auto-scroll>
              <template #masks>
                <svg class="d-visual-genplan__svg" xmlns="http://www.w3.org/2000/svg" :width="section.render.width" :height="section.render.height" :viewBox="`0 0 ${section.render.width} ${section.render.height}`">
                  <template v-for="label in section.labels" :key="label.id">
                    <polygon v-if="label.isPolygon" :ref="setItemRef" :data-tooltip="JSON.stringify(label)"  class="d-visual-genplan__path" :class="{_premium: label.premium, _hover:hoverLabelId === label.id }"  :points="label.points" @click="openPopup(label)"/>
                    <path v-else :ref="setItemRef" :data-tooltip="JSON.stringify(label)"  class="d-visual-genplan__path" :class="{_premium: label.premium, _hover:hoverLabelId === label.id }"  :d="label.points" @click="openPopup(label)"/>
                  </template>
                </svg>
              </template>
            </d-fitblock>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="type === '2d'" >
      <d-chess v-if="chessData" :data="chessData"></d-chess>
    </div>
    <d-popup-simple v-if="quarterMobileInfo" v-model="quarterMobilePopupShow" mod="center">

    </d-popup-simple>
  </div>
</template>

<script>
import DVisualHeader from "@components/molecules/DVisualHeader.vue";
import DFitblock from "@components/molecules/DFitblock.vue";
import tippy from "tippy.js";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/vue';
import { Navigation } from 'swiper';
import {ref} from "vue";
import setPlanHeight from "@utils/setPlanHeight";
import DChess from "@components/organisms/DChess.vue";
import DDisclaimer from "@components/atoms/DDisclaimer.vue";
export default {
  name: "DVisualFloors",
  components: {
    DVisualHeader,
    DFitblock,
    DPopupSimple,
    Swiper,
    SwiperSlide,
    DChess,
    DDisclaimer
  },
  props: {
    headerData: {
      type: String,
      default: ''
    },
    data3d: {
      type: String,
      default: '',
    },
    dataChess: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      type: null,
      genplanData3d: null,
      hoverLabelId: null,
      itemRefs: [],
      quarterMobileInfo: null,
      quarterMobilePopupShow: false,
      choose2dLink: null,
      chooseParamsLink: null,
      swiperOpts: {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
      },
      nextSlideText: null,
      prevSlideText: null,
      swiper: null,
      heightPlan: null,
      chessData: null,
      chessLegendData: null,
      disclaimer: null
    }
  },
  computed: {
    headerDataConvertToObject() {
      return JSON.parse(this.headerData)
    },
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    // premiumAvailable() {
    //   if (!this.labelsData3d) {
    //     return null
    //   }
    //   return this.labelsData3d.find((label) => {
    //     return label.premium
    //   })
    // },
    screenXS() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
    sections() {
      if (this.genplanData3d) {
        return this.genplanData3d.section
      } else {
        return null
      }
    }
  },
  created() {
    this.getData3d()
    this.getDataChess()
  },
  beforeUpdate() {
    this.itemRefs = []
  },
  mounted() {
    this.$nextTick(() => {
      this.heightPlan = setPlanHeight(this.$refs.genplan)
      window.addEventListener("resize", this.setHeightPlan);
    })
  },
  unmounted() {
    window.removeEventListener("resize", this.setHeightPlan);
  },
  updated() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item)
    })
  },
  methods: {
    async getData3d() {
      try {
        let response = await this.axios.get(this.data3d);
        console.log('response genplan buldings', response.data)
        this.genplanData3d = response.data
        this.chooseParamsLink = response.data.info.choose_params_link;
        this.choose2dLink = response.data.info.choose_2d_link;
        this.disclaimer = response.data.info.disclaimer ? response.data.info.disclaimer : null;
      } catch(err) {
        console.log('error load genplan floors data', err);
      }
    },
    async getDataChess() {
      try {
        let response = await this.axios.get(this.dataChess);
        console.log('response chess', response.data)
        this.chessData = response.data.sections
        this.chessLegendData = response.data.legend
      } catch(err) {
        console.log('error load genplan floors data', err);
      }
    },
    changeType(value) {
      this.type = value
      console.log('chessdata', this.chessData)
      if (!this.dataChess) {
        if (value === '2d' && this.screenXS) window.location.href = this.chooseParamsLink;
        else if (value === '2d')   window.location.href = this.choose2dLink;
      }

    },

    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },
    createTooltip(target) {
      const data = JSON.parse(target.dataset.tooltip)
      let tplFlats = ``

      data.flats.forEach((flat) => {
        tplFlats += `
          <div class="d-visual-building-info__param _flat">
            <div class="d-visual-building-info__name">${ flat.rooms === 0 ? 'Студии' : `${ flat.rooms }-комн. квартиры` } </div>` +
          (flat.price_request && flat.price_request_label
           ? `<div class="d-visual-building-info__value">${ flat.price_request_label }</div>`
           : `<div class="d-visual-building-info__value">от ${ flat.priceMin } млн ₽ </div>`) +
          `</div>
        `
      })

      const tpl = `
        <div class="d-visual-building-info">
          <div class="d-visual-building-info__title">Этаж ${data.floorNum}</div>
          <div class="d-visual-building-info__content">
            <div class="d-visual-building-info__flats"> ${tplFlats} </div>
          </div>
        </div>
      `

      tippy(target, {
        // trigger: 'click',
        allowHTML: true,
        // interactive: true,
        appendTo: () => this.$refs.genplan,
        offset: [0, 8],
        touch: false,
        interactiveDebounce: 10,
        theme: 'visual-building',
        placement: 'left-end',
        animation: 'fade',
        content: tpl,
        arrow: false,
      });
    },
    openPopup(data) {
      if (this.isTouchDevice) {
        this.quarterMobileInfo = data
        this.quarterMobilePopupShow = true
      } else {
        window.location.href = data.url
      }

    },
    onSlideChange(swiper) {
      const indexSlide = swiper.realIndex
      const nextSlide = swiper.slides[indexSlide + 1]
      const prevSlide = swiper.slides[indexSlide - 1]
      this.nextSlideText = nextSlide ? nextSlide.dataset.sectionTitle : null
      this.prevSlideText = prevSlide ? prevSlide.dataset.sectionTitle : null
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    navSlide(dir) {
      dir === 'next' ? this.swiper.slideNext() : this.swiper.slidePrev();
    },
    setHeightPlan() {
      this.heightPlan = setPlanHeight(this.$refs.genplan)
    }
  }

}
</script>

<style scoped>

</style>
