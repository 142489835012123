const tabsWidthStart = window.tabsWidthStart = global.tabsWidthStart = function() {
    $('.d-tabs__buttons').each(function(i, el) {
        if($(el).find('.d-tabs__button._active').length) $(el).find('.d-tabs__buttonBg').css({
            'left': $(el).find('.d-tabs__button._active').position().left,
            'width': $(el).find('.d-tabs__button._active').innerWidth(),
        });
    });
};
$(window).on('load', () => tabsWidthStart());
$(window).on('resize', () => tabsWidthStart());
$(document).ready(() => tabsWidthStart());

$(document).on('click', '.d-tabs__buttons .d-tabs__button:not(._click-off)', function(e) {
    e.preventDefault();
    $(this)
      .addClass('_active')
      .siblings()
      .removeClass('_active')
      .closest('.d-tabs')
      .find('.d-tabs__content:not(._single)')
      .removeClass('_active')
      .eq($(this).index())
      .addClass('_active');

    $(this).parent().find('.d-tabs__buttonBg').css({
        'left': $(this).position().left,
        'width': $(this).innerWidth(),
    });
});
$(document).on('click', '.d-tabs__buttons .d-tabs__button', function(e) {

    if ($(this).hasClass('_scroll')) {
        const container = $(this).parent().parent();
        const inner = $(this).parent();
        if (container.innerWidth() < inner.outerWidth()) {
            container.get(0).scrollLeft =
                ($(this).position().left + $(this).outerWidth() / 2) - container.innerWidth() / 2 + inner.get(0).offsetLeft
        }
    }
});
