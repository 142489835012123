<template>
  <div class="d-select-phone" :class="Object.assign({},{_open: isOpen}, modClass)" v-click-outside="onClickOutside">
    <div class="d-select-phone__head" :class="singleSelectedEmpty ? '' : '_active'" @click="toggleSelect">
      <span>{{ singleSelectedEmpty ? singleSelectedPlaceholder.value : singleSelected.value }}</span>
    </div>
      <div v-show="isOpen" ref="dropdown" class="d-select-phone__drop">
        <div class="d-select-phone__list" data-simplebar>
          <div v-for="item in options" :key="item.id" :class="{ _active: checkActiveOption(item), _disable: item.disabled }" class="d-select-phone__item" :data-value="item.value" @click="chooseSelect(item)">
            <div class="d-select-phone__flag iti__flag" :class="getCountryClass(item)"></div>
            {{ item.name }}
            <span>{{ item.value }}</span>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import {isEmpty as _isEmpty, isEqual as _isEqual, cloneDeep as _cloneDeep} from 'lodash';
import eventPath from '@utils/eventPath';
import AdditionalClassCSS from "@utils/additionalClassCSS";
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: 'DSelectPhone',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    dataOptions: {
      type: [String, Array, Boolean],
      required: true,
      default: false,
    },
    modelValue: {
      type: [Array, String, Number, Object, Boolean],
      required: false,
      default: false,
    },
    dataPlaceholder: {
      type: [Number, String],
      default: '',
    },
    dataReturnParam: {
      type: String,
      default: 'value',
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    mod: {
      type: [String, Array],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      selectItem: '',
      singleSelected: {},
      singleSelectedPlaceholder: {disabled: false, value: null, name: ''},
      isOpen: false,
      options: [],
    };
  },
  computed: {
    singleSelectedEmpty() {
      return _isEmpty(this.singleSelected);
    },
    modClass() {
      return additionalClassCSS(this.mod);
    }
  },
  watch: {
    modelValue(newOptions) {
      this.setInitValue();
    },
    singleSelected(newValue) {
      this.sendData();
    },
    dataOptions() {
      this.isOpen = false;
      this.initOptions();
      if (this.modelValue !== false) {
        this.setInitValue();
      }
      else this.singleSelected = this.options[0];
    },
    reset() {
      if (this.reset) {
        this.singleSelected = {};
      }
    },
  },
  created() {
    this.initOptions();
    if (this.modelValue !== false) {
      this.setInitValue();
    }
  },
  methods: {
    initOptions() {
      if (this.dataOptions && (typeof this.dataOptions).toLowerCase() === 'string') this.options = JSON.parse(this.dataOptions);
      else if (this.dataOptions) this.options = _cloneDeep(this.dataOptions);
      this.singleSelectedPlaceholder.name = this.dataPlaceholder;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    chooseSelect(item) {
      if (item.disabled) return;
      this.isOpen = !this.isOpen;
      this.singleSelected = item;
      if (this.singleSelected.id === item.id) {
        this.isActive = true;
      }
    },
    checkActiveOption(item) {
      return this.singleSelected ? this.singleSelected.id === item.id : false;
    },
    onClickOutside(event) {
      const dropDownRef = this.$refs.dropdown;
      const path = eventPath(event);
      if (!path.includes(dropDownRef)) {
        this.isOpen = false;
      }
    },
    setInitValue() {
      this.singleSelected = this.options.find((item) => _isEqual(item[this.dataReturnParam], this.modelValue));
    },
    getReturnValue() {
      if (_isEmpty(this.singleSelected)) {
        return null;
      }
      else {
        return this.singleSelected[this.dataReturnParam];
      }
    },
    sendData() {
      const value = this.getReturnValue();
      if (!_isEqual(this.modelValue, value)) this.$emit('update:modelValue', value);
    },
    getCountryClass(item) {
      const res = {};
      const countryName = `iti__${item.slug}`
      res[countryName] = true;
      return res
    }
  },
};
</script>
