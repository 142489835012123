<template>
  <div class="d-switcher" :class="{ _disabled: disabled}" data-switcher="">
    <div class="d-switcher__text" :class="{ _active: firstValue.value === currentValue }" @click="setValue(null, false)">{{ firstValue.name }}</div>
    <div class="d-switcher__button">
      <input ref="input" type="checkbox" id="switch" @change="setValue($event)" :disabled="disabled">
      <label for="switch" ref="switch"></label>
    </div>
    <div class="d-switcher__text" :class="{ _active: secondValue.value === currentValue }" @click="setValue(null, true)">{{ secondValue.name }}</div>
  </div>
</template>

<script>
export default {
  name: "DSwitcher",
  props: {
    values: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: null
    }
  },
  mounted() {
    this.currentValue = this.value;
    const indexValue = this.values.findIndex((item) => item.value === this.value);
    indexValue ? this.$refs.input.checked = true : this.$refs.input.checked = false;

    this.$emit('update:value', this.currentValue)
  },
  computed: {
    firstValue() {
      if (!this.values.length) return null
      return this.values[0]
    },
    secondValue() {
      if (!this.values.length) return null
      return this.values[1]
    },
  },
  methods: {
    setValue($event, value = null) {
      if (this.disabled) return;
      let isChecked = false;
      if ($event) {
        isChecked = $event.target.checked
      } else {
        this.$refs.input.checked = value;
        isChecked = value
      }
      if (isChecked) {
        this.currentValue = this.secondValue.value
      } else {
        this.currentValue = this.firstValue.value
      }

      this.$emit('update:value', this.currentValue)

    }
  }
}
</script>
