import tagSearchBa from '@modules/legacy/tagSearch';
import {subscribe, isSupported} from 'on-screen-keyboard-detector';

$(window).on('load', () => {
    if (isSupported()) {
        const callMe = document.querySelector('[data-callme-button]');
        const social = document.querySelector('[data-socialbuttons]');
        subscribe(visibility => {
            if (visibility === 'hidden') {
                if (callMe) callMe.removeAttribute('hidden');
                if (social) social.removeAttribute('hidden');
            }
            else {
                if (callMe) callMe.setAttribute('hidden', 'hidden');
                if (social) social.setAttribute('hidden', 'hidden');
            }
        });
    }

});
$(document).on('click', '[data-search-show]', function(e) {
    e.preventDefault();
    $('[data-search-wrap]').addClass('_show');
    $('[data-search]').addClass('_show');

    $('[data-search-popup]').addClass('_show');
});
$(window).on('load', () => {
    new tagSearchBa({
        input: $('body').find('[data-search-input]'),
        tags: $('body').find('[data-search-drop]'),
        suggestions: $('body').find('[data-search-suggestions]'),
    });
});

$(document).on('keyup', '[data-search-input]', function() {
    if ($(this).val().length) {
        $('[data-search-drop]').removeClass('_open');
        $('[data-search-clear]').addClass('_active');
        $('[type="submit"]').addClass('_hidden');
    }
    else {
        $('[data-search-drop]').addClass('_open');
        $('[data-search-clear]').removeClass('_active');
        $('[type="submit"]').removeClass('_hidden');
    }
});
$(document).on('click', '[data-search-clear]', function(e) {
    $(this).removeClass('_active');
    $('[type="submit"]').removeClass('_hidden');
});

$(document).on('click', '[data-search-popup-close]', function(e) {
    e.preventDefault();
    $('[data-search-popup]').removeClass('_show');
});
$(document).on('keyup', function(e) {
    if (e.key && e.key.toLowerCase() === 'escape') $('[data-search-popup]').removeClass('_show');
});
