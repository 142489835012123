<template>
  <div class="d-aux-rooms-filter" :class="{_tablet: screenTablet}">
    <div v-if="screenTablet" class="d-aux-rooms-filter__row _header">
      <div class="d-aux-rooms-filter__title"> Фильтр</div>
      <div class="d-aux-rooms-filter__clear" @click="resetFilters">Сбросить</div>
    </div>
    <div class="d-aux-rooms-filter__row _row1">
      <d-filter-item ref="locationFilter"
                     data-title="Объект"
                     class="_location d-aux-rooms-filter__item"
                     :always-open="false"
                     :is-active="locationFilterActive"
                     :screen-xs="screenTablet"
                     :setClosed="!locationFilterOpen"
                     :class="{ _tags: tagsObjectTouch.length}"
                     @filter:open="resetLocationFilter($event)">
        <div class="d-tabs _filter">
          <div class="d-aux-rooms-filter__object d-tabs__content _active _single">
            <div class="d-index-filter-params__row">
              <div class="d-index-filter-params__checkboxes _object">
                <d-checkbox v-for="object in allowObjects"
                            :key="`object-${object.id}`"
                            :mod="['full', 'hover', 'cross']"
                            name="object"
                            :id="`object-${object.id}`"
                            :value="object.id"
                            v-model="filterParamsInternal.object"
                            :disabled="!facets.object.includes(object.id)"
                            @update:modelValue="updateFilterParams('object', screenTablet ? false : true)">
                  {{ object.filter_title }}
                </d-checkbox>
              </div>
            </div>
            <div v-if="facets?.quarter?.length" class="d-index-filter-params__row">
              <div class="d-index-filter-params__label">Квартал</div>
              <div class="d-index-filter-params__checkboxes _quarter">
                <d-checkbox v-for="quarter in allowQuarters"
                            :key="`quarter-${quarter.id}`"
                            :mod="['full', 'hover', 'cross']"
                            name="quarter"
                            :id="`quarter-${quarter.id}`"
                            :value="quarter.id"
                            v-model="filterParamsInternal.quarter"
                            :disabled="!facets.quarter.includes(quarter.id)"
                            @update:modelValue="updateFilterParams('quarter', screenTablet ? false : true)">
                  {{ quarter.filter_title }}
                </d-checkbox>
              </div>
            </div>
            <div v-if="facets?.building?.length" class="d-index-filter-params__row">
              <div class="d-index-filter-params__label">Корпус</div>
              <div class="d-index-filter-params__checkboxes _building">
                <d-checkbox v-for="building in allowBuildings"
                            :key="`building-${building.id}`"
                            :mod="['full', 'hover', 'cross']"
                            name="building"
                            :id="`building-${building.id}`"
                            :value="building.id"
                            v-model="filterParamsInternal.building"
                            :disabled="!facets.building.includes(building.id)"
                            @update:modelValue="updateFilterParams('building', screenTablet ? false : true)">
                  {{ building.filter_title }}
                </d-checkbox>
              </div>
            </div>
          </div>
        </div>
      </d-filter-item>
      <div v-if="screenTablet" class="d-choose-params__tags _projects" :class="{ _show: !locationFilterOpen && tagsObjectTouch.length, _open: locationFilterOpen}">
        <template v-if="tagsObjectTouch.length && !locationFilterOpen" >
          <div v-for="(tag, index) in tagsObjectTouch" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
            <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
            <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
              </svg>
            </button>
          </div>
        </template>

      </div>
      <d-filter-item v-show="screenTablet ? !locationFilterOpen : true"
                     ref="paramsFilter"
                     :always-open="screenTablet"
                     :is-active="paramsFilterActive"
                     :screen-xs="screenPhone"
                     :data-title="mod === 'storeroom' ? 'Параметры кладовой': 'Параметры машиноместа'"
                     :setClosed="!paramsFilterOpen"
                     class="_params d-aux-rooms-filter__item"
                     @filter:open="paramsFilterOpen = $event">
        <div class="d-tabs _filter">
          <div class="d-tabs__content _active _single">
            <div class="d-index-filter-params">
              <div v-if="mod ==='storeroom' && filters['type'].length > 1" class="d-index-filter-params__row d-tabs__buttons">
                <div class="d-index-filter-params__checkboxes _type d-tabs__buttonsInner">
                  <d-checkbox :key="`type-0`"
                              :mod="['full', 'hover']"
                              name="type"
                              :id="`type-all`"
                              type="radio"
                              :value="null"
                              :reset="filterParamsInternal['type'] !== null"
                              v-model="filterParamsInternal['type']"
                              class="d-tabs__button _click-off"
                              :class="{_active: !filterParamsInternal['type']}"
                              @update:modelValue="updateFilterParams('type')">
                    Все
                  </d-checkbox>
                  <d-checkbox v-for="fType in filters['type']"
                              :key="`type-${fType.pk}`"
                              :mod="screenTablet ? ['full', 'hover'] : ['full', 'grow', 'hover']"
                              name="type"
                              :id="`type-${fType.pk}`"
                              type="radio"
                              :value="fType.pk"
                              :reset="filterParamsInternal['type'] !== fType.pk"
                              v-model="filterParamsInternal['type']"
                              :disabled="!facets['type'].includes(fType.pk)"
                              class="d-tabs__button _click-off"
                              :class="{_active: filterParamsInternal['type'] === fType.pk}"
                              @update:modelValue="updateFilterParams('type')">
                    {{ fType.filter_title }}
                  </d-checkbox>
                  <div class="d-tabs__buttonBg"></div>
                </div>
              </div>
              <div class="d-index-filter-params__row">
                <div class="d-index-filter-params__label">Уровень</div>
                <div v-if="negativeFloors.length" class="d-index-filter-params__checkboxes _level">
                  <d-checkbox v-for="fLevel in negativeFloors"
                              :key="`level-${fLevel.pk}`"
                              :mod="['full', 'hover']"
                              name="level"
                              :id="`level-${fLevel.pk}`"
                              :value="fLevel.pk"
                              v-model="filterParamsInternal['level']"
                              :disabled="!facets['level'].includes(fLevel.pk)"
                              @update:modelValue="updateFilterParams('level')">
                    {{ fLevel.filter_title }}
                  </d-checkbox>
                </div>
                <div v-if="positiveFloors.length" class="d-index-filter-params__checkboxes _level">
                  <d-checkbox v-for="fLevel in positiveFloors"
                              :key="`level-${fLevel.pk}`"
                              :mod="['full', 'hover']"
                              name="level"
                              :id="`level-${fLevel.pk}`"
                              :value="fLevel.pk"
                              v-model="filterParamsInternal['level']"
                              :disabled="!facets['level'].includes(fLevel.pk)"
                              @update:modelValue="updateFilterParams('level')">
                    {{ fLevel.filter_title }}
                  </d-checkbox>
                </div>
              </div>
              <div v-if="mod !=='storeroom'" class="d-index-filter-params__row">
                <div class="d-index-filter-params__label">Тип</div>
                <div class="d-index-filter-params__checkboxes _type">
                  <d-checkbox v-for="fType in filters['type']"
                              :key="`type-${fType.pk}`"
                              :mod="screenTablet ? ['full', 'hover'] : ['full', 'grow', 'hover']"
                              name="type"
                              :id="`type-${fType.pk}`"
                              :value="fType.pk"
                              v-model="filterParamsInternal['type']"
                              :disabled="!facets['type'].includes(fType.pk)"
                              @update:modelValue="updateFilterParams('type')">
                    {{ fType.filter_title }}
                  </d-checkbox>
                </div>
              </div>
              <div v-if="mod ==='storeroom'" class="d-index-filter-params__row">
                <div class="d-index-filter-params__label">Площадь</div>
                <d-input-slider-range unit="м²"
                                      :step="0.01"
                                      :from="initialFilterParams.area[0]"
                                      :to="initialFilterParams.area[1]"
                                      type="number"
                                      :theme="''"
                                      v-model="filterParamsInternal.area"
                                      @update:modelValue="updateFilterParams('area')"/>
              </div>
            </div>
          </div>
        </div>
      </d-filter-item>
      <div v-show="screenTablet ? !locationFilterOpen : true" class="d-index-filter-item d-aux-rooms-filter__item _active _price" :class="{_open: screenTablet}">
        <div class="d-index-filter-item__head" v-if="screenTablet">Стоимость</div>
        <div :class="{'d-index-filter-item__drop': screenTablet, 'd-index-filter-choose__list': !screenTablet}">
          <d-input-slider-range unit="млн ₽"
                                :step="0.01"
                                :from="initialFilterParams.price[0]"
                                :to="initialFilterParams.price[1]"
                                type="price"
                                :theme="screenTablet ? '' : 'white'"
                                v-model="filterParamsInternal.price"
                                @update:modelValue="updateFilterParams('price')"/>
        </div>
      </div>
    </div>
    <div v-show="screenTablet ? !locationFilterOpen : true" class="d-aux-rooms-filter__row _row2">
      <div class="d-index-filter-item__head" v-if="screenTablet">Дополнительные параметры</div>
      <div class="d-aux-rooms-filter__advantages">
        <d-checkbox v-if="mod !=='storeroom'"
                    class="d-aux-rooms-filter__advantage _charging"
                    :mod="['full', 'icon']"
                    id="charging"
                    name="charging"
                    icon="charging"
                    :theme="screenTablet ? 'dark' : 'white'"
                    v-model="filterParamsInternal.charging"
                    @update:modelValue="updateFilterParams('charging')">Электрозарядка
        </d-checkbox>
        <d-checkbox class="d-aux-rooms-filter__advantage _action"
                    :mod="['full', 'icon']"
                    id="promotion"
                    name="promotion"
                    icon="fire"
                    :theme="screenTablet ? 'dark' : 'white'"
                    v-model="filterParamsInternal.promotion"
                    @update:modelValue="updateFilterParams('promotion')">Акция
        </d-checkbox>
      </div>
      <button v-if="!screenTablet && filterParamsChanged" class="d-aux-rooms-filter__clear" @click="resetFilters">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
        </svg>
        Очистить фильтры
      </button>
    </div>
    <div v-if="screenTablet && !locationFilterOpen" class="d-aux-rooms-filter__row _footer">
      <button v-if="!screenPhone && filterParamsChanged" class="d-aux-rooms-filter__clear" @click="resetFilters">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
        </svg>
        Очистить фильтры
      </button>
      <button v-if="!loading" class="d-aux-rooms-filter__save d-button _blue" @click="closeFilter">
        <span>{{ resultCount }}</span>
      </button>
      <div v-else class="d-aux-rooms-filter__save skeleton-button d-button _blue _show"><span class="skeleton-button__loader"></span></div>
    </div>
    <div v-if="screenTablet && locationFilterOpen" class="d-aux-rooms-filter__row _footer _object">
      <button v-if="!loading"  class="d-aux-rooms-filter__save d-button _blue" @click="applyLocationFilter">
        <span>Сохранить</span>
      </button>
      <div v-else class="d-aux-rooms-filter__save skeleton-button d-button _blue _show"><span class="skeleton-button__loader"></span></div>
    </div>
  </div>
</template>

<script>
import {toRaw} from 'vue';
import {isEqual as _isEqual, debounce as _debounce} from 'lodash';
import DFilterItem from '@components/organisms/DFilterItem.vue';
import DInputSliderRange from '@components/atoms/DInputSliderRange.vue';
import DFilterSearchList from '@components/molecules/DFilterSearchList.vue';
import DCheckbox from '@components/atoms/DCheckbox.vue';
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore, useTagsStore} from '@/stores/search';

export default {
  name: 'DSearchFilter',
  components: {DFilterItem, DInputSliderRange, DFilterSearchList, DCheckbox},
  emits: ['update:filter', 'reset:filter', 'close:filter'],
  props: {
    mod: {
      type: String,
      default: 'params',
    },
    close: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterParamsInternal: {},
      locationFilterBackup: {},
      locationFilterOpen: false,
      paramsFilterOpen: false,
      store: null,
    };
  },
  created() {
    this.filterParamsInternal = JSON.parse(JSON.stringify(toRaw(this.filterParams)));
    this.store = useSearchStore();
    this.$emit('update:filter', {params: this.filterParamsInternal, reload: false});
    document.addEventListener("visibilitychange", this.hideFilters);
  },
  computed: {
    positiveFloors() {
      return this.filters.level.filter(i => !i.filter_title.match(/^-/))
    },
    negativeFloors() {
      return this.filters.level.filter(i => i.filter_title.match(/^-/))
    },
    resultCount() {
      return this.result.total_mobile;
    },
    locationFilterActive() {
      return 'object' in this.filterParamsInternal ? this.filterParamsInternal.object.length > 0 : false;
    },
    paramsFilterActive() {
      return 'type' in this.filterParamsInternal
             ? (Array.isArray(this.filterParamsInternal.type) && this.filterParamsInternal.type.length > 0)
               || (!Array.isArray(this.filterParamsInternal.type) && this.filterParamsInternal.type !== null)
               || this.filterParamsInternal.level.length > 0
               || ('area' in this.filterParamsInternal && 'area' in this.initialFilterParams && !_isEqual(this.filterParamsInternal.area, this.initialFilterParams.area))
             : false;
    },
    priceFilterActive() {
      return 'price' in this.filterParamsInternal && 'price' in this.initialFilterParams && !_isEqual(this.filterParamsInternal.price, this.initialFilterParams.price);
    },
    allowObjects() {
      return 'object' in this.filters && 'object' in this.facets ? this.filters['object'].filter(object => this.facets['object'].includes(object.id)) : [];
    },
    allowQuarters() {
      return 'quarter' in this.filters && 'quarter' in this.facets ? this.filters['quarter'].filter(quarter => this.facets['quarter'].includes(quarter.id)) : [];
    },
    allowBuildings() {
      return 'building' in this.filters && 'building' in this.facets ? this.filters['building'].filter(building => this.facets['building'].includes(building.id)) : [];
    },
    filterParamsChanged() {
      return !_isEqual(toRaw(this.filterParams), toRaw(this.initialFilterParams));
    },
    tagsObjectTouch() {
      if (this.tags.length) {
        return this.tags.filter((tag) => ['projects', 'object', 'quarters', 'quarter', 'buildings', 'building'].includes(tag.filter))
      } else {
        return []
      }
    },
    ...mapWritableState(useTagsStore, [
      'tags',
      'resetFilterTag',
    ]),
    ...mapWritableState(useSearchStore, [
      'initialFilterParams',
      'loading',
      'loaded',
      'facets',
    ]),
    ...mapState(useSearchStore, [
      'filterParams',
      'screenTablet',
      'screenPhone',
      'resetFilterFlag',
      'filters',
      'facetsInitial',
      'result',
    ]),
  },
  watch: {
    close(value) {
      if(value) this.closeFilter();
    },
    //'facets': {
    //  deep: true,
    //  handler(value) {
        //if (this.filterParamsInternal.promotion && !value.promotion) this.filterParamsInternal.promotion = false;
        //if (this.filterParamsInternal.charging && !value.charging) this.filterParamsInternal.charging = false;
        //if (this.loaded) { // зависимые фильтры
        //  this.adjustRangeValue('price');
        //  this.adjustRangeValue('area');
        //  this.adjustArrayValue('level');
        //  if (this.mod !== 'storeroom') this.adjustArrayValue('type');
        //}
      //},
    //},
    resetFilterFlag(value) {
      if (value) this.resetFilters();
    },
    resetFilterTag(tag) {
      if (tag) this.resetFilter(tag);
    },
    paramsFilterOpen(value) {
      if (value) window.tabsWidthStart();
    },
  },
  methods: {
    hideFilters(e) {
      this.resetLocationFilter(false);
      this.paramsFilterOpen = false;
    },
    closeFilter() {
      this.hideFilters();
      this.$emit('close:filter');
    },
    async adjustArrayValue(type) {
      if (type in this.facets && ['level', 'type'].includes(type)) {
        await this.$nextTick(function() {
          this.filterParamsInternal[type] = this.filterParamsInternal[type].filter(x => this.facets[type].includes(x));
        });
      }
    },
    async adjustRangeValue(type, minMax = false) {
      if (type in this.facets && ['price', 'area'].includes(type)) {
        let [valueMin, valueMax] = [...this.filterParamsInternal[type]];
        let adjustValue = false;
        if(minMax) {
          if (Number(valueMin) < Number(this.facets[type].min)) {
            valueMin = this.facets[type].min;
            adjustValue = true;
          }
          if (Number(valueMax) > Number(this.facets[type].max)) {
            valueMax = this.facets[type].max;
            adjustValue = true;
          }
        }
        else {
          if (Number(valueMin) !== Number(this.facets[type].min)) {
            valueMin = this.facets[type].min;
            adjustValue = true;
          }
          if (Number(valueMax) !== Number(this.facets[type].max)) {
            valueMax = this.facets[type].max;
            adjustValue = true;
          }
        }
        if (adjustValue) {
          await this.$nextTick(function() {
            this.filterParamsInternal[type] = [valueMin, valueMax];
          });
        }
      }
    },
    setTags: _debounce(function() {
      //console.time('setTags');
      const tags = [];
      const params = Object.assign({}, JSON.parse(JSON.stringify(this.filterParamsInternal)));
      if (!Object.keys(this.facets).length || !Object.keys(this.initialFilterParams).length ||
        !Object.keys(params).length) {
        this.tags = tags;
        return;
      }

      const facetsPrice = [this.facets.price.min, this.facets.price.max];
      if (!_isEqual(params.price, facetsPrice) && !_isEqual(params.price, this.initialFilterParams.price)) {
        const [min, max] = params.price;
        tags.push({
          filter: 'price',
          category: 'price',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } млн ₽`,
          pk: null,
        });
      }

      if ('area' in this.facets) {
        if (!_isEqual(params.area, this.initialFilterParams.area)) {
          const [min, max] = params.area;
          tags.push({
            filter: 'area',
            category: 'area',
            tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } м²`,
            pk: null,
          });
        }
      }

      ['charging', 'promotion'].forEach(k => {
        if (k in params && params[k] && params[k] !== 'false') {
          tags.push({
            filter: k,
            category: k,
            tag_title: this.filters[k].tag_title,
            pk: null,
          });
        }
      });

      if (this.mod === 'storeroom' && 'type' in this.facets && 'type' in params && params['type'] !== null) {
        const tag = this.filters['type'].find(type => type.pk.toString() === params['type'].toString());
        if (tag) tags.push({
          filter: 'type',
          category: 'type',
          tag_title: tag.tag_title,
          pk: tag.pk,
        });
      }

      const arrayFilters = this.mod === 'storeroom' ? ['object', 'quarter', 'building', 'level'] : ['object', 'quarter', 'building', 'level', 'type'];

      arrayFilters.forEach(k => {
        if (params.hasOwnProperty(k) && params[k].length > 0) {
          const titles = params[k].sort().map(kPk => [
            kPk,
            this.filters[k].find(el => el.pk.toString() === kPk.toString()).tag_title,
          ]);
          titles.forEach((tag) => {
            tags.push({
              filter: k,
              category: k,
              tag_title: tag[1],
              pk: tag[0],
            });
          });
        }
      });
      //console.timeLog('setTags');
      this.tags = tags;
      //console.timeEnd('setTags');
    }, 500),
    async resetFilter(tag) {
      const [filter, category, value] = [tag.filter, tag.category, tag.pk];
      //console.log(`%c${ this.$options.name } resetFilter(${ filter }) toRaw(this.filterParamsInternal[filter])) = `,
      //  'color: lime; background-color: darkred; padding: 5px 7px',
      //  JSON.stringify(toRaw(this.filterParamsInternal[filter])));
      //console.log(`%c${ this.$options.name } resetFilter(${ filter }) value = `,
      //  'color: lime; background-color: darkred; padding: 5px 7px',
      //  value);

      switch (filter) {
        case 'price':
        case 'area': {
          //this.filterParamsInternal[filter] = [this.facets[filter].min, this.facets[filter].max]; // зависимые фильтры
          this.filterParamsInternal[filter] = this.initialFilterParams[filter];
          break;
        }
        case 'charging':
        case 'promotion': {
          this.filterParamsInternal[filter] = false;
          break;
        }
        case 'type': {
          if (this.mod === 'storeroom') {
            this.filterParamsInternal[filter] = null
            break;
          }
          this.filterParamsInternal[filter].splice(this.filterParamsInternal[filter].findIndex((v) => value === v), 1);
          break;
        }
        default: {
          this.filterParamsInternal[filter].splice(this.filterParamsInternal[filter].findIndex((v) => value === v), 1);
        }
      }
      await this.updateFilterParams(filter);
      this.resetFilterTag = null;
    },
    clearFilter(type) {
      this.filterParamsInternal[type] = [];
    },
    async resetFilters() {
      this.facets = JSON.parse(JSON.stringify(this.facetsInitial));
      this.filterParamsInternal = Object.assign({}, toRaw(this.initialFilterParams));
      await this.updateFilterParams(null);
    },
    resetLocationFilter(open) {
      this.locationFilterOpen = open;
      if(open) {
        this.locationFilterBackup = {object: this.filterParamsInternal.object, quarter: this.filterParamsInternal.quarter, building: this.filterParamsInternal.building}
      }
      else if (Object.keys(this.locationFilterBackup).length > 0 && this.screenTablet) {
        this.filterParamsInternal.object = this.locationFilterBackup.object;
        this.filterParamsInternal.quarter = this.locationFilterBackup.quarter;
        this.filterParamsInternal.building = this.locationFilterBackup.building;
        this.locationFilterBackup = {};
      }
      this.updateFilterParams('location', true);
    },
    applyLocationFilter() {
      this.locationFilterOpen = false;
      this.locationFilterBackup = {};
      this.updateFilterParams('location', true, true);
    },
    updateFilterParams: async function(type = null, updateData = true, force = false) {
      if (!this.loaded) return;
      //console.time('updateFilterParams');
      if (type === 'object') {
        this.clearFilter('quarter');
        this.clearFilter('building');
      }
      if (type === 'quarter') {
        this.clearFilter('building');
      }
      this.setTags();

      if (!_isEqual(toRaw(this.filterParams), toRaw(this.filterParamsInternal)) || force) {
        this.loading = true;
        this.$emit('update:filter', {params: Object.assign({}, this.filterParamsInternal), reload: type !== null, updateData});
      }

    },
  },
};
</script>

