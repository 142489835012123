<template>
  <div class="d-object-contacts" :class="pageClass">
    <div class="d-object-contacts__mobile">
      <div class="d-object-single-easy-to-buy__infoTablet">
        <div v-if="!officesData" class="d-object-single-easy-to-buy__subtitle">Приехать на консультацию</div>
        <div class="d-object-single-easy-to-buy__addresses">
          <div v-for="(office, idx) in offices" :key="office.id" class="d-object-single-easy-to-buy__address" @click="openPopup(office, idx + 1)">
            <img class="d-object-single-easy-to-buy__addressImg" src="/assets/blueant/assets/img/d/map.jpg" alt="">
            <div class="d-object-single-easy-to-buy__addressTitle">{{ office.name }}</div>
            <div class="d-object-single-easy-to-buy__addressText">{{ office.address }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-object-contacts__desktop">
      <d-map-contacts-offices v-model:routeData="routeData" v-model:hoverId="hoverOfficeId" v-model:activeId="activeOfficeId" :page="page" @suggest="updateSuggest" @allowRoutes="checkAllowRoute" @openPopup="openPopup" v-if="offices" :offices="offices"/>
      <d-map-contacts v-model:hoverId="hoverOfficeId" v-model:activeId="activeOfficeId" v-if="offices" :suggest="suggest" :page="page" :offices="offices" :allowRoute="allowRoute" @sendData="sendData"/>
    </div>

    <d-popup-simple v-if="mobileOfficeData" v-model="showMobilePopup" @closed="mobileOfficeData = null">
      <d-map-contacts-offices v-model:routeData="routeData" v-model:hoverId="hoverOfficeId" :single-office="mobileOfficeData" v-model:activeId="activeOfficeId" v-model:openMobileInfo="openMobileInfoOffice" @suggest="updateSuggest" @allowRoutes="checkAllowRoute" @openPopup="openPopup" v-if="offices" :offices="offices"/>
      <d-map-contacts v-model:hoverId="hoverOfficeId" v-model:activeId="activeOfficeId" :single-office="mobileOfficeData" v-if="offices" :offices="offices"  :allowRoute="allowPopupRoute" v-model:openMobileInfo="openMobileInfoOffice" @sendData="sendData"/>
    </d-popup-simple>
  </div>
</template>

<script>
import DMapContacts from "../molecules/DMapContacts.vue";
import DMapContactsOffices from "../molecules/DMapContactsOffices.vue";
import DPopupSimple from "../molecules/DPopupSimple.vue";
import additionalClassCSS from "@utils/additionalClassCSS";
export default {
  name: "DObjectContacts",
  components: {
    DMapContacts,
    DMapContactsOffices,
    DPopupSimple
  },
  props: {
    officesApiUrl: {
      type: String,
      default: '',
    },
    officesData: {
      type: Array,
      default: null
    },
    page: {
      type: String,
      default: ''
    },
    sendDataUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      offices: null,
      hoverOfficeId: null,
      activeOfficeId: null,
      allowRoute: false,
      showMobilePopup: false,
      mobileOfficeData: null,
      allowPopupRoute: false,
      openMobileInfoOffice: true,
      suggest: false,
      routeData: null
    }
  },
  computed: {
    pageClass() {
      return additionalClassCSS(this.page);
    },
  },
  created() {
    if (this.officesData) this.offices = this.officesData
    else this.getOffices()
  },
  methods: {
    async getOffices() {
      try {
        let response = await this.axios.get(this.officesApiUrl);
        this.offices = response.data.offices
        console.log('response', response.data.offices)
      } catch(err) {
        console.log('error load object contacts offices', err);
      }
    },
    hoverOffice(id) {
      this.hoverOfficeId = id
    },
    checkAllowRoute(value) {
      this.allowPopupRoute = value
      this.allowRoute = value
    },
    openPopup(office, num) {

      this.openMobileInfoOffice = true
      this.mobileOfficeData = Object.assign({}, office, { num : num})
      this.showMobilePopup = true
    },
    updateSuggest(value) {
      this.suggest = value
    },
    sendData(data) {
      this.routeData = data
      console.log('send data', data)
    }
  }
}
</script>

<style scoped>

</style>
