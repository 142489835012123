<template>
  <div v-if="dataMod === 'index'" class="d-purchase-mortgage-calc" :class="{ _error: errored, _loading: loading, _empty: empty }">
    <div class="d-purchase-mortgage-calc__col _left">
      <div class="d-purchase-mortgage-calc__item">
        <DInputSliderSingle title="Стоимость жилья" :range="ranges.price" :value="filterParams.price" v-model.number="filterParams.price"/>
      </div>
      <div class="d-purchase-mortgage-calc__item">
        <DInputSliderSingle title="Первоначальный взнос" :range="ranges.firstPayment" :value="filterParams.payment" v-model.number="filterParams.payment"/>
      </div>
      <div class="d-purchase-mortgage-calc__item">
        <div class="d-purchase-mortgage-calc__item">
          <DInputSliderSingle title="Срок" :range="ranges.years" :step="1" format-type="year" type="number" :value="filterParams.years" v-model.number="filterParams.years"/>
        </div>
      </div>
    </div>
    <div class="d-purchase-mortgage-calc__col _right">
      <template v-if="count === 0">
        <div class="d-purchase-mortgage-calc-empty">
          <div class="d-purchase-mortgage-calc-empty__text">По заданным параметрам ничего не найдено. Попробуйте сбросить фильтр или задать другие параметры.</div>
          <button class="d-purchase-mortgage-calc-empty__button d-button _dark" @click="setDefaultParams"><span>Сбросить фильтры</span></button>
        </div>
      </template>
      <template v-else-if="errored">
        <div class="d-purchase-mortgage-calc-empty _error">
          <div class="d-purchase-mortgage-calc-empty__text">При загрузке данных произошла ошибка. Попробуйте обновить страницу</div>
          <button class="d-purchase-mortgage-calc-empty__button d-button _dark"><span>Обновить страницу</span></button>
        </div>
      </template>
      <template v-else-if="loading">
        <div class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">Ежемесячный платёж</div>
          <div class="d-purchase-mortgage-calc__value skeleton-field _field1"></div>
        </div>
        <div class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">Ставка</div>
          <div class="d-purchase-mortgage-calc__value skeleton-field _field2"></div>
        </div>
        <div class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">&nbsp;</div>
          <div class="d-purchase-mortgage-calc__button skeleton-button d-button _blue">
            <span class="skeleton-button__loader"></span>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="count > 0" class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">Ежемесячный платёж от</div>
          <div class="d-purchase-mortgage-calc__value">{{ formatSum(filterItems[0].payment) }}&nbsp;₽</div>
        </div>
        <div v-if="count > 0" class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">Ставка от</div>
          <div class="d-purchase-mortgage-calc__value">{{ filterItems[0].percent }}&nbsp;%</div>
        </div>
        <div class="d-purchase-mortgage-calc__item">
          <div class="d-purchase-mortgage-calc__label">&nbsp;</div>
          <a v-if="count > 0" class="d-purchase-mortgage-calc__button d-button _blue" :href="resultLink" _target="blank">
            <span>{{ resultCount }} банков</span>
            <span class="middle">{{ resultCount }}</span>
            <span class="short">Показать все</span>
          </a>
          <div v-else class="d-purchase-mortgage-calc__value">{{ noResultFound }}</div>
        </div>
      </template>
    </div>
  </div>
  <div v-if="dataMod === 'flat'" class="d-flat-mortgage-calc" :class="{ _error: errored, _loading: loading, _empty: empty }">
    <div class="d-flat-mortgage-calc__item">
      <DInputSliderSingle title="Стоимость недвижимости" :range="ranges.price" :value="filterParams.price" v-model.number="filterParams.price"/>
    </div>
    <div class="d-flat-mortgage-calc__item">
      <DInputSliderSingle title="Первоначальный взнос" :range="ranges.firstPayment" :value="filterParams.payment" :tip="paymentPercent" v-model.number="filterParams.payment"/>
    </div>
    <div class="d-flat-mortgage-calc__item">
      <DInputSliderSingle title="Срок кредита" :range="ranges.years" :step="1" format-type="year" type="number" :value="filterParams.years" v-model.number="filterParams.years"/>
    </div>
    <div class="d-flat-mortgage-calc__result">
      <div class="d-flat-mortgage-calc__resultLabel">Сумма кредита</div>
      <div v-if="loading" class="d-flat-mortgage-calc__resultValue skeleton-field _fieldCalc1"></div>
      <div v-else class="d-flat-mortgage-calc__resultValue">{{ creditSum }} ₽</div>
    </div>
    <div class="d-flat-mortgage-calc__bottom">
      <div class="d-flat-mortgage-calc__note">Данный расчет приведен для общей информации и не является публичной офертой</div>
      <div v-if="loading" class="d-flat-mortgage-calc__button skeleton-button d-button _blue"><span class="skeleton-button__loader"></span></div>
      <button v-else-if="errored" class="d-flat-mortgage-calc__button d-button _blue _disable"><span>Получить одобрение</span></button>
      <button v-else class="d-flat-mortgage-calc__button d-button _blue" @click="showPopup"><span>Получить одобрение</span></button>
    </div>
  </div>
  <div v-if="dataMod === 'purchase'" class="d-flat-mortgage-calc _purchase" :class="{ _error: errored, _loading: loading, _empty: empty }">
    <div class="d-flat-mortgage-calc__item">
      <DInputSliderSingle title="Стоимость недвижимости" :range="ranges.price" :value="filterParams.price" v-model.number="filterParams.price"/>
    </div>
    <div class="d-flat-mortgage-calc__item">
      <DInputSliderSingle title="Первоначальный взнос" :range="ranges.firstPayment" :value="filterParams.payment" v-model.number="filterParams.payment"/>
    </div>
    <div class="d-flat-mortgage-calc__item">
      <div class="d-purchase-mortgage-calc__item">
        <DInputSliderSingle title="Срок кредита" :range="ranges.years" :step="1" format-type="year" type="number" :value="filterParams.years" v-model.number="filterParams.years"/>
      </div>
    </div>
    <div v-if="dataLoading" class="d-flat-mortgage-calc__result">
      <div class="skeleton-field _fieldCalc1"></div>
    </div>
    <div v-else class="d-flat-mortgage-calc__result">{{ resultCount }}</div>
  </div>
</template>

<script>
import _, { cloneDeep as _cloneDeep} from 'lodash';
import * as Vue from 'vue';
import DMortgageInfo from '@components/molecules/DMortgageInfo.vue';
import DInputSliderSingle from '../atoms/DInputSliderSingle.vue';
import declOfNum from '../../utils/declOfNum';
import {toRaw} from 'vue';
import DInvestForm from '@components/molecules/DInvestForm.vue';
import DSelect from '@components/molecules/DSelect.vue';
import DCallMeForm from '@components/organisms/DCallMeForm.vue';
import DCheckbox from '@components/atoms/DCheckbox.vue';
import vClickOutside from 'click-outside-vue3';
import VueAxios from 'vue-axios';
import axios from 'axios';

export default {
  name: 'DMortgageForm',
  emits: ['request', 'update:items', 'load:items', 'load:info', 'error'],
  components: {
    DInputSliderSingle
  },
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/blueant/assets/json/flat-mortgage-list.json',
    },
    dataForm: {
      type: [String, Object],
      default: '',
    },
    dataMod: {
      type: String,
      default: 'index',
    },
    reset: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    dataLoading: {
      type: Boolean,
      default: true,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    sortParam: {
      type: String,
      default: 'percent',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
    dataPriorBank: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ranges: {
        price: [0, 150000000],
        firstPayment: [30000000, 15000000],
        years: [1, 30],
      },
      filterParams: {
        payment: null,
        price: null,
        years: null,
      },
      filterParamsInit: {
        payment: null,
        price: null,
        years: null,
        sum: null,
      },
      result: {
        string: '',
        stringEmpty: '',
        percent: '',
        payment: '',
        link: '',
      },
      form: {
        price_initial: 26000000,
        price_min: 15000000,
        price_max: 150000000,
        payment_percentInitial: 90,
        payment_percentMin: 10,
        payment_percentMax: 90,
        years_rangeInitial: 12,
        years_rangeMin: 1,
        years_rangeMax: 30,
      },
      errored: false,
      loading: false,
      items: [],
      noResultFound: 'Результатов не найдено',
      calcParams: {
        sum: 0,
        percent: 0,
        programPercent: 0,
      },
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    filterParams: {
      handler(params) {
        this.checkData();
      },
      deep: true,
    },
    filterItems: {
      handler(items) {
        if (this.items.length) this.$emit('load:items', this.filterItems);
      },
      deep: true,
    },
    reset(newVal) {
      if (newVal) this.setDefaultParams();
    },
  },
  computed: {
    count() {
      return this.filterItems.length;
    },
    resultLink() {
      return `/mortgage/?payment=${ this.filterParams.payment }&price=${ this.filterParams.price }&payment_percent=${ this.calcParams.percent }&years=${ this.filterParams.years }`;
    },
    resultCount() {
      if (this.empty) return this.noResultFound;
      return this.dataMod === 'index'
             ? `Показать ${ this.count } ${ declOfNum(this.count,
          ['предложение', 'предложения', 'предложений']) }`
             : `Найдено ${ this.filterItems.length } ${ declOfNum(this.filterItems.length,
          ['программа', 'программы', 'программ']) } в ${ this.uniqItems.length } ${ declOfNum(this.uniqItems.length,
          ['банке', 'банках', 'банках']) }`;
    },
    filterItems() {
      let items = toRaw(this.items);
      if (!Object.keys(this.filterParams).length) return _.orderBy(items, [self.sortParam], [self.sortOrder]);
      const self = this;

      if (this.sort) {

        items =
          _.orderBy(_.filter(items, function(o) {
            return self.filterParams.years <= o.time && self.calcParams.percent >= o.initial
              && (o.amountcredit ? self.calcParams.sum <= o.amountcredit : true) && (o.min ? self.calcParams.sum >= o.min : true);
          })
            .sort((a, b) => {
              if (self.dataPriorBank && a.bank_slug === self.dataPriorBank) {
                if (b.bank_slug !== self.dataPriorBank) return -1;
                else return a.percent - b.percent;
              }
              else
                return 0;

            })
            .filter(function(item) {
              return item.percent;
            })
            .map(function(item) {
              item.payment = Math.round(self.calcParams.sum * (item.percent / 1200) / (1 - Math.pow(1 + (item.percent / 1200), (-12 * self.filterParams.years))));
              //item.percent = item.percent.toString().replace('.', ',');
              return item;
            }), [self.sortParam], [self.sortOrder]);
      }
      else {
        items =
          _.orderBy(
            items
              .filter(function(item) {
                return item.percent;
              })
              .filter(function(o) {
                return self.filterParams.years <= o.time && self.calcParams.percent >= o.initial
                  && (o.amountcredit ? self.calcParams.sum <= o.amountcredit : true) && (o.min ? self.calcParams.sum >= o.min : true);
              })
              .map(function(item) {
                item.payment = Math.round(self.calcParams.sum * (item.percent / 1200) / (1 - Math.pow(1 + (item.percent / 1200), (-12 * self.filterParams.years))));
                item.paymentPercent = item.percent.toString().replace('.', ',');
                return item;
              }),
            'payment', 'asc');
      }
      return items;
    },
    uniqItems() {
      return _.uniqWith(this.filterItems, (a, b) => b.bank_slug === a.bank_slug);
    },
    empty() {
      return this.count === 0;
    },
    creditSum() {
      return Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        maximumSignificantDigits: 11,
      })
        .format(this.filterParams.price - this.filterParams.payment)
        .replace(/,/g, ' ');
    },
    paymentPercent() {
      return Math.round(this.filterParams.payment * 100 / this.filterParams.price) + '%'
    }
  },
  created() {
    this.setInitParams();
  },
  methods: {
    formatSum(sum) {
      return Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        maximumSignificantDigits: 11,
      })
        .format(sum)
        .replace(/,/g, ' ');
    },
    async loadItems() {
      this.loading = true;
      try {
        const response = await this.axios.get(this.dataUrlApi);
        this.items = response.data.map((el, index) => {
          el.index = index;
          return el;
        });
        if (this.items.length) this.$emit('load:items', this.filterItems);
        else this.showInfo();
      } catch (error) {
        console.error(error);
        this.$emit('error');
        this.showInfo();
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    showInfo() {
      console.log('%cshowInfo()', 'color: yellow');
      if (this.dataMod === 'index') {
        document.dispatchEvent(new CustomEvent('mortgageInfo', {}));
      }
      else {
        console.log('%cthis.$emit(\'load:info\')', 'color: yellow');
        this.$emit('load:info');
      }
    },
    setInitParams() {
      console.log('setInitParams this.dataForm = ', this.dataForm);
      try {
        if (this.dataForm && (typeof this.dataForm).toLowerCase() === 'string') this.form = JSON.parse(this.dataForm);
        else if (this.dataForm && (typeof this.dataForm).toLowerCase() === 'object') this.form = _cloneDeep(this.dataForm);


      } catch (e) {
        console.error('%cDMortgageForm setInitParams() error', 'color: yellow', e);
      }
      const url = new URL(location.href);
      const priceParam = url.searchParams.get('price-initial');
      console.log('priceParam = ', priceParam);
      if (priceParam) {
        this.form.price_initial = Number(priceParam);
        console.log('this.form.price_initial = ', this.form.price_initial);
      }

      const min = Math.ceil((this.form.price_initial / 100) * this.form.payment_percentMin);
      const max = Math.floor((this.form.price_initial / 100) * this.form.payment_percentMax);

      this.ranges.price = [this.form.price_min, this.form.price_max];
      this.ranges.firstPayment = [min, max];
      this.ranges.years = [this.form.years_rangeMin, this.form.years_rangeMax];

      this.filterParamsInit.price = this.form.price_initial;
      this.filterParamsInit.payment = Math.round((this.form.price_initial / 100) * this.form.payment_percentInitial);
      this.filterParamsInit.years = this.form.years_rangeInitial;

      this.setDefaultParams();
      if (this.dataMod === 'flat') this.$emit('update:items', this.filterParams);

    },
    setDefaultParams() {
      this.filterParams.price = this.filterParamsInit.price;
      this.filterParams.payment = this.filterParamsInit.payment;
      this.filterParams.years = this.filterParamsInit.years;
    },
    checkData() {
      const min = Math.ceil((this.filterParams.price / 100) * this.form.payment_percentMin);
      const max = Math.floor((this.filterParams.price / 100) * this.form.payment_percentMax);
      if (this.filterParams.payment < min) this.filterParams.payment = min;
      if (this.filterParams.payment > max) this.filterParams.payment = max;
      this.ranges.firstPayment = [min, max];

      this.calcParams = {
        sum: this.filterParams.price - this.filterParams.payment,
        percent: (this.filterParams.payment * 100 / this.filterParams.price),
      };

      if (this.dataMod === 'flat') this.$emit('update:items', this.filterParams);
    },
    showPopup() {
      this.$emit('request', {form_data: this.filterParams});
    },
  },
};
</script>

<style scoped></style>
