<template>
  <d-index-filter-wrapper v-if="!screenTablet"
                          v-bind="{...$props, ...$attrs}"
                          :reset-filters-flag="resetFiltersFlag"
                          :reset-filter-tag="resetFilterTag"
                          @update:result="result = $event"
                          @tags="setTags"
                          mod="objects"></d-index-filter-wrapper>
  <template v-else>
    <div class="d-result-filter__buttons-wrap">
      <div class="d-tabs _whiteBg _availFilter">
        <div class="d-result-filter__buttons _tablet _left">
          <div class="d-tabs__buttons">
            <div class="d-tabs__buttonsInner">
              <button class="d-tabs__button" :class="{_active: availFilter}" @click="chooseAvailFilter(true)">В продаже</button>
              <button class="d-tabs__button" :class="{_active: !availFilter}" @click="chooseAvailFilter(false)">Все</button>
              <div class="d-tabs__buttonBg"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-result-filter__buttons _tablet">
        <button class="d-result-filter__show d-button _white _withImg _left" @click="showFilter">
          <img src="/assets/blueant/assets/img/d/icon_filter_dark.svg"><span>Фильтры</span>
        </button>
        <button class="d-result-filter__map d-button _white _withImg _left" data-geography-show @click="showMap">
          <img src="/assets/blueant/assets/img/d/icon_pin.svg"><span>На&nbsp;карте</span>
        </button>
      </div>
      <a :href="dataChooseParamsLink" class="d-projects__buttonParams d-button _blue _mobile">
        <span>Выбрать квартиру</span>
      </a>
    </div>

    <d-popup-simple v-model="filterShow" mod="filter">
      <d-index-filter-wrapper v-bind="{...$props, ...$attrs}"
                              v-model:filterShow="filterShow"
                              :filter-params-outside="filterParams"
                              :screenTablet="true"
                              :reset-filters-flag="resetFiltersFlag"
                              :reset-filter-tag="resetFilterTag"
                              @update:result="result = $event"
                              @close="filterShow = false"
                              @tags="setTags"
                              mod="popup"></d-index-filter-wrapper>
    </d-popup-simple>
  </template>
  <teleport v-if="!screenTablet" to=".d-index-flats__top">
    <div class="d-result-filter__buttons">
      <button class="d-result-filter__map d-button _white _dark _left" data-geography-show @click="showMap">
        <span>Смотреть на&nbsp;карте</span>
      </button>
      <a :href="dataChooseParamsLink" class="d-result-filter__params d-button _blue _left">
        <span>Выбрать квартиру</span>
      </a>
    </div>
  </teleport>
  <div class="d-result-filter__header">
    <div class="d-index-filter-choose__count _objects">{{ result.total_string }}</div><!--  v-if="screenTablet" -->
    <template v-if="tags.length && screenTablet">
      <div class="d-index-filter-choose__list">
        <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
          <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
          <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
            </svg>
          </button>
        </div>
      </div>
      <button v-if="!screenMobile || result.total > 0" class="d-index-filter__clear _objects" @click="resetFilters">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
        </svg>
        Очистить фильтры
      </button>
    </template>
  </div>


  <d-search-filter-empty v-if="result.total === 0 && tags.length" @reset="resetFilters" class="_objects"/>
  <d-index-filter-result :chooseParamsLink="dataChooseParamsLink" :data-items="result.items" :data-action-url="dataCallMeUrl" mod="objects" :data-lead-url="dataCallMeLeadUrl"/>
</template>

<script>
import DIndexFilterWrapper from '@components/organisms/DIndexFilterWrapper.vue';
import DIndexFilterResult from '@components/organisms/DIndexFilterResult.vue';
import DSearchFilterEmpty from '@components/molecules/DSearchFilterEmpty.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';

export default {
  name: 'DObjectsFilter',
  components: {DPopupSimple, DIndexFilterResult, DIndexFilterWrapper, DSearchFilterEmpty},
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/json/home-filter-api.json',
    },
    dataFilterParamsApi: {
      type: String,
      default: '/assets/json/home-filter-params.json',
    },
    dataFilterSpecsApi: {
      type: String,
      default: '/assets/json/home-filter-specs.json',
    },
    dataChooseParamsLink: {
      type: String,
      default: 'https://donstroy.moscow/full-search/',
    },
    dataCallMeUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataCallMeLeadUrl: {
      type: [String, Boolean],
      default: false,
    },
    dataInitialFilter: {
      type: [String],
      default: '{}',
    },
    style: {
      type: [String, Object],
    },
  },
  data() {
    return {
      filterShow: false,
      result: {
        more_link: '',
        items: [],
        total: 0,
        total_string: '',
      },
      filterParams: {
        available: true,
      },
      screenTablet: false,
      screenMobile: false,
      tags: [],
      resetFiltersFlag: 0,
      resetFilterTag: null,
    };
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    availFilter() {
      return this.filterParams.available;
    },
  },
  methods: {
    checkScreenSize() {
      this.screenTablet = window.matchMedia('(max-width: 1279px)').matches;
      this.screenMobile = window.matchMedia('(max-width: 767px)').matches;
    },
    showFilter() {
      this.filterShow = true;
      this.$nextTick(() => window.tabsWidthStart());
      this.saveStateToURL('list');
    },
    showMap() {
      if (window.matchMedia('(max-width: 1279px)').matches) $('body').addClass('_noScroll');
      document.dispatchEvent(new CustomEvent('showMap', {}));
      this.saveStateToURL('map');
    },
    chooseAvailFilter(type) {
      this.filterParams.available = type;
    },
    saveStateToURL(value) {
      const url = new URL(location.href);
      url.searchParams.set('type', value);
      history.pushState(null, '', url);
    },
    setTags(tags) {
      this.tags = tags;
    },
    resetFilter(tag) {
      this.resetFilterTag = null;
      this.resetFilterTag = tag;
      if (tag.category === 'available') this.filterParams.available = true;
    },
    resetFilters() {
      this.filterParams.available = true;
      this.resetFiltersFlag += 1;
    },
  },
};
</script>
