<template>
  <div v-if="errored" class="d-press-center-error">
    <div class="d-press-center-error__content">
      <div class="d-press-center-error__text">При загрузке данных произошла ошибка. Попробуйте обновить страницу</div>
      <button class="d-press-center-error__button d-button _dark" @click.once="reload"><span>Обновить</span></button>
    </div>
  </div>
  <div v-else-if="loading && (!filter.type || !pages[filter.type] || pages[filter.type].count <= parseInt(perPage))" class="d-tabs__content _active">
    <div class="d-news">
      <div class="d-news__list">
        <d-news-skeleton :count="parseInt(perPage)"/>
      </div>
    </div>
  </div>
  <template v-else>
    <div class="d-tabs__content" :class="{_active: !filter.type || filter.type === 'news'}">
      <div class="d-news" data-items="news">
        <div class="d-news__list">
          <d-news-item v-for="item in newsItems" :item-data="item" :data-likes-url="likesUrl" :key="item.id"/>
          <d-news-skeleton v-if="loading && data.news && data.news.length < pages.news.count" :count="parseInt(perPage)"/>
        </div>
        <div v-if="pagerVisible && filter.type === 'news' && pages.news.pages > 1" class="d-news__bottom">
          <div class="d-news__pager">
            <d-press-pager v-if="pages.news.pages" :pages-count="pages.news.pages" :current-page-index="pages.news.showPage" @changePage="setPage('news', $event)"/>
          </div>
          <button v-if="pages.news.page * perPage + pages.news.count < pages.news.total" class="d-button _dark" @click="addMore('news')"><span>Показать ещё</span></button>
        </div>
      </div>
    </div>
    <div class="d-tabs__content" :class="{_active: filter.type === 'media'}">
      <div class="d-news" data-items="media">
        <div class="d-news__list">
          <d-media-item v-for="item in mediaItems" :item-data="item" :data-likes-url="likesUrl" :key="item.id"/>
          <d-news-skeleton v-if="loading && data.media && data.media.length < pages.media.count" :count="parseInt(perPage)"/>
        </div>
        <div v-if="pagerVisible && filter.type === 'media' && pages.media.pages > 1" class="d-news__bottom">
          <div class="d-news__pager">
            <d-press-pager v-if="pages.media.pages" :pages-count="pages.media.pages" :current-page-index="pages.media.showPage" @changePage="setPage('media', $event)"/>
          </div>
          <button v-if="pages.media.page * perPage + pages.media.count < pages.media.total" class="d-button _dark" @click="addMore('media')"><span>Показать ещё</span></button>
        </div>
      </div>
    </div>
    <teleport to=".d-press-center__controls">
      <div class="d-press-center__selects" :class="filter.type && filter.year && months[filter.type] && months[filter.type][filter.year] ? '' : '_monthHide'">
        <d-select v-if="filter.type" id="year-select" data-placeholder="Год" :data-options="years[filter.type]" v-model="filter.year"></d-select>
        <d-select v-if="filter.type && filter.year && months[filter.type] && months[filter.type][filter.year]"
                  id="month-select"
                  data-placeholder="Месяц"
                  :data-options="months[filter.type][filter.year]"
                  v-model="filter.month"></d-select>
      </div>
      <div class="d-press-center__writeButton d-button _blue" data-pressform-show :data-action-url="actionUrl">
        <span>Написать нам</span>
      </div>

    </teleport>
  </template>
</template>

<script>
import DSelect from '@components/molecules/DSelect.vue';
import DNewsItem from '@components/molecules/DNewsItem.vue';
import DMediaItem from '@components/molecules/DMediaItem.vue';
import DPressPager from '@components/molecules/DPressPager.vue';
import DNewsSkeleton from '@components/molecules/DNewsSkeleton.vue';
import {sortedUniqBy, mapValues, orderBy, debounce as _debounce} from 'lodash';
import {toRaw} from 'vue';

export default {
  name: 'DPressCenter',
  components: {
    DSelect, DNewsItem, DMediaItem, DPressPager, DNewsSkeleton,
  },
  props: {
    urlApi: {
      type: String,
      default: '/assets/blueant/assets/json/press-api.json',
    },
    perPage: {
      type: [Number, String],
      default: 7,
    },
    actionUrl: {
      type: String,
      required: true,
      default: '/assets/blueant/assets/json/form-response.json',
    },
    likesUrl: {
      type: String,
      required: true,
      default: '/likes/api',
    },
    formType: {
      type: String,
      default: 'callback',
    },
  },
  data() {
    return {
      years: [],
      months: {news: [], media: []},
      data: {
        news: [],
        media: [],
      },
      pagerVisible: false,
      filter: {
        year: null,
        month: null,
        type: null,
      },
      show: {
        year: null,
        month: null,
      },
      showPopup: false,
      errored: false,
      loading: true,
      politicsLink: '',
      pages: {
        news: {},
        media: {},
      },
      monthNames: {},
      pushState: true,
    };
  },
  created() {
    this.show = {
      year: Number(this.perPage),
      month: Number(this.perPage),
    };
    this.pages = {
      news: {page: 0, showPage: 0, count: 0, pages: 0},
      media: {page: 0, showPage: 0, count: 0, pages: 0},
    };
    this.politicsLink = document.querySelector('[data-callMe-show][data-politics-link]').dataset.politicsLink;

    let currentType;
    const self = this;
    document.querySelectorAll('#pcLinks a').forEach(link => {
      link.addEventListener('click', this.switchType);
      const regEx = new RegExp(link.getAttribute('href'), 'i');
      const url = new URL(window.location);
      if (regEx.test(url.pathname)) {
        currentType = link.dataset.type;
        self.filter.type = currentType;
      }
    });
    if (!currentType) this.filter.type = document.querySelector('#pcLinks a._active').dataset.type;

    const url = new URL(window.location);
    const currentYear = url.searchParams.get('year');
    const currentMonth = url.searchParams.get('month');
    if (currentYear) this.filter.year = currentYear;
    if (currentMonth) this.filter.month = currentMonth;
    window.addEventListener('popstate', this.restore);
    //console.log('%ccreated this.filter = ', 'color: yellow', this.filter);
  },
  beforeUnmount() {
    window.removeEventListener('popstate', this.restore);
  },
  mounted() {

    const url = new URL(window.location);
    const currentPage = url.searchParams.get('page');
    if (currentPage) {
      this.pages[this.filter.type].page = parseInt(currentPage) - 1;
      this.pages[this.filter.type].showPage = parseInt(currentPage) - 1;
      this.pages[this.filter.type].count = parseInt(this.perPage);
    }
    //console.log('%cmounted this.filter = ', 'color: yellow', this.filter);
    this.getData();
  },
  computed: {
    mediaItems() {
      return this.data.media.map(item => {item._active = !!window.likes[item.id]; return item;})
    },
    newsItems() {
      return this.data.news.map(item => {item._active = !!window.likes[item.id]; return item;})
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$smoothScroll({
          scrollTo: document.querySelector(`#pcData`),
          updateHistory: false,
          duration: 500,
          offset: -250,
        });

        if (this.filter.type) {
          if (this.pushState) {
            const url = new URL(window.location);
            if (this.filter.year || this.filter.month) url.searchParams.set('page', '1');
            if (this.filter.year) url.searchParams.set('year', this.filter.year);
            if (this.filter.month) url.searchParams.set('month', this.filter.month);
            history.pushState(null, '', url);

            this.pages[this.filter.type].page = 0;
            this.pages[this.filter.type].showPage = 0;
          }
          else this.pushState = true;

          this.getData();
        }
      },
    },
  },
  methods: {
    getMonth: function(idx) {
      if (idx in this.monthNames) return this.monthNames[idx];
      let objDate = new Date(1);
      objDate.setMonth(idx - 1);
      let monthName = objDate.toLocaleString('ru-RU', {month: 'long'});
      monthName = monthName[0].toUpperCase() + monthName.slice(1);
      this.monthNames[idx] = monthName;
      return this.monthNames[idx];
    },
    switchType(e) {
      e.preventDefault();
      e.target.parentNode.querySelector('.d-tabs__button._active').classList.remove('_active');
      e.target.classList.add('_active');
      window.tabsWidthStart();
      this.pagerVisible = false;
      this.loading = true;
      this.pages = {
        news: {page: 0, showPage: 0, count: 0, pages: 0, total: 0},
        media: {page: 0, showPage: 0, count: 0, pages: 0, total: 0},
      };
      this.$smoothScroll({
        scrollTo: document.querySelector(`#pcData`),
        updateHistory: false,
        duration: 500,
        offset: -350,
      });
      setTimeout(() => {
        this.filter.type = null;
        this.filter.year = null;
        this.filter.month = null;

        history.pushState(null, '', e.target.getAttribute('href'));
        document.title = e.target.dataset.title ? e.target.dataset.title : e.target.innerHTML;
        setTimeout(() => {
          this.filter.type = e.target.dataset.type;
        }, 100);
      }, 500);
    },
    getData: _debounce(async function() {
      if (!this.filter.type) return;
      try {
        this.loading = true;
        this.pagerVisible = false;
        const url = `${ this.urlApi }?type=${ this.filter.type }&page=${ this.pages[this.filter.type].showPage + 1 }` +
          `&year=${ this.filter.year ?? '' }` + `&month=${ this.filter.month ?? '' }`;
        let response = await this.axios.get(url);

        if (this.filter.type === 'news') {
          const data = orderBy(response.data.news.items.map(this.calcDate), ['sortOrder'], ['desc']);
          if (this.pages.news.showPage !== this.pages.news.page) this.data.news = [...this.data.news, ...data];
          else this.data.news = data;
        }
        else {
          const data = orderBy(response.data.media.items.map(this.calcDate), ['sortOrder'], ['desc']);
          if (this.pages.media.showPage !== this.pages.media.page) this.data.media = [...this.data.media, ...data];
          else this.data.media = data;
        }

        //console.log('%cgetData this.filter = ', 'color: yellow', this.filter);
        this.setSelects(response.data);
        this.$nextTick(() => {
          this.pages = {
            news: {
              page: this.pages.news.page,
              showPage: this.pages.news.showPage,
              count: Number(this.perPage),
              pages: Math.ceil(response.data.news.total / this.perPage),
              total: response.data.news.total,
            },
            media: {
              page: this.pages.media.page,
              showPage: this.pages.media.showPage,
              count: Number(this.perPage),
              pages: Math.ceil(response.data.media.total / this.perPage),
              total: response.data.media.total,
            },
          };
          setTimeout(() => {this.pagerVisible = true;}, 100);
        });
      } catch (err) {
        this.errored = true;
        console.error('error load news items', err);
      } finally {
        this.loading = false;
      }
    }, 500),
    async setPage(type, num) {
      this.$smoothScroll({
        scrollTo: document.querySelector(`#pcData`),
        updateHistory: false,
        duration: 500,
        offset: -350,
      });

      const url = new URL(window.location);
      url.searchParams.set('page', parseInt(num) + 1);
      history.pushState(null, '', url);

      setTimeout(async () => {
        this.pages[type].count = parseInt(this.perPage);
        this.pages[type].page = num;
        this.pages[type].showPage = num;
        await this.getData();
      }, 500);

    },
    async addMore(type) {
      this.pages[type].count += parseInt(this.perPage);
      this.pages[type].showPage += 1;

      const url = new URL(window.location);
      url.searchParams.set('page', this.pages[type].showPage + 1);
      history.pushState(null, '', url);

      await this.getData();
    },
    reload() {
      window.location.reload();
    },
    setSelects(data) {
      let months = {news: {}, media: {}};
      let yearsNews = sortedUniqBy(orderBy(data.news.years.map(item => {
        if (!(item.year in months.news)) months.news[item.year] = [];
        const monthName = this.getMonth(item.month);
        months.news[item.year].push({
          id: item.month,
          name: monthName,
          value: parseInt(item.month),
          disabled: false,
        });
        return {
          id: item.year,
          name: item.year,
          value: item.year,
          disabled: false,
        };
      }), ['value'], ['desc']), 'id');
      yearsNews.unshift({
        id: null,
        name: 'Все годы',
        value: null,
        disabled: false,
      });
      this.years.news = yearsNews;

      let yearsMedia = sortedUniqBy(orderBy(data.media.years.map(item => {
        if (!(item.year in months.media)) months.media[item.year] = [];
        const monthName = this.getMonth(item.month);
        months.media[item.year].push({
          id: item.month,
          name: monthName,
          value: parseInt(item.month),
          disabled: false,
        });
        return {
          id: item.year,
          name: item.year,
          value: item.year,
          disabled: false,
        };
      }), ['value'], ['desc']), 'id');
      yearsMedia.unshift({
        id: null,
        name: 'Все годы',
        value: null,
        disabled: false,
      });
      this.years.media = yearsMedia;

      //console.log('%csetSelects this.filter = ', 'color: yellow', this.filter);

      this.months.news = mapValues(months.news, items => {
        const months = sortedUniqBy(orderBy(items, ['value'], ['desc']), 'id');
        months.unshift({
          id: null,
          name: 'Все месяцы',
          value: null,
          disabled: false,
        });
        return months;
      });

      this.months.media = mapValues(months.media, items => {
        const months = sortedUniqBy(orderBy(items, ['value'], ['desc']), 'id');
        months.unshift({
          id: null,
          name: 'Все месяцы',
          value: null,
          disabled: false,
        });
        return months;
      });
      //console.log('%csetSelects this.filter = ', 'color: salmon', this.filter);
      //console.log('%csetSelects this.months.news = ', 'color: salmon', this.months.news);

    },
    calcDate(item) {
      const stringDate = item.date.toString().match(/^(\d{2})\.(\d{2})\.(\d{4})$/);
      item.monthNum = ('month' in item) ? item.month : stringDate[2];
      item.month = this.getMonth(Number(item.monthNum));
      item.year = ('year' in item) ? item.year : stringDate[3];
      item.sortOrder = `${ item.year }${ item.monthNum }`;
      item.index = 0;
      item.visible = true;
      return item;
    },
    async restore() {
      const url = new URL(window.location);
      let currentType = toRaw(this.filter.type);
      this.filter.type = null;
      this.pushState = false;
      document.querySelectorAll('#pcLinks a').forEach(link => {
        const regEx = new RegExp(link.getAttribute('href'), 'i');
        if (regEx.test(url.pathname)) {
          currentType = link.dataset.type;
        }
      });
      const currentYear = url.searchParams.get('year');
      const currentMonth = url.searchParams.get('month');
      const currentPage = url.searchParams.get('page');
      if (currentYear) this.filter.year = currentYear;
      if (currentMonth) this.filter.month = currentMonth;
      if (currentPage) {
        this.pages[currentType].page = parseInt(currentPage) - 1;
        this.pages[currentType].showPage = parseInt(currentPage) - 1;
        this.pages[currentType].count = parseInt(this.perPage);
      }
      this.filter.type = currentType;
    },
  },
};
</script>

<style scoped>

</style>
