<template>
  <div class="d-index-flats__slider" :class="classList">
    <div class="d-index-flat _slider" :class="classList">
      <div class="d-index-flat__actions" :class="classList">
        <button v-for="label in slider.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background_color}" :data-v-tippy="label.tooltip">
          <div class="d-flat-action__name">{{ label.title }}</div>
          <a v-if="!touchDevice && label.link" class="d-flat-action__link" :href="label.link"></a>
        </button>
      </div>
      <div class="d-result-slider__info">
        <div class="d-result-slide__title" v-html="slider.name"></div>
        <div class="d-result-slide__subtitle" v-html="slider.slider_title"></div>
        <div class="d-result-slide__address">{{ slider.address }}</div>
        <div class="d-result-slide__metro">
          <div v-for="metro in slider.metro" class="d-metro">
            <div v-for="mcolor in metro.color" class="d-metro__color" :style="{backgroundColor: mcolor}"></div>
            <div class="d-metro__name">
              {{ metro.name }}
              <span v-if="metro.drive" class="d-metro__drive">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-drive"></use>
                </svg> {{ metro.drive }}
              </span>
              <span v-if="metro.walk" class="d-metro__walk">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-walk"></use>
                </svg> {{ metro.walk }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-result-slide__price">{{ slider.price_from }}</div>
        <a class="d-result-slide__more d-button" :href="slider.link">
          <span>Подробнее</span>
        </a>
      </div>
      <div class="d-result-slider__arrows _objects">
        <div v-show="slider.slider.length > 1" class="d-arrows-slider">
          <button class="d-arrows-slider__arrow d-button _slider _prev topSlider _undefined">
            <span></span>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"/>
            </svg>
          </button>
          <button class="d-arrows-slider__arrow d-button _slider _next topSlider _undefined">
            <span></span>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="d-index-flat__img d-result-slider swiper" :class="classList" data-result-slider>
        <div class="swiper-wrapper">
          <div v-for="img in slider.slider" class="swiper-slide d-result-slide">
            <img class="d-result-slide__img" :src="img" alt="">
          </div>
        </div>
        <div v-show="slider.slider.length > 1" class="d-result-slider__pagination d-slider-pagination" data-result-slider-pagination></div>
        <div v-show="slider.slider.length > 1" class="d-result-slider__timer"></div>
      </div>

    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import tippy from 'tippy.js';
import getTouchDevice from '@utils/getTouchDevice';

export default {
  name: 'DResultSlider',
  data() {
    return {
      touchDevice: false,
    };
  },
  props: {
    slider: {
      type: Object,
      default: () => {},
    },
    mod: {
      type: String,
      default: 'index',
    },
  },
  watch: {
    slider() {
      //this.refill();
    },
  },
  computed: {
    modClass() {
      const obj = {};
      if (this.mod) {
        obj[`_${ this.mod }`] = true;
      }
      return obj;
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
  },
  created() {
    this.touchDevice = getTouchDevice();
    //this.refill();
  },
  mounted() {

    const progressBar = document.querySelector('.d-result-slider__timer');
    new Swiper('[data-result-slider]', {
      loop: this.slider.slider.length > 1,
      slidesPerView: 'auto',
      spaceBetween: 0,
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 3500,
        //disableOnInteraction: false
      },
      pagination: {
        el: '[data-result-slider-pagination]',
        type: 'custom',
        renderCustom: function(swiper, current, total) {
          let currentSlide = current.toString();
          let totalSlide = total.toString();
          return `<span>${ currentSlide }&nbsp;-&nbsp;${ totalSlide }</span>`;
        },
      },
      navigation: {
        nextEl: '.topSlider._next',
        prevEl: '.topSlider._prev',
      },
      on: {
        init: function() {
          progressBar.classList.remove('active');
          progressBar.classList.add('animate');
          progressBar.classList.add('active');
        },
        slideChangeTransitionStart: function(swiper) {
          progressBar.classList.remove('animate');
          progressBar.classList.remove('active');
          if (!swiper.autoplay.running) return;
          progressBar.classList.add('active');
        },
        slideChangeTransitionEnd: function(swiper) {
          if (!swiper.autoplay.running) return;
          progressBar.classList.add('animate');
        },
        slideChange: function(swiper) {
          progressBar.classList.remove('animate');
          progressBar.classList.remove('active');
          if (!swiper.autoplay.running) return;
          progressBar.classList.add('animate');
          progressBar.classList.add('active');
        },
        autoplayStop: function() {
          progressBar.classList.remove('animate');
          progressBar.classList.remove('active');
          progressBar.setAttribute('hidden', true);
        },
      },
    });
  },
  methods: {
    refill() {
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              //trigger: 'click',
              allowHTML: true,
              //interactive: true,
              appendTo: () => document.body,
              offset: [0, 8],
              //interactiveDebounce: 10,
              theme: this.touchDevice ? 'index-filter-touch' : 'index-filter',
              placement: 'top-start',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true
            });
            $(el).on('click', () => {
              console.log(el, el._tippy);
              el._tippy.hide();
            })
          }
        });
      });
    },
  },
};
</script>

<style scoped>

</style>
