<template>
  <div class="d-awards-popup">
    <div class="d-awards-popup__close d-button _close _blue" @click="$emit('close')">
      <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
    </div>
    <div ref="pagination" class="d-awards-popup__pagination d-slider-pagination"></div>
    <div v-if="photos.length > 1" class="d-awards-popup__arrows">
      <button ref="prev" class="d-arrows-slider__arrow d-button _slider _prev awards">
        <span></span>
        <svg  width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
        </svg>
      </button>

      <button ref="next" class="d-arrows-slider__arrow d-button _slider _next awards">
        <span></span>
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
        </svg>
      </button>
    </div>
    <swiper
        ref="slider"
        v-bind="swiperOpts"
        class="d-awards-popup__slider"
        :pagination="{
              el: pagination,
              type: 'custom',
              renderCustom: function (swiper, current, total) {
              let currentSlide =  current.toString();
              let totalSlide = total.toString();
              return `<span>${currentSlide}&nbsp;-&nbsp;${totalSlide}</span>`;
              }
            }"
        :navigation="{
              prevEl: prev,
              nextEl: next,
            }"
    >
      <swiper-slide v-for="photo in photos" :key="photo" class="d-awards-popup__slide">
        <img :src="photo" alt="">
      </swiper-slide>
    </swiper>
    <div v-if="!swipeInfoHide && isTouchDevice" class="d-awards-popup__note">
      <div class="d-swipe-info">
        <img src="/assets/blueant/assets/img/d/icon_swipe2.svg" alt="">
        <div>Используйте жесты для масштабирования и&nbsp;прокрутки изображения</div>
        <button @click="closeSwipeInfo">
          <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" alt="">
        </button>
      </div>
    </div>



  </div>
</template>

<script>
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/vue';
import {Navigation, Pagination} from 'swiper';
import {ref} from "vue";
import Cookies from "js-cookie";
import getTouchDevice from '@utils/getTouchDevice';
export default {
  name: "DAwardsPopup",
  emits: ['close'],
  props: {
    photos: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const pagination = ref(null)
    return {
      modules: [Navigation, Pagination],
      prev,
      next,
      pagination
    };
  },
  data() {
    return {
      swipeInfoHide: true,
      isTouchDevice: false,
    }
  },
  computed: {
    swiperOpts() {
      return {
        loop: this.photos.length > 1,
        slidesPerView: 'auto',
        spaceBetween: 10,
      }
    }
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closeSwipeAwards')) === 1;
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isTouchDevice = getTouchDevice();
    },
    closeSwipeInfo() {
      Cookies.set('closeSwipeAwards', 1)
      this.swipeInfoHide = true
    },
  }
}
</script>
