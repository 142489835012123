<template>
  <div v-if="dataFlat" class="d-flat-top">
    <div class="d-flat-top__item _top">
      <div class="d-flat-top__icons">
        <button v-if="dataFlat.panorams && dataFlat.panorams.length === 1"
                class="d-button _flatIcon _icon1"
                data-v-tippy-top="true"
                :data-v-tippy="isTouchDevice ? '' : 'Смотреть вид из окон'"
                @click="openViewPopup(dataFlat.panorams[0].url)">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-window"></use>
          </svg>
        </button>
        <button v-else-if="dataFlat.panorams && dataFlat.panorams.length > 1"
                class="d-button _flatIcon _icon1 _panoramButton"
                data-v-tippy-top="true"
                :data-v-tippy="isTouchDevice ? '' : 'Смотреть вид из окон'">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-window"></use>
          </svg>
        </button>
        <button v-if="booklets.length" class="d-button _flatIcon _text _icon2" @click="downloadBooklets" data-v-tippy-top="true" :data-v-tippy="'Скачать буклеты'">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-book"></use>
          </svg>
          <span>Буклеты</span>
        </button>
        <a v-if="dataFlat.pdfLink" class="d-button _flatIcon _text _icon3" :href="dataFlat.pdfLink" data-v-tippy-top="true" :data-v-tippy="'Скачать PDF'" target="_blank">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-pdf"></use>
          </svg>
          <span>PDF</span>
        </a>
        <button class="d-button _flatIcon _icon4" data-social-share-list>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-share"></use>
          </svg>
        </button>
        <button class="d-button _flatIcon _favorite _icon5"
                :class="{ _active: isFavorite}"
                @click="setFavorite"
                data-v-tippy-top="true"
                :data-v-tippy="isTouchDevice ? '' : (isFavorite ? 'Убрать из избранного' : 'Добавить в избранное')">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
          </svg>
        </button>
      </div>
      <a class="d-flat-top__name" :href="dataFlat.uri">{{ dataFlat.objectName }}</a>
      <h1 class="d-flat-top__title" v-html="dataFlat.flatTitle"/>
      <div class="d-flat-top__actions">
        <div v-if="'is_new' in dataFlat && dataFlat.is_new" class="d-flat-top__sticker _new" :data-v-tippy="dataFlat.is_new_tip"><span>new</span></div>
        <div v-for="label in dataFlat.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background}">
          <div class="d-flat-action__name">{{ label.title }}</div>
        </div>
      </div>
    </div>
    <div class="d-flat-top__item _plans">
      <div class="d-tabs">
        <button type="button" class="d-flat-plan__zoom" @click="zoom(currentTab)"></button>
        <div class="d-tabs__buttons">
          <div class="d-tabs__buttonsInner">
            <button v-for="(tab, index) in dataFlat.views" :key="`handler-${index}`" class="d-tabs__button _scroll _click-off" :class="{_active: currentView === index}" @click="choosePlan(index, $event)">{{
                tab.title
              }}
            </button>
            <div ref="tabBg" class="d-tabs__buttonBg"></div>
          </div>
        </div>
        <div v-for="(tab, index) in dataFlat.views" :key="`view-${index}`" class="d-tabs__content" :class="{ _flatPlan: tab.title.toLowerCase() === 'планировка' && planFloor, _active: currentView === index }">
          <div class="d-flat-plan" :class="{ _street: tab.sides && Object.keys(tab.sides).length, _house: tab.imageShift}">
            <div :ref="`plan-${index}`" class="d-flat-plan__img" @click="zoom(tab)">
              <img :ref="`image-${index}`" :src="tab.image"/>
              <!--<img :ref="`image-${index}`" :src="tab.image" :style="{ transform: screenXS && tab.imageShift ? `translateX(${tab.imageShift})` : 'none'}" />
              <div class="d-flat-plan__zoom"></div>-->
            </div>
            <div v-for="(side, key) in tab.sides" :key="`side-${key}`" class="d-flat-plan__street" :class="[`_${key}`, typeof side === 'object' ? '_eye' : '']">
              <template v-if="typeof side === 'string'">{{ side }}</template>
              <template v-if="typeof side === 'object'">
                <span class="d-flat-plan__street_eye"
                      data-v-tippy-top="true"
                      :data-v-tippy="isTouchDevice ? '' : side.title ? side.title : ''"
                      @click="openViewPopup(side.url)">
                  <svg>
                    <use :xlink:href="`/assets/blueant/assets/sprite.svg#sprite-eye`"></use>
                  </svg>
                </span>
              </template>
            </div>
            <div v-if="tab.tip" class="d-flat-plan__tip">{{ tab.tip }}</div>
            <d-swipe-zoom-info v-if="!swipeInfoHide" class="d-flat-plan__swipeInfo" @close="closeSwipeInfo()">
              Используйте жесты для прокрутки изображения
            </d-swipe-zoom-info>
            <d-disclaimer v-if="disclaimer" class="d-flat-plan__disclaimer" :text="disclaimer"/>
          </div>
          <div v-if="tab.title.toLowerCase() === 'планировка' && planFloor" class="d-flat-plan-floor">
            <img :src="planFloor.image" alt="вид на этаже"/>
          </div>
          <img v-if="tab.compassImg" :src="tab.compassImg" class="d-flat-plan__compass" :style="{transform: tab.compassDegree ? `rotate(${tab.compassDegree}deg)` : 'none'}">
        </div>
      </div>
    </div>
    <div class="d-flat-top__item _info">
      <div class="d-flat-top__infoTop">
        <div class="d-flat-info">
          <div class="d-flat-info__row">
            <div class="d-flat-info__label">Проект</div>
            <div class="d-flat-info__value">
              <a :href="dataFlat.uri">
                {{ dataFlat.objectName }}
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
                </svg>
              </a>
            </div>
          </div>
          <div v-if="dataFlat.quarterName && dataFlat.quarterName.toString() !== '0' && dataFlat.quarterName.toString() !== '-'" class="d-flat-info__row">
            <div class="d-flat-info__label">Квартал</div>
            <div class="d-flat-info__value">{{ dataFlat.quarterName }}</div>
          </div>
          <div v-if="dataFlat.korpus && dataFlat.korpus.toString() !== '0'" class="d-flat-info__row">
            <div class="d-flat-info__label">Корпус</div>
            <div class="d-flat-info__value">{{ dataFlat.korpus }}</div>
          </div>
          <div v-if="dataFlat.section && dataFlat.section.toString() !== '0'" class="d-flat-info__row">
            <div class="d-flat-info__label">Секция</div>
            <div class="d-flat-info__value">{{ dataFlat.section }}</div>
          </div>
          <div class="d-flat-info__row">
            <div class="d-flat-info__label">Этаж</div>
            <div class="d-flat-info__value">{{ dataFlat.floor }}</div>
          </div>
          <div v-if="dataFlat.area && dataFlat.area.toString() !== '0'" class="d-flat-info__row">
            <div class="d-flat-info__label">Площадь, м²</div>
            <div class="d-flat-info__value">{{ dataFlat.area }}</div>
          </div>
          <div v-if="dataFlat.height && dataFlat.height.toString() !== '0'" class="d-flat-info__row">
            <div class="d-flat-info__label">Высота потолков, м</div>
            <div class="d-flat-info__value">{{ dataFlat.height }}</div>
          </div>
        </div>
        <div class="d-flat-top__buttonsPrint">
          <button v-if="booklets.length" class="d-button _dark _small" @click="downloadBooklets"><span>Буклеты</span></button>
          <a v-if="dataFlat.pdfLink" class="d-button _dark _small" :href="dataFlat.pdfLink" target="_blank"><span>PDF</span></a>
          <button v-if="dataFlat.panorams && dataFlat.panorams.length === 1" class="d-button _dark _small _phone" @click="openViewPopup(dataFlat.panorams[0].url)">
            <span>Вид из окон</span>
          </button>
          <button v-else-if="dataFlat.panorams && dataFlat.panorams.length > 1" class="d-button _dark _small _phone _panoramButton">
            <span>Вид из окон</span>
          </button>
        </div>
      </div>
      <div v-if="!dataFlat.is_sold" class="d-flat-top__infoBottom">
        <div class="d-flat-top__price">
          <div class="d-flat-price">
            <div class="d-flat-price__digits">
              <div v-if="dataFlat.furnish_calc" class="d-flat-top__furnish">Стоимость без опций</div>
              <div v-if="dataFlat.priceOld" class="d-flat-price__old">{{ formatMoney(dataFlat.priceOld) }} ₽</div>
              <div v-if="dataFlat.price_request && dataFlat.price_request_label" class="d-flat-price__now" v-html="dataFlat.price_request_label"></div>
              <div v-else class="d-flat-price__now">{{ formatMoney(dataFlat.price) }} ₽<span v-if="dataFlat.actions_disclaimer" class="d-flat-price__star">*</span></div>
            </div>
            <div v-if="mortgagePrice" class="d-flat-price__mortgage">
              <div class="d-flat-price__mortgageInfo">В ипотеку от {{ formatMoney(mortgagePrice) }} ₽/мес.</div>
              <button class="d-flat-price__mortgageLink" @click="scrollTo('.d-flat-mortgage__title')">Рассчитать</button>
            </div>
            <div v-if="dataFlat.actions_disclaimer" class="d-flat-price__disclaimer" v-html="dataFlat.actions_disclaimer"></div>
          </div>
        </div>
        <div class="d-flat-top__buttons">
          <a v-if="(!('can_booking' in dataFlat) || dataFlat.can_booking !== false) && dataBookingUrl && !dataFlat.furnish_calc" class="d-flat-top__button d-button _dark" :href="dataBookingUrl" @click.prevent="bookOnline" target="_blank"><span>Забронировать онлайн</span></a>
          <button v-if="!dataFlat.furnish_calc" class="d-flat-top__button d-button _blue" data-callMe-show :data-action-url="dataActionUrl" :data-lead-url="dataLeadUrl" :data-props="flatLeadData"><span>Оставить заявку</span>
          </button>
          <a v-if="dataFlat.furnish_calc" class="d-flat-top__button d-button _dark" @click="scrollTo('.d-flat__furnish')" ><span>Смотреть опции отделки</span></a>
        </div>
      </div>
      <div v-else class="d-flat-top__infoBottom _sold">
        Квартира продана
      </div>
    </div>
    <d-popup-simple v-if="showPanoram && showPanoramPopup" v-model="showPanoramPopup" :with-close="false">
      <d-panoram-popup :panoram="showPanoram" @close="showPanoramPopup = null"/>
    </d-popup-simple>
  </div>
  <teleport to="body">
    <notifications position="top right" animation-type="velocity" :max="3" :reverse="true" :ignoreDuplicates="true" :pauseOnHover="true" :closeOnClick="false" :duration="notificationDuration">
      <template #body="props">
        <d-notification-item v-bind="{...props}"/>
      </template>
    </notifications>
  </teleport>
</template>

<script>
import DDisclaimer from '@components/atoms/DDisclaimer.vue';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import DPanoramPopup from '@components/molecules/DPanoramPopup.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import formatMoney from '@utils/formatMoney';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import multiDownload from 'multi-download';
import Cookies from 'js-cookie';
import DNotificationItem from '@components/atoms/DNotificationItem.vue';

export default {
  name: 'DFlatInfo',
  components: {DSwipeZoomInfo, DDisclaimer, DNotificationItem, DPopupSimple, DPanoramPopup},
  props: {
    dataActionUrl: {
      type: String,
      default: '',
    },
    dataLeadUrl: {
      type: String,
      default: '',
    },
    dataBookingUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataFlat: null,
      mortgagePrice: 0,
      booklets: [],
      isFavorite: false,
      favorites: [],
      tippyTips: [],
      zoomImages: [],
      planRefs: [],
      isTouchDevice: false,
      screenXS: false,
      screenWidth: 1024,
      screenHeight: 1024,
      flatLeadData: '',
      planFloor: false,
      swipeInfoHide: null,
      currentView: 0,
      flatId: null,
      showPanoram: null,
      showPanoramPopup: false,
      notificationDuration: 5000,
    };
  },
  computed: {
    disclaimer() {
      if (!this.dataFlat) return null;
      else return this.dataFlat.disclaimer;
    },
    currentTab() {
      return this.dataFlat.views[this.currentView];
    },
  },
  created() {
    document.addEventListener('set-favorite', e => {
      this.setFavorite(e, e.detail);
    });
    const self = this;
    window.mortgage.registerListener((val) => self.setMortgagePrice(val));

    if (window.flat_info) {
      if (window.flat_data) this.dataFlat = Object.freeze(Object.assign({}, window.flat_info, window.flat_data));
      else this.dataFlat = Object.freeze(window.flat_info);
    }
    if (window.flat_id) {
      this.flatId = window.flat_id;
    }

    this.planFloor = this.dataFlat.views.find(view => view.title.toLowerCase().match(/этаже/u));

    if (window.booklets_data) this.booklets = Object.freeze(window.booklets_data.map(el => el.link));

    this.favorites = window.getFavorites();
    this.isFavorite = window.inFavorites(window.flat_id);

    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);
  },
  watch: {
    showPanoramPopup() {
      if (!this.showPanoramPopup) this.releaseBody();
    },
  },
  mounted() {
    this.setMortgagePrice(window.mortgage.price);

    this.setTippy();
    this.setPanoramTippy();
    const flatLeadData = {
      'area': this.dataFlat.area,
      'comment': '',
      'email': '',
      'flat': window.flat_id,
      'floor': this.dataFlat.floor,
      'formType': 'reserve',
      'housing': this.dataFlat.korpus,
      'price': this.dataFlat.price,
      'section': this.dataFlat.section,
      'sourceConversion': '',
      'typeFlat': this.dataFlat.rooms,
      'URL': window.location.href || '',
      'utm': Cookies.get('utm_data') || window.utm_str_src || '',
    };

    //    `
    //Проект - ${ this.dataFlat.objectName }
    //
    //ID квартиры - ${ window.flat_id }
    //
    //Корпус - ${ this.dataFlat.korpus }
    //
    //Секция - ${ this.dataFlat.section }
    //
    //Этаж - ${ this.dataFlat.floor }
    //
    //Тип квартиры - ${ this.dataFlat.rooms }
    //
    //Площадь - ${ this.dataFlat.area } м2
    //
    //Стоимость - ${ this.dataFlat.price } р
    //
    //Источник перехода ${ utm_data ? utm_data : '_ _' }
    //
    //Полный URL - ${ window.location.href }}
    //`;
    this.flatLeadData = JSON.stringify(flatLeadData);
    this.swipeInfoHide = Number(Cookies.get('closeSwipeInfra')) === 1;
    window.tabsWidthStart();
    window.update_social_share();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  methods: {
    choosePlan(index, e) {
      this.currentView = index;
      this.$refs.tabBg.style.left = `${ e.target.offsetLeft }px`;
      this.$refs.tabBg.style.width = `${ e.target.offsetWidth }px`;
      this.$nextTick(() => {
        try {
          const container = this.$refs[`plan-${ index }`][0];
          const plan = this.$refs[`image-${ index }`][0];
          if (container && plan && plan.offsetLeft + plan.offsetWidth / 2 > (container.offsetWidth / 2 - container.scrollLeft)) {
            container.scrollLeft = (plan.offsetLeft + plan.offsetWidth / 2) - container.offsetWidth / 2;
          }
        } catch (e) {}
      });
    },
    setMortgagePrice(newValue) {
      //console.log(`%c ${this.$options.name} setMortgagePrice newValue = `, 'color: yellow', newValue);
      this.mortgagePrice = newValue;
    },
    setScreenSize() {
      this.isTouchDevice = window.matchMedia('(pointer: coarse)').matches;
      this.screenXS = window.matchMedia('(max-width: 767px)').matches;
      this.screenWidth = Math.round(window.innerWidth / 100 * 99);
      this.screenHeight = Math.round(window.innerHeight / 100 * 99);
    },
    zoom(tab) {
      console.log('tab', tab);
      const dataAdditImg = tab.title.toLowerCase() === 'планировка' && this.planFloor
                           ? this.planFloor.image : '';
      const dataImg = tab.imageZoom ? tab.imageZoom : tab.image;
      const popupData = {
        dataImg,
        dataAdditImg,
        dataWidth: this.screenWidth,
        dataHeight: this.screenHeight,
        dataTip: tab.tip,
        imgWidth: 3024,
        imgHeight: 2021,
        disclaimer: this.disclaimer,
        disclaimerShow: tab.disclaimerShow,
      };
      window.layoutAapp.$vfm.show({component: 'DFlatViewPopup', bind: popupData});
    },
    setPanoramTippy() {
      if (this.dataFlat.panorams && this.dataFlat.panorams.length) {
        let tippyContent = ``;
        this.dataFlat.panorams.forEach(data => {
          tippyContent += `<span data-open-panoram="${ data.url }">${ data.title }</span>`;
        });
        document.querySelectorAll('._panoramButton').forEach(el => {
          this.tippyTips.push(tippy(el, {
            trigger: 'click',
            allowHTML: true,
            interactive: true,
            appendTo: () => document.body,
            offset: [0, 8],
            interactiveDebounce: 10,
            theme: 'flat-panoram-button',
            placement: 'bottom-start',
            animation: 'fade',
            content: tippyContent,
            arrow: false,
          }));
        });
        document.addEventListener('click', (e) => {
          if (e.target && e.target.dataset.openPanoram) this.openViewPopup(e.target.dataset.openPanoram);
        });
      }
    },
    setTippy() {
      if (this.tippyTips.length) this.tippyTips.forEach(instance => {
        if (!instance.reference.classList.contains('_panoramButton')) instance.setContent(instance.reference.dataset.vTippy);
      });
      else document.querySelectorAll('[data-v-tippy]').forEach(el => {
        if (el.dataset.vTippy > '') {
          this.tippyTips.push(tippy(el, {
            //trigger: 'click',
            allowHTML: true,
            interactive: true,
            appendTo: () => document.body,
            offset: [0, 8],
            interactiveDebounce: 10,
            theme: 'flat-button',
            placement: el.dataset.vTippyTop ? 'top' : 'bottom',
            animation: 'fade',
            content: el.dataset.vTippy,
            arrow: false,
          }));
        }
      });
    },
    async setFavorite(e, data = null) {
      console.log('%csetFavorite data = ', 'color: lime', data);
      if (!data) {
        this.favorites = window.getFavorites();
        if (this.isFavorite) await window.removeFromFavorites(window.flat_id)
        else await window.addToFavorites(window.flat_id);
        this.favorites = window.getFavorites();

        this.$nextTick(() => {
          this.isFavorite = window.inFavorites(window.flat_id);
          setTimeout(() => {
            this.setTippy();
          }, 1000);
        });
        if (window.inFavorites(window.flat_id)) {
          const price = this.dataFlat.price_request && this.dataFlat.price_request_label ? this.dataFlat.price_request_label : `${ formatMoney(this.dataFlat.price) } ₽`;
          data = {id: window.flat_id, title: this.dataFlat.flatTitle, price};
        }
      }
      if (data) this.$notify({
        text: data.title + ' ' + data.price,
        data: {
          message: 'Добавлено в Избранное',
          price: data.price,
          title: data.title,
        },
      });

    },
    bookOnline(e) {
      try {
        dataLayerPush('event_to_GA', 'button_click', 'buy_book_online');
      } catch (e) {
        console.error('%cDFlatInfo bookOnline() error to call dataLayerPush', 'color: yellow', e);
      }
      if (window.DonStroyWidget && window.flat_id) DonStroyWidget.prototype.openBookingPopup(window.flat_id, {textBookingObject: 'квартира'});
      else {
        console.error('%cDFlatInfo bookOnline() error to call DonStroyWidget', 'color: yellow', window.DonStroyWidget, window.flat_id);
        window.location = (this.dataBookingUrl ? this.dataBookingUrl : `/lk/forms/?object_id=${this.flatId}`);
      }
    },
    downloadBooklets() {
      multiDownload(this.booklets);
    },
    scrollTo(item) {
      const el = document.querySelector(item);
      this.$smoothScroll({
        scrollTo: el,
        updateHistory: false,
      });
    },
    formatMoney,
    closeSwipeInfo() {
      Cookies.set('closeSwipeInfra', 1);
      this.swipeInfoHide = true;
    },
    openViewPopup(panoram) {
      $('body').addClass('_noJivo');
      this.showPanoramPopup = true;
      this.showPanoram = panoram;
    },
    releaseBody() {
      $('body').removeClass('_noJivo');
      $('body').removeClass('_popupOpen');
    },
  },
};
</script>

<style scoped>
.d-tabs__content {
  position: relative;
}

.d-flat-plan__compass {
  position: absolute;
  object-fit: none;
  width: unset;
  height: unset;
  bottom: 24px;
  left: 32px;
}

</style>
