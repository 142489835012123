<template>
  <d-index-filter-wrapper v-if="!screenTablet" v-bind="{...$props, ...$attrs}" @update:result="result = $event"></d-index-filter-wrapper>
  <template v-else>
    <div class="d-index-filter__buttons">
      <button class="d-index-filter__show d-button _blue _withImg _left" @click="showFilter">
        <img src="/assets/blueant/assets/img/d/icon_filter.svg"><span>Фильтры</span>
      </button>
      <button class="d-index-filter__map d-button _white _withImg _left" data-geography-show @click="showMap">
        <img src="/assets/blueant/assets/img/d/icon_pin.svg"><span>На&nbsp;карте</span>
      </button>
    </div>
    <d-popup-simple v-model="filterShow" mod="filter">
      <d-index-filter-wrapper v-bind="{...$props, ...$attrs}"
                              :screenTablet="true"
                              :resetFiltersFlag="resetFiltersFlag"
                              :resetFilterTag="resetFilterTag"
                              :popup="true"
                              @update:result="result = $event"
                              @close="filterShow = false"></d-index-filter-wrapper>
    </d-popup-simple>
  </template>
  <div v-if="screenTablet" class="d-index-filter__choose d-index-filter-choose _index _tablet">
    <div class="d-index-filter-choose__count _index">{{ result.total_string }}</div>
    <button v-show="tags.length" class="d-index-filter__clear" @click="resetFiltersFlag+=1">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
      </svg>
      Очистить фильтры
    </button>
    <div class="d-index-filter-choose__list">
      <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
        <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
        <button class="d-index-filter-choose-item__del" @click="resetFilterTag = tag">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <d-index-filter-result :chooseParamsLink="dataChooseParamsLink" :moreLink="result.more_link" :data-items="result.items" :data-action-url="dataCallMeUrl" :data-lead-url="dataCallMeLeadUrl"/>
</template>

<script>
import {useTagsStore} from '@/stores/index';
import {mapState} from 'pinia';
import DIndexFilterWrapper from '@components/organisms/DIndexFilterWrapper.vue';
import DIndexFilterResult from '@components/organisms/DIndexFilterResult.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';

export default {
  name: 'DIndexFilter',
  components: {DPopupSimple, DIndexFilterResult, DIndexFilterWrapper},
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/json/home-filter-api.json',
    },
    dataFilterParamsApi: {
      type: String,
      default: '/assets/json/home-filter-params.json',
    },
    dataFilterSpecsApi: {
      type: String,
      default: '/assets/json/home-filter-specs.json',
    },
    dataChooseParamsLink: {
      type: String,
      default: 'https://donstroy.moscow/full-search/',
    },
    dataCallMeUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataCallMeLeadUrl: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      filterShow: false,
      result: {
        more_link: '',
        items: [],
        total: 0,
        total_string: '',
      },
      screenTablet: false,
      resetFilterTag: null,
      resetFiltersFlag: 0,
    };
  },
  computed: {
    ...mapState(useTagsStore, [
      'tags',
    ]),
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.screenTablet = window.matchMedia('(max-width: 1279px)').matches;
    },
    showFilter() {
      this.filterShow = true;
      this.$nextTick( () => window.tabsWidthStart());
    },
    showMap() {
      document.dispatchEvent(new CustomEvent('showMap', {})); // document.dispatchEvent(new CustomEvent('mortgageInfo', {}));
    },
  },
};
</script>
