import { debounce as _debounce } from 'lodash';

function tagSearchBa(opt) {
    const input = opt.input;
    const tags = opt.tags;
    const suggestions = opt.suggestions;
    const _that = this;
    //const frame = opt.frame;
    let input_blur_timeout;
    let projects_ids = [];
    const $body = $('body');
    let selectedIndex = -1;
    let searchSuggestions = [];

    function init() {
        tags.html(getTags(null, opt.input.data('url')));

        $body
            .on('mousedown', '.search__tag', function() {

                let val = $(this).find('span').text().replace('#', ''),
                    url = $(this).data('targ');

                input.val(val); //.focus();
                //let projects = findByTag(val);

                console.log('url = ', val);
                if (url) {
                    window.location = url;
                }
                else {
                    loadProjectPage(findByTag(val), val);
                }

                // projects_ids = [];
                // $.each(projects, function(i, v){
                //     if($.inArray(projects_ids, v.page_id)==-1){
                //         projects_ids.push(v.page_id);
                //     }
                // });
                // console.log(projects_ids);
            })
            .on('mousedown', '.search__suggestion.focus-element:not(.no-click)', function() {
                const $this = $(this);
                const val = $this.find('div').text().toLowerCase();

                input.val(val);

                if ($this.hasClass('url-element')) {
                    pjax_loadPage($this.data('url'));
                }
                else {
                    loadProjectPage(findByTag(val), val);
                }
            })
            .on('focus', '[data-search-input]', function() {
                const self = this;
                $(self).val('');
                const url = self.dataset.url;

                getTags(tags_html => {
                    tags.html(tags_html);
                    setTimeout(() => {
                        // $body.addClass('search-opened');
                        $(self).parent('form').find('[data-search-drop]').addClass('_open');
                    }, 10);
                }, url);

                clearTimeout(input_blur_timeout);

                $(this).removeAttr('placeholder');
                $('.home__search-placeholder').remove();
            })
            .on('blur', '[data-search-input]', function() {
                const self = this;
                const elSuggestions = $(self).parent('form').find('[data-search-suggestions]');
                $(self).attr('placeholder', 'Поиск...');
                input_blur_timeout = setTimeout(() => {
                    $(self).parent('form').find('[data-search-drop]').removeClass('_open');
                    elSuggestions.removeClass('_open');
                }, 100);
            })
            .on('keyup', '[data-search-input]', function(e) {
                console.log();
                if (e.key.toLowerCase() === 'escape') {
                    $(this).parent('form').trigger('reset');
                    $(this).parent('form').find('[data-search-popup]').removeClass('_show');
                }
                else if (e.key.match(/^arrow(up|down)$/i)) {
                    e.preventDefault();
                    iterate[e.key](this);

                    return false;
                }
                else if (e.key.toLowerCase() === 'enter') {
                    e.preventDefault();
                    processSearch();

                    return false;

                }
                else if (!e.key.match(/arrow(left|right)/i)) {
                    getSiblingsTagsD($(this).val(), this);
                }
            })
            .on('submit', '[data-search-form]', function(e) {
                e.preventDefault();
                return false;
            })
            .on('submit', 'form.d-search', function(e) {
                e.preventDefault();
                return false;
            })
            .on('click', '.home__search-button', function() {
                processSearch();

                return false;
            })
            .on('mousedown', '.d-search__tag', function(e) {
                console.log('%c d-search__tag click', 'color: yellow', e.target, this);
            });

    }

    init();

    const iterate = {
        ArrowDown: function(input) {
            if (searchSuggestions.length) {
                searchSuggestions.removeClass('_selected');
                if (selectedIndex < searchSuggestions.length - 1) {
                    selectedIndex++;
                }
                else {
                    selectedIndex = 0;
                }
                this.hilight(input, selectedIndex);
            }
        },
        ArrowUp: function(input) {
            if (searchSuggestions.length) {
                searchSuggestions.removeClass('_selected');
                if (selectedIndex > 0) {
                    selectedIndex--;
                }
                else {
                    selectedIndex = searchSuggestions.length - 1;
                }
                this.hilight(input, selectedIndex);
            }
        },
        hilight(input, index) {
            searchSuggestions.eq(index).addClass('_selected');
            $(input).val(searchSuggestions.eq(selectedIndex).text());
        },
    };

    function processSearch() {
        let searchSuggestionSelected;
        if (searchSuggestions.length) {
            searchSuggestionSelected = Array.from(searchSuggestions).find(el => $(el).hasClass('_selected'));
            if (!searchSuggestionSelected) searchSuggestionSelected = Array.from(searchSuggestions)[0];
        }
        if (searchSuggestionSelected && $(searchSuggestionSelected).hasClass('url-element')) {
            pjax_loadPage($(searchSuggestionSelected).data('url'));
        }
        else if (input.val() && searchSuggestions.length) {
            let val = findByTag(input.val());
            loadProjectPage(val, input.val());
        }
        return false;
    }

    function loadProjectPage(projects_ids, text) {
        if (projects_ids && projects_ids.length) {
            pjax_loadPage('/buy-living/living-objects?ids=' + projects_ids.join() + '&tag=' + text);
        }
    }

    function findByTag(tag) {
        let result;
        $.ajax({
            url: '/find_tag/getFind/' + tag,
            cache: false,
            type: 'POST',
            dataType: 'json',
            async: false,
            success: function(data) {
                // console.log(data);
                result = data;
            },
        });
        let projects = result;
        projects_ids = [];
        $.each(projects, function(i, v) {
            if ($.inArray(projects_ids, v.page_id) == -1) {
                projects_ids.push(v.page_id);
            }
        });
        // console.log(projects_ids);
        return projects_ids;
    }


    function getSiblingsTags (tag, el = null) {
    const elSuggestions = el ? $(el).parent('form').find('[data-search-suggestions]') : suggestions;
    let result;
    $.ajax({
        url: `/find_tag/getTag/`,
        //url: '/assets/blueant/assets/json/search_suggestions.json',
        cache: false,
        type: 'POST',
        dataType: 'json',
        async: false,
        data: {
            search: tag,
        },
        success: function(data) {
            result = data;
        },
    });
    var tab_html = '';
    if (result && result.length) {
        $.each(result, function(i, v) {
            if (i < 6) {
                const reg = new RegExp('' + tag, 'gi');

                if (typeof v === 'object') {
                    let text = v.name.replace(reg, '<span>' + tag + '</span>');
                    tab_html += '<div tabindex="0" class="search__suggestion focus-element url-element" data-url="' +
                        encodeURI(v.url) + '"><div>' + text + '</div></div>';
                }
                else {
                    let text = v.replace(reg, '<span>' + tag + '</span>');
                    tab_html += '<div tabindex="0" class="search__suggestion focus-element"><div>' + text +
                        '</div></div>';
                }
            }
        });
        elSuggestions.html(tab_html).addClass('_open');
        searchSuggestions = elSuggestions.find('.search__suggestion');
        selectedIndex = -1;
    }
    else if (tag.length > 2) {
        // elSuggestions.removeClass('visible');
        tab_html += '<div class="search__suggestion no-click focus-element" ><div>По запросу нет результатов поиска</div></div>';
        elSuggestions.html(tab_html).addClass('_open').addClass('visible');
        searchSuggestions = [];
        selectedIndex = -1;
    }
    else {
        console.error('getSiblingsTags no results! tag = ', tag);
        elSuggestions.removeClass('visible');
    }
    //console.log(result);
}

    const getSiblingsTagsD = _debounce(getSiblingsTags, 500);

    function getTags(callback, url) {
        let result = [];
        var tag_html = '<div class="d-search__tagTitle">Возможно, вам будет интересно:</div>';
        if (!url) url = '/find_tag/distinct/'; // https://api.donstroy-a2.dev.blue-ant.ru/find_tag/distinct/
        $.ajax({
            url,
            //url: '/assets/json/search_tag.json/',
            cache: false,
            type: 'GET',
            dataType: 'json',
            async: false,
            success: function(response) {
                //console.log(response);
                result = response;
            },
        });

        $.each(result, function(i, v) {
            if (i < 10) {
                if (typeof v === 'object') {
                    tag_html += '<div class="search__tag d-search__tag" data-targ="' + v.url + '"><span>' + v.name +
                        '</span></div>';
                }
                else {
                    tag_html += '<div class="search__tag d-search__tag"><span>' + v + '</span></div>';
                }

            }
        });

        if (callback) callback(tag_html);

        return tag_html;

    }

    _that.findByTag = function(tag) {
        return findByTag(tag);
    };

    _that.clearInput = function() {
        input.val('');
    };

    return _that;
}

export default tagSearchBa;
