<template>
  <div :data-place-id="currentItem.guid" class="d-aux-rooms-line">
    <div class="d-aux-rooms-line__item _name">
      {{ currentItem.object.title }}<small v-if="currentItem.quarter && !screenPhone" :data-quarter="currentItem.quarter" v-html="quarterName(currentItem.quarter)"></small>
    </div>
    <div class="d-aux-rooms-line__item _build" :data-building="currentItem.building">
      <div class="d-aux-rooms-line__label">Корпус</div>
      {{ currentItem.building }}
    </div>
    <div class="d-aux-rooms-line__item _number">
      <div class="d-aux-rooms-line__label">Номер</div>
      {{ currentItem.number }}
    </div>
    <div class="d-aux-rooms-line__item _type">
      <div class="d-aux-rooms-line__label">Тип</div>
      <div class="d-aux-rooms-line__size" :class="{_notip: !currentItem.sizeTip}">
        {{ currentItem.type }}
        <div v-if="currentItem.sizeTip" ref="sizeInfo" :data-v-tippy="currentItem.sizeTip" class="d-aux-rooms-line__sizeInfo"></div>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _level">
      <div class="d-aux-rooms-line__label">Уровень</div>
      {{ currentItem.level }}<span class="d-aux-rooms-line__label _mobile">ур.</span>
    </div>
    <div class="d-aux-rooms-line__item _square">
      <div class="d-aux-rooms-line__label">Площадь, м&sup2;</div>
      {{ currentItem.area }}<span class="d-aux-rooms-line__label _mobile">м&sup2;</span>
    </div>
    <div class="d-aux-rooms-line__item _price">
      <div class="d-flat-price">
        <div class="d-flat-price__digits">
          <div v-if="currentItem.is_sold" class="d-flat-price__now">Продано</div>
          <template v-else>
            <div v-if="currentItem.price_old" class="d-flat-price__old">{{ currentItem.price_old }} ₽</div>
            <div class="d-flat-price__now">{{ currentItem.price_formatted }} ₽</div>
          </template>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _advantages">
      <div v-for="(prop, id) in currentItem.props" :key="`prop-${currentItem.id}-${id}`" class="d-flat-action" :style="{background: prop.bgColor, color: prop.color}">
        <div class="d-flat-action__name" :data-v-tippy="prop.tip">{{ prop.title }}</div>
        <img v-if="prop.icon" :src="prop.icon"/>
        <a v-if="prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _buttons _left">
      <a v-if="!currentItem.is_sold" class="d-aux-rooms-line__button d-button _flatIcon _scheme" :href="currentItem.link" target="_blank">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-map"></use>
        </svg>
        <span class="d-button__tooltip">Смотреть на схеме</span>
      </a>
      <button class="d-aux-rooms-line__button d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
        </svg>
        <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
        <span v-else class="d-button__tooltip">Добавить в избранное</span>
      </button>
    </div>
    <button v-if="!isFavorite" class="d-favorites-return _carPlace _line" @click="setFavorite">
      <span v-if="mod === 'carPlace'">Вы удалили это машиноместо&nbsp;из&nbsp;избранного</span>
      <span v-else>Вы удалили эту кладовую из&nbsp;избранного</span>
      <span class="d-favorites-return__button">Вернуть</span>
    </button>
    <a v-if="!currentItem.is_sold" class="d-aux-rooms-line__order" :href="currentItem.link" target="_blank"></a>
  </div>
</template>

<script>

export default {
  name: 'DFavoritesParkingPlaceLine',
  emits: ['set-favorite'],
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    favorites: {
      type: Array,
      default: null,
    },
    screenPhone: {
      type: Boolean,
      default: false,
    },
    mod: String,
  },
  computed: {
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.currentItem.form_id);
      return false;
    },
  },
  methods: {
    quarterName(quarter) {
      return !isNaN(quarter) ? `<span>Квартал </span>${ quarter }` : quarter;
    },
    setFavorite() {
      const price = this.currentItem.price_request && this.currentItem.price_request_label ? this.currentItem.price_request_label : `${ this.currentItem.price_formatted } ₽`;
      this.$emit('set-favorite', {id: this.currentItem.form_id, title: this.currentItem.title, price, show: true, is_sold: this.currentItem.is_sold});
    },
  },
};
</script>
