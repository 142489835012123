<template>
  <div class="d-aux-rooms-filter">
    <div class="d-aux-rooms-filter__row _row1">
      <div class="d-aux-rooms-filter__item skeleton-field"></div>
      <div class="d-aux-rooms-filter__item skeleton-field"></div>
      <div class="d-aux-rooms-filter__item skeleton-field"></div>
    </div>
    <div class="d-aux-rooms-filter__row _row2">
      <div class="d-aux-rooms-filter__advantages">
        <div class="skeleton-field"></div>
        <div class="skeleton-field"></div>
      </div>
      <div class="d-aux-rooms-filter__clear skeleton-field"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSearchFilterSkeleton',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
