<template>
  <div class="d-flat-card _choose" :class="classList">
    <template v-if="mod === 'skeleton'">
      <div class="d-flat-card _skeleton">
        <div class="d-flat-card__top">
          <div class="d-flat-card__title skeleton-field"></div>
          <div class="d-flat-card__position">
            <div class="d-flat-card__object skeleton-field"></div>
            <div class="skeleton-field"></div>
          </div>
        </div>
        <div class="d-flat-card__content">
          <div class="d-flat-card__img">
            <div class="skeleton-field"></div>
          </div>
          <div class="d-flat-card__info">
            <div class="d-flat-card__square skeleton-field"></div>
            <div class="d-flat-card__price skeleton-field"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flat-card__top">
        <div class="d-flat-card__title">
          <template v-if="data.isPenthouse">
            Пентхаус № {{ data.number }}
          </template>
          <template v-else-if="data.rooms === 0">
            Cтудия № {{ data.number }}
          </template>
          <template v-else>
            {{ data.rooms }}-<span>комнатная</span>
            <span class="mobile">комн.</span> квартира {{ data.number }}
          </template>
          <span v-if="'is_new' in data && data.is_new" class="d-flat-card__sticker _new" :data-v-tippy="data.is_new_tip">new</span>
        </div>

        <div class="d-flat-card__position">
          <div class="d-flat-card__object">{{ data.project }}</div>
          <template v-if="data.quarter">{{ data.quarter }},</template>
          Корпус {{ data.building }},
          секция {{ data.section }},
          этаж {{ data.floor }}
        </div>
        <div class="d-flat-card__favorites">
          <button class="d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite" :data-v-tippy="isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-heart"></use>
            </svg>
          </button>
        </div>
      </div>
      <div class="d-flat-card__content">
        <div class="d-flat-card__img">
          <img  v-if="'promotion' in data && data['promotion'].bage" :src="data['promotion'].bage" class="d-flat-card__img_black-friday"/>
          <img :src="data.plan">
        </div>
        <div class="d-flat-card__actions">
          <div v-for="label in data.labels" :key="label.title" class="d-flat-action" :style="`background-color:${label.background_color}; color: ${label.color}`" :data-v-tippy="label.tooltip">
            <div class="d-flat-action__name">{{ label.title }}</div>
          </div>
          <div v-if="'promotion' in data && data['promotion'].label" class="d-flat-action _black-friday" :style="{color: data['promotion'].color, backgroundColor: data['promotion'].background}" :data-v-tippy="data['promotion'].text">
            <div class="d-flat-action__name">{{ data['promotion'].label }}</div>
          </div>

        </div>
        <div class="d-flat-card__info">
          <div class="d-flat-card__square">{{ data.area }} м²</div>
          <div v-if="data.price_request && data.price_request_label" class="d-flat-card__price _request" v-html="data.price_request_label" />
          <div v-else class="d-flat-card__price">
            <div v-if="data.price_old" class="d-flat-card__priceOld">
              {{ data.price_old.toLocaleString('ru-RU') }} ₽
            </div>
            {{ data.price.toLocaleString('ru-RU') }} ₽
          </div>
        </div>
      </div>
      <a class="d-flat-card__link" :href="data.link" target="_blank"></a>
    </template>
  </div>
</template>

<script>
import additionalClassCSS from "@utils/additionalClassCSS";
export default {
  name: "DChooseFlatCard",
  props: {
    data: Object,
    mod: {
      type: [Array, String],
      default: '',
    },
    favorites: {
      type: Array,
      default: null
    }
  },
  computed: {
    flatTitle() {
      if (this.data.isPenthouse) {
        return `Пентхаус № ${this.data.number}`;
      }
      if (this.data.rooms === 0) {
        return `Студия № ${this.data.number}`;
      }
      return `${ this.data.rooms }-комнатная квартира № ${this.data.number}`;
    },
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.data.id)
      else return false
    }
  },
  methods: {
    setFavorite() {
      const price = this.data.price_request && this.data.price_request_label ? this.data.price_request_label : `${ this.data.price.toLocaleString('ru-RU') } ₽`;
      this.$emit('set-favorite', {id: this.data.id, title: this.flatTitle, price});
    },
  }
}
</script>
