<template>
  <div class="d-flat-card _type" :class="classList">
    <template v-if="mod === 'typeSkeleton'">
      <div class="d-flat-card__top">
        <div class="d-flat-card__title skeleton-field"></div>
        <div class="d-flat-card__position">
          <div class="d-flat-card__object skeleton-field"></div>
          <div class="skeleton-field"></div>
        </div>
      </div>
      <div class="d-flat-card__content">
        <div class="d-flat-card__img">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-card__bottom">
          <div class="d-flat-card__info">
            <div class="d-flat-card__square skeleton-field"></div>
            <div class="d-flat-card__price skeleton-field"></div>
          </div>
          <div class="d-flat-card__count skeleton-field"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flat-card__top" :class="{ _short: short }">
        <div class="d-flat-card__title">{{ data.title }}</div>
        <div class="d-flat-card__position">
          <div class="d-flat-card__object">{{ data.project }}</div>
          <template v-if="data.quarter">{{ data.quarter }},</template>
          Корпус {{ data.building }},
          этаж {{ floorStr }}
        </div>
        <div class="d-flat-card__bottom _type-single">
          <div class="d-flat-card__info" :data-price-request="data.price_request">
            <div class="d-flat-card__square">от {{ data.area_from }} м²</div>
            <div v-if="data.price_request && data.price_request_label" class="d-flat-card__price" v-html="data.price_request_label">
            </div>
            <div v-else class="d-flat-card__price">от {{ data.price_from.toLocaleString('ru-RU') }} ₽</div>
          </div>
          <div class="d-flat-card__count d-button _dark">
            <span>{{ totalSingleTitle }}</span>
          </div>
        </div>
      </div>
      <div v-if="!short" class="d-flat-card__content">
        <div class="d-flat-card__img">
          <img :src="data.plan">
        </div>
        <div class="d-flat-card__bottom">
          <div class="d-flat-card__info" :data-price-request="data.price_request">
            <div class="d-flat-card__square">от {{ data.area_from }} м²</div>
            <div v-if="data.price_request && data.price_request_label" class="d-flat-card__price" v-html="data.price_request_label">
            </div>
            <div v-else class="d-flat-card__price">от {{ data.price_from.toLocaleString('ru-RU') }} ₽</div>
          </div>
          <div class="d-flat-card__count d-button _dark">
            <span v-if="mod === 'single'">{{ totalSingleTitle }}</span>
            <span v-else>{{ totalTitle }}</span>
          </div>
        </div>
      </div>
      <a class="d-flat-card__link" href="/" target="_blank" @click.prevent="openType"></a>
    </template>
  </div>
</template>
<script>
import declOfNum from "@utils/declOfNum";
import additionalClassCSS from '@utils/additionalClassCSS';
export default {
  name: "DChooseTypeCard",
  props: {
    data: Object,
    mod: {
      type: [Array, String],
      default: '',
    },
    short: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
    totalTitle() {
      return `${this.data.flats_total} ${declOfNum(this.data.flats_total, ['предложение', 'предложения', 'предложений'])}`
    },
    totalSingleTitle() {
      return `${this.data.flats_total} ${declOfNum(this.data.flats_total, ['квартира', 'квартиры', 'квартир'])}`
    },
    floorStr() {
      if (this.data.floors[0] === this.data.floors[1]) return `${this.data.floors[0]}`
      else return `${this.data.floors[0]}-${this.data.floors[1]}`
    }
  },
  methods: {

    openType() {
      this.$emit('openType', this.data)
    }
  }
}
</script>

<style scoped>

</style>
