<template>
  <div v-for="n in count" :key="n" class="d-news-item _skeleton">
    <div class="d-news-item__image">
      <div class="skeleton-field"></div>
    </div>
    <div class="d-news-item__content">
      <div class="d-news-item__likes skeleton-field"></div>
      <div class="d-news-item__name">
        <div class="skeleton-field"></div>
        <div class="skeleton-field"></div>
      </div>
      <div class="d-news-item__more skeleton-field"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DNewsSkeleton',
  props: {
    count: {
      type: Number,
      default: 5
    }
  }
};
</script>

<style scoped>

</style>
