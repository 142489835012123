<template>
  <teleport to="#parking-search-filter">
    <d-search-filter-wrapper v-bind="{ ...$props, ...$attrs }" mod="parking" :filterParamsApi="filterParamsApi" :filterSpecsApi="filterSpecsApi" @update:filter="updateFilter"></d-search-filter-wrapper>
  </teleport>
  <teleport to="#parking-search-controls">
    <d-search-controls-wrapper :tags="tags" @reset:tag="resetFilter"/>
  </teleport>
  <d-search-tags v-if="loaded" :tags="tags" @reset:tag="resetFilter"/>
  <d-search-filter-empty v-if="loaded && dataLoaded && !errored && !loading && !result.total" @reset="resetFilters"/>
  <d-search-filter-error v-else-if="errored"/>
  <d-search-result-wrapper v-else :favorites="favorites" @changePage="changePage" mod="carPlace" @set-favorite="setFavorite"/>

  <teleport to=".d-aux-rooms__content">
    <button class="d-choose-params__buttonFilter _fixed" :class="{ _show: filterOutOfView && !callMeFormIsVisible }" @click="setShowFilter">
      <img src="/assets/blueant/assets/img/d/icon_filter.svg"/><span>Фильтр</span>
    </button>
  </teleport>
  <teleport to="body">
    <notifications position="top right" animation-type="velocity" :max="3" :reverse="true" :ignoreDuplicates="true" :pauseOnHover="true" :closeOnClick="false" :duration="notificationDuration">
      <template #body="props">
        <d-notification-item v-bind="{...props}"/>
      </template>
    </notifications>
  </teleport>
  <teleport to="body">
    <d-parking-place-popup v-if="showItemPopup" :favorites="favorites" @close="showItemPopup=false" @set-favorite="setFavorite" mod="carPlace"/>
  </teleport>

</template>

<script>
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore, useTagsStore, useItemStore} from '@/stores/search';

import {isEqual as _isEqual, omit as _omit, debounce as _debounce} from 'lodash';
import {buildParams} from '@utils/getSearchUrl';

import DSearchFilterWrapper from '@components/organisms/DSearchFilterWrapper.vue';
import DSearchControlsWrapper from '@components/organisms/DSearchControlsWrapper.vue';
import DSearchResultWrapper from '@components/organisms/DSearchResultWrapper.vue';
import DSearchTags from '@components/organisms/DSearchTags.vue';
import DNotificationItem from '@components/atoms/DNotificationItem.vue';
import DSearchFilterEmpty from '@components/molecules/DSearchFilterEmpty.vue';
import DSearchFilterError from '@components/molecules/DSearchFilterError.vue';
import DParkingPlacePopup from '@components/molecules/DParkingPlacePopup.vue';

const multipleCountStrings = ['Найдено', 'Найдено', 'Найдено'];
const multipleResultStrings = ['машиноместо', 'машиноместа', 'машиномест'];

export default {
  name: 'DParkingSearch',
  components: {
    DSearchFilterWrapper,
    DParkingPlacePopup, DSearchFilterError, DSearchFilterEmpty, DNotificationItem, DSearchControlsWrapper, DSearchResultWrapper, DSearchTags,
  },
  props: {
    jsonApi: {
      type: String,
      default: '/assets/json/data.json',
    },
    parkJsonApi: {
      type: String,
      default: '/assets/json/data_park.json',
    },
    filterParamsApi: {
      type: String,
      default: '/assets/json/parking-search-params.json',
    },
    filterSpecsApi: {
      type: String,
      default: '/assets/json/parking-search-specs.json',
    },
    switchContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filtersEl: null,
      controlsEl: null,
      callMeFormIsVisible: false,
      favorites: null,
    };
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
    this.getData();
  },
  mounted() {
    this.checkScreenSize();
    this.checkView();
    this.recentItems = JSON.parse(localStorage.getItem('recentParking') || '[]');
    this.multipleCountStrings = multipleCountStrings;
    this.multipleResultStrings = multipleResultStrings;
    //this.fixedBtnFilterHandler()
    this.checkVisibleCallmeForm();

    this.favorites = window.getFavorites();
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  setup() {
    const itemStore = useItemStore();

    return {itemStore};
  },
  computed: {
    ...mapWritableState(useTagsStore, [
      'resetFilterTag',
    ]),
    ...mapState(useTagsStore, [
      'tags',
    ]),
    ...mapState(useItemStore, [
      'item',
    ]),
    ...mapWritableState(useItemStore, [
      'recentItems',
      'showItemPopup',
    ]),
    ...mapWritableState(useSearchStore, [
      'result',
      'screenTablet',
      'screenPhone',
      'showFilter',
      'resetFilterFlag',
      'loading',
      'errored',
      'loaded',
      'dataLoaded',
      'filters',
      'showMore',
      'page',
      'items',
      'data',
      'showItems',
      'multipleCountStrings',
      'multipleResultStrings',
    ]),
    ...mapState(useSearchStore, [
      'filters',
      'filterParams',
      'initialFilterParams',
      'display',
      'sort',
      'notificationDuration',
      'filterOutOfView',
    ]),
  },
  watch: {
    showFilter(show) {
      if (show) this.filterChanged = false;
      else if (this.screenTablet && this.filterChanged) this.scrollTo('.d-aux-rooms__list', -72);
    },
    display() {
      this.checkView();
    },
    'sort.selected': {
      deep: true,
      handler() {
        this.setLoading(true, this);
        this.updateFilter();
      },
    },
    showItems() {
      if (this.showItems && (this.showItems.length || this.loaded)) this.setLoading(false, this);
    },
    loaded(value) {
      if (value) this.loadItems(false);
    },
    dataLoaded(value) {
      if (value) this.loadItems(false);
    },
    item: {
      deep: true,
      handler(value) {
        if (value !== null) {
          this.showItemPopup = true;
          this.itemStore.addRecent(this.item, 'Parking');
        }
      },
    },
  },
  methods: {
    scrollTo(elementId, offset = -250) {
      this.$smoothScroll({
        scrollTo: document.querySelector(elementId),
        updateHistory: false,
        duration: 500,
        offset,
      });
    },
    setShowFilter() {
      this.scrollTo('#parking-search-filter');
      if (this.screenTablet) {
        this.showFilter = false;
        this.$nextTick(function() {
          this.showFilter = true;
        });
      }
    },
    checkScreenSize() {
      this.screenTablet = window.matchMedia('(max-width: 1279px)').matches;
      this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
    },
    checkView() {
      (this.display === 'card') ? this.$el.parentNode.classList.remove('_list') : this.$el.parentNode.classList.add('_list');
    },
    saveStateToURL: _debounce(async function(page = null) {
      if (!this.loaded) return;
      if (page === null) page = this.result.page;
      let params = {};
      if (page !== 0) params.page = page;
      if (!_isEqual(this.filterParams, this.initialFilterParams)) params = Object.assign(params, this.filterParams);
      if (!_isEqual(this.sort.selected, this.sort.options[0])) params = Object.assign(params, _omit(this.sort.selected, ['title', 'id']));
      const url = new URL(location.href);
      Object.keys(params).map(key => {if (!params[key]) delete params[key]; });
      const queryStr = buildParams(params);
      url.search = new URLSearchParams(queryStr);
      history.pushState(null, '', url);
    }, 250),
    resetFilter(tag) {
      console.time('resetFilter');
      this.loading = true;
      console.timeLog('resetFilter');
      this.resetFilterTag = tag;
      console.timeEnd('resetFilter');
    },
    resetFilters() {
      this.loading = true;
      this.resetFilterFlag += 1;
    },
    async changePage(num) {
      if (!this.loaded) return;
      await this.saveStateToURL(num);
      this.page = num;
      this.loadItems();
    },
    async updateFilter(closeFilter = false) {
      if (!this.loaded) return;
      this.showMore = false;
      if (!localStorage.getItem('setPage')) this.page = 0;
      localStorage.removeItem('setPage');
      this.saveStateToURL();
      this.loadItems(this.showFilter);
      if (closeFilter) this.showFilter = false;
    },
    loadItems(scroll = true) {
      if (!this.loaded) return;
      if (!this.showMore) {
        if (scroll) this.scrollTo('.d-aux-rooms__list', -120);
        this.showItems = this.result.items;
      }
      else this.showItems = this.showItems.concat(this.result.items);
      this.setLoading(false, this);
    },
    async getData() {
      try {
        const response = await this.axios.get(this.jsonApi);
        this.data = response.data;

        const response2 = await this.axios.get(this.parkJsonApi);
        this.data.types = response2.data.types;
        this.data.floors = response2.data.floors;
        this.items = Object.values(response2.data.apartments);
        this.dataLoaded = true;

      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
      }
    },
    setLoading: _debounce(function(value, self) {
      self.loading = value;
    }, 250),
    checkVisibleCallmeForm() {
      const targetCallmeForm = document.querySelector('.d-still-questions');
      const options = {
        // rootMargin: '0',
        threshold: 0.01,
      };
      const callback = (entries, observer) => {
        this.callMeFormIsVisible = entries[0].isIntersecting === true;
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(targetCallmeForm);
    },
    async setFavorite(data) {
      if (!window.inFavorites(data.id)) {
        await window.addToFavorites(data.id);
        if (data.show) {
          if (window.inFavorites(data.id)) this.$notify({
            text: data.title,
            data: {
              message: 'Добавлено в Избранное',
              price: data.price,
              title: data.title,
            },
          });
          else this.$notify({
            text: data.title,
            type: 'warn',
            data: {
              message: 'Не удалось добавить в избранное',
              title: data.title,
            },
          });
        }
      }
      else await window.removeFromFavorites(data.id);
      this.favorites = window.getFavorites();
    },
  },
};
</script>
