<template>
  <div>
    <div class="d-flat-list _type" :class="classList">
      <template v-if="mod === 'typeSkeleton'">
        <div class="d-flat-list__item _item1">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item2">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item3">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item4">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item5">
          <div class="skeleton-field"></div>
        </div>
      </template>
      <template v-else>
        <div class="d-flat-list__item _item1">
          <img :src="data.plan">
        </div>
        <div class="d-flat-list__item _item2">
          <div class="d-flat-list__label">Комнат</div> <span v-html="roomsTitle()"></span>
        </div>
        <div class="d-flat-list__item _item3">
          <div class="d-flat-list__object">{{ data.project }}<small v-if="data.quarter">{{ data.quarter }}</small>
          </div>
        </div>
        <div class="d-flat-list__item _item4">
          <div class="d-flat-list__label">Этаж</div> {{ floorStr }}
        </div>
        <div class="d-flat-list__item _item5">
          <div class="d-flat-list__label">Площадь, м²</div>от {{ data.area_from }}
        </div>
        <div class="d-flat-list__item _item6" :data-price-request="data.price_request">
          <div v-if="data.price_request && data.price_request_label" class="d-flat-list__price _request" v-html="data.price_request_label" />
          <div v-else class="d-flat-list__price">от {{ data.price_from.toLocaleString('ru-RU') }} ₽</div>
        </div>
        <div class="d-flat-list__item _item7">
          <div class="d-flat-list__count d-button _dark">
            <span>{{ data.flats_total }} {{ totalTitle }}</span>
          </div>
        </div>
        <a class="d-flat-list__link" href="/" target="_blank" @click.prevent="openType"></a>
      </template>
    </div>

    <div class="d-flat-list _mobile _type" :class="classList">
      <template v-if="mod === 'typeSkeleton'">
        <div class="d-flat-list__top">
          <div class="d-flat-list__img skeleton-field"></div>
          <div class="d-flat-list__info skeleton-field"></div>
          <div class="d-flat-list__object skeleton-field"></div>
        </div>
        <div class="d-flat-list__bottom">
          <div class="d-flat-list__price skeleton-field"></div>
          <div class="d-flat-list__count skeleton-field"></div>
        </div>
      </template>
      <template v-else>
        <div class="d-flat-list__top">
          <div class="d-flat-list__img">
            <img :src="data.plan">
          </div>
          <div class="d-flat-list__info">{{ roomsTitle(true) }} от {{ data.area_from }} м²</div>
          <div class="d-flat-list__object">{{ data.project }}</div>
        </div>
        <div class="d-flat-list__bottom" :data-price-request="data.price_request">
          <div v-if="data.price_request && data.price_request_label" class="d-flat-list__price _request" v-html="data.price_request_label" />
          <div v-else class="d-flat-list__price">от {{ shortPrice }} млн ₽</div>
          <div class="d-flat-list__count">
            <span>{{ data.flats_total }}</span> {{ totalTitle }}
          </div>
        </div>
        <a class="d-flat-list__link" href="/" @click.prevent="openType"></a>
      </template>
    </div>
  </div>
</template>

<script>
import declOfNum from "@utils/declOfNum";
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: "DChooseTypeRow",
  props: {
    data: Object,
    mod: {
      type: [Array, String],
      default: '',
    },
  },
  computed: {
    totalTitle() {
      return declOfNum(this.data.flats_total, ['предложение', 'предложения', 'предложений'])
    },
    shortPrice() {
      return parseFloat((this.data.price_from / 1000000).toFixed(1));
    },
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
    floorStr() {
      if (this.data.floors[0] === this.data.floors[1]) return `${this.data.floors[0]}`
      else return `${this.data.floors[0]}-${this.data.floors[1]}`
    }
  },
  methods: {
    openType() {
      this.$emit('openType', this.data)
    },
    roomsTitle(mobile = false) {
      if (this.data.isPenthouse) {
        return 'Пентхаус'
      }
      if (this.data.rooms === 0) {
        return 'Студия'
      }

      if (mobile) return `${this.data.rooms}-комн.`

      return `${this.data.rooms} <small>${declOfNum(this.data.rooms, ['комната', 'комнаты', 'комнат'])}</small>`
    },
  }
}
</script>

<style scoped>

</style>
