<template>
  <div class="flat-card-chess">
    <button class="flat-card-chess__close d-button _close _blue" @click="$emit('close')">
      <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
    </button>
    <div class="flat-card-chess__img _row">
      <img :src="data.plan" alt="">
    </div>
    <div class="flat-card-chess__inner">
      <div class="flat-card-chess__top">
        <div class="flat-card-chess__title" v-html="itemTitle"></div>
        <div v-if="'labels' in data" class="flat-card-chess__actions">
          <div v-for="label in data.labels" class="d-flat-action flat-card-chess__action" :style="{color: label.color, backgroundColor: label.background_color}">
            <div class="d-flat-action__name">{{ label.title }}</div>
          </div>
        </div>
      </div>
      <div class="flat-card-chess__img _column">
        <img :src="data.plan"  alt="">
      </div>
      <div v-if="data.project" class="flat-card-chess__project">{{ data.project }}</div>
      <div class="flat-card-chess__info">{{ data.location }}</div>
      <div class="flat-card-chess__bottom">
        <div class="flat-card-chess__area">{{ data.area }} м²</div>
        <div class="flat-card-chess__price-wrap">
          <div v-if="data.price_request && data.price_request_label" class="flat-card-chess__price _price-request">{{ data.price_request_label }}</div>
          <template v-else>
            <div v-if="data.oldPrice" class="flat-card-chess__price _old">{{ data.oldPrice.toLocaleString('ru-RU') }} ₽</div>
            <div class="flat-card-chess__price">{{ data.price.toLocaleString('ru-RU') }} ₽</div>
          </template>
        </div>
      </div>
      <a :href="data.url" class="flat-card-chess__btn d-button _dark _small">
        <span>Перейти к квартире</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DFlatCardChess",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    itemTitle() {
      if ('is_new' in this.data && this.data.is_new) return `${this.data.title} <span class="flat-card-chess__sticker _new">new</span>`
      return this.data.title
    }
  }
}
</script>
