<template>
  <teleport to="body">
    <d-popup v-slot="{ close }" v-bind="$attrs" @closed="$emit('closed')">
      <div class="d-disclaimer-popup">
        <div v-if="dataTitle" class="d-disclaimer-popup__title" v-html="dataTitle"></div>
        <div v-if="dataText" class="d-disclaimer-popup__text" data-simplebar>
          <div class="d-disclaimer-popup__textInner" v-html="dataText" />
        </div>
      </div>
    </d-popup>
  </teleport>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';

export default {
  name: 'DPopupAgreement',
  components: { DPopup },
  created() {
      this.dataTitle =  this.$attrs.agreementTitle
      this.dataText = this.$attrs.agreementHtml
  },
};
</script>
