function menuItems() {
    let menuLinks = $('[data-links]');
    let items = $('.menu__item');
    let col1 = $('.menu__item._item1, .menu__item._item2, .menu__item._item4');
    let col2 = $('.menu__item._item3, .menu__item._item5, .menu__item._item6');
    if (window.matchMedia('(max-width: 1279px)').matches) {
        if ($('.menu__col').length === 0) {
            menuLinks.prepend('<div class="menu__col _col1"></div><div class="menu__col _col2"></div>');
            $(col1).remove();
            $(col2).remove();
            setTimeout(function() {
                col1.each(function(i) {
                    $('.menu__col._col1').append(col1[i]);
                });
                col2.each(function(i) {
                    $('.menu__col._col2').append(col2[i]);
                });
            }, 50);
        }
    }
    else {
        $('.menu__col').remove();
        items.each(function(i) {
            menuLinks.append(items[i]);
        });
    }
}

function menuItem() {
    let $item = $('.d-footer .d-menu-links__item._item4');
    if (window.matchMedia('(max-width: 1279px)').matches) {
        $item
          .remove()
          .addClass('_tablet');
        $('.d-footer .d-menu-links').append($item);
    }
    else {
        $('.d-menu-links__item._item4._tablet')
          .remove()
          .removeClass('_tablet');;
        $('.d-footer .d-menu-links__item._item2').append($item);
    }
}
menuItem();

function menuSocialWidth() {
    let width = $('.d-menu-links._header .d-menu-links__item._item5').outerWidth();
    $('[data-menu-social]').css('width', width);
}

global.menuOnOff = window.menuOnOff = function menuOnOff(open = null) {
    const burger = $('[data-burger]');
    burger.toggleClass('_active', open);
    if (burger.hasClass('_active')) {
        document.querySelector('body').classList.add('_menuOpen');
    }
    else {
        document.querySelector('body').classList.remove('_menuOpen');
    }
    $('[data-menu]').toggleClass('_open', open);
    $('[data-header]').toggleClass('_menuOpen', open);
    menuSocialWidth();
}

$(document).on('click', '[data-burger]', menuOnOff);

$(window).on('load', menuItems)

$(window).on('resize', function() {
    menuItem();
    menuItems();
    menuSocialWidth();
});
$(document).on('keyup', function(e) {
    if(e.key && e.key.toLowerCase() === 'escape') menuOnOff(false);
});

$(document).on('click', '[data-menu-section]', function(e) {
    if (window.matchMedia('(max-width: 767px)').matches) {
        e.preventDefault();
        $('[data-menu-item]').toggleClass('_hide');
        $(this).parent().removeClass('_hide').toggleClass('_active');
    }
});
