<template>
  <div class="d-object-contacts-qr-popup">
    <div class="d-object-contacts-qr-popup__img">
      <img :src="data.qr.img" alt="">
    </div>

    <template v-if="typeForm === 'sms'">
      <div class="d-object-contacts-qr-popup__body">
        <button class="d-object-single-easy-to-buy-address__back" @click="back">
          <svg>
            <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-arrow'></use>
          </svg>
          Назад
        </button>
        <form class="d-object-contacts-qr-popup__form" action="" @submit.prevent="submitSmsForm">
          <div class="d-input d-object-contacts-qr-popup__inp" :class="{ _error: v$.smsForm.phone.$errors.length }">
            <div class="d-input__error" v-for="(error, index) of v$.smsForm.phone.$errors" :key="index">{{ error.$message }}</div>
            <d-phone-input mod="qr" @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
          </div>
          <button class="d-object-contacts-qr-popup__submit d-button _blue" :class="{_disable: v$.smsForm.$invalid}" @click.prevent="submitSmsForm"><span>Отправить</span></button>
          <d-recaptcha-info :errored="errored" :error-text="errorText" />
        </form>
      </div>
    </template>
    <template v-else-if="typeForm === 'email'">
      <div class="d-object-contacts-qr-popup__body">
        <button class="d-object-single-easy-to-buy-address__back" @click="back">
          <svg>
            <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-arrow'></use>
          </svg>
          Назад
        </button>
        <form class="d-object-contacts-qr-popup__form" action="" @submit.prevent="submitEmailForm">
          <div class="d-input d-object-contacts-qr-popup__inp" :class="{ _error: v$.emailForm.email.$errors.length }">
            <input ref="email" type="email" name="email" placeholder="Ваш email*" class="d-input__field" v-model="v$.emailForm.email.$model" @blur="v$.emailForm.email.$touch"
                   :disabled="loading" inputmode="email" :class="{ _filled: emailForm.email > ''}">
            <div class="d-input__error" v-for="(error, index) of v$.emailForm.email.$errors" :key="index">{{ error.$message }}</div>
          </div>
          <button class="d-object-contacts-qr-popup__submit d-button _blue" :class="{_disable: v$.emailForm.$invalid}" @click.prevent="submitEmailForm"><span>Отправить</span></button>
          <d-recaptcha-info :errored="errored" :error-text="errorText" />
        </form>
      </div>
    </template>

    <template v-else>
      <template v-if="formSuccess">
        <div class="d-object-contacts-qr-popup__body">
          <div class="d-object-contacts-qr-popup__title">Маршрут отправлен!</div>

          <div class="d-object-contacts-qr-popup__buttons _success">

            <button class="d-object-contacts-qr-popup__success-btn d-button _blue" @click="closeSuccess">
              <span>Хорошо, спасибо!</span>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-object-contacts-qr-popup__body">
          <div class="d-object-contacts-qr-popup__title">Отправить маршрут </div>
          <div class="d-object-contacts-qr-popup__text">Считайте код слева, чтобы построить маршрут до офиса продаж. Также мы можем отправить вам ссылку на e-mail  SMS.</div>
          <div class="d-object-contacts-qr-popup__buttons">
            <button class="d-button _dark _small" @click="openForm('sms')">
              <span>Отправить SMS</span>
            </button>
            <button class="d-button _dark _small" @click="openForm('email')">
              <span>Отправить e-mail</span>
            </button>
          </div>
        </div>
      </template>

    </template>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, email, helpers} from '@vuelidate/validators';
import phoneHelper from "@utils/phoneHelper";
import DPhoneInput from "@components/molecules/DPhoneInput.vue";
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: "DMapContactsOfficeQrPopup",
  components: {
    DRecaptchaInfo,
    DPhoneInput
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    routeData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      typeForm: null,
      smsForm: {
        phone: '',
      },
      emailForm: {
        phone: '',
      },
      loading: false,
      formSuccess: false,
      phoneParams: {
        mask: '',
        code: ''
      },
      reset: false,
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  computed: {
    hiddenInputs() {
      if (!this.data) return null
      return this.data.qrForm.inputs
    },
    urlForm() {
      if (!this.data) return null
      return this.data.qrForm.url
    }
  },
  mounted() {

  },
  methods: {
    openForm(type) {
      this.typeForm = type
    },
    submitSmsForm() {
      this.v$.smsForm.$touch();

      if (!this.v$.smsForm.$invalid) {
        let formData = new FormData();
        this.hiddenInputs.forEach((input) => {
          formData.append(input.name, input.value)
        })

        formData.append('data', this.smsForm.phone)
        formData.append('type', this.typeForm)
        if (this.routeData) {
          formData.append('route_from', this.routeData.from)
          formData.append('route_to', this.routeData.to)
        }

        this.sendData(formData)
      }

    },
    submitEmailForm() {
      this.v$.emailForm.$touch();

      if (!this.v$.emailForm.$invalid) {
        let formData = new FormData();
        this.hiddenInputs.forEach((input) => {
          formData.append(input.name, input.value)
        })

        formData.append('data', this.emailForm.email)
        formData.append('type', this.typeForm)
        if (this.routeData) {
          formData.append('route_from', this.routeData.from)
          formData.append('route_to', this.routeData.to)
        }


        this.sendData(formData)
      }
    },
    async sendData(formdata) {
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        formdata.append('url', window.location.href);
        formdata.append('token', await this.$recaptcha(this.dataType));
        const response = await this.axios.post(this.urlForm, formdata);
        if (response.data.status === 'error') {
          this.errored = true;
          this.errorText = response.data.mess;
        }
        else if (response.data.status === 'success') {
          this.resetForm();
          this.formSuccess = true
          this.typeForm = null
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.smsForm.$reset();
      this.smsForm.phone = '+7'

      this.v$.emailForm.$reset();
      this.emailForm.email = ''
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    closeSuccess() {
      this.$emit('success')
      this.typeForm = null;
      this.resetForm();
      setTimeout(() => {
        this.formSuccess = false;
      }, 400)
    },
    back() {
      this.typeForm = null;
      this.resetForm()
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.smsForm.phone = phone;
    },
    blurInpPhone() {
      this.v$.smsForm.phone.$touch()
      console.log('touch phone')
    }
  },
  validations() {
    return {
      smsForm: {
        phone: {
          required: helpers.withMessage('Заполните это поле', required),
          phoneHelper: helpers.withMessage('Введите номер полностью', phoneHelper(this.phoneParams)),
        },
      },
      emailForm: {
        email: {
          required: helpers.withMessage('Заполните это поле', required),
          emailHelper: helpers.withMessage('Введите корректный email', email),
        },
      },
    };
  },
}
</script>

<style scoped>

</style>
