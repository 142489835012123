<template>
  <div ref="target" class="d-search-notify" :style="{ left, opacity }" :class="{ '_active': !isSwiping }">
    <div class="d-search-notify__message" :class="{ '_warn': props?.item?.type === 'warn'}">
      {{ props?.item?.data?.message }}
    </div>
    <div class="d-search-notify__title" v-html="props?.item?.data?.title"/>
    <div class="d-search-notify__price" v-html="props?.item?.data?.price"/>
    <button class="d-search-notify__close" @click="props?.close">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
      </svg>
    </button>
  </div>
</template>


<script>
export default {
  name: 'DNotificationItem',
};
</script>
<script setup>
import {computed, ref} from 'vue';
import {usePointerSwipe} from '@vueuse/core';

const props = defineProps({
  close: {
    type: Function,
    default: () => {},
  },
  item: {
    type: Object,
    default: () => {},
  },
  class: {
    type: String,
    default: '',
  }
});

const target = ref(null);

const containerWidth = computed(() => target.value?.offsetWidth);

const left = ref('0');
const opacity = ref(1);

const reset = () => {
  left.value = '0';
  opacity.value = 1;
};

const {distanceX, isSwiping} = usePointerSwipe(target, {
  onSwipe(e) {
    if (containerWidth.value) {
      if (distanceX.value < 0) {
        const distance = Math.abs(distanceX.value);
        left.value = `${ distance }px`;
        opacity.value = 1.25 - distance / containerWidth.value;
      }
      else {
        left.value = '0';
        opacity.value = 1;
      }
    }
  },
  onSwipeEnd(e, direction) {
    if (distanceX.value < 0 && containerWidth.value && (Math.abs(distanceX.value) / containerWidth.value) >= 0.5) {
      left.value = '100%';
      opacity.value = 0;
      props.close();
    }
    else {
      left.value = '0';
      opacity.value = 1;
    }
  },
});

</script>

<style scoped>

</style>
