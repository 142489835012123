<template>
  <div>
    <d-search-filter-skeleton v-show="!screenTablet && !loaded" class="skeleton"/>
    <template v-if="loaded && !screenTablet">
      <d-search-filter :mod="mod" @reset:filter="resetFilterFlag++" @update:filter="updateFilters"/>
    </template>
    <template v-else-if="loaded">
      <teleport to="body">
        <d-popup v-model="showFilterInternal" v-slot="{ close }" v-bind="$attrs" :content-class="'search-filter'">
          <d-search-filter :mod="mod" @reset:filter="resetFilterFlag++" @update:filter="updateFilters" :close="!showFilterInternal" @close:filter="$emit('update:filter', true)"/>
        </d-popup>
      </teleport>
    </template>
  </div>
</template>

<script>
import {toRaw} from 'vue';
import {isEqual as _isEqual, debounce as _debounce, omit as _omit} from 'lodash';
import {useWindowSize} from '@vueuse/core';
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore} from '@/stores/search';
import getSearchUrl from '@utils/getSearchUrl';

import DPopup from '@components/molecules/DPopup.vue';
import DSearchFilterSkeleton from '@components/molecules/DSearchFilterSkeleton.vue';
import DSearchFilter from '@components/organisms/DSearchFilter.vue';

export default {
  name: 'DSearchFilterWrapper',
  components: {DSearchFilter, DSearchFilterSkeleton, DPopup},
  inheritAttrs: false,
  emits: ['loaded', 'update:filter'],
  props: {
    mod: {
      type: String,
      default: '',
    },
    filterParamsApi: {
      type: String,
      default: '/assets/json/parking-search-params.json',
    },
    filterSpecsApi: {
      type: String,
      default: '/assets/json/parking-search-specs.json',
    },
  },
  data() {
    return {};
  },
  created() {
    this.initFilters();
  },
  mounted() {
    addEventListener('popstate', this.preFillFilter);
  },
  unmounted() {
    removeEventListener('popstate', this.preFillFilter);
  },
  computed: {
    showFilterInternal: {
      get() {
        return this.showFilter;
      },
      set() {
        this.showFilter = false;
      },
    },
    ...mapWritableState(useSearchStore, [
      'showFilter',
      'resetFilterFlag',
      'loading',
      'loaded',
      'errored',
      'filters',
      'filterParams',
      'initialFilterParams',
      'facetsInitial',
      'facets',
      'sort',
      'notificationDuration',
      'page',
    ]),
    ...mapState(useSearchStore, [
      'result',
      'screenTablet',
      'screenPhone',
    ]),
  },
  methods: {
    async initFilters() {
      const specs = await this.getSpecs();
      if (!specs) {
        return;
      }

      const facets = await this.getFacets();
      if (!facets) {
        return;
      }

      this.initFilterParams();
      const presetFilterData = await this.preFillFilter();
      if (Object.keys(presetFilterData).length) {
        await this.updateFilters({params: Object.assign({}, this.filterParams, presetFilterData), reload: true});
      }
      else
        this.loaded = true;
      //await this.$nextTick(() => {
      //  window.tabsWidthStart();
      //});
    },
    updateFilters: _debounce(async function(data) {
      if (_isEqual(toRaw(this.filterParams), toRaw(data.params)) && data.updateData) {
        return this.$emit('update:filter', false);
      }
      if (data.updateData) this.setLoading(true, this);
      if (data.reload) {
        const facets = await this.getFacets(data.params);
        if (!facets) {
          this.errored = true;
          return;
        }
        // глобальные параметры фильтра, выставляются только после того, как пришли новые фасеты
      }
      this.filterParams = JSON.parse(JSON.stringify(data.params));
      this.loaded = true;
      this.setLoading(false, this);
      if (data.updateData) this.$emit('update:filter', false);
    }, 500),
    async getSpecs() {
      try {
        const {width} = useWindowSize();
        let response = await this.axios.post(this.filterSpecsApi, {screen: width._value});
        const filters = response.data.specs;
        if ('type' in filters) filters.type = filters.type.map(el => {
          el.type = 'type';
          return el;
        });
        if ('level' in filters) filters.level = filters.level.map(el => {
          el.type = 'level';
          return el;
        });
        if ('object' in filters) filters.object = filters.object.map(el => {
          el.type = 'object';
          return el;
        });
        if ('quarter' in filters) filters.quarter = filters.quarter.map(el => {
          el.type = 'quarter';
          return el;
        });
        if ('building' in filters) filters.building = filters.building.map(el => {
          el.type = 'building';
          return el;
        });
        this.filters = filters;

        this.notificationDuration = response.data.notification_duration * 1000;

        this.per_page = response.data.per_page;

        this.sort = Object.assign({}, response.data.sort, {
          selected: response.data.sort.options[0],
        });
        if (!this.loaded) {
          this.initialFilterParams.price = [filters.price.min, filters.price.max]; // берём допустимые цены из спецификации
          if ('area' in filters) this.initialFilterParams.area = [filters.area.min, filters.area.max];
          if ('type' in filters && this.mod === 'storeroom') this.initialFilterParams.type = null;
        }
        return this.filters;
      } catch (err) {
        console.error(`%c${ this.$options.name } getSpecs() error load getSpecs result`, 'color: yellow', err);
        this.errored = true;
      }
      return false;
    },
    initFilterParams() {
      this.filterParams = JSON.parse(JSON.stringify(toRaw(this.initialFilterParams)));
    },
    async preFillFilter() {
      let presetFilterData = {};
      if (location.search) {
        try {
          presetFilterData = getSearchUrl();
          const arrayKeys = this.mod === 'storeroom'
                            ? ['level', 'building', 'object', 'quarter', 'price', 'area']
                            : ['type', 'level', 'building', 'object', 'quarter', 'price', 'area'];
          arrayKeys.forEach(key => {
            if (key in presetFilterData && !Array.isArray(presetFilterData[key])) presetFilterData[key] = [presetFilterData[key]];
          });
          const booleanKeys = ['charging', 'promotion'];
          booleanKeys.forEach(key => {
            if (key in presetFilterData) presetFilterData[key] = true;
          });
        } catch (e) {
          console.error(`%c${ this.$options.name } preFillFilter() Cannot parse initialFilter: `, 'color: yellow', e);
        }
      }
      else console.error(`%c${ this.$options.name } preFillFilter() initialFilter not yet provided; location.search = `, 'color: yellow', location.search);
      if (Object.keys(presetFilterData).length) {

        if ('open' in presetFilterData) delete presetFilterData.open;

        if ('page' in presetFilterData) {
          this.page = presetFilterData.page;
          localStorage.setItem('setPage', presetFilterData.page);
        }

        if ('sortValue' in presetFilterData && 'sortOrder' in presetFilterData) {
          const sortFromSearch = this.sort.options.find(option => option.sortValue === presetFilterData.sortValue && option.sortOrder === presetFilterData.sortOrder);
          if (sortFromSearch) this.sort.selected = sortFromSearch;
        }
        presetFilterData = _omit(presetFilterData, ['page', 'sortOrder', 'sortValue']);
      }
      return presetFilterData;
    },
    async getFacets(filterParams = null) {
      this.setLoading(true, this);
      try {
        const response = filterParams !== null
                         ? await this.axios.post(this.filterParamsApi, filterParams)
                         : await this.axios.get(this.filterParamsApi);

        if (filterParams === null) this.facetsInitial = response.data.facets;
        this.facets = response.data.facets;

        // filterParams — выставленное в фильтре
        // filters — описания фильтров
        // facets — допустимые при текущих filterParams значения фильтров

        return this.facets;
      } catch (err) {
        console.error(`%c${ this.$options.name } getFacets() error load getFacets result`, 'color: yellow', err);
        this.errored = true;
      }
      return false;
    },
    setLoading: _debounce(function(value, self) {
      self.loading = value;
    }, 250),
  },
};
</script>

<style scoped>
:deep(.search-filter) {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding-top: 72px;
}

:deep(.vfm__content.search-filter) {
  width: 100%
}

:deep(.search-filter .d-popup__close) {
  top: 12px;
  right: 24px;
}

@media (max-width: 767px) {
  :deep(.search-filter) {
    padding-top: 60px;
  }

  :deep(.search-filter .d-popup__close) {
    width: 32px !important;
    height: 32px;
    top: 14px;
    right: 12px;
  }
}
</style>
