<template>
  <div class="d-countdown__time _notify" v-html="title"></div>
</template>

<script>

export default {
  name: 'DNotify',
  props: {
    title: {
      type: String,
      default: "Новый пул квартир<br>в продаже"
    },
  },
};
</script>
