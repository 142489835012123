<template>
  <swiper v-if="floors.length" ref="slider" v-bind="swiperOpts" :initialSlide="currentFloorIndex" @init="onSwiper" @slideChange="chooseFloor" class="d-choose-floor__slider">
    <swiper-slide v-for="(floor, index) in floors"
                  :key="floor.id"
                  class="swiper-slide d-choose-floor__slide"
                  :class="{_vertical: floor.render.width/floor.render.height < 1, _unavail: !floor.flats.filter(flat => flat.available).length}"
                  :data-floor="floor.num"
                  :ref="`floor-slide-${index}`">
      <div class="d-choose-floor__floor-plan" :ref="`floor-plan-${index}`">
        <svg v-if="floor.render && floor.render.img"
             class="d-visual-genplan__svg"
             xmlns="http://www.w3.org/2000/svg"
             :id="`floor-plan-${index}`"
             :width="floor.render.width"
             :height="floor.render.height"
             :viewBox="`${floor.render.viewBox ? floor.render.viewBox : '0 0 '+floor.render.width + ' ' + floor.render.height}`">
          <path v-for="(flat, flatIndex) in floor.flats"
                :key="flat.id"
                :ref="setItemRef"
                fill="#ffffff"
                fill-opacity="0"
                :data-active="currentFloorIndex === index"
                :data-flat-index="flatIndex"
                :data-floor-index="index"
                class="d-visual-genplan__path _floor"
                :class="{ _empty: !flat.available, _onscreen: currentFloorIndex === index}"
                :d="flat.points ? flat.points : coords2poins(flat.coords, flat.shape)"
                @click="openPopup(`${index}-${flatIndex}`, flat.url)"/>
        </svg>
        <div :ref="setFloorRef" :data-floor="index" class="d-choose-floor__slide-labels">
          <template v-for="(flat, flatIndex) in floor.flats" :key="`flat-label-${flat.id}`" class="_label" :class="{ _unavail: !flat.available, _avail: flat.available}">
            <div v-if="flat.available" :id="`flat-label-${index}-${flatIndex}`" class="_label _avail" @click="openPopup(`${index}-${flatIndex}`, flat.url)">
              {{ flat.rooms ? flat.rooms : flat.penthouse ? 'Пентх.' : 'Ст.' }}<i></i>{{ flat.area }}
            </div>
            <div v-else :id="`flat-label-${index}-${flatIndex}`" class="_label _unavail">продано</div>
          </template>
        </div>
        <img v-if="floor.render && floor.render.img" :src="floor.render.img">
      </div>
    </swiper-slide>
  </swiper>
  <d-popup-simple v-if="currentPopupTemplate" v-model="showPopup" mod="bottom" :with-close="true" :hide-overlay="true">
    <div v-html="currentPopupTemplate"/>
  </d-popup-simple>
</template>

<script>
import tippy from 'tippy.js';
import {pick as _pick} from 'lodash';
import coords2path from '@utils/area2svg';
import getCentroid from '@utils/pathHelper';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';

export default {
  name: 'DPlanSlider',
  components: {Swiper, SwiperSlide, DPopupSimple},
  emits: ['changeFloor', 'setWidth'],
  props: {
    floors: {
      type: Array,
      default: () => [],
    },
    currentFloorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      itemRefs: [],
      floorRefs: [],
      flatsPopups: [],
      flatsTooltips: {},
      swiperOpts: {
        loopedSlides: this.floors.length,
        observer: true,
        slidesPerView: 1,
        spaceBetween: 150,
        speed: 750,
        watchOverflow: true,
        breakpoints: {
          1920: {
            allowTouchMove: true,
            direction: 'vertical',
            pagination: {
              type: 'custom',
              clickable: true,
              el: '.d-floor-slider__pagination._pagination',
              renderCustom: this.renderPagination,
            },
            navigation: {
              nextEl: '.d-floor-slider__control._next',
              prevEl: '.d-floor-slider__control._prev',
            },
          },
          1280: {
            allowTouchMove: true,
            direction: 'vertical',
            pagination: {
              type: 'custom',
              clickable: true,
              el: '.d-floor-slider__pagination',
              renderCustom: this.renderPagination,
            },
            navigation: {
              nextEl: '.d-floor-slider__control._next',
              prevEl: '.d-floor-slider__control._prev',
            },
          },
          768: {
            allowTouchMove: true,
            direction: 'horizontal',
            pagination: {},
            navigation: {},
          },
          0: {
            allowTouchMove: false,
            direction: 'horizontal',
            pagination: {},
            navigation: {},
          },
        },
      },
      slider: null,
      screenSize: 1920,
      showPopup: false,
      currentPopupTemplate: null,
    };
  },
  watch: {
    currentFloorIndex(newVal, oldVal) {
      this.slider.slideTo(newVal);
    },
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item);
    });
    if (this.slider) this.chooseFloor(this.slider);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  updated() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item);
    });
    this.$nextTick(() => {
      this.floorRefs.forEach(item => {
        this.setContainedSize(item);
      });
    });
  },
  computed: {
    visiblePagination() {
      return this.screenSize >= 1920 ? 7 : this.screenSize >= 1280 ? 6 : 0;
    },
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    floorsPager() {
      return [...this.floors].map((el, index) => {
        const floor = _pick(el, ['num', 'flats']);
        floor.index = index;
        floor.avail = floor.flats.filter((flat) => flat.available).length > 0;
        delete floor.flats;
        return floor;
      });
    },
  },
  methods: {
    onSwiper(swiper) {
      this.slider = swiper;
      //console.log(`%c${ this.$options.name } onSwiper() this.slider = `, 'color: yellow', this.slider);
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    setFloorRef(el) {
      if (el) {
        this.floorRefs[el.dataset.floor] = el;
      }
    },
    checkScreenSize() {
      this.screenSize = window.innerWidth;
    },
    setContainedSize(el) {
      if (!el) return;
      const planSlide = this.$refs[`floor-slide-${ el.dataset.floor }`][0].$el;
      const planWrapper = this.$refs[`floor-plan-${ el.dataset.floor }`][0];
      if (planWrapper) {
        const doRatio = this.floors[el.dataset.floor].render.width / this.floors[el.dataset.floor].render.height;
        const cRatio = planWrapper.offsetWidth / planWrapper.offsetHeight;
        let width = 0;
        let height = 0;
        if (doRatio > cRatio) {
          width = planWrapper.offsetWidth;
          height = width / doRatio;
        }
        else {
          height = planWrapper.offsetHeight;
          width = height * doRatio;
        }
        el.style.width = planWrapper.style.width = planWrapper.style.flexBasis = `${ width }px`;
        el.style.height = planWrapper.style.height = `${ height }px`;
        //if (planWrapper.offsetWidth > planSlide.offsetWidth) planSlide.scrollLeft = (planWrapper.offsetWidth - planSlide.offsetWidth) / 2;
        this.$emit('setWidth', width);
      }
    },
    coords2poins(coords, shape) {
      return coords2path(coords.split(',').map(function(num) { return Number(num);}), shape);
    },
    chooseFloor(swiper) {
      //console.log(`%c${ this.$options.name } chooseFloor() swiper.slides = `, 'color: yellow', swiper.slides);
      if (swiper.slides && Object.keys(swiper.slides).length) {
        const selectedFloorNum = swiper.slides[swiper.activeIndex].dataset.floor;
        this.$emit('changeFloor', selectedFloorNum);
        this.$nextTick(() => {
          //console.log(`%c${ this.$options.name } chooseFloor() this.floorRefs[${swiper.activeIndex}] = `, 'color: yellow', this.floorRefs[swiper.activeIndex]);
          this.setContainedSize(this.floorRefs[swiper.activeIndex]);
        });
      }
    },
    renderPagination(swiper, current, total) {
      if (!this.visiblePagination) return '';
      const index = current <= total / 2 ? current : total - current + 1;
      const visible = this.visiblePagination;
      let hideAtBottomStart, hideAtTopEnd;
      const visibleCenter = Math.ceil(visible / 2);
      const hideAtEdge = index <= visibleCenter ? 0 : index - visibleCenter;
      if (current > total / 2) {
        hideAtBottomStart = hideAtEdge;
        hideAtTopEnd = total - visible - hideAtBottomStart;
      }
      else {
        hideAtTopEnd = hideAtEdge;
        hideAtBottomStart = total - visible - hideAtTopEnd;
      }
      let customPaginationHtml = '';
      const bulletClass = 'swiper-pagination-bullet';
      const activeClass = `${ bulletClass } swiper-pagination-bullet-active`;
      const prevClass = `${ bulletClass } swiper-pagination-bullet-prev`;
      const hiddenClass = `${ bulletClass } swiper-pagination-bullet-hidden`;
      for (let i = 1; i <= total; i++) {
        const floorIndex = total - i + 1;
        customPaginationHtml += this.calcBulletHtml(
          i,
          current,
          total,
          floorIndex,
          swiper,
          hideAtTopEnd,
          hideAtBottomStart,
          bulletClass,
          activeClass,
          prevClass,
          hiddenClass,
        );
      }
      //console.log(`%c${ this.$options.name } renderPagination() current, total = `, 'color: yellow', current, total);
      //console.log(`%c${ this.$options.name } renderPagination() customPaginationHtml = `, 'color: yellow', customPaginationHtml);
      return customPaginationHtml;
    },
    calcBulletHtml(i, current, total, floorIndex, swiper, hideAtTopEnd, hideAtBottomStart, bulletClass, activeClass, prevClass, hiddenClass) {
      let setClass = hiddenClass;
      const hide = floorIndex <= hideAtBottomStart || floorIndex > total - hideAtTopEnd;
      if (i === current) {
        setClass = activeClass;
      }
      else if (!hide) {
        if (i === swiper.previousIndex + 1) setClass = prevClass;
        else setClass = bulletClass;
      }
      const floorDisplayNum = this.floorsPager[i - 1].num;
      setClass = this.floorsPager[i - 1].avail ? `${ setClass } _avail` : `${ setClass } _unavail`;
      return `<div role="button" class="${ setClass }" data-slider-id="${ i }" data-floor-index="${ floorIndex - 1 }" data-floor="${ floorDisplayNum }">${ floorDisplayNum }</div>`;
    },

    createTooltip(target) {
      if (target.dataset.active !== 'true') return;
      const flat = this.floors[target.dataset.floorIndex].flats[target.dataset.flatIndex];

      if (!target.dataset.hasLabel) {
        const floorLabel = document.getElementById(`flat-label-${ target.dataset.floorIndex }-${ target.dataset.flatIndex }`);
        if (!floorLabel) return;
        const centroid = getCentroid(target, this.floors[target.dataset.floorIndex].render.width, this.floors[target.dataset.floorIndex].render.height);
        floorLabel.style.top = centroid.y + '%';
        floorLabel.style.left = centroid.x + '%';

      }

      if (flat.available && !target.dataset.hasLabel) {
        let tplFlatLabels = ``;
        const isNewLabel = 'is_new' in flat && flat.is_new ? '<span class="d-visual-floor-info__sticker _new">new</span>': ''
        flat.labels.forEach(label => tplFlatLabels += `<div class="d-flat-action" style="color: ${ label.color }; background-color: ${ label.background_color }"><div class="d-flat-action__name">${ label.title }</div></div>`);

        let tplFlat = this.isTouchDevice ? `
<div class="d-visual-floor-info _d-plan-slider">
  <div class="d-visual-floor-info__title">${ flat.title } ${ isNewLabel }</div>
  <div class="d-visual-floor-info__labels">${ tplFlatLabels }</div>
  <div class="d-visual-floor-info__object">${ flat.object } </div>
  <div class="d-visual-floor-info__plan"><img src="${ flat.plan }" /></div>
  <div class="d-visual-floor-info__location">${ flat.location }</div>
  <div class="d-visual-floor-info__area">${ flat.area } м² </div>
  <div class="d-visual-floor-info__price${flat.price_request && flat.price_request_label? ' _price-request' : ''}">
    <div class="d-visual-floor-info__price-old">${ flat.price_old ? flat.price_old.replace(' ', ' ') + ' ₽' : '' }</div>`+
          (flat.price_request && flat.price_request_label
          ? `<span>${ flat.price_request_label.replace(' ', ' ') }</span>`
          : `<span>${ flat.price.replace(' ', ' ') } ₽</span>`) +
  `</div>
  <div class="d-visual-floor-info__link">
    <a href="${ flat.url }" class="d-button _dark">Перейти к квартире</a>
  </div>
</div>
        ` : `
<a class="d-visual-floor-info _d-plan-slider" href="${ flat.url }">
  <div class="d-visual-floor-info__title">${ flat.title } ${ isNewLabel }</div>
  <div class="d-visual-floor-info__labels">${ tplFlatLabels }</div>
  <div class="d-visual-floor-info__object">${ flat.object } </div>
  <div class="d-visual-floor-info__plan"><img src="${ flat.plan }" /></div>
  <div class="d-visual-floor-info__location">${ flat.location }</div>
  <div class="d-visual-floor-info__area">${ flat.area } м² </div>
  <div class="d-visual-floor-info__price${flat.price_request && flat.price_request_label? ' _price-request' : ''}">
    <div class="d-visual-floor-info__price-old">${ flat.price_old ? flat.price_old.replace(' ', ' ') + ' ₽' : '' }</div>` +
          (flat.price_request && flat.price_request_label
           ? `<span>${ flat.price_request_label.replace(' ', ' ') }</span>`
           : `<span>${ flat.price.replace(' ', ' ') } ₽</span>`) +
          `</div>
  <div class="d-visual-floor-info__link">
    <button class="d-button _dark">Перейти к квартире</button>
  </div>
</a>
        `;

        this.flatsPopups[`${ target.dataset.floorIndex }-${ target.dataset.flatIndex }`] = tplFlat;
        const secondTarget = document.getElementById(`flat-label-${ target.dataset.floorIndex }-${ target.dataset.flatIndex }`);
        const self = this;
        tippy(target, {
          //trigger: 'click',
          allowHTML: true,
          interactive: true,
          appendTo: () => document.body,
          offset: [8, 8],
          interactiveDebounce: 10,
          theme: 'visual-floor',
          placement: 'left-end',
          animation: 'fade',
          content: tplFlat,
          arrow: false,
          touch: false,
          triggerTarget: [target, secondTarget],
          onShow: (e) => {
            secondTarget.classList.add('_hover');
            if (e && e.id) {
              self.hideTooltips(e.id);
              self.flatsTooltips[e.id] = e;
            }
            else console.error('tippy onShow no e.id!', e);
          },
          onHide: () => {secondTarget.classList.remove('_hover');},
        });
      }
      target.dataset.hasLabel = '1';
    },
    hideTooltips(showId) {
      for (const [key, tooltip] of Object.entries(this.flatsTooltips)) {
        if (tooltip && key !== showId) tooltip.hide();
      }
    },
    openPopup(data, url) {
      if (this.isTouchDevice) {
        this.showPopup = true;
        this.currentPopupTemplate = this.flatsPopups[data];
      }
      else if (url) {
        this.showPopup = false;
        this.currentPopupTemplate = null;
        window.location.href = url;
      }
    },
  },
};
</script>

<style scoped>

</style>
