import {defineStore} from 'pinia';
import {orderBy as _orderBy} from 'lodash';
import declOfNum from '@utils/declOfNum';

export const useTagsStore = defineStore('tags', {
    state: () => ({tags: [], resetFilterTag: null}),
});

export const useItemStore = defineStore('item', {
    state: () => ({item: null, recentItems: [], floor: null, showItemPopup: false}),
    getters: {
        lastItems(state) {
            return state.recentItems.filter(itemId => itemId !== state.item.guid).slice(-4);
        },
    },
    actions: {
        addRecent(item, type) {
            this.recentItems = this.recentItems.filter(itemId => itemId !== item.guid);
            this.recentItems.push(this.item.guid);
            localStorage.setItem('recent' + type, JSON.stringify(this.recentItems.slice(-30)));
        },
    },
});

export const useSearchStore = defineStore('search', {
    state: () => (
        {
            screenTablet: false,
            screenPhone: false,
            showFilter: false,
            resetFilterFlag: 0,
            resetFilterTag: null,
            loading: true,
            loaded: false,
            dataLoaded: false,
            errored: false,
            // filters: — спецификации фильтров
            filters: {
                type: [],
                level: [],
                area: {
                    'min': 1,
                    'max': 100,
                },
                price: {
                    'min': 6.52,
                    'max': 89.56,
                },
                object: [],
                district: [],
                quarter: [],
                charging: null,
                promotion: null,
            },
            // filterParams: — текущие выбранные параметры фильтра
            filterParams: {},
            // initialFilterParams: — изначально выставленные параметры фильтра для сброса
            initialFilterParams: {
                area: [],
                price: [],
                level: [],
                type: [],
                object: [],
                quarter: [],
                building: [],
                charging: false,
                promotion: false,
            },
            // facets — допустимые при текущих filterParams значения фильтров
            facetsInitial: {},
            facets: {},
            display: 'list',
            sort: {
                options: [],
                line: [],
                selected: null,
            },
            showMore: false,
            page: 0,
            filterOutOfView: false,
            notificationDuration: 5000,
            per_page: 6,
            selectedItemId: null,
            items: [],
            tags: [],
            data: {
                objects: {},
                quarters: {},
                buildings: {},
                types: {},
            },
            showItems: [],
            multipleCountStrings: [],
            multipleResultStrings: [],
        }
    ),
    getters: {
        getItemByFormId(state) {
            return state.selectedItemId && state.items.length ? state.items.find((item) => item.form_id === state.selectedItemId) : null
        },
        sortedItems(state) {
            //console.time('sortedItems');
            const sort = state.sort.selected;
            const sorted = sort && sort.sortValue
                           ? _orderBy(this.filteredItems, [sort.sortValue], [sort.sortOrder])
                           : this.filteredItems;
            //console.timeEnd('sortedItems');
            return sorted;
        },
        filteredItems(state) {
            //console.time('filteredItems');
            const fp = state.filterParams;
            let items = [].concat(state.items);
            if ('object' in fp && fp.object.length) items = items.filter(i => fp.object.includes(i.o));
            if ('quarter' in fp && fp.quarter.length) items = items.filter(i => fp.quarter.includes(i.q));
            if ('building' in fp && fp.building.length) items = items.filter(i => fp.building.includes(i.b));
            if ('type' in fp && Array.isArray(fp.type) &&
                fp.type.length) items = items.filter(i => fp.type.includes(i.t));
            else if ('type' in fp && !Array.isArray(fp.type) && fp.type !== null) items = items.filter(i => fp.type ===
                i.t);
            if ('level' in fp && fp.level.length) items = items.filter(i => fp.level.includes(i.level));
            if ('area' in fp && fp.area.length) items = items.filter(i => i.area >= Math.min(...fp.area) && i.area <=
                Math.max(...fp.area));
            if ('price' in fp && fp.price.length) items = items.filter(i => i.price >= Math.min(...fp.price) *
                1000000 && i.price <= Math.max(...fp.price) * 1000000);
            if ('charging' in fp && fp.charging === true) items = items.filter(i => 'charging' in i.props);
            if ('promotion' in fp && fp.promotion === true) items = items.filter(i => 'promotion' in i.props);

            //console.timeEnd('filteredItems');
            return items;
        },
        result(state) {
            //console.time('result');
            const result = {
                items: this.sortedItems.slice(state.per_page * state.page,
                    state.per_page * state.page + state.per_page),
                total: this.filteredItems.length,
                page: state.page,
                total_string: ``,
                total_mobile: ``,
                pages: 0,
            };
            if (!state.loaded) {
                //console.timeEnd('result');
                return result;
            }

            result.total_string = `${ declOfNum(result.total, state.multipleCountStrings) } ${ result.total } ${ declOfNum(result.total,
                state.multipleResultStrings) }`;
            result.total_mobile
                = result.total
                  ? `Показать ${ result.total } ${ declOfNum(result.total, ['объект', 'объекта', 'объектов']) }`
                  : 'Ничего не найдено';
            result.pages = Math.ceil(result.total / state.per_page);

            //console.timeEnd('result');
            return result;
        },
    },
});
