<template>
  <div class="d-booklets__section">
    <div class="d-booklets__section-title skeleton-field"></div>
    <div class="d-booklets__section-content">
      <div v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]" class="d-booklets__item">
          <div class="d-booklets__item-cover skeleton-field">
          </div>
          <div class="d-booklets__item-bottom">
            <div class="d-booklets__item-title skeleton-field"></div>
            <div class="d-booklets__item-info skeleton-field"></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DBookletsPreloader',
};
</script>
