const setPlanHeight = (planRef) => {
    if (!planRef) return '';
    const headerHeight = document.querySelector('[data-header]').offsetHeight;
    const footer = document.querySelector('.d-footer');
    const footerPaddingTop = Number(window.getComputedStyle(footer).paddingTop.replace(/[^0-9.]/g, ''))
    const viewportHeight = window.innerHeight;
    const planWidth = planRef.offsetWidth
    const offset = viewportHeight >= 1100 ? planRef.offsetTop : headerHeight
    const ratio = planWidth / (viewportHeight - offset - footerPaddingTop)
    const allowRatio = [1.43, 2.13]
    if (ratio > allowRatio[1]) {
         return `height: ${planWidth / allowRatio[1]}px`
    }
    if (ratio < allowRatio[0]) {
        return `height: ${planWidth / allowRatio[0]}px`
    }
    if (ratio <= allowRatio[1] && ratio >= allowRatio[0]) {
        return `height: ${viewportHeight - offset - footerPaddingTop}px`
    }
}

export default setPlanHeight;
