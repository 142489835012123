<template>
  <div ref="outer" class="fit-block _scrollable">
    <div ref="inner" class="fit-block__inner">
      <img ref="pusher" class="fit-block__pusher" :src="render.img" alt="" :width="render.width" :height="render.height" />
      <slot v-if="hasLabelsSlot" name="labels"></slot>
      <slot v-if="hasMaskSlot" name="masks"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DFitblock',
  props: {
    render: {
      type: Object,
      default: () => {},
    },
    autoScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    hasLabelsSlot() {
      return !!this.$slots.labels;
    },
    hasMaskSlot() {
      return !!this.$slots.masks;
    },
    mobileIsXs() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
  },
  mounted() {
    this.$nextTick(() => {

      this.onResize();
      if (this.$refs.pusher.complete || this.$refs.pusher.readyState === 4) {
        this.$refs.inner.style.visibility = 'visible';
        this.refresh();
      } else {
        // $(this.$refs.pusher).on('load', () => {
        //   this.$refs.inner.style.visibility = 'visible';
        //   this.refresh();
        // });
        this.$refs.pusher.onload = () => {
          this.$refs.inner.style.visibility = 'visible';
          this.refresh();
        };
      }
    });
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.refresh();
    },
    refresh() {

      const outer = this.$refs.outer;
      const inner = this.$refs.inner;
      const pusher = this.$refs.pusher;
      const wrapH = outer.offsetHeight;
      const wrapW = outer.offsetWidth;
      const pusherH = pusher.naturalHeight;
      const pusherW = pusher.naturalWidth;

      const rel = pusherW / pusherH;
      if (wrapW / pusherW > wrapH / pusherH) {
        pusher.style.width = `${wrapW}px`;
        pusher.style.height = 'auto';
        inner.style.marginLeft = `-${wrapW / 2}px`;
        inner.style.marginTop = `-${wrapW / rel / 2}px`;
      } else {
        pusher.style.width = 'auto';
        pusher.style.height = `${wrapH}px`;
        inner.style.marginLeft = `-${wrapW / 2}px`;
        inner.style.marginTop = `-${wrapH / 2}px`;
      }
      this.$nextTick(() => {
        const innerW = inner.offsetWidth;
        const wrapW = outer.offsetWidth;
        if (this.autoScroll && innerW > wrapW) this.$refs.outer.scrollLeft = (innerW - wrapW)/2;
      })
    },
  },
};
</script>

<style scoped lang="stylus">


</style>
