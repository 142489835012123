<template>
  <form class="d-vacancy-form" :class="{ _success: success }" @submit.prevent="submit">
    <template v-if="success">
      <div class="d-vacancy-form__success">
        Ваше резюме успешно отправлено. <br>
        Наши менеджеры по подбору сперсонала свяжутся с вами.
        <button type="button" class="d-vacancy-form__button d-button _blue _success" @click="success = false">
          <span>Хорошо, спасибо</span>
        </button>
      </div>

    </template>
    <template v-else>
      <div class="d-vacancy-form__item">
        <div class="d-input _cashback" :class="{ _error: v$.form.name.$errors.length }">
          <input v-model="v$.form.name.$model" @blur="v$.form.name.$touch" class="d-input__field" type="text" name="name" placeholder="ФИО*">
          <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
        </div>
      </div>
      <div class="d-vacancy-form__item">
        <div class="d-input _cashback" :class="{ _error: v$.form.phone.$errors.length }">
          <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" :error="v$.form.phone.$errors.length > 0" @blur="blurInpPhone"/>
          <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        </div>
      </div>
      <div class="d-vacancy-form__item">
        <div class="d-input _cashback" :class="{ _error: v$.form.email.$errors.length }">
          <input class="d-input__field" v-model="v$.form.email.$model" @blur="v$.form.email.$touch" type="email" name="email" placeholder="Ваш e-mail*">
          <div class="d-input__error" v-for="(error, index) of v$.form.email.$errors" :key="index">{{ error.$message }}</div>
        </div>
      </div>
      <div class="d-vacancy-form__item">
        <d-input-file :data-upload-api-url="dataUploadApiUrl"
                      :form-data="formData ? formData : null"
                      v-model:file="v$.form.cv.$model"
                      :error="v$.form.cv.$errors.length ? v$.form.cv.$errors[0].$message : null"
                      @loading="setLoadingValue"/>
      </div>
      <div class="d-vacancy-form__item _agree">
        <div class="d-vacancy-form__agree d-checkbox _agree" :class="{_error: v$.form.agree.$error}">
          <input class="d-checkbox__input" @change="v$.form.agree.$touch()" v-model="v$.form.agree.$model" type="checkbox" name="agree" id="agree_vacancy">
          <label class="d-checkbox__label _inline" for="agree_vacancy">Согласен с <a :href="formData ? formData.politicsLink : ''" target="_blank">политикой
            обработки персональных данных</a>
          </label>
        </div>
        <d-recaptcha-info :errored="errored" :error-text="errorText"/>
      </div>
      <div class="d-vacancy-form__item">
        <div v-if="loadingFile" class="d-callMe__button skeleton-button d-button _blue">
          <span class="skeleton-button__loader"></span>
        </div>
        <button v-else type="submit" class="d-vacancy-form__button d-button _blue" :class="{_disable: v$.form.$invalid}">
          <span>Отправить</span>
        </button>

      </div>
    </template>


  </form>
</template>

<script>
import DInputFile from '@components/molecules/DInputFile.vue';
import useVuelidate from '@vuelidate/core';
import {required, sameAs, helpers, email, minLength, maxLength} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import DPhoneInput from '@components/molecules/DPhoneInput.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: 'DVacancyForm',
  components: {
    DRecaptchaInfo,
    DInputFile,
    DPhoneInput,
  },
  props: {
    dataActionUrl: {
      type: String,
      default: '',
    },
    dataVacancyForm: {
      type: String,
      default: '',
    },
    dataUploadApiUrl: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        cv: null,
        agree: false,
      },
      phoneParams: {
        mask: '',
        code: '',
      },
      reset: false,
      success: false,
      formData: null,
      loadingFile: false,
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
    };
  },
  created() {
    this.getDataForm();
  },
  mounted() {
  },
  methods: {
    async getDataForm() {
      try {
        let response = await this.axios.get(this.dataVacancyForm);
        console.log('response vacancy form', response.data);
        this.formData = response.data.data;
      } catch (err) {
        console.log('error load company awards data', err);
      }
    },
    async submit() {

      this.v$.$touch();
      if (!this.v$.$invalid) {
        console.log('submit');

        const formData = Object.assign({}, this.form);

        try {
          await this.$recaptchaLoaded();
          formData.token = await this.$recaptcha(this.dataType);
          const response = await this.axios.post(this.dataActionUrl, formData);
          // console.log('response', response)
          if (response.data.error) {
            this.errored = true;
            this.errorText = response.data.error;
          }
          else if (response.data.mail_send_status === 'Y') {
            this.resetForm();
            this.success = true;
          }
          else {
            console.error(response.data);
            this.errored = true;
          }
        } catch (error) {
          console.error(error);
          this.errored = true;
        } finally {
          // this.loading = false;
        }
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.name = '';
      this.form.phone = '+7';
      this.form.email = '';
      this.form.cv = null;
      this.form.agree = false;
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    setLoadingValue(value) {
      this.loadingFile = value;
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.form.phone = phone;
    },
    blurInpPhone() {
      this.v$.form.phone.$touch();
      console.log('touch phone');
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Заполните это поле', required),
        },
        email: {
          required: helpers.withMessage('Заполните это поле', required),
          email: helpers.withMessage('Введите корректный email', email),
        },
        phone: {
          required: helpers.withMessage('Заполните это поле', required),
          phoneHelper: helpers.withMessage('Введите номер полностью', phoneHelper(this.phoneParams)),
        },
        cv: {
          required: helpers.withMessage('Прикрепите файл', required),
        },
        agree: {
          required,
          sameAs: sameAs(true),
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
