import getTouchDevice from '@utils/getTouchDevice';
const svgPercent = `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(3 0)">
    <path d="M13.8485 14.3614C12.3318 15.9687 10.4194 16.7724 8.11128 16.7724C5.80319 16.7724 3.87429 15.9687 2.32457 14.3614C0.774858 12.7191 0 10.7274 0 8.38621C0 6.04506 0.774858 4.07081 2.32457 2.46345C3.87429 0.82115 5.80319 0 8.11128 0C10.4194 0 12.3318 0.803678 13.8485 2.41103C15.3982 4.01839 16.1731 6.01012 16.1731 8.38621C16.1731 10.7274 15.3982 12.7191 13.8485 14.3614ZM8.06182 12.16C9.11695 12.16 9.97424 11.7931 10.6337 11.0593C11.2931 10.3255 11.6229 9.43448 11.6229 8.38621C11.6229 7.30299 11.2931 6.39448 10.6337 5.66069C9.97424 4.9269 9.11695 4.56 8.06182 4.56C7.03967 4.56 6.19887 4.9269 5.53941 5.66069C4.87996 6.39448 4.55023 7.30299 4.55023 8.38621C4.55023 9.43448 4.87996 10.3255 5.53941 11.0593C6.19887 11.7931 7.03967 12.16 8.06182 12.16ZM2.91808 31.9724L25.7187 5.08414L28.3895 6.70897L5.58887 33.6497L2.91808 31.9724ZM23.9382 38C21.6301 38 19.7012 37.1963 18.1515 35.589C16.6018 33.9467 15.8269 31.9549 15.8269 29.6138C15.8269 27.2726 16.6018 25.2984 18.1515 23.691C19.7012 22.0487 21.6301 21.2276 23.9382 21.2276C26.2133 21.2276 28.1257 22.0487 29.6754 23.691C31.2251 25.2984 32 27.2726 32 29.6138C32 31.9549 31.2251 33.9467 29.6754 35.589C28.1257 37.1963 26.2133 38 23.9382 38ZM23.8887 33.3876C24.9438 33.3876 25.8011 33.0207 26.4606 32.2869C27.12 31.5531 27.4498 30.6621 27.4498 29.6138C27.4498 28.5306 27.12 27.6221 26.4606 26.8883C25.8011 26.1545 24.9438 25.7876 23.8887 25.7876C22.8666 25.7876 22.0258 26.1545 21.3663 26.8883C20.7069 27.6221 20.3771 28.5306 20.3771 29.6138C20.3771 30.6621 20.7069 31.5531 21.3663 32.2869C22.0258 33.0207 22.8666 33.3876 23.8887 33.3876Z" fill="white"/>
  </g>
</svg>`;



const randInRange = (min, max) => Math.random() * (max - min) + min;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const clamp = (value, min = 0, max = 1) => Math.max(min, Math.min(max, value));


const options = {
	tail: 1.1,
	delay: 50,
	hideSpeed: 0.25,
	move: true,
	angle: 30,
	maxCount: 15
};


class cursorAnimation {
	constructor() {
		this.canvas = document.createElement('canvas');
        this.canvas.style.position = "fixed";
        this.canvas.style.top = "0";
        this.canvas.style.left = "0";
        this.canvas.style.pointerEvents = "none";
        this.canvas.style.zIndex = "9999999999";

		document.body.appendChild(this.canvas);
		this.canvasContainer = this.canvas.parentElement;
		this.ctx = this.canvas.getContext("2d");
		this.dpr = window.devicePixelRatio || 1;
		this.bubbles = [];
		this.canvasSize = {
			w: 0,
			h: 0
		};

		this.lastSpawnTime = 0;
		this.lastX = 0;
		this.lastY = 0;
		this.dx = 0;
		this.dy = 0;
		this.canvasImage = null;
		this.offCanvas = null;

		this.isTouchDevice = getTouchDevice();

		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseClick = this.onMouseClick.bind(this);
		this.resizeCanvas = this.resizeCanvas.bind(this);
		this.animate = this.animate.bind(this);
		this.initImage = this.initImage.bind(this);
		this.init();
	}

	init() {
		this.initImage();
		this.resizeCanvas();
	}

	setImage(callback = null) {
		this.canvasImage = null;
		const image = new Image();
		if (callback) {
			image.onload = () => {
				callback.call(this, image);
			};
		}
		image.src = "data:image/svg+xml," + encodeURIComponent(svgPercent);
	}

	initImage() {
		this.setImage((image) => {
			this.offCanvas = document.createElement('canvas');
			this.offCanvas.width = 38;
			this.offCanvas.height = 38;
			const ctx = this.offCanvas.getContext("2d");
			ctx.drawImage(image, 0, 0, 38, 38);
			this.canvasImage = this.offCanvas;
			this.animate();
			window.addEventListener("resize", this.resizeCanvas);
			if (!this.isTouchDevice) this.canvasContainer.addEventListener("mousemove", this.onMouseMove);
			//else this.canvasContainer.addEventListener("touchstart", this.onMouseClick);
			else this.canvasContainer.addEventListener("click", this.onMouseClick);
		});
	}

	resizeCanvas() {
		if (!this.isTouchDevice) {
			this.canvasSize.w = this.canvasContainer.scrollWidth;
			this.canvasSize.h = this.canvasContainer.scrollHeight;
		}
		else {
			this.canvasSize.w = window.innerWidth;
			this.canvasSize.h = window.innerHeight;
		}
		this.canvas.width = this.canvasSize.w * this.dpr;
		this.canvas.height = this.canvasSize.h * this.dpr;
		this.canvas.style.width = this.canvasSize.w + "px";
		this.canvas.style.height = this.canvasSize.h + "px";
		this.ctx.scale(this.dpr, this.dpr);
	}

	async onMouseClick(event) {
		const touch = event.touches && event.touches.length === 1 ? event.touches[0] : event.changedTouches && event.changedTouches.length === 1 ? event.changedTouches[0] : event;
		if (touch && touch.clientX) {
			const {clientX: newX, clientY: newY} = touch;
			for (let i in [1, 2, 3, 4, 5]) {
				this.createBubble(newX, newY, 0, 10, true);
				await sleep(options.delay);
			}
		}
	}

	onMouseMove(event) {
		const currentTime = Date.now();
		if (currentTime - this.lastSpawnTime >= options.delay) {
			const { clientX: newX, clientY: newY } = event;
			this.dx = newX - this.lastX;
			this.dy = newY - this.lastY;

			this.createBubble(newX, newY, this.dx, this.dy);

			this.lastSpawnTime = currentTime;
			this.lastX = newX;
			this.lastY = newY;
		}
	}

	createBubble(x, y, dx, dy, moveUp = false) {
		const bubble = new Particle(x, y, dx, dy, this.canvasImage, moveUp);
		this.bubbles.push(bubble);
		if (this.bubbles.length > options.maxCount) this.bubbles.shift();
		//if (this.bubbles.length > options.maxCount) this.bubbles[0].remove();
	}

	clearContext() {
		this.ctx.clearRect(0, 0, this.canvasSize.w, this.canvasSize.h);
	}

	animate() {
		this.clearContext();
		for (let i = 0; i < this.bubbles.length; i++) {
			this.bubbles[i].draw(this.ctx);
			if (!this.bubbles[i].update()) this.bubbles.splice(i, 1);
		}
		window.requestAnimationFrame(this.animate);
	}
}

class Particle {
	constructor(x, y, dx, dy, canvasImage, moveUp = false) {
		const randomAngle =
			Math.atan2(dy, dx) +
			Math.PI +
			(randInRange(-options.angle, options.angle) *
				Math.PI) /
				180;

		const randomSize = Math.floor(randInRange(10, 38));
		const shrinkStep = options.hideSpeed;
		const steps = Math.ceil(randomSize / shrinkStep);

		this.img = canvasImage;
		this.lastSize = 1;
		this.size = 1;
		this.fixedSize = Math.floor(randInRange(10, 38));
		this.x = x - 0.5;
		this.y = y - 0.5;
		this.reachedSize = false;
		this.opacity = 1;
		this.opacityStep = parseFloat((1 / steps).toFixed(4));
		this.shrinkStep = options.hideSpeed;
		this.dx = Math.cos(randomAngle) * options.tail;
		this.dy = Math.sin(randomAngle) * options.tail;
		this.moveUp = moveUp;
		this.speedUp = false;

		return this;
		//particles.push(this);
	}

	remove() {
		this.speedUp = true;
	}

	draw(ctx) {
		ctx.globalAlpha = clamp(this.opacity, 0, 1);
		ctx.drawImage(this.img, this.x, this.y, this.size, this.size);
	}

	update() {
		this.lastSize = this.size;
		this.size = this.reachedSize
			? this.size - (this.speedUp ? 2 : this.shrinkStep)
			: this.size + 1;
		if (this.reachedSize)
			this.opacity = parseFloat(this.opacity - this.opacityStep).toFixed(4);
		if (this.size <= 0.5 || this.opacity <= 0) return false;

		if (!this.reachedSize && this.size >= this.fixedSize) this.reachedSize = true;

		if (this.moveUp || options.move === true) {
			this.x = this.x + this.lastSize / 2 + this.dx - this.size / 2;
			this.y = this.y + this.lastSize / 2 + this.dy - this.size / 2;
		} else {
			this.x = this.x + this.lastSize / 2 - this.size / 2;
			this.y = this.y + this.lastSize / 2 - this.size / 2;
		}

		return true;
	}
}



const initCanvas = () => {
	window.cursorAnimation = new cursorAnimation();
};
if (document.readyState === 'complete') {
    initCanvas();
} else {
    document.onreadystatechange = function () {
        if (document.readyState === "complete") {
            initCanvas();
        }
    }
}
