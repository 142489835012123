<template>
  <div class="d-booklets__section d-aux-rooms__result-error">
    <div class="d-aux-rooms__result-error_icon _reload"></div>
    <div v-if="mod === 'error'" class="d-aux-rooms__result-error_text">
      При загрузке данных произошла ошибка. Попробуйте&nbsp;обновить страницу
    </div>
    <div v-else class="d-aux-rooms__result-error_text">
      По заданным параметрам ничего не найдено. Попробуйте&nbsp;сбросить фильтр
    </div>
    <button v-if="mod === 'error'" class="d-button _dark d-aux-rooms__result-error_button" @click="reload"><span>Обновить страницу</span></button>
    <button v-else class="d-button _dark d-aux-rooms__result-error_button" @click="$emit('reset')"><span>Сбросить фильтр</span></button>
  </div>
</template>

<script>
export default {
  name: 'DBookletsError',
  emits: ['reset'],
  props: {
    mod: {
      type:String,
      default: 'error'
    }
  },
  methods: {
    reload() {
      window.location.reload()
    }
  }
};
</script>
