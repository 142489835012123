export async function getSupportWebp() {
  if (!self.createImageBitmap) return false;

  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const blob = await fetch(webpData).then(r => r.blob());
  return createImageBitmap(blob).then(() => true, () => false);
}

export async function addWebpUrlParam(url) {
  const isSupportsWebp = await getSupportWebp();
  const apiUrl = new URL(url, window.location.href);
  const params = apiUrl.searchParams;
  params.set('webp', isSupportsWebp.toString());
  apiUrl.search = params;
  return apiUrl.toString();
}
