<template>
  <div class="d-visual-buildings">
    <d-visual-header v-if="headerDataConvertToObject" :chess-legend="false" :data="headerDataConvertToObject" @changeType="changeType"/>
    <div v-if="type === '3d'" class="d-visual-genplan" ref="genplan" :style="`${heightPlan}`">
      <d-swipe-zoom-info v-if="!swipeInfoHide" class="d-visual-genplan__swipe" @close="closeSwipeInfo()">
        Используйте жесты для прокрутки изображения
      </d-swipe-zoom-info>
      <d-disclaimer v-if="disclaimer" class="d-visual-genplan__disclaimer" :text="disclaimer"/>
      <img v-if="genplanData3d && genplanData3d.render" :src="genplanData3d.render.compass" class="d-visual-genplan__compass"/>
      <d-fitblock v-if="genplanData3d && genplanData3d.render" :render="genplanData3d.render" auto-scroll>
        <template #masks>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanData3d.render.width"
               :height="genplanData3d.render.height"
               :viewBox="`0 0 ${genplanData3d.render.width} ${genplanData3d.render.height}`">
            <path v-for="(label, index) in labelsData3d"
                  :data-label-id="label.id"
                  :key="label.id"
                  class="d-visual-genplan__path"
                  :class="{ _hover: hoverLabelId === label.id }"
                  :d="label.points"
                  @click="openPopup(label)"/>
          </svg>
        </template>
        <template #labels>
          <div v-for="(label, index) in labelsData3d"
               :ref="setItemRef"
               data-append-to="genplan"
               :data-tooltip="index"
               :data-label-id="label.id"
               :key="label.id"
               class="d-visual-genplan-marker"
               :style="`left: ${label.position.left}%; top: ${label.position.top}%;`"
               @mouseover="hoverLabelId = label.id"
               @mouseleave="hoverLabelId = null"
               @click="openPopup(label)">{{ label.title }}
          </div>
        </template>
      </d-fitblock>
    </div>
    <div v-if="type === '2d'" class="d-visual-genplan _2d" ref="genplan2d" :style="`${heightPlan2d}`">
      <d-swipe-zoom-info v-if="!swipeZoomInfoHide" class="d-visual-genplan__swipe" mod="pinchzoom" @close="closeSwipeZoomInfo()">
        Используйте жесты для перемещения и масштабирования изображения
      </d-swipe-zoom-info>
      <img v-if="genplanData3d && genplanData3d.render2d" :src="genplanData3d.render2d.compass" class="d-visual-genplan__compass"/>
      <d-fitblock v-if="genplanData3d && !screenXS" :render="genplanData3d.render2d" :autoScroll="true">
        <template #masks>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanData3d.render2d.width"
               :height="genplanData3d.render2d.height"
               :viewBox="`0 0 ${genplanData3d.render2d.width} ${genplanData3d.render2d.height}`">
            <path v-for="(label, index) in labelsData3d"
                  :ref="setItemRef"
                  data-append-to="genplan2d"
                  :data-label-id="label.id"
                  :data-tooltip="index"
                  :key="label.id"
                  class="d-visual-genplan__path"
                  :class="{ _hover: hoverLabelId === label.id, _empty: !label.data.allowFlats }"
                  :d="label.points2d ? label.points2d : coords2poins(label.coords, label.shape)"
                  @click="openPopup(label)"/>
          </svg>
        </template>
        <template v-if="labels2d" #labels>
          <div v-for="(label, index) in labels2d" :key="index" class="d-visual-genplan-eye" :style="`left: ${label.position.left}%; top: ${label.position.top}%;`" @click="openViewPopup(label)">
            <svg>
              <use :xlink:href="`/assets/blueant/assets/sprite.svg#sprite-eye`"></use>
            </svg>
          </div>
        </template>
      </d-fitblock>
      <pinch-scroll-zoom v-if="genplanData3d && screenXS && planWidth && planHeight"
                         ref="zoomer"
                         :width="planWidth"
                         :height="planHeight"
                         :scale="zoom"
                         :within="true"
                         :contentWidth="planWidth"
                         :contentHeight="planHeight"
                         class="d-visual-genplan__zoom-wrapper"
                         :minScale="1"
                         :maxScale="20">
        <div class="d-visual-genplan__zoom">
          <img :src="genplanData3d.render2d.img" alt="" :width="genplanData3d.render2d.width" :height="genplanData3d.render2d.height"/>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanData3d.render2d.width"
               :height="genplanData3d.render2d.height"
               :viewBox="`0 0 ${genplanData3d.render2d.width} ${genplanData3d.render2d.height}`">
            <path v-for="(label, index) in labelsData3d"
                  :ref="setItemRef"
                  data-append-to="genplan2d"
                  :data-label-id="label.id"
                  :data-tooltip="index"
                  :key="label.id"
                  class="d-visual-genplan__path"
                  :class="{ _hover: hoverLabelId === label.id, _empty: !label.data.allowFlats }"
                  :d="label.points2d ? label.points2d : coords2poins(label.coords, label.shape)"
                  @click="openPopup(label)"/>
          </svg>
          <template v-if="labels2d">
            <div v-for="(label, index) in labels2d" :key="index" class="d-visual-genplan-eye" :style="`left: ${label.position.left}%; top: ${label.position.top}%;`" @click="openViewPopup(label)">
              <svg>
                <use :xlink:href="`/assets/blueant/assets/sprite.svg#sprite-eye`"></use>
              </svg>
            </div>
          </template>
        </div>
      </pinch-scroll-zoom>
    </div>
    <d-popup-simple v-if="buildingMobileInfo" v-model="buildingMobilePopupShow" mod="center">
      <div class="d-visual-building-info">
        <div class="d-visual-building-info__title">Корпус {{ buildingMobileInfo.data.buildingNum }}</div>
        <div class="d-visual-building-info__content">
          <div v-if="buildingMobileInfo.data.sections" class="d-visual-building-info__sections d-choose-builds-info__section">
            <div class="d-choose-builds-info__label">Выберите секцию</div>
            <div class="d-choose-builds-info__list">
              <swiper class="d-choose-builds-info__slider" v-bind="swiperOpts">
                <swiper-slide v-for="section in buildingMobileInfo.data.sections" :key="section.id" class="d-checkbox d-choose-builds-info__checkbox _full" :class="{ _disabled: !section.floors.length }">
                  <input class="d-checkbox__input" v-model="currentSection" type="radio" name="section" :id="`section-${section.id}`" :value="section.name"/>
                  <label class="d-checkbox__label" :for="`section-${section.id}`" :class="{_disabled: section.disabled}">{{ section.name }}</label>
                </swiper-slide>
              </swiper>

            </div>
          </div>
          <div class="d-visual-building-info__row">
            <div class="d-visual-building-info__param">
              <div class="d-visual-building-info__value">{{ buildingMobileInfo.data.floors[0] }}-{{ buildingMobileInfo.data.floors[1] }}</div>
              <div class="d-visual-building-info__name">этажей</div>
            </div>
            <div class="d-visual-building-info__param">
              <div class="d-visual-building-info__value">{{ buildingMobileInfo.data.allowFlats }}</div>
              <div class="d-visual-building-info__name">свободных квартир</div>
            </div>
            <div class="d-visual-building-info__param">
              <div class="d-visual-building-info__value">{{ buildingMobileInfo.data.areas[0] }}-{{ buildingMobileInfo.data.areas[1] }}</div>
              <div class="d-visual-building-info__name">площадь, м<sup>2</sup></div>
            </div>
          </div>
          <div class="d-visual-building-info__flats">
            <div v-for="flat in buildingMobileInfo.data.flats" :key="flat.rooms" class="d-visual-building-info__param _flat">
              <div class="d-visual-building-info__name">{{ flat.rooms === 0 ? 'Студии' : `${ flat.rooms }-комн. квартиры` }}</div>
              <div v-if="flat.price_request && flat.price_request_label" class="d-visual-building-info__value">{{ flat.price_request_label }}</div>
              <div v-else class="d-visual-building-info__value">от {{ flat.priceMin }} млн ₽</div>
            </div>
          </div>
          <a v-if="sectionLink" class="d-visual-building-info__btn d-button _dark" :href="sectionLink"><span>Выбрать на схеме</span></a>
          <div v-if="floorsList" class="d-visual-building-info__sections d-choose-builds-info__floor">
            <div class="d-choose-builds-info__label">Выберите этаж</div>
            <div class="d-choose-builds-info__list">
              <swiper class="d-choose-builds-info__slider" v-bind="swiperOpts">
                <swiper-slide v-for="floor in floorsList" :key="floor.id" class="d-checkbox d-choose-builds-info__checkbox _full" :class="{ _disabled: floor.disabled }">
                  <input class="d-checkbox__input" v-model="currentFloor" type="radio" name="floor" :id="`floor-${floor.id}`" :value="floor.name"/>
                  <label class="d-checkbox__label" :class="{_disabled: floor.disabled}" :for="`floor-${floor.id}`">{{ floor.name }}</label>
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <a v-if="floorLink" class="d-visual-building-info__btn d-button _dark" :href="floorLink"><span>Выбрать на этаже</span></a>
        </div>
      </div>
    </d-popup-simple>
    <d-popup-simple v-if="openedView" v-model="showPopup" @closed="openedView = null" :with-close="false">
      <d-awards-popup :photos="openedView.photo" @close="showPopup = false; openedView = null"/>
    </d-popup-simple>
    <d-popup-simple v-if="openedView" v-model="showPanoram" @closed="openedView = null" :with-close="false">
      <d-panoram-popup :panoram="openedView.panoram" @close="showPanoram = false; openedView = null"/>
    </d-popup-simple>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import DVisualHeader from '@components/molecules/DVisualHeader.vue';
import DFitblock from '@components/molecules/DFitblock.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import Cookies from 'js-cookie';
import coords2path from '@utils/area2svg';
import setPlanHeight from '@utils/setPlanHeight';
import DDisclaimer from '@components/atoms/DDisclaimer.vue';
import PinchScrollZoom from '@coddicat/vue3-pinch-scroll-zoom';
import {Swiper, SwiperSlide} from 'swiper/vue';
import DAwardsPopup from '@components/molecules/DAwardsPopup.vue';
import DPanoramPopup from '@components/molecules/DPanoramPopup.vue';

export default {
  name: 'DVisualBuildings',
  components: {
    DPanoramPopup,
    DAwardsPopup,
    DVisualHeader,
    DFitblock,
    DPopupSimple,
    DSwipeZoomInfo,
    DDisclaimer,
    PinchScrollZoom,
    Swiper,
    SwiperSlide,
  },
  props: {
    headerData: {
      type: String,
      default: '',
    },
    data3d: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      type: null,
      genplanData3d: null,
      labelsData3d: null,
      labels2d: null,
      hoverLabelId: null,
      buildingMobileInfo: null,
      buildingMobilePopupShow: false,
      currentSection: null,
      itemRefs: [],
      swipeInfoHide: false,
      currentFloor: null,
      swipeZoomInfoHide: false,
      chooseParamsLink: null,
      heightPlan: null,
      heightPlan2d: null,
      disclaimer: null,
      zoom: 1,
      screenXS: false,
      planHeight: 0,
      planWidth: 0,
      openedView: null,
      showPopup: false,
      showPanoram: false,
    };
  },
  computed: {
    headerDataConvertToObject() {
      return JSON.parse(this.headerData);
    },
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      }
      else {
        if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
          return true;
        }
        else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    floorsList() {
      if (this.currentSection) {
        const section = this.buildingMobileInfo.data.sections.find((section) => section.name === this.currentSection);
        return section.floors;
      }
      return null;
    },
    floorLink() {
      if (this.floorsList) {
        const currentFloor = this.floorsList.find((floor) => floor.name === this.currentFloor);
        //console.log('currentFloor', this.floorsList, this.currentFloor);
        if (currentFloor) return currentFloor.link;

      }
      return null;
    },
    sectionLink() {
      if (this.currentSection) {
        const section = this.buildingMobileInfo.data.sections.find((section) => section.name === this.currentSection);
        // if (section) return this.type === '3d' ? section.link : section.chessLink;
        if (section) return section.chessLink;
      }
      return null;
    },
    swiperOpts() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 0,
      };
    },
  },
  watch: {
    currentSection() {

      this.currentFloor = this.setInitFloorSection(this.floorsList);
      //console.log('this floorsList', this.floorsList);
    },
    showPopup() {
      if (!this.showPopup) this.releaseBody();
    },
    showPanoram() {
      if (!this.showPanoram) this.releaseBody();
    },
  },
  created() {
    this.getData3d();
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closedSwipeInfoVisualBuildings')) === 1;
    this.swipeZoomInfoHide = Number(Cookies.get('closedSwipeZoomInfoVisualBuildings')) === 1;
    this.$nextTick(() => {
      this.setHeightPlan(this.type === '3d' && !this.heightPlan);
      window.addEventListener('resize', this.setHeightPlan);
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.setHeightPlan);
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  updated() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item);
    });
  },
  methods: {
    coords2poins(coords, shape) {
      return coords2path(coords.split(',').map(function(num) { return Number(num);}), shape);
    },
    changeType(value) {
      this.type = value;
      this.setHeightPlan(value === '3d' && !this.heightPlan);
    },
    async getData3d() {
      try {
        let response = await this.axios.get(this.data3d);
        //console.log('response genplan buldings', response);
        this.labels2d = response.data['2d_eyes'];
        this.genplanData3d = response.data.render;
        this.labelsData3d = response.data.labels;
        this.chooseParamsLink = response.data.info.choose_params_link;
        this.disclaimer = response.data.info.disclaimer ? response.data.info.disclaimer : null;
      } catch (err) {
        console.log('error load genplan buildfings data', err);
      }
    },
    createTooltip(target) {
      const data = this.labelsData3d[target.dataset.tooltip].data;
      const appendTo = target.dataset.appendTo;
      let tplFlats = ``;

      data.flats.forEach((flat) => {
        tplFlats += `
          <div class="d-visual-building-info__param _flat">
            <div class="d-visual-building-info__name">${ flat.rooms === 0 ? 'Студии' : `${ flat.rooms }-комн. квартиры` } </div>` +
          (flat.price_request && flat.price_request_label
           ? `<div class="d-visual-building-info__value">${ flat.price_request_label }</div>`
           : (data.allowFlats > 1
              ? `<div class="d-visual-building-info__value">от ${ flat.priceMin } млн ₽ </div>`
              : `<div class="d-visual-building-info__value">${ flat.priceMin } млн ₽ </div>`)
          ) + `</div>`;
      });

      const tpl = data.allowFlats > 0 ? `
        <div class="d-visual-building-info">
          <div class="d-visual-building-info__title">Корпус ${ data.buildingNum }</div>
          <div class="d-visual-building-info__content">
            <div class="d-visual-building-info__row">
              <div class="d-visual-building-info__param">
                <div class="d-visual-building-info__value">${ data.floors[0] }-${ data.floors[1] }</div>
                <div class="d-visual-building-info__name">этажей</div>
              </div>
              <div class="d-visual-building-info__param">
                <div class="d-visual-building-info__value">${ data.allowFlats }</div>
                <div class="d-visual-building-info__name">свободных квартир</div>
              </div>
              <div class="d-visual-building-info__param">
                <div class="d-visual-building-info__value">${ data.areas[0] }-${ data.areas[1] }</div>
                <div class="d-visual-building-info__name">площадь, м<sup>2</sup></div>
              </div>
            </div>
            <div class="d-visual-building-info__flats"> ${ tplFlats } </div>
          </div>
        </div>
      ` : `
        <div class="d-visual-building-info">
          <div class="d-visual-building-info__title">Корпус ${ data.buildingNum }</div>
          <div class="d-visual-building-info__content"><p class="_empty">Нет квартир в продаже</p></div>
        </div>
      `;
      const labelId = target.dataset.labelId;
      const secondTarget = document.querySelector(`.d-visual-genplan__path[data-label-id="${ labelId }"]`);

      tippy(target, {
        // trigger: 'click',
        allowHTML: true,
        interactive: true,
        appendTo: () => this.$refs[appendTo],
        offset: [0, 8],
        // interactiveDebounce: 10,
        theme: 'visual-building',
        placement: 'left-end',
        animation: 'fade',
        content: tpl,
        arrow: false,
        touch: false,
        triggerTarget: [secondTarget, target],
      });
    },

    openPopup(data) {
      const typeParam = data.url.includes('?type');
      if (data.data.allowFlats) {
        if (this.isTouchDevice) {
          this.currentSection = null;
          this.currentFloor = null;

          this.buildingMobileInfo = data;
          this.buildingMobilePopupShow = true;

          console.log('data', data.data);

          this.currentSection = this.setInitFloorSection(data.data.sections);
          const currentSection = data.data.sections.find((section) => section.init);
          this.currentFloor = this.setInitFloorSection(currentSection.floors);
        }
        else {
          window.location.href = typeParam ? data.url : `${ data.url }?type=${ this.type }`;
        }
      }
    },
    setInitFloorSection(data) {
      //console.log('setInitFloorSection', data);
      const initItem = data.find((item) => item.init);
      if (initItem) return initItem.name;
      else return data[0].name;
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    closeSwipeInfo() {
      Cookies.set('closedSwipeInfoVisualBuildings', 1);
      this.swipeInfoHide = true;
    },
    closeSwipeZoomInfo() {
      Cookies.set('closedSwipeZoomInfoVisualBuildings', 1);
      this.swipeZoomInfoHide = true;
    },
    setHeightPlan(set3d = true) {
      this.screenXS = window.matchMedia('(max-width: 767px)').matches;

      this.$nextTick(() => {
        if (this.$refs.genplan && set3d) this.heightPlan = setPlanHeight(this.$refs.genplan);
        if (this.$refs.genplan2d) {
          this.heightPlan2d = setPlanHeight(this.$refs.genplan2d) + '!important';
          this.$nextTick(() => {
            this.planHeight = this.$refs.genplan2d.getBoundingClientRect().height;
            this.planWidth = window.innerWidth;
          });
        }
      });
    },
    openViewPopup(view) {
      if (view.panoram) {
        $('body').addClass('_noJivo');
        this.openedView = view;
        this.showPanoram = true;
      }
      else if (view.photo && view.photo.length) {
        $('body').addClass('_noJivo');
        this.openedView = view;
        this.showPopup = true;
      }
    },
    releaseBody() {
      $('body').removeClass('_noJivo');
      $('body').removeClass('_popupOpen');
    }
  },
};
</script>
