<template>
  <div class="d-commercial">
    <div class="d-commercial__top">
      <h1 class="d-commercial__title">Коммерческая недвижимость</h1>
      <div class="d-commercial__buttons">
        <button v-if="type === 'params'" class="d-button _blue " @click="changeType('map')">
          <span>Смотреть на карте</span>
        </button>
        <button v-if="type === 'map'" class="d-button _blue " @click="changeType('params')">
          <span>Смотреть списком</span>
        </button>
      </div>
      <div ref="mobileButtons" class="d-commercial__buttons _mobile">
        <button class="d-commercial__buttonFilter" @click="openFilterMobile">
          <img src="/assets/blueant/assets/img/d/icon_filter.svg" alt="">
          <span>Фильтры</span>
        </button>
        <button class="d-commercial__buttonOnMap" @click="showMobileMap = true">
          <img src="/assets/blueant/assets/img/d/icon_pin.svg" alt="">
          <span>На карте</span>
        </button>
      </div>
    </div>
    <div v-if="!isTablet" v-show="type === 'params'" class="d-commercial__filter">
      <d-commercial-filter
          :data-filter-params-api="filterParamsApi"
          :data-filter-specs-api="filterSpecsApi"
          :tags-items="tags"
          :remove-tag="removeTag"
          :reset-all-filters="resetAllFilters"
          @update:tagsItems="setTags"
          @filterChanged="getData"
      />
    </div>
    <div v-show="type === 'params'" class="d-commercial__content _params">
      <div class="d-tabs _whiteBg d-commercial__inner">
        <div class="d-commercial__count _mobile">
          <template v-if="totalStr">
            Найдено {{ totalStr }}
          </template>
        </div>
        <div class="d-commercial__controls">
<!--          аренды пока не будет-->
<!--          <div class="d-tabs__buttons">-->
<!--            <div class="d-tabs__buttonsInner">-->
<!--              <button class="d-tabs__button _active">Покупка</button>-->
<!--              <button class="d-tabs__button">Аренда</button>-->
<!--              <div class="d-tabs__buttonBg"></div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="d-commercial__count _desktop">
            <template v-if="totalStr">
              Найдено {{ totalStr }}
            </template>
          </div>
          <div v-if="view === 'card'" class="d-commercial__sorter" data-sorter-drop>
            <d-select :data-options="sortingData" v-model="sortParam" :icon="getSortDirIcon"/>
          </div>
          <div class="d-commercial__switchView">
            <div class="d-switch-view" :class="{ _list: view === 'list'}">
              <button class="d-switch-view__button" :class="{ _active: view === 'list'}" @click="changeView('list')">
                <svg>
                  <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-list'></use>
                </svg>
              </button>
              <button class="d-switch-view__button" :class="{ _active: view === 'card'}" @click="changeView('card')">
                <svg>
                  <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-card'></use>
                </svg>
              </button>
              <div class="d-switch-view__buttonBg"></div>
            </div>
          </div>
        </div>
        <div v-if="tags.length" class="d-commercial__tags">
          <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
            <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
            <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
              </svg>
            </button>
          </div>
        </div>
        <div class="d-tabs__content _active">
          <div v-if="loading && !resultItems.length" class="d-commercial-empty">
            <div class="d-commercial-empty__icon">
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
              </svg>
            </div>
            <div class="d-commercial-empty__text">По заданным параметрам ничего не найдено.
              Попробуйте сбросить фильтр или задать другие параметры.</div>
            <button class="d-button _dark" @click="resetFilters"><span>Сбросить фильтры</span></button>
          </div>
          <div class="d-commercial__results" :class="{ _list: view === 'list'}" data-switch-content>
            <div class="d-commercial__list">
              <template v-if="loading">
                <d-commercial-card v-for="(item) in resultItems" :data="item" :key="item.id"/>
              </template>
              <template v-else>
                <d-commercial-card v-for="item in [1,2,3,4,5,6]" :key="item" mod="skeleton"/>
              </template>
            </div>

            <div class="d-commercial__list _list">
              <div v-if="result && result.items.length"  class="d-commercial__sorter _line">
                <div class="d-sorter-line _commercial">
                  <div class="d-sorter-line__item _item1">&nbsp;</div>
                  <button class="d-sorter-line__item _item2 _disabled">
                    <span class="d-sorter-direction _line"><span></span></span><span>Объект</span><span class="short">Объект</span>
                  </button>
                  <button class="d-sorter-line__item _item2 _disabled">
                    <span class="d-sorter-direction _line"><span></span></span><span>Назначение</span><span class="short">Назначение</span>
                  </button>
                  <button class="d-sorter-line__item _item2 _disabled">
                    <span class="d-sorter-direction _line"><span></span></span><span>Расположение</span><span class="short">Располож.</span>
                  </button>
                  <button class="d-sorter-line__item _item2"
                          :class="{ _active: sortParam.includes('area') , _desc: sortParam.includes('desc'), _asc: sortParam.includes('asc')}"
                          @click.stop.prevent="changeSortLine('area', $event)">
                    <span class="d-sorter-direction _line"><span></span></span><span>Площадь, м&sup2;</span><span class="short">Площадь, м&sup2;</span>
                  </button>
                  <button class="d-sorter-line__item _item2"
                          :class="{ _active: sortParam.includes('price') , _desc: sortParam.includes('desc'), _asc: sortParam.includes('asc')}"
                          @click.stop.prevent="changeSortLine('price', $event)">
                    <span class="d-sorter-direction _line"><span></span></span><span>Стоимость, ₽</span><span class="short">Стоимость, ₽</span>
                  </button>
                </div>
              </div>
              <template v-if="loading">
                <d-commercial-row v-for="(item) in resultItems" :data="item" :key="item.id"/>
              </template>
              <template v-else>
                <d-commercial-row v-for="(item) in [1,2,3,4,5,6]" :key="item" mod="skeleton"/>
              </template>
            </div>
            <div v-if="result && result.items.length" class="d-commercial__bottom">
              <div v-if="pages.pages > 1" class="d-commercial__pager">
                <d-press-pager :pages-count="pages.pages" :current-page-index="pages.showPage" @changePage="setPage"/>
              </div>
              <div v-if="remainItems > 0" class="d-commercial__more">
                <button class="d-button _dark" @click="addMore"><span>Показать еще</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 'map'" class="d-commercial__content _map">
      <d-map-commercial :points-api-url="commercialMapApi" page="commercial"/>
    </div>
    <d-popup-simple  v-model="showFilterMobile" mod="filter">
      <d-commercial-filter
          v-if="isTablet"
          :data-filter-params-api="filterParamsApi"
          :data-filter-specs-api="filterSpecsApi"
          :tags-items="tags" :remove-tag="removeTag"
          :total="totalStr"
          :reset-all-filters="resetAllFilters"
          @update:tagsItems="setTags" mod="mobile"
          @filterChanged="getData"
          @closed="showFilterMobile = false"
      />
    </d-popup-simple>
    <d-popup-simple  v-model="showMobileMap">
      <d-map-commercial v-if="showMobileMap" :points-api-url="commercialMapApi" page="commercial"/>
    </d-popup-simple>
    <button class="d-commercial__buttonFilter _fixed" :class="{ _show: showFixedButton && !callMeFormIsVisible}" @click="openFilterMobile">
      <img src="/assets/blueant/assets/img/d/icon_filter.svg" alt="">
      <span>Фильтры</span>
    </button>
  </div>
</template>

<script>
import {debounce as _debounce} from 'lodash';
import getSearchUrl from "@utils/getSearchUrl";
import declOfNum from "@utils/declOfNum";
import DCommercialFilter from "@components/organisms/DCommercialFilter.vue";
import DCommercialCard from "@components/molecules/DCommercialCard.vue";
import DCommercialRow from "@components/molecules/DCommercialRow.vue";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
import DCommercialFilterMobile from "@components/organisms/DCommercialFilterMobile.vue";
import DSelect from "@components/molecules/DSelect.vue";
import DPressPager from "@components/molecules/DPressPager.vue";
import DMapCommercial from "@components/organisms/DMapCommercial.vue";
export default {
  name: "DCommercialParams",
  components: {
    DCommercialFilter,
    DCommercialCard,
    DCommercialRow,
    DPopupSimple,
    DCommercialFilterMobile,
    DSelect,
    DPressPager,
    DMapCommercial
  },
  props: {
    commercialApi: {
      type: String,
      default: '/assets/blueant/assets/json/commercial-filter-api.json',
    },
    commercialMapApi: {
      type: String,
      default: '/assets/blueant/assets/json/commercial-map.json',
    },
    filterSpecsApi: {
      type: String,
      default: '/assets/blueant/assets/json/commercial-filter-specs.json',
    },
    filterParamsApi: {
      type: String,
      default: '/assets/blueant/assets/json/commercial-filter-params.json',
    },
    apiMethod: {
      type: String,
      default: "post"
    },
    perPage: {
      type: [Number, String],
      default: 7,
    },
  },
  data() {
    return {
      tags: [],
      removeTag: {},
      showFilterMobile: false,
      result: null,
      resultItems: null,
      type: 'params',
      view: 'card',
      sortingData: [
        {
          id: 1,
          name: "По возрастанию цены",
          value: 'price-asc'
        },
        {
          id: 2,
          name: "По убыванию цены",
          value: 'price-desc'
        },
        {
          id: 3,
          name: "По возрастанию площади",
          value: 'area-asc'
        },
        {
          id: 4,
          name: "По убыванию площади",
          value: 'area-desc'
        }
      ],
      sortParam: 'price-asc',
      filterParams: null,
      pages: {},
      remainItems: null,
      loading: false,
      resetAllFilters: false,
      showMobileMap: false,
      showFixedButton: false,
      callMeFormIsVisible: false,
      isTablet: false
    }
  },
  computed: {
    totalStr() {
      if (!this.result) return null
      else {
        const str = declOfNum(this.result.total, ['объект','объекта','объектов'])
        return `${this.result.total} ${str}`
      }
    },
    getSortDirIcon() {
      const isAsc = this.sortParam.includes('asc');
      return isAsc ? 'sort-asc' : 'sort-desc'
    }
  },
  watch: {
    sortParam() {
      this.pages.page = 0;
      this.pages.showPage = 0;
      this.getData(this.filterParams);
    },
  },
  created() {
    this.pages = {page: 0, showPage: 0, count: 0, pages: 0};
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    window.addEventListener('popstate', this.restoreParams)
    this.restoreParams();
    this.$nextTick(() => {
      this.checkVisibleButtons()
      this.checkVisibleCallmeForm()
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
    window.removeEventListener('popstate', this.restoreParams);
  },
  methods: {
    checkScreenSize() {
      this.isTablet = window.matchMedia("(max-width: 1279px)").matches && window.matchMedia("(pointer:coarse)").matches;
    },
    setTags(tags) {
      this.tags = tags
    },
    resetFilter(tag) {
      this.removeTag = tag;
    },
    resetFilters() {
      console.log('reset')
      this.resetAllFilters = true
      setTimeout(() => {
        this.resetAllFilters = false
      }, 100)
    },
    openFilterMobile() {
      this.showFilterMobile = true
    },
    getData: _debounce(async function(filterParams, pageParam, changedOnlyFilters = false) {
      console.log('pageParam', pageParam)
      if (!pageParam) {
        pageParam = { page: this.pages.page + 1 }
      }
      this.filterParams = filterParams;
      const fullParams = Object.assign({}, this.filterParams, { sort : this.sortParam }, pageParam)

      this.saveStateToURL(fullParams, {
        view: this.view,
        type: this.type
      })
      try {
        console.log('%csend data params', 'color: yellow', fullParams)
        const response = await this.axios[this.apiMethod](this.commercialApi, fullParams);

        this.result = response.data;
        console.log('this.pages.showPage !== this.pages.page', this.pages.showPage, this.pages.page)
        if (this.pages.showPage !== this.pages.page && !changedOnlyFilters) this.resultItems = [...this.resultItems, ...response.data.items];
        else this.resultItems = response.data.items;

        this.pages = {
          page: this.pages.page,
          showPage: this.pages.showPage,
          count: Number(this.perPage),
          pages: Math.ceil(response.data.total / this.perPage),
          total: response.data.items.total,
        }
        if (changedOnlyFilters) {
          this.pages.page = 0
          this.pages.showPage = 0
        }
        await this.$nextTick(() => {
          this.pages = {
            page: this.pages.page,
            showPage: this.pages.showPage,
            count: Number(this.perPage),
            pages: Math.ceil(response.data.total / this.perPage),
            total: response.data.total,
          }
          this.calcRemainItems();
          this.loading = true;
        })

      } catch (error) {
        console.error(error);
        // this.errored = true;
      } finally {
        // this.setLoading(false);
      }
    }, 500),
    saveStateToURL(params, extraParams = {}) {
      const queryStr = this.buildParams(Object.assign({}, params, extraParams))
      let newURL = location.pathname + "?" + queryStr;
      history.pushState({}, document.title, newURL);
    },
    buildParams(data) {
      const params = new URLSearchParams()

      Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(value => params.append(`${key}[]`, value.toString()))
        } else {
          params.append(key, value.toString())
        }
      });

      return params.toString()
    },
    async setPage(num) {
      this.$smoothScroll({
        scrollTo: document.querySelector(`[data-switch-content]`),
        updateHistory: false,
        duration: 500,
        offset: -220,
      });

      setTimeout(async () => {
        this.pages.count = parseInt(this.perPage);
        this.pages.page = num;
        this.pages.showPage = num;

        await this.getData(this.filterParams, {
          page: parseInt(num) + 1
        });
      }, 500);
      this.calcRemainItems()
    },
    async addMore() {
      this.pages.count += parseInt(this.perPage);
      this.pages.showPage += 1;

      await this.getData(this.filterParams, {
        page: this.pages.showPage + 1
      });
      this.calcRemainItems()
    },
    restoreParams() {
      const url = new URL(window.location);
      const currentSort = url.searchParams.get('sort');
      const currentPage = url.searchParams.get('page');
      const currentView = url.searchParams.get('view');
      const currentType = url.searchParams.get('type')
      currentSort ? this.sortParam = currentSort : this.sortParam = 'price-asc';

      if (currentPage) {
        this.pages.page = parseInt(currentPage) - 1;
        this.pages.showPage = parseInt(currentPage) - 1;
        this.pages.count = parseInt(this.perPage);
      }

      if (currentView) {
        this.view = currentView
      }

      if (currentType) {
        this.type = currentType
      }
    },
    calcRemainItems() {
      const total = parseInt(this.result.total);
      this.remainItems = total - (this.perPage * (this.pages.showPage + 1))
    },
    changeSortLine(value, $event) {
      const isDesc = $event.target.classList.contains('_desc');
      if (isDesc) this.sortParam = `${value}-asc`
      else this.sortParam = `${value}-desc`
    },
    changeView(value) {
      this.view = value;
      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        view: value
      })
    },
    changeType(value) {
      this.type = value;
      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        view: this.view,
        type: this.type
      })
    },
    checkVisibleButtons() {
      let element = this.$refs.mobileButtons;
      let handleIntersection = (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            this.showFixedButton = false;
          } else {
            this.showFixedButton = true;
          }
        }
      }
      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(element);
    },
    checkVisibleCallmeForm() {
      const targetCallmeForm = document.querySelector('.d-still-questions')
      const options = {
        // rootMargin: '0',
        threshold: 0.01
      }
      const callback = (entries, observer) => {
        this.callMeFormIsVisible = entries[0].isIntersecting === true;
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(targetCallmeForm);
    },
  }
}
</script>

<style scoped>

</style>
