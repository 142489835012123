<template>
  <div class="d-loading-spinner">
    <svg class="d-loading-spinner__circular-loader" viewBox="25 25 50 50">
      <circle class="d-loading-spinner__loader-path" cx="50" cy="50" r="20" fill="none" stroke="#39D0E2" stroke-width="2" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DLoadingSpinner',
};
</script>
