<template>
  <div v-if="loaded" class="d-object-single-gallery">
    <div class="d-object-single-gallery__title d-page-title">Галерея</div>
    <div class="d-tabs _whiteBg">
      <div class="d-tabs__buttons d-object-single-gallery__tabs">
        <div class="d-tabs__buttonsInner">
          <button v-for="(gallery, index) in galleryData" :key="`buttons-${index}`" class="d-tabs__button _scroll" :class="{_active: currentSliderIndex === index}">
            {{ gallery.title }}
          </button>
          <div class="d-tabs__buttonBg"></div>
        </div>
      </div>
      <div v-for="(gallery, index) in galleryData" :key="`gallery-${index}`" class="d-tabs__content d-object-single-gallery__slider" :class="{_active: currentSliderIndex === index}">
        <div class="d-object-single-gallery-slider swiper _osGallery" ref="osGallerySlider">
          <div class="swiper-wrapper">
            <div v-for="(item, i) in gallery.items" :key="`gallery-${index}-slide-${i}`" class="swiper-slide d-object-single-gallery-slider__slide">
              <img class="swiper-lazy" :data-src="item.img" alt="" :style="item.position ? `object-position: ${item.position}` : ''">
              <div class="swiper-lazy-preloader"></div>
              <div class="d-object-single-gallery-slider__disclaimer">
                <d-disclaimer :text="disclaimer"/>
              </div>
              <div class="d-object-single-gallery-slider__show-gallery-button" @click="openGallery(gallery, i)">
                <svg v-if="screenPhone" width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-scale-small"></use>
                </svg>
                <svg v-else width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-scale"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-object-single-gallery-slider__arrows">
            <d-gallery-arrows v-show="gallery.items.length > 1" button-class="osGallery"/>
          </div>
          <div v-show="gallery.items.length > 1" class="d-object-single-gallery-slider__paginationWrap">
            <div class="d-object-single-gallery-slider__pagination d-slider-pagination" data-os-gallery-slider-pagination></div>
          </div>
        </div>

      </div>
    </div>
    <d-popup-simple v-if="openedGallery" v-model="showPopup" @closed="closeGallery" :with-close="false">
      <d-object-gallery-popup :photos="openedGallery.items" :initial-slide="openedGallery.currentIndex" @close="showPopup = false"/>
    </d-popup-simple>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import DGalleryArrows from '@components/atoms/DGalleryArrows.vue';
import DDisclaimer from '@components/atoms/DDisclaimer.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import DObjectGalleryPopup from '@components/molecules/DObjectGalleryPopup.vue';
import { addWebpUrlParam } from '@utils/getSupportWebp';

export default {
  name: 'DObjectGallery',
  components: {DObjectGalleryPopup, DGalleryArrows, DDisclaimer, DPopupSimple},
  props: {
    galleryApiUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      rendered: false,
      screenPhone: false,
      showPopup: false,
      openedGallery: null,
      galleryData: [],
      currentSliderIndex: 0,
      disclaimer: 'Визуализация проекта предварительная, возможны изменения.',
      sliderOpts: {},
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
  },
  updated() {
    if (!this.rendered) {
      this.rendered = true;
      window.tabsWidthStart();
      this.initSliders();
    }
  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(await addWebpUrlParam(this.galleryApiUrl));

        this.galleryData = response.data;
        this.loaded = true;
      } catch (err) {
        console.error('error load gallery data', err);
      }
    },
    openGallery(gallery, index) {
      if (gallery.items.length) {
        this.openedGallery = Object.assign({}, gallery);
        this.openedGallery.currentIndex = index;
        this.showPopup = true;
      }
    },
    closeGallery() {
      this.showPopup = false;
      this.openedGallery = null;
    },
    initSliders() {
      this.$refs.osGallerySlider.forEach(function(el, i) {
        const slidesCount = $(el).find('.swiper-slide').length;
        if (slidesCount < 2) $(el).addClass('_empty');
        new Swiper(el, {
          preloadImages: false,
          lazy: {
            enabled: true,
            loadPrevNext: true,
            loadPrevNextAmount: 2,
          },
          loop: slidesCount > 1,
          a11y: false,
          slidesPerView: slidesCount === 1 ? 1 : 'auto',
          spaceBetween: 0,
          pagination: {
            el: '._osGallery [data-os-gallery-slider-pagination]',
            type: 'custom',
            renderCustom: function(swiper, current, total) {
              let currentSlide = current.toString();
              let totalSlide = total.toString();
              return `<span>${ currentSlide }&nbsp;-&nbsp;${ totalSlide }</span>`;
            },
          },
          navigation: {
            nextEl: '.osGallery._next',
            prevEl: '.osGallery._prev',
          },
          breakpoints: {
            320: {
              spaceBetween: 12,
              lazy: {
                loadPrevNext: true,
              },
            },
            768: {
              spaceBetween: 24,
            },
            1280: {
              lazy: {
                loadPrevNext: false,
              },
            },
          },
        });
      });
    },
  },
};
</script>
