<template>
  <teleport to="body">
    <vue-final-modal
        v-slot="{ close }"
        name="d-popup-simple"
        :esc-to-close="true"
        :click-to-close="true"
        :lock-scroll="false"
        :z-index-base="99999"
        v-bind="$attrs"
        :classes="['d-popup-simple', ...this.modClass]"
        :overlay-class="['d-popup-simple__overlay', ...this.modClass]"
        content-class="d-popup-simple__content"
        @closed="closed"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
      <div v-if="insideOverlay" class="d-popup-simple__inner">
        <slot></slot>
      </div>
      <slot v-else></slot>
      <div v-if="insideOverlay" class="d-popup-simple__overlay" @click.stop="close"></div>
      <button v-if="withClose" class="d-popup-simple__close d-button _close _blue" :class="{_small: smallClose}" @click="close">
        <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
      </button>


    </vue-final-modal>
  </teleport>

</template>

<script>
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
export default {
  name: "DPopupSimple",
  components: {
    VueFinalModal,
    ModalsContainer
  },
  props: {
    withClose: {
      type: Boolean,
      default: true
    },
    smallClose: {
      type: Boolean,
      default: true
    },
    mod: {
      type: [Array, String],
      default: null,
    },
    insideOverlay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modClass() {
      const result = [];

      if (this.mod) {
        if (Array.isArray(this.mod)) {
          this.mod.forEach((mod) => {
            result.push(`_${mod}`)
          });
        } else {
          result.push(`_${this.mod}`)
        }
      }
      return result;
    },
  },
  data() {
    return {

    }
  },
  methods: {
    closed() {
      this.$emit('closed');
    },
    beforeOpen() {
      document.querySelector('body').classList.add('_popupOpen');
    },
    beforeClose() {
      document.querySelector('body').classList.remove('_popupOpen');
    }
  }
}
</script>

<style>
.vfm--overlay.d-popup-simple__overlay {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
}
.vfm--overlay.d-popup-simple__overlay._type-single {
  background: #f2f3f7;
  backdrop-filter: none;
}

</style>

<style>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  position: relative;
  width: 50%;
  max-height: 300px;
  padding: 16px;
  overflow: auto;
  background-color: #fff;
  border-radius: 4px;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 8px 8px 0 0;
  cursor: pointer;
}
.modal-close:hover {
  color: gray;
}
</style>
