<template>
  <div class="d-choose-utp" :class="{ _list: view === 'list' }">
    <div class="d-choose-utp__title" v-html="data.title"></div>
    <div class="d-choose-utp__list">
      <a v-for="item in data.items" :key=item.id class="d-choose-utp__item" :href="item.link" target="_blank" @click.prevent="openUtp(item.text)">
        <span v-html="item.name"></span>
        <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
      </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DChooseUtpCard",
  props: {
    data: Object,
    view: String
  },
  methods: {
    openUtp(text) {
      this.$emit('open-utp', text)
    }
  }
}
</script>

<style scoped>

</style>