const coordsRange = (coords, threshold = 1) => {
    const coords2Calc = [...coords];
    const coordsFirst = coords2Calc.shift();
    const restrictCoords = coords2Calc.reduce(
        (acc, point) => {
            const cur = point.coords;
            return {
                min: [Math.min(cur[0], acc.min[0]), Math.min(cur[1], acc.min[1])],
                max: [Math.max(cur[0], acc.max[0]), Math.max(cur[1], acc.max[1])],
            };
        },
        {min: coordsFirst.coords, max: coordsFirst.coords},
    );
    if(threshold) {
        restrictCoords.min = [restrictCoords.min[0] - threshold, restrictCoords.min[1] - threshold];
        restrictCoords.max = [restrictCoords.max[0] + threshold, restrictCoords.max[1] + threshold];
    }
    return [
        restrictCoords.min,
        restrictCoords.max,
    ];
};

export default coordsRange;
