<template>
  <div v-if="contactsData" class="d-contacts">
    <div ref="callMeButtonFixed" :class="{ _show: showFixedBtnCallMeForm && currentCategory === 'services' }" class="d-contacts__fixed-btn">
      <button class="d-button _big _blue d-contacts__btn" @click="openPopupForm('callMe')">
        <span>Связаться с нами</span>
      </button>
    </div>
    <div class="d-contacts__header">
      <h1 class="d-page-title d-contacts__title">Контакты</h1>
      <div class="d-contacts__buttons _tablet">
        <button class="d-button _big _dark d-contacts__btn"  @click="openPopupForm('thanks')">
          <span>Оставить благодарность</span>
        </button>
        <button ref="callMeButton" class="d-button _big _blue d-contacts__btn" @click="openPopupForm('callMe')">
          <span>Связаться с нами</span>
        </button>
      </div>
    </div>

    <div class="d-tabs-contacts-contacts">
      <div class="d-tabs-contacts__buttons">
        <div class="d-tabs-contacts__buttonsInner">
          <button v-for="category in contactsData.categories" :key="category.id" :ref="setTabsItemRef" class="d-tabs-contacts__button" :class="{ _active: currentCategory === category.type}" @click="setCategory($event, category)">{{ category.name }}</button>
          <div ref="tabsBg" class="d-tabs-contacts__buttonBg" :style="`left: ${tabsBgBox.left}px; width: ${tabsBgBox.width}px`"></div>
        </div>
        <div class="d-contacts__buttons _desktop">
          <button class="d-button _big _dark d-contacts__btn"  @click="openPopupForm('thanks')">
            <span>Оставить благодарность</span>
          </button>
          <button class="d-button _big _blue d-contacts__btn" @click="openPopupForm('callMe')">
            <span>Связаться с нами</span>
          </button>
        </div>
      </div>
      <div v-if="salesOffices" class="d-tabs-contacts__content" :class="{_active: currentCategory === 'sales'}">
        <d-object-contacts v-if="currentCategory === 'sales'" page="contacts" :title="null" :offices-data="salesOffices"/>
      </div>
      <div v-if="serviceOffices" class="d-tabs-contacts__content" :class="{_active: currentCategory === 'services'}">
        <div class="d-contacts__list">
          <d-contacts-office-simple v-for="office in serviceOffices" :key="office.id" :data="office"/>
        </div>

      </div>
      <div v-if="centralOffices" class="d-tabs-contacts__content" :class="{_active: currentCategory === 'central'}">
        <div class="d-contacts__list">
          <d-contacts-office-simple v-for="office in centralOffices" :key="office.id" :data="office"/>
        </div>
      </div>
    </div>
    <d-popup-simple mod="contacts" v-model="showFormPopup" :with-close="false">
      <div v-if="currentFormData && showFormPopup" class="d-contacts-form-popup">
        <div class="d-callMe__form">
          <div class="d-callMe__top">
            <button class="d-contacts-form-popup__close d-button _close _blue _small" @click="showFormPopup = false">
              <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
            </button>
            <div class="d-callMe__title">{{ currentFormData.title }}</div>
            <div v-if="currentFormData.text" class="d-callMe__subtitle">{{ currentFormData.text }}</div>
            <img :src="currentFormData.img" class="d-callMe__bg">
          </div>
          <d-contacts-form :form-type="currentTypeForm" :data-action-url="currentFormData.url" data-mod="popup" :managers="currentFormData.managers ? currentFormData.managers : null" data-props="" :data-politics-link="formsData.politics" @success="openSuccessPopup"/>
        </div>
      </div>
    </d-popup-simple>
    <d-popup-simple v-model="showSuccessPopup" mod="contacts" :with-close="false">
      <div class="d-callMe__success">
        <button class="d-contacts-form-popup__close d-button _close _blue _small" @click="showSuccessPopup = false">
          <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
        </button>
        <div class="d-callMe__title">Заявка отправлена</div>
        <div class="d-callMe__subtitle" v-html="successText"></div>
        <img src="/assets/blueant/assets/img/d/callMe_bg_success.jpg" class="d-callMe__bg">
        <button class="d-callMe__button _success d-button _blue" @click="showSuccessPopup = false"><span>Хорошо, спасибо</span></button>
      </div>
    </d-popup-simple>
  </div>
</template>

<script>
import DContactsOfficeSimple from "@components/molecules/DContactsOfficeSimple.vue";
import DObjectContacts from "@components/organisms/DObjectContacts.vue";
import DContactsForm from "@components/organisms/DContactsForm.vue";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
export default {
  name: "DContacts",
  components: {
    DContactsOfficeSimple,
    DObjectContacts,
    DPopupSimple,
    DContactsForm
  },
  props: {
    apiUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentTab: 0,
      currentCategory: null,
      contactsData: null,
      salesOffices: null,
      serviceOffices: null,
      centralOffices: null,
      tabsItemRefs: [],
      tabsBgBox: {
        left: null,
        width: null
      },
      formsData: null,
      showFormPopup: false,
      currentTypeForm: null,
      currentFormData: null,
      showSuccessPopup: false,
      successText: null,
      showFixedBtnCallMeForm: false,
      headerVisible: false,
      footerVisible: false
    }
  },
  created() {
    if (this.apiUrl) {
      this.getData()
    }
  },
  beforeUpdate() {
    this.tabsItemRefs = []
  },
  mounted() {
    window.addEventListener('resize', this.updateAnimation);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateAnimation);
  },
  updated() {
    setTimeout(this.updateAnimation, 500)
  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(this.apiUrl);
        console.log('response contacts', response.data)
        this.contactsData = response.data
        this.setInitCategory(response.data.categories)
        this.salesOffices = this.filterOfficesData('sales', response.data.categories)
        this.serviceOffices = this.filterOfficesData('services', response.data.categories)
        this.centralOffices = this.filterOfficesData('central', response.data.categories)
        this.formsData = response.data.formsData

        setTimeout(this.checkCallMeButtonInViewport, 100)
        setTimeout(this.checkVisibleFooter, 100)
      } catch(err) {
        console.log('error load contacts data', err);
      }
    },
    filterOfficesData(type, data) {
      const value = data.find((category) => category.type === type)
      if (value) return value.offices
    },
    setInitCategory(data) {
      const url = new URL(location.href);
      const typeParam = url.searchParams.get('type')
      if (typeParam) {
        this.currentCategory = typeParam
      } else {
        const currentCategory = data.find((cat) => cat.init)
        if (currentCategory) this.currentCategory = currentCategory.type
        else this.currentCategory = data[0].type
      }


      this.saveStateToURL()
    },
    setTabsItemRef(el) {
      if (el) {
        this.tabsItemRefs.push(el)
      }
    },
    setCategory(e, cat) {
      this.tabsAnimation(e.target)

      this.currentCategory = cat.type;
      this.saveStateToURL()
    },
    saveStateToURL(reset = false) {
      const url = new URL(location.href);
      if (reset) {
        url.searchParams.delete('type');
        history.pushState(null, '', url);
      } else {
        url.searchParams.set('type', this.currentCategory);
        history.pushState(null, '', url);
      }
    },
    tabsAnimation(el) {

      const inner = el.parentNode;
      const container = inner.parentNode;
      this.tabsBgBox.width = el.offsetWidth
      this.tabsBgBox.left = el.offsetLeft
      if (inner.offsetWidth > container.offsetWidth) {
        container.scrollLeft = (el.offsetLeft +  el.offsetWidth / 2)  - container.offsetWidth/ 2 + inner.offsetLeft
      }
    },
    updateAnimation() {
      this.tabsItemRefs.forEach((el) => {
        if (el.classList.contains('_active')) {
          this.tabsAnimation(el)
        }
      })
    },
    checkCallMeButtonInViewport() {
      const options = {
        rootMargin: '50px',
        threshold: 1
      }
      const callback = (entries, observer) => {
        this.headerVisible = entries[0].isIntersecting === true;
        this.showFixedBtnCallMeForm = !(entries[0].isIntersecting === true || this.footerVisible);
      };
      const observer = new IntersectionObserver(callback, options);

      const target = this.$refs.callMeButton
      observer.observe(target);
    },
    checkVisibleFooter() {
      const options = {
        threshold: 0.05
      }
      const callback = (entries, observer) => {
        // this.showFixedBtnCallMeForm = !entries[0].isIntersecting;
        this.footerVisible = entries[0].isIntersecting === true;
        this.showFixedBtnCallMeForm = !(entries[0].isIntersecting === true || this.headerVisible);
      };
      const observer = new IntersectionObserver(callback, options);
      const footerEl = document.querySelector('.d-footer');

      observer.observe(footerEl);
    },
    openPopupForm(type) {
      const data = this.formsData[type]
      this.currentTypeForm = type;
      this.currentFormData = data;
      this.showFormPopup = true;
    },
    openSuccessPopup(text) {
      this.showFormPopup = false;
      this.showSuccessPopup = true;
      this.successText = text;
    }
  }
}
</script>

<style scoped>

</style>
