<template>
  <div class="d-press-pager">
    <div class="d-press-pager__inner _buttons">
      <div v-if="!prevPageDisabled" role="button" class="d-press-pager__control _prev" @click="changePage(currentPageIndex - 1)">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
      </div>
      <div class="d-press-pager__pagination">
        <div v-for="page in pages"
             role="button"
             class="d-press-pager__pagination-bullet"
             :class="{ '_prev': page === prevPageIndex, '_active': page === currentPageIndex}"
             :data-page-index="page"
             @click="changePage(page)">{{ page + 1 }}
        </div>
      </div>
      <div v-if="!nextPageDisabled" role="button" class="d-press-pager__control _next" :class="{ _disabled: nextPageDisabled}" @click="changePage(currentPageIndex + 1)">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

//import {pick as _pick} from 'lodash';

export default {
  name: 'DPressPager',
  emits: ['changePage'],
  props: {
    pagesCount: {
      type: Number,
      default: 1,
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prevPageIndex: null,
      pagesPager: [],
      pagesView: 5,
    };
  },
  watch: {
    currentPageIndex(newValue, oldValue) {
      this.prevPageIndex = oldValue;
      if (newValue >= 0) this.$nextTick(() => {
        this.reposition();
      });
    },
  },
  created() {
    window.addEventListener('resize', this.reposition);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.reposition);
  },
  mounted() {
    this.reposition(false);
  },
  computed: {
    prevPageDisabled() {
      return this.currentPageIndex === 0;
    },
    nextPageDisabled() {
      return this.currentPageIndex === this.pagesCount - 1;
    },
    pages() {
      const pagesView = this.pagesCount < this.pagesView ? this.pagesCount : this.pagesView;
      let leftSpan = Math.ceil((pagesView - 1) / 2);
      if (this.currentPageIndex + 1 - leftSpan < 0) leftSpan = 0;
      let rightSpan = pagesView - 1 - leftSpan;
      let leftEdge = this.currentPageIndex - leftSpan;
      let rightEdge = this.currentPageIndex + rightSpan;
      if (rightEdge > this.pagesCount - 1) {
        leftEdge = leftEdge - (rightEdge - (this.pagesCount - 1))
      }
      if (leftEdge < 0) leftEdge = 0;
      return [...Array(pagesView).keys()].map(x => x + leftEdge);
    },
  },
  methods: {
    reposition() {
      this.pagesView = (window.innerWidth < 768) ? 4 : 5;
    },
    changePage(index) {
      this.$emit('changePage', index);
    },
  },
};
</script>

