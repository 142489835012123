<template>
  <div :data-place-id="currentItem.guid" class="d-aux-rooms-card">
    <div class="d-aux-rooms-card__top">
      <div class="d-aux-rooms-card__name">{{ currentItem.title }}</div>
      <div class="d-aux-rooms-card__buttons">
        <a v-if="!currentItem.is_sold" class="d-aux-rooms-card__button d-button _flatIcon" :href="currentItem.link" target="_blank">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-map"></use>
          </svg>
          <span class="d-button__tooltip">Смотреть на схеме</span>
        </a>
        <button class="d-aux-rooms-card__button d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
          </svg>
          <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
          <span v-else class="d-button__tooltip">Добавить в избранное</span>
        </button>
      </div>
    </div>
    <div class="d-aux-rooms-card__info">
      <div class="d-flat-info">
        <div class="d-flat-info__row" :data-object="currentItem.object">
          <div class="d-flat-info__label">Проект</div>
          <div v-if="currentItem.object.url" class="d-flat-info__value">
            <a :href="currentItem.object.url" target="_blank">
              {{ currentItem.object.title }}
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
              </svg>
            </a>
          </div>
          <div v-else class="d-flat-info__value">{{ currentItem.object.title }}</div>
        </div>
        <div v-if="currentItem.quarter" class="d-flat-info__row">
          <div class="d-flat-info__label">Квартал</div>
          <div class="d-flat-info__value">{{ currentItem.quarter }}</div>
        </div>
        <div v-if="currentItem.building" class="d-flat-info__row">
          <div class="d-flat-info__label">Корпус</div>
          <div class="d-flat-info__value">{{ currentItem.building }}</div>
        </div>
        <div class="d-flat-info__row">
          <div class="d-flat-info__label">Уровень</div>
          <div class="d-flat-info__value">{{ currentItem.level }}</div>
        </div>
        <div class="d-flat-info__row">
          <div class="d-flat-info__label">Площадь, м&sup2;</div>
          <div class="d-flat-info__value">{{ currentItem.area }}</div>
        </div>
        <div v-if="currentItem.type" class="d-flat-info__row">
          <div class="d-flat-info__label">Тип</div>
          <div class="d-flat-info__value _info" :class="{_notip: !currentItem.sizeTip}">
            {{ currentItem.type }}
            <div v-if="currentItem.sizeTip" ref="sizeInfo" :data-v-tippy="currentItem.sizeTip" class="d-flat-info__info"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms-card__advantages">
      <div v-for="(prop, id) in currentItem.props" :key="`prop-${currentItem.id}-${id}`" class="d-flat-action" :style="{background: prop.bgColor, color: prop.color}">
        <div class="d-flat-action__name" :data-v-tippy="prop.tip">{{ prop.title }}</div>
        <a v-if="prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
      </div>
    </div>
    <div class="d-aux-rooms-card__bottom">
      <div class="d-aux-rooms-card__price">
        <div class="d-flat-price">
          <div class="d-flat-price__digits">
            <div v-if="currentItem.is_sold" class="d-flat-price__now">Продано</div>
            <template v-else>
              <div v-if="currentItem.price_old" class="d-flat-price__old">{{ currentItem.price_old }} ₽</div>
              <div class="d-flat-price__now">{{ currentItem.price_formatted }} ₽</div>
            </template>
          </div>
        </div>
      </div>
      <a v-if="!currentItem.is_sold && currentItem.booking_link" class="d-aux-rooms-card__order d-button _dark" :href="currentItem.booking_link" target="_blank"><span>Забронировать</span></a>
      <button v-else-if="!currentItem.is_sold" class="d-aux-rooms-card__order d-button _dark" data-callMe-show :data-props="placeLeadData(currentItem)">
        <span>Оставить заявку</span>
      </button>
    </div>
    <button v-if="!isFavorite" class="d-favorites-return _carPlace" @click="setFavorite">
      <span v-if="mod === 'carPlace'">Вы удалили это машиноместо&nbsp;из&nbsp;избранного</span>
      <span v-else>Вы удалили эту кладовую из&nbsp;избранного</span>
      <span class="d-favorites-return__button">Вернуть</span>
    </button>
    <a v-if="!currentItem.is_sold" class="d-aux-rooms-card__order _full" :href="currentItem.link" target="_blank"></a>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'DFavoritesParkingPlaceCard',
  emits: ['set-favorite'],
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    favorites: {
      type: Array,
      default: null,
    },
    mod: String,
  },
  computed: {
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.currentItem.form_id);
      return false;
    },
  },
  methods: {
    setFavorite() {
      const price = this.currentItem.price_request && this.currentItem.price_request_label ? this.currentItem.price_request_label : `${ this.currentItem.price_formatted } ₽`;
      this.$emit('set-favorite', {id: this.currentItem.form_id, title: this.currentItem.title, price, show: true, is_sold: this.currentItem.is_sold});
    },
    placeLeadData(item) {
      return JSON.stringify({
        'area': item.area,
        'comment': '',
        'email': '',
        'flat': item.form_id,
        'floor': item.level,
        'formType': 'reserve',
        'housing': item.quarter,
        'price': item.price,
        'section': item.building,
        'sourceConversion': '',
        'typeFlat': 'parking',
        'URL': window.location.href || '',
        'utm': Cookies.get('utm_data') || window.utm_str_src || '',
      });
    },
  },
};
</script>
