<template>
  <form ref="form" class="d-cashback-sms-form" action="" autocomplete="off">
    <div class="d-cashback-sms-form__title">На ваш номер телефона будет отправлено СМС с кодом подтверждения.</div>
    <div class="d-cashback-sms-form__inputs">
      <input v-for="inp in ['one', 'two', 'three', 'four']" :key="inp" :ref="setItemRef" class="d-cashback-sms-form__inp" :class="{_complete: codeIsComplete && !error.length, _error: error.length}" type="number"  @keyup="inputHandler($event, inp)">
      <div v-if="error.length" class="d-cashback-sms-form__error">{{ error }}</div>
    </div>
    <a v-if="canSendCodeAgain" href="" @click.prevent="requestCode" class="d-cashback-sms-form__try _link">Запросить код повторно </a>
    <div v-else class="d-cashback-sms-form__try _disabled">Запросить код повторно можно <span></span> через {{ timerMessage }}</div>
    <button type="button" class="d-button _blue d-cashback-sms-form__btn" :class="{_disable: !codeIsComplete}" @click="submit">
      <span>Подтвердить</span>
    </button>
  </form>
</template>

<script>
import declOfNum from "@utils/declOfNum";
export default {
  name: "DCashbackSmsForm",
  props: {
    error: {
      type: String,
      default: '12'
    }
  },
  data() {
    return {
      code: {
        one: null,
        two: null,
        three: null,
        four: null
      },
      formatCode: null,
      codeIsComplete: false,
      itemRefs: [],
      secondsRemaining: 60,
      intervalHandle: null,
      timerMessage: '',
      canSendCodeAgain: false,
    }
  },
  mounted() {
    this.startCountdown();
  },
  watch: {
    code: {
      handler(code) {
        this.formatCode = Object.values(code).join('')
      },
      deep: true
    },
    formatCode(value) {
      if (value.length === 4) {
        this.codeIsComplete = true
      }
    },
    error(value) {
      if (value.length) {
        this.$refs.form.reset();
        this.itemRefs[0].focus()
      }
    }
  },
  beforeUpdate() {
    this.itemRefs = []
  },
  methods: {
    inputHandler(e, name) {
      const target = e.target;
      const value = target.value;
      const nodeName = target.nextSibling.nodeName

      if (value.length > 1) {
        e.target.value = e.target.value.slice(0,1)
        target.nextSibling.focus()
      }

      if (value.length === 1 && nodeName === 'INPUT') {
        target.nextSibling.focus()
      } else {
        target.blur()
      }


      if (this.error.length) {
        this.$emit('resetError')
      }

      this.code[name] = e.target.value
    },
    submit() {
      this.$emit('submit', this.formatCode)
    },
    requestCode() {
      this.$refs.form.reset();
      this.canSendCodeAgain = false;
      this.startCountdown();
      this.codeIsComplete = false;
      this.$emit('requestCode')
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },
    tick() {
      const min = Math.floor(this.secondsRemaining / 60);
      let sec = this.secondsRemaining - min * 60;

      this.timerMessage = `${sec} ${declOfNum(sec, ['секунду', 'секунды', 'секунд'])}`;
      if (this.secondsRemaining === 0) {
        clearInterval(this.intervalHandle);
        this.canSendCodeAgain = true;
      }
      this.secondsRemaining--;
    },
    retrySend() {
      this.canSendCodeAgain = false;
      this.startCountdown();
      // this.$emit('retry');
    },
    startCountdown() {
      this.secondsRemaining = 59;
      this.intervalHandle = setInterval(this.tick, 1000);
    },
  }
}
</script>

<style scoped>

</style>