<template>
  <d-popup-simple v-model="showPopup" :with-close="false" :hide-overlay="false">
    <div class="d-callMe _contacts">
      <div class="d-callMe__form">
        <div class="d-callMe__top">
          <div class="d-callMe__title">Запрос в<br/>пресс-службу</div>
          <picture>
            <source srcset="/assets/blueant/assets/img/d/press_center_order_bg_320.jpg" media="(max-width: 767px)">
            <img class="d-callMe__bg" src="/assets/blueant/assets/img/d/press_center_order_bg.jpg">
          </picture>
        </div>
        <form class="d-callMe__content" @submit.prevent="submit">
          <div class="d-callMe__item _item1">
            <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
              <input class="d-input__field" type="text" name="" placeholder="Ваше имя*" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
              <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
            </div>
          </div>
          <div class="d-callMe__item _item3">
            <div class="d-input" :class="{ _error: v$.form.email.$errors.length }">
              <input ref="email" type="email" name="email" placeholder="Ваш e-mail*" class="d-input__field" v-model="v$.form.email.$model" @blur="v$.form.email.$touch" :disabled="loading" inputmode="email">
              <div class="d-input__error" v-for="(error, index) of v$.form.email.$errors" :key="index">Введите корректный email</div>
            </div>
          </div>
          <div class="d-callMe__item _item2">
            <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
              <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
              <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
            </div>
          </div>
          <div class="d-callMe__item _item4">
            <div class="d-textarea">
              <textarea name="text" class="d-textarea__field" placeholder="Ваше сообщение" v-model="v$.form.text.$model" :disabled="loading" @blur="v$.form.text.$touch"></textarea>
            </div>
          </div>
          <div class="d-callMe__item _item5">
            <div class="d-callMe__agree d-checkbox _agree">
              <input type="checkbox" name="agree1" id="agree_callMe1" class="d-checkbox__input" @change="v$.form.agree.$touch()" v-model="v$.form.agree.$model">
              <label for="agree_callMe1" class="d-checkbox__label _inline">Согласен с </label><a :href="politicsLink" target="_blank">политикой обработки персональных данных</a>
            </div>
            <d-recaptcha-info :errored="errored" :error-text="errorText"/>
          </div>
          <div v-if="loading" class="d-callMe__item _item6">
            <div class="d-callMe__button skeleton-button d-button _blue">
              <span class="skeleton-button__loader"></span>
            </div>
          </div>
          <div v-else class="d-callMe__item _item6">
            <button class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Отправить</span></button>
          </div>
        </form>
        <button class="d-callMe__close d-button _close _small _blue" @click="showPopup = false">
          <img src="/assets/blueant/assets/img/d/icon_close.svg">
        </button>
      </div>
    </div>
  </d-popup-simple>
</template>

<script>
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import useVuelidate from '@vuelidate/core';
import {required, sameAs, helpers, email} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import DPhoneInput from '@components/molecules/DPhoneInput.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: 'DPressCenterFormPopup',
  emits: ['update:modelValue', 'update:model-value', 'success'],
  components: {DRecaptchaInfo, DPopupSimple, DPhoneInput},
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    actionUrl: {
      type: String,
      required: true,
      default: '/assets/blueant/assets/json/form-response.json',
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        text: '',
        agree: false,
      },
      phoneParams: {
        mask: '',
        code: '',
      },
      reset: false,
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
      loading: false,
      politicsLink: '',
    };
  },
  computed: {
    showPopup: {
      get() {
        return this.modelValue;
      },
      set() {
        this.$emit('update:modelValue', false);
      },
    },
  },
  created() {
    this.politicsLink = document.querySelector('[data-callMe-show][data-politics-link]').dataset.politicsLink;
  },
  mounted() {
  },
  beforeUnmount() {
    document.querySelector('body').style.overflow = 'auto';
  },
  methods: {
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.sendData({...this.form, formType: this.formType});
      }
    },
    async sendData(formdata) {
      formdata.URL = formdata.url = window.location.href;
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        formdata.token = await this.$recaptcha(this.dataType);
        const response = await this.axios.post(this.actionUrl, formdata);
        if (response.data.error) {
          this.errored = true;
          this.errorText = response.data.error;
        }
        else if (response.data.success) {
          this.resetForm();
          this.$emit('success');
          this.showPopup = false;
          window.layoutAapp.$vfm.show({component: 'DPopupSuccess', bind: {content: response.data.success}});
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.name = '';
      this.form.phone = '+7';
      this.form.email = '';
      this.form.text = '';
      this.form.agree = false;
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.form.phone = phone;
    },
    blurInpPhone() {
      this.v$.form.phone.$touch();
      console.log('touch phone');
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Заполните это поле', required),
        },
        email: {
          required: helpers.withMessage('Заполните это поле', required),
          email,
        },
        text: {},
        phone: {
          required: helpers.withMessage('Заполните это поле', required),
          phoneHelper: helpers.withMessage('Введите номер полностью', phoneHelper(this.phoneParams)),
        },
        agree: {
          required,
          sameAs: sameAs(true),
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
