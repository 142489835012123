import {defineStore} from 'pinia';
import {orderBy as _orderBy} from 'lodash';
import declOfNum from '@utils/declOfNum';

export const useInvestStore = defineStore('invest', {
    state: () => (
        {
            loading: true,
            loaded: false,
            errored: false,
            type: null,
            page: 0,
            per_page: 4,
            apartments: [],
            showItems: [],
            price_min: 12415000,
            price_max: 635733300,
            has_commercial: true,
            sale_toolp_text: 'Все приведенные цифры и расчеты являются предварительными и могут быть изменены, вся размещенная информация носит исключительно ознакомительный характер, не является окончательной, исчерпывающей и ни при каких условиях не является публичной офертой, определяемой положениями Гражданского кодекса РФ. Для получения актуальной информации о наличии, характеристиках и стоимости указанных объектов обращайтесь к менеджерам отдела продаж.',
            price_toolp_text: 'Все приведенные цифры и расчеты являются предварительными и могут быть изменены, вся размещенная информация носит исключительно ознакомительный характер, не является окончательной, исчерпывающей и ни при каких условиях не является публичной офертой, определяемой положениями Гражданского кодекса РФ. Для получения актуальной информации о наличии, характеристиках и стоимости указанных объектов обращайтесь к менеджерам отдела продаж.',
        }
    ),
    getters: {
        showMore() {
          return this.result.items.length < this.filteredItems.length;
        },
        sortedItems() {
            console.time('sortedItems');
            const sorted = _orderBy(this.filteredItems, 'price', 'asc');
            console.timeEnd('sortedItems');
            return sorted;
        },
        filteredItems(state) {
            console.time('filteredItems');
            let items = [].concat(state.apartments);
            if (state.type && state.type === 'commercial') items = items.filter(i => i.is_commercial);
            else if (state.type) items = items.filter(i => !i.is_commercial);
            items = items.filter(i => i.price <= state.price_range[1]*1000000 && i.price >= state.price_range[0]*1000000);

            console.timeEnd('filteredItems');
            return items;
        },
        result(state) {
            console.time('result');
            const result = {
                items: this.sortedItems.slice(0, state.per_page * state.page + state.per_page),
                total: this.filteredItems.length,
                page: state.page,
                total_string: ``,
                total_prefix: ``,
                pages: 0,
            };
            // проверочное слово «эти» — «вам будут интересны эти 44 предложения»
            result.total_string = `${ result.total } ${ declOfNum(result.total, ['предложение', 'предложения', 'предложений']) }`;
            result.total_prefix
                = result.total
                  ? `Вам  ${ declOfNum(result.total, ['будет', 'будут', 'будут']) } ${ declOfNum(
                    result.total,
                    ['интересно', 'интересны', 'интересны']) }`
                  : 'Предложений не найдено';
            result.pages = Math.ceil(result.total / state.per_page);

            console.timeEnd('result');
            return result;
        },
    },
});
