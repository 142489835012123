<template>
  <div class="d-mortgage-info">
    <div class="d-mortgage-info__icon"></div>
    <div class="d-mortgage-info__text">
      Для покупки недвижимости в Донстрой действуют десятки ипотечных программ от самых надёжных банков России. Узнать информацию об актуальных ставках: <a :href="telHref" class="d-contacts-simple-office__phone">{{ tel }}</a>
    </div>
    <button class="d-button _dark" data-callMe-show><span>Подробнее</span></button>
  </div>
</template>

<script>
export default {
  name: 'DMortgageInfo',
  data() {
    return {
      tel: '',
      telHref: ''
    }
  },
  mounted() {
    this.tel = document.querySelector('.d-header__phone').innerText;
    this.telHref = document.querySelector('.d-header__phone').getAttribute('href');
  }
};
</script>
