<template>
  <div v-if="furnishData" class="flat-furnish-calc">
    <div class="flat-furnish-calc__row _top">
      <div class="flat-furnish-calc__col">
        <div class="flat-furnish-calc__content">
          <div class="flat-furnish-calc__title">{{ furnishData.content.title }}</div>
          <div v-if="furnishData.content.data && furnishData.content.data.length" class="flat-furnish-calc__info">
            <div class="flat-furnish-calc__item" :class="{ _label: item.label}" v-for="item in furnishData.content.data" :key="item.label">
              <div v-if="item.label" class="flat-furnish-calc__label">{{ item.label }}</div>
              <div class="flat-furnish-calc__text" v-html="item.text"></div>
            </div>
          </div>
        </div>

      </div>
      <div class="flat-furnish-calc__col">
        <div class="flat-furnish-calc__title">{{ furnishData.options.title }}</div>
        <div v-if="furnishData.options.data && furnishData.options.data.length" class="flat-furnish-calc__options">
          <div v-for="(option, idx) in furnishData.options.data" :key="option.label" class="flat-furnish-calc__option">
            <div  class="d-checkbox">
              <input class="d-checkbox__input" v-model="selectedOptions" type="checkbox" name="option" :id="option.label" :value="option">
              <label class="d-checkbox__label" :for="option.label">{{ option.label }}</label>
            </div>
            <button v-if="option.images" class="flat-furnish-calc__eye" @click="openImagesPopup(option)">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-eye_furnish"></use>
              </svg>
            </button>
            <div v-if="optionIsSelected(option.id) && option.quantity_available" class="flat-furnish-calc-counter">
              <button class="flat-furnish-calc-counter__btn" :class="{ _disabled: optionsAmount[idx].amount <= 1}" @click="changeCounter('decrement', idx)">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-minus"></use>
                </svg>
              </button>
              <input class="flat-furnish-calc-counter__input" type="number" v-model="optionsAmount[idx].amount" :maxlength="2" @input="inputCounterHandler($event, idx)">
              <button class="flat-furnish-calc-counter__btn" :class="{ _disabled: optionsAmount[idx].amount >= 99}" @click="changeCounter('increment', idx)">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-plus"></use>
                </svg>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="flat-furnish-calc__row _bottom">
      <div class="flat-furnish-calc__col">
        <div class="flat-furnish-calc__price-wrap">
          <div class="flat-furnish-calc__price">
            <div class="flat-furnish-calc__label">стоимость с отделкой</div>
            <div class="flat-furnish-calc__price-value">{{ price }} ₽</div>
          </div>
          <div class="flat-furnish-calc__price _opts">
            <div class="flat-furnish-calc__label">стоимость с выбранными опциями</div>
            <div class="flat-furnish-calc__price-value">{{ priceWithOptions.toLocaleString('ru-RU') }} ₽</div>
          </div>
        </div>
      </div>
      <div class="flat-furnish-calc__col">
        <div class="flat-furnish-calc__actions">
          <a v-if="(!('can_booking' in dataFlat) || dataFlat.can_booking !== false) && dataBookingUrl" class="d-flat-top__button d-button _dark" :href="`${dataBookingUrl}${furnishUrl}`" @click.prevent="bookOnline" target="_blank"><span>Забронировать онлайн</span></a>
          <button class="d-flat-top__button d-button _blue" data-callMe-show :data-action-url="dataActionUrl" :data-lead-url="dataLeadUrl" :data-props="flatLeadData" ><span>Оставить заявку</span></button>
        </div>
      </div>

    </div>
    <d-popup-simple v-model="showImagesPopup" @closed="closeImagesPopup" :with-close="false">
      <d-object-gallery-popup v-if="imagesPopupData" :photos="imagesPopupData" @close="closeImagesPopup" type="furnish"/>
    </d-popup-simple>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import DPopupSimple from "../molecules/DPopupSimple.vue";
import DObjectGalleryPopup from "../molecules/DObjectGalleryPopup.vue";

export default {
  name: "DFlatFurnishCalc",
  components: {
    DPopupSimple,
    DObjectGalleryPopup
  },
  props: {
    dataApiUrl: String,
    dataApiSendParams: String,
    dataBookingUrl: String,
    dataActionUrl: {
      type: String,
      default: '',
    },
    dataLeadUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      furnishData: null,
      dataFlat: null,
      flatId: null,
      flatLeadData: '',
      selectedOptions: [],
      imagesPopupData: null,
      showImagesPopup: false,
      amount: 1,
      optionsAmount: [],
      priceWithOptions: 0
    }
  },
  watch: {
    optionsAmount: {
      handler: function () {
        this.priceWithOptionsCalculate()
      },
      deep: true
    },
    selectedOptions: {
      handler: function () {
        this.priceWithOptionsCalculate()
      },
      deep: true
    }
  },
  created() {
    this.getData()

    if (window.flat_info) {
      if (window.flat_data) this.dataFlat = Object.freeze(Object.assign({}, window.flat_info, window.flat_data));
      else this.dataFlat = Object.freeze(window.flat_info);
    }
    if (window.flat_id) {
      this.flatId = window.flat_id;
    }

    if (window.flat_id)  this.flatId = window.flat_id;
  },
  mounted() {
    const flatLeadData = {
      'area': this.dataFlat.area,
      'comment': '',
      'email': '',
      'flat': window.flat_id,
      'floor': this.dataFlat.floor,
      'formType': 'reserve',
      'housing': this.dataFlat.korpus,
      'price': this.dataFlat.price,
      'section': this.dataFlat.section,
      'sourceConversion': '',
      'typeFlat': this.dataFlat.rooms,
      'URL': window.location.href || '',
      'utm': Cookies.get('utm_data') || window.utm_str_src || '',
    };
    this.flatLeadData = JSON.stringify(flatLeadData);
  },
  computed: {
    price() {
      if (this.dataFlat) return Number(this.dataFlat.price).toLocaleString('ru-RU')
    },
  },
  methods: {
    async getData() {
      try {
        const { data } = await this.axios.get(this.dataApiUrl);
        this.furnishData = data
        console.log('response furnish', data)

        this.furnishData.options.data.forEach((option) => {
          this.optionsAmount.push({
            id: option.id,
            amount: 1
          })
        })

        this.priceWithOptionsCalculate()

      } catch (error) {
        console.error(error);
      }
      finally {

      }
    },
    bookOnline(e) {
      try {
        dataLayerPush('event_to_GA', 'button_click', 'buy_book_online');
      } catch (e) {
        console.error('%cDFlatInfo bookOnline() error to call dataLayerPush', 'color: yellow', e);
      }
      if (window.DonStroyWidget && window.flat_id) DonStroyWidget.prototype.openBookingPopup(window.flat_id, {textBookingObject: 'квартира'});
      else {
        console.error('%cDFlatInfo bookOnline() error to call DonStroyWidget', 'color: yellow', window.DonStroyWidget, window.flat_id);
        // window.open(this.dataBookingUrl ? `${this.dataBookingUrl}${this.furnishUrl}` : `/lk/forms/?object_id=${this.flatId}${this.furnishUrl}`);
        this.sendFurnishParams()
      }
    },
    async sendFurnishParams() {
      try {
        const { renovation_type_id } = this.furnishData
        const optionsWithAmount = [];
        this.optionsAmount.forEach( item => {
          const selectedOpt = this.selectedOptions.find(opt => opt.id === item.id)
          if (selectedOpt) {
            optionsWithAmount.push({
              id: item.id,
              amount: item.amount
            })
          }
        })

        const { data } = await this.axios.post(this.dataApiSendParams, {
          flat_id: this.flatId,
          price: this.priceWithOptions,
          options: optionsWithAmount,
          renovation_type_id
        })

        if (data.id) {
          window.location = (this.dataBookingUrl ? `${this.dataBookingUrl}&furnish_options_id=${data.id}` : `/lk/forms/?object_id=${this.flatId}&furnish_options_id=${data.id}`);
        }

        console.log('resp furnish', data)
      } catch (e) {
        console.log(e)
      }
    },
    openImagesPopup(option) {
      this.showImagesPopup = true;
      this.imagesPopupData = option.images
    },
    closeImagesPopup() {
      this.showImagesPopup = false;
      this.imagesPopupData = null
    },
    inputCounterHandler(e, idx) {
      const maxLength = 2;
      const length = e.target.value.length;
      if (length > maxLength) {
        this.optionsAmount[idx].amount = e.target.value.slice(0, maxLength);
      }
      else {
        e.target.value = e.target.value.replace(/\D/g, '');
      }

      if (Number(e.target.value) === 0) this.optionsAmount[idx].amount = 1
    },
    changeCounter(type, idx) {
      if (type === 'increment') {
        this.optionsAmount[idx].amount++
      } else {
        this.optionsAmount[idx].amount--
      }
    },
    optionIsSelected(id) {
      return this.selectedOptions.find(option => option.id === id)
    },
    priceWithOptionsCalculate() {
      if (!this.selectedOptions.length) this.priceWithOptions = Number(this.dataFlat.price)
      else {
        this.priceWithOptions = this.selectedOptions.reduce((acc, current, idx) => {

          const amount = this.optionsAmount.find(item => item.id === current.id)
          return acc + (current.value * amount.amount)
        }, Number(this.dataFlat.price))
      }
    },
  }
}
</script>

<style scoped>

</style>