
var secure_key = global.secure_key;
var widget_booking = global.widget_booking;
var onloadCallback_booking = global.onloadCallback_booking = function onloadCallback_booking () {
    console.log('booking captcha');
    widget_booking = global.widget_booking = window.widget_booking = grecaptcha.render('recaptcha_booking', {
        'sitekey': '6LfYXKAUAAAAAOisGrnqgFXf2V3Dn6isREdmNCgt',
        'size': 'normal',
        'theme': 'dark',
        'callback': verifyCallback
    });
};

var widget_favorite = global.widget_favorite;
var onloadCallback_favorite = global.onloadCallback_favorite = function onloadCallback_favorite () {
    console.log('favorite captcha');
    widget_favorite = global.widget_favorite = window.widget_favorite = grecaptcha.render('recaptcha_favorite', {
        'sitekey': '6LfYXKAUAAAAAOisGrnqgFXf2V3Dn6isREdmNCgt',
        'size': 'normal',
        'theme': 'dark',
        'callback': verifyCallback
    });
};
var fav_recaptcha_check = global.fav_recaptcha_check = window.fav_recaptcha_check = function fav_recaptcha_check() {
  if (grecaptcha && (!grecaptcha.getResponse(widget_favorite) || grecaptcha.getResponse(widget_favorite) !== secure_key)) {
    grecaptcha.reset();
    return false;
  }

  return true;
};



var verifyCallback = global.verifyCallback = window.verifyCallback = function verifyCallback(response) {
    secure_key = response;
    $('#media_form').removeClass('error');
}

var LKAction = global.LKAction = window.LKAction = function LKAction(name, data, method, success) {
    window.userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    if (window.userInfo.id) {
        $.ajax({
            url: 'https://lk-dev.donstroy.com/ajax/widget/',
            method: method,
            data: {
                userId: window.userInfo.id,
                act: name,
                ...data
            },
            success(response) {
                success(response);
            }
        });
    }
}

var dataLayerPush = global.dataLayerPush = window.dataLayerPush = function dataLayerPush(event, eventAction, eventCategory, project = false) {
    if (project) {
        dataLayer.push({
            event: event,
            eventAction: eventAction,
            eventCategory: eventCategory,
            project_name: project //название ЖК
        });
    } else {
        dataLayer.push({
            event: event,
            eventAction: eventAction,
            eventCategory: eventCategory,
        });
    }
}
