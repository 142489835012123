<template>
  <div class="d-commercial-filter" :class="{ _mobile: mod === 'mobile'}">
    <div v-if="initial" class="d-commercial-filter__row _row1 _skeleton">
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
    </div>
    <div class="d-commercial-filter__title">
      Фильтр
      <a href="#" v-if="tagsItems.length" class="d-commercial-filter__reset-mobile" @click.prevent="resetFilters">Сбросить</a>
    </div>
    <div v-if="!initial" class="d-commercial-filter__row _row1">
      <div class="d-commercial-filter__item">
        <d-filter-item data-title="Объект" :is-active="filterParams.projects.length > 0" :alwaysOpen="mod === 'mobile'" mod="commercial">
          <d-filter-projects
              v-model:projects="filterParams.projects"
              v-model:quarters="filterParams.quarters"
              v-model:buildings="filterParams.buildings"
              v-if="filters.projects.length"
              :data="filters.projects"
          />
        </d-filter-item>

      </div>
      <div class="d-commercial-filter__item">
        <d-filter-item data-title="Назначение" :is-active="filterParams.purposes.length > 0" :alwaysOpen="mobileOpen" mod="commercial">
          <d-commercial-purposes v-model="filterParams.purposes" v-if="filters.purposes.length" :data="filters.purposes"/>
        </d-filter-item>

      </div>
      <div class="d-commercial-filter__item">
        <d-filter-item :is-active="filterParams.districts.length > 0" data-title="Расположение" class="" @resetFilter="resetFilter"
                       :screen-xs="screenXS" :alwaysOpen="mobileOpen" mod="commercial">
          <d-commercial-location v-model:districts="filterParams.districts" v-if="filters.districts.length" :districts-list="filters.districts"/>
        </d-filter-item>

      </div>
      <div v-if="mod === 'mobile'" class="d-commercial-filter__group">Параметры объекта</div>
      <div class="d-commercial-filter__item _range">
        <div v-if="mod === 'mobile'" class="d-commercial-filter__name">Площадь</div>
        <DInputSliderRange v-if="filters.area" mod="commercial" :init-values="[facets.area.min, facets.area.max]" unit="м²" :step="0.1" :from="filters.area.min" :to="filters.area.max"
                           type="number" v-model="filterParams.area"/>

      </div>
      <div class="d-commercial-filter__item _range">
        <div v-if="mod === 'mobile'" class="d-commercial-filter__name">Стоимость</div>
        <DInputSliderRange v-if="filters.price" mod="commercial" :init-values="[facets.price.min, facets.price.max]" unit="млн ₽" :step="0.01" :from="filters.price.min" :to="filters.price.max"
                           type="price" v-model="filterParams.price"/>
      </div>
      <div class="d-commercial-filter__item _clear">
        <button class="d-commercial-filter__clear" @click="resetFilters" :disabled="!tagsItems.length">
          <svg>
            <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-refresh'></use>
          </svg>
          Очистить фильтры
        </button>
      </div>
    </div>
    <div class="d-commercial-filter__bottom">
      <button class="d-commercial-filter__reset-tablet d-button _dark" @click="resetFilters">
        <span>Сбросить фильтры</span>
      </button>
      <button class="d-commercial-filter__view-result d-button _blue" @click="$emit('closed')">
        <span>Найдено {{ total }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {isEqual as _isEqual, debounce as _debounce} from 'lodash';
import getSearchUrl from "@utils/getSearchUrl";
import DFilterItem from "@components/organisms/DFilterItem.vue";
import DFilterProjects from "@components/molecules/DFilterProjects.vue";
import DInputSliderRange from "@components/atoms/DInputSliderRange.vue";
import DFilterSearchList from "@components/molecules/DFilterSearchList.vue";
import DCommercialLocation from "@components/molecules/DCommercialLocation.vue";
import DCommercialPurposes from "@components/molecules/DCommercialPurposes.vue";
import {toRaw} from "vue";
export default {
  name: "DCommercialFilter",
  components: {
    DFilterItem,
    DFilterProjects,
    DInputSliderRange,
    DFilterSearchList,
    DCommercialLocation,
    DCommercialPurposes
  },
  props: {
    dataFilterSpecsApi: {
      type: String,
      default: '/assets/json/commercial-filter-specs.json',
    },
    dataFilterParamsApi: {
      type: String,
      default: '/assets/json/commercial-filter-params.json',
    },
    tags: {
      type: Array,
      default: () => []
    },
    tagsItems: {
      type: Array,
      default: () => []
    },
    removeTag: {
      type: Object,
      default: () => {}
    },
    resetAllFilters: {
      type: Boolean,
      default: false
    },
    mod: {
      type: String,
      default: 'desktop'
    },
    total: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      initial: true,
      filterParams: {
        projects: [],
        quarters: [],
        buildings: [],
        purposes: [],
        districts: [],
        price: [1, 100],
        area: [1, 100],
      },
      filters: {
        price: null,
        area: null,
        projects: [],
        quarters: [],
        buildings: [],
        purposes: [],
        districts: [],
      },
      screenXS: false,
      locationFilterOpen: false,
      filterParamsLocation: [],
      locationFilter: '',
      locationSearch: '',
      facets: {},
      initialFilterParams: {},
      mobileOpen: false,
    }
  },
  computed: {
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.initFilters()
    this.$emit('update:tagsItems', [])
    window.addEventListener('popstate', this.restoreFilters)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
    window.removeEventListener('popstate', this.restoreParams);
  },
  watch: {
    filterParams: {
      handler(params) {
        if (!this.initial) {
          this.setTags(params);
          this.sendData()
        }
      },
      deep: true
    },
    removeTag(tag) {
      this.resetFilter(tag)
    },
    resetAllFilters(value) {
      console.log('value', value)
      if (value) {
        this.resetFilters()
      }
    }
  },
  methods: {
    checkScreenSize() {
      this.desktopXS = this.screenXS = window.matchMedia('(max-width: 1599px)').matches;
      this.mobileOpen = this.screenXS = window.matchMedia('(max-width: 1279px)').matches;
    },
    async initFilters() {
      await this.getSpecs();
      await this.initFilterParams();
      await this.getFacets();
      this.initial = false;
      await this.setTags(this.filterParams);
    },
    async getSpecs() {
      try {
        let response = await this.axios.get(this.dataFilterSpecsApi);
        const filters = response.data.specs;
        filters.projects = filters.projects.map(el => {
          el.type = 'projects';
          return el;
        });
        filters.districts = filters.districts.map(el => {
          el.type = 'districts';
          return el;
        });
        filters.purposes = filters.purposes.map(el => {
          el.type = 'purposes';
          return el;
        });
        this.filters = filters;
        return this.filters;
      } catch (err) {
        console.error('%cDIndexFilter getSpecs() error load getSpecs result', 'color: yellow', err);
      }
    },
    async getFacets(withFilter = false) {
      // this.setLoading(true);
      try {
        const response = withFilter
            ? await this.axios.post(this.dataFilterParamsApi, this.filterParams)
            : await this.axios.get(this.dataFilterParamsApi);

        this.facets = response.data.facets;
        console.log('this.facets', this.facets)

        const newPricesRange = [this.facets.price.min, this.facets.price.max];
        const newAreaRange = [this.facets.area.min, this.facets.area.max];

        if (_isEqual(this.initialFilterParams.price, this.filterParams.price) &&
            !_isEqual(this.filterParams.price, newPricesRange)) this.filterParams.price = newPricesRange;
        if (_isEqual(this.initialFilterParams.area, this.filterParams.area) &&
            !_isEqual(this.filterParams.area, newAreaRange)) this.filterParams.area = newAreaRange;

        return this.facets;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        // this.setLoading(false);
      }
    },
    setTags: _debounce(async function(params) {
      const tags = [];
      const facetsPrice = [this.facets.price.min, this.facets.price.max];
      if (!_isEqual(params.price, this.initialFilterParams.price) && !_isEqual(params.price, facetsPrice)) {
        const [min, max] = params.price;
        tags.push({
          filter: 'price',
          category: 'price',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } млн ₽`,
          pk: null,
        });
      }
      const facetsArea = [this.facets.area.min, this.facets.area.max];
      if (!_isEqual(params.area, this.initialFilterParams.area) && !_isEqual(params.area, facetsArea)) {
        const [min, max] = params.area;
        tags.push({
          filter: 'params',
          category: 'area',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } м²`,
          pk: null,
        });
      }
      if (params.districts.length > 0) {
        const titles = params.districts.map(district => [district, this.filters.districts.find(el => el.pk.toString() === district.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'districts',
            category: 'districts',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.purposes.length > 0) {
        const titles = params.purposes.map(purpose => [purpose, this.filters.purposes.find(el => el.pk.toString() === purpose.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'purposes',
            category: 'purposes',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.projects.length > 0) {
        const titles = params.projects.map(project => [project, this.filters.projects.find(el => el.pk.toString() === project.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'projects',
            category: 'projects',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.projects.length === 1 && params.quarters.length) {
        const currentProjectQuarters = this.filters.projects.find((project) => project.pk === params.projects[0]).quarters;
        const quarters = currentProjectQuarters.filter((quarter) => params.quarters.includes(quarter.pk))

        quarters.forEach((tag) => {
          tags.push({
            filter: 'quarters',
            category: 'quarters',
            tag_title: tag.tag_title,
            pk: tag.pk,
          });
        });
      }
      if (params.projects.length === 1 && params.quarters.length === 1) {
        const currentProjectQuarters = this.filters.projects.find((project) => project.pk === params.projects[0]).quarters;
        const currentQuarterBuildings = currentProjectQuarters.find((quarter) => quarter.pk === this.filterParams.quarters[0]).buildings;
        const buildings = currentQuarterBuildings.filter((building) => params.buildings.includes(building.pk))

        buildings.forEach((tag) => {
          tags.push({
            filter: 'buildings',
            category: 'buildings',
            tag_title: tag.tag_title,
            pk: tag.pk,
          });
        });
      }
      this.$emit('update:tagsItems', tags)
    }, 0),
    initFilterParams() {
      const urlSearch = window.location.search;
      this.initialFilterParams = {
        price: [this.filters.price.min, this.filters.price.max],
        area: [this.filters.area.min, this.filters.area.max],
        districts: [],
        projects: [],
        quarters: [],
        buildings: [],
        purposes: [],
      };


      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));

      if (urlSearch) {
        this.restoreFilters()
      }

      this.filterParamsLocation = [];
      this.sendData()
    },
    chooseLocationFilter(type) {
      this.locationSearch = '';
      this.locationFilter = type;
    },

    async resetFilters() {
      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));
      await this.getFacets();
    },
    resetFilter(tag) {
      const [filter, category, value] = [tag.filter, tag.category, tag.pk];
      if (filter === 'price') this.filterParams.price = this.initialFilterParams.price;
      if (category === 'area') this.filterParams.area = this.initialFilterParams.area;

      if (filter === 'projects') this.filterParams.projects = this.filterParams.projects.filter(v => v.toString() !== value.toString());
      if (filter === 'districts') this.filterParams.districts = this.filterParams.districts.filter(v => v.toString() !== value.toString());

      if (filter === 'quarters') this.filterParams.quarters = this.filterParams.quarters.filter(v => v.toString() !== value.toString());
      if (filter === 'buildings') this.filterParams.buildings = this.filterParams.buildings.filter(v => v.toString() !== value.toString());
      if (filter === 'purposes') this.filterParams.purposes = this.filterParams.purposes.filter(v => v.toString() !== value.toString());
    },
    sendData() {
      this.$emit('filterChanged',this.filterParams, { page: 1}, true)
    },
    restoreFilters() {
      const paramsFromUrl = getSearchUrl();
      for (let param in paramsFromUrl) {
        const cleanParam = param.replace('[]', '')
        if (this.initialFilterParams.hasOwnProperty(cleanParam)) {
          const paramNameIsArray = Array.isArray(this.initialFilterParams[cleanParam]);
          const paramValueIsArray = Array.isArray(paramsFromUrl[param])
          if ( paramNameIsArray && !paramValueIsArray ) {

            this.filterParams[cleanParam] = [paramsFromUrl[param]]
          } else {
            this.filterParams[cleanParam] = paramsFromUrl[param]
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>