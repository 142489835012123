<template>

  <d-mortgage-info v-if="showInfo" class="_purchase"/>
  <template v-else>
    <div class="d-mortgage__item _item1">
      <d-mortgage-form data-mod="purchase"
                       :data-form="dataForm"
                       :data-url-api="dataUrlApi"
                       @update:items="setItems"
                       @load:items="loadItems"
                       @load:info="showInfo = true"
                       @error="this.errored = true"
                       :reset="resetForm"
                       :sort-param="sortParam"
                       :sort-order="sortOrder"
                       :sort="true"
                       :data-prior-bank="dataPriorBank"
                       :data-loading="loading"/>
    </div>
    <div class="d-mortgage__item _item2">
      <d-mortgage-results v-if="!empty && !errored"
                          :items="items"
                          :loading="loading"
                          :empty="empty"
                          v-model:sortParam="sortParam"
                          v-model:sortOrder="sortOrder"
                          @request="sendRequest"/>
      <div v-else-if="empty" class="d-flat-mortgage-empty">
        <div class="d-flat-mortgage-empty__text">По заданным параметрам ничего не найдено. Попробуйте&nbsp;сбросить фильтр или задать другие параметры.</div>
        <button class="d-flat-mortgage-empty__button d-button _dark" @click="reset"><span>Сбросить фильтры</span></button>
      </div>
      <div v-else-if="errored" class="d-flat-mortgage-empty _error">
        <div class="d-flat-mortgage-empty__text">При загрузке данных произошла ошибка. Попробуйте обновить страницу</div>
        <button class="d-flat-mortgage-empty__button d-button _dark" @click="reload"><span>Обновить страницу</span></button>
      </div>
    </div>
    <teleport to="body">
      <d-popup v-if="bankData && showPopup" v-model="showPopup" content-class="">
        <div class="d-purchase-popup _show" data-purchase-popup>
          <div class="d-purchase-popup__top">
            <div class="d-purchase-popup__title" v-html="bankData.name"></div>
            <img class="d-purchase-popup__logo" :src="bankData.logo"></div>
          <div class="d-purchase-popup__content">
            <div class="d-purchase-popup__info">
              <div class="d-purchase-popup__facts">
                <div class="d-purchase-popup__fact">
                  <div class="d-purchase-popup__factValue">{{ bankData.percent_second ? bankData.percent_second : bankData.percent }}%</div>
                  <div class="d-purchase-popup__factLabel">ставка</div>
                </div>
                <div class="d-purchase-popup__fact">
                  <div class="d-purchase-popup__factValue">{{ bankData.initial }}%</div>
                  <div class="d-purchase-popup__factLabel">первоначальный взнос</div>
                </div>
                <div class="d-purchase-popup__fact">
                  <div class="d-purchase-popup__factValue">до&nbsp;{{ bankData.time * 12 }}</div>
                  <div class="d-purchase-popup__factLabel">срок&nbsp;кредита, мес.</div>
                </div>
              </div>
              <div class="d-purchase-popup__about">
                <div v-if="bankData.comment && bankData.comment!=='-'" class="d-purchase-popup__aboutRow">
                  <div class="d-purchase-popup__aboutLabel">Комментарий</div>
                  <div class="d-purchase-popup__aboutValue" v-html="bankData.comment"/>
                </div>
                <div v-if="bankData.conditions && bankData.conditions!=='-'" class="d-purchase-popup__aboutRow">
                  <div class="d-purchase-popup__aboutLabel">Условия применения ставки</div>
                  <div class="d-purchase-popup__aboutValue" v-html="bankData.conditions"/>
                </div>
                <div v-if="bankData.special && bankData.special!=='-'" class="d-purchase-popup__aboutRow">
                  <div class="d-purchase-popup__aboutLabel">Особые условия</div>
                  <div class="d-purchase-popup__aboutValue" v-html="bankData.special"/>
                </div>
              </div>
            </div>
            <div class="d-purchase-popup__form">
              <div class="d-purchase-form">
                <div class="d-purchase-form__title">Оставьте свои контакты для заявки на ипотеку, и&nbsp;наши менеджеры свяжутся с вами</div>
                <div id="d-purchase-form">
                  <d-call-me-form data-mod="purchase"
                                  data-type="mortgage"
                                  :data-politics-link="politicsLink"
                                  :data-action-url="dataUrlMortgageRequest"
                                  :data-lead-url="dataLeadUrl"
                                  :data-props="dataProps"
                                  @success="showPopup = false"></d-call-me-form>
                </div>
              </div>
            </div>
          </div>
          <button class="d-purchase-popup-close d-button _close _blue _mobile" @click="showPopup = false"><img src="/assets/blueant/assets/img/d/icon_close.svg"></button>
        </div>
        <button class="d-purchase-popup-close d-button _close _blue _desktop" @click="showPopup = false"><img src="/assets/blueant/assets/img/d/icon_close.svg"></button>
      </d-popup>
    </teleport>
  </template>
</template>
<script>
import DPopup from '@components/molecules/DPopup.vue';
import DCallMeForm from '@components/organisms/DCallMeForm.vue';
import DMortgageForm from '@components/molecules/DMortgageForm.vue';
import DMortgageInfo from '@components/molecules/DMortgageInfo.vue';
import DMortgageResults from '@components/molecules/DMortgageResults.vue';
import declOfNum from '@utils/declOfNum';
import {toRaw} from 'vue';
import _ from 'lodash';
import Cookies from 'js-cookie';

export default {
  name: 'DMortgage',
  components: {DMortgageForm, DMortgageResults, DPopup, DCallMeForm, DMortgageInfo},
  props: {
    dataForm: {
      type: [String, Object],
      default: '',
    },
    dataUrlApi: {
      type: String,
      default: '/assets/json/flat-mortgage-list.json',
    },
    dataUrlMortgageRequest: {
      type: String,
      default: '/assets/json/form-response.json',
    },
    dataLeadUrl: {
      type: String,
      default: '',
    },
    dataPriorBank: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      form: {},
      formdata: {},
      dataFlat: null,
      errored: false,
      loading: true,
      resetForm: false,
      offset: 0,
      sortParam: 'percent',
      sortOrder: 'asc',
      noResultFound: 'Результатов не найдено',
      showPopup: false,
      bankData: null,
      politicsLink: '',
      dataProps: {},
      showInfo: false,
    };
  },
  watch: {
    showInfo() {
      if(this.showInfo)
        document.querySelector('.d-mortgage').classList.add('_info');
    },
    showPopup(value) {
      if (!value)
        document.querySelector('body').classList.remove('_popupOpen');
    },
  },
  computed: {
    resultCount() {
      if (!this.items.length) return this.noResultFound;
      return `Найдено ${ this.items.length } ${ declOfNum(this.filterItems.length,
        ['программа', 'программы', 'программ']) } в ${ this.uniqItems.length } ${ declOfNum(this.uniqItems.length,
        ['банке', 'банках', 'банках']) }`;
    },
    uniqItems() {
      return _.uniqWith(this.items, (a, b) => b.bank_slug === a.bank_slug);
    },
    empty() {
      return this.items.length === 0;
    },
  },
  methods: {
    sendRequest(data = {}) {
      const dataProps = {
        'comment': '',
        'email': '',
        'sourceConversion': '',
        'URL': window.location.href || '',
        'utm': Cookies.get('utm_data') || window.utm_str_src || '',
      };
      this.dataProps = Object.assign({}, data, {type: 'mortgage', form_data: this.form}, dataProps);
      this.politicsLink = document.querySelector('[data-callMe-show][data-politics-link]').dataset.politicsLink;

      console.log(`%c${ this.$options.name } sendRequest this.dataProps`, 'color: lime', this.dataProps);

      this.bankData = data.bank_data;
      this.showPopup = true;
    },
    setItems(data) {
      this.form = data;
      const {price, payment, years} = data;
      this.formdata = {
        price,
        payment,
        percent: (payment * 100 / price),
        sum: price - payment,
        years,
      };
    },
    loadItems(data) {
      this.loading = false;
      this.items = data;
    },
    reset() {
      this.resetForm = true;
      setTimeout(() => {this.resetForm = false;}, 1000);
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>

</style>
