<template>
  <form class="_filter d-search">
    <div class="d-search__wrapper">
    <input ref="input" class="d-search__text" :value="search" :placeholder="placeholder" @input="sendValue">
    <button class="d-search__button" type="button" @click="sendValue">
      <img src="/assets/blueant/assets/img/d/icon_lupa.svg">
    </button>
    </div>
    <button class="d-search__button _clear" data-search-clear type="button" :class="{_active: search > ''}" @click="resetValue">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
      </svg>
    </button>
    <div class="d-search__stations" data-search-stations data-simplebar>
      <div v-for="item in showItems" :key="item.pk" class="d-search__station d-checkbox" :class="{_disabled: !inFiltered(item)}">
        <input class="d-checkbox__input" type="checkbox" name="location" :id="`${idComponent}${listId}-${item.locationType}-${item.pk}`" v-model="selected" :value="item.pk" :disabled="!inFiltered(item)">
        <label class="d-checkbox__label" :for="`${idComponent}${listId}-${item.locationType}-${item.pk}`">
          <div>{{ item.filter_title }}</div>
          <div class="d-metro-way">
            {{ item.text }}
            <img v-if="item.type && item.type === 'walk'" src="/assets/blueant/assets/img/d/icon_people.svg">
            <img v-else-if="item.type && item.type === 'car'" src="/assets/blueant/assets/img/d/icon_car.svg">
          </div>
          <div v-if="item.color.length" class="d-metro">
            <div v-for="colorCode in item.color" :key="`${item.pk}-color-${colorCode}`" class="d-metro__color" :style="{background: colorCode}"></div>
          </div>
        </label>
      </div>
    </div>
  </form>
</template>

<script>
import {isEqual as _isEqual, uniqueId as _uniqueId, orderBy as _orderBy} from 'lodash';

export default {
  name: 'DFilterSearchList',
  emits: ['update:checked', 'update:search'],
  props: {
    placeholder: {
      type: String,
      default: 'Поиск...',
    },
    search: {
      type: String,
      default: '',
    },
    type: {
      type: [String, Boolean],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    facets: {
      type: Array,
      default: () => [],
    },
    checked: {
      type: Array,
      default: () => [],
    },
    listId: {
      type: String,
      default: '',
    },
    districtList: {
      type: Array,
      default: () => [],
    },
    metroList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      idComponent: null,
      selected: [],
    };
  },
  computed: {
    enabledFacets() {
      let returnData = this.items;
      if (this.selectedDistrictsIds.length)
        returnData = returnData.filter(item => !('district' in item) || this.selectedDistrictsIds.includes(item.district));
      if (this.selectedMetrosIds.length)
        returnData = returnData.filter(item => ('district' in item) || this.filteredDistrictsIdsByMetro.includes(item.pk));
      return returnData.map(item => item.pk).filter(pk => this.facets.includes(pk));
    },
    selectedDistrictsIds() {
      return this.districtList.filter((pk) =>{
          return this.selected.includes(pk);
      });
    },
    selectedMetrosIds() {
      return this.metroList.filter((pk) =>{
        return this.selected.includes(pk);
      });
    },
    selectedMetros() {
      return this.items.filter((item) =>{
          return ('district' in item && this.selected.includes(item.pk));
      });
    },
    filteredDistrictsIdsByMetro() {
      if (!this.selectedMetrosIds.length) return this.districtList;
      return this.districtList.filter(pk => this.selectedMetros.map(metro => metro.district).includes(pk));
    },
    showItems() {
      let items = this.type ? this.items.filter(el => el.locationType === this.type) : this.items;
      if (this.search) items = items.filter(el => el.filter_title.toLocaleLowerCase('ru-RU').includes(this.search.toLocaleLowerCase('ru-RU')));
      items = _orderBy(items.filter(el => this.facets.includes(el.pk)).map(el => {
        el.selected = this.selected.includes(el.pk);
        return el;
      }), 'selected', 'desc');
      return items;
    },
  },
  watch: {
    checked: {
      immediate: true,
      handler(params) {
        //console.log('%cwatch checked params = ', 'color: yellow', params);
        if (!_isEqual(this.checked, this.selected)) this.selected = this.checked;
      },
      deep: true,
    },
    selected: {
      handler(params) {
        //console.log('%cwatch selected params = ', 'color: yellow', params);
        this.$emit('update:checked', params);
      },
      deep: true,
    },
  },
  mounted () {
    this.idComponent = _uniqueId('item_');
  },
  methods: {
    resetValue() {
      this.$emit('update:search', '');
    },
    sendValue(e) {
      this.$emit('update:search', this.$refs.input.value);
    },
    inFiltered(item) {
      return this.enabledFacets.includes(item.pk) || this.selected.includes(item.pk)
    },
  },
};
</script>
