import Cookies from 'js-cookie';

function registerUTM() {
    const marks = window.location.href.match(/(utm(-|_)[^&=]+=[^&=]+)/gi);
    if (marks) {
        const UTM = marks.reduce((p, c) => `${ p }&${ c }`, '').replace(/^&/, '');
        window.localStorage.setItem('utm_data', UTM);
        if(UTM) Cookies.set('utm_data', UTM, {expires: 180});
    }
    let UTM = Cookies.get('utm_data');
    if (!UTM) UTM = window.localStorage.getItem('utm_data') || '';
    window.utm_str_src = UTM;
}

if (!window.utm_str_src) registerUTM();
