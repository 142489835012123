<template>
  <div class="d-aux-rooms-line _carPlaceSkeleton">
    <div class="d-aux-rooms-line__item _name">
      <div class="skeleton-field"></div>
    </div>
    <div class="d-aux-rooms-line__item _all">
      <div class="skeleton-field"></div>
    </div>
    <div class="d-aux-rooms-line__item _price">
      <div class="skeleton-field"></div>
    </div>
    <div class="d-aux-rooms-line__item _buttons">
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DParkingPlaceLineSkeleton',
}
</script>