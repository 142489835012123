<template>
  <template v-if="loading">
    <d-favorites-preloader :mode="favoritesApp"/>
  </template>
  <template v-else-if="errored">
    <d-favorites-info :mode="favoritesApp === 'carPlace' ? 'parking' : 'storeroom'" :error="true"/>
  </template>
  <template v-else-if="empty">
    <d-favorites-info :mode="favoritesApp === 'carPlace' ? 'parking' : 'storeroom'" :error="false"/>
  </template>
  <template v-else>
    <div class="d-favorites__section">
      <div class="d-favorites-section">
        <template v-if="view === 'card'">
          <TransitionGroup name="cards" tag="div" class="d-favorites-section__list _aux">
            <d-favorites-parking-place-card v-for="item in datasetSorted"
                                            :key="item.form_id"
                                            :currentItem="item"
                                            :favorites="favorites"
                                            :class="`_${favoritesApp}`"
                                            :mod="favoritesApp"
                                            @openItem="openItem"
                                            @set-favorite="setFavorite"/>
          </TransitionGroup>
        </template>
        <template v-else>
          <div class="d-favorites-section__list _line">
            <div class="d-favorites-section__sorter">
              <div class="d-sorter-line" :class="`_${favoritesApp}`">
                <div class="d-sorter-line__item _item1">Объект</div>
                <div class="d-sorter-line__item _item2">Корпус</div>
                <div class="d-sorter-line__item _item3">Номер</div>
                <div class="d-sorter-line__item _item4">Тип</div>
                <div class="d-sorter-line__item _item5">Уровень</div>
                <div class="d-sorter-line__item _item6">Площадь, м²</div>
                <div class="d-sorter-line__item _item7">Стоимость, ₽</div>
                <div class="d-sorter-line__item _item8">Особенности</div>
                <div class="d-sorter-line__item _item9">&nbsp;</div>
              </div>
            </div>
            <TransitionGroup name="list" tag="">
              <d-favorites-parking-place-line v-for="item in datasetSorted"
                                              :key="item.form_id"
                                              :currentItem="item"
                                              :favorites="favorites"
                                              :class="`_${favoritesApp}`"
                                              :mod="favoritesApp"
                                              :screenPhone="screenPhone"
                                              @openItem="openItem"
                                              @set-favorite="setFavorite"/>
            </TransitionGroup>
          </div>
        </template>
      </div>
    </div>
  </template>
  <teleport v-if="dataset && dataset.length" to=".d-favorites__sorter">
    <d-select :data-options="sortingData" v-model="sortParam" icon="sort2"/>
  </teleport>
  <teleport v-if="dataset && dataset.length" to=".d-favorites__switchView">
    <div class="d-switch-view" :class="{ _list: view === 'list'}">
      <button class="d-switch-view__button" :class="{ _active: view === 'list'}" @click="changeView()">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-list"></use>
        </svg>
      </button>
      <button class="d-switch-view__button" :class="{ _active: view === 'card'}" @click="changeView()">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-card"></use>
        </svg>
      </button>
      <div class="d-switch-view__buttonBg"></div>
    </div>
  </teleport>
</template>

<script>
import DSelect from '@components/molecules/DSelect.vue';
import {debounce as _debounce, orderBy as _orderBy, pick as _pick} from 'lodash';
import DFavoritesParkingPlaceCard from '@components/molecules/DFavoritesParkingPlaceCard.vue';
import DFavoritesParkingPlaceLine from '@components/molecules/DFavoritesParkingPlaceLine.vue';
import DFavoritesInfo from '@components/molecules/DFavoritesInfo.vue';
import DFavoritesPreloader from '@components/molecules/DFavoritesPreloader.vue';
import Cookies from 'js-cookie';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import getTouchDevice from '@utils/getTouchDevice';

export default {
  name: 'DFavoritesParking',
  components: {
    DFavoritesParkingPlaceCard,
    DFavoritesParkingPlaceLine,
    DFavoritesPreloader,
    DFavoritesInfo,
    DSelect,
  },
  props: {
    favoritesApp: {
      type: String,
      default: '',
    },
    apiUrl: {
      type: String,
      default: '/assets/blueant/assets/json/favorites-parking-api.json',
    },
  },
  data() {
    return {
      view: 'card',
      sortParam: 'price-asc',
      sortOrder: 'asc',
      sortValue: 'price',
      favorites: [],
      touchDevice: false,
      screenPhone: false,
      notificationDuration: 5000,
      dataset: null,
      favoritesLocal: [],
      empty: false,
      loading: true,
      loaded: false,
      errored: false,
      sortingData: [
        {
          id: 1,
          name: 'По возрастанию цены',
          value: 'price-asc',
        },
        {
          id: 2,
          name: 'По убыванию цены',
          value: 'price-desc',
        },
        {
          id: 3,
          name: 'По возрастанию уровня',
          value: 'level-asc',
        },
        {
          id: 4,
          name: 'По убыванию уровня',
          value: 'level-desc',
        },
        {
          id: 5,
          name: 'По возрастанию площади',
          value: 'area-asc',
        },
        {
          id: 6,
          name: 'По убыванию площади',
          value: 'area-desc',
        },
      ],
      favoritesCounter: null,
    };
  },
  watch: {
    sortParam(value) {
      [this.sortValue, this.sortOrder] = value.split('-');
    },
    favoritesTotal: {
      immediate: true,
      handler(value) {
        if (!this.favoritesCounter) return;
        if (value === 0) this.favoritesCounter.removeAttribute('data-count');
        else if (value > 9) this.favoritesCounter.setAttribute('data-count', '9+');
        else this.favoritesCounter.setAttribute('data-count', value);
        window.tabsWidthStart();
      },
    },
  },
  computed: {
    favoritesTotal() {
      return this.dataset ? this.favoritesLocal.length : null;
    },
    datasetSorted() {
      return this.dataset ? _orderBy(this.dataset, [this.sortValue], [this.sortOrder]) : [];
    },
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.checkScreenSize();
    this.favorites = window.getFavorites();
    this.checkIsEmpty();
    this.favoritesCounter = document.querySelector('button.d-tabs__button._active');
    if (!this.empty) this.getData();
    else this.dataset = [];
  },
  updated() {
    this.refill();
  },
  methods: {
    checkIsEmpty() {
      if (!this.favorites.length) {
        this.loading = false;
        this.empty = true;
      }
    },
    checkScreenSize() {
      this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
      this.touchDevice = getTouchDevice();
      this.refill();
    },
    refill() {
      console.log('refill');
      const self = this;
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              trigger: this.touchDevice ? 'click' : 'mouseenter',
              allowHTML: true,
              interactive: true,
              appendTo: () => document.body,
              offset: [0, 8],
              interactiveDebounce: 10,
              theme: this.touchDevice ? 'index-filter-touch' : 'index-filter',
              placement: 'top',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true,
              onShow(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').addEventListener('click', () => {
                  instance.hide();
                });
              },
              onHide(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').removeEventListener('click', () => {
                  instance.hide();
                });
              },
            });
          }
        });
      });
    },
    getData: _debounce(async function() {
      this.loading = true;
      try {
        const response = await this.axios.post(this.apiUrl, this.favorites, {
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        if (response.status !== 200) {
          console.error(response);
          this.errored = true;
        }
        if (!response.data.length) {
          this.empty = true;
        }
        this.dataset = response.data;
        this.favoritesLocal = this.dataset.map(item => item.form_id).filter(item => this.favorites.includes(item));
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
        this.loaded = true;
      }
    }, 500),
    async changeView() {
      this.view = this.view === 'list' ? 'card' : 'list';
      this.refill();
    },
    openItem(item) {
      window.open(item.link);
    },
    async setFavorite(data) {
      if (!window.inFavorites(data.id)) {
        const result = await window.addToFavorites(data.id);
        if (result) this.favoritesLocal.push(data.id);
      }
      else {
        const result = await window.removeFromFavorites(data.id);
        if (result) this.favoritesLocal = this.favoritesLocal.filter(f => f !== data.id);
      }
      this.favorites = window.getFavorites();
    },
  },
};
</script>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active,
.cards-move,
.cards-enter-active,
.cards-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(300px);
}

.cards-enter-from,
.cards-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
.cards-leave-active,
.list-leave-active {
  position: absolute;
}
</style>
