import mapsInit from "../legacy/mapsInit";

const popupContactMap = ($mapContainer, markerData) => {
    const maps = new mapsInit();
    const $mapEl = $mapContainer.find('[data-map]')
    // console.log('markerData', markerData)
    const coords = [55.715404,37.506907]

    const options = {
        disableScroll: true,
        // disableScroll: false,
        coords: coords,
        letter: '',
        route: true,
    }
    let officeIsActiveRouteEnable = false

    new maps.init({
            el: $mapEl,
            center: options.coords,
            zoomButtons: $mapContainer.find('.mapsInit__zoom-button'),
            zoom: 8,
            disableScroll: options.disableScroll
        },
        function (map) {
            var suggestView = new ymaps.SuggestView('suggest');
            suggestView.events.add('select', function () {
                geocode();
            })


            const object_marker = new maps.objectMarker({
                position: markerData.coords,
                size: [12, 12],
                offset: [-6, -12],
                html: `
                    <div class="ba-marker-object ba-marker-inner hover">
                        <div class="ba-object-item__marker-bubble">${markerData.name}</div>
                    </div>
                    
                `
            });

            map.setBounds(map.geoObjects.getBounds(), {
                checkZoomRange: true,
                zoomMargin: 50
            });


            $('[data-offices-preview]')
                .on('click', '.offices-contacts-card-item', function () {
                    const $this = $(this);
                    markers.forEach((m, i) => {
                        if (i === $this.data('targ')) {
                            m.$el.addClass('hover');
                            m.options.set('visible', true);
                            map.panTo(window.officesData[i].coords.map(coord => Number(coord)));
                        } else {
                            m.options.set('visible', false);
                            m.$el.addClass('hidden');
                        }
                    });
                })
                .on('mouseleave', '.offices-contacts-card-item', function () {
                    const $this = $(this);

                    markers.forEach((m, i) => {
                        if (i === $this.data('targ')) m.$el.removeClass('hover');
                        else m.$el.removeClass('hidden');
                    });
                });



            let route = null;
            if (options.route) {
                route = new maps.route();
                let help_visible = true;

                function add_route(coords) {

                    route.toggle(true, coords, markerData.coords, true, true);

                    if (help_visible) {
                        $('body').find('.map__route-help').addClass('hidden');
                        help_visible = false;
                    }

                }



                $('body').find('.map__route-help').delay(500).queue(function (next) {
                    $(this).removeClass('hidden');
                    next();
                });


                $mapContainer
                    .on('click', '[data-map-geo]', function () {
                        navigator.geolocation.getCurrentPosition((position) => {
                            add_route([position.coords.latitude, position.coords.longitude]);
                        });
                    });
            }

            function geocode() {
                // Забираем запрос из поля ввода.
                var request = $('#suggest').val();
                // Геокодируем введённые данные.
                ymaps.geocode(request).then(function (res) {
                    var obj = res.geoObjects.get(0),
                        error, hint;
                    const coordsSelect = obj.geometry.getCoordinates()



                    route.toggle(true, coordsSelect, markerData.coords, true, true);

                }, function (e) {
                    // console.log(e)
                })

            }

            const $tabs = $('[data-tabs-object-contacts]')
            $tabs.each((idx, el) => {
                const $el = $(el)
                const $tabItem = $el.find('[data-tab-id]');
                const initTabID = $tabItem.eq(0).data('tab-id')

                $el.find(`[data-tab-content]`).removeClass('_active')
                $el.find(`[data-tab-content="${initTabID}"]`).addClass('_active')

                $tabItem.on('click', function () {
                    const id = $(this).data('tab-id')
                    const $contentActive = $el.find(`[data-tab-content="${id}"]`)
                    const $routeTab = $tabItem.eq(1)

                    if (id === 0) {
                        $('#suggest').val('')
                        map.panTo(markerData.coords.map(coord => Number(coord)));
                    }

                    $tabItem.removeClass('_active')
                    $(this).addClass('_active')
                    $el.find(`[data-tab-content]`).removeClass('_active')
                    $contentActive.addClass('_active')


                    if ($routeTab.hasClass('_active')) {
                        officeIsActiveRouteEnable = true
                        $('.map-geo-button').show()
                    } else {
                        officeIsActiveRouteEnable = false
                        route.toggle(false);
                        $('.map-geo-button').hide()
                    }
                })
            })

            map.container.fitToViewport();
        }
    );
}

export default popupContactMap