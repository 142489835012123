function formatMoney(value) {
    if (!value) return value;
    let newValue = null;
    if (typeof value === 'number') {
        newValue = Number(value.toString().replace(/\D/g, ''));
    }
    else {
        newValue = Number(value.replace(/[^\d.-]/g, ''));
    }

    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        maximumSignificantDigits: 11,
    })
        .format(newValue)
        .replace(/,/g, ' ');
}

export default formatMoney;
