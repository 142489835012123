<template>
  <div class="d-object-single-progress">
    <div class="d-object-single-progress__title d-page-title">Ход строительства</div>
    <div class="d-object-single-progress__controls">
      <div class="d-object-single-progress__selects">
        <d-select-progress v-if="yearsSelectData" v-model="currentYear" :data-options="yearsSelectData"></d-select-progress>
        <d-select-progress v-if="monthsSelectData" v-model="currentMonth" :data-options="monthsSelectData"></d-select-progress>
        <d-select-progress v-if="quartersSelectData" v-model="currentQuarter" :data-options="quartersSelectData"></d-select-progress>
      </div>
      <button v-if="camerasData && camerasData.length" class="d-object-single-progress__button d-button _blue" @click="openCamerasPopup">
        <span>Онлайн-камеры</span>
      </button>
    </div>

    <div v-if="filteredAlbums" class="d-object-single-progress__slider">
      <d-progress-slider :data="filteredAlbums" @openPopup="openPopup"/>
    </div>
    <d-popup-simple v-model="showPopup" :with-close="false" mod="progress">
      <d-progress-popup v-if="currentAlbumData" :data="currentAlbumData" @close="closeProgressPopup"/>
    </d-popup-simple>

    <d-popup-simple v-model="showCameras" :with-close="false" mod="center">
      <div class="d-object-progress-cameras">
        <button class="d-object-progress-cameras__close d-button _close _big _blue" @click="closeCameraPopup">
          <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
        </button>
        <div class="d-object-progress-cameras__header">
          <d-select class="d-object-progress-cameras__select" v-if="camerasData" dataReturnParam="id"  v-model="currentCameraId" :data-options="camerasData"/>
          <button class="d-object-progress-cameras__share d-button" data-social-share-list data-social-share-mod="camera">
            <img src="/assets/blueant/assets/img/d/icon_share.svg" alt="">
          </button>
        </div>
        <template v-for="camera in camerasData" :key="camera.id">
          <div v-if="camera.id === currentCameraId" class="d-object-progress-cameras__content">
            <iframe class="d-object-progress-cameras__iframe" :src="camera.url" frameborder="0" allowfullscreen></iframe>
          </div>
        </template>

      </div>
    </d-popup-simple>
  </div>
</template>

<script>
import DSelectProgress from "../molecules/DSelectProgress.vue";
import DProgressSlider from "../molecules/DProgressSlider.vue";
import DPopupSimple from "../molecules/DPopupSimple.vue";
import DProgressPopup from "../molecules/DProgressPopup.vue";
import DSelect from "@components/molecules/DSelect.vue";
import { addWebpUrlParam } from '@utils/getSupportWebp';

export default {
  name: "DObjectProgress",
  components: {
    DSelectProgress,
    DProgressSlider,
    DPopupSimple,
    DProgressPopup,
    DSelect
  },
  props: {
    progressApiUrl: {
      type: String,
      default: ''
    },
    camerasApiUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      progressData: null,
      currentYear: null,
      currentMonth: null,
      currentQuarter: null,
      yearsSelectData: null,
      monthsSelectData: null,
      quartersSelectData: null,
      filteredAlbums: null,
      swiper: null,
      showPopup: false,
      currentAlbumData: null,
      showCameras: null,
      camerasData: null,
      currentCameraId: null,
    }
  },
  watch: {
    currentYear() {
      this.getMonthsSelectData()
      this.setInitMonth()
      this.getQuartersSelectData()
      this.setInitQuarter();

      this.filterAlbums()
    },
    currentMonth() {
      this.getQuartersSelectData()
      this.setInitQuarter();

      this.filterAlbums()
    },
    currentQuarter() {
      this.filterAlbums()
    },
    currentCameraId() {
      this.saveStateCameraToURL()
    },
    showCameras(value) {
      if (!value) {
        this.saveStateCameraToURL(true)
      }
    }
  },
  created() {
    this.getData()
    this.getCameras()
  },
  mounted() {

  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(await addWebpUrlParam(this.progressApiUrl));

        this.progressData = response.data

        this.getYearsSelectData()
        this.setInitYear()

        this.getMonthsSelectData()
        this.setInitMonth()

        this.getQuartersSelectData()
        this.setInitQuarter()

        this.filterAlbums()
      } catch(err) {
        console.error('error load progress data', err);
      }
    },
    async getCameras() {
      try {
        let response = await this.axios.get(this.camerasApiUrl);
        // console.log('response progress', response)
        this.camerasData = response.data
        this.openCameraFromURL()
      } catch(err) {
        console.log('error load progress data', err);
      }
    },
    getYearsSelectData() {
      const result = [];

      this.progressData.forEach((item) => {
        result.push(
            {
              id: item.id,
              name: item.year,
              value: item.year,
              init: item.init
            }
        )
      })
      this.yearsSelectData = result
    },
    setInitYear() {
      if (this.yearsSelectData) {
        const initYear = this.yearsSelectData.find((year) => year.init)
        initYear ? this.currentYear = initYear.value : this.currentYear = this.yearsSelectData[0].value
      }
    },
    getMonthsSelectData() {
      const result = [];
      const currentYear = this.progressData.find((year) => year.year === this.currentYear)
      const months = currentYear.months;

      months.forEach((month) => {
        result.push(
            {
              id: month.id,
              name: month.name,
              value: month.value,
              init: month.init
            }
        )
      })
     this.monthsSelectData = result;
    },
    setInitMonth() {
      if (this.monthsSelectData) {
        const initMonth = this.monthsSelectData.find((month) => month.init)
        initMonth ? this.currentMonth = initMonth.value : this.currentMonth = this.monthsSelectData[0].value
      }
    },
    getQuartersSelectData() {
      const result = [];
      result.push(
          {
            id: 99999999999,
            name: 'Все кварталы',
            value: 'all',

          }
      )
      const currentYear = this.progressData.find((year) => year.year === this.currentYear)

      const months = currentYear.months;
      const currentMonth = months.find((month) => month.value === this.currentMonth)
      if (currentMonth) {

        currentMonth.albums.forEach((album) => {
          result.push({
            id: album.id,
            name: album.name,
            value: album.id,
          })
        })

        this.quartersSelectData = result
      }
    },
    setInitQuarter() {
      this.currentQuarter = 'all'
    },
    filterAlbums() {
      this.filteredAlbums = null
      const currentYear = this.progressData.find((year) => year.year === this.currentYear)


      if (currentYear) {
        const yearValue = currentYear.year;
        const currentMonth = currentYear.months.find((month) => month.value === this.currentMonth )

        let result = []
        if (currentMonth) {
          const monthValue = currentMonth.name;
          currentMonth.albums.forEach((album) => {
            result.push(Object.assign({}, album, {year: yearValue, month: monthValue}))
          })

          if (this.currentQuarter !== 'all') {
            result = result.filter((album) => album.id === this.currentQuarter)
          }
          this.filteredAlbums = result
        }
      }
    },
    openPopup(album) {
      this.currentAlbumData = album
      this.showPopup = true
    },
    changeCamera(event, id) {
      this.currentCameraId = id
    },
    openCamerasPopup() {
      this.showCameras = true;
      this.currentCameraId = this.camerasData[0].id;
      this.saveStateCameraToURL()
      window.update_social_share();
    },
    saveStateCameraToURL(reset = false) {
      const url = new URL(location.href);
      if (reset) {
        url.searchParams.delete('camera');
        history.pushState(null, '', url);
      } else {
        url.searchParams.set('camera', this.currentCameraId);
        history.pushState(null, '', url);
      }
    },
    share(type) {
      const url = window.location.href;
      const currentCamera = this.camerasData.find((camera) => camera.id === this.currentCameraId)
      const title = `Онлайн камера ${currentCamera.name}`
      const services = {
            vk: `http://vk.com/share.php?url=${url}&title=${title}`,
            tg: `https://t.me/share/url?url=${url}&text=${title}`,
          };
      if (services[type]) {
        window.open(services[type]);
      }
    },
    openCameraFromURL() {
      const url = new URL(location.href);
      const cameraParam = url.searchParams.get('camera');
      if (cameraParam && this.camerasData) {
        const destination = $('#section-progress');
        const $objectHeader = $('[data-object-header]');
        const offset = $objectHeader.outerHeight() + $objectHeader.position().top
        $('html,body').animate({
          scrollTop: destination.offset().top - offset
        },'slow');
        const currentCamera = this.camerasData.find((camera) => camera.id === cameraParam)
        this.showCameras = true;
        this.currentCameraId = currentCamera.id
        window.update_social_share();
      }
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href);
    },
    closeProgressPopup() {
      this.showPopup = false;
    },
    closeCameraPopup() {
      this.showCameras = false;
      document.querySelector('body').style.overflow = 'auto';
    }
  }
}
</script>

<style scoped>

</style>
