<template>

  <div class="d-investment-card" :class="{_commercial: item.is_commercial}">
    <a class="d-investment-card__link" :href="item.url" target="_blank" />
    <div class="d-investment-card__img">
      <img :src="item.path">
    </div>
    <div class="d-investment-card__top">
      <div class="d-investment-card__title">{{ item.title }}</div>
      <div class="d-investment-card__project">{{ item.zhk_name }}</div>
      <div class="d-investment-card__more d-button _small _grey">
        <span>Подробнее</span>
      </div>
    </div>
    <div class="d-investment-card__bottom">
      <div class="d-investment-card__info">
        <div class="d-investment-card__infoItem">
          <div class="d-investment-card__infoLabel">Стоимость</div>
          <div class="d-investment-card__infoValue">{{ item.price_formatted }} ₽</div>
          <a class="d-investment-card__mortgage" :href="`/mortgage/?price-initial=${item.price}`" target="_blank">Рассчитать ипотеку</a>
        </div>
        <div class="d-investment-card__infoItem">
          <div class="d-investment-card__infoLabel">Доход от роста цены при&nbsp;продаже за 3 г.</div>
          <div class="d-investment-card__infoValue">до {{ item.invest_rent_cost_per_year }} ₽ / {{ item.invest_percent }}%
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-info_n"></use>
            </svg>
            <div class="d-investment-card__infoToolp">{{ sale_toolp_text }}</div>
          </div>
        </div>
        <div v-if="item.rent_profit_to" class="d-investment-card__infoItem">
          <div class="d-investment-card__infoLabel">Доход от сдачи в&nbsp;аренду за 1 г.</div>
          <div class="d-investment-card__infoValue">до {{ item.rent_profit_to }} ₽</div>
        </div>
      </div>
      <div class="d-investment-card__buttons">
        <button class="d-investment-card__button d-button _dark" data-callme-show>
          <span>Получить консультацию</span>
        </button>
        <a v-if="item.bookink_link" :href="item.bookink_link" class="d-investment-card__button d-button _blue" target="_blank">
          <span>Онлайн-бронирование</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'pinia';
import {useInvestStore} from '@/stores/invest';
export default {
  name: 'DInvestItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState(useInvestStore, [
      'sale_toolp_text',
      'price_toolp_text',
    ]),
  }
};
</script>

<style scoped>

</style>
