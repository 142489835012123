<template>
  <template v-if="loading">
    <d-favorites-preloader/>
  </template>
  <template v-else-if="errored">
    <d-favorites-info mode="flat" :error="true"/>
  </template>
  <template v-else-if="empty">
    <d-favorites-info mode="flat" :error="false"/>
  </template>
  <template v-else-if="dataset">
    <div v-for="(data, i) in datasetSorted" :key="i" class="d-favorites__section">
      <div class="d-favorites-section">
        <div class="d-favorites-section__title" v-html="data.title"></div>
        <template v-if="view === 'card'">
          <div class="d-favorites-section__list">
            <div class="swiper" data-favorites-flat>
              <TransitionGroup name="cards" tag="div" class="swiper-wrapper">
                <div v-for="item in data.items" :key="item.id" class="swiper-slide">
                  <d-favorites-flat-card mod="favorites" :data="item" :object="i" :favorites="favorites" @set-favorite="setFavorite"/>
                  <d-favorites-pdf-info v-if="item.pdf" :pdf="item.pdf" @show-popup="openEmailPopup(item.pdf)"/>
                </div>
              </TransitionGroup>
            </div>
            <div class="d-favorites-section__arrows">
              <d-slider-arrows mod="dark" :name="`favorites${i+1}`"/>
            </div>
          </div>
          <d-favorites-pdf-info v-if="data.pdf" :pdf="data.pdf" :object="i" @show-popup="openEmailPopup(data.pdf)"/>
        </template>
        <template v-else>
          <div class="d-favorites-section__list _line">
            <div class="d-favorites-section__sorter">
              <div class="d-sorter-line _choose _favorites">
                <div class="d-sorter-line__item _item1">Планировка</div>
                <div class="d-sorter-line__item _item2"><span>Комнатность</span><span class="short">Комнатность</span></div>
                <div class="d-sorter-line__item _item3">Объект</div>
                <div class="d-sorter-line__item _item4">Корпус</div>
                <div class="d-sorter-line__item _item5"><span>Этаж</span><span class="short">Этаж</span></div>
                <div class="d-sorter-line__item _item6">Номер</div>
                <div class="d-sorter-line__item _item7"><span>Площадь, м&sup2;</span><span class="short">Площадь, м&sup2;</span></div>
                <div class="d-sorter-line__item _item8"><span>Стоимость, ₽</span><span class="short">Стоимость, ₽</span></div>
                <div class="d-sorter-line__item _item9">Особенности</div>
                <div class="d-sorter-line__item _item10">&nbsp;</div>
              </div>
            </div>
          </div>
          <div class="d-favorites-section__list _line">
            <TransitionGroup name="list" tag="">
              <div v-for="item in data.items" :key="item.id">
                <d-favorites-flat-row mod="favorites" :data="item" :object="i" :favorites="favorites" @set-favorite="setFavorite"/>
                <d-favorites-pdf-info v-if="item.pdf" :pdf="item.pdf" mod="block" @show-popup="openEmailPopup(item.pdf)"/>
              </div>
            </TransitionGroup>
            <d-favorites-pdf-info v-if="data.pdf " :pdf="data.pdf" :object="i" mod="line" @show-popup="openEmailPopup(data.pdf)"/>
          </div>
        </template>
      </div>
    </div>
  </template>
  <teleport v-if="dataset && dataset.length" to=".d-favorites__sorter">
    <d-select :data-options="sortingData" v-model="sortParam" icon="sort2"/>
  </teleport>
  <teleport v-if="dataset && dataset.length" to=".d-favorites__switchView">
    <div class="d-switch-view" :class="{ _list: view === 'list'}">
      <button class="d-switch-view__button" :class="{ _active: view === 'list'}" @click="changeView()">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-list"></use>
        </svg>
      </button>
      <button class="d-switch-view__button" :class="{ _active: view === 'card'}" @click="changeView()">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-card"></use>
        </svg>
      </button>
      <div class="d-switch-view__buttonBg"></div>
    </div>
  </teleport>
  <d-popup-simple v-model="showEmailPopup" mod="center" :with-close="false" inside-overlay>
    <d-favorites-pdf-to-email-popup v-if="currentPdfData" :url="currentPdfData" :url-form="emailFormUrl" @success="showEmailPopup = false"/>
  </d-popup-simple>
</template>

<script>
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import DSelect from '@components/molecules/DSelect.vue';
import {debounce as _debounce, orderBy as _orderBy, pick as _pick} from 'lodash';
import DFavoritesFlatCard from '@components/molecules/DFavoritesFlatCard.vue';
import DFavoritesFlatRow from '@components/molecules/DFavoritesFlatRow.vue';
import DFavoritesInfo from '@components/molecules/DFavoritesInfo.vue';
import DFavoritesPreloader from '@components/molecules/DFavoritesPreloader.vue';
import DFavoritesPdfToEmailPopup from '@components/molecules/DFavoritesPdfToEmailPopup.vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import getTouchDevice from '@utils/getTouchDevice';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import DSliderArrows from '@components/atoms/DSliderArrows.vue';
import DFavoritesPdfInfo from '@components/molecules/DFavoritesPdfInfo.vue';
import Cookies from 'js-cookie';

export default {
  name: 'DFavoritesFlats',
  components: {
    DFavoritesPdfInfo,
    DSliderArrows,
    DFavoritesFlatCard,
    DFavoritesPdfToEmailPopup,
    DFavoritesPreloader,
    DFavoritesInfo,
    DPopupSimple,
    DSelect,
    DFavoritesFlatRow,
  },
  props: {
    favoritesApp: {
      type: String,
      default: '',
    },
    apiUrl: {
      type: String,
      default: '/assets/blueant/assets/json/favorites-flat-api.json',
    },
    bookletApiUrl: {
      type: String,
      default: '/assets/blueant/assets/json/favorites-booklet-api.json',
    },
    emailFormUrl: {
      type: String,
      default: '/assets/blueant/assets/json/send-email-response.json',
    },
  },
  data() {
    return {
      view: 'card',
      sortParam: 'price-asc',
      sortOrder: 'asc',
      sortValue: 'price',
      favorites: [],
      touchDevice: false,
      screenPhone: false,
      notificationDuration: 5000,
      dataset: null,
      datasetSorted: [],
      sliders: [],
      empty: false,
      loading: true,
      loaded: false,
      errored: false,
      showEmailPopup: false,
      currentPdfData: null,
      pdfs: [],
      sortingData: [
        {
          id: 1,
          name: 'По возрастанию цены',
          value: 'price-asc',
        },
        {
          id: 2,
          name: 'По убыванию цены',
          value: 'price-desc',
        },
        {
          id: 3,
          name: 'По возрастанию площади',
          value: 'area-asc',
        },
        {
          id: 4,
          name: 'По убыванию площади',
          value: 'area-desc',
        },
        {
          id: 5,
          name: 'По возрастанию этажа',
          value: 'floor-asc',
        },
        {
          id: 6,
          name: 'По убыванию этажа',
          value: 'floor-desc',
        },
        {
          id: 7,
          name: 'По возрастанию комнатности',
          value: 'rooms-asc',
        },
        {
          id: 8,
          name: 'По убыванию комнатности',
          value: 'rooms-desc',
        },
      ],
      favoritesCounter: null,
    };
  },
  watch: {
    sortParam(value) {
      [this.sortValue, this.sortOrder] = value.split('-');
      this.resetSliders();
      this.prepareDataSet();
    },
    favoritesTotal: {
      immediate: true,
      handler(value) {
        if (!this.favoritesCounter) return;
        if (value === 0) this.favoritesCounter.removeAttribute('data-count');
        else if (value > 9) this.favoritesCounter.setAttribute('data-count', '9+');
        else this.favoritesCounter.setAttribute('data-count', value);
        window.tabsWidthStart();
      },
    },
  },
  computed: {
    favoritesTotal() {
      return this.dataset ? this.dataset.map(object => object.favorites).reduce((prev, current) => prev + current.length, 0) : null;
    },
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.checkScreenSize();

    const viewParam = Cookies.get('favorites-view');
    if (viewParam && ['card', 'list'].includes(viewParam)) {
      this.resetSliders();
      this.view = viewParam;
    }

    this.favorites = window.getFavorites();
    this.checkIsEmpty();
    this.favoritesCounter = document.querySelector('button.d-tabs__button._active');
    if (!this.empty) this.getData();
    else this.dataset = [];
  },
  updated() {
    if ($('[data-favorites-flat]').length && !this.sliders.length) {
      this.makeSliders();
    }
    this.refill();
  },
  methods: {
    checkIsEmpty() {
      if (!this.favorites.length) {
        this.loading = false;
        this.empty = true;
      }
    },
    checkScreenSize() {
      this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
      this.touchDevice = getTouchDevice();
      this.refill();
    },
    refill() {
      const self = this;
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (self.touchDevice && (el.classList.contains('_download') || el.classList.contains('_send'))) return;
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              trigger: this.touchDevice ? 'click' : 'mouseenter',
              allowHTML: true,
              interactive: true,
              appendTo: () => document.body,
              offset: [0, 8],
              interactiveDebounce: 10,
              theme: this.touchDevice ? 'index-filter-touch' : 'index-filter',
              placement: 'top',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true,
              onShow(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').addEventListener('click', () => {
                  instance.hide();
                });
              },
              onHide(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').removeEventListener('click', () => {
                  instance.hide();
                });
              },
            });
          }
        });
      });
      window.update_social_share();
    },
    resetSliders() {
      this.sliders.forEach(slider => slider.destroy());
      this.sliders = [];
    },
    makeSliders() {
      if (this.view === 'card') {
        const self = this;
        $('[data-favorites-flat]').each(function(i, el) {
          const slider = new Swiper(el, {
            loop: false,
            lazy: false,
            keyboard: {
              enabled: true,
              onlyInViewport: true,
            },
            slidesPerView: 'auto',
            slideToClickedSlide: false,
            a11y: false,
            spaceBetween: 32,
            navigation: {
              nextEl: `.favorites${ i + 1 }._next`,
              prevEl: `.favorites${ i + 1 }._prev`,
            },
            breakpoints: {
              320: {
                spaceBetween: 12,
              },
              768: {
                spaceBetween: 32,
              },
              1600: {
                slidesPerView: 3,
              },
            },
          });
          self.sliders.push(slider);
        });
      }
    },
    prepareDataSet() {
      this.datasetSorted = [...this.dataset].map(block => {
        block.items = _orderBy(block.items, [this.sortValue], [this.sortOrder]);
        return block;
      });
    },
    getData: _debounce(async function() {
      this.loading = true;
      try {
        const response = await this.axios.post(this.apiUrl, this.favorites, {
          withCredentials: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        if (response.status !== 200) {
          console.error(response);
          this.errored = true;
        }
        if (!response.data.length) {
          this.empty = true;
        }
        this.dataset = response.data.map((object, objectId) => {
          object.favorites = object.items.map(item => item.id);
          this.pdfs[objectId] = object.pdf
          return object;
        });
        this.prepareDataSet();
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
        this.loaded = true;
      }
    }, 500),
    async changeView() {
      this.resetSliders();
      this.view = this.view === 'list' ? 'card' : 'list';
      Cookies.set('favorites-view', this.view, {path: '/', expires: 365});
    },
    async setFavorite(data) {
      if (!window.inFavorites(data.id)) {
        const result = await window.addToFavorites(data.id);
        if (result) this.dataset[data.object].favorites.push(data.id);
      }
      else {
        const result = await window.removeFromFavorites(data.id);
        if (result) this.dataset[data.object].favorites = this.dataset[data.object].favorites.filter(flat_id => flat_id !== data.id);
      }
      this.favorites = window.getFavorites();
      if (!this.dataset[data.object].favorites.length) {
        delete this.dataset[data.object].pdf
        delete this.datasetSorted[data.object].pdf
      }
      else {
        await this.getPdfData(data.object)
      }
    },
    openEmailPopup(pdfData) {
      this.currentPdfData = pdfData.url;
      this.showEmailPopup = true;
    },

    getPdfData: _debounce(async function(object) {
      const [pdfData, favorites] = [this.pdfs[object], this.dataset[object].favorites];
      const data = Object.assign({}, _pick(pdfData, ['url']), {favorites});
      try {
        const response = await this.axios.post(this.bookletApiUrl, data, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        });
        if (response.status !== 200) {
          console.error(response);
          this.errored = true;
        }
        if (response.data.status === 'success') {
          this.dataset[object].pdf = response.data.pdf;
          this.datasetSorted[object].pdf = response.data.pdf;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
      }
    }, 500),
  },
};
</script>


<style scoped>
.list-move,
.list-enter-active,
.list-leave-active,
.cards-move,
.cards-enter-active,
.cards-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(300px);
}

.cards-enter-from,
.cards-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.cards-leave-active,
.list-leave-active {
  position: absolute;
}
</style>
