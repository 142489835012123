<template>
  <div class="d-aux-rooms__sorter _line">
    <div class="d-sorter-line" :class="`_${mod}`">
      <template v-for="(column, id) in columns">
        <button v-if="column.sortValue"
                :key="column.id"
                class="d-sorter-line__item"
                :class="{_asc: currentSort?.sortValue !== column.sortValue || currentSort?.sortOrder === 'asc', '_active': currentSort?.sortValue === column.sortValue}"
                @click="setSort(column.sortValue)">
          <span class="d-sorter-direction _line"><span></span></span><span>{{ column.title }}</span></button>
        <div v-else :key="column.id" class="d-sorter-line__item" :class="`_item${id+1}`">{{ column.title }}</div>
      </template>
    </div>
  </div>

</template>

<script>
import DParkingPlaceLine from '@components/molecules/DParkingPlaceLine.vue';

export default {
  name: 'DSearchSort',
  components: {DParkingPlaceLine},
  emits: ['update:sort'],
  props: {
    sort: {
      type: Object,
      default: () => { },
    },
    options: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    mod: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentSort: {
      get() {
        return this.sort;
      },
      set(value) {
        this.$emit('update:sort', value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    setSort(sortValue) {
      const sortOrder = this.currentSort?.sortValue === sortValue ? this.currentSort?.sortOrder === 'asc' ? 'desc' : 'asc' : 'asc';
      this.currentSort = this.options.find(el => (el.sortValue === sortValue && el.sortOrder === sortOrder));
    },
  },
};
</script>
