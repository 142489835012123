<template>
  <div class="d-object-single-genplan__wrapper">
    <div class="d-object-single-genplan__planWrap _desktop">
      <div v-if="premiumAvailable" class="d-object-single-genplan-legend">
        <div class="d-object-single-genplan-legend__item">Бизнес</div>
        <div class="d-object-single-genplan-legend__item">Премиум</div>
      </div>
      <div class="d-object-single-genplan__planInner">
        <div ref="genplan" class="d-object-single-genplan__plan" :style="`height: ${heightPlan}px`">
          <div class="d-object-genplan">
            <d-fitblock v-if="genplanData" :render="genplanData.render" auto-scroll>
              <template #masks>
                <svg class="d-object-genplan__svg" xmlns="http://www.w3.org/2000/svg" :width="genplanData.render.width" :height="genplanData.render.height" :viewBox="`0 0 ${genplanData.render.width} ${genplanData.render.height}`">
                  <path v-for="label in genplanData.labels" :data-label-id="label.id" :key="label.id" class="d-object-genplan__path" :class="{_premium: label.premium, _hover:hoverLabelId === label.id }"  :d="label.points"  @click="labelClickHandler(label)" />
                </svg>
              </template>
              <template #labels>
                <div v-for="label in genplanData.labels" :key="label.id" :data-label-id="label.id" :ref="setItemRef" :data-tooltip="JSON.stringify(label)"   class="d-object-single-genplan-point" :class="{_hover:hoverLabelId === label.id,  _premium: label.premium}" :style="`left: ${label.position.left}%; top: ${label.position.top}%;`" @click="labelClickHandler(label)" @mouseover="hoverLabel(label.id)"  @mouseout="hoverLabelId = null">
                  <div class="d-object-single-genplan-point__name">{{ label.title }}</div>
                </div>
              </template>
            </d-fitblock>
          </div>
        </div>
        <div v-if="currentLabelActive" class="d-object-single-genplan-point-info" :class="{ _show: currentLabelActive, _premium: currentLabelActive.premium }">
          <div class="d-object-single-genplan-point-info__name">{{ currentLabelActive.title }}</div>
          <div class="d-object-single-genplan-point-info__text" v-html="currentLabelActive.text"></div>
          <a :href="currentLabelActive.url" class="d-object-single-genplan-point-info__btn d-button _dark"><span>Перейти к выбору</span> </a>

          <button class="d-object-single-genplan-point-info__close" @click="closePointInfo">
            <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
          </button>
        </div>
        <div v-if="!swipeInfoHide" class="d-object-single-genplan__info">
          <div class="d-swipe-info">
            <img src="/assets/blueant/assets/img/d/icon_swipe1.svg" />
            <div>Используйте жесты для прокрутки изображения</div>
            <button @click="closeSwipeInfo(false)">
              <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
            </button>
          </div>
        </div>
        <div class="d-object-single-genplan__compass">
          <img src="/assets/blueant/assets/img/d/compass_white.svg" />
        </div>
        <d-disclaimer v-if="disclaimer" class="d-object-single-genplan__disclaimer" :text="disclaimer"/>
      </div>
    </div>
    <div v-if="genplanData" class="d-object-single-genplan__mobileButton" :style="`background-image: url(${genplanData.render.img})`">
      <button @click="showPopup = true" class="d-button _white _withImg">
        <img src="/assets/blueant/assets/img/d/icon_pin.svg"/>
        <span>Открыть генплан</span>
      </button>
    </div>

    <d-popup-simple v-model="showPopup" name="parkingScheme" mod="scheme">
      <div v-if="showPopup" class="d-object-single-genplan__planWrap">
        <div v-if="premiumAvailable" class="d-object-single-genplan-legend">
          <div class="d-object-single-genplan-legend__item">Бизнес</div>
          <div class="d-object-single-genplan-legend__item">Премиум</div>
        </div>
        <div class="d-object-single-genplan__planInner">
          <div class="d-object-single-genplan__plan">
            <div class="d-object-genplan">
              <d-fitblock v-if="genplanData" :render="genplanData.render">
                <template #masks>
                  <svg class="d-object-genplan__svg" xmlns="http://www.w3.org/2000/svg" :width="genplanData.render.width" :height="genplanData.render.height" :viewBox="`0 0 ${genplanData.render.width} ${genplanData.render.height}`">
                    <path v-for="label in genplanData.labels" :key="label.id" class="d-object-genplan__path" :class="{_premium: label.premium, _hover:hoverLabelId === label.id }"  :d="label.points"  @click="labelClickHandler(label)" @mouseover="hoverLabel(label.id)"  @mouseout="hoverLabelId = null"/>
                  </svg>
                </template>
                <template #labels>
                  <div v-for="label in genplanData.labels" :key="label.id"  class="d-object-single-genplan-point" :class="{_hover:hoverLabelId === label.id,  _premium: label.premium}" :style="`left: ${label.position.left}%; top: ${label.position.top}%;`" @click="labelClickHandler(label)" @mouseover="hoverLabel(label.id)"  @mouseout="hoverLabelId = null">
                    <div class="d-object-single-genplan-point__name">{{ label.title }}</div>
                    <div v-if="label.text" class="d-object-single-genplan-point__text" v-html="label.text"></div>
                  </div>
                </template>
              </d-fitblock>


            </div>
          </div>
          <div v-if="currentLabelActive" class="d-object-single-genplan-point-info" :class="{ _show: currentLabelActive, _premium: currentLabelActive.premium }">
            <div class="d-object-single-genplan-point-info__name">{{ currentLabelActive.title }}</div>
            <div class="d-object-single-genplan-point-info__text" v-html="currentLabelActive.text"></div>
            <a :href="currentLabelActive.url" class="d-object-single-genplan-point-info__btn d-button _dark"><span>Перейти к выбору</span> </a>

            <button class="d-object-single-genplan-point-info__close" @click="closePointInfo">
              <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
            </button>
          </div>
          <div v-if="!swipeInfoHideMobile" class="d-object-single-genplan__info">
            <div class="d-swipe-info">
              <img src="/assets/blueant/assets/img/d/icon_swipe1.svg" />
              <div>Используйте жесты для прокрутки изображения</div>
              <button @click="closeSwipeInfo(true)">
                <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
              </button>
            </div>
          </div>
          <div class="d-object-single-genplan__compass">
            <img src="/assets/blueant/assets/img/d/compass_white.svg" />
          </div>
          <d-disclaimer v-if="disclaimer" class="d-object-single-genplan__disclaimer" :text="disclaimer"/>
        </div>
      </div>
    </d-popup-simple>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import DFitblock from "../molecules/DFitblock.vue";
import DPopupSimple from "../molecules/DPopupSimple.vue";
import tippy from "tippy.js";
import setPlanHeight from "@utils/setPlanHeight";
import DDisclaimer from '@components/atoms/DDisclaimer.vue'
import { addWebpUrlParam } from '@utils/getSupportWebp';

export default {
  name: "DGenplanObject",
  props: {
    genplanApiUrl: {
      type: String,
      default: ''
    }
  },
  components: {
    DFitblock,
    DPopupSimple,
    DDisclaimer
  },
  data() {
    return {
      genplanData: null,
      hoverLabelId: null,
      currentLabelActive: null,
      swipeInfoHide: null,
      swipeInfoHideMobile: null,
      showPopup: false,
      itemRefs: [],
      heightPlan: null,
    }
  },
  computed: {
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    premiumAvailable() {
      if (!this.genplanData) {
        return null
      }
      return this.genplanData.labels.find((label) => {
        return label.premium
      })
    },
    disclaimer() {
      if (!this.genplanData) return null
      else return this.genplanData.disclaimer
    }
  },
  created() {
    this.getData()
  },
  beforeUpdate() {
    this.itemRefs = []
  },
  updated() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item)
    })
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closedSwipeInfo')) === 1
    this.swipeInfoHideMobile = Number(Cookies.get('closedSwipeInfoMobile')) === 1

    this.$nextTick(() => {
      this.heightPlan = setPlanHeight(this.$refs.genplan)
      window.addEventListener("resize", this.setHeightPlan);
    })
  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(await addWebpUrlParam(this.genplanApiUrl));

        this.genplanData = response.data.genplan
      } catch(err) {
        console.error('error load genplan data', err);
      }
    },
    labelClickHandler(label) {
      if (this.isTouchDevice) {
        this.currentLabelActive = label
        this.hoverLabelId = label.id
      } else if (label.url && label.url !== '#') {
        window.location = label.url
      }

    },
    closePointInfo() {
      this.hoverLabelId = null
      this.currentLabelActive = null
    },
    hoverLabel(id) {
      this.hoverLabelId = id
    },
    closeSwipeInfo(mobile = false) {
      if (mobile) {
        Cookies.set('closedSwipeInfoMobile', 1)
        this.swipeInfoHideMobile = true
      } else {
        Cookies.set('closedSwipeInfo', 1)
        this.swipeInfoHide = true
      }

    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el)
      }
    },
    createTooltip(target) {
      const data = JSON.parse(target.dataset.tooltip)

      const labelId = target.dataset.labelId
      const secondTarget = document.querySelector(`.d-object-genplan__path[data-label-id="${labelId}"]`)

      if (data.text) {
        tippy(target, {
          // trigger: 'click',
          allowHTML: true,
          // appendTo: () => this.$refs.genplan,
          offset: [0, 8],
          theme: 'genplan-object',
          placement: 'right-end',
          animation: 'fade',
          content: data.text,
          arrow: false,
          touch: false,
          triggerTarget: [secondTarget, target]
        });
      }

    },
    setHeightPlan() {
      this.heightPlan = setPlanHeight(this.$refs.genplan)
    }
  }
}
</script>

<style scoped>

</style>
