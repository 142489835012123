<template>
  <div class="filter-projects">
    <div class="filter-projects__step">
      <div class="filter-projects__inner">
        <div v-for="project in data" :key="project.pk" class="d-checkbox _full">
          <input class="d-checkbox__input"
                 type="checkbox" name="furnish"
                 :id="`filter-project-${project.pk}-id${id}`"
                 v-model="selectedProjects"
                 :value="project.pk"
                 @change="sendData('projects')"
          >
          <label class="d-checkbox__label" :for="`filter-project-${project.pk}-id${id}`">
            {{ project.filter_title }}
            <svg v-if="projectIsActive(project.pk)" class="d-checkbox__close">
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-close'></use>
            </svg>
          </label>
        </div>
      </div>
    </div>
    <div v-if="quartersProject" class="filter-projects__step">
      <div class="filter-projects__title">Квартал</div>
      <div class="filter-projects__inner">
        <div v-for="quarter in quartersProject" :key="quarter.pk" class="d-checkbox _full">
          <input
              class="d-checkbox__input"
              type="checkbox"
              name="furnish"
              :id="`filter-project-quarter${quarter.pk}-id${id}`"
              v-model="selectedQuarters"
              :value="quarter.pk"
              @change="sendData('quarters')"
          >
          <label class="d-checkbox__label" :for="`filter-project-quarter${quarter.pk}-id${id}`">
            {{ quarter.filter_title }}
            <svg v-if="quarterIsActive(quarter.pk)" class="d-checkbox__close">
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-close'></use>
            </svg>
          </label>
        </div>
      </div>
    </div>
    <div v-if="buildingsQuarter" class="filter-projects__step">
      <div class="filter-projects__title">Корпус</div>
      <div class="filter-projects__inner">
        <div v-for="building in buildingsQuarter" :key="building.pk" class="d-checkbox _full">
          <input
              class="d-checkbox__input"
              type="checkbox"
              name="furnish"
              :id="`filter-project-building${building.pk}-id${id}`"
              v-model="selectedBuildings"
              :value="building.pk"
              @change="sendData('buildings')"
          >
          <label class="d-checkbox__label" :for="`filter-project-building${building.pk}-id${id}`">
            {{ building.filter_title }}
            <svg v-if="buildingIsActive(building.pk)" class="d-checkbox__close">
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-close'></use>
            </svg>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intersection as _intersection, uniqueId as _uniqueId } from "lodash";
export default {
  name: "DFilterProjects",
  components: [],
  props: {
    data: {
      type: Array,
      default: () => [],
      require: true
    },
    projects: {
      type: Array,
      default: () => []
    },
    quarters: {
      type: Array,
      default: () => []
    },
    buildings: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    saved: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      selectedProjects: [],
      selectedQuarters: [],
      selectedBuildings: [],
      savedProjects: [],
      savedQuarters: [],
      savedBuildings: []
    }
  },
  computed: {
    quartersProject() {
      if (this.selectedProjects.length === 1) {
        const currentProject = this.data.find((project) => project.pk === this.selectedProjects[0]);
        return currentProject ? currentProject.quarters : null
      }
      return null
    },
    buildingsQuarter() {
      if (this.selectedProjects.length === 1) {
        if (this.selectedQuarters.length > 1) return null
        const currentProject = this.data.find((project) => project.pk === this.selectedProjects[0]);
        if (!currentProject.quarters && !currentProject.buildings) return null
        if (!currentProject.quarters && currentProject.buildings) {
          return currentProject.buildings
        } else {
          const currentQuarter = this.quartersProject.find((quarter) => quarter.pk === this.selectedQuarters[0]);
          return currentQuarter ? currentQuarter.buildings : null
        }
      }
      return null
    }
  },
  mounted() {
    this.id = _uniqueId()

    this.selectedProjects = this.savedProjects = this.projects;
    this.selectedQuarters = this.savedQuarters = this.quarters;
    this.selectedBuildings = this.savedBuildings =  this.buildings;
  },
  methods: {
    sendData(type, emit) {
      if (!this.isMobile || emit) {
        switch (type) {
          case 'projects':
            this.$emit('update:projects', this.selectedProjects);
            break;
          case 'quarters':
            this.$emit('update:quarters', this.selectedQuarters);
            break;
          case 'buildings':
            this.$emit('update:buildings', this.selectedBuildings);
            break;
        }
      }

    },
    projectIsActive(pk) {
      return this.selectedProjects.includes(pk)
    },
    quarterIsActive(pk) {
      return this.selectedQuarters.includes(pk)
    },
    buildingIsActive(pk) {
      return this.selectedBuildings.includes(pk)
    }
  },
  watch: {
    projects(data) {
      this.selectedProjects = _intersection(data, this.selectedProjects)
      this.savedProjects = this.selectedProjects;
    },
    quarters(data) {
      this.selectedQuarters = _intersection(data, this.selectedQuarters)
      this.savedQuarters = this.selectedQuarters
    },
    buildings(data) {
      this.selectedBuildings = _intersection(data, this.selectedBuildings)
      this.savedBuildings = this.selectedBuildings
    },
    selectedProjects(data) {
      if (data.length > 1 || !data.length) {
        this.selectedQuarters = [];
        this.selectedBuildings = [];
        this.sendData('quarters');
        this.sendData('buildings');
      }
    },
    selectedQuarters(data) {
      if ((data.length > 1 || !data.length) && this.quarters.length) {
        this.selectedBuildings = [];
        this.sendData('buildings');
      }
    },
    saved(value) {
      if (value) {
        this.selectedProjects.forEach((project) => {
          if (!this.savedProjects.includes(project)) this.savedProjects.push(project)
        })
        this.selectedQuarters.forEach((quarter) => {
          if (!this.savedQuarters.includes(quarter)) this.savedQuarters.push(quarter)
        })
        this.selectedBuildings.forEach((bld) => {
          if (!this.savedBuildings.includes(bld)) this.savedBuildings.push(bld)
        })
        this.sendData('projects', true);
        this.sendData('quarters', true);
        this.sendData('buildings', true);
      }
    },
    isOpen(open) {
      if (!open && !this.saved) {

        this.selectedProjects.forEach((project) => {
          if (!this.savedProjects.includes(project)) {
            this.selectedProjects = this.selectedProjects.filter((p) => p !== project)
          }
        })
        if (this.savedProjects.length) {
          this.savedProjects.forEach((project) => {
            if (!this.selectedProjects.includes(project)) {
              this.selectedProjects.push(project)
            }
          })
        }

        this.selectedQuarters.forEach((quarter) => {
          if (!this.savedQuarters.includes(quarter)) {
            this.selectedQuarters = this.selectedQuarters.filter((q) => q !== quarter)
          }
        })
        if (this.savedQuarters.length) {
          this.savedQuarters.forEach((quarter) => {
            if (!this.selectedQuarters.includes(quarter)) {
              this.selectedQuarters.push(quarter)
            }
          })
        }



        this.selectedBuildings.forEach((building) => {
          if (!this.savedBuildings.includes(building)) {
            this.selectedBuildings = this.selectedBuildings.filter((b) => b !== building)
          }
        })
        if (this.savedBuildings.length) {
          this.savedBuildings.forEach((building) => {
            if (!this.selectedBuildings.includes(building)) {
              this.selectedBuildings.push(building)
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>