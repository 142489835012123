<template>
  <div :data-place-id="currentItem.guid">
    <div class="d-aux-rooms-card__top">
      <div class="d-aux-rooms-card__name">{{ currentItem.title }}</div>
      <div class="d-aux-rooms-card__buttons">
        <button class="d-aux-rooms-card__button d-button _flatIcon" @click="openItem">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-map"></use>
          </svg>
          <span class="d-button__tooltip">Смотреть на схеме</span>
        </button>
        <button class="d-aux-rooms-card__button d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
          </svg>
          <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
          <span v-else class="d-button__tooltip">Добавить в избранное</span>
        </button>
      </div>
    </div>
    <div class="d-aux-rooms-card__info">
      <div class="d-flat-info">
        <div class="d-flat-info__row" :data-object="currentItem.o">
          <div class="d-flat-info__label">Проект</div>
          <div v-if="itemObject.url" class="d-flat-info__value">
            <a :href="itemObject.url" target="_blank">
              {{ itemObject.title }}
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
              </svg>
            </a>
          </div>
          <div v-else class="d-flat-info__value">{{ itemObject.title }}</div>
        </div>
        <div v-if="currentItem.q" class="d-flat-info__row" :data-quarter="currentItem.q">
          <div class="d-flat-info__label">Квартал</div>
          <div class="d-flat-info__value">{{ data.quarters[currentItem.q] }}</div>
        </div>
        <div v-if="currentItem.b" class="d-flat-info__row" :data-building="currentItem.b">
          <div class="d-flat-info__label">Корпус</div>
          <div class="d-flat-info__value">{{ data.buildings[currentItem.b] }}</div>
        </div>
        <div class="d-flat-info__row">
          <div class="d-flat-info__label">Уровень</div>
          <div class="d-flat-info__value">{{ currentItem.level }}</div>
        </div>
        <div class="d-flat-info__row">
          <div class="d-flat-info__label">Площадь, м&sup2;</div>
          <div class="d-flat-info__value">{{ currentItem.area }}</div>
        </div>
        <div class="d-flat-info__row">
          <div class="d-flat-info__label">Тип</div>
          <div class="d-flat-info__value _info" :class="{_notip: !currentItem.sizeTip}">
            {{ data.types[currentItem.t].title }}
            <div v-if="currentItem.sizeTip" ref="sizeInfo" :data-v-tippy="currentItem.sizeTip" class="d-flat-info__info"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms-card__advantages">
      <div v-for="(prop, id) in currentItem.props" :key="`prop-${currentItem.id}-${id}`" class="d-flat-action" :style="{background: prop.bgColor, color: prop.color}">
        <div class="d-flat-action__name" :data-v-tippy="prop.tip">{{ prop.title }}</div>
        <a v-if="prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
      </div>
    </div>
    <div class="d-aux-rooms-card__bottom">
      <div class="d-aux-rooms-card__price">
        <div class="d-flat-price">
          <div class="d-flat-price__digits">
            <div v-if="currentItem.price_old" class="d-flat-price__old">{{ currentItem.price_old }} ₽</div>
            <div class="d-flat-price__now">{{ currentItem.price_formatted }} ₽</div>
          </div>
        </div>
      </div>
      <a v-if="currentItem.booking_link" class="d-aux-rooms-card__order d-button _dark" :href="currentItem.booking_link" target="_blank"><span>Забронировать</span></a>
      <button v-else class="d-aux-rooms-card__order d-button _dark" data-callMe-show :data-props="placeLeadData(currentItem)">
        <span>Оставить заявку</span>
      </button>
    </div>
    <button class="d-aux-rooms-card__order _full" @click="openItem"></button>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import {mapState} from 'pinia';
import {useSearchStore} from '@/stores/search';
import Cookies from 'js-cookie';

export default {
  name: 'DParkingPlaceCard',
  emits: ['openItem', 'set-favorite'],
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltips: [],
    };
  },
  mounted() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltips();
    }
  },
  updated() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltips();
    }
  },
  computed: {
    ...mapState(useSearchStore, [
      'data',
    ]),
    itemObject() {
      if (this.currentItem.o && this.data.objects[this.currentItem.o]) return {
        url: this.data.objects[this.currentItem.o].url,
        title: this.data.objects[this.currentItem.o].title,
      };
      console.error('Object data not found in data; object ID = ', this.currentItem.o, '; item guid: ', this.currentItem.guid, '; data: ', this.data);
      return {
        url: '',
        title: '',
      };
    },
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
  },
  methods: {
    createTooltips() {
      const targets = document.querySelectorAll(`[data-place-id="${ this.currentItem.guid }"] [data-v-tippy]`);

      if (this.tooltips.length) this.tooltips.forEach(tooltip => tooltip.destroy());
      if (targets) {
        Array.from(targets).forEach(target => {
          const tpl = this.isTouchDevice
                      ? `<div>${ target.dataset.vTippy }</div><img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />`
                      : target.dataset.vTippy;
          this.tooltips.push(tippy(target, {
              allowHTML: true,
              interactive: false,
              //appendTo: () => document.body,
              offset: [0, 12],
              theme: 'd-aux-rooms-size-tip',
              placement: 'top',
              animation: 'fade',
              content: tpl,
              arrow: false,
              hideOnClick: true,
              //trigger: touch ? '',
              touch: this.isTouchDevice,

            }),
          );
        });
      }

    },
    openItem() {
      this.$emit('openItem', this.currentItem);
    },
    setFavorite(item) {
      const price = this.currentItem.price_request && this.currentItem.price_request_label ? this.currentItem.price_request_label : `${ this.currentItem.price_formatted } ₽`;
      this.$emit('set-favorite', {id: this.currentItem.form_id, title: this.currentItem.title, price, show: true});
    },
    placeLeadData(item) {
      return JSON.stringify({
        'area': item.area,
        'comment': '',
        'email': '',
        'flat': item.form_id,
        'floor': item.level,
        'formType': 'reserve',
        'housing': this.data.quarters[item.q],
        'price': item.price,
        'section': this.data.buildings[item.b],
        'sourceConversion': '',
        'typeFlat': 'parking',
        'URL': window.location.href || '',
        'utm': Cookies.get('utm_data') || window.utm_str_src || '',
      });
    },
  },
};
</script>
