import { capitalize } from 'lodash';

const holidays = [
    {
        day: 1,
        month: 1,
    },
    {
        day: 2,
        month: 1,
    },
    {
        day: 31,
        month: 12,
    },
];

const shortDays = [
    {
        day: 31,
        month: 12,
        from: 10,
        to: 15,
    },
    {
        day: 3,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 4,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 5,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 6,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 7,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 8,
        month: 1,
        from: 10,
        to: 20,
    },
    {
        day: 8,
        month: 3,
        from: 10,
        to: 20,
    },
    {
        day: 9,
        month: 3,
        from: 10,
        to: 20,
    },
    {
        day: 10,
        month: 3,
        from: 10,
        to: 20,
    },
    {
      day: 27,
      month: 4,
      from: 9,
      to: 21,
    },
    {
      day: 28,
      month: 4,
      from: 10,
      to: 20,
    },
    {
      day: 29,
      month: 4,
      from: 10,
      to: 20,
    },
    {
      day: 30,
      month: 4,
      from: 10,
      to: 20,
    },
    {
      day: 1,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 2,
      month: 5,
      from: 9,
      to: 21,
    },
    {
      day: 3,
      month: 5,
      from: 9,
      to: 21,
    },
    {
      day: 4,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 5,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 6,
      month: 5,
      from: 9,
      to: 21,
    },
    {
      day: 7,
      month: 5,
      from: 9,
      to: 21,
    },
    {
      day: 8,
      month: 5,
      from: 9,
      to: 21,
    },
    {
      day: 9,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 10,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 11,
      month: 5,
      from: 10,
      to: 20,
    },
    {
      day: 12,
      month: 5,
      from: 10,
      to: 20,
    },
];

function getMoscowDate() {
    return new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));
}

function dateToFormatString(date, num) {
    const dd = String(date.getDate()).padStart(2, '0');
    let dayName;
    const curMonth = new Date(date).toLocaleString('ru-RU', { timeZone: 'Europe/Moscow', month: '2-digit' });
    const curYear = new Date(date).toLocaleString('ru-RU', { timeZone: 'Europe/Moscow', year: '2-digit' });

    if (num === 2) {
        dayName = 'Завтра';
    } else {
        dayName = capitalize(date.toLocaleString('ru-RU', { timeZone: 'Europe/Moscow', weekday: 'long' }));
    }

    return `${dayName}, ${dd}.${curMonth}.${curYear}`;
}

function getOpeningHours(dayNum, day, month) {
    const shortDay = shortDays.find((item) => item.day === day && item.month === month + 1);
    if (shortDay) return { from: shortDay.from, to: shortDay.to };
    return dayNum === 0 || dayNum === 6 ? { from: 10, to: 20 } : { from: 9, to: 21 };
}

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
}

function getHoursData(date, today = false) {
    const addableDate = addMinutes(date, 15);
    const schedule = getOpeningHours(addableDate.getDay(), addableDate.getDate(), addableDate.getMonth());
    const from = schedule.from;
    const to = schedule.to;
    let hoursData = [];
    let count = 0;
    if (today)
        hoursData.push({
            id: 0,
            value: 'Ближайшее',
            name: 'Ближайшее',
            disabled: false,
        });

    for (let hour = from; hour >= from && hour <= to; hour++) {
        if (hour !== to) {
            hoursData.push(
                {
                    id: count++,
                    value: `${hour}:00`,
                    name: `${hour}:00`,
                    hour,
                    min: 0,
                    disabled: false,
                },
                {
                    id: count++,
                    value: `${hour}:15`,
                    name: `${hour}:15`,
                    hour,
                    min: 15,
                    disabled: false,
                },
                {
                    id: count++,
                    value: `${hour}:30`,
                    name: `${hour}:30`,
                    hour,
                    min: 30,
                    disabled: false,
                },
                {
                    id: count++,
                    value: `${hour}:45`,
                    name: `${hour}:45`,
                    hour,
                    min: 45,
                    disabled: false,
                },
            );
        } else if (hour === from) {
            hoursData.push(
                {
                    id: count++,
                    value: `${hour}:15`,
                    name: `${hour}:15`,
                    hour,
                    min: 15,
                    disabled: false,
                },
                {
                    id: count++,
                    value: `${hour}:30`,
                    name: `${hour}:30`,
                    hour,
                    min: 30,
                    disabled: false,
                },
                {
                    id: count++,
                    value: `${hour}:45`,
                    name: `${hour}:45`,
                    hour,
                    min: 45,
                    disabled: false,
                },
            );
        }
    }

    if (today) {
        hoursData = hoursData.filter((e, i) => {
            return addableDate.getHours() < e.hour || i === 0 || (addableDate.getHours() === e.hour && addableDate.getMinutes() < e.min);
        });
    }
    return hoursData;
}

function getDaysData() {
    const date = getMoscowDate();
    const daysData = [];
    const curWeekday = date.getDay();
    const currDay = date.getDate();
    const currMonth = date.getMonth();

    if (date.getHours() < getOpeningHours(curWeekday).to && isNotHoliday(currDay, currMonth)) {
        daysData.push({
            id: 0,
            value: 'Сегодня',
            name: 'Сегодня',
            hours: getHoursData(date, true),
            disabled: false,
        });
    }
    const max = daysData.length ? 7 : 8;

    for (let i = 2; i <= max; i++) {
        date.setDate(date.getDate() + 1);
        const day = date.getDate();
        const month = date.getMonth();

        if (isNotHoliday(day, month)) {
            daysData.push({
                id: daysData.length,
                value: `${dateToFormatString(date, i)}`,
                name: `${dateToFormatString(date, i)}`,
                hours: getHoursData(date),
                disabled: false,
            });
        }
    }

    return daysData;
}

function isNotHoliday(day, month) {
    let result = true;
    holidays.forEach((item) => {
        if (item.day === day && item.month === month + 1) {
            result = false;
        }
        return result;
    });
    return result;
}

export default getDaysData;
