<template>
  <div v-if="currentItem" class="d-aux-rooms-line _viewed" :class="classList">
    <div v-if="data.objects[currentItem.o]" class="d-aux-rooms-line__item _name">
      {{ data.objects[currentItem.o].title }}
      <small v-if="currentItem.q">{{ data.quarters[currentItem.q] }}</small>
    </div>
    <div class="d-aux-rooms-line__item _type">
      <div class="d-aux-rooms-line__label">Тип</div>
      <div v-if="data.types[currentItem.t]" class="d-aux-rooms-line__size" :class="{_notip: !tpl}">
        {{ data.types[currentItem.t].title }}
        <div ref="sizeInfo" v-if="tpl && type !== 'popup'" class="d-aux-rooms-line__sizeInfo"/>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _level">
      <div class="d-aux-rooms-line__label">Уровень</div>
      {{ currentItem.level }}<span class="d-aux-rooms-line__label _mobile">ур.</span>
    </div>
    <div class="d-aux-rooms-line__item _square">
      <div class="d-aux-rooms-line__label">Площадь, м&sup2;</div>
      {{ currentItem.area.toString().replace('.', ',') }}<span class="d-aux-rooms-line__label _mobile">м&sup2;</span>
    </div>
    <div class="d-aux-rooms-line__item _price">
      <div class="d-flat-price">
        <div class="d-flat-price__digits">
          <div v-if="currentItem.price_old" class="d-flat-price__old">{{ currentItem.price_old }} ₽</div>
          <div class="d-flat-price__now">{{ currentItem.price_formatted }} ₽</div>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _advantages">
      <div v-for="(prop, id) in currentItem.props" :key="`prop-${currentItem.id}-${id}`" class="d-flat-action">
        <img v-if="prop.icon" :src="prop.icon"/>
        <a v-if="type !== 'tooltip' && prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _buttons">
      <div class="d-aux-rooms-line__button d-button _flatIcon _favorite" :class="{_active: isFavorite}" @click.stop="setFavorite">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'pinia';
import {useSearchStore} from '@/stores/search';
import tippy from 'tippy.js';
import additionalClassCSS from '@utils/additionalClassCSS';

export default {
  name: 'DParkingPlaceTooltip',
  emits: ['set-favorite'],
  props: {
    currentItem: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'recent',
    },
    mod: {
      type: String,
      default: ''
    },
    isFavorite: {
      type: [Boolean, Object],
      default: null
    }
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltip();
    }
  },
  updated() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltip();
    }
  },
  computed: {
    ...mapState(useSearchStore, [
      'data',
    ]),
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    tpl() {
      return !this.currentItem.sizeTip ? null : (this.isTouchDevice ? `<div>${ this.currentItem.sizeTip }</div><img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />` : this.currentItem.sizeTip);
    },
    typeClass() {
      return additionalClassCSS(this.type);
    },
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.typeClass, this.modClass);
    },
  },
  methods: {
    createTooltip() {
      const target = this.$refs.sizeInfo;

      if (this.tooltip) this.tooltip.destroy();
      if (this.tpl) this.tooltip = tippy(target, {
        allowHTML: true,
        interactive: false,
        //appendTo: () => document.body,
        offset: [0, 12],
        theme: 'd-aux-rooms-size-tip',
        placement: 'top',
        animation: 'fade',
        content: this.tpl,
        arrow: false,
        hideOnClick: true,
        //trigger: touch ? '',
        touch: this.isTouchDevice,

      });

    },
    setFavorite() {
      const price = this.currentItem.price_request && this.currentItem.price_request_label ? this.currentItem.price_request_label : `${ this.currentItem.price_formatted } ₽`;
      this.$emit('set-favorite', {id: this.currentItem.form_id, title: this.currentItem.title, price, show: false});
    },
  },
};
</script>
