<template>
  <div :data-place-id="currentItem.guid">
    <div class="d-aux-rooms-line__item _name" :data-object="currentItem.o">
      {{ itemObject.title }}<small v-if="currentItem.q && !screenPhone" :data-quarter="currentItem.q" v-html="quarterName(data.quarters[currentItem.q])"></small>
    </div>
    <div class="d-aux-rooms-line__item _build" :data-building="currentItem.b">
      <div class="d-aux-rooms-line__label">Корпус</div>{{ data.buildings[currentItem.b] }}
    </div>
    <div class="d-aux-rooms-line__item _number">
      <div class="d-aux-rooms-line__label">Номер</div>{{ currentItem.number }}
    </div>
    <div class="d-aux-rooms-line__item _type">
      <div class="d-aux-rooms-line__label">Тип</div>
      <div class="d-aux-rooms-line__size" :class="{_notip: !currentItem.sizeTip}">
        {{ data.types[currentItem.t].title }}
        <div v-if="currentItem.sizeTip" ref="sizeInfo" :data-v-tippy="currentItem.sizeTip" class="d-aux-rooms-line__sizeInfo"></div>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _level">
      <div class="d-aux-rooms-line__label">Уровень</div>{{ currentItem.level }}<span class="d-aux-rooms-line__label _mobile">ур.</span>
    </div>
    <div class="d-aux-rooms-line__item _square">
      <div class="d-aux-rooms-line__label">Площадь, м&sup2;</div>{{ currentItem.area }}<span
        class="d-aux-rooms-line__label _mobile">м&sup2;</span>
    </div>
    <div class="d-aux-rooms-line__item _price">
      <div class="d-flat-price">
        <div class="d-flat-price__digits">
          <div v-if="currentItem.price_old" class="d-flat-price__old">{{ currentItem.price_old }} ₽</div>
          <div class="d-flat-price__now">{{ currentItem.price_formatted }} ₽</div>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _advantages">
      <div v-for="(prop, id) in currentItem.props" :key="`prop-${currentItem.id}-${id}`" class="d-flat-action" :style="{background: prop.bgColor, color: prop.color}">
        <div class="d-flat-action__name" :data-v-tippy="prop.tip">{{ prop.title }}</div>
        <img v-if="prop.icon" :src="prop.icon" />
        <a v-if="prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
      </div>
    </div>
    <div class="d-aux-rooms-line__item _buttons">
      <button class="d-aux-rooms-line__button d-button _flatIcon _scheme" @click="openItem">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-map"></use>
        </svg>
        <span class="d-button__tooltip">Смотреть на схеме</span>
      </button>
      <button class="d-aux-rooms-line__button d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
        </svg>
        <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
        <span v-else class="d-button__tooltip">Добавить в избранное</span>
      </button>
    </div>
    <button class="d-aux-rooms-line__order" @click="openItem"></button>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import {mapState} from 'pinia';
import {useSearchStore} from '@/stores/search';

export default {
  name: 'DParkingPlaceLine',
  emits: ['openItem', 'set-favorite'],
  props: {
    currentItem: {
      type: Object,
      default: () => {}
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useSearchStore, [
      'data', 'screenPhone'
    ]),
    itemObject() {
      if (this.currentItem.o && this.data.objects[this.currentItem.o]) return {
        url: this.data.objects[this.currentItem.o].url,
        title: this.data.objects[this.currentItem.o].title,
      };
      console.error('Object data not found in data; object ID = ', this.currentItem.o, '; item guid: ', this.currentItem.guid, '; data: ', this.data);
      return {
        url: '',
        title: '',
      };
    },
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    tpl() {
      return this.isTouchDevice ? `<div>${ this.data.types[this.currentItem.t].tip }</div><img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />` : this.data.types[this.currentItem.t].tip;
    },
  },
  data() {
    return {
      tooltips: [],
    }
  },
  mounted() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltips();
    }
  },
  updated() {
    if (this.currentItem && this.$refs.sizeInfo) {
      this.createTooltips();
    }
  },
  methods: {
    quarterName(quarter) {
      return !isNaN(quarter) ? `<span>Квартал </span>${quarter}`: quarter;
    },
    createTooltips() {
      const targets = document.querySelectorAll(`[data-place-id="${ this.currentItem.guid }"] [data-v-tippy]`);

      if (this.tooltips.length) this.tooltips.forEach(tooltip => tooltip.destroy());
      if (targets) {
        Array.from(targets).forEach(target => {
          const tpl = this.isTouchDevice
                      ? `<div>${ target.dataset.vTippy }</div><img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />`
                      : target.dataset.vTippy;
          this.tooltips.push(tippy(target, {
              allowHTML: true,
              interactive: false,
              //appendTo: () => document.body,
              offset: [0, 12],
              theme: 'd-aux-rooms-size-tip',
              placement: 'top',
              animation: 'fade',
              content: tpl,
              arrow: false,
              hideOnClick: true,
              //trigger: touch ? '',
              touch: this.isTouchDevice,

            }),
          );
        });
      }
    },
    openItem() {
      this.$emit('openItem', this.currentItem)
    },
    setFavorite() {
      const price = this.currentItem.price_request && this.currentItem.price_request_label ? this.currentItem.price_request_label : `${ this.currentItem.price_formatted } ₽`;
      this.$emit('set-favorite', {id: this.currentItem.form_id, title: this.currentItem.title, price, show: true});
    },
  }
}
</script>
