<template>
  <div v-if="recentItems.length" class="d-aux-rooms-viewed">
    <div class="d-aux-rooms-viewed__title">Вы смотрели ранее:</div>
    <div ref="slider" class="d-aux-rooms-viewed__list swiper">
      <div class="swiper-wrapper">
        <div v-for="recentItem in recentItems" class="swiper-slide" @click="setItem(recentItem)">
          <d-parking-place-tooltip :currentItem="recentItem" type="recent" :is-favorite="isFavorite(recentItem.form_id)" :mod="mod" @set-favorite="setFavorite"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import {mapWritableState, mapState} from 'pinia';
import {useItemStore, useSearchStore} from '@/stores/search';
import DParkingPlaceTooltip from '@components/atoms/DParkingPlaceTooltip.vue';

export default {
  name: 'DParkingRecent',
  components: {DParkingPlaceTooltip},
  props: {
    mod: {
      type: String,
      default: ''
    },
    favorites: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      recentItems: [],
      swiperSlider: null,
    };
  },
  computed: {
    ...mapWritableState(useItemStore, [
      'item',
    ]),
    ...mapState(useItemStore, [
      'lastItems',
    ]),
    ...mapState(useSearchStore, [
      'items',
    ]),
  },
  watch: {
    lastItems: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(function() {

          if (this.swiperSlider !== null) {
            this.swiperSlider.destroy(true, true);
            this.swiperSlider = null;
          }
          this.recentItems = this.items.filter(item => this.lastItems.includes(item.guid));
        });
      },
    },
    recentItems: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(function() {
          if (this.recentItems.length && this.$refs.slider) this.swiperSlider = new Swiper(this.$refs.slider, {
            slidesPerView: 'auto',
            spaceBetween: 8,
          });
        });
      },

    },
  },
  methods: {
    setItem(item) {
      this.item = item;
    },
    setFavorite(data) {
      this.$emit('set-favorite', data);
      return false;
    },
    isFavorite(form_id) {
      return window.inFavorites(form_id)
    }
  },
};
</script>
