<template>
  <div class="d-send-pdf _show">
    <div class="d-send-pdf__inner">
      <div v-if="formSuccess" class="d-send-pdf__content">
        <div class="d-send-pdf__title">Презентация отправлена!</div>
        <div class="d-send-pdf__text">Проверьте электронную почту</div>
        <button class="d-send-pdf__button d-button _blue" @click="closeSuccess">
          <span>Хорошо, спасибо!</span>
        </button>
      </div>
      <div v-else class="d-send-pdf__content">
        <div class="d-send-pdf__title">Презентация</div>
        <div class="d-send-pdf__text">Ваши избранные объекты сформированы в&nbsp;брошюру. Введите адрес электронной
          почты, на&nbsp;которую она будет отправлена.
        </div>
        <form class="d-send-pdf__form" action="" @submit.prevent="submitEmailForm">
          <div class="d-send-pdf__input d-input _grey" :class="{ _error: v$.emailForm.email.$errors.length }">
            <input class="d-input__field"
                   ref="email"
                   type="email"
                   name="email"
                   placeholder="Введите e-mail"
                   v-model="v$.emailForm.email.$model"
                   @blur="v$.emailForm.email.$touch"
                   :disabled="loading"
                   inputmode="email"
                   :class="{ _filled: emailForm.email > ''}">
            <div class="d-input__error" v-for="(error, index) of v$.emailForm.email.$errors" :key="index">{{ error.$message }}</div>
          </div>
          <button class="d-send-pdf__button d-button _blue" :class="{_disable: v$.emailForm.$invalid}" @click.prevent="submitEmailForm">
            <span>Отправить</span>
          </button>
        </form>
      </div>
      <div class="d-send-pdf__close d-button _close _blue" @click="closeSuccess">
        <img src="/assets/blueant/assets/img/d/icon_close.svg">
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, email, helpers} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import Inputmask from 'inputmask/lib/inputmask';

export default {
  name: 'DFavoritesPdfToEmailPopup',
  props: {
    url: String,
    urlForm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emailForm: {
        phone: '',
      },
      loading: false,
      errored: false,
      formSuccess: false,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mounted() {

  },
  watch: {},
  methods: {
    submitEmailForm() {
      this.v$.emailForm.$touch();

      if (!this.v$.emailForm.$invalid) {
        let formData = new FormData();
        formData.append('url', this.url);
        formData.append('email', this.emailForm.email);

        this.sendData(formData);
      }
    },
    async sendData(formdata) {
      this.loading = true;
      try {
        const response = await this.axios.post(this.urlForm, formdata);
        console.log('response.data.status = ', response.data.status);
        if (response.data.status === 'success') {
          this.resetForm();
          this.formSuccess = true;
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.emailForm.$reset();
      this.emailForm.email = '';
    },
    closeSuccess() {
      this.$emit('success');
      this.resetForm();
      setTimeout(() => {
        this.formSuccess = false;
      }, 400);
    },
  },
  validations() {
    return {
      emailForm: {
        email: {
          required: helpers.withMessage('Заполните это поле', required),
          emailHelper: helpers.withMessage('Введите корректный email', email),
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
