<template>
  <div class="d-progress-popup-slider">
    <div v-if="!swipeInfoHide && isTouchDevice" class="d-progress-popup-slider__info">
      <div class="d-swipe-info">
        <img src="/assets/blueant/assets/img/d/icon_swipe2.svg" />
        <div>Используйте жесты для прокрутки и масштабирования изображения</div>
        <button @click="closeSwipeInfo()">
          <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
        </button>
      </div>
    </div>
    <div v-if="content" class="d-progress-popup__more">
      <button class="d-button _white _border" @click="openContent"><span>Подробнее</span></button>
    </div>
    <div class="d-arrows-slider d-progress-popup-slider__arrow">
      <button ref="prev" class="d-arrows-slider__arrow d-button _slider _prev progressSlider">
        <span></span>
        <svg  width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
        </svg>
      </button>

      <button ref="next" class="d-arrows-slider__arrow d-button _slider _next progressSlider">
        <span></span>
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
        </svg>
      </button>
    </div>
    <div ref="pagination" class="d-progress-popup-slider__pagination d-slider-pagination"></div>
    <swiper
        class="d-progress-popup-slider__swiper"
        ref="slider"
        v-bind="swiperOpts"
        :pagination="{
          el: pagination,
          type: 'custom',
          renderCustom: function (swiper, current, total) {
          let currentSlide =  current.toString();
          let totalSlide = total.toString();
          return `<span>${currentSlide}&nbsp;-&nbsp;${totalSlide}</span>`;
          }
        }"
        :navigation="{
              prevEl: prev,
              nextEl: next,
            }"
    >

      <swiper-slide v-for="img in images" :key="img" class="d-progress-popup-slider__slide swiper-slide">
        <div class="d-progress-popup-slider__slide-bg" :style="`background-image: url(${img})`"></div>
        <img class="d-progress-popup-slider__img" :src="img" alt="">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {markRaw, ref} from 'vue';
import { Swiper, SwiperSlide,   } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import Cookies from "js-cookie";
import getTouchDevice from '@utils/getTouchDevice';
export default {
  name: "DProgressPopupSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    content: {
      type: String,
      default: ''
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const pagination = ref(null)
    return {
      modules: [Navigation, Pagination],
      prev,
      next,
      pagination
    };
  },
  data() {
    return {
      swiperOpts: {
        loop: true,
        loopAdditionalSlides: 4,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        //breakpoints: {
        //  320: {
        //    allowTouchMove: false
        //  },
        //  1280: {
        //    allowTouchMove: true
        //  }
        //},
      },
      swipeInfoHide: null,
      screenWidth: 0,
      screenHeight: 0,
      isTouchDevice: false
    }
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closeSwipeProgress')) === 1;
    this.setSizeContainer()
    window.addEventListener('resize', this.setSizeContainer);
  },
  unmounted() {
    window.removeEventListener('resize', this.setSizeContainer);
  },
  methods: {
    closeSwipeInfo() {
      Cookies.set('closeSwipeProgress', 1)
      this.swipeInfoHide = true
    },
    setSizeContainer() {
      this.isTouchDevice = getTouchDevice();
      this.screenWidth = Math.round(window.innerWidth / 100 * 99);
      this.screenHeight = Math.round(window.innerHeight / 100 * 99);
    },
    openContent() {
      this.$emit('openContent')
    }
  }
}
</script>
