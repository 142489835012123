<template>
  <div class="d-map-contacts" :class="pageClass">
    <d-map-zoom-buttons @changeZoom="zoomMap"/>
    <div ref="map" class="d-map-contacts__map"></div>
  </div>
</template>

<script>
import DMapZoomButtons from "../atoms/DMapZoomButtons.vue";
import {toRaw} from "vue";
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: "DMapContacts",
  components: {
    DMapZoomButtons
  },
  props: {
    hoverId: {
      type: [Number, String],
      default: null
    },
    activeId: {
      type: [Number, String],
      default: null
    },
    openMobileInfo: {
      type: Boolean,
      default: null
    },
    offices: {
      type: Array,
      default: () => []
    },
    allowRoute: {
      type: Boolean,
      default: false
    },
    singleOffice: {
      type: Object,
      default: () => {}
    },
    page: {
      type: String,
      default: ''
    },
    suggest: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      mapInstance: null,
      route: null,
      routeMarker: null,
    }
  },
  computed: {
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    pageClass() {
      return additionalClassCSS(this.page);
    },
  },
  mounted() {
    this.$nextTick(() => {
      ymaps.ready(this.mapInit);
    })
  },
  watch: {
    hoverId(value) {
      const $marker = $(`[data-office-id-marker]`);
      const $currentMarker = $(`[data-office-id-marker="${value}"]`)

      if (!value) {
        $marker.parents('.ymaps-2-1-79-placemark-overlay').removeClass('_active').css({
          zIndex: 730
        })
      }
      $marker.removeClass('_active')
      $currentMarker.addClass('_active')
      $currentMarker.parents('.ymaps-2-1-79-placemark-overlay').css({
        zIndex: 740
      })

    },
    activeId(value) {
      const rawMap = toRaw(this.mapInstance)
      if (value !== null) {

        rawMap.geoObjects.each((m) => {

          if (m.customData.id === value) {
            m.options.set('visible', true);
            const currentOffice = (this.offices.find((office) => office.id === value))
            if (currentOffice) {
              rawMap.panTo(currentOffice.coords.map(coord => Number(coord)));
            }

          } else {
            m.options.set('visible', false);
          }
        })
      } else {
        rawMap.geoObjects.each((m) => {
          m.options.set('visible', true);
        })
        rawMap.setBounds(rawMap.geoObjects.getBounds(), {
          zoomMargin: 50
        });
      }
    },
    allowRoute(value) {
      if (!value) {
        const rawMap = toRaw(this.mapInstance)
        const rawRoute = toRaw(this.route)
        const rawRoteMarker = toRaw(this.routeMarker)
        this.routeMarker = null;
        rawMap.geoObjects.remove(rawRoute);
        rawMap.geoObjects.remove(rawRoteMarker);
      }
    },
    suggest(value) {
      if (value) {
        const suggestInp = document.querySelector('#suggest')
        var suggestView = new ymaps.SuggestView(suggestInp);
        suggestView.events.add('select', () => {
          this.geocode(suggestInp, this.singleOffice);
        })
      }
    }
  },
  methods: {
    mapInit() {
      this.mapInstance = new ymaps.Map(this.$refs.map, {
        center: this.singleOffice ? this.singleOffice.coords : [55.769761, 37.562889],
        zoom: 12,
        controls: []
      });

      const rawMap = toRaw(this.mapInstance)

      if (this.singleOffice) {
        const screenXS = window.matchMedia('(max-width: 767px)').matches;
        let pixelCenter = rawMap.getGlobalPixelCenter(this.singleOffice.coords);

        if (screenXS) pixelCenter = [ pixelCenter[0], pixelCenter[1] + 128 ] // сдвигаем центр карты вверх, чтобы инфо офиса не перекрывало метку на мобиле
        else pixelCenter = [ pixelCenter[0] - 128, pixelCenter[1]] // сдвигаем центр карты право, чтобы инфо офиса не перекрывало метку на планшете

        const geoCenter = rawMap.options.get('projection').fromGlobalPixels(pixelCenter, rawMap.getZoom());
        rawMap.setCenter(geoCenter);

      }

      rawMap.behaviors.disable('scrollZoom');

      if (this.singleOffice) {
        rawMap.geoObjects.add(this.createMarker(this.singleOffice, this.singleOffice.num - 1))

        const suggestInp = document.querySelector('#suggest')
        var suggestView = new ymaps.SuggestView(suggestInp);
        suggestView.events.add('select', () => {
          this.geocode(suggestInp);
        })
      } else {
        this.offices.forEach((point, idx) => {
          rawMap.geoObjects.add(this.createMarker(point, idx))
        })
        if (this.offices.length === 1) {
          rawMap.setCenter(this.offices[0].coords);
        } else {
          rawMap.setBounds(rawMap.geoObjects.getBounds(), {
            zoomMargin: 50
          });

        }

      }



      rawMap.events.add('click', (e) => {
        if (this.allowRoute) {
          const suggestInp = document.querySelector('#suggest');
          suggestInp.value = '';

          var coords = e.get('coords');

          // Если метка уже создана – просто передвигаем ее.
          if (this.routeMarker) {
            const rawMarker = toRaw(this.routeMarker)
            rawMarker.geometry.setCoordinates(coords);
          }
          // Если нет – создаем.
          else {
            this.routeMarker = this.createRouteMarker(coords);
            const rawMarker = toRaw(this.routeMarker)
            rawMap.geoObjects.add(rawMarker);
            // Слушаем событие окончания перетаскивания на метке.
            rawMarker.events.add('dragend', () => {
              this.getAddress(rawMarker.geometry.getCoordinates())
            });
          }
          const rawMarker = toRaw(this.routeMarker)
          this.getAddress(rawMarker, coords)

          const currentOffice = this.singleOffice ? this.singleOffice : this.offices.find((office) => office.id === this.activeId)
          this.sendData(coords, currentOffice.coords )
          this.drawRoute([coords, currentOffice.coords ])
        }

      });
    },
    createMarker(point, idx) {
      const coords = Object.values(point.coords)
      let marker_html;

      const html = `
        <div class="d-map-point _contacts" data-office-id-marker="${point.id}">
            <div class="d-map-point__img">
                <div class="d-map-point__num">${idx + 1}</div>
                <div class="d-map-point-info _contacts">
                    <div class="d-map-point-info__name">${point.name}</div>
              </div>
            </div>
        </div>
      `
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });
      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [8, 8],
            iconImageOffset: [-4, -8],
            zIndex: 730,
            zIndexActive:  731,
            zIndexHover: 731,
            iconPane: 'overlaps'
          });
      marker.customData = {
        id: point.id,
        $inner_el: $(marker_html)
      }
      marker.events
          .add('click', (e) => {
            console.log('open office', point.id)
            this.$emit('update:activeId', point.id);
          })
          .add('mouseenter', (e) => {
            if (!this.isTouchDevice) {
              this.$emit('update:hoverId', point.id);
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            if (!this.isTouchDevice) {
              this.$emit('update:hoverId', null);
              const $inner_el = $(marker_html);
              $inner_el.removeClass('_hover');
            }

          })
          .add('click', () => {
            if (this.isTouchDevice) {
              const $inner_el = $(marker_html);

              $('.d-map-point').removeClass('_active')
              this.showMarkerInfoMobile = true

              this.$emit('update:openMobileInfo', true);

              $inner_el.addClass('_active').removeClass('_hover');

            }
          })
      return marker
    },
    zoomMap(value) {
      const rawMap = toRaw(this.mapInstance)
      const currentZoom = rawMap.getZoom()
      rawMap.setZoom(currentZoom + value)
    },
    createRouteMarker(coords) {
      let marker_html;

      const html = `
        <div class="d-map-point _route">
            <div class="d-map-point__img">

            </div>
        </div>
      `
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });
      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [8, 8],
            iconImageOffset: [-4, -8],
            zIndex: 730,
            zIndexActive:  731,
            zIndexHover: 731,
            iconPane: 'overlaps'
          });
      return marker
    },
    drawRoute(points) {
      const rawMap = toRaw(this.mapInstance)
      const rawRoute = toRaw(this.route)
      if (this.route) {
        rawMap.geoObjects.remove(rawRoute);
      }
      ymaps.route(points, {
        multiRoute: false
      }).done( (route) => {
        route.options.set("mapStateAutoApply", true);
        route.getPaths().options.set({
          strokeColor: '#5131F5',
          opacity: 1
        });
        this.route = route;
        const rawRoute = toRaw(this.route)
        rawMap.geoObjects.add(rawRoute);
      }, function (err) {
        throw err;
      }, this);
    },
    geocode(inp) {
      // Забираем запрос из поля ввода.
      var request = inp.value;
      // Геокодируем введённые данные.
      ymaps.geocode(request).then( (res) => {
        var obj = res.geoObjects.get(0),
            error, hint;
        const coordsSelect = obj.geometry.getCoordinates()

        let coordsPointTo = null;


        const rawMap = toRaw(this.mapInstance)

        if (this.singleOffice) {
          coordsPointTo = this.singleOffice.coords
        } else {
          rawMap.geoObjects.each((point) => {
            const pointIsVisible = point.options.get('visible');
            if (pointIsVisible) {
              coordsPointTo =  point.geometry.getCoordinates()
            }
          })
        }


        // Если метка уже создана – просто передвигаем ее.
        if (this.routeMarker) {
          const rawMarker = toRaw(this.routeMarker)
          rawMarker.geometry.setCoordinates(coordsSelect);
        }
        // Если нет – создаем.
        else {
          this.routeMarker = this.createRouteMarker(coordsSelect);
          const rawMarker = toRaw(this.routeMarker)
          rawMap.geoObjects.add(rawMarker);
          // Слушаем событие окончания перетаскивания на метке.
          rawMarker.events.add('dragend', () => {
            this.getAddress(rawMarker.geometry.getCoordinates());
          });
        }

        this.sendData(coordsSelect, coordsPointTo)
        this.drawRoute([coordsSelect, coordsPointTo ])
        // route.toggle(true, coordsSelect, coordsPointTo, true, true);

      }, function (e) {
        console.log(e)
      })
    },
    getAddress(marker, coords) {
      marker.properties.set('iconCaption', 'поиск...');
      ymaps.geocode(coords).then(function (res) {
        var firstGeoObject = res.geoObjects.get(0);

        marker.properties
            .set({
              // Формируем строку с данными об объекте.
              iconCaption: [
                // Название населенного пункта или вышестоящее административно-территориальное образование.
                firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
              ].filter(Boolean).join(', '),
              // В качестве контента балуна задаем строку с адресом объекта.
              balloonContent: firstGeoObject.getAddressLine()
            });
      });
    },
    sendData(from, to) {
      this.$emit('sendData', {
        from,
        to
      })
    }
  }
}
</script>

<style scoped>

</style>
