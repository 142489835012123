<template>
  <div>
    <div class="d-swipe-info">
      <img v-if="mod === 'pinchzoom'" src="/assets/blueant/assets/img/d/icon_swipe2.svg"/>
      <img v-else src="/assets/blueant/assets/img/d/icon_swipe1.svg"/>
      <div>
        <slot></slot>
      </div>
      <button @click="close">
        <img src="/assets/blueant/assets/img/d/icon_close_pen.svg"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSwipeZoomInfo',
  emits: 'close',
  props: {
    mod: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
