<template>
  <vue-final-modal
      v-slot="{ params, close }"
      v-bind="$attrs"
      :esc-to-close="true"
      :z-index-base="99999"
      :classes="classes"
      content-class="d-zoom-popup"
      overlay-class="d-overlay"
      @closed="closed"
      @before-open="beforeOpen"
      @before-close="beforeClose">
    <pinch-scroll-zoom ref="zoomer" :width="dataWidth" :height="dataHeight" :contentWidth="imgWidth" :contentHeight="imgHeight" :minScale="0.9"  :maxScale="20" :scale="zoom" :within="true" class="d-zoom-popup__image" @scaling="scalingHandler">
      <img :src="dataImg" />
      <div v-if="dataAdditImg" class="d-flat-plan-floor _zoom">
        <img :src="dataAdditImg" alt="на этаже" />
      </div>
    </pinch-scroll-zoom>
    <div class="d-zoom-popup__zoom d-zoom-map">
      <button class="d-zoom-map__button _plus" @click="scale(0.1)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-plus"></use>
        </svg>
      </button>
      <button class="d-zoom-map__button _minus" @click="scale(-0.1)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-minus"></use>
        </svg>
      </button>
    </div>
    <d-swipe-zoom-info v-if="noteOpen" class="d-zoom-popup__note" mod="pinchzoom" @close="closeSwipeZoomInfo()">
      Используйте жесты для перемещения и масштабирования изображения
    </d-swipe-zoom-info>
    <button class="d-zoom-popup__close d-button _close _blue" @click="close">
      <img src="/assets/blueant/assets/img/d/icon_close.svg">
    </button>
    <div v-if="dataTip" class="d-zoom-popup__tip">{{ dataTip }}</div>
    <d-disclaimer v-if="disclaimerShow && disclaimer" :text="disclaimer" class="d-zoom-popup__disclaimer" />
  </vue-final-modal>
</template>

<script>
import {markRaw} from "vue";
import {VueFinalModal} from 'vue-final-modal';
import PinchScrollZoom from '@coddicat/vue3-pinch-scroll-zoom';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue'
import DDisclaimer from '@components/atoms/DDisclaimer.vue'
import Cookies from 'js-cookie';

export default {
  name: 'DFlatViewPopup',
  components: {
    VueFinalModal, PinchScrollZoom, DSwipeZoomInfo, DDisclaimer
  },
  inheritAttrs: false,
  props: {
    dataImg: {
      type: String,
      default: '',
    },
    dataAdditImg: {
      type: String,
      default: '',
    },
    dataWidth: {
      type: Number,
      default: 500,
    },
    dataHeight: {
      type: Number,
      default: 500,
    },
    dataTip: {
      type: [String, Boolean],
      default: false,
    },
    imgWidth: {
      type: Number,
      required: false,
    },
    imgHeight: {
      type: Number,
      required: false,
    },
    disclaimer: {
      type: String,
      default: '',
    },
    disclaimerShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoom: 1,
      noteOpen: true,
      classes: 'modal-container'
    };
  },
  created() {
    if (this.dataAdditImg) this.classes = 'modal-container _flatPlan';
    this.noteOpen = !(Number(Cookies.get('closedSwipeZoomInfoFlatZoom')) === 1)
  },
  methods: {
    closed() {
      this.$emit('closed');
    },
    scalingHandler(e) {
      this.zoomData = markRaw(e);
      console.log(this.zoomData);
    },
    scale(n) {
      this.zoom += n;
    },
    closeSwipeZoomInfo() {
      Cookies.set('closedSwipeZoomInfoFlatZoom', 1)
      this.noteOpen = false
    },
    beforeOpen() {
      // document.querySelector('body').style.overflow = 'hidden';
      const jivo = document.querySelector('jdiv');
      if (jivo) document.querySelector('jdiv').style.zIndex = '0';
    },
    beforeClose() {
      // document.querySelector('body').style.overflow = '';
      const jivo = document.querySelector('jdiv');
      if (jivo) document.querySelector('jdiv').style.zIndex = '';
    }
  },
};
</script>

<style scoped>

</style>
