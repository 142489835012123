<template>
  <div v-if="tags.length" class="d-index-filter-choose__list d-aux-rooms__tags">
    <div v-for="tag in tags" :key="tag.tag_title" class="d-index-filter-choose-item" :data-key="`${tag.filter}-${tag.category}-${tag.tag_title}-${tag.pk}`" @click="resetFilter(tag)">
      <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
      <button class="d-index-filter-choose-item__del">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DSearchTags',
  emits: ['reset:tag'],
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    resetFilter(tag) {
      this.$emit('reset:tag', tag);
    },
  },
};
</script>
