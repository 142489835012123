<template>
  <div v-if="dataObject" class="d-commercial-object-top">
    <div class="d-commercial-object-top__item _top">
      <div class="d-commercial-object-top__icons">
        <a v-if="dataObject.pdfLink" class="d-commercial-object-top__pdf d-button _flatIcon _text" :href="dataObject.pdfLink" data-v-tippy-top="true" data-v-tippy="Скачать PDF">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-pdf"></use>
          </svg>
          <span>Скачать PDF</span>
        </a>
        <button class="d-button _flatIcon _icon3" data-social-share-list>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-share"></use>
          </svg>
        </button>
      </div>
      <h1 class="d-commercial-object-top__title">{{ dataObject.title }}</h1>
      <div class="d-commercial-object-top__metro">
        <div v-for="metro in dataObject.metro" :key="metro.id" class="d-metro">
          <div class="d-metro__color _yellow" :style="`background:${metro.color}`"></div>
          <div>{{ metro.name }}</div>
        </div>
      </div>
      <div class="d-commercial-object-top__address" v-html="dataObject.address"></div>
    </div>
    <div class="d-commercial-object-top__item _plans">
      <div class="d-tabs">
        <div class="d-tabs__buttons">
          <div class="d-tabs__buttonsInner">
            <button v-if="dataObject.floors.length" class="d-tabs__button" :class="{ _single: !dataObject.images.length }">Планировка</button>
            <button v-if="dataObject.images.length" class="d-tabs__button _active" :class="{ _single: !dataObject.floors.length }">Фото</button>
            <div v-show="dataObject.images.length && dataObject.floors.length" class="d-tabs__buttonBg"></div>
          </div>
        </div>
        <div v-if="dataObject.floors.length" class="d-tabs__content" :class="{ _active: !dataObject.images.length }">
          <div v-if="currentPlan" class="d-commercial-object-photo _single">
            <img class="d-commercial-object-photo__img" :src="currentPlan" alt="">
          </div>
          <button class="d-commercial-object-top__zoom" @click="zoom">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-zoom"></use>
            </svg>
          </button>
          <div v-if="dataObject.floors && dataObject.floors.length > 1" class="d-commercial-object-top__chooseFloor">
            <d-select
                :data-options="dataObject.floors"
                mod="commercial" v-model="currentFloor"
                @update:modelValue="setFloorPlan"/>
          </div>
        </div>
        <div v-if="dataObject.images.length" class="d-tabs__content _active">
          <button class="d-commercial-object-top__zoom" @click="showPopupGallery = true">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-zoom"></use>
            </svg>
          </button>
          <d-commercial-object-slider :images="dataObject.images" :disclaimer="dataObject.disclaimer"/>
        </div>
      </div>
    </div>
    <div class="d-commercial-object-top__item _info">
      <a v-if="dataObject.pdfLink" :href="dataObject.pdfLink" class="d-commercial-object-top__pdf _mobile d-button _dark">
        <span>PDF</span>
      </a>
      <div v-if="dataObject.params" class="d-flat-info _commercial">
        <div v-for="param in dataObject.params" :key="param.id" class="d-flat-info__row">
          <div class="d-flat-info__label">{{ param.name }}</div>
          <div class="d-flat-info__value">
            <template v-if="param.link">
              <a :href="param.link">{{ param.value }}</a>
            </template>
            <template v-else>
              <div v-html="param.value"></div>
            </template>
          </div>
        </div>
      </div>
      <div class="d-commercial-object-top__infoBottom">
        <div class="d-flat-price">
          <div class="d-flat-price__digits">
            <div v-if="dataObject.discount" class="d-flat-price__old">{{ dataObject.priceOld }}</div>
            <div class="d-flat-price__now">{{ dataObject.price }}</div>

          </div>
        </div>
        <button class="d-commercial-object-top__order d-button _blue" data-callMe-show :data-action-url="dataActionUrl" :data-lead-url="dataLeadUrl" :data-props="commercialLeadData">
          <span>Оставить заявку</span>
        </button>
      </div>
    </div>
    <d-popup-simple v-model="showPopupGallery">
      <d-commercial-object-slider :images="dataObject.images" :disclaimer="dataObject.disclaimer" mod="popup"/>
    </d-popup-simple>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { Swiper, SwiperSlide,   } from 'swiper/vue';
import { Navigation, Pagination, Lazy } from 'swiper';
import {ref} from "vue";
import DDisclaimer from "@components/atoms/DDisclaimer.vue";
import DSelect from "@components/molecules/DSelect.vue";
import Cookies from "js-cookie";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
import DCommercialObjectSlider from "@components/molecules/DCommercialObjectSlider.vue";

export default {
  name: "DCommercialInfo",
  components: {
    DDisclaimer,
    Swiper,
    SwiperSlide,
    DSelect,
    DPopupSimple,
    DCommercialObjectSlider
  },
  props: {
    dataActionUrl: {
      type: String,
      default: '',
    },
    dataLeadUrl: {
      type: String,
      default: '',
    },
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const pagination = ref(null)
    return {
      modules: [Navigation, Pagination, Lazy],
      prev,
      next,
      pagination
    };
  },
  data() {
    return {
      dataObject: null,
      tippyTips: [],
      swiperOpts: {
        loop: true,
        preloadImages: false,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      currentFloor: null,
      currentPlan: null,
      commercialLeadData: null,
      screenWidth: 1024,
      screenHeight: 1024,
      screenXS: false,
      showPopupGallery: false
    }
  },
  created() {
    if (window.commercial_object) {
      this.dataObject = Object.freeze(Object.assign({}, window.commercial_object))
    }
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setScreenSize);
  },
  mounted() {
    this.setTippy();
    if (this.dataObject.floors && this.dataObject.floors.length) {
      this.currentFloor = this.dataObject.floors[0].value;
      this.setFloorPlan(this.currentFloor)
    }

    const commercialLeadData = {
      'area': this.dataObject.area,
      'comment': '',
      'email': '',
      'object': this.dataObject.id,
      'formType': 'reserve',
      'price': this.dataObject.price,
      'sourceConversion': '',
      'URL': window.location.href || '',
      'utm': Cookies.get('utm_data') || window.utm_str_src || '',
    };
    this.commercialLeadData = JSON.stringify(commercialLeadData);
    window.tabsWidthStart();
    window.update_social_share();
  },
  methods: {
    setTippy() {
      if (this.tippyTips.length) this.tippyTips.forEach(instance => {
        //console.log('%c${this.$options.name} instance.reference.dataset.vTippy = ', 'color: yellow', instance.reference.dataset.vTippy);
        instance.setContent(instance.reference.dataset.vTippy);
      });

      else document.querySelectorAll('[data-v-tippy]').forEach(el => {
        if (el.dataset.vTippy > '') {
          this.tippyTips.push(tippy(el, {
            //trigger: 'click',
            allowHTML: true,
            interactive: true,
            appendTo: () => document.body,
            offset: [0, 8],
            interactiveDebounce: 10,
            theme: 'flat-button',
            placement: el.dataset.vTippyTop ? 'top' : 'bottom',
            animation: 'fade',
            content: el.dataset.vTippy,
            arrow: false,
          }));
        }
      });
    },
    setFloorPlan(floorValue) {
      const currentFloor = this.dataObject.floors.find((floor) => floor.value === floorValue)
      this.currentPlan = currentFloor.plan
    },
    zoom() {
      const dataImg = this.currentPlan;
      const dataAdditImg = true; //
      const popupData = {dataImg, dataWidth: this.screenWidth, dataHeight: this.screenHeight, imgWidth: 3024, imgHeight: 2021, disclaimer: this.disclaimer, disclaimerShow: 1};
      window.layoutAapp.$vfm.show({component: 'DFlatViewPopup', bind: popupData});
    },
    setScreenSize() {
      this.screenXS = window.matchMedia('(max-width: 767px)').matches;
      this.screenWidth = Math.round(window.innerWidth / 100 * 99);
      this.screenHeight = Math.round(window.innerHeight / 100 * 99);
    },
  }
}
</script>

<style scoped>

</style>
