<template>
  <div class="d-aux-rooms-card _auxRoomSkeleton">
    <div class="d-aux-rooms-card__top">
      <div class="d-aux-rooms-card__name skeleton-field"></div>
      <div class="d-aux-rooms-card__buttons">
        <div class="skeleton-field"></div>
        <div class="skeleton-field"></div>
      </div>
    </div>
    <div class="d-aux-rooms-card__info">
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
    </div>
    <div class="d-aux-rooms-card__bottom">
      <div class="d-aux-rooms-card__price skeleton-field"></div>
      <div class="d-aux-rooms-card__order skeleton-field"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DParkingPlaceCardSkeleton',
}
</script>
