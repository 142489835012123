<template>
  <div class="d-index-flats__filter" :class="{_popup: popup, _objects: mod === 'objects'}">
    <div class="d-index-filter">
      <div v-if="!initial" class="d-index-filter__top">
        <div class="d-index-filter__col _left" :class="{_show: !hidden, _objects: mod === 'objects'}">
          <div class="d-index-filter__head">
            <div class="d-index-filter__title">Фильтр</div>
            <div v-show="tags.length" class="d-index-filter__clear _popup" @click="resetFilters">Сбросить</div>
          </div>
          <div v-if="mod === 'objects'" class="d-index-filter-item d-tabs _filter _availFilter">
            <div class="d-tabs__buttons">
              <div class="d-tabs__buttonsInner">
                <button class="d-tabs__button" :class="{_active: availFilter}" @click="chooseAvailFilter(true)">В продаже</button>
                <button class="d-tabs__button" :class="{_active: !availFilter}" @click="chooseAvailFilter(false)">Все</button>
                <div class="d-tabs__buttonBg"></div>
              </div>
            </div>
          </div>
          <d-filter-item ref="locationFilter"
                         data-title="Расположение"
                         class=" _location"
                         :is-active="locationFilterActive"
                         :tags="locationTags"
                         :screen-xs="screenXS"
                         :alwaysOpen="false"
                         :setClosed="!locationFilterOpen"
                         @resetFilter="resetFilter"
                         @filter:open="toggleLocationFilter">
            <div class="d-tabs _filter">
              <div class="d-tabs__buttons">
                <div class="d-tabs__buttonsInner">
                  <button class="d-tabs__button" :class="{_active: locationFilter === ''}" @click="chooseLocationFilter()">Все</button>
                  <button class="d-tabs__button" :class="{_active: locationFilter === 'metro'}" @click="chooseLocationFilter('metro')">Метро</button>
                  <button class="d-tabs__button" :class="{_active: locationFilter === 'district'}" @click="chooseLocationFilter('district')">Район</button>
                  <div class="d-tabs__buttonBg"></div>
                </div>
              </div>
              <div class="d-tabs__content" :class="{_active: locationFilter === ''}">
                <d-filter-search-list placeholder="Метро или район..."
                                      :items="locationItems"
                                      v-model:search="locationSearch"
                                      v-model:checked="filterParamsLocation"
                                      list-id="all"
                                      :facets="[...facets.metro, ...facets.district]"
                                      :district-list="facets.district"
                                      :metro-list="facets.metro"/>
              </div>
              <div class="d-tabs__content" :class="{_active: locationFilter === 'metro'}">
                <d-filter-search-list placeholder="Метро..." :items="locationItems" v-model:search="locationSearch" v-model:checked="filterParamsLocation" type="metro" list-id="metro" :facets="facets.metro" :district-list="facets.district" :metro-list="facets.metro"/>
              </div>
              <div class="d-tabs__content" :class="{_active: locationFilter === 'district'}">
                <d-filter-search-list placeholder="Район..."
                                      :items="locationItems"
                                      v-model:search="locationSearch"
                                      v-model:checked="filterParamsLocation"
                                      type="district"
                                      list-id="district"
                                      :facets="facets.district"
                                      :district-list="facets.district"
                                      :metro-list="facets.metro"/>
              </div>
            </div>
          </d-filter-item>
          <d-filter-item v-show="!mobileOpen || !locationFilterOpen" ref="paramsFilter" :is-active="paramsFilterActive" data-title="Параметры квартиры" :always-open="mobileOpen" :screen-xs="screenXS">
            <div class="d-tabs _filter">
              <div class="d-tabs__buttons">
                <div class="d-tabs__buttonsInner">
                  <button ref="category_null" class="d-tabs__button _active" @click="chooseCategory()">Все</button>
                  <button v-for="flatType in filters.category" :ref="`category_${flatType.pk}`" :key="flatType.pk" class="d-tabs__button" @click="chooseCategory(flatType.pk)">
                    {{ flatType.filter_title }}
                  </button>
                  <div ref="category_buttonBg" class="d-tabs__buttonBg"></div>
                </div>
              </div>
              <div class="d-tabs__content _active _single">
                <div class="d-index-filter-params _params">
                  <div class="d-index-filter-params__row">
                    <div class="d-index-filter-params__label">Количество комнат</div>
                    <div class="d-index-filter-params__checkboxes _rooms">
                      <div v-for="rooms in filters.rooms" :key="rooms.pk" class="d-checkbox _full" :class="{_disabled: !facets.rooms.includes(rooms.pk)}">
                        <input class="d-checkbox__input" type="checkbox" name="room" :id="`room-${rooms.pk}`" v-model="filterParams.rooms" :value="rooms.pk" :disabled="!facets.rooms.includes(rooms.pk)">
                        <label class="d-checkbox__label" :for="`room-${rooms.pk}`">{{ rooms.filter_title }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="d-index-filter-params__row">
                    <div class="d-range-slider _pb">
                      <DInputSliderRange :init-values="[facets.area.min, facets.area.max]"
                                         ref="areaFilter"
                                         title="Площадь"
                                         unit="м²"
                                         type="number"
                                         v-model="filterParams.area"
                                         @focus="setFocus"
                                         :step="0.1"
                                         :from="filters.area.min"
                                         :to="filters.area.max"/>
                    </div>
                  </div>
                  <div class="d-index-filter-params__row">
                    <div class="d-range-slider _moreParams">
                      <DInputSliderRange :init-values="[facets.floor_number.min, facets.floor_number.max]"
                                         ref="floorFilter"
                                         title="Этаж"
                                         unit=""
                                         type="number"
                                         v-model="filterParams.floor_number"
                                         @focus="setFocus"
                                         :step="1"
                                         :from="filters.floor_number.min"
                                         :to="filters.floor_number.max"/>
                    </div>
                    <div class="d-checkbox">
                      <input class="d-checkbox__input" type="checkbox" name="notFL" id="floor_first_last" v-model="filterParams.floor_first_last">
                      <label class="d-checkbox__label" for="floor_first_last">Не первый и не последний</label>
                    </div>
                  </div>
                  <div class="d-index-filter-params__row">
                    <div class="d-index-filter-params__label">Отделка</div>
                    <div class="d-index-filter-params__checkboxes">
                      <div v-for="furnish in filters.furnish" :key="furnish.pk" class="d-checkbox _full" :class="{_disabled: !facets.furnish.includes(furnish.pk)}">
                        <input class="d-checkbox__input"
                               type="checkbox"
                               name="furnish"
                               v-model="filterParams.furnish"
                               :id="`furnish-${furnish.pk}`"
                               :value="furnish.pk"
                               :disabled="!facets.furnish.includes(furnish.pk)">
                        <label class="d-checkbox__label" :for="`furnish-${furnish.pk}`">{{ furnish.filter_title }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </d-filter-item>
          <d-filter-item :class="`_${mod} _price`"
                         v-show="!mobileOpen || !locationFilterOpen"
                         ref="priceFilter"
                         :is-active="priceFilterActive"
                         data-title="Стоимость"
                         :always-open="mobileOpen"
                         :screen-xs="screenXS">
            <div class="d-index-filter-params__row">
              <div class="d-range-slider _moreParams">
                <DInputSliderRange ref="priceFilter" title="" unit="млн ₽" v-model="filterParams.price" @focus="setFocus" :step="0.01" :from="filters.price.min" :to="filters.price.max" type="price"/>
              </div>
              <div class="d-checkbox">
                <input class="d-checkbox__input" type="checkbox" name="promotion" id="promotion" v-model="filterParams.promotion">
                <label class="d-checkbox__label" for="promotion">
                  Со скидкой
                </label>
              </div>
            </div>
          </d-filter-item>
        </div>
        <div v-if="mod === 'index' || !desktopXS" class="d-index-filter__col _clear">
          <button class="d-index-filter__clear" @click="resetFilters" :disabled="!tags.length">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
            </svg>
            Очистить фильтры
          </button>
        </div>
      </div>
      <div class="d-index-filter__choose d-index-filter-choose" :class="`_${mod}`">
        <template v-if="mod === 'index'">
          <div class="d-index-filter-choose__list">
            <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
              <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
              <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
                </svg>
              </button>
            </div>
          </div>
          <div class="d-index-filter-choose__count" :class="`_${mod}`">{{ result.total_string }}</div>
        </template>
        <template v-else>
          <div class="d-index-filter-choose__count" :class="`_${mod}`">{{ result.total_string }}</div>
          <div class="d-index-filter-choose__list">
            <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
              <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
              <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
                </svg>
              </button>
            </div>
          </div>
          <button v-if="desktopXS" class="d-index-filter__clear" @click="resetFilters" :disabled="!tags.length">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-refresh"></use>
            </svg>
            Очистить фильтры
          </button>
        </template>
      </div>
    </div>
    <div class="d-index-filter__popup-buttons">
      <button v-if="!locationFilterOpen && !loading" class="d-button _blue _show" @click="closeFilter"><span>{{ result.total_string }}</span></button>
      <button v-else-if="!locationFilterOpen && loading" class="skeleton-button d-button _blue _show"><span class="skeleton-button__loader"></span></button>
      <button v-if="locationFilterOpen" class="d-button _blue" @click="closeLocationFilter"><span>Сохранить</span></button>
    </div>
  </div>
</template>

<script>
import {useTagsStore} from '@/stores/index';
import {mapWritableState} from 'pinia';
import {toRaw} from 'vue';
import {isEqual as _isEqual, debounce as _debounce} from 'lodash';
import DFilterItem from '@components/organisms/DFilterItem.vue';
import DInputSliderRange from '@components/atoms/DInputSliderRange.vue';
import DFilterSearchList from '@components/molecules/DFilterSearchList.vue';
import {subscribe, isSupported} from 'on-screen-keyboard-detector';

export default {
  name: 'DIndexFilterWrapper',
  components: {DFilterItem, DInputSliderRange, DFilterSearchList},
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/json/home-filter-api.json',
    },
    dataFilterParamsApi: {
      type: String,
      default: '/assets/json/home-filter-params.json',
    },
    dataFilterSpecsApi: {
      type: String,
      default: '/assets/json/home-filter-specs.json',
    },
    dataChooseParamsLink: {
      type: String,
      default: 'https://donstroy.moscow/full-search/',
    },
    dataCallMeUrl: {
      type: String,
      default: '',
    },
    filterShow: {
      type: Boolean,
      default: true,
    },
    mod: {
      type: String,
      default: 'index',
    },
    popup: {
      type: Boolean,
      default: false,
    },
    filterParamsOutside: {
      type: Object,
      default: () => {},
    },
    resetFiltersFlag: {
      type: [Boolean, Number],
      default: true,
    },
    resetFilterTag: {
      type: Object,
      default: () => {},
    },
    dataInitialFilter: {
      type: String,
      default: '{}',
    },
    screenTablet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterParams: {
        price: [1, 100000],
        category: null,
        rooms: [],
        metro: [],
        district: [],
        area: [0, 500],
        floor_number: [0, 300],
        furnish: [],
        floor_first_last: false,
        available: true,
      },
      initialFilterData: {},
      initialFilterParams: {},
      filters: {
        category: [],
        rooms: [],
        price: {
          'min': 6.52,
          'max': 89.56,
        },
        metro: [],
        district: [],
        area: {
          'min': 17,
          'max': 343,
        },
        floor_number: {
          'min': 1,
          'max': 66,
        },
        furnish: [],
        floor_first_last: false,
        available: true,
      },
      result: {
        more_link: '',
        items: [],
        total: 0,
        total_string: '',
      },
      locationSearch: '',
      locationFilter: '',
      filterParamsLocation: [],
      locationFilterBackup: [],
      facets: {},
      loading: true,
      errored: true,
      initial: true,
      hidden: false,
      mobileOpen: false,
      screenXS: false,
      desktopXS: false,
      locationFilterOpen: false,
      focusEl: null,
    };
  },
  created() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.initFilters();
  },
  mounted() {
    if (this.dataInitialFilter) {
      try {
        this.initialFilterData = JSON.parse(this.dataInitialFilter);
      } catch (e) {
        console.error('%cCannot parse dataInitialFilter: ', e);
      }
    }
    else console.error('%cdataInitialFilter not yet provided = ', this.dataInitialFilter);

    if (isSupported()) {
      subscribe(visibility => {
        if (visibility === 'visible' && this.focusEl) {
          this.focusEl.scrollIntoView();
        }
      });
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapWritableState(useTagsStore, [
      'tags',
    ]),
    locationItems() {
      return [...this.filters.metro, ...this.filters.district];
    },
    locationFilterActive() {
      return this.filterParamsLocation.length > 0;
    },
    paramsFilterActive() {
      return this.tags.filter(el => el.filter === 'params').length > 0;
    },
    priceFilterActive() {
      return !_isEqual(this.filterParams.price, this.initialFilterParams.price) || this.tags.filter(el => el.filter === 'price').length > 0;
    },
    locationTags() {
      return this.tags.filter(el => el.filter === 'location');
    },
    event() {
      return !this.locationFilterOpen ? 'click' : null;
    },
    availFilter() {
      return this.filterParams.available;
    },
  },
  watch: {
    filterParams: {
      handler(params) {
        if (!this.initial) {
          //console.log('%cwatch filterParams params = ', 'color: yellow', params);
          this.setTags(params);
          this.sendData();
        }
      },
      deep: true,
    },
    filterParamsLocation: {
      handler(params) {
        this.filterParams.metro = params.filter(x => this.facets.metro.includes(x));
        this.filterParams.district = params.filter(x => this.facets.district.includes(x));
      },
      deep: true,
    },
    result: {
      handler(data) {
        this.$emit('update:result', data);
      },
      deep: true,
    },
    filterShow(newValue) {
      if (newValue) this.locationFilterOpen = false;
    },
    locationFilterOpen(newValue) {
      if (newValue) this.locationFilter = '';
    },
    filterParamsOutside: {
      handler(data) {
        for (const param in data) {
          if (this.filterParams.hasOwnProperty(param)) {
            this.filterParams[param] = data[param];
          }
        }
      },
      deep: true,
    },
    tags() {
      this.$emit('tags', this.tags);
    },
    resetFiltersFlag(value) {
      if (value) this.resetFilters();
    },
    resetFilterTag(tag) {
      if (tag) this.resetFilter(tag);
    },
  },
  methods: {
    setFocus(e) {
      if (e && e.target) this.focusEl = e.target;
      return true;
    },
    preFillFilter() {
      if (Object.keys(this.initialFilterData).length) {
        for (let key in this.initialFilterData) {
          switch (key) {
            case 'district':
            case 'metro': {
              this.filterParamsLocation = this.initialFilterData[key];
              break;
            }
            case 'price':
            case 'area':
            case 'floor_number': {
              this.filterParams[key] = [this.initialFilterData[key].min, this.initialFilterData[key].max];
              break;
            }
            default:
              this.filterParams[key] = this.initialFilterData[key];
          }
        }
      }
    },
    closeFilter() {
      this.$emit('close');
    },
    toggleLocationFilter(open) {
      this.locationFilterOpen = open;
      if(open) {
        this.locationFilterBackup = this.filterParamsLocation
      }
      else if (this.screenTablet) {
        this.filterParamsLocation = this.locationFilterBackup;
        this.locationFilterBackup = [];
      }
    },
    closeLocationFilter: _debounce(function() {
      this.locationFilterOpen = false;
    }, 150),
    checkScreenSize() {
      this.desktopXS = this.screenXS = window.matchMedia('(max-width: 1599px)').matches;
      this.mobileOpen = this.screenXS = window.matchMedia('(max-width: 1279px)').matches;
    },
    async initFilters() {
      await this.getSpecs();
      this.initFilterParams(); // => this.getData();
      await this.getFacets();
      this.preFillFilter();
      this.initial = false;
      this.$nextTick(() => {
        window.tabsWidthStart();
      });
    },
    resetFilter(tag) {
      const [filter, category, value] = [tag.filter, tag.category, tag.pk];
      if (filter === 'price') this.filterParams.price = this.initialFilterParams.price;
      if (filter === 'location') this.filterParamsLocation = this.filterParamsLocation.filter(v => v.toString() !== value.toString());

      if (category === 'category') {
        this.chooseCategory();
        this.$refs.category_null.click();
      }

      if (category === 'rooms')
        this.filterParams.rooms = this.filterParams.rooms.filter(v => v.toString() !== value.toString());
      if (category === 'furnish') this.filterParams.furnish = [];
      if (category === 'area') this.filterParams.area = this.initialFilterParams.area;
      if (category === 'floor_number') this.filterParams.floor_number = this.initialFilterParams.floor_number;
      if (category === 'floor_first_last') this.filterParams.floor_first_last = false;
      if (category === 'available') this.filterParams.available = true;
      if (category === 'promotion') this.filterParams.promotion = false;
    },
    async resetFilters() {
      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));
      this.filterParamsLocation = [];
      this.chooseCategory();
      this.$refs.category_null.click();
      this.tags = [];
      await this.getFacets();
      await this.getData();
    },
    chooseAvailFilter(type) {
      this.filterParams.available = type;
    },
    chooseLocationFilter(type) {
      this.locationSearch = '';
      this.locationFilter = type;
    },
    chooseCategory(pk = null) {
      this.filterParams.category = pk;
    },
    initFilterParams() {
      this.initialFilterParams = {
        price: [this.filters.price.min, this.filters.price.max],
        category: null,
        rooms: [],
        metro: [],
        district: [],
        area: [this.filters.area.min, this.filters.area.max],
        floor_number: [this.filters.floor_number.min, this.filters.floor_number.max],
        furnish: [],
        floor_first_last: false,
        available: true,
      };
      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));
      this.filterParamsLocation = [];
      this.tags = [];
      this.getData();
    },
    async getSpecs() {
      try {
        let response = await this.axios.get(this.dataFilterSpecsApi);
        const filters = response.data.specs;
        filters.metro = filters.metro.map(el => {
          el.locationType = 'metro';
          return el;
        });
        filters.district = filters.district.map(el => {
          el.locationType = 'district';
          return el;
        });
        filters.category = filters.category.map(el => {
          el.type = 'category';
          return el;
        });
        filters.rooms = filters.rooms.map(el => {
          el.type = 'rooms';
          return el;
        });
        this.filters = filters;
        return this.filters;
      } catch (err) {
        console.error('%cDIndexFilter getSpecs() error load getSpecs result', 'color: yellow', err);
      }
    },
    async getFacets(withFilter = false) {
      this.setLoading(true);
      try {
        const response = withFilter
                         ? await this.axios.post(this.dataFilterParamsApi, this.filterParams)
                         : await this.axios.get(this.dataFilterParamsApi);

        this.facets = response.data.facets;

        const newPricesRange = [this.facets.price.min, this.facets.price.max];
        const newAreaRange = [this.facets.area.min, this.facets.area.max];
        const newFloorRange = [this.facets.floor_number.min, this.facets.floor_number.max];
        if (_isEqual(this.initialFilterParams.price, this.filterParams.price) &&
          !_isEqual(this.filterParams.price, newPricesRange)) this.filterParams.price = newPricesRange;
        if (_isEqual(this.initialFilterParams.area, this.filterParams.area) &&
          !_isEqual(this.filterParams.area, newAreaRange)) this.filterParams.area = newAreaRange;
        if (_isEqual(this.initialFilterParams.floor_number, this.filterParams.floor_number) &&
          !_isEqual(this.filterParams.floor_number, newFloorRange)) this.filterParams.floor_number = newFloorRange;

        return this.facets;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.setLoading(false);
      }
    },
    async getData() {
      this.setLoading(true);
      try {
        const response = await this.axios.get(this.dataUrlApi);
        this.result = response.data;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.setLoading(false);
      }
    },
    setLoading: _debounce(function(value) {
      this.loading = value;
    }, 250),
    sendData: _debounce(async function() {
      this.setLoading(true);
      try {
        //console.log('%csendData', 'color: yellow');
        await this.getFacets(true);
        this.setLoading(true);
        const response = await this.axios.post(this.dataUrlApi, this.filterParams);
        //console.log('%csendData response = ', 'color: yellow', response);
        this.result = response.data;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.setLoading(false);
      }
    }, 500),
    setTags: _debounce(async function(params) {
      const tags = [];
      const facetsPrice = [this.facets.price.min, this.facets.price.max];
      if (!_isEqual(params.price, this.initialFilterParams.price) && !_isEqual(params.price, facetsPrice)) {
        const [min, max] = params.price;
        tags.push({
          filter: 'price',
          category: 'price',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } млн ₽`,
          pk: null,
        });
      }
      const facetsArea = [this.facets.area.min, this.facets.area.max];
      if (!_isEqual(params.area, this.initialFilterParams.area) && !_isEqual(params.area, facetsArea)) {
        const [min, max] = params.area;
        tags.push({
          filter: 'params',
          category: 'area',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } м²`,
          pk: null,
        });
      }
      const facetsFloor = [this.facets.floor_number.min, this.facets.floor_number.max];
      if (!_isEqual(params.floor_number, this.initialFilterParams.floor_number) && !_isEqual(params.floor_number, facetsFloor)) {
        const [min, max] = params.floor_number;
        tags.push({
          filter: 'params',
          category: 'floor_number',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } этажи`,
          pk: null,
        });
      }
      if (params.category) {
        const curCat = this.filters.category.find(el => el.pk.toString() === params.category);
        curCat.filter = 'params';
        curCat.category = 'category';
        tags.push(curCat);
      }
      if (!params.available) {
        tags.push({
          filter: 'available',
          category: 'available',
          tag_title: 'Все',
          pk: null,
        });
      }
      if (params.floor_first_last) {
        tags.push({
          filter: 'params',
          category: 'floor_first_last',
          tag_title: `Не первый и не последний этаж`,
          pk: null,
        });
      }
      if (params.promotion) {
        tags.push({
          filter: 'price',
          category: 'promotion',
          tag_title: `Со скидкой`,
          pk: null,
        });
      }
      if (params.rooms.length > 0) {
        const titles = params.rooms.map(roomPk => [roomPk, this.filters.rooms.find(el => el.pk.toString() === roomPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'params',
            category: 'rooms',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.furnish.length > 0) {
        const titles = params.furnish.map(furnishPk => [furnishPk, this.filters.furnish.find(el => el.pk.toString() === furnishPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'params',
            category: 'furnish',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.metro.length > 0) {
        const titles = params.metro.map(metroPk => [metroPk, this.filters.metro.find(el => el.pk.toString() === metroPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'location',
            category: 'metro',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.district.length > 0) {
        const titles = params.district.map(metroPk => [metroPk, this.filters.district.find(el => el.pk.toString() === metroPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'location',
            category: 'district',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }

      this.tags = tags;

    }, 50),
  },
};
</script>

<style scoped>

</style>
