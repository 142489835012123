<template>
  <d-popup v-slot="{ close }" v-bind="$attrs">
    <div class="d-callMe__form">
      <div class="d-callMe__top">
        <div class="d-callMe__title">Заявка<br/>на ипотеку</div>
        <div class="d-callMe__subtitle">Оставьте Ваши контакты и наши менеджеры свяжутся с вами</div>
        <picture>
          <source srcset="/assets/blueant/assets/img/d/flat_order_bg_320.jpg" media="(max-width: 767px)">
          <img class="d-callMe__bg" src="/assets/blueant/assets/img/d/flat_order_bg.jpg">
        </picture>
      </div>
      <d-call-me-form :data-action-url="actionUrl" :data-lead-url="leadUrl" :data-props="dataProps" :data-politics-link="politicsLink" data-mod="popup" data-type="mortgage" @success="close"/>
    </div>
  </d-popup>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';
import DCallMeForm from '@components/organisms/DCallMeForm.vue';

export default {
  name: 'DFlatMortgageFormPopup',
  components: {
    DPopup, DCallMeForm,
  },
  props: {
    actionUrl: {
      type: String,
      required: true,
      default: '',
    },
    leadUrl: {
      type: [String, Boolean],
      default: false,
    },
    dataProps: {
      type: [Object, Boolean],
      default: false,
    },
    politicsLink: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>
