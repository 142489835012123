<template>
  <div class="d-choose-params d-container">
    <div class="d-choose-params__top" ref="paramsTop">
      <h1 class="d-choose-params__title">Выбор квартиры</h1>
      <div class="d-choose-params__buttons">
        <a :href="btnMapUrl" class="d-button _blue">
          <span>Все объекты</span>
        </a>
      </div>
      <div class="d-choose-params__buttons _mobile">
        <button class="d-choose-params__buttonFilter" @click="openFilterMobile">
          <img src="/assets/blueant/assets/img/d/icon_filter.svg" alt="">
          <span>Фильтры</span>
        </button>
        <a :href="btnMapUrl" class="d-choose-params__buttonOnMap">
          <span>Все объекты</span>
        </a>
      </div>
    </div>
    <div class="d-choose-params__filter" ref="filterDesktop">
      <d-choose-params-filter v-if="!isTouchDevice"
                              :data-api-method="apiMethod"
                              :data-filter-specs-api="filterSpecsApi"
                              :data-filter-params-api="filterParamsApi"
                              :remove-tag="removeTag"
                              :tags-items="tags"
                              :reset-all-filters="resetAllFilters"
                              :type-single-is-open="openTypeDataId"
                              @update:tagsItems="setTags"
                              @filterChanged="getData"/>
    </div>
    <div class="d-choose-params__content">
      <button class="d-choose-params__buttonFilter _fixed" :class="{ _show: showFilterFixedBtn }" @click="fixedFilterBtnHandler">
        <img src="/assets/blueant/assets/img/d/icon_filter.svg"><span>Фильтр</span>
      </button>
      <div class="d-tabs _whiteBg">
        <div class="d-choose-params__controls">
          <div class="d-tabs__buttons">
            <div class="d-tabs__buttonsInner">
              <button class="d-tabs__button" :class="{ _active: viewType === 'flats' && !openTypeDataId}" @click="setViewType('flats')">Квартиры</button>
              <button class="d-tabs__button" :class="{ _active: viewType === 'types' || openTypeDataId}" @click="setViewType('types')">Типы</button>
              <div class="d-tabs__buttonBg"></div>
            </div>
          </div>


          <div v-if="(totalNum && (notStandardSearch && !openTypeDataId) || (notStandardSearchTypeFlats && openTypeDataId))" class="d-choose-params__not-empty-result">По заданным параметрам ничего не найдено,
            показаны наиболее близкие варианты.
          </div>
          <div v-else-if="totalNum" class="d-choose-params__count">Найдено {{ totalStr }}</div>
          <div class="d-choose-params__sorter">
            <d-select v-show="view === 'card' || isTouchDevice || openTypeDataId" :data-options="sortingData" v-model="sortParam" icon="sort2"/>
          </div>
          <div class="d-choose-params__switchView" :class="{ _disabled: openTypeDataId }">
            <div class="d-switch-view" :class="{ _list: view === 'list'}">
              <button class="d-switch-view__button" :class="{ _active: view === 'list'}" @click="changeView()">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-list"></use>
                </svg>
              </button>
              <button class="d-switch-view__button" :class="{ _active: view === 'card'}" @click="changeView()">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-card"></use>
                </svg>
              </button>
              <div class="d-switch-view__buttonBg"></div>
            </div>
          </div>
        </div>
        <div v-if="tags.length" class="d-choose-params__tags">
          <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item" :class="{ _disabled: tagDisableControl(tag) && openTypeDataId }">
            <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
            <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
              </svg>
            </button>
          </div>
        </div>
        <div class="d-tabs__content" :class="{ _active: viewType === 'flats' && !openTypeDataId}">
          <div class="d-choose-params__results" :class="{ _list: view === 'list'}" data-switch-content>
            <div class="d-choose-params__list">
              <template v-if="loadingFlats && total_flats">
                <template v-for="item in flatsResult" :key="item.id">
                  <d-choose-utp-card v-if="item.isUtp" :data="item" @open-utp="openUtp"/>
                  <d-choose-flat-card v-else :data="item" @set-favorite="setFavorite" :favorites="favorites"/>
                </template>
              </template>
              <template v-else-if="!loadingFlats">
                <d-choose-flat-card v-for="item in [1,2,3,4,5,6]" mod="skeleton"/>
              </template>

            </div>
            <div class="d-choose-params__list _list">
              <div v-if="total_flats" class="d-choose-params__sorter _line">
                <div class="d-sorter-line _choose">
                  <div class="d-sorter-line__item _item1">Планировка</div>
                  <button class="d-sorter-line__item _item2"
                          :class="{ _active: sortValue === 'room' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'room' }"
                          @click="changeSortLine('room', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Комнатность</span>
                    <span class="short">Комнатность</span>
                  </button>
                  <div class="d-sorter-line__item _item3">Объект</div>
                  <div class="d-sorter-line__item _item4">Корпус</div>
                  <button class="d-sorter-line__item _item5"
                          :class="{ _active: sortValue === 'floor' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'floor' }"
                          @click="changeSortLine('floor', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Этаж</span>
                    <span class="short">Этаж</span>
                  </button>
                  <div class="d-sorter-line__item _item6">Номер</div>
                  <button class="d-sorter-line__item _item7"
                          :class="{ _active: sortValue === 'area' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'area' }"
                          @click="changeSortLine('area', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Площадь, м&sup2;</span>
                    <span class="short">Площадь, м&sup2;</span>
                  </button>
                  <button class="d-sorter-line__item _item8"
                          :class="{ _active: sortValue === 'price' , _desc: this.sortOrder === 'desc' && sortValue === 'price', _asc: this.sortOrder === 'asc' && sortValue === 'price' }"
                          @click="changeSortLine('price', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Стоимость, ₽</span>
                    <span class="short">Стоимость, ₽</span>
                  </button>
                  <div class="d-sorter-line__item _item9">Особенности</div>
                  <div class="d-sorter-line__item _item10">&nbsp;</div>
                </div>
              </div>
              <template v-if="loadingFlats && total_flats">
                <template v-for="item in flatsResult" :key="item.id">
                  <d-choose-utp-card v-if="item.isUtp" :data="item" view="list" @open-utp="openUtp"/>
                  <d-choose-flat-row v-else :data="item" @set-favorite="setFavorite" :favorites="favorites"/>
                </template>
              </template>

              <template v-else-if="!loadingFlats">
                <d-choose-flat-row v-for="item in [1,2,3,4,5,6]" mod="skeleton"/>
              </template>
            </div>
            <div v-if="total_flats" class="d-choose-params__bottom">
              <div v-if="pages.pages > 1" class="d-choose-params__pager">
                <d-press-pager :pages-count="pages.pages" :current-page-index="pages.showPage" @changePage="setPage"/>
              </div>
              <div class="d-choose-params__more">
                <button v-if="remainFlatsItems > 0" class="d-button _dark" @click="addMore">
                  <span>Показать еще</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="loadingFlats && !total_flats" class="d-choose-params-empty">
            <div class="d-choose-params-empty__text">По заданным параметрам ничего не найдено.
              Попробуйте&nbsp;сбросить фильтр или задать другие параметры.
            </div>
            <button class="d-choose-params-empty__button d-button _dark" @click="resetFilters">
              <span>Сбросить фильтры</span>
            </button>
          </div>
        </div>
        <div class="d-tabs__content" :class="{ _active: viewType === 'types' || openTypeDataId}">
          <template v-if="openTypeDataId && !isTouchDevice">
            <d-choose-type-single v-if="openTypeDataId && filterParams"
                                  :api-flats-url="apiTypeSingleUrl"
                                  :api-method="apiMethod"
                                  :current-type-id="openTypeDataId"
                                  :filters="filterParams"
                                  :sort="sortParam"
                                  :flats-per-page="typeSinglePerPageFlats"
                                  :update="updateTypeSingleData"
                                  :favorites="favorites"
                                  @reset="resetFilters"
                                  @closeType="closeTypeSingle"
                                  @update-total="getTotalFlatsTypeSingle"
                                  @set-favorite="setFavorite"
                                  @search-type="setSearchType"/>
          </template>
          <template v-else>
            <div class="d-choose-params__results" :class="{ _list: view === 'list'}">
              <div class="d-choose-params__list">
                <template v-if="loadingTypes && total_types">
                  <template v-for="type in typesResult" :key="type.id">
                    <d-choose-utp-card v-if="type.isUtp" :data="type" @open-utp="openUtp"/>
                    <d-choose-type-card v-else :data="type" @openType="openTypeSingle"/>
                  </template>
                </template>
                <template v-else-if="!loadingTypes">
                  <d-choose-type-card v-for="item in [1,2,3,4,5,6]" mod="typeSkeleton"/>
                </template>

              </div>
              <div class="d-choose-params__list _list">
                <div v-if="total_types" class="d-sorter-line _choose _type">
                  <div class="d-sorter-line__item _item1">Планировка</div>
                  <button class="d-sorter-line__item _item2"
                          :class="{ _active: sortValue === 'room' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'room' }"
                          @click="changeSortLine('room', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Комнатность</span>
                    <span class="short">Комнатность</span>
                  </button>
                  <div class="d-sorter-line__item _item3">Объект</div>
                  <button class="d-sorter-line__item _item4"
                          :class="{ _active: sortValue === 'floor' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'floor' }"
                          @click="changeSortLine('floor', $event)">
                    <span class="d-sorter-direction _line">
                      <span></span>
                    </span>
                    <span>Этаж</span>
                    <span class="short">Этаж</span>
                  </button>
                  <button class="d-sorter-line__item _item5"
                          :class="{ _active: sortValue === 'area' , _desc: this.sortOrder === 'desc' && sortValue === 'area', _asc: this.sortOrder === 'asc' && sortValue === 'area' }"
                          @click.stop.prevent="changeSortLine('area', $event)">
                  <span class="d-sorter-direction _line">
                    <span></span>
                  </span>
                    <span>Площадь, м²</span>
                    <span class="short">Площадь, м²</span>
                  </button>
                  <button class="d-sorter-line__item _item6"
                          :class="{ _active: sortValue === 'price' , _desc: this.sortOrder === 'desc' && sortValue === 'price', _asc: this.sortOrder === 'asc' && sortValue === 'price' }"
                          @click.stop.prevent="changeSortLine('price', $event)">
                  <span class="d-sorter-direction _line">
                    <span></span>
                  </span>
                    <span>Стоимость, ₽</span>
                    <span class="short">Стоимость, ₽</span>
                  </button>
                  <div class="d-sorter-line__item _item7">&nbsp;</div>
                </div>
                <template v-if="loadingTypes && total_types">
                  <template v-for="type in typesResult" :key="type.id">
                    <d-choose-utp-card v-if="type.isUtp" :data="type" view="list" @open-utp="openUtp"/>
                    <d-choose-type-row v-else :data="type" @openType="openTypeSingle"/>
                  </template>

                </template>
                <template v-else-if="!loadingTypes">
                  <d-choose-type-row v-for="item in [1,2,3,4,5,6]" mod="typeSkeleton"/>
                </template>

              </div>
              <div v-if="total_types" class="d-choose-params__bottom">
                <div v-if="pages.pages > 1" class="d-choose-params__pager">
                  <d-press-pager :pages-count="pages.pages" :current-page-index="pages.showPage" @changePage="setPage"/>
                </div>
                <div class="d-choose-params__more">
                  <button v-if="remainTypesItems > 0" class="d-button _dark" @click="addMore">
                    <span>Показать еще</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="loadingTypes && !total_types" class="d-choose-params-empty">
              <div class="d-choose-params-empty__text">По заданным параметрам ничего не найдено.
                Попробуйте&nbsp;сбросить фильтр или задать другие параметры.
              </div>
              <button class="d-choose-params-empty__button d-button _dark" @click="resetFilters">
                <span>Сбросить фильтры</span>
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <d-popup-simple v-model="showFilterMobile" mod="filter">
      <d-choose-params-filter v-if="isTouchDevice"
                              :data-filter-specs-api="filterSpecsApi"
                              :data-filter-params-api="filterParamsApi"
                              :data-api-method="apiMethod"
                              :remove-tag="removeTag"
                              :tags-items="tags"
                              :reset-all-filters="resetAllFilters"
                              :total="viewType === 'flats' ? total_flats : total_types"
                              :popupIsOpen="showFilterMobile"
                              :type-single-is-open="openTypeDataId"
                              :view-type="viewType"
                              :not-standard-search="notStandardSearch"
                              mod="mobile"
                              @update:tagsItems="setTags"
                              @filterChanged="getData"
                              @closed="showFilterMobile = false"/>
    </d-popup-simple>
    <d-popup-simple v-model="typePopupShow" @closed="closeTypeSingle" mod="type-single">
      <d-choose-type-single v-if="openTypeDataId && isTouchDevice && filterParams"
                            :api-flats-url="apiTypeSingleUrl"
                            :api-method="apiMethod"
                            :current-type-id="openTypeDataId"
                            :flats-per-page="typeSinglePerPageFlats"
                            :filters="filterParams"
                            :sort="sortParam"
                            :update="updateTypeSingleData"
                            :favorites="favorites"
                            @reset="resetFilters"
                            @update-total="getTotalFlatsTypeSingle"
                            @set-favorite="setFavorite"
                            @search-type="setSearchType"/>
    </d-popup-simple>
    <d-popup-simple v-model="utpShow" mod="utp" @closed="utpText = null">
      <div v-if="utpText" v-html="utpText"></div>
    </d-popup-simple>
  </div>
  <teleport to="body">
    <notifications position="top right" animation-type="velocity" :max="3" :reverse="true" :ignoreDuplicates="true" :pauseOnHover="true" :closeOnClick="false" :duration="notificationDuration">
      <template #body="props">
        <d-notification-item v-bind="{...props}"/>
      </template>
    </notifications>
  </teleport>
</template>

<script>
import DChooseParamsFilter from '@components/organisms/DChooseParamsFilter.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import DSelect from '@components/molecules/DSelect.vue';
import {debounce as _debounce} from 'lodash';
import DChooseFlatCard from '@components/molecules/DChooseFlatCard.vue';
import DChooseFlatRow from '@components/molecules/DChooseFlatRow.vue';
import DChooseTypeCard from '@components/molecules/DChooseTypeCard.vue';
import DChooseTypeRow from '@components/molecules/DChooseTypeRow.vue';
import DChooseUtpCard from '@components/molecules/DChooseUtpCard.vue';
import DChooseTypeSingle from '@components/molecules/DChooseTypeSingle.vue';
import DPressPager from '@components/molecules/DPressPager.vue';
import declOfNum from '@utils/declOfNum';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import getTouchDevice from '@utils/getTouchDevice';
import DNotificationItem from '@components/atoms/DNotificationItem.vue';

export default {
  name: 'DChooseParams',
  components: {
    DNotificationItem,
    DChooseParamsFilter,
    DPopupSimple,
    DSelect,
    DChooseFlatCard,
    DChooseFlatRow,
    DChooseTypeCard,
    DChooseTypeRow,
    DChooseUtpCard,
    DChooseTypeSingle,
    DPressPager,
  },
  props: {
    apiUrlFlats: {
      type: String,
      default: '/assets/blueant/assets/json/choose-params-api-flats.json',
    },
    apiUrlTypes: {
      type: String,
      default: '/assets/blueant/assets/json/choose-params-api-types.json',
    },
    apiTypeSingleUrl: {
      type: String,
      default: '/assets/blueant/assets/json/choose-type-flats.json',
    },
    filterSpecsApi: {
      type: String,
      default: '/assets/json/choose-params-specs.json',
    },
    filterParamsApi: {
      type: String,
      default: '/assets/json/choose-filter-params.json',
    },
    apiMethod: {
      type: String,
      default: 'post',
    },
    typeSinglePerPageFlats: {
      type: String,
      default: 3,
    },
    perPageFlats: {
      type: [Number, String],
      default: 9,
    },
    perPageTypes: {
      type: [Number, String],
      default: 9,
    },
    btnMapUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tags: [],
      flatsResult: [],
      typesResult: [],
      removeTag: null,
      showFilterMobile: false,
      view: 'card',
      viewType: 'flats',
      notStandardSearch: false,
      notStandardSearchTypeFlats: false,
      sortingData: [
        {
          id: 1,
          name: 'По возрастанию цены',
          value: 'price-asc',
        },
        {
          id: 2,
          name: 'По убыванию цены',
          value: 'price-desc',
        },
        {
          id: 3,
          name: 'По возрастанию площади',
          value: 'area-asc',
        },
        {
          id: 4,
          name: 'По убыванию площади',
          value: 'area-desc',
        },
        {
          id: 5,
          name: 'По возрастанию этажа',
          value: 'floor-asc',
        },
        {
          id: 6,
          name: 'По убыванию этажа',
          value: 'floor-desc',
        },
        {
          id: 7,
          name: 'По возрастанию комнатности',
          value: 'room-asc',
        },
        {
          id: 8,
          name: 'По убыванию комнатности',
          value: 'room-desc',
        },
      ],
      sortParam: 'price-asc',
      openTypeDataId: null,
      typePopupShow: false,
      filterParams: null,
      pages: {},
      remainFlatsItems: null,
      remainTypesItems: null,
      total_flats: 0,
      total_types: 0,
      resetAllFilters: false,
      loadingFlats: false,
      loadingTypes: false,
      flatsTotalTypeSingle: null,
      showFilterFixedBtn: false,
      utpShow: false,
      utpText: null,
      sortOrder: 'desc',
      sortValue: 'price',
      updateTypeSingleData: false,
      callMeFormIsVisible: false,
      filterIsVisible: false,
      favorites: null,
      touchDevice: false,
      screenPhone: false,
      notificationDuration: 5000,
    };
  },
  watch: {
    sortParam() {
      this.pages.page = 0;
      this.pages.showPage = 0;
      this.getData(this.filterParams);

      this.updateTypeSingleData = true;
      setTimeout(() => {
        this.updateTypeSingleData = false;
      }, 300);
    },
    showFilterMobile() {
      setTimeout(tabsWidthStart, 100);
    },
    filterParams: {
      handler(params) {
        this.updateTypeSingleData = true;
        setTimeout(() => {
          this.updateTypeSingleData = false;
        }, 300);
      },
      deep: true,
    },
  },
  computed: {
    isTouchDevice() {
      return window.matchMedia('(pointer:coarse)').matches || window.matchMedia('(max-width: 1279px)').matches;
    },
    perPage() {
      return this.viewType === 'flats' ? this.perPageFlats : this.perPageTypes;
    },
    total() {
      return this.viewType === 'flats' ? this.total_flats : this.total_types;
    },
    totalNum() {
      if (this.flatsTotalTypeSingle !== null) return this.flatsTotalTypeSingle;
      if (this.viewType === 'flats') return this.total_flats;
      return this.total_types;
    },
    totalStr() {
      if (this.flatsTotalTypeSingle !== null) return `${ this.flatsTotalTypeSingle } ${ declOfNum(this.flatsTotalTypeSingle, ['квартира', 'квартиры', 'квартир']) }`;
      if (this.viewType === 'flats') {
        return `${ this.total_flats } ${ declOfNum(this.total_flats, ['квартира', 'квартиры', 'квартир']) }`;
      }
      else {
        return `${ this.total_types } ${ declOfNum(this.total_types, ['тип', 'типа', 'типов']) }`;
      }
    },
    screenXS() {
      return window.matchMedia('(max-width: 767px)').matches;
    },
  },
  created() {
    this.pages = {page: 0, showPage: 0, count: 0, pages: 0};
    this.touchDevice = getTouchDevice();
    window.addEventListener('resize', this.checkScreenSize);
  },
  mounted() {
    this.restoreParams();
    this.fixedBtnFilterHandler();
    this.checkVisibleCallmeForm();
    this.checkScreenSize();

    this.favorites = window.getFavorites();
  },
  methods: {
    checkScreenSize() {
      this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
    },
    refill() {
      const self = this;
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              trigger: this.touchDevice ? 'click' : 'mouseenter',
              allowHTML: true,
              interactive: true,
              appendTo: () => document.body,
              offset: [0, 8],
              interactiveDebounce: 10,
              theme: this.touchDevice ? 'choose-filter-touch' : 'choose-filter',
              placement: 'top',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true,
              onShow(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').addEventListener('click', () => {
                  instance.hide();
                });
              },
              onHide(instance) {
                if (self.touchDevice) instance.popper.querySelector('.tippy-box').removeEventListener('click', () => {
                  instance.hide();
                });
              },
            });
          }
        });
      });
    },
    getData: _debounce(async function(filterParams, pageParam, addMore = false) {
      if (!addMore) {
        this.loadingTypes = false;
        this.loadingFlats = false;
      }

      if (!pageParam) {
        this.pages.page = 0;
        this.pages.showPage = 0;
        pageParam = {page: this.pages.page + 1};
      }

      this.filterParams = filterParams;
      const fullParams = Object.assign({}, this.filterParams, {sort: this.sortParam, view_type: this.viewType}, pageParam);
      //
      this.saveStateToURL(fullParams, {
        view: this.view,
        view_type: this.viewType,
        layout: this.openTypeDataId,
      });

      try {
        if (this.viewType === 'flats') {
          await this.getDataFlats(fullParams);
        }
        else {
          await this.getDataTypes(fullParams);
        }
        // this.loading = true;

      } catch (error) {
        console.error(error);
        // this.errored = true;
      } finally {
        // this.setLoading(false);
      }
    }, 500),
    async getDataFlats(params) {
      try {
        console.log('%csend data params flats', 'color: yellow', params);
        const response = await this.axios[this.apiMethod](this.apiUrlFlats, params);

        this.total_flats = response.data.total_flats;
        // console.log('response flats', response.data.flats)

        if (this.pages.showPage !== this.pages.page) this.flatsResult = [...this.flatsResult, ...response.data.flats];
        else this.flatsResult = response.data.flats;
        const countUtpCards = Math.ceil(this.total_flats / Number(this.perPage));

        response.data['not-standard-search'] ? this.notStandardSearch = true : this.notStandardSearch = false;

        this.pages = {
          page: this.pages.page,
          showPage: this.pages.showPage,
          count: Number(this.perPage),
          pages: Math.ceil((this.total + countUtpCards) / Number(this.perPage)),
          total: this.total,
        };

        await this.$nextTick(() => {
          this.pages = {
            page: this.pages.page,
            showPage: this.pages.showPage,
            count: Number(this.perPage),
            pages: Math.ceil((this.total + countUtpCards) / Number(this.perPage)),
            total: this.total,
          };
          this.calcRemainItems();
          this.loadingFlats = true;
        });

      } catch (error) {
        console.error(error);
      } finally {
        this.refill();
      }
    },
    async getDataTypes(params) {
      try {
        console.log('%csend data params types', 'color: yellow', params);
        const response = await this.axios[this.apiMethod](this.apiUrlTypes, params);

        this.total_types = response.data.total_types;
        // console.log('response types', response.data.types)

        if (this.pages.showPage !== this.pages.page) this.typesResult = [...this.typesResult, ...response.data.types];
        else this.typesResult = response.data.types;

        response.data['not-standard-search'] ? this.notStandardSearch = true : this.notStandardSearch = false;

        const countUtpCards = Math.ceil(this.total_types / Number(this.perPage));
        this.pages = {
          page: this.pages.page,
          showPage: this.pages.showPage,
          count: Number(this.perPage),
          pages: Math.ceil((this.total + countUtpCards) / Number(this.perPage)),
          total: this.total,
        };

        await this.$nextTick(() => {
          this.pages = {
            page: this.pages.page,
            showPage: this.pages.showPage,
            count: Number(this.perPage),
            pages: Math.ceil((this.total + countUtpCards) / Number(this.perPage)),
            total: this.total,
          };
          this.calcRemainItems();
          this.loadingTypes = true;
        });
      } catch (error) {
        console.error(error);
      }
    },
    setTags(tags) {
      this.tags = tags;
    },
    resetFilter(tag) {
      this.removeTag = tag;
    },
    openFilterMobile() {
      this.showFilterMobile = true;
    },
    changeView() {
      this.view === 'list' ? this.view = 'card' : this.view = 'list';
      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        view: this.view,
      });
    },
    changeSortLine(value, $event) {
      this.sortOrder === 'desc' ? this.sortOrder = 'asc' : this.sortOrder = 'desc';
      this.sortValue = value;
      this.sortParam = `${ this.sortValue }-${ this.sortOrder }`;
    },
    openTypeSingle(typeData) {
      this.openTypeDataId = typeData.id;
      if (this.isTouchDevice) {
        this.typePopupShow = true;
      }
      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        layout: typeData.id,
      });

    },
    buildParams(data) {
      // console.log('build params', data)
      const params = new URLSearchParams();

      Object.entries(data).forEach(([key, value]) => {
        if (value !== null) {
          if (Array.isArray(value)) {
            value.forEach(value => params.append(`${ key }[]`, value.toString()));
          }
          else {
            params.append(key, value.toString());
          }
        }

      });

      return params.toString();
    },
    saveStateToURL(params, extraParams = {}) {
      const queryStr = this.buildParams(Object.assign({}, params, extraParams));
      let newURL = location.pathname + '?' + queryStr;
      history.replaceState({}, document.title, newURL);
    },
    async setViewType(value) {
      this.viewType = value;
      this.openTypeDataId = null;
      this.flatsTotalTypeSingle = null;
      this.pages = {
        page: 0,
        showPage: 0,
        count: Number(this.perPage),
        pages: Math.ceil(this.total / Number(this.perPage)),
        total: this.total,
      };

      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        view: this.view,
        view_type: this.viewType,
      });

      await this.getData(this.filterParams);
      this.calcRemainItems();

    },
    closeTypeSingle() {
      this.openTypeDataId = null;
      this.flatsTotalTypeSingle = null;
      this.saveStateToURL(this.filterParams, {
        sort: this.sortParam,
        page: this.pages.page + 1,
        layout: null,
      });
    },
    restoreParams() {
      const url = new URL(window.location);
      const currentSort = url.searchParams.get('sort');
      const currentPage = url.searchParams.get('page');
      const currentView = url.searchParams.get('view');
      const currentType = url.searchParams.get('view_type');
      const currentTypeSingle = url.searchParams.get('layout');
      currentSort ? this.sortParam = currentSort : this.sortParam = 'price-asc';

      if (currentPage) {
        this.pages.page = parseInt(currentPage) - 1;
        this.pages.showPage = parseInt(currentPage) - 1;
        this.pages.count = parseInt(this.perPage);
      }

      if (currentView) {
        this.view = currentView;
      }

      if (currentType) {
        this.viewType = currentType;
      }

      if (currentTypeSingle) {
        this.openTypeDataId = currentTypeSingle;
        if (this.isTouchDevice) this.typePopupShow = true;

      }
    },
    async setPage(num) {
      this.$smoothScroll({
        scrollTo: document.querySelector(`[data-switch-content]`),
        updateHistory: false,
        duration: 500,
        offset: -220,
      });

      setTimeout(async () => {
        this.pages.count = parseInt(this.perPage);
        this.pages.page = num;
        this.pages.showPage = num;

        await this.getData(this.filterParams, {
          page: parseInt(num) + 1,
        });
      }, 500);
      this.calcRemainItems();
    },
    calcRemainItems() {
      if (this.viewType === 'flats') {
        const countUtpCards = Math.ceil(this.total_flats / Number(this.perPage));
        const total = parseInt(this.total_flats) + countUtpCards;
        this.remainFlatsItems = total - (this.perPage * (this.pages.showPage + 1));
      }
      else {
        const countUtpCards = Math.ceil(this.total_types / Number(this.perPage));
        const total = parseInt(this.total_types) + countUtpCards;
        this.remainTypesItems = total - (this.perPage * (this.pages.showPage + 1));
      }
    },
    async addMore() {
      this.pages.count += parseInt(this.perPage);
      this.pages.showPage += 1;

      await this.getData(this.filterParams, {
        page: this.pages.showPage + 1,
      }, true);
      this.calcRemainItems();
    },
    resetFilters() {
      this.resetAllFilters = true;
      setTimeout(() => {
        this.resetAllFilters = false;
      }, 100);
    },
    getTotalFlatsTypeSingle(value) {
      this.flatsTotalTypeSingle = value;
    },
    fixedBtnFilterHandler() {
      const options = {
        // rootMargin: '0',
        threshold: 0.1,
      };
      const callback = (entries, observer) => {
        this.filterIsVisible = entries[0].isIntersecting === true;
        this.showFilterFixedBtn = !((this.filterIsVisible && !this.callMeFormIsVisible) || (!this.filterIsVisible && this.callMeFormIsVisible) || (this.filterIsVisible && this.callMeFormIsVisible));
      };
      const observer = new IntersectionObserver(callback, options);

      const target = this.isTouchDevice ? this.$refs.paramsTop : this.$refs.filterDesktop;
      observer.observe(target);
    },
    checkVisibleCallmeForm() {
      const targetCallmeForm = document.querySelector('.d-still-questions');
      const options = {
        // rootMargin: '0',
        threshold: 0.01,
      };
      const callback = (entries, observer) => {
        this.callMeFormIsVisible = entries[0].isIntersecting === true;
        this.showFilterFixedBtn = !((this.filterIsVisible && !this.callMeFormIsVisible) || (!this.filterIsVisible && this.callMeFormIsVisible) || (this.filterIsVisible && this.callMeFormIsVisible));
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(targetCallmeForm);
    },
    fixedFilterBtnHandler() {
      if (this.isTouchDevice) {
        this.showFilterMobile = true;
      }
      else {
        this.$smoothScroll({
          scrollTo: this.$refs.filterDesktop,
          updateHistory: false,
          offset: -128,
        });
      }
    },
    openUtp(text) {
      this.utpText = text;
      if (text) this.utpShow = true;
    },
    tagDisableControl(tag) {
      return tag.filter === 'projects' || tag.filter === 'quarters' || tag.filter === 'buildings' || tag.filter === 'rooms';
    },
    async setFavorite(data) {
      if (!window.inFavorites(data.id)) {
        await window.addToFavorites(data.id);
        if (window.inFavorites(data.id)) this.$notify({
          text: data.title,
          data: {
            message: 'Добавлено в Избранное',
            price: data.price,
            title: data.title,
          },
        });
        else this.$notify({
          text: data.title,
          type: 'warn',
          data: {
            message: 'Не удалось добавить в избранное',
            title: data.title,
          },
        });
      }
      else {
        await window.removeFromFavorites(data.id);
      }
      this.favorites = window.getFavorites();
      this.refill();
    },
    setSearchType(value) {
      this.notStandardSearchTypeFlats = value;
      console.log('setSearchType() this.notStandardSearchTypeFlats = ', this.notStandardSearchTypeFlats);
    },
  },
};
</script>
