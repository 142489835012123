<template>
  <template v-if="time && time > 0 && showTimer">
    <vue-countdown :time="time" tag="div" :transform="transformSlotProps" v-slot="{ days, hours, minutes }" class="d-countdown__time" @end="showTimer=false">
      <div class="d-countdown__item">
        <div class="d-countdown__label">дней</div>
        <div class="d-countdown__digit">{{ days }}</div>
      </div>
      <div class="d-countdown__item">
        <div class="d-countdown__label">часов</div>
        <div class="d-countdown__digit">{{ hours }}</div>
      </div>
      <div class="d-countdown__item">
        <div class="d-countdown__label">минут</div>
        <div class="d-countdown__digit">{{ minutes }}</div>
      </div>
    </vue-countdown>
    <div class="d-countdown__title" v-html="title"></div>
  </template>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'DCountdown',
  components: {VueCountdown},
  data() {
    const cEndDate = this.endDate ? parseInt(this.endDate) * 1000 : 0;
    const cCurrentDate = new Date();
    return {
      showTimer: (cEndDate - cCurrentDate) > 0,
      time: cEndDate - cCurrentDate,
    };
  },
  props: {
    endDate: [String],
    title: {
      type: String,
      default: "до окончания акции"
    },
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${ value }` : String(value);
      });

      return formattedProps;
    },
  },
};
</script>
