/* Social Share */
import ClipboardJS from 'clipboard';

class socialLink {
    constructor(data) {
        this.data = data;
        this._el = null;
        this.init();
    }

    init() {
        if (this.data.link) {
            this._el = document.createElement('a');
            this._el.setAttribute('href', this.data.link);
            this._el.setAttribute('target', '_blank');
            if (this.data.class) this._el.classList.add(this.data.class);
            if (this.data.svg) this._el.innerHTML = this.data.svg;
        }
        else if (this.data.is_share) {
            this._el = document.createElement('button');
            this._el.classList.add('d-social__share');
            this._el.setAttribute('data-social-share-list', '');

            this._el.innerHTML = `<svg><use xlink:href="/assets/blueant/assets/sprite.svg#sprite-share"></use></svg>`;
        }
        else {
            this._el = document.createElement('div');
            if (this.data.class) this._el.classList.add(this.data.class);
        }
        this._el.classList.add('d-social__item');
    }

    get element() {
        return this._el;
    }

}

function init_social_icons() {
    const $sharePlaces = $('body').find('[data-social-share-icons]');
    if (!$sharePlaces.length || !shareIconsArray) return;
    $sharePlaces.each(function(id, el) {
        $(el).html('');
        shareIconsArray.forEach(socialElData => {
            const link = new socialLink(socialElData);
            el.appendChild(link.element);
        });
    });

}

update_social_share = window.update_social_share = function(el = null) {
    //if(el) console.log('%cwindow.update_social_share el = ', 'color: lime', el, el.dataset.socialShareList);
    //else console.log('%cwindow.update_social_share', 'color: lime');
    const $headImage = $('head').find('meta[property="og:image"]').first();
    const default_description = '«Донстрой» – крупнейший девелопер Москвы';
    let share_data = {
        image: $headImage.length
               ? $headImage.attr('content')
               : '/public/images/share_image.png',
        title: document.title,
        description: document.description || default_description,
        url: window.location.href,
        copyBtn: null,
    };

    const buttons = el ? $(el) : $('body').find('[data-social-share-list]');

    buttons.each(function() {
        const $this = $(this);
        const classNameMod = $this.data('social-share-mod');
        let currentShareData;
        try {currentShareData = this.dataset.socialShareList ? JSON.parse(this.dataset.socialShareList) : false;} catch (e) {}
        const buttonShareData = (currentShareData && typeof currentShareData === 'object')
                                ? Object.assign({}, share_data, currentShareData)
                                : Object.assign({}, share_data);

        if (window.socialShare && !$this.data('socialShare')) {
            //console.log('%cwindow.update_social_share share_data.url', 'color: lime', buttonShareData.url, this);
            $this.data('socialShare', window.socialShare($this, {
                ...buttonShareData,
                services: shareServices ?? 'vk,tg',
                prefix: 'd-share',
                className: 'd-share',
                classNameMod: classNameMod ? `_${ classNameMod }` : '',
                buttonClass: '_item',
            })[0].share);
        }
        else if ($this.data('socialShare').data.url !== buttonShareData.url) {
            //console.log('%cwindow.update_social_share data.url => share_data.url', 'color: lime', $this.data('socialShare').data.url, buttonShareData.url,
            // this);
            $this.data('socialShare').updateData(buttonShareData);
        }
    });
    const clipboard = new ClipboardJS('[data-share-copy-btn]');
    clipboard.on('success', function(e) {
        e.trigger.classList.add('_active');
        window.setTimeout(() => {
            e.trigger.classList.remove('_active');
        }, 3000);

        e.clearSelection();
    });

    clipboard.on('error', function(e) {
        console.error('%cclipboard error Action:', 'color: yellow', e.action);
        console.error('%cclipboard error Trigger:', 'color: yellow', e.trigger);
    });
};

function initSocialShare () {
    (function() {

        var utils = {
            capitalize: function capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            extend: function(o) {
                for (var i = 1, j = arguments.length; i < j; i++) {
                    var p = arguments[i];
                    if (typeof (p) != 'object') continue;
                    for (var key in p) {
                        if (p[key] !== undefined) o[key] = p[key];
                    }
                }
                return o;
            },
        };

        if (window.jQuery) jQuery.fn.socialShare = function(options) {
            return this.each(function() { this.share = new Share(this, options); });
        };
        else if (window.$) $.fn.socialShare = function(options) {
            return this.each(function() { this.share = new Share(this, options); });
        };

        window.socialShare = function(elements, options) {
            options = options || {};
            if (typeof elements !== 'object') return elements;
            if (elements.length !== undefined) {
                for (var i = 0, j = elements.length; i < j; i++) {
                    elements[i].share = new Share(elements[i], options);
                }
            }
            else elements.share = new Share(elements, options);
            return elements;
        };

        function Share(element, options) {
            this.selector = element;
            this.settings = utils.extend({}, this.defaults, this.getAttrSettings(), options);
            if (this.checkSettings()) {
                this.data = this.getData();

                if (this.data) this.buildHTML();
                else this.log('Not enough data for share. Merged data: ' + JSON.stringify(this.data));
            }
            else this.log('Errors in settings: ' + JSON.stringify(this.settings));
        }

        Share.prototype = {
            defaults: {prefix: 'share', className: '-buttons-list', buttonClass: '-button', services: '', tracking: false},
            fields: ['title', 'description', 'url', 'image', 'copyBtn'],
            services: {
                vk: 'http://vk.com/share.php?url={url}&title={title}&description={description}&image={image}&noparse=true',
                facebook: 'http://www.facebook.com/sharer/sharer.php?u={url}',
                fb: 'http://www.facebook.com/sharer/sharer.php?u={url}',
                mailru: 'http://connect.mail.ru/share?url={url}&title={title}&description={description}&imageurl={image}',
                odnoklassniki: 'https://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl={url}&st.comments={title}',
                ok: 'https://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl={url}&st.comments={title}',
                livejournal: 'http://www.livejournal.com/update.bml?subject={title}&event={url}&prop_taglist=',
                lj: 'http://www.livejournal.com/update.bml?subject={title}&event={url}&prop_taglist=',
                twitter: 'http://twitter.com/share?text={title}&url={url}&counturl={url}&picture={image}',
                tg: `https://t.me/share/url?url={url}&text=${ encodeURIComponent('\n') }{title}`,
                telegram: 'https://t.me/share/url?url={url}&text={title}',
                wa: 'https://api.whatsapp.com/send?text={url}',
            },

            log: function() {
                if (!window.console) return false;
                for (var i = 0, j = arguments.length; i < j; i++)
                    console.error('%cShare Plugin: ' + arguments[i].toString(), 'color: yellow');
                return this;
            },

            buildHTML: function() {
                const wrapper = document.createElement('ul');
                //this.selector.innerHTML = '';
                for (var i = 0, j = this.settings.services.length; i < j; i++) {
                    var service = this.settings.services[i];
                    if (this.services[service]) {
                        var button = document.createElement('li');
                        button.className = this.settings.prefix + '_' + this.settings.buttonClass + ' share-' + service;
                        button.setAttribute('title', service);
                        button.setAttribute('data-' + this.settings.prefix + '-service', service);
                        button.innerHTML = `<svg><use xlink:href="/assets/blueant/assets/sprite.svg#sprite-icon-${ service }"></use></svg>`;
                        (function(share) {
                            button.onclick = function() {
                                share.clickButton(this.getAttribute('data-' + share.settings.prefix + '-service'));
                            };
                        })(this);
                        wrapper.appendChild(button);
                    }
                    else this.log('Service ' + service + ' not found in plugin');
                }
                wrapper.className = this.settings.className;
                if (ClipboardJS.isSupported()) {
                    this.data.copyBtn = this.copyButton();
                    wrapper.appendChild(this.data.copyBtn);
                }
                this.selector.appendChild(wrapper);
            },

            copyButton: function() {
                const copyBtn = document.createElement('button');
                copyBtn.setAttribute('data-share-copy-btn', '');
                copyBtn.setAttribute('data-clipboard-text', this.data.url);
                copyBtn.classList.add(this.settings.prefix + '_' + this.settings.buttonClass);
                copyBtn.classList.add('_link');

                copyBtn.innerHTML = `<svg><use xlink:href="/assets/blueant/assets/sprite.svg#sprite-link-copy"></use></svg>`;

                const copyBtnResult = document.createElement('div');
                copyBtnResult.className = this.settings.prefix + '__tooltip';
                if (this.settings.classNameMod) copyBtnResult.classList.add(this.settings.classNameMod);
                copyBtnResult.innerHTML = window.dStrings && window.dStrings.linkCopied ? window.dStrings.linkCopied : 'ссылка скопирована';

                copyBtn.append(copyBtnResult);

                return copyBtn;
            },

            checkData: function(data) {
                data = data || this.data;
                if (data.title && data.title.length > 100) data.title = data.title.slice(0, 97) + '...';
                if (data.description && data.description.length > 150) data.description = data.description.slice(0,
                    147) + '...';
                return (data.title && data.description && data.url);
            },

            checkSettings: function(s) {
                s = s || this.settings;
                if (!s.services || (!s.prefix && !s.className)) return false;
                if (typeof (s.services) == 'string') s.services = s.services.split(/\s*,\s*/);
                return ((s.services instanceof Array) && s.services.length);
            },

            getAttrSettings: function() {
                var settings = {};
                for (var key in this.defaults) {
                    var attr = this.selector.getAttribute('data-share-' + key);
                    if (attr) settings[key] = attr;
                }
                return settings;
            },

            getData: function() {
                var data = utils.extend({},
                    this.getDefaultData(),
                    this.getOpenGraphData(),
                    this.getAttrData(),
                    this.getSettingsData());
                if (this.checkData(data)) return data;
                else return false;
            },

            getAttrData: function() {
                var data = {};
                for (var i = 0, j = this.fields.length; i < j; i++) {
                    var field = this.fields[i],
                        value = this.selector.getAttribute('data-share' + utils.capitalize(field));
                    if (value) data[field] = value;
                }
                return data;
            },

            getDefaultData: function() {
                var title = document.querySelector('head title');
                return {
                    title: title.textContent || title.innerText,
                    description: document.querySelector('meta[name="description"]').getAttribute('content'),
                    url: window.location.href,
                    image: '',
                    copyBtn: null,
                };
            },

            getOpenGraphData: function() {
                var data = {},
                    og_tags = document.querySelectorAll('meta[property^="og:"]');
                for (var i = 0, j = og_tags.length; i < j; i++) {
                    var tag = og_tags[i],
                        field = tag.getAttribute('property').split(':')[1],
                        value = tag.getAttribute('content');
                    if (value) data[field] = value;
                }
                return data;
            },

            getSettingsData: function() {
                var data = {};
                for (var i = 0, j = this.fields.length; i < j; i++) {
                    var field = this.fields[i],
                        value = this.settings[field];
                    if (value) data[field] = value;
                }
                return data;
            },

            updateData: function(options) {
                if (!options || (typeof (options)).toLowerCase() !== 'object') return false;
                var cached_url = this.data.url;
                const copyBtn = this.data.copyBtn;
                utils.extend(this.settings, options);
                this.data = Object.assign({}, this.getData(), {copyBtn});
                if (cached_url === this.data.url) this.log(
                    'Data wont update without url change. Some social networks use url to cache data.');
                if (this.data.copyBtn) this.data.copyBtn.setAttribute('data-clipboard-text', this.data.url);
                return this;
            },

            renderUrl: function(url, data) {
                url = url.toString();
                for (var attr in data) {
                    if (data[attr] == null || data[attr] === undefined) data[attr] = '';
                    url = url.split('{' + attr + '}').join(encodeURIComponent(data[attr]));
                }

                return url;
            },

            clickButton: function(service) {
                var url = this.services[service];
                if (!url) {
                    this.log('Service ' + service + ' not found in plugin');
                    return share;
                }
                if (!this.settings.tracking) this.data = this.getData();
                if (this.data) {
                    url = this.renderUrl(url, this.data);
                    if (this.settings.clickHandler && typeof (this.settings.clickHandler) == 'function')
                        this.settings.clickHandler(this.data, service);
                    window.open(url, '_blank');
                }
                return this;
            },

        };
    })();

    init_social_icons();
    update_social_share();
}

export default initSocialShare

