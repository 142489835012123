<template>
  <div class="d-visual-header">
    <div class="d-visual-header__row" :class="{ _chess: chessLegend }">
      <h1 class="d-visual-header__title d-page-title">{{ currentType === '2d' && chessLegend ? 'Выберите квартиру' : data.title }}</h1>
      <div v-if="chessLegend && currentType === '2d'" class="d-visual-header__legend chess-legend _desktop">
        <div v-for="item in chessLegend" :key="item.id" class="chess-legend__item" :class="modChessLegendItem(item.type)">
          {{ item.name }}
        </div>
      </div>
      <div v-if="data.types && data.types.length" class="d-visual-header__types" :class="{ _chess: chessLegend && chessLegend.length }">
        <d-switcher v-if="switcherValues" v-model:value="currentType" :disabled="disabledTypes" :values="data.types"/>
      </div>
      <a class="d-visual-header__choose d-button _blue" :class="{_single: !data.types || !data.types.length}" :href="data.searchFilterLink.url"><span>{{ data.searchFilterLink.name }}</span></a>
      <div class="d-visual-breadcrumbs d-visual-header__breadcrumbs _tablet">
        <template v-for="item in data.breadcrumbs" :key="item.id">
          <a v-if="item.url" :href="item.url" class="d-visual-breadcrumbs__item">{{ item.name }}</a>
          <div v-else class="d-visual-breadcrumbs__item">{{ item.name }}</div>
        </template>
      </div>
    </div>
    <div class="d-visual-breadcrumbs d-visual-header__breadcrumbs _desktop">
      <template v-for="item in data.breadcrumbs" :key="item.id">
        <a v-if="item.url" :href="item.url" class="d-visual-breadcrumbs__item">{{ item.name }}</a>
        <div v-else class="d-visual-breadcrumbs__item">{{ item.name }}</div>
      </template>
    </div>
    <div v-if="chessLegend && currentType === '2d'" class="d-visual-header__legend chess-legend _desktop-small">
      <div v-for="item in chessLegend" :key="item.id" class="chess-legend__item" :class="modChessLegendItem(item.type)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import DSwitcher from "@components/atoms/DSwitcher.vue";
export default {
  name: "DVisualHeader",
  components: {
    DSwitcher
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    chessLegend: {
      type: [Array, Boolean],
      default: () => [],
    },
  },
  data() {
    return {
      "switcherValues": [
        {
          "id": 0,
          "name": "3D схема",
          "value": '3d',
          "init": true
        },
        {
          "id": 1,
          "name": "2D схема",
          "value": '2d',
          "init": false
        }
      ],
      currentType: null
    }
  },
  computed: {
    disabledTypes() {
      if (this.data.types) {
        return this.data.types.reduce(
            (previousValue, currentValue) => previousValue.disabled || currentValue.disabled,
        );
      } else {
        return null
      }

    }
  },
  watch: {
    currentType(value) {
      this.$emit('changeType', value)
      console.log('change type', value)
      this.saveStateToURL();
      this.sendType(value)
    },
  },
  beforeMount() {
    if (this.data.types && this.data.types.length) {
      this.setInitType();
    } else {
      this.currentType = '3d' // костыль, который принудительно ставит 3д, если массив типов с бека не приходит
    }

  },
  methods: {
    setInitType() {
      const url = new URL(location.href);
      const typeParam = url.searchParams.get('type')
      if (typeParam) {
        this.currentType = typeParam;
      } else {
        const currentType = this.data.types.find((item) => item.init)
        this.currentType = currentType.value;
      }

      this.saveStateToURL()
    },
    modChessLegendItem(type) {
      const res = {}
      res[`_${type}`] = true
      return res
    },
    saveStateToURL() {
      const url = new URL(location.href);
      url.searchParams.set('type', this.currentType);
      history.replaceState(null, '', url);
    },
    sendType(value) {
      if (this.data.typeApi) {
        const url = window.location.href;
        this.axios.post(this.data.typeApi, { type: value, url: url });
      }
    }
  }
}
</script>

<style scoped>

</style>
