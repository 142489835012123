<template>
  <div class="d-object-single-location-map__zoom d-zoom-map">
    <button class="d-zoom-map__button _plus" @click="zoomMap(1)">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-plus"></use>
      </svg>
    </button>
    <button class="d-zoom-map__button _minus" @click="zoomMap(-1)">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-minus"></use>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "DMapZoomButtons",
  methods: {
    zoomMap(value) {
      this.$emit('changeZoom', value)
    }

  }
}
</script>

<style scoped>

</style>