<template>
  <div class="d-object-single-progress-slider">
    <div class="d-object-single-progress-slider__arrows">
      <div class="d-arrows-slider">
        <button ref="prev" class="d-arrows-slider__arrow d-button _slider _prev progressSlider _dark">
          <span></span>
          <svg  width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
          </svg>
        </button>

        <button ref="next" class="d-arrows-slider__arrow d-button _slider _next progressSlider _dark">
          <span></span>
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
          </svg>
        </button>
      </div>
    </div>
    <swiper
        ref="slider"
        v-bind="swiperOpts"
        :navigation="{
              prevEl: prev,
              nextEl: next,
            }"

    >

      <swiper-slide v-for="album in data" :key="album.id" class="d-object-single-progress-slider__slide swiper-slide" :class="{ _full: data.length === 1}">
        <img class="d-object-single-progress-slider__img" :src="album.images[0]" alt="">
        <div class="d-object-single-progress-slider__text">
          {{ album.name }}
          <small>{{ album.month }} {{ album.year }}</small>
        </div>
        <div class="d-object-single-progress-slider__count">{{ album.images.length }} фото</div>
        <a href="#" class="d-object-single-progress-slider__link" @click.prevent="openPopup(album)"></a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/vue';
import { Navigation } from 'swiper';
export default {
  name: "DProgressSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
    };
  },
  data() {
    return {

    }
  },
  computed: {
    swiperOpts() {
      return {
        // loop: true,
        // loopAdditionalSlides: 4,
        // slidesPerView: this.data.length === 1 ? 1 : 3,
        a11y: false,
        spaceBetween: 32,
        breakpoints: {
          320: {
            slidesPerView: this.data.length === 1 ? 1 : 'auto',
            spaceBetween: 12
          },
          768: {
            slidesPerView: this.data.length === 1 ? 1 : 'auto',
            spaceBetween: 32
          },
          1280: {
            slidesPerView: this.data.length === 1 ? 1 : 2,
          },
          1600: {
            slidesPerView: this.data.length > 2 ? 3 : this.data.length,
          }
        }

      }
    },
    length() {
      return this.data.length
    }
  },
  methods: {
    openPopup(album) {
      this.$emit('openPopup', album)
    }
  },
}
</script>

<style scoped>

</style>
