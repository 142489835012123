<template>
  <div class="d-flat-card _similar" :class="classList">
    <div class="d-flat-card__top">
      <a class="d-flat-card__title" :href="data.link">
        <template v-if="data.isPenthouse">
          Пентхаус № {{ data.number }}
        </template>
        <template v-else-if="data.rooms === 0">
          Cтудия № {{ data.number }}
        </template>
        <template v-else>
          {{ data.rooms }}-<span>комнатная</span>
          <span class="mobile">комн.</span> квартира {{ data.number }}
        </template>
        <span v-if="'is_new' in data && data.is_new" class="d-flat-card__sticker _new" :data-v-tippy="data.is_new_tip">new</span>
      </a>
      <div class="d-flat-card__position">
        <div class="d-flat-card__object">{{ data.project }}</div>
        <template v-if="data.quarter">{{ data.quarter }},</template>
        Корпус {{ data.building }},
        секция {{ data.section }},
        этаж {{ data.floor }}
      </div>
      <div class="d-flat-card__favorites">
        <button class="d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-heart"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="d-flat-card__content">
      <div class="d-flat-card__img">
        <img v-if="'promotion' in data && data['promotion'] && data['promotion'].bage" :src="data['promotion'].bage" class="d-flat-card__img_black-friday"/>
        <img :src="data.plan" loading="lazy">
      </div>
      <div class="d-flat-card__actions">
        <template v-if="'labels' in data && data.labels">
          <div v-for="label in data.labels" :key="label.title" class="d-flat-action" :style="`background-color:${label.background_color}; color: ${label.color}`" :data-v-tippy="label.tooltip">
            <div class="d-flat-action__name">{{ label.title }}</div>
          </div>
        </template>
        <div v-if="'promotion' in data && data['promotion'] && data['promotion'].label"
             class="d-flat-action _black-friday"
             :style="{color: data['promotion'].color, backgroundColor: data['promotion'].background}"
             :data-v-tippy="data['promotion'].text">
          <div class="d-flat-action__name">{{ data['promotion'].label }}</div>
        </div>
      </div>
      <div class="d-flat-card__info">
        <div class="d-flat-card__square">{{ data.area }} м²</div>
        <div v-if="data.is_sold" class="d-flat-card__price" v-html="'Продано'" />
        <div v-else-if="data.price_request && data.price_request_label" class="d-flat-card__price _request" v-html="data.price_request_label" />
        <div v-else class="d-flat-card__price">
          <div v-if="data.price_old" class="d-flat-card__priceOld">
            {{ data.price_old.toLocaleString('ru-RU') }} ₽
          </div>
          {{ data.price.toLocaleString('ru-RU') }} ₽
        </div>
      </div>
      <div class="d-flat-card__buttons">
        <button v-if="data.can_booking" class="d-button _dark" @click.prevent="bookOnline"><span>Забронировать онлайн</span></button>
        <button v-else class="d-button _blue" data-callMe-show :data-props="flatLeadData"><span>Оставить заявку</span></button>
      </div>
    </div>
    <button v-if="!isFavorite" class="d-favorites-return" @click="setFavorite">
      <span>Вы удалили эту квартиру из избранного</span>
      <span class="d-favorites-return__button">Вернуть</span>
    </button>
    <a class="d-flat-card__link" :href="data.link" target="_blank"></a>
  </div>
</template>

<script>
import additionalClassCSS from '@utils/additionalClassCSS';
import Cookies from 'js-cookie';

export default {
  name: 'DFavoritesFlatCard',
  emits: ['set-favorite'],
  props: {
    data: Object,
    mod: {
      type: [Array, String],
      default: '',
    },
    favorites: {
      type: Array,
      default: null,
    },
    object: Number,
  },
  data() {
    return {
      flatLeadData: '',
    }
  },
  computed: {
    flatTitle() {
      if (this.data.isPenthouse) {
        return `Пентхаус № ${ this.data.number }`;
      }
      if (this.data.rooms === 0) {
        return `Студия № ${ this.data.number }`;
      }
      return `${ this.data.rooms }-комнатная квартира № ${ this.data.number }`;
    },
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.data.id);
      else return false;
    },
  },
  mounted() {
    const flatLeadData = {
      'area': this.data.area,
      'comment': '',
      'email': '',
      'flat': this.data.id,
      'floor': this.data.floor,
      'formType': 'reserve',
      'housing': this.data.building,
      'price': this.data.price,
      'section': this.data.section,
      'sourceConversion': '',
      'typeFlat': this.data.rooms,
      'URL': window.location.href || '',
      'utm': Cookies.get('utm_data') || window.utm_str_src || '',
    };
    this.flatLeadData = JSON.stringify(flatLeadData);
  },
  methods: {
    bookOnline(e) {
      try {
        dataLayerPush('event_to_GA', 'button_click', 'buy_book_online');
      } catch (e) {
        console.error('%cbookOnline() error to call dataLayerPush', 'color: red', e);
      }
      window.location = (this.data.booking_url ? this.data.booking_url : `/lk/forms/?object_id=${this.data.id}`);
    },
    setFavorite() {
      const price = this.data.price_request && this.data.price_request_label ? this.data.price_request_label : `${ this.data.price.toLocaleString('ru-RU') } ₽`;
      this.$emit('set-favorite', {object: this.object, id: this.data.id, title: this.flatTitle, price, is_sold: this.data.is_sold});
    },
  },
};
</script>
