<template>
  <div v-if="initial">
    <div class="d-choose-filter _skeleton">
      <div class="d-choose-filter__row _row1">
        <div class="d-choose-filter__item skeleton-field"></div>
        <div class="d-choose-filter__item skeleton-field"></div>
        <div class="d-choose-filter__item skeleton-field"></div>
        <div class="d-choose-filter__item skeleton-field"></div>
      </div>
      <div class="d-choose-filter__row _row2">
        <div class="d-choose-filter__actions">
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
        </div>
        <div class="d-choose-filter__clear skeleton-field"></div>
      </div>
    </div>
  </div>
  <div v-else class="d-choose-filter" :class="{ _mobile: mod === 'mobile'}">
    <div class="d-choose-filter__title">
      Фильтр
      <a href="#" v-if="tagsItems.length" class="d-choose-filter__reset-mobile" @click.prevent="resetFilters">Сбросить</a>
    </div>
    <div class="d-choose-filter__row _row1">
      <div class="d-choose-filter__item _projects" :class="{ _disabled: typeSingleIsOpen}">
        <d-filter-item data-title="Объект" class=" _location" :class="{ _tags: tagsObjectTouch.length}" :is-active="filterParams.projects.length > 0" v-model:alwaysOpen="projectsIsOpen" mod="choose" :screen-xs="isTouchDevice">
          <d-filter-projects
              v-model:projects="filterParams.projects"
              v-model:quarters="filterParams.quarters"
              v-model:buildings="filterParams.buildings"
              :is-open="projectsIsOpen"
              :is-mobile="mod === 'mobile'"
              :saved="savedProjectsMobile"
              :reset="resetAllFilters"
              v-if="filters.projects.length"
              :data="filters.projects"
          />

        </d-filter-item>
        <div v-if="isTouchDevice" class="d-choose-params__tags _projects" :class="{ _show: !projectsIsOpen && tagsObjectTouch.length, _open: projectsIsOpen}">
          <template v-if="tagsObjectTouch.length && !projectsIsOpen" >
            <div v-for="(tag, index) in tagsObjectTouch" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
              <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
              <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
                <svg>
                  <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use>
                </svg>
              </button>
            </div>
          </template>

        </div>
      </div>
      <div v-if="!mobileOpen" class="d-choose-filter__item _rooms" :class="{ _disabled: typeSingleIsOpen}">
        <d-filter-item data-title="Количество комнат" :is-active="filterParams.rooms.length > 0" :alwaysOpen="mobileOpen" mod="commercial">
          <d-filter-checkboxes v-model="filterParams.rooms" v-if="filters.rooms.length" :data="filters.rooms"/>
        </d-filter-item>
      </div>
      <div v-show="!projectsIsOpen || !mobileOpen" class="d-choose-filter__item">
        <d-filter-item data-title="Параметры квартиры" :is-active="paramsFilterActive" :alwaysOpen="mobileOpen" mod="" :screen-xs="screenXS">
          <div class="d-tabs _filter">
            <div class="d-tabs__buttons">
              <div class="d-tabs__buttonsInner">
                <button ref="category_null" class="d-tabs__button" :class="{ _active: !filterParams.category}" @click="chooseCategory()">Все</button>
                <button v-for="flatType in filters.category" :ref="`category_${flatType.pk}`" :key="flatType.pk" class="d-tabs__button" :class="{ _active: filterParams.category === flatType.pk}" @click="chooseCategory(flatType.pk)">
                  {{ flatType.filter_title }}
                </button>
                <div ref="category_buttonBg" class="d-tabs__buttonBg"></div>
              </div>
            </div>
            <div class="d-choose-filter__rooms" :class="{ _disabled: typeSingleIsOpen}">
              <div class="d-choose-filter-params__title _params">Количество комнат</div>
              <div class="d-choose-filter__checkboxes _rooms">
                <div v-for="rooms in filters.rooms" :key="rooms.pk" class="d-checkbox _full" :class="{ _disabled: rooms.disabled}">
                    <input class="d-checkbox__input"
                           type="checkbox"
                           name="room"
                           :id="`${mod}-room-${rooms.pk}`"
                           v-model="filterParams.rooms"
                           :value="rooms.pk"
                    >
                    <label class="d-checkbox__label" :for="`${mod}-room-${rooms.pk}`">{{ rooms.pk === 0 ? 'Ст' : rooms.pk }}</label>
                </div>
              </div>
            </div>
            <div class="d-tabs__content _active _single">
              <div class="d-choose-filter-params _params">
                <div class="d-choose-filter-params__row">
                  <div class="d-range-slider _pb">
                    <DInputSliderRange :init-values="[facets.area.min, facets.area.max]"
                                       title="Площадь"
                                       unit="м²"
                                       :step="0.1"
                                       :from="filters.area.min"
                                       :to="filters.area.max"
                                       type="number"
                                       v-model="filterParams.area"/>
                  </div>
                </div>


                <div class="d-choose-filter-params__row">
                  <div class="d-range-slider _moreParams">
                    <div class="d-range-slider _pb">
                      <DInputSliderRange :init-values="[facets.floor_number.min, facets.floor_number.max]"
                                         title="Этаж"
                                         unit=""
                                         :step="1"
                                         :from="filters.floor_number.min"
                                         :to="filters.floor_number.max"
                                         type="number"
                                         v-model="filterParams.floor_number"/>
                    </div>
                  </div>
                  <div class="d-checkbox">
                    <input class="d-checkbox__input" type="checkbox" name="notFL" id="floor_first_last" v-model="filterParams.floor_first_last">
                    <label class="d-checkbox__label" for="floor_first_last">Не первый и не последний</label>
                  </div>
                </div>
                <div class="d-choose-filter-params__row _price">
                  <div class="d-choose-filter-params__title">Стоимость</div>
                  <DInputSliderRange v-if="filters.price" mod="commercial" :init-values="[facets.price.min, facets.price.max]" unit="млн ₽" :step="0.01" :from="filters.price.min" :to="filters.price.max"
                                     type="price" v-model="filterParams.price"/>
                </div>
                <div class="d-choose-filter-params__row _advantages _other">
                  <div class="d-choose-filter-params__title _other">Дополнительные параметры</div>
                  <div class="d-choose-filter__actions _mobile">
                    <d-checkbox :mod="['full','icon']" :id="`furnish-${mod}`" v-model="filterParams.furnish" :name="`furnish-${mod}`" icon="brush" class="d-choose-filter__action">Отделка</d-checkbox>
                    <d-checkbox :mod="['full','icon']" :id="`action-${mod}`" v-model="filterParams.discount" :name="`action-${mod}`" icon="fire" class="d-choose-filter__action _action">Акция</d-checkbox>
                    <d-checkbox :mod="['full','icon']" :id="`apartments-${mod}`" v-model="filterParams.apartments" :name="`apartments-${mod}`" icon="building" class="d-choose-filter__action _apartments">Апартаменты</d-checkbox>
                  </div>
                  <div class="d-index-filter-params__checkboxes">
                    <div v-for="advantage in filters.advantages" :key="advantage.pk" class="d-checkbox _full" :class="{_disabled: !facets.advantages.includes(advantage.pk)}">
                      <input class="d-checkbox__input"
                             type="checkbox"
                             name="advantage"
                             :id="`advantage-${advantage.pk}-${mod}`"
                             v-model="filterParams.advantages"
                             :value="advantage.pk"
                             :disabled="!facets.advantages.includes(advantage.pk)">
                      <label class="d-checkbox__label" :for="`advantage-${advantage.pk}-${mod}`">{{ advantage.filter_title }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </d-filter-item>
      </div>
      <div class="d-choose-filter__item _price">
        <DInputSliderRange v-if="filters.price" mod="commercial" :init-values="[facets.price.min, facets.price.max]" unit="млн ₽" :step="0.01" :from="filters.price.min" :to="filters.price.max"
                           type="price" v-model="filterParams.price"/>
      </div>
    </div>
    <div class="d-choose-filter__row _row2">
      <div class="d-choose-filter__actions _desktop">
        <d-checkbox :mod="['full','icon']" :id="`furnish-${mod}`" v-model="filterParams.furnish" :name="`furnish-${mod}`" icon="brush" class="d-choose-filter__action">Отделка</d-checkbox>
        <d-checkbox :mod="['full','icon']" :id="`action-${mod}`" v-model="filterParams.discount" :name="`action-${mod}`" icon="fire" class="d-choose-filter__action _action">Акция</d-checkbox>
        <d-checkbox :mod="['full','icon']" :id="`apartments-${mod}`" v-model="filterParams.apartments" :name="`apartments-${mod}`" icon="building" class="d-choose-filter__action _apartments">Апартаменты</d-checkbox>
      </div>
      <button class="d-choose-filter__clear _desktop" :disabled="!tagsItems.length" @click="resetFilters">
        <svg>
          <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-refresh'></use>
        </svg>
        Очистить фильтры
      </button>
    </div>
    <div class="d-choose-filter__bottom">
      <template v-if="projectsIsOpen">
        <button class="d-choose-filter__view-result d-button _blue" @click="saveProjectsMobile">
          <span>Сохранить</span>
        </button>
      </template>
      <template v-else>
        <button class="d-choose-filter__clear _mobile" @click="resetFilters">
          <svg>
            <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-refresh'></use>
          </svg>
          <span>Сбросить фильтры</span>
        </button>
        <button class="d-choose-filter__view-result d-button _blue" @click="$emit('closed')">
          <span v-if="total">Найдено {{ totalFlatsStr }}</span>
          <span v-else>Ничего не найдено</span>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import DFilterItem from "@components/organisms/DFilterItem.vue";
import DFilterProjects from "@components/molecules/DFilterProjects.vue";
import DCheckbox from "@components/atoms/DCheckbox.vue";
import DInputSliderRange from "@components/atoms/DInputSliderRange.vue";
import DFilterCheckboxes from "@components/molecules/DFilterCheckboxes.vue";
import {toRaw} from "vue";
import getSearchUrl from "@utils/getSearchUrl";
import {isEqual as _isEqual, debounce as _debounce, cloneDeep as _cloneDeep} from 'lodash';
import declOfNum from "@utils/declOfNum";
export default {
  name: "DChooseParamsFilter",
  components: {
    DCheckbox,
    DFilterItem,
    DFilterProjects,
    DInputSliderRange,
    DFilterCheckboxes
  },
  props: {
    dataFilterSpecsApi: {
      type: String,
      default: '',
    },
    dataFilterParamsApi: {
      type: String,
      default: '',
    },
    dataApiMethod: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => []
    },
    tagsItems: {
      type: Array,
      default: () => []
    },
    removeTag: {
      type: Object,
      default: () => {}
    },
    mod: {
      type: String,
      default: 'desktop'
    },
    resetAllFilters: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0,
    },
    popupIsOpen: {
      type: Boolean,
      default: false
    },
    typeSingleIsOpen: {
      type: [String, Number],
      default: null
    },
    viewType: {
      type: String,
      default: 'flats'
    },
    notStandardSearch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filterParams: {
        projects: [],
        quarters: [],
        buildings: [],
        price: [1, 100],
        area: [1, 100],
        floor_number: [0, 300],
        rooms: [],
        category: null,
        floor_first_last: false,
        advantages: [],
        discount: false,
        furnish: false,
        apartments: false,
      },
      filters: {
        price: null,
        area: null,
        category: null,
        projects: [],
        quarters: [],
        buildings: [],
        rooms: [],
        floor_number: null,
        floor_first_last: false,
        advantages: [],
        discount: false,
        furnish: false,
        apartments: false,
      },
      initialFilterParams: {},
      facets: {},
      initial: true,
      mobileOpen: false,
      screenXS: false,
      projectsIsOpen: false,
      savedProjectsMobile: false
    }
  },
  computed: {
    paramsFilterActive() {
      return this.tagsItems.filter(el => (el.filter === 'params') && (el.category !== 'discount' && el.category !== 'furnish' && el.category !== 'apartments')).length > 0;
    },
    totalFlatsStr() {
      const labels = this.viewType === 'flats' ? ['квартира', 'квартиры', 'квартир'] : ['тип', 'типа', 'типов'];
      const labelsSimilar = this.viewType === 'flats' ? ['похожая', 'похожие', 'похожих'] : ['похожий', 'похожих', 'похожих']
      const isSimilarResult = this.notStandardSearch ? declOfNum(this.total, labelsSimilar) : ''
      return `${this.total} ${isSimilarResult} ${declOfNum(this.total,labels)}`
    },
    isTouchDevice() {
      return window.matchMedia('(max-width: 1279px)').matches && window.matchMedia("(pointer:coarse)").matches;
    },
    tagsObjectTouch() {
      if (this.tagsItems.length) {
        return this.tagsItems.filter((tag) => tag.filter === 'projects' || tag.filter === 'quarters' || tag.filter === 'buildings')
      } else {
        return []
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch: {
    filterParams: {
      handler(params) {
        if (!this.initial) {
          this.getSpecsDebounce();
          this.setTags(params);
          this.sendData();
        }
      },
      deep: true,
    },
    removeTag(tag) {
      this.resetFilter(tag)
    },
    resetAllFilters(value) {
      if (value) {
        this.resetFilters()
        console.log('reset filters', this.filterParams.projects)
      }
    },
    popupIsOpen(value) {
      if (!value) this.projectsIsOpen = false
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
    this.initFilters()

  },
  methods: {
    async initFilters() {
      await this.getSpecs();
      await this.initFilterParams();
      await this.getFacets();
      this.initial = false;
      await this.setTags(this.filterParams);
    },
    getSpecsDebounce: _debounce(async function() {
      await this.getSpecs();
    },300),
    async getSpecs() {
      try {
        let response = await this.axios[this.dataApiMethod](this.dataFilterSpecsApi, this.filterParams);
        const filters = response.data.specs;
        filters.projects = filters.projects.map(el => {
          el.type = 'projects';
          return el;
        });
        this.filters = filters;
        return this.filters;
      } catch (err) {
        console.error('%cDIndexFilter getSpecs() error load getSpecs result', 'color: yellow', err);
      }
    },
    async getFacets(withFilter = false) {
      // this.setLoading(true);
      try {
        const response = withFilter
            ? await this.axios.post(this.dataFilterParamsApi, this.filterParams)
            : await this.axios.get(this.dataFilterParamsApi);

        this.facets = response.data.facets;

        // const newPricesRange = [this.facets.price.min, this.facets.price.max];
        // const newAreaRange = [this.facets.area.min, this.facets.area.max];
        //
        // if (_isEqual(this.initialFilterParams.price, this.filterParams.price) &&
        //     !_isEqual(this.filterParams.price, newPricesRange)) this.filterParams.price = newPricesRange;
        // if (_isEqual(this.initialFilterParams.area, this.filterParams.area) &&
        //     !_isEqual(this.filterParams.area, newAreaRange)) this.filterParams.area = newAreaRange;

        return this.facets;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        // this.setLoading(false);
      }
    },
    initFilterParams() {
      const urlSearch = window.location.search;
      this.initialFilterParams = {
        price: [this.filters.price.min, this.filters.price.max],
        area: [this.filters.area.min, this.filters.area.max],
        floor_number: [this.filters.floor_number.min, this.filters.floor_number.max],
        rooms: [],
        projects: [],
        quarters: [],
        buildings: [],
        advantages: [],
        floor_first_last: false,
        discount: false,
        furnish: false,
        apartments: false,
        category: null,
      };


      this.filterParams = _cloneDeep(toRaw(this.initialFilterParams))
      if (urlSearch) {
        const paramsFromUrl = getSearchUrl();
        for (let param in paramsFromUrl) {
          const cleanParam = param.replace('[]', '')
          if (this.initialFilterParams.hasOwnProperty(cleanParam)) {
            const paramNameIsArray = Array.isArray(this.initialFilterParams[cleanParam]);
            const paramValueIsArray = Array.isArray(paramsFromUrl[param])
            const valueIsBooleanTrue = paramsFromUrl[param] === 'true';
            const valueIsBooleanFalse = paramsFromUrl[param] === 'false';
            if (valueIsBooleanTrue) {
              this.filterParams[cleanParam] = true
              continue
            }
            if (valueIsBooleanFalse) {
              this.filterParams[cleanParam] = false
              continue
            }

            if ( paramNameIsArray && !paramValueIsArray ) {

              this.filterParams[cleanParam] = [paramsFromUrl[param]]
            } else {
              this.filterParams[cleanParam] = paramsFromUrl[param]
            }
          }
        }
      }

      this.sendData()
    },
    chooseCategory(pk = null) {
      this.filterParams.category = pk;
    },
    setTags: _debounce(async function(params) {
      console.log('setTags params = ', params);
      const tags = [];
      const facetsPrice = [this.facets.price.min, this.facets.price.max];
      if (!_isEqual(params.price, this.initialFilterParams.price) && !_isEqual(params.price, facetsPrice)) {
        const [min, max] = params.price;
        tags.push({
          filter: 'price',
          category: 'price',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } млн ₽`,
          pk: null,
        });
      }
      const facetsArea = [this.facets.area.min, this.facets.area.max];
      if (!_isEqual(params.area, this.initialFilterParams.area) && !_isEqual(params.area, facetsArea)) {
        const [min, max] = params.area;
        tags.push({
          filter: 'params',
          category: 'area',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } м²`,
          pk: null,
        });
      }
      const facetsFloor = [this.facets.floor_number.min, this.facets.floor_number.max];
      if (!_isEqual(params.floor_number, this.initialFilterParams.floor_number) && !_isEqual(params.floor_number, facetsFloor)) {
        const [min, max] = params.floor_number;
        tags.push({
          filter: 'params',
          category: 'floor_number',
          tag_title: `${ min.toString().replace('.', ',') }—${ max.toString().replace('.', ',') } этажи`,
          pk: null,
        });
      }
      if (params.category) {
        const curCat = this.filters.category.find(el => el.pk.toString() === params.category);
        curCat.filter = 'params';
        curCat.category = 'category';
        tags.push(curCat);
      }
      if (params.floor_first_last) {
        tags.push({
          filter: 'params',
          category: 'floor_first_last',
          tag_title: `не первый и не последний этаж`,
          pk: null,
        });
      }
      if (params.discount) {
        tags.push({
          filter: 'params',
          category: 'discount',
          tag_title: `По акции`,
          pk: null,
        });
      }
      if (params.furnish) {
        tags.push({
          filter: 'params',
          category: 'furnish',
          tag_title: `С отделкой`,
          pk: null,
        });
      }
      if (params.apartments) {
        tags.push({
          filter: 'params',
          category: 'apartments',
          tag_title: `Апартаменты`,
          pk: null,
        });
      }

      if (params.rooms.length > 0) {
        const roomsSelected = this.filters.rooms.filter((room) => params.rooms.includes(room.pk))
        roomsSelected.forEach((tag) => {
          tags.push({
            filter: 'rooms',
            category: 'rooms',
            tag_title: tag.filter_title,
            pk: tag.pk,
          });
        })

      }
      if (params.advantages.length > 0) {
        const advantagesSelected = this.filters.advantages.filter((adv) => params.advantages.includes(adv.pk))
        advantagesSelected.forEach((tag) => {
          tags.push({
            filter: 'params',
            category: 'advantages',
            tag_title: tag.filter_title,
            pk: tag.pk,
          });
        })

      }
      if (params.projects.length > 0) {
        const titles = params.projects.map(project => [project, this.filters.projects.find(el => el.pk.toString() === project.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'projects',
            category: 'projects',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.projects.length === 1 && params.quarters.length) {
        const currentProjectQuarters = this.filters.projects.find((project) => project.pk === params.projects[0]).quarters;
        const quarters = currentProjectQuarters.filter((quarter) => params.quarters.includes(quarter.pk))

        quarters.forEach((tag) => {
          tags.push({
            filter: 'quarters',
            category: 'quarters',
            tag_title: tag.tag_title,
            pk: tag.pk,
          });
        });
      }
      if (params.projects.length === 1 && params.quarters.length === 1) {
        const currentProjectQuarters = this.filters.projects.find((project) => project.pk === params.projects[0]).quarters;
        const currentQuarterBuildings = currentProjectQuarters.find((quarter) => quarter.pk === this.filterParams.quarters[0]).buildings;
        const buildings = currentQuarterBuildings.filter((building) => params.buildings.map((bld) => bld.toString()).includes(building.pk.toString()))
        buildings.forEach((tag) => {
          tags.push({
            filter: 'buildings',
            category: 'buildings',
            tag_title: tag.tag_title,
            pk: tag.pk,
          });
        });
      }
      if (params.projects.length === 1 && params.buildings.length && !params.quarters.length) {
        const currentProject = this.filters.projects.find((project) => project.pk === params.projects[0]);
        if (currentProject.buildings) {
          const buildings = currentProject.buildings.filter((building) => params.buildings.map((bld) => bld.toString()).includes(building.pk.toString()))

          buildings.forEach((tag) => {
            tags.push({
              filter: 'buildings',
              category: 'buildings',
              tag_title: tag.tag_title,
              pk: tag.pk,
            });
          });
        }

      }
      console.log('setTags tags = ', tags);
      this.$emit('update:tagsItems', tags)
    }, 100),
    resetFilter(tag) {
      const [filter, category, value] = [tag.filter, tag.category, tag.pk];

      if (filter === 'price') this.filterParams.price = this.initialFilterParams.price;
      if (category === 'area') this.filterParams.area = this.initialFilterParams.area;

      if (category === 'category') {
        this.chooseCategory();
        this.$refs.category_null.click();
      }

      if (category === 'floor_number') this.filterParams.floor_number = this.initialFilterParams.floor_number;
      if (category === 'floor_first_last') this.filterParams.floor_first_last = false;

      if (category === 'rooms') this.filterParams.rooms = this.filterParams.rooms.filter(v => v.toString() !== value.toString());
      if (category === 'advantages') this.filterParams.advantages = this.filterParams.advantages.filter(v =>  v.toString() !== value.toString())

      if (filter === 'projects') {
        this.filterParams.projects = this.filterParams.projects.filter(v => v.toString() !== value.toString());
        this.filterParams.quarters = []
        this.filterParams.buildings = []
      }
      if (filter === 'quarters') {
        this.filterParams.quarters = this.filterParams.quarters.filter(v => v.toString() !== value.toString());
        this.filterParams.buildings = []
      }
      if (filter === 'buildings') this.filterParams.buildings = this.filterParams.buildings.filter(v => v.toString() !== value.toString());

      if (category === 'furnish') this.filterParams.furnish = false;
      if (category === 'discount') this.filterParams.discount = false;
      if (category === 'apartments') this.filterParams.apartments = false;
    },
    async resetFilters() {
      this.filterParams = _cloneDeep(toRaw(this.initialFilterParams))
      await this.getFacets();
    },
    checkScreenSize() {
      this.mobileOpen = this.screenXS = window.matchMedia('(max-width: 1279px)').matches;
    },
    sendData() {
      this.$emit('filterChanged',this.filterParams)
    },
    saveProjectsMobile() {
      this.savedProjectsMobile = true;
      this.projectsIsOpen = false;
      setTimeout(() => {
        this.savedProjectsMobile = false;
      }, 500)
    },
  }
}
</script>

<style scoped>

</style>
