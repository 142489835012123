<template>
  <div class="d-floor-slider">
    <div class="d-floor-slider__inner">
      <button class="d-floor-slider__control _prev">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
      </button>
      <div class="d-floor-slider__pagination _pagination"></div>
      <button class="d-floor-slider__control _next">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DFloorSlider',
};
</script>

