<template>
  <div v-if="pagesCount > 1" class="d-aux-rooms__bottom">
    <div class="d-aux-rooms__pager d-search-pager">
      <div class="d-search-pager__inner _buttons">
        <div v-if="!prevPageDisabled" role="button" class="d-search-pager__control _prev" @click="changePage(currentPageIndex - 1)">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
          </svg>
        </div>
        <div class="d-search-pager__pagination">
          <div v-for="page in pages"
               role="button"
               class="d-search-pager__pagination-bullet"
               :class="{ '_prev': page === prevPageIndex, '_active': page === currentPageIndex}"
               :data-page-index="page"
               @click="changePage(page)">{{ page + 1 }}
          </div>
        </div>
        <div v-if="!nextPageDisabled" role="button" class="d-search-pager__control _next" @click="changePage(currentPageIndex + 1)">
          <svg>
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="d-aux-rooms__more">
      <button v-if="!nextPageDisabled" class="d-button _dark" @click="morePage(currentPageIndex + 1)"><span>Показать ещё</span></button>
    </div>
  </div>
</template>

<script>
import {mapWritableState} from 'pinia';
import {useSearchStore} from '@/stores/search';
export default {
  name: 'DSearchPager',
  emits: ['changePage'],
  props: {
    pagesCount: {
      type: Number,
      default: 1,
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    prevPageDisabled() {
      return this.currentPageIndex === 0;
    },
    nextPageDisabled() {
      return this.currentPageIndex === this.pagesCount - 1;
    },
    pages() {
      const pagesView = this.pagesCount < this.pagesView ? this.pagesCount : this.pagesView;
      let leftSpan = Math.ceil((pagesView - 1) / 2);
      if (this.currentPageIndex + 1 - leftSpan < 0) leftSpan = 0;
      let rightSpan = pagesView - 1 - leftSpan;
      let leftEdge = this.currentPageIndex - leftSpan;
      let rightEdge = this.currentPageIndex + rightSpan;
      if (rightEdge > this.pagesCount - 1) {
        leftEdge = leftEdge - (rightEdge - (this.pagesCount - 1));
      }
      if (leftEdge < 0) leftEdge = 0;
      return [...Array(pagesView).keys()].map(x => x + leftEdge);
    },
    ...mapWritableState(useSearchStore, [
      'showMore',
    ]),
  },
  created() {
    window.addEventListener('resize', this.reposition);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.reposition);
  },
  mounted() {
    this.reposition(false);
  },
  data() {
    return {
      prevPageIndex: null,
      pagesPager: [],
      pagesView: 5,
    };
  },
  methods: {
    reposition() {
      this.pagesView = (window.innerWidth < 768) ? 4 : 5;
    },
    changePage(index) {
      this.showMore = false;
      this.$parent.$emit('changePage', index);
    },
    morePage(index) {
      this.showMore = true;
      this.$parent.$emit('changePage', index);
    },
  },
};
</script>

<style scoped>

</style>
