<template>
  <div class="d-flat-mortgage__list">
    <div v-if="!empty" class="d-sorter-line _flatMortgage">
      <button class="d-sorter-line__item" :class="{ _active: sortParam==='bank', _asc: sortParam==='bank' && sortOrder==='asc', _desc: sortParam==='bank' && sortOrder==='desc'}"
              @click="setSortParam('bank')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Банк</span><span class="short">Банк</span>
      </button>
      <div class="d-sorter-line__item">Программа</div>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='percent', _asc: sortParam==='percent' && sortOrder==='asc', _desc: sortParam==='percent' && sortOrder==='desc'}"
              @click="setSortParam('percent')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Ставка</span><span class="short">Ставка</span>
      </button>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='initial', _asc: sortParam==='initial' && sortOrder==='asc', _desc: sortParam==='initial' && sortOrder==='desc'}"
              @click="setSortParam('initial')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Первоначальный взнос</span><span class="short">Первонач. взнос</span>
      </button>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='payment', _asc: sortParam==='payment' && sortOrder==='asc', _desc: sortParam==='payment' && sortOrder==='desc'}"
              @click="setSortParam('payment')">
        <span class="d-sorter-direction _line"><span></span></span><span>Платёж в месяц</span>
        <span class="short">Платёж в мес.</span>
      </button>
    </div>
    <div v-if="!empty" class="d-sorter" :class="{_open: sortDropOpen}">
      <div class="d-sorter__head">
        <button class="d-sorter__direction d-sorter-direction _active" :class="{ _asc: sortOrder==='asc', _desc: sortOrder==='desc'}" @click="setSortParam(sortParam)"><span></span>
        </button>
        <div class="d-sorter__label" @click="showSortDrop">{{ sortLabel[sortParam] }}
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-down"></use>
          </svg>
        </div>
      </div>
      <div class="d-sorter__drop">
        <div class="d-sorter__list">
          <div class="d-sorter__item" :class="{ _active: sortParam==='bank', _asc: sortParam==='bank' && sortOrder==='asc', _desc: sortParam==='bank' && sortOrder==='desc'}"
               @click="setSortParam('bank')">Банк
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='percent', _asc: sortParam==='percent' && sortOrder==='asc', _desc: sortParam==='percent' && sortOrder==='desc'}"
               @click="setSortParam('percent')">Ставка
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='initial', _asc: sortParam==='initial' && sortOrder==='asc', _desc: sortParam==='initial' && sortOrder==='desc'}"
               @click="setSortParam('initial')">Первоначальный взнос
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='payment', _asc: sortParam==='payment' && sortOrder==='asc', _desc: sortParam==='payment' && sortOrder==='desc'}"
               @click="setSortParam('payment')">Платёж в месяц
          </div>
        </div>
      </div>
    </div>
    <template v-if="loading">
      <div v-for="i in [0, 1, 2, 3, 4]" class="d-bank">
        <div class="d-bank__item">
          <div class="skeleton-field _fieldBank1"></div>
        </div>
        <div class="d-bank__item _program">
          <div class="d-bank__label skeleton-label _label1"></div>
          <div class="skeleton-field _fieldBank2"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label2"></div>
          <div class="skeleton-field _fieldBank3"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label3"></div>
          <div class="skeleton-field _fieldBank3 _mobile"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label4"></div>
          <div class="skeleton-field _fieldBank4"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="(item, index) in items" :key="item.index" :data-bank="item.bank_slug" class="d-bank" @click="sendRequest(item)">
        <div class="d-bank__item">
          <img class="d-bank__logo" :src="item.logo">
        </div>
        <div class="d-bank__item _program">
          <div class="d-bank__label">Программа</div>
          {{ item.name }}
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label">Ставка</div>
          {{ item.percent }}%
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label">Первонач. взнос</div>
          от {{ item.initial }}%
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label">Платёж в мес.</div>
          от {{ formatMoney(item.payment) }} ₽
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'DFlatMortgageResults',
  emits: ['request', 'update:sortOrder', 'update:sortParam'],
  props: {
    items: {
      type: Array,
      default: [],
    },
    next: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    sortParam: {
      type: String,
      default: 'percent',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
  },
  data() {
    return {
      sortLabel: {
        initial: 'Первоначальный взнос',
        percent: 'Ставка',
        bank: 'Банк',
        payment: 'Платёж в месяц',
      },
      sortDropOpen: false,
    };
  },
  computed: {},
  methods: {
    showSortDrop() {
      this.sortDropOpen = !this.sortDropOpen;
    },
    sendRequest(bank_data) {
      this.$emit('request', {bank_data});
    },
    setSortParam(param) {
      if (this.sortParam === param) {
        if (this.sortOrder === 'asc') this.$emit('update:sortOrder', 'desc');
        else this.$emit('update:sortOrder', 'asc');
      }
      else {
        this.$emit('update:sortParam', param);
        this.$emit('update:sortOrder', 'asc');
      }
      this.sortDropOpen = false;
    },
    formatMoney(value) {
      if (!value) return value;
      if (typeof value === 'number') {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          useGrouping: true,
          maximumSignificantDigits: 11,
        })
            .format(value)
            .replace(/,/g, ' ');
      }
      else {
        return value;
      }
    },
  },
};
</script>

<style scoped>

</style>
