<template>
  <div v-if="!errored" class="captcha-info">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
    <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
  </div>
  <div v-else class="captcha-info _error">{{ errorText }}</div>
</template>

<script>
export default {
  name: 'DRecaptchaInfo',
  props: {
    errored: Boolean,
    errorText: {
      type: String,
      default: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу'
    }
  }
};
</script>
