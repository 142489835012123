<template>
  <d-popup v-slot="{ close }" v-bind="$attrs" @closed="removeHash">
    <div class="d-callMe__form" :class="modClass">
      <div class="d-callMe__top">
        <div class="d-callMe__title">Получить консультацию</div>
        <div class="d-callMe__subtitle" v-if="mod && mod === 'regional'">
          Оставьте Ваши контакты и наши менеджеры свяжутся с Вами.<br>
          В форме указано московское время.
        </div>
        <div class="d-callMe__subtitle" v-else>Оставьте Ваши контакты и наши менеджеры свяжутся с Вами.</div>
        <img src="/assets/blueant/assets/img/d/callMe_bg.jpg" class="d-callMe__bg">
      </div>
      <d-call-me-form :data-action-url="actionUrl" :data-lead-url="leadUrl" :data-mod="['popup',mod]" :data-props="props" :data-politics-link="politicsLink" @success="close"/>
    </div>
  </d-popup>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';
import DCallMeForm from '@components/organisms/DCallMeForm.vue';

export default {
  name: 'DCallMeFormPopup',
  components: {
    DPopup, DCallMeForm,
  },
  props: {
    actionUrl: {
      type: String,
      required: true,
      default: '',
    },
    leadUrl: {
      type: [String, Boolean],
      default: false,
    },
    props: {
      type: [Object, Boolean, String],
      default: false,
    },
    politicsLink: {
      type: String,
      required: true,
      default: '',
    },
    mod: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    modClass() {
      const result = [];

      if (this.mod) {
        if (Array.isArray(this.mod)) {
          this.mod.forEach((mod) => {
            result.push(`_${mod}`)
          });
        } else {
          result.push(`_${this.mod}`)
        }
      }
      return result;
    },
  },
  methods: {
    removeHash() {
      if (window.location.hash.match(/callme/)) {
        const url = new URL(location.href);
        url.hash = '';
        history.pushState(null, '', url);
      }
    }
  }
};
</script>
