function getCentroid(path, w, h) {
    var centroid = {cx: 0, cy: 0},
        area = 0,
        length = path.getTotalLength(),
        lengthSegment = length / 100.0;
    for (var i = 0; i < length; i += lengthSegment) {
        var curr = path.getPointAtLength(i),
            next = path.getPointAtLength(i + lengthSegment);
        centroid.cx += (curr.x + next.x) * (curr.x * next.y - next.x * curr.y);
        centroid.cy += (curr.y + next.y) * (curr.x * next.y - next.x * curr.y);
        area += curr.x * next.y - next.x * curr.y;
    }
    const box = path.getBBox();

    area = area / 2.0;
    centroid.cx = centroid.cx / (6 * area);
    centroid.cy = centroid.cy / (6 * area);

    centroid.x = (100 * centroid.cx / w).toFixed(2);
    centroid.y = (100 * centroid.cy / h).toFixed(2);
    centroid.box = box;
    centroid.w = w;
    centroid.h = h;

    return centroid;
}

export default getCentroid;
