<template>
  <div class="d-favorites-pdf-info" :class="`${mod ? '_'+mod : ''}`">
    <div class="d-favorites-pdf-info__item _item1">
      <div class="d-favorites-pdf-info__title">{{ pdf.title }}</div>
      <div class="d-favorites-pdf-info__size">PDF {{ pdf.size }}</div>
    </div>
    <div class="d-favorites-pdf-info__item _item2">
      <button class="d-button _flatIcon _favorites" :data-social-share-list="pdfJson" :data-object="object">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-share"></use>
        </svg>
      </button>
      <button class="d-button _flatIcon _favorites _send" data-v-tippy="Отправить на e-mail" @click="$emit('show-popup')">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-email"></use>
        </svg>
      </button>
      <a class="d-button _flatIcon _favorites _download" :href="pdf.url" data-v-tippy="Скачать PDF" target="_blank">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-download"></use>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DFavoritesPdfInfo',
  emits: ['show-popup'],
  props: {
    object: {
      type: [Object, Number],
      default: null
    },
    pdf: Object,
    mod: {
      type: String,
      default: '',
    },
  },
  computed: {
    pdfJson() {
      return JSON.stringify(this.pdf)
    }
  }
};
</script>
