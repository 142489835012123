<template>
  <d-search-control-skeleton v-if="!loaded"/>
  <template v-else>
    <button v-if="screenTablet" class="d-aux-rooms__buttonFilter" @click="setShowFilter">
      <img src="/assets/blueant/assets/img/d/icon_filter.svg"/><span>Фильтры</span>
    </button>
    <d-view-switcher @update:display="setDisplay" :value="display"/>
    <div v-element-visibility="onElementVisibility" class="d-aux-rooms__count">{{ result.total_string }}</div>
    <d-search-tags v-if="loaded" :tags="tags" @reset:tag="resetFilter" />
    <div class="d-aux-rooms__sorter" :class="{ _hide: display === 'list' }">
      <d-drop-sorter v-model="currentSort" :data-options="sortOptions"></d-drop-sorter>
    </div>
  </template>
</template>

<script>
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore} from '@/stores/search';
import {vElementVisibility} from '@vueuse/components';
import DSearchControlSkeleton from '@components/molecules/DSearchControlSkeleton.vue';
import DViewSwitcher from '@components/atoms/DViewSwitcher.vue';
import DDropSorter from '@components/molecules/DDropSorter.vue';
import DSearchTags from '@components/organisms/DSearchTags.vue';

export default {
  name: 'DSearchControlsWrapper',
  components: {DSearchControlSkeleton, DViewSwitcher, DDropSorter, DSearchTags},
  directives: {elementVisibility: vElementVisibility},
  inheritAttrs: false,
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    mod: {
      type: String,
      default: 'parking',
    },
  },
  watch: {
    sortOptions: {
      immediate: true,
      handler() {
        if (!this.currentSort) this.currentSort = this.sortOptions[0];
      },
    },
  },
  computed: {
    currentSort: {
      get() {
        return this.sort.selected;
      },
      set(value) {
        this.sort.selected = value;
      },
    },
    sortOptions() {
      return this.sort.options;
    },
    ...mapWritableState(useSearchStore, [
      'showFilter',
      'display',
      'sort',
      'filterOutOfView',
    ]),
    ...mapState(useSearchStore, [
          'result',
          'screenTablet',
          'loaded',
        ])
  },
  data() {
    return {
      initial: true,
    };

  },
  methods: {
    setDisplay(value) {
      this.display = value;
    },
    setShowFilter() {
      this.showFilter = true;
    },
    onElementVisibility(state) {
      this.filterOutOfView = !state;
    },
    resetFilter(tag) {
      this.$emit('reset:tag', tag);
    },
  },
};
</script>

<style lang="sass" scoped></style>
