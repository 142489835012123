<template>
  <div class="d-news-item _smi" :data-index="itemData.index" :data-page="itemData.page" :class="{_hidden: !itemData.visible}">
    <div class="d-news-item__date">
      <div class="d-news-date">{{ itemData.date }}</div>
    </div>
    <div class="d-news-item__image"><img :src="itemData.image" alt=""></div>
    <div class="d-news-item__content">
      <div class="d-news-item__likes">
        <button class="d-news-likes" :data-id="itemData.id" :data-likes="itemData.likes" :data-url="dataLikesUrl" :class="{_active: itemData._active}">
          <span>{{ itemData.likes }}</span>
          <div class="button"></div>
        </button>
      </div>
      <div v-if="itemData.source" class="d-news-item__source" v-html="itemData.source"></div>
      <div class="d-news-item__name" v-html="itemData.name"></div>
      <div class="d-news-item__more d-button _dark"><span>Подробнее</span></div>
    </div>
    <a class="d-news-item__link" :href="itemData.link"></a>
  </div>
</template>

<script>
export default {
  name: 'DMediaItem',
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    dataLikesUrl: {
      type: String,
      required: true,
      default: '/assets/blueant/assets/json/likes-response.json',
    },
  },
};
</script>

<style scoped>

</style>
