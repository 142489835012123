import {InteractiveForm} from "./InteractiveForm";
import SlimSelect from 'slim-select'
import '../../styles/libs/slim-select.min.css'
import SimpleBar from 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

class popupForm {
    constructor($el) {
        const type = $el.data('popup-form')
        console.log('type', type)
        const template = $(`[data-popup-form-template="${type}"]`)
        const $main = template.find('[data-popup-form-main]')
        const $success = template.find('[data-popup-form-success]')
        const $error = template.find('[data-popup-form-error]')
        const $select = template.find('[data-select]')

        let formHandler;
        let selectHandler;

        $el.on('click', function () {
            const formName = $(this).data('targ')
            $.magnificPopup.open({
                mainClass: 'ba-popup-form',
                closeOnContentClick: false,
                items: {
                    src: template,
                    type: 'inline'
                },
                callbacks: {

                    beforeClose: function() {
                        $('body').css('overflow', 'auto');
                    },
                    beforeOpen: function (instance) {
                        $('body').css('overflow', 'hidden');


                        formHandler = new InteractiveForm(template[0], {
                            submitHandler: (form) => {
                                let $form = $(form);


                                const $phone = $form.find("input[type=tel]")
                                let phone_val = null
                                if ($phone.length) {
                                    phone_val = $phone.val();
                                    // phone_val = phone_val.replace(/[\s+-]/g, "");
                                }

                                let dataToSend = Object.assign({}, $form.serializeObject(), {formType: formName})
                                console.log('dataToSend', dataToSend)

                                $.ajax({
                                    url: form.action,
                                    type: form.method,
                                    dataType: 'json',
                                    data: dataToSend,
                                })
                                    .done(response => {
                                        console.log('response', form)
                                        console.log('dataToSend', dataToSend)



                                        if (response.status === 0) {
                                            $main.removeClass('_active')
                                            $error.removeClass('_active')
                                            $success.addClass('_active')
                                            form.reset()

                                            const apart = {}
                                            const object = dataToSend

                                            var lead_data = {
                                                'area': apart.sq || '',
                                                'comment': object.message || '',
                                                'email': object.mail || '',
                                                'flat': apart.f_id || '',
                                                'floor': apart.f || '',
                                                'formType': object.type,
                                                'housing': apart.b || '',
                                                'name': object.name || '',
                                                'phone': object.phone || '',
                                                'price': apart.tc || '',
                                                'question': object.question || '',
                                                'section': apart.s || '',
                                                'sourceConversion': '',
                                                'timeComfortable': object.call_time || '',
                                                'typeFlat': apart.type_rc || '',
                                                'URL': window.location.href || '',
                                                'utm': Cookies.get('utm_data') || ''
                                            };

                                            console.log('lead_data', lead_data)

                                            $.ajax({
                                                url: '/assets/pages/create_lead.php',
                                                cache: false,
                                                type: 'POST',
                                                data: lead_data,
                                                success: function (response) {
                                                    console.log(response);
                                                },
                                                error: function (response) {
                                                    console.log(response);
                                                }
                                            });
                                        }
                                    })
                                    .fail((/*response*/) => {
                                        $main.removeClass('_active')
                                        $success.removeClass('_active')
                                        $error.addClass('_active')
                                    });
                            }
                        })
                    },
                    open: function () {
                        // const $agreeBtn = this.container.find('[data-form-agree]')
                        // const $politicLink = $agreeBtn.find('a')
                        // console.log('$politicLink', $politicLink)
                        // $politicLink.on('click', function (e) {
                        //     console.log('click link', e)
                        //     e.preventDefault()
                        // })
                        if ($select.length) {
                            selectHandler = new SlimSelect({
                                select: '#managers',
                                showSearch: false,
                                placeholder: 'Выберите менеджера',
                                beforeOpen: () => {
                                    console.log('$select', $select)
                                    const selectList = $select.parent().find('.ss-list')[0];
                                    if (selectList) {
                                        new SimpleBar(selectList, {
                                            autoHide: false
                                        });
                                    }

                                },
                            })
                        }
                    },
                    close: function () {
                        // disableScroll.off();
                        $main.addClass('_active')
                        $error.removeClass('_active')
                        $success.removeClass('_active')
                        console.log('close')
                        template.trigger('reset')
                        formHandler.destroy()
                        if ($select.length) {
                            selectHandler.destroy()
                        }

                    }
                }
            });
        })
    }
}

export default popupForm