<template>
  <div>
    <div class="d-flat-list" :class="classList">
      <template v-if="mod === 'skeleton'">
        <div class="d-flat-list__item _item1">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item2">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item3">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item4">
          <div class="skeleton-field"></div>
        </div>
        <div class="d-flat-list__item _item5"></div>
        <div class="d-flat-list__item _item6">
          <div class="skeleton-field"></div>
        </div>
      </template>
      <template v-else>
        <div v-if="'is_new' in data && data.is_new" class="d-flat-list__sticker _new" :data-v-tippy="data.is_new_tip"><span>new</span></div>
        <div class="d-flat-list__item _item1">
          <img :src="data.plan">
        </div>
        <div class="d-flat-list__item _item2">
          <div class="d-flat-list__label">Комнат</div>
          <span v-html="roomsTitle"></span>
        </div>
        <div class="d-flat-list__item _item3">
          <div class="d-flat-list__object">{{ data.project }}<small>{{ data.quarter }}</small></div>
        </div>
        <div class="d-flat-list__item _item4">
          <div class="d-flat-list__label">Корпус</div>
          {{ data.building }}, {{ data.section }} секц
        </div>
        <div class="d-flat-list__item _item5">
          <div class="d-flat-list__label">Этаж</div>
          {{ data.floor }} из {{ data.floors_total }}
        </div>
        <div class="d-flat-list__item _item6">
          <div class="d-flat-list__label">Номер</div>
          {{ data.number }}
        </div>
        <div class="d-flat-list__item _item7">
          <div class="d-flat-list__label">Площадь, м²</div>
          {{ data.area }}
        </div>
        <div class="d-flat-list__item _item8">
          <div v-if="data.price_request && data.price_request_label" class="d-flat-list__price _request" v-html="data.price_request_label" />
          <div v-else class="d-flat-list__price">
            <div v-if="data.price_old" class="d-flat-list__priceOld"> {{ data.price_old.toLocaleString('ru-RU') }} ₽</div>
            {{ data.price.toLocaleString('ru-RU') }} ₽
          </div>
        </div>
        <div class="d-flat-list__item _item9">
          <div class="d-flat-list__actions _desktop">
            <button v-if="'promotion' in data && data['promotion'].bage" class="d-flat-action _black-friday-bage" :data-v-tippy="data['promotion'].text">
              <img v-if="data['promotion'].bage_line" :src="data['promotion'].bage_line" class="d-flat-list__black-friday"/>
              <img v-else-if="data['promotion'].bage" :src="data['promotion'].bage" class="d-flat-list__black-friday"/>
            </button>
            <button v-if="'promotion' in data && data['promotion'].label" class="d-flat-action _black-friday" :style="{color: data['promotion'].color, backgroundColor: data['promotion'].background}" :data-v-tippy="data['promotion'].text">
              <div class="d-flat-action__name">{{ data['promotion'].label }}</div>
            </button>
            <div v-for="label in data.labels" :key="label.title" class="d-flat-action" :style="`background-color:${label.background_color}; color: ${label.color}`" :data-v-tippy="label.tooltip">
              <div class="d-flat-action__name">{{ label.title }}</div>
            </div>
          </div>
          <div class="d-flat-list__actions">
            <button v-if="'promotion' in data && data['promotion'].bage" class="d-flat-action _black-friday-bage" :data-v-tippy="data['promotion'].text">
              <img v-if="data['promotion'].bage_line" :src="data['promotion'].bage_line" class="d-flat-list__black-friday"/>
              <img v-else-if="data['promotion'].bage" :src="data['promotion'].bage" class="d-flat-list__black-friday"/>
            </button>
            <button v-if="'promotion' in data && data['promotion'].label" class="d-flat-action _black-friday" :style="{color: data['promotion'].color, backgroundColor: data['promotion'].background}" :data-v-tippy="data['promotion'].text">
              <div class="d-flat-action__name">{{ data['promotion'].label }}</div>
            </button>
            <img v-for="label in data.labels" :key="label.title"  :src="`/assets/blueant/assets/img/d/${label.icon}.svg`" :data-v-tippy="label.tooltip">
          </div>
        </div>
        <div class="d-flat-list__item _item10">
          <button class="d-button d-flat-list__favorite _flatIcon _favorite" :class="classList" @click="setFavorite" :data-v-tippy="isFavorite ? 'В избранном' : 'Добавить в избранное'">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-heart"></use>
            </svg>
          </button>
        </div>
        <a class="d-flat-list__link" :href="data.link" target="_blank"></a>
      </template>

    </div>

    <div class="d-flat-list _mobile" :class="classList">
      <template v-if="mod === 'skeleton'">
        <div class="d-flat-list__top">
          <div class="d-flat-list__img skeleton-field"></div>
          <div class="d-flat-list__price skeleton-field"></div>
          <div class="d-flat-list__object skeleton-field"></div>
        </div>
        <div class="d-flat-list__bottom">
          <div class="d-flat-list__items skeleton-field"></div>
          <div class="d-button skeleton-field"></div>
        </div>
      </template>
      <template v-else>
        <div v-if="'is_new' in data && data.is_new" class="d-flat-list__sticker _new" :data-v-tippy="data.is_new_tip"><span>new</span></div>
        <div class="d-flat-list__top">
          <div class="d-flat-list__img">
            <img :src="data.plan">
          </div>
          <div v-if="data.price_request && data.price_request_label" class="d-flat-list__price _request" v-html="data.price_request_label" />
          <div v-else class="d-flat-list__price">
            <div v-if="data.price_old" class="d-flat-list__priceOld"> {{ data.price_old.toLocaleString('ru-RU') }} ₽</div>
            {{ data.price.toLocaleString('ru-RU') }} ₽
          </div>
          <div class="d-flat-list__object">{{ data.project }}</div>
        </div>
        <div class="d-flat-list__bottom">
          <div class="d-flat-list__items">
            <div class="d-flat-list__item">{{ roomsTitleMobile }}</div>
            <div class="d-flat-list__item">{{ data.area }} м&sup2;</div>
            <div class="d-flat-list__item">{{ data.floor }} эт.</div>
          </div>
          <div class="d-flat-list__actions">
            <img v-if="'promotion' in data && data['promotion']" src="/assets/blueant/assets/img/d/icon_fire.svg" :data-v-tippy="data['promotion'].text">
            <img v-for="label in data.labels" :key="label.title"  :src="`/assets/blueant/assets/img/d/${label.icon}.svg`" :data-v-tippy="label.tooltip">
          </div>
          <button class="d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-heart"></use>
            </svg>
            <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
            <span v-else class="d-button__tooltip">Добавить в избранное</span>
          </button>
        </div>
        <a class="d-flat-list__link" :href="data.link" target="_blank"></a>
      </template>
    </div>
  </div>
</template>

<script>
import declOfNum from '@utils/declOfNum';
import additionalClassCSS from '@utils/additionalClassCSS';

export default {
  name: 'DChooseFlatRow',
  props: {
    data: Object,
    mod: {
      type: [Array, String],
      default: '',
    },
    favorites: {
      type: Array,
      default: null,
    },
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass, {_active: this.isFavorite});
    },
    roomsTitle() {
      if (this.data.isPenthouse) {
        return 'Пентхаус';
      }
      if (this.data.rooms === 0) {
        return 'Студия';
      }
      return `${ this.data.rooms } <small>${ declOfNum(this.data.rooms, ['комната', 'комнаты', 'комнат']) }</small>`;
    },
    flatTitle() {
      if (this.data.isPenthouse) {
        return `Пентхаус № ${this.data.number}`;
      }
      if (this.data.rooms === 0) {
        return `Студия № ${this.data.number}`;
      }
      return `${ this.data.rooms }-комнатная квартира № ${this.data.number}`;
    },
    roomsTitleMobile() {
      if (this.data.isPenthouse) {
        return 'Пент.';
      }
      if (this.data.rooms === 0) {
        return 'Ст';
      }
      return `${ this.data.rooms }к`;
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.data.id);
      else return false;
    },
  },
  methods: {
    setFavorite() {
      const price = this.data.price_request && this.data.price_request_label ? this.data.price_request_label : `${ this.data.price.toLocaleString('ru-RU') } ₽`;
      this.$emit('set-favorite', {id: this.data.id, title: this.flatTitle, price});
    },
  },
};
</script>
