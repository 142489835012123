import Swiper from 'swiper/bundle';
const isSpecialEl = document.querySelector('.month-offers__vip');
const swiperWrapper = document.querySelector('.month-offers__sliderWrapper');

const slidesPerGroup = isSpecialEl ? 2 : 3;

if (swiperWrapper) {
    swiperWrapper.classList.toggle('month-offers__sliderWrapper--vip', isSpecialEl)
}


new Swiper('.swiper[data-offers-slider]', {
    resistanceRatio: 0,
    autoHeight: true,
    pagination: {
        type: 'fraction',
        el: '.month-offers__pagination',
    },
    navigation: {
        nextEl: '.month-offers__button--next',
        prevEl: '.month-offers__button--prev',
    },
    breakpoints: {
        0: {
            allowTouchMove: true,
            spaceBetween: 14,
            slidesPerView: 1,
            slidesPerGroup: 1,
        },
        768: {
            spaceBetween: 16,
            allowTouchMove: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
        },
        1280: {
            allowTouchMove: false,
            spaceBetween: 32,
            slidesPerView: slidesPerGroup,
            slidesPerGroup: slidesPerGroup,
        },
        1920: {
            allowTouchMove: false,
            spaceBetween: 48,
            slidesPerView: slidesPerGroup,
            slidesPerGroup: slidesPerGroup,
        }
    },
})
