import Inputmask from "inputmask/lib/inputmask";
import '../libs/jquery.serialize-object.min'
import '../libs/jquery.validate.min'
import '../libs/jquery.validate_messages_ru'
$.validator.addMethod(
	"regex",
	function(value, element, regexp) {
		var re = new RegExp(regexp);
		return this.optional(element) || re.test(value);
	},
	"Поле заполнено не верно"
);

export class InteractiveForm {
	constructor(el, opts = {}) {
		this.$form = $(el);
		console.log('$form', this.$form)
		let $form = this.$form;
		const $agreeBtn = this.$form.find('[data-form-agree]')

		$form.on('submit', function (e) {
			e.preventDefault()
		})


		console.log('$agreeBtn', $agreeBtn)
		if ($agreeBtn.length) {
			const $politicLink = $agreeBtn.find('a')
			console.log('$politicLink', $politicLink)
			$politicLink.on('click', function (e) {
				e.stopPropagation()
			})
			$agreeBtn.on('click', (e) => {
				const $this = $(e.currentTarget)
				console.log('defaultPrevented', e.defaultPrevented)
				const $submitBtn = this.$form.find('button[type="submit"]')
				$this.toggleClass('_active')
				if ($this.hasClass('_active')) {
					$submitBtn.addClass('_active')
				} else {
					$submitBtn.removeClass('_active')
				}
			})
		}


		if ($form.data("action")) {
			$form.prop("action", $form.data("action"));
		}

		let $phoneInputs = $form.find('[name="phone"], [type="tel"]');

		Inputmask("+9 (999) 999-99-99").mask($phoneInputs);



		let validatorOpts = {
			rules: {
				captcha: {
					required: true,
					regex: /\d\d\d\d\d/
				}
			},
			messages:{
				name: {
					required: "Введите корректное имя",
				},
				phone: {
					required: "Введите корректный телефон",
				},
				email: {
					required: "Введите корректный e-mail"
				}
			},
			errorElement: "em",

			focusCleanup: false,
			submitHandler: opts.submitHandler || this.standartFormHandler, //(form)=>{}
			errorPlacement: ($errorLabel, $el) => {
				if ($el.attr("name") === "agree") {
					return true;
				} else {
					$el.after($errorLabel);
					return true;
				}
			}
		};

		$phoneInputs.each((index, el) => {
			validatorOpts.rules[el.name] = {
				required: !!el.required,
				regex: /^\+?\d\s*\(\d{3}\)?\s*\d{3}-?\d{2}-?\d{2}/
			};
		});

		if (opts.validatorParams) {
			$.extend(true, validatorOpts, opts.validatorParams);
		}

		if (opts.successBlockMod) {
			$.extend(true, opts, { successBlockMod: "default" });
		}

		this.opts = opts;
		this.validator = $form.validate(validatorOpts);

	}

	standartFormHandler(form, opts) {
		let $form = $(form);

		const $phone = $form.find("input[type=tel]")
		let phone_val = null
		if ($phone.length) {
			phone_val = $phone.val();
			phone_val = phone_val.replace(/[\s+-]/g, "");
		}

        // console.log('phone_val', phone_val)


		let dataToSend = $.extend(true, $form.serializeObject(), {
			url: window.location.href,
		});

		const formTheme = $form.data('form-theme')

		$.ajax({
			url: form.action,
			type: form.method,
			data: dataToSend,
		})
			.done(response => {
				console.log('response', response)
				console.log('this.opts', this)
				if (response.code === 1) {
					// opts.success()
				}
			})
			.always((/*response*/) => {
				// window.pagePreloader.hide();
			});

	}

	success(response) {

	}

	destroy() {
		this.validator.destroy();
		// this.$form.find("input").inputmask("remove");
	}
}

