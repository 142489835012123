<template>
  <d-booklets-preloader v-if="!loaded && !errored"/>
  <d-booklets-error v-else-if="errored"/>
  <d-booklets-error v-else-if="!computedList.length" mod="empty" @reset="selected = null"/>
  <TransitionGroup v-else name="list" tag="">
    <div v-for="section in computedList" class="d-booklets__section" :data-slug="section.slug" :key="section.slug">
      <h2 class="d-booklets__section-title">{{ section.title }}</h2>
      <div class="d-booklets__section-content">
        <d-booklets-item v-for="item in section.items" :item="item"/>
      </div>
    </div>
  </TransitionGroup>
  <teleport v-if="loaded" to=".d-booklets__controls-wrapper">
    <d-select :data-options="sectionsFilter" data-placeholder="Все" v-model="selected" />
  </teleport>
</template>

<script>
import DBookletsPreloader from '@components/molecules/DBookletsPreloader.vue';
import DBookletsError from '@components/molecules/DBookletsError.vue';
import DBookletsItem from '@components/atoms/DBookletsItem.vue';
import DSelect from '@components/molecules/DSelect.vue';

export default {
  name: 'DBooklets',
  components: {DBookletsPreloader, DBookletsError, DBookletsItem, DSelect},
  props: {
    apiUrl: {
      type: String,
      default: '/assets/blueant/assets/json/data-booklets.json',
    },
  },
  data() {
    return {
      loaded: false,
      errored: false,
      sections: [],
      selected: null,
    };
  },
  mounted() {
    this.setPopstateEventHandler();
    this.loadData();
  },
  beforeUnmount() {
    this.removePopstateEventHandler();
  },
  computed: {
    computedList() {
      return this.selected ? this.sections.filter(el => el.slug === this.selected) : [...this.sections];
    },
    sectionsFilter() {
      return [{id: 0, value: null, name: 'Все'}, ...this.sections.map(el => ({id: el.slug, value: el.slug, name: el.title}))];
    }
  },
  watch: {
    selected() {
      this.saveStateToURL()
    }
  },
  methods: {
    async loadData() {
      try {
        this.loadFilter();
        const req = await this.axios.get(this.apiUrl);
        if (req.data && req.data.length > 0) {
          this.loaded = true;
          this.sections = req.data;
          return;
        }
        this.errored = true;
        console.error('could not load data! req = ', req);
      } catch (e) {
        this.errored = true;
        console.error(e);
      }
    },
    loadFilter() {
      const paramsFromUrl = (new URLSearchParams(window.location.search)).get('filter');
      if (paramsFromUrl) this.selected = paramsFromUrl;
      else this.selected = null;
    },
    setPopstateEventHandler() {
      window.addEventListener("popstate", this.loadFilter);
    },
    removePopstateEventHandler() {
      window.removeEventListener("popstate", this.loadFilter);
    },
    saveStateToURL() {
      const url = new URL(location.href);
      const newSearch = this.selected ? '?' + (new URLSearchParams('filter='+this.selected)).toString() :  '';
      if (url.search !== newSearch) {
        url.search = newSearch;
        history.pushState(null, '', url);
      }
    }
  },
};
</script>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scale(0.9) translateY(30px);
}
.list-leave-active {
  position: absolute;
}
</style>
