<template>
  <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor d-video-popup mfp-ready" tabindex="-1" style="overflow: hidden auto;">
    <div class="mfp-container mfp-s-ready mfp-iframe-holder">
      <div class="mfp-content">
        <div class="mfp-iframe-scaler">
          <button title="Close (Esc)" type="button" class="mfp-close _panorama" @click="close">×</button>
          <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" allow="autoplay; fullscreen" :src="panoram"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DPanoramPopup',
  emits: ['close'],
  props: {
    panoram: String,
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
};
</script>
