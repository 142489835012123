<template>
  <div class="d-type-single" ref="typeSingle">
    <div class="d-type-single__back" @click="closeType">Ко всем типам</div>
    <div class="d-type-single__inner">
      <div class="d-type-single__card" ref="card">
        <d-choose-type-card v-if="typeData" :data="typeData" mod="single"/>
      </div>
      <div class="d-type-single__fixed" :class="{ _show: showFixedCard }">
        <d-choose-type-card v-if="typeData" :data="typeData" mod="single" :short="true"/>
      </div>
      <div v-if="typeFlats && totalFlats && loading" class="d-type-single__list">
        <div class="d-type-single__result">
          <div class="d-sorter-line _type-single">
            <div class="d-sorter-line__item _item1">Планировка</div>
            <div class="d-sorter-line__item _item2">Комнатность</div>
            <div class="d-sorter-line__item _item3">Объект</div>
            <div class="d-sorter-line__item _item4">Корпус</div>
            <div class="d-sorter-line__item _item5">Этаж</div>
            <div class="d-sorter-line__item _item6">Номер</div>
            <div class="d-sorter-line__item _item7">Площадь, м&sup2;</div>
            <div class="d-sorter-line__item _item8">Стоимость, ₽</div>
            <div class="d-sorter-line__item _item9">Особенности</div>
            <div class="d-sorter-line__item _item10">&nbsp;</div>
          </div>
          <d-choose-flat-row v-for="flat in typeFlatsPart" :key="flat.id" :data="flat" mod="type-single"  @set-favorite="setFavorite" :favorites="favorites"/>
        </div>
        <div class="d-type-single__more">
          <button v-if="remainFlats > 0" class="d-button _dark" @click="moreFlats">
            <span>Показать еще</span>
          </button>
        </div>
      </div>
      <div v-else-if="loading && (!typeFlats || !totalFlats)" class="d-choose-params-empty">
        <div class="d-choose-params-empty__text">По заданным параметрам ничего не найдено.
          Попробуйте&nbsp;сбросить фильтр или задать другие параметры.</div>
        <button class="d-choose-params-empty__button d-button _dark" @click="resetFilters">
          <span>Сбросить фильтры</span>
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import {debounce as _debounce} from "lodash";
import DChooseTypeCard from "@components/molecules/DChooseTypeCard.vue";
import DChooseFlatRow from "@components/molecules/DChooseFlatRow.vue";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import getTouchDevice from "@utils/getTouchDevice";
export default {
  name: "DChooseTypeSingle",
  props: {
    currentTypeId: [Number, String],
    apiFlatsUrl: String,
    apiMethod: String,
    flatsPerPage: String,
    filters: Object,
    sort: String,
    update: Boolean,
    favorites: Array
  },
  components: {
    DChooseTypeCard,
    DChooseFlatRow
  },
  data() {
    return {
      typeFlats: null,
      totalFlats: null,
      flatsFrom: 0,
      flatsTo: null,
      typeData: null,
      showFixedCard: false,
      loading: false,
      touchDevice: false,
    }
  },
  computed: {
    typeFlatsPart() {
      return this.typeFlats.slice(this.flatsFrom, this.flatsTo)
    },
    remainFlats() {
      return this.totalFlats - this.flatsTo;
    },
    parentTypeSingleNode() {
      return this.$refs.typeSingle.closest('.d-popup-simple__content')
    },
    queryParams() {
      return Object.assign({}, this.filters, { id: this.currentTypeId, sort: this.sort})
    }
  },
  watch: {
    update(value){
      if (value) {
        this.getData(this.queryParams)
      }

    }
  },
  created() {
    this.getData(this.queryParams)
    this.touchDevice = getTouchDevice();
  },
  mounted() {
   if (this.parentTypeSingleNode) {
     this.parentTypeSingleNode.addEventListener('scroll', this.scrollHandler);
   }
   this.fixedCardHandler();


  },
  unmounted() {
    if (this.parentTypeSingleNode) {
      this.parentTypeSingleNode.removeEventListener('scroll', this.scrollHandler);
    }
  },
  methods: {
    closeType() {
      this.$emit('closeType')
    },
    getData: _debounce(async function(queryParams) {
      this.loading = false;
      try {
        console.log('%csend data params type single', 'color: yellow', queryParams)
        const response = await this.axios[this.apiMethod](this.apiFlatsUrl, queryParams);
        this.typeData = response.data.type;
        this.flatsTo = Number(this.flatsPerPage);
        this.typeFlats = response.data.flats;
        this.totalFlats = this.typeFlats.length;
        this.$emit('updateTotal', this.totalFlats)
        this.loading = true;
        this.$emit('search-type', response.data['not-standard-search'])

        this.$smoothScroll({
          scrollTo: document.querySelector(`.d-choose-params__content`),
          updateHistory: false,
          duration: 800,
          offset: -220,
        });
      } catch (error) {
        console.error(error);
        // this.errored = true;
      } finally {
        // this.setLoading(false);
        this.refill();
      }

    }, 500),
    moreFlats() {
      if (this.flatsTo < this.totalFlats) {
        this.flatsTo += Number(this.flatsPerPage)
      }
    },
    scrollHandler(event) {
      const scrollValue = event.target.scrollTop;
    },
    fixedCardHandler() {
      const options = {
        // rootMargin: '0',
        threshold: 0.01
      }
      const callback = (entries, observer) => {
        this.showFixedCard = entries[0].isIntersecting !== true;
      };
      const observer = new IntersectionObserver(callback, options);

      const target = this.$refs.card
      observer.observe(target);
    },
    resetFilters() {
      this.$emit('reset')
    },
    setFavorite(id) {
      this.$emit('set-favorite', id)
    },
    refill() {
      const self = this;
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              trigger: this.touchDevice ? 'click' : 'mouseenter',
              allowHTML: true,
              interactive: true,
              offset: [0, 8],
              interactiveDebounce: 10,
              theme: this.touchDevice ? 'choose-filter-touch' : 'choose-filter',
              placement: 'top',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true,
              appendTo: () => document.body,
              zIndex: 9999999999999,
              onShow(instance) {
                if(self.touchDevice) instance.popper.querySelector('.tippy-box').addEventListener('click', () => {
                  instance.hide();
                });
              },
              onHide(instance) {
                if(self.touchDevice) instance.popper.querySelector('.tippy-box').removeEventListener('click', () => {
                  instance.hide();
                });
              },
            });
          }
        });
      });
    },
  }
}
</script>

<style scoped>

</style>