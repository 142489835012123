<template>
  <teleport to="body">
    <d-popup v-slot="{ close }" v-bind="$attrs" :content-class="''" :big-close="true" class="bf_popup">
      <picture class="black-friday__popup-picture" @click="close">
        <source media="(min-width: 1920px)"
                :srcset="`${popup['1920']['1x']} 1x, ${popup['1920']['2x']} 2x, ${popup['1920']['3x']} 3x`"
                type="image/png">
        <source media="(min-width: 1280px)" :srcset="`${popup['1280']} 1x`" type="image/png">
        <source media="(min-width: 768px)" :srcset="`${popup['768']} 1x`" type="image/png">
        <source media="(max-width: 767px)" :srcset="`${popup['320']} 1x`" type="image/png">
        <img :src="popup['320']" type="image/png">
      </picture>
    </d-popup>
  </teleport>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';

export default {
  name: 'DPopupBlackFriday',
  components: {DPopup},
  data() {
    return {
      popup: {
        1920: {
          '1x': '/assets/blueant/assets/img/blackfriday/2023-07/bf-big/bf.png',
          '2x': '/assets/blueant/assets/img/blackfriday/2023-07/bf-big/bf@2x.png',
          '3x': '/assets/blueant/assets/img/blackfriday/2023-07/bf-big/bf@3x.png',
        },
        1280: '/assets/blueant/assets/img/blackfriday/2023-07/bf-md.png',
        768: '/assets/blueant/assets/img/blackfriday/2023-07/bf-sm.png',
        320: '/assets/blueant/assets/img/blackfriday/2023-07/bf-xs.png',
      },
    };
  },
  beforeMount() {
    if(window.blackFriday && window.blackFriday.active && window.blackFriday.popup) this.popup = window.blackFriday.popup;
    if(window.popup30year && window.popup30year.active && window.popup30year.popup) this.popup = window.popup30year.popup;
  },
};
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.d-popup__close) {
  top: 40px;
  right: 40px;
}

.black-friday__popup-picture {
  object-fit: cover;
  max-height: 800px;
}

@media screen and (max-width: 1919px) {
  .black-friday__popup-picture {
    max-height: 600px
  }

  :deep(.d-popup__close) {
    top: 32px;
    right: 32px;
  }
}

@media screen and (max-width: 1279px) {
  .black-friday__popup-picture {
    max-height: 538px
  }
}

@media screen and (max-width: 767px) {
  .black-friday__popup-picture {
    max-height: 224px
  }

  :deep(.d-popup__close) {
    top: 12px;
    right: 12px;
    width: 48px !important;
    height: 48px !important;
  }

  :deep(.d-overlay) {
    display: block !important;
  }
}
</style>
