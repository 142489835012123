<template>
  <div class="d-map _commercial" ref="mapContainer">
    <div ref="map" class="d-map-container"></div>
    <div class="d-object-single-location-map__zoom d-zoom-map">
      <button class="d-zoom-map__button _plus" @click="zoomMap(1)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11V5H11V11H5V13H11V19H13V13H19V11H13Z"></path>
        </svg>
      </button>
      <button class="d-zoom-map__button _minus" @click="zoomMap(-1)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 11V13L19 13V11L5 11Z"></path>
        </svg>
      </button>
    </div>
    <div class="d-commercial-card _map" v-if="showMarkerInfoMobile">
      <button class="d-commercial-card__close d-button _close _blue" @click="showMarkerInfoMobile = false">
        <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
      </button>
      <div class="d-commercial-card__content">
        <div class="d-commercial-card__name">{{ markerInfoMobileData.name }}</div>
        <div class="d-commercial-card__address">{{ markerInfoMobileData.text }}</div>
        <div class="d-commercial-card__bottom">
          <div class="d-commercial-card__square">{{ markerInfoMobileData.area }} м²</div>
          <div v-if="markerInfoMobileData.price" class="d-commercial-card__price">{{ markerInfoMobileData.price.toLocaleString('ru-RU') }} ₽</div>
          <div v-else class="d-commercial-card__price">По запросу</div>
          <a :href="markerInfoMobileData.link" :target="markerInfoMobileData.target" class="d-commercial-card__link d-button _dark">
            <span>Перейти к объекту</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toRaw, markRaw} from 'vue';
import coordsRange from '../../utils/coordsRange';
import tippy from "tippy.js";

export default {
  name: 'DMap',
  props: {
    pointsApiUrl: {
      type: String,
      default: '',
    },
    dataCenterPoint: {
      type: [String, Array],
      default: [55.769761, 37.562889],
    },
    dataBaseZoom: {
      type: [String, Number],
      default: 10,
    },
    dataRestrictArea: {
      type: Boolean,
      default: true,
    },
    page: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      mapInstance: null,
      showMarkerInfoMobile: false,
      markerInfoMobileData: {
        name: null,
        text: null,
        price: null,
        img: null,
        link: null,
        target: null,
        area: null
      },
      points: [],
      centerPoint: null,
      isMapLocked: true,
      pointsLoaded: false,
      mapMargins: [
        {
          bottom: 0,
          right: 0,
          height: 5,
          width: 250,
        },
      ],
      zoomMargin: [120, 250, 5, 50],
      zoomMarginSM: [100, 50, 120, 50],
      zoomMarginXS: [70, 50, 120, 50],
      tippyTips: []
    };
  },
  computed: {
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    screenSM() {
      return window.matchMedia("(max-width: 1279px)").matches
    },
    screenXS() {
      return window.matchMedia("(max-width: 767px)").matches
    }
  },
  created() {
    this.centerPoint = ((typeof this.dataCenterPoint).toLowerCase() === 'string') ? JSON.parse(this.dataCenterPoint) : this.dataCenterPoint;
    this.getPoints();
  },
  mounted() {
    if(this.screenXS) {
      this.mapMargins = [];
      this.zoomMargin = this.zoomMarginXS;
    }
    else if(this.screenSM || this.isTouchDevice) {
      this.mapMargins = [];
      this.zoomMargin = this.zoomMarginSM;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    pointsLoaded() {
      this.$nextTick(() => {
        //console.log('%cDMap pointsLoaded() points = ', 'color: lime', this.points);
        ymaps.ready(this.mapInit);
        window.addEventListener('resize', this.onResize);
      });
    },
    isMapLocked() {
      const rawMap = toRaw(this.mapInstance);
      this.mapBehaviorToggle(rawMap);
    },
  },
  methods: {
    onResize() {
      if (this.screenXS) this.isMapLocked = false;
    },
    lockToggle() {
      this.isMapLocked = !this.isMapLocked;
    },
    mapBehaviorToggle(rawMap) {
      const state = this.isMapLocked ? 'disable' : 'enable';
      rawMap.behaviors[state]('scrollZoom');
      rawMap.behaviors[state]('dblClickZoom');
      rawMap.behaviors[state]('multiTouch');
      rawMap.behaviors[state]('rightMouseButtonMagnifier');
    },
    async getPoints() {
      try {
        let response = await this.axios.get(this.pointsApiUrl);
        this.points = response.data.objects;
        //console.log('%cDMap getPoints() response', 'color: yellow', response.data.objects);
        this.$nextTick(() => {
          this.pointsLoaded = true;
        });
      } catch (err) {
        console.error('%cDMap error load map points', 'color: yellow', err);
      }
    },
    mapInit() {
      const $map = $(this.$refs.map);
      let restrictCoords = false;
      let props = {
        center: this.centerPoint,
        zoom: this.dataBaseZoom,
        controls: [],
      };
      if (this.dataRestrictArea) {
        restrictCoords = coordsRange(this.points, 0.5);
        props = ymaps.util.bounds.getCenterAndZoom(
            restrictCoords,
            [$map.width(), $map.height()],
            null,
            {margin: 50, preciseZoom: true}
        );
        props.controls = [];
      }

      this.mapInstance = new ymaps.Map(this.$refs.map,
          props,
          {
            yandexMapDisablePoiInteractivity: true,
            //restrictMapArea: restrictCoords, // хотелось ограничить возможность двигать карту далеко за пределы очерченной области с объектами, но тогда на меньших разрешениях карта отрисовывается без учёта необходимости вписать все обекты с необходимыми отсупами
          });

      const rawMap = toRaw(this.mapInstance);
      rawMap.behaviors.disable('scrollZoom');

      this.mapMargins.forEach((margin) => rawMap.margin.addArea(margin));

      this.points.forEach((point) => {
        rawMap.geoObjects.add(this.createMarker(point));
      });

      if (this.page === 'commercial') {
        setTimeout(() => {
          this.setTippy();
        }, 100)
      }
      this.mapBehaviorToggle(rawMap);

      const self = this;
      rawMap.setBounds(rawMap.geoObjects.getBounds(), {
        zoomMargin: self.zoomMargin, checkZoomRange: true, useMapMargin: !self.screenXS, preciseZoom: true
      });
      this.onResize();

    },
    createMarker(point) {
      //console.log('%cDMap createMarker() point = ', 'color: lime', point);
      const coords = Object.values(point.coords);
      let marker_html;

      const html = this.isTouchDevice
                   ? `
        <div class="d-map-point _object _${this.page}" data-v-tippy='${this.page === 'commercial' ? JSON.stringify(point) : null}'>
            <div class="d-map-point__img">
                <img src="${ point.img }" alt="">
                <div class="d-map-point-info">
                <div class="d-map-point-info__name">${ point.name }</div>
                <div class="d-map-point-info__text">${ point.text }</div>
                <div class="d-map-point-info__price">${ point.price ? point.price.toLocaleString('ru-RU') + ' ₽' : 'По запросу'}</div>
              </div>
            </div>
        </div>
      `
                   : `
        <div class="d-map-point _object _${this.page}" data-v-tippy='${this.page === 'commercial' ? JSON.stringify(point) : null}'>
            <a class="d-map-point__img" href="${ point.link }" target="${ point.target }">
                <img src="${ point.img }" alt="">
                <div class="d-map-point-info">
                <div class="d-map-point-info__name">${ point.name }</div>
                <div class="d-map-point-info__text">${ point.text }</div>
                <div class="d-map-point-info__price">${ point.price ? point.price.toLocaleString('ru-RU') + ' ₽': 'По запросу'}</div>
              </div>
            </a>
        </div>`;
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function() {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function() {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        },
      });
      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: '',
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [8, 8],
            iconImageOffset: [-4, -8],
            zIndex: 730,
            zIndexActive: 732,
            zIndexHover: 731,
            iconPane: 'overlaps',
          });

      marker.events
          .add('mouseenter', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            const $inner_el = $(marker_html);
            $inner_el.removeClass('_hover');
          })
          .add('click', () => {
            if (this.isTouchDevice) {
              const $inner_el = $(marker_html);

              $('.d-map-point').removeClass('_active');
              this.showMarkerInfoMobile = true;

              $inner_el.addClass('_active').removeClass('_hover');

              for (const p in this.markerInfoMobileData) {
                this.markerInfoMobileData[p] = point[p];
              }
            }
          });
      return marker;
    },
    zoomMap(value) {
      const rawMap = toRaw(this.mapInstance);
      const currentZoom = rawMap.getZoom();
      rawMap.setZoom(currentZoom + value, {duration: 200}).then(() => {
        console.log('%czoomMap rawMap.getZoom() = ', 'color: yellow', rawMap.getZoom());

      });

    },
    closeMarkerInfoMobile() {
      this.showMarkerInfoMobile = false;
      $('.d-map-point').removeClass('_active');
    },
    setTippy() {
      this.tippyTips = [];

      document.querySelectorAll('[data-v-tippy]').forEach(el => {
        const { name, text, price, area, link, target} = JSON.parse(el.dataset.vTippy);
        let tpl = `
        <div class="d-commercial-card _map">
            <div class="d-commercial-card__content">
                <div class="d-commercial-card__name">${name}</div>
                <div class="d-commercial-card__address">${text}</div>
                <div class="d-commercial-card__bottom">
                    <div class="d-commercial-card__square">${area} м²</div>
                    <div class="d-commercial-card__price">${price ? price.toLocaleString('ru-RU') + ` ₽` : 'По запросу'}</div>
                    <a href="${link}" target="${target}" class="d-commercial-card__link d-button _dark">
                        <span>Перейти к объекту</span>
                    </a>
                </div>
            </div>
        </div>
      `;

        if (el.dataset.vTippy > '') {
          this.tippyTips.push(tippy(el, {
            //trigger: 'click',
            allowHTML: true,
            interactive: false,
            appendTo: () => this.$refs.mapContainer,
            offset: [-112, 48],
            theme: 'commercial-map',
            placement: 'right',
            animation: 'fade',
            content: tpl,
            arrow: false,
            touch: false
          }));
        }
      })
    },
  },
};
</script>
