<template>

  <d-mortgage-info v-if="showInfo" class="_flat"/>
  <template v-else>
    <d-flat-mortgage-results v-if="!empty && !errored"
                             :items="showItems"
                             :loading="loading"
                             :empty="empty"
                             v-model:sortParam="sortParam"
                             v-model:sortOrder="sortOrder"
                             @request="sendRequest"/>
    <div v-else-if="empty" class="d-flat-mortgage-empty">
      <div class="d-flat-mortgage-empty__text">По заданным параметрам ничего не найдено. Попробуйте&nbsp;сбросить фильтр или задать другие параметры.</div>
      <button class="d-flat-mortgage-empty__button d-button _dark" @click="reset"><span>Сбросить фильтры</span></button>
    </div>
    <div v-else-if="errored" class="d-flat-mortgage-empty _error">
      <div class="d-flat-mortgage-empty__text">При загрузке данных произошла ошибка. Попробуйте обновить страницу</div>
      <button class="d-flat-mortgage-empty__button d-button _dark"><span>Обновить страницу</span></button>
    </div>
    <div class="d-flat-mortgage__calc">
      <d-mortgage-form data-mod="flat"
                       :data-form="dataForm"
                       :data-url-api="dataUrlApi"
                       @request="sendRequest"
                       @update:items="setItems"
                       @load:items="loadItems"
                       @load:info="showInfo = true"
                       @error="this.errored = true"
                       :reset="resetForm"
                       :sort-param="sortParam"
                       :sort-order="sortOrder"
                       :sort="true"
                       :data-loading="loading"></d-mortgage-form>
    </div>
    <button v-if="!errored && !empty && least > 0" class="d-flat-mortgage__more" @click="showMore">Показать ещё {{ next }} {{ nextText }}</button>
  </template>
</template>

<script>

import DFlatMortgageResults from '@components/organisms/DFlatMortgageResults.vue';
import DMortgageForm from '@components/molecules/DMortgageForm.vue';
import DMortgageInfo from '@components/molecules/DMortgageInfo.vue';
import declOfNum from '@utils/declOfNum';
import _ from 'lodash';
import {toRaw} from 'vue';
import Cookies from 'js-cookie';

export default {
  name: 'DFlatMortgage',
  components: {
    DMortgageInfo,
    DFlatMortgageResults,
    DMortgageForm,
  },
  props: {
    dataForm: {
      type: [String, Object],
      default: '',
    },
    dataUrlApi: {
      type: String,
      default: '/assets/json/flat-mortgage-list.json',
    },
    dataUrlMortgageRequest: {
      type: String,
      default: '/assets/json/form-response.json',
    },
    dataLeadUrl: {
      type: String,
      default: '',
    },
    dataPriorBank: {
      type: String,
      default: '',
    },
    dataMod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      form: {},
      formdata: {},
      dataFlat: null,
      errored: false,
      loading: true,
      resetForm: false,
      offset: 0,
      sortParam: 'percent',
      sortOrder: 'asc',
      showInfo: false,
    };
  },
  created() {
    if (window.flat_info) {
      if (window.flat_data) this.dataFlat = Object.freeze(Object.assign({}, window.flat_info, window.flat_data));
      else this.dataFlat = Object.freeze(window.flat_info);
    }
  },
  watch: {
    showInfo() {
      if (this.showInfo)
        document.querySelector('.d-flat-mortgage__content').classList.add('_info');
    },
  },
  computed: {
    least() {
      return this.filterItems.length - (this.offset > 0 ? this.offset : 5);
    },
    next() {
      return this.filterItems.length - this.offset > 10 ? 10 : this.filterItems.length - this.offset;
    },
    nextText() {
      return declOfNum(this.next, ['предложение', 'предложения', 'предложений']);
    },
    filterItems() {
      if (!Object.keys(this.formdata).length) return this.items;
      let items = toRaw(this.items);
      const self = this;

      items = _.orderBy(_.filter(items, function(o) {
        return self.formdata.years <= o.time && self.formdata.percent >= o.initial
          && (o.max ? self.formdata.sum <= o.max : true) && (o.min ? self.formdata.sum >= o.min : true);
      })
        .sort((a, b) => {
          if (self.dataPriorBank && a.bank_slug === self.dataPriorBank) {
            if (b.bank_slug !== self.dataPriorBank) return -1;
            else return a.percent - b.percent;
          }
          else
            return 0;

        })
        .filter(function(item) {
          return item.percent;
        })
        .map(function(item) {
          item.payment = Math.round(self.formdata.sum * (item.percent / 1200) / (1 - Math.pow(1 + (item.percent / 1200), (-12 * self.formdata.years))));
          //item.percent = item.percent.toString().replace('.', ',');
          return item;
        }), [self.sortParam], [self.sortOrder]);
      //if (this.loading) {
        const mortgagePrice = items.length ? [...items].sort((a, b) => {return a.payment - b.payment; })[0].payment : 0;
        //setTimeout(() => {
          //document.dispatchEvent(new CustomEvent('mortgagePrice', {
          //    detail: {mortgagePrice},
          //  }));
          window.mortgage.price = mortgagePrice;
          console.log(`%c ${ this.$options.name } filterItems() this.loading = `, 'color: lime', this.loading);
          console.log(`%c ${ this.$options.name } filterItems() items.length = `, 'color: lime',  items.length);
          console.log(`%c ${ this.$options.name } filterItems() window.mortgage.price = `, 'color: lime', window.mortgage.price);
        //}, 2000);
      //}
      if (items.length) this.loading = false;
      return items;
    },
    showItems() {
      let items = _.cloneDeep(toRaw(this.filterItems));
      return this.offset > 0 ? items.splice(0, this.offset) : items.splice(0, 5);
    },
    empty() {
      return this.filterItems.length === 0;
    },
  },
  methods: {
    showMore() {
      this.offset += (this.offset > 0 ? 10 : 15);
    },
    loadItems(data) {
      this.items = data;
      this.loading = false;
    },
    sendRequest(data = {}) {
      const flatLeadData = {
        'area': this.dataFlat.area,
        'comment': '',
        'email': '',
        'flat': window.flat_id,
        'floor': this.dataFlat.floor,
        'housing': this.dataFlat.korpus,
        'price': this.dataFlat.price,
        'section': this.dataFlat.section,
        'sourceConversion': '',
        'typeFlat': this.dataFlat.rooms,
        'URL': window.location.href || '',
        'utm': Cookies.get('utm_data') || window.utm_str_src || '',
      };
      const dataProps = Object.assign({}, data, {type: 'mortgage', form_data: this.form}, flatLeadData);
      const politicsLink = document.querySelector('[data-callMe-show][data-politics-link]').dataset.politicsLink;

      //console.log('%cDFlatMortgage sendRequest dataProps', 'color: lime', dataProps);

      window.layoutAapp.$vfm.show({component: 'DFlatMortgageFormPopup', bind: {actionUrl: this.dataUrlMortgageRequest, leadUrl: this.dataLeadUrl, dataProps, politicsLink}});
    },
    setItems(data) {
      this.form = data;
      const {price, payment, years} = data;
      this.formdata = {
        price,
        payment,
        percent: (payment * 100 / price),
        sum: price - payment,
        years,
      };
    },
    reset() {
      this.resetForm = true;
      setTimeout(() => {this.resetForm = false;}, 1000);
    },
  },
};
</script>

<style scoped>

</style>
