const getSearchUrl = () => {
    const groupParamsByKey = (params) => [...params.entries()].reduce((acc, tuple) => {
        const [key, val] = tuple;
        if(acc.hasOwnProperty(key)) {
            if(Array.isArray(acc[key])) {
                acc[key] = [...acc[key], isNaN(val) ? val : Number(val)]
            } else {
                acc[key] = [acc[key], isNaN(val) ? val : Number(val)];
            }
        } else {
            acc[key] = isNaN(val) ? val : Number(val);
        }

        return acc;
    }, {});

    const params = new URLSearchParams(window.location.search);
    return groupParamsByKey(params);
}

export function buildParams(data) {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(value => params.append(key, value.toString()))
    } else {
      params.append(key, value.toString())
    }
  });

  return params.toString()
}
export default getSearchUrl;
