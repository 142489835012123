<template>
  <div class="d-aux-rooms-popup__schemeWrap">
    <div ref="schemePlace" class="d-aux-rooms-popup-scheme">
      <pinch-scroll-zoom ref="zoomer"
                         class="d-aux-rooms-popup-scheme__inner"
                         :width="dimensions.dataWidth"
                         :height="dimensions.dataHeight"
                         :contentWidth="dimensions.contentWidth"
                         :contentHeight="dimensions.contentHeight"
                         :minScale="0.9"
                         :maxScale="20"
                         :scale="zoom"
                         :within="true"
                         @scaling="scalingHandler">
        <div style="position: relative; width: 100%; height: 100%">
          <img :src="mapImg" style="position: absolute" />
          <svg xmlns="http://www.w3.org/2000/svg"
               :viewBox="viewBox"
               style="position: absolute">
            <g v-if="pathData.length || polygonData.length" id="SELECTION" fill="none">
              <path v-for="path in pathData"
                    :key="path.id"
                    :d="path.getAttribute('d')"
                    :transform="path.getAttribute('transform')"
                    :class="getClass(path)"
                    @click="setItem(path.id)"
                    @mouseover="createTooltip($event, path.id)"
                    @mouseleave="currentFlatData = null"/>
              <polygon v-for="polygon in polygonData"
                    :key="polygon.id"
                    :points="polygon.getAttribute('points')"
                    :transform="polygon.getAttribute('transform')"
                    :class="getClass(polygon)"
                    @click="setItem(polygon.id)"
                    @mouseover="createTooltip($event, polygon.id)"
                    @mouseleave="currentFlatData = null"/>
            </g>
          </svg>
        </div>
      </pinch-scroll-zoom>
    </div>
    <div class="d-aux-rooms-popup__zoom d-zoom-map">
      <button class="d-zoom-map__button _plus" @click="scale(0.1)" :disabled="zoom >= 20">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-plus"></use>
        </svg>
      </button>
      <button class="d-zoom-map__button _minus" @click="scale(-0.1)" :disabled="zoom <= 1">
        <svg>
          <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-minus"></use>
        </svg>
      </button>
    </div>
    <d-swipe-zoom-info v-if="noteOpen" class="d-aux-rooms-popup__swipe" mod="pinchzoom" @close="closeSwipeZoomInfo()">
      Используйте жесты для перемещения и масштабирования изображения
    </d-swipe-zoom-info>
  </div>
  <div style="display: none">
    <div ref="tooltip" class="d-aux-rooms-popup-scheme__popup">
      <d-parking-place-tooltip :currentItem="currentFlatData" type="tooltip" :mod="mod" :is-favorite="inFavorites"/>
    </div>
  </div>

</template>

<script>
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore, useItemStore} from '@/stores/search';
import PinchScrollZoom from '@coddicat/vue3-pinch-scroll-zoom';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import Cookies from 'js-cookie';
import tippy from 'tippy.js';
import DParkingPlaceTooltip from '@components/atoms/DParkingPlaceTooltip.vue';
import {markRaw} from 'vue';

export default {
  name: 'DPlacePopupSvgArea',
  components: {DParkingPlaceTooltip, DSwipeZoomInfo, PinchScrollZoom},
  props: {
    dimensions: {
      type: Object,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    mapImg: {
      type: String,
      required: true,
    },
    mod: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapWritableState(useItemStore, [
      'item',
    ]),
    ...mapState(useSearchStore, [
      'data',
      'items',
    ]),
    availItems() {
      return this.items.filter(item => this.svgItems.includes(item.guid));
    },
    availIds() {
      return this.availItems.map(item => item.guid);
    },
    inFavorites() {
      return this.currentFlatData ? window.inFavorites(this.currentFlatData.form_id) : false;
    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        if (this.src) this.initData();
      },
    },
  },
  created() {
    this.noteOpen = !(Number(Cookies.get('closedSwipeZoomInfoParkingPlace')) === 1);
  },
  data() {
    return {
      zoom: 1,
      pathData: [],
      polygonData: [],
      viewBox: null,
      svgItems: [],
      availColor: '#35B8FC',
      currentColor: '#5131F5',
      noteOpen: true,
      currentFlatData: null,
    };
  },
  methods: {
    async initData() {
      try {
        this.svgItems = [];
        const response = await this.axios.get(this.src);
        const svgString = response.data;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        let paths = svgDoc.getElementsByTagName('path');
        let polygons = svgDoc.getElementsByTagName('polygon');
        if (!(paths.length > 0)) {
          const groups = svgDoc.getElementsByTagName('g');
          if (groups.length > 0) {
            paths = groups[0].getElementsByTagName('path');
          }
        }
        if (!(polygons.length > 0)) {
          const groups = svgDoc.getElementsByTagName('g');
          if (groups.length > 0) {
            polygons = groups[0].getElementsByTagName('polygon');
          }
        }
        const viewBoxAttr = svgDoc.documentElement.getAttribute('viewBox');
        if (viewBoxAttr) {
          this.viewBox = viewBoxAttr;
        }
        Array.from(paths).forEach(el => this.svgItems.push(el.id));
        Array.from(polygons).forEach(el => this.svgItems.push(el.id));
        this.pathData = Array.from(paths);
        this.polygonData = Array.from(polygons);
      } catch (error) {
        console.error(error);
      }

    },
    getClass(path) {
      return this.item.guid === path.id ? '_selected' : this.availIds.includes(path.id) ? '_avail' : null;
    },
    scale(n) {
      this.zoom += n;
    },
    closeSwipeZoomInfo() {
      Cookies.set('closedSwipeZoomInfoParkingPlace', 1);
      this.noteOpen = false;
    },
    setItem(guid) {
      this.item = this.availItems.find(item => item.guid === guid);
    },
    scalingHandler(e) {
      const zoomData = markRaw(e);
      this.zoom = Math.round(zoomData.scale * 10) / 10;
    },
    createTooltip(e, guid) {
      if (!e.target || (!e.target.classList.contains('_avail') && !e.target.classList.contains('_selected'))) return;
      const target = e.target;
      this.currentFlatData = this.availItems.find(item => item.guid === guid);

      this.$nextTick(function() {
        const tpl = this.$refs.tooltip.outerHTML.replace('_tooltip', '');
        if (target._tippy) target._tippy.destroy();
        if (target.classList.contains('_avail')) tippy(target, {
          allowHTML: true,
          //interactive: true,
          appendTo: () => document.body,
          offset: [0, 12],
          //interactiveDebounce: 10000000,
          theme: 'd-aux-rooms-popup',
          placement: 'top',
          animation: 'fade',
          content: tpl,
          arrow: false,
          touch: false,

        });
      });

    },
  },
};
</script>
