<template>
  <div class="d-contacts-simple-office">
    <div class="d-contacts-simple-office__title">{{ data.name }}</div>
    <div class="d-contacts-simple-office__text" v-html="data.text"></div>
    <div class="d-contacts-simple-office__bottom">
      <a v-if="data.phone" :href="`tel:${data.phone}`" class="d-contacts-simple-office__phone">{{ data.phone }}</a>
      <div v-if="data.time" class="d-contacts-simple-office__time">{{ data.time }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DContactsOfficeSimple",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>