<template>
  <div class="d-cashback-wrapper">
    <d-cashback-sms-form v-if="showSmsForm" :error="smsError" @submit="submitSms" @requestCode="submit" @resetError="resetError"/>
    <form class="d-cashback-form" :class="{ _success: success || errored || showSmsForm }" action="" method="" data-form="" @submit.prevent="submit($event)" autocomplete="off">
      <div class="d-cashback-form__title">{{ formTitle }}</div>
      <div class="d-cashback-form__row">
        <div class="d-cashback-form__item">
          <div class="d-input _cashback" :class="{ _focus: focusInput === 'bankName', _error: v$.form.bankName.$errors.length, _done: v$.form.bankName.$dirty && !v$.form.bankName.$errors.length }">
            <div class="d-input__label">Наименование Банка получателя *</div>
            <input class="d-input__field" type="text" name="bankName" @focus="focusInput = 'bankName'" v-model="form.bankName" @blur="blurInput('bankName')">
            <div class="d-input__error" v-if="v$.form.bankName.$error">Заполните поле</div>
          </div>
        </div>
      </div>
      <div class="d-cashback-form__row">
        <div class="d-cashback-form__item">
          <div class="d-input _cashback" :class="{  _focus: focusInput === 'bankBik', _error: v$.form.bankBik.$errors.length, _done: v$.form.bankBik.$dirty && !v$.form.bankBik.$errors.length  }">
            <div class="d-input__label">БИК Банка получателя *</div>
            <input ref="bankBik" class="d-input__field" type="text" name="bankBik" @focus="focusInput = 'bankBik'" maxlength="9" inputmode="numeric" v-model="form.bankBik" @blur="blurInput('bankBik')">
            <div class="d-input__error" v-if="v$.form.bankBik.$error">БИК Банка получателя содержит 9 цифр</div>
          </div>
        </div>
      </div>
      <div class="d-cashback-form__row">
        <div class="d-cashback-form__item">
          <div class="d-input _cashback" :class="{ _focus: focusInput === 'fio', _error: v$.form.fio.$errors.length, _done: v$.form.fio.$dirty && !v$.form.fio.$errors.length  }">
            <div class="d-input__label">Получатель ФИО полностью *</div>
            <input class="d-input__field" type="text" name="fio" @focus="focusInput = 'fio'" v-model="form.fio" @blur="blurInput('fio')">
            <div class="d-input__error" v-if="v$.form.fio.$error">Заполните поле</div>
          </div>
        </div>
      </div>
      <div class="d-cashback-form__row">
        <div class="d-cashback-form__item _half">
          <div class="d-input _cashback" :class="{ _focus: focusInput === 'bankAccount', _error: v$.form.bankAccount.$errors.length, _done: v$.form.bankAccount.$dirty && !v$.form.bankAccount.$errors.length }">
            <div class="d-input__label">Счет получателя в банке получателя *</div>
            <input ref="bankAccount"
                   class="d-input__field"
                   type="text"
                   maxlength="20"
                   name="bankAccount"
                   @focus="focusInput = 'bankAccount'"
                   inputmode="numeric"
                   v-model="form.bankAccount"
                   @blur="blurInput('bankAccount')">
            <div class="d-input__error _left" v-if="v$.form.bankAccount.$error">Счет получателя в банке получателя содержит 20 цифр</div>
          </div>
        </div>
        <div class="d-cashback-form__item _half">
          <div class="d-input _cashback">
            <div class="d-input__label">ИНН получателя</div>
            <input class="d-input__field" type="number" name="inn" inputmode="numeric" maxLength="12" v-model="v$.form.inn.$model" @input="formatNumberInp($event)">
          </div>
        </div>
      </div>
      <div class="d-cashback-form__row _agree">
        <div class="d-checkbox _agree" :class="{_error: v$.form.agreeRules.$error}">
          <input class="d-checkbox__input" type="checkbox" name="agreeRules" id="agreeRules" v-model="v$.form.agreeRules.$model" @change="v$.form.agreeRules.$touch()">
          <label class="d-checkbox__label" for="agreeRules">Ознакомлен и согласен с
            <button type="button" @click="openPopup('rules')">Правилами</button>
          </label></div>
      </div>
      <div class="d-cashback-form__row _agree">
        <div class="d-checkbox _agree" :class="{_error: v$.form.agreeData.$error}">
          <input class="d-checkbox__input" type="checkbox" name="agreeData" id="agreeData" @change="v$.form.agreeData.$touch()" v-model="v$.form.agreeData.$model">
          <label class="d-checkbox__label" for="agreeData">
            Даю согласие на обработку предоставленных персональных данных в порядке, указанном в
            <button type="button" @click="openPopup('data')">Согласии</button>
            . Настоящим подтверждаю, что ознакомлен с текстом Согласия, его положения мне разъяснены и понятны
          </label></div>
      </div>
      <div class="d-cashback-form__row _agree">
        <d-recaptcha-info :errored="errored" :error-text="errorText"/>
      </div>
      <div class="d-cashback-form__row _button">
        <button type="submit" class="d-cashback-form__button d-button _blue"><span>Отправить</span></button>
      </div>
    </form>
    <div class="d-cashback-success" :class="{ _show: success }" data-form-success="data-form-success">
      <div class="d-cashback-success__title">Ваши данные успешно отправлены. Средства поступят на&nbsp;счет в течение времени,
        <nobr>указанного в
          <button type="button" @click="openPopup('rules')">Правилах</button>
        </nobr>
        <br>
        <br>
        Для внесения изменений просим Вас связаться с менеджером компании «ДОНСТРОЙ»
      </div>
      <a class="d-cashback-success__button d-button _dark" href="/"><span>На главную</span></a>
    </div>
    <div class="d-cashback-success" :class="{ _error: errored && !showSmsForm }" data-form-success="data-form-success">
      <div class="d-cashback-success__title">
        Ваша заявка не принята.
        Для решения проблемы свяжитесь с нами по телефону.
      </div>
      <a class="d-cashback-success__button d-button _dark" href="/"><span>На главную</span></a>
    </div>
    <d-popup-disclaimer v-if="currentPopupContent && showPopup" v-model="currentPopupContent" :data-title="currentPopupContent.title" :data-text="currentPopupContent.text" @closed="e"></d-popup-disclaimer>
  </div>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, sameAs, helpers, minLength, maxLength} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import {toRaw} from 'vue';
import Inputmask from 'inputmask/lib/inputmask';
import DPopupDisclaimer from '@components/organisms/DPopupDisclaimer.vue';
import DCashbackSmsForm from '@components/molecules/DCashbackSmsForm.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

const bankAccountHelper = (value) => {
  const numValue = value.replace(/[^0-9,\s]/g, '');
  return numValue.length === 20;
};
const bankBikHelper = (value) => {
  const numValue = value.replace(/[^0-9,\s]/g, '');
  return numValue.length === 9;
};
export default {
  name: 'DCashback',
  components: {
    DRecaptchaInfo,
    DPopupDisclaimer,
    DCashbackSmsForm,
  },
  props: {
    dataActionUrl: {
      type: String,
      default: '',
    },
    dataFormContent: {
      type: String,
      default: '',
    },
    userSuccess: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        bankName: null,
        bankBik: null,
        bankAccount: null,
        fio: '',
        inn: null,
        agreeRules: false,
        agreeData: false,
      },
      success: false,
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
      showPopup: false,
      formContent: null,
      currentPopupContent: null,
      userId: null,
      focusInput: null,
      showSmsForm: false,
      smsError: '',
      smsErrorText: '',
    };
  },
  computed: {
    rulesPopupContent() {
      if (this.formContent && this.formContent.rulesPopup) return this.formContent.rulesPopup;
      else return null;
    },
    dataPopupContent() {
      if (this.formContent && this.formContent.dataPopup) return this.formContent.dataPopup;
      else return null;
    },
    formTitle() {
      if (this.formContent && this.formContent.formTitle) return this.formContent.formTitle;
      else return null;
    },
    otherInputs() {
      if (this.formContent && this.formContent.inputs) return this.formContent.inputs;
      else return null;
    },
  },
  created() {
    this.getFormContent();
  },
  mounted() {
    this.success = this.userSuccess;
    this.$nextTick(() => {
      Inputmask('99999999999999999999').mask($(this.$refs.bankAccount));
      Inputmask('999999999').mask($(this.$refs.bankBik));
    });
    this.setUserId();
  },
  methods: {
    async getFormContent() {
      try {
        let response = await this.axios.get(this.dataFormContent);

        this.formContent = response.data;
      } catch (err) {
        console.log('error load form content', err);
      }
    },
    async submit(e, code) {
      if (!code) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      this.v$.$touch();
      if (!this.v$.$invalid) {
        const otherInputsData = {};

        if (this.otherInputs) {
          this.otherInputs.forEach((inp) => {
            otherInputsData[inp.name] = inp.value;
          });
        }

        const formData = Object.assign({}, this.form, otherInputsData, {userId: this.userId, code: code});
        console.log('%c sendData formdata = ', 'color: yellow', formData);

        try {
          await this.$recaptchaLoaded();
          formData.token = await this.$recaptcha(this.dataType);
          const response = await this.axios.post(this.dataActionUrl, formData);
          // console.log('response', response)
          if (response.data.code_send) {
            this.showSmsForm = true;
          }
          if (response.data.success) {
            console.log('success');
            this.resetForm();
            this.success = true;
            this.showSmsForm = false;
            this.errored = false;
          }
          if (response.data.sms_error) {
            this.smsError = response.data.sms_error;
          }
          if (response.data.error) {
            this.showSmsForm = false;
            this.errored = true;
            this.errorText = response.data.error;
          }
          // else {
          //   console.log('error 1')
          //   console.error(response.data);
          //   this.errored = true;
          // }
        } catch (error) {
          console.log('error 2');
          console.error(error);
          this.errored = true;
        } finally {
          // this.loading = false;
        }
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.bankName = null;
      this.form.bankBik = null;
      this.form.bankAccount = null;
      this.form.fio = '';
      this.form.inn = null;
      this.form.agreeRules = false;
      this.form.agreeData = false;
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
    openPopup(type) {
      if (type === 'rules') this.currentPopupContent = this.rulesPopupContent;
      if (type === 'data') this.currentPopupContent = this.dataPopupContent;
      this.showPopup = true;
    },
    setUserId() {
      const url = new URL(location.href);
      const userIdFromUrl = url.searchParams.get('id');
      if (userIdFromUrl) this.userId = userIdFromUrl;
    },
    blurInput(type) {
      this.v$.form[type].$touch();
      this.focusInput = null;
    },
    formatNumberInp(evt) {
      const maxLength = 12;
      const length = evt.target.value.length;
      if (length > maxLength) {
        this.form.inn = evt.target.value.slice(0, maxLength);
      }
      else {
        evt.target.value = evt.target.value.replace(/\D/g, '');
      }
    },
    submitSms(code) {
      console.log('submit code', code);
      this.submit(null, code);
    },
    resetError() {
      this.smsError = '';
    },
  },
  validations() {
    return {
      form: {
        bankName: {
          required: helpers.withMessage('Заполните поле', required),
        },
        bankBik: {
          required: helpers.withMessage('Заполните поле', bankBikHelper),
        },
        bankAccount: {
          required: helpers.withMessage('Счет получателя в банке получателя содержит 20 цифр', bankAccountHelper),
        },
        fio: {
          required: helpers.withMessage('Заполните поле', required),
        },
        inn: {},
        agreeRules: {
          required,
          sameAs: sameAs(true),
        },
        agreeData: {
          required,
          sameAs: sameAs(true),
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
