<template>
  <div class="d-infra-map-mobile">
    <div class="d-object-single-location-types _mobile">
      <div class="d-object-single-location-types__item _outside">Внешняя</div>
      <div class="d-object-single-location-types__item _inside">Внутренняя</div>
    </div>
    <div ref="infraMapMobile" class="d-object-single-location-map__map"></div>
    <button class="d-object-single-location-map__button d-button _blue _withImg _left d-infra-map-mobile__btn-filter" @click="showFilter = true">
      <img src="/assets/blueant/assets/img/d/icon_filter.svg" />
      <span class="mobile">Фильтр</span>
      <samp v-show="countSelectedCategories" class="d-object-single-location-map__count">{{ countSelectedCategories }}</samp>
    </button>
    <d-popup-simple v-model="showFilter" :with-close="false">
      <d-map-infra-legend v-if="categories.length" :data="categories" @changeCategory="changeCategory" mod="mobile" @close="showFilter = false"/>
    </d-popup-simple>
    <div v-if="showMarkerInfo && currentPointContent" class="d-infra-map-mobile__point" :class="{ _offset: !swipeInfoHide }">
      <div class="d-map-infra-point _mobile">
        <div class="d-map-infra-point__title">
          <span v-html="currentPointContent"></span>
          <button class="d-map-infra-point__close" @click="closePointInfo">
            <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" alt="">
          </button>
        </div>
      </div>
    </div>
    <div class="d-index-geography-map-point-info _infra" :class="{_show: showMarkerProjectInfo, _offset: !swipeInfoHide}">
      <div>
        <a class="d-index-geography-map-point-info__name" href="#">{{ markerInfoMobileData.name }}</a>
      </div>
      <div class="d-index-geography-map-point-info__content">
        <div class="d-index-geography-map-point-info__address">{{ markerInfoMobileData.text }}</div>
        <div class="d-index-geography-map-point-info__price">{{ markerInfoMobileData.price }}</div>
      </div>
      <button class="d-index-geography-map-point-info__close" @click="closeMarkerInfoMobile"><img src="/assets/blueant/assets/img/d/icon_close.svg"></button>
    </div>
    <div v-if="!swipeInfoHide" class="d-infra-map-mobile__swipe">
      <div class="d-swipe-info">
        <img src="/assets/blueant/assets/img/d/icon_swipe1.svg" />
        <div>Используйте жесты для перемещения и масштабирования
          карты</div>
        <button @click="closeSwipeInfo(false)">
          <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {toRaw} from "vue";
import DPopupSimple from "./DPopupSimple.vue";
import DMapInfraLegend from "./DMapInfraLegend.vue";
import Cookies from "js-cookie";

export default {
  name: "DMapInfraMobile",
  components: {
    DPopupSimple,
    DMapInfraLegend
  },
  props: {
    infraData: {
      type: Object,
      default: () => {}
    },
    pointProject: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mapInstance: null,
      points: [],
      showFilter: false,
      filteredPoints: [],
      countSelectedCategories: 0,
      currentPointContent: null,
      markerInfoMobileData: {
        name: null,
        text: null,
        price: null,
        img: null
      },
      showMarkerProjectInfo: false,
      showMarkerInfo: false,
      swipeInfoHide: null
    }
  },
  computed: {
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      ymaps.ready(this.mapInit);
    })
    this.swipeInfoHide = Number(Cookies.get('closeSwipeInfra')) === 1
  },
  methods: {
    mapInit() {
      this.mapInstance = new ymaps.Map(this.$refs.infraMapMobile, {
        center: [55.769761, 37.562889],
        zoom: 17,
        controls: []
      });

      this.addMarkers()
    },
    createMarker(point) {
      const coords = Object.values(point.coords);

      let marker_html;

      const html = `
        <div class="d-map-infra-point ${point.type === 'inside' ? '_inside' : ''}">
            <div class="d-map-infra-point__img">${point.icon}</div>
            <div class="d-map-infra-point__title">
                ${point.title}
                <button class="d-map-infra-point__close">
                    <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" alt="">
                </button>
            </div>

        </div>
      `

      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-infra-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-infra-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });

      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [48, 48],
            iconImageOffset: [-24, -48],
            zIndex: 730,
            zIndexActive:  731,
            zIndexHover: 731,
            iconPane: 'overlaps'
          });

      marker.events
          .add('mouseenter', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.removeClass('_hover');
            }

          })
          .add('click', (e) => {
            if (this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $('.d-map-infra-point').removeClass('_active').removeClass('_hover')
              $inner_el.addClass('_hover');
              this.showMarkerInfo = true
              this.currentPointContent = point.title
              this.showMarkerProjectInfo = false
              $('.d-map-point').removeClass('_active')
            }
          })

      return marker
    },
    createMarkerProject(point) {
      const coords = Object.values(point.coords)
      let marker_html;

      const html = `
        <div class="d-map-point _object">
            <div class="d-map-point__img">
                <img src="${point.img}" alt="">
                <div class="d-map-point-info">
                <div class="d-map-point-info__name">${point.name}</div>
                <div class="d-map-point-info__text">${point.text}</div>
                <div class="d-map-point-info__price">${point.price}</div>
              </div>
              </div>
        </div>
      `
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function () {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function () {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        }
      });
      const marker = new ymaps.Placemark(coords,
          {
            hintContent: '',
            balloonContent: ''
          }, {
            preset: 'islands#circleDotIcon',
            iconLayout: markerLayout,
            iconImageHref: '/assets/i/blank.gif',
            iconImageSize: [8, 8],
            iconImageOffset: [-4, -8],
            zIndex: 730,
            zIndexActive:  731,
            zIndexHover: 731,
            iconPane: 'overlaps'
          });

      marker.events
          .add('mouseenter', () => {
            if (!this.isTouchDevice) {
              const $inner_el = $(marker_html);
              $inner_el.addClass('_hover');
            }

          })
          .add('mouseleave', () => {
            const $inner_el = $(marker_html);
            $inner_el.removeClass('_hover');
          })
          //.add('click', () => {
          //  if (this.isTouchDevice) {
          //    const $inner_el = $(marker_html);
          //
          //    $('.d-map-point').removeClass('_active')
          //    this.showMarkerProjectInfo = true
          //
          //    $inner_el.addClass('_active').removeClass('_hover');
          //    $('.d-map-infra-point').removeClass('_active').removeClass('_hover')
          //    this.showMarkerInfo = false
          //    for (const p in this.markerInfoMobileData) {
          //      this.markerInfoMobileData[p] = point[p]
          //    }
          //  }
          //})
      return marker
    },
    addMarkers(setBounds = true) {
      const placemarks = []
      const clusterer = new ymaps.Clusterer({
        clusterIcons: [
          {
            size: [32, 32],
            offset: [-32, -32],
          },
        ],
        clusterBalloonContentLayout: null,
        groupByCoordinates: false,
        clusterDisableClickZoom: false,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
            '{% if properties.geoObjects.length> 9 %}' +
            '<div class="d-map-infra-cluster">9+</div>' +
            '{% else %}' +
            '<div class="d-map-infra-cluster">{{ properties.geoObjects.length }}</div>' +
            '{% endif %}'
        ),
      });

      clusterer.options.set({
        gridSize: 170,
        clusterDisableClickZoom: false,
        zIndex: 1200,
      });

      const rawMap = toRaw(this.mapInstance)

      if (this.infraData) {
        this.points.forEach((point) => {
          placemarks.push(this.createMarker(point))
        })

        clusterer.add(placemarks);

        rawMap.geoObjects
            .add(clusterer);

        if (setBounds) {
          rawMap.setBounds(rawMap.geoObjects.getBounds(), {
            zoomMargin: 50
          });
        }

      }
      rawMap.geoObjects.add(this.createMarkerProject(this.pointProject))
    },
    setPoints(data) {
      const result = []
      data.forEach((category) => {
        category.points.forEach((point) => {
          result.push(Object.assign({}, point, { icon: category.icon }))
        })
      })
      return result
    },
    changeCategory(categories) {
      this.countSelectedCategories = categories.length
      this.filterPoints(categories)
    },
    filterPoints(categories) {
      categories.length ? this.filteredPoints = this.infraData.filter((category) => categories.includes(category.label)) : this.filteredPoints = this.infraData
      this.points = this.setPoints(this.filteredPoints)

      const rawMap = toRaw(this.mapInstance)

      if (rawMap) {
        rawMap.geoObjects
            .removeAll()
        this.addMarkers(false)
      }
    },
    closeMarkerInfoMobile() {
      this.showMarkerProjectInfo = false;
      $('.d-map-point').removeClass('_active')
    },
    closePointInfo() {
      this.showMarkerInfo = false
      this.currentPointContent = null
      $('.d-map-infra-point').removeClass('_active').removeClass('_hover')
    },
    closeSwipeInfo() {
      Cookies.set('closeSwipeInfra', 1)
      this.swipeInfoHide = true
    }
  }
}
</script>

<style scoped>

</style>
