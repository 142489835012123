export const maskList = [
    { code: '+61', slug: 'au', name: 'Австралия', mask: '# #### ####' },
    { code: '+43', slug: 'at', name: 'Австрия', mask: '### ### ####' },
    {
        code: '+994',
        slug: 'az',
        name: 'Азербайджан',
        mask: '## ### ## ##'
    },
    {
        code: '+358',
        slug: 'ax',
        name: 'Аландские о-ва',
        mask: '### ### ## ##'
    },
    { code: '+355', slug: 'al', name: 'Албания', mask: '### ### ###' },
    { code: '+213', slug: 'dz', name: 'Алжир', mask: '## ### ####' },
    { code: '+376', slug: 'ad', name: 'Андорра', mask: '### ###' },
    { code: '+54', slug: 'ar', name: 'Аргентина', mask: '### ### ####' },
    { code: '+374', slug: 'am', name: 'Армения', mask: '## ### ###' },
    { code: '+973', slug: 'bh', name: 'Бахрейн', mask: '#### ####' },
    { code: '+375', slug: 'by', name: 'Беларусь', mask: '## ### ## ##' },
    { code: '+32', slug: 'be', name: 'Бельгия', mask: '### ### ###' },
    { code: '+229', slug: 'bj', name: 'Бенин', mask: '## ## ####' },
    { code: '+359', slug: 'bg', name: 'Болгария', mask: '### ### ###' },
    {
        code: '+387',
        slug: 'ba',
        name: 'Босния и Герцеговина',
        mask: '## ####'
    },
    {
        code: '+44',
        slug: 'gb',
        name: 'Великобритания',
        mask: '## #### ####'
    },
    { code: '+36', slug: 'hu', name: 'Венгрия', mask: '### ### ###' },
    { code: '+58', slug: 've', name: 'Венесуэла', mask: '### ### ####' },
    { code: '+49', slug: 'de', name: 'Германия', mask: '### ###' },
    { code: '+30', slug: 'gr', name: 'Греция', mask: '### ### ####' },
    { code: '+995', slug: 'ge', name: 'Грузия', mask: '### ### ###' },
    { code: '+45', slug: 'dk', name: 'Дания', mask: '## ## ## ##' },
    { code: '+20', slug: 'eg', name: 'Египет', mask: '### ### ####' },
    { code: '+972', slug: 'il', name: 'Израиль', mask: '# ### ####' },
    { code: '+353', slug: 'ie', name: 'Ирландия', mask: '### ### ###' },
    { code: '+354', slug: 'is', name: 'Исландия', mask: '### ####' },
    { code: '+34', slug: 'es', name: 'Испания', mask: '### ### ###' },
    { code: '+39', slug: 'it', name: 'Италия', mask: '# ### #####' },
    { code: '+7', slug: 'kz', name: 'Казахстан', mask: '### ### ## ##' },
    { code: '+1', slug: 'ca', name: 'Канада', mask: '### ### ####' },
    { code: '+974', slug: 'qa', name: 'Катар', mask: '#### ####' },
    { code: '+357', slug: 'cy', name: 'Кипр', mask: '## ### ###' },
    { code: '+996', slug: 'kg', name: 'Киргизия', mask: '### ### ###' },
    { code: '+86', slug: 'cn', name: 'Китай', mask: '### #### ###' },
    { code: '+850', slug: 'kp', name: 'КНДР', mask: '### ###' },
    { code: '+965', slug: 'kw', name: 'Кувейт', mask: '#### ####' },
    { code: '+371', slug: 'lv', name: 'Латвия', mask: '## ### ###' },
    { code: '+266', slug: 'ls', name: 'Лесото', mask: '# ### ####' },
    { code: '+370', slug: 'lt', name: 'Литва', mask: '### ## ###' },
    {
        code: '+423',
        slug: 'li',
        name: 'Лихтенштейн',
        mask: '### ### ####'
    },
    { code: '+352', slug: 'lu', name: 'Люксембург', mask: '### ### ###' },
    { code: '+356', slug: 'mt', name: 'Мальта', mask: '#### ####' },
    { code: '+212', slug: 'ma', name: 'Марокко', mask: '## #### ###' },
    { code: '+373', slug: 'md', name: 'Молдова', mask: '#### ####' },
    { code: '+377', slug: 'mc', name: 'Монако', mask: '## ### ###' },
    { code: '+976', slug: 'mn', name: 'Монголия', mask: '## ## ####' },
    { code: '+31', slug: 'nl', name: 'Нидерланды', mask: '## ### ####' },
    { code: '+47', slug: 'no', name: 'Норвегия', mask: '### ## ###' },
    { code: '+971', slug: 'ae', name: 'ОАЭ', mask: '# ### ####' },
    { code: '+968', slug: 'om', name: 'Оман', mask: '## ### ###' },
    { code: '+48', slug: 'pl', name: 'Польша', mask: '### ### ###' },
    { code: '+351', slug: 'pt', name: 'Португалия', mask: '## ### ####' },
    { code: '+7', slug: 'ru', name: 'Россия', mask: '### ### ## ##' },
    { code: '+40', slug: 'ro', name: 'Румыния', mask: '## ### ####' },
    {
        code: '+966',
        slug: 'sa',
        name: 'Саудовская Аравия',
        mask: '# ### ####'
    },
    {
        code: '+389',
        slug: 'mk',
        name: 'Северная Македония',
        mask: '## ### ###'
    },
    { code: '+381', slug: 'rs', name: 'Сербия', mask: '## ### ####' },
    { code: '+65', slug: 'sg', name: 'Сингапур', mask: '#### ####' },
    { code: '+421', slug: 'sk', name: 'Словакия', mask: '### ### ###' },
    { code: '+386', slug: 'si', name: 'Словения', mask: '## ### ###' },
    {
        code: '+1',
        slug: 'us',
        name: 'Соединенные Штаты',
        mask: '### ### ####'
    },
    {
        code: '+992',
        slug: 'tj',
        name: 'Таджикистан',
        mask: '## ### ####'
    },
    {
        code: '+993',
        slug: 'tm',
        name: 'Туркменистан',
        mask: '# ### ####'
    },
    { code: '+90', slug: 'tr', name: 'Турция', mask: '### ### ####' },
    { code: '+998', slug: 'uz', name: 'Узбекистан', mask: '## ### ####' },
    { code: '+380', slug: 'ua', name: 'Украина', mask: '## ### ## ##' },
    {
        code: '+358',
        slug: 'fi',
        name: 'Финляндия',
        mask: '### ### ## ##'
    },
    { code: '+33', slug: 'fr', name: 'Франция', mask: '### ### ###' },
    { code: '+385', slug: 'hr', name: 'Хорватия', mask: '## ### ###' },
    { code: '+382', slug: 'me', name: 'Черногория', mask: '## ### ###' },
    { code: '+420', slug: 'cz', name: 'Чехия', mask: '### ### ###' },
    { code: '+56', slug: 'cl', name: 'Чили', mask: '# #### ####' },
    { code: '+41', slug: 'ch', name: 'Швейцария', mask: '## ### ####' },
    { code: '+46', slug: 'se', name: 'Швеция', mask: '## ### ####' },
    { code: '+372', slug: 'ee', name: 'Эстония', mask: '### ####' }
]
