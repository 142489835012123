<template>
  <d-popup v-slot="{ close }" v-bind="$attrs">
    <div class="d-callMe__form" :class="modClass">
      <div class="d-callMe__top">
        <div class="d-callMe__title">{{ strings.callMeFormTitle }}</div>
        <div class="d-callMe__subtitle" v-html="strings.callMeFormSubtitle">
        </div>
        <img src="/assets/blueant/assets/img/d/callMe_bg.jpg" class="d-callMe__bg">
      </div>
      <d-call-me-form-national :data-action-url="actionUrl" :data-mod="['popup',mod]" :data-props="props" @success="close"/>
    </div>
  </d-popup>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';
import DCallMeFormNational from '@components/organisms/DCallMeFormNational.vue';

export default {
  name: 'DCallMeFormPopupNational',
  components: {
    DCallMeFormNational,
    DPopup
  },
  props: {
    actionUrl: {
      type: String,
      required: true,
      default: '',
    },
    leadUrl: {
      type: [String, Boolean],
      default: false,
    },
    props: {
      type: [Object, Boolean, String],
      default: false,
    },
    mod: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    modClass() {
      const result = [];

      if (this.mod) {
        if (Array.isArray(this.mod)) {
          this.mod.forEach((mod) => {
            result.push(`_${mod}`)
          });
        } else {
          result.push(`_${this.mod}`)
        }
      }
      return result;
    },
  },
  created() {
    this.strings = window.dStrings
  }
};
</script>
