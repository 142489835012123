<template>
  <div class="d-favorites-notes" :class="{'_error': error}">
    <div class="d-favorites-notes__text" v-html="error ? titles.error : titles[mode]"></div>
    <a :href="links[mode]" class="d-favorites-notes__button d-button _dark"><span v-html="infos[mode]"></span></a>
  </div>
</template>

<script>
export default {
  name: 'DFavoritesInfo',
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titles: {
        error: 'При загрузке данных произошла ошибка.<br/>Попробуйте обновить страницу',
        flat: 'Вы не добавили в избранное ни одной&nbsp;квартиры.',
        parking: 'Вы не добавили в избранное ни одного машиноместа.',
        storeroom: 'Вы не добавили в избранное ни одного кладового&nbsp;помещения.',
      },
      infos: {
        flat: 'Выбрать квартиру',
        parking: 'Выбрать машиноместо',
        storeroom: 'Выбрать кладовую',
      },
      links: {
        flat: '/full-search/',
        parking: '/parking-search/',
        storeroom: '/storeroom-search/',
      },
    }
  }
};
</script>

<style scoped>

</style>
