<template>
  <div class="d-progress-popup">
    <div class="d-progress-popup__overlay" :class="{_show: openContent}" @click="openContent = false"></div>
    <button class="d-progress-popup__close d-button _close _blue" @click="close">
      <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
    </button>
    <div v-if="data.content && data.content.body" class="d-progress-popup__content" :class="{_show: openContent}">
      <button class="d-progress-popup__close-content d-button _small _close _blue" @click="openContent = false">
        <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
      </button>
      <div class="d-progress-popup__title"> {{ data.content.title }} </div>
      <div class="d-progress-popup__text" >
        <simplebar data-simplebar-auto-hide="true" class="d-progress-popup__text_scrollarea">
          <div class="d-progress-popup__scroll" :ref="`content_${id}`" v-html="data.content.body"></div>
        </simplebar>

      </div>
    </div>
    <div class="d-progress-popup__slider" :class="{_full: !data.content.body}">
      <d-progress-popup-slider :images="data.images" @open-content="openContent = true" :content="data.content.body"/>
    </div>
<!--    <div v-if="data.content.body" class="d-progress-popup__more">-->
<!--      <div class="d-button _white _border" @click="openContent = true"><span>Подробнее</span></div>-->
<!--    </div>-->

  </div>
</template>

<script>
import DProgressPopupSlider from "./DProgressPopupSlider.vue";
import simplebar from 'simplebar-vue';
export default {
  name: "DProgressPopup",
  components: {
    DProgressPopupSlider,
    simplebar,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      openContent: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
