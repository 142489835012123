<template>
  <div class="d-aux-rooms__result-hot-found">
    <div class="d-aux-rooms__result-hot-found_icon _reset"></div>
    <div class="d-aux-rooms__result-hot-found_text">
      По заданным параметрам ничего не найдено. Попробуйте&nbsp;сбросить фильтр или задать другие параметры.
    </div>
    <button class="d-button _dark d-aux-rooms__result-hot-found_button" @click="$emit('reset')"><span>Сбросить фильтры</span></button>
  </div>
</template>

<script>
export default {
  name: 'DSearchFilterEmpty',
  emits: ['reset'],
};
</script>

<style scoped>

</style>
