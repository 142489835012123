<template>
  <div class="d-docs">
    <div class="d-docs__col _header">
      <div class="d-docs__header">
        <div class="d-docs__title d-page-title">Документы проекта</div>
        <div class="d-tabs _whiteBg">
          <div class="d-tabs__buttons">
            <div class="d-tabs__buttonsInner">
              <button class="d-tabs__button _active" @click="changeType('docs')">Документы</button>
              <button class="d-tabs__button" :class="{_disabled: !booklets || !booklets.length}" @click="changeType('booklets')">Буклеты</button>
              <div class="d-tabs__buttonBg" style=""></div>
            </div>
          </div>
        </div>
        <d-select class="d-docs__categories" v-if="categories && type === 'docs'" v-model="currentCategory" :data-options="categories"></d-select>
      </div>
      <div v-if="sites" class="d-docs__links _desktop">
        <a v-for="site in sites" :key="site.id" class="d-docs__link" :href="site.link" target="_blank">{{ site.name }}</a>
      </div>
    </div>
    <div class="d-docs__col _list">
      <template v-if="type === 'docs'">
        <div v-if="items" class="d-docs__list">
          <div v-for="doc in items" :key="doc.id" class="d-doc">
            <div class="d-doc__name">{{ doc.name }}</div>
            <div class="d-doc__bottom">
              <div class="d-doc__size"><span class="d-doc__sizeFormat">PDF</span>,&nbsp;<span>{{ doc.size }} мб</span></div>
            </div>
            <div class="d-doc__button d-button _dark"><span>Скачать</span></div>
            <a class="d-doc__link" target="_blank" :href="doc.path"></a>
          </div>
        </div>
      </template>
      <template v-if="type === 'booklets'">
        <div v-if="booklets" class="d-docs__list">
          <div v-for="booklet in booklets" :key="booklet.id" class="d-doc">
            <div class="d-doc__name">{{ booklet.name }}</div>
            <div class="d-doc__bottom">
              <div class="d-doc__size"><span class="d-doc__sizeFormat">PDF</span>,&nbsp;<span>{{ booklet.size }} мб</span></div>
            </div>
            <div class="d-doc__button d-button _dark"><span>Скачать</span></div>
            <a class="d-doc__link" target="_blank" :href="booklet.path"></a>
          </div>
        </div>
      </template>

    </div>
    <div v-if="sites" class="d-docs__links _tablet">
      <a v-for="site in sites" :key="site.id" class="d-docs__link" :href="site.link" target="_blank">{{ site.name }}</a>
    </div>





  </div>
</template>

<script>
import DSelect from "@components/molecules/DSelect.vue";
export default {
  name: "DObjectDocs",
  components: {
    DSelect
  },
  props: {
    docsApiUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      categories: null,
      sites: null,
      items: null,
      currentCategory: null,
      type: 'docs',
      booklets: null
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    window.tabsWidthStart();
  },
  watch: {
    currentCategory() {
      this.getDocItems()
    }
  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(this.docsApiUrl);

        this.categories = response.data.docs
        this.sites = response.data.sites
        this.booklets = response.data.booklets

        this.setCategoryInit()

        this.getDocItems(this.categories)
      } catch(err) {
        console.log('error load infra map points', err);
      }
    },
    getDocItems() {
      const result = []
      const currentCategory = this.categories.find((cat) => cat.value === this.currentCategory)
      if (currentCategory) {
        this.items = currentCategory.items;
      } else {
        this.categories.forEach((cat) => {
          result.push(cat.items)
          this.items = result.flat()
        })
      }


    },
    setCategoryInit() {
      const initCategory = this.categories.find((cat) => cat.init)
      if (initCategory) {
        this.currentCategory = initCategory.value
      }
    },
    changeType(value) {
      this.type = value
    }
  }
}
</script>

<style scoped>

</style>
