<template>
  <div class="d-commercial-list" :class="classList">
    <template v-if="mod === 'skeleton'">
      <div class="d-commercial-list__item _img">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-list__item _name">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-list__item">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-list__item">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-list__item">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-list__item _price">
        <div class="skeleton-field"></div>
      </div>
    </template>
    <template v-else>
      <div class="d-commercial-list__item _img">
        <img :src="data.img" alt=""/>
      </div>
      <div class="d-commercial-list__item _name">{{ data.name }}</div>
      <div class="d-commercial-list__item">{{ data.purpose }}</div>
      <div class="d-commercial-list__item">{{ data.location }}</div>
      <div class="d-commercial-list__item">{{ data.area }} м²</div>
      <div v-if="data.price" class="d-commercial-list__item _price">{{ data.price.toLocaleString('ru-RU') }} ₽</div>
      <div v-else class="d-commercial-list__item _price">По запросу</div>
      <a class="d-commercial-list__link" :href="data.url"></a>
    </template>
  </div>
</template>

<script>
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: "DCommercialRow",
  props: {
    mod: {
      type: [Array, String],
      default: '',
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
  },
}
</script>

<style scoped>

</style>