<template>
  <div class="d-projects-map">
    <div class="d-projects-map__content d-container">
      <div class="d-projects-map__available d-tabs _whiteBg">
        <div class="d-tabs__buttons">
          <div class="d-tabs__buttonsInner">
            <button class="d-tabs__button" :class="{_active: availFilter}" @click="chooseAvailFilter(true)">В продаже</button>
            <button class="d-tabs__button" :class="{_active: !availFilter}" @click="chooseAvailFilter(false)">Все</button>
            <div class="d-tabs__buttonBg"></div>
          </div>
        </div>
      </div>
      <d-filter-item v-if="!initial"
                     ref="locationFilter"
                     id="projects-place"
                     :is-active="locationFilterActive"
                     data-title="Расположение"
                     class="d-projects-map__choosePlace _location _projects-map"
                     :tags="locationTags"
                     :screen-xs="false"
                     :alwaysOpen="false"
                     @resetFilter="resetFilter">
        <div class="d-tabs _filter">
          <div class="d-tabs__buttons">
            <div class="d-tabs__buttonsInner">
              <button class="d-tabs__button" :class="{_active: locationFilter === ''}" @click="chooseLocationFilter()">Все</button>
              <button class="d-tabs__button" :class="{_active: locationFilter === 'metro'}" @click="chooseLocationFilter('metro')">Метро</button>
              <button class="d-tabs__button" :class="{_active: locationFilter === 'district'}" @click="chooseLocationFilter('district')">Район</button>
              <div class="d-tabs__buttonBg"></div>
            </div>
          </div>
          <div class="d-tabs__content" :class="{_active: locationFilter === ''}">
            <d-filter-search-list placeholder="Метро или район..."
                                  :items="locationItems"
                                  v-model:search="locationSearch"
                                  v-model:checked="filterParamsLocation"
                                  list-id="all"
                                  :facets="[...facets.metro, ...facets.district]"
                                  :district-list="facets.district"
                                  :metro-list="facets.metro"/>
          </div>
          <div class="d-tabs__content" :class="{_active: locationFilter === 'metro'}">
            <d-filter-search-list placeholder="Метро..." :items="locationItems" v-model:search="locationSearch" v-model:checked="filterParamsLocation" type="metro" list-id="metro" :facets="facets.metro" :district-list="facets.district" :metro-list="facets.metro"/>
          </div>
          <div class="d-tabs__content" :class="{_active: locationFilter === 'district'}">
            <d-filter-search-list placeholder="Район..."
                                  :items="locationItems"
                                  v-model:search="locationSearch"
                                  v-model:checked="filterParamsLocation"
                                  type="district"
                                  list-id="district"
                                  :facets="facets.district"
                                  :district-list="facets.district"
                                  :metro-list="facets.metro"/>
          </div>
        </div>
      </d-filter-item>
      <button class="d-projects-map__buttonList d-button _white" @click="showFilter"><span>Смотреть списком</span><span class="tablet"><svg><use xlink:href="/assets/blueant/assets/sprite.svg#sprite-list"></use></svg>Списком </span>
      </button>
    </div>
    <div class="d-projects-map__map">
      <div ref="map" class="d-map-container" style="display: block; width: 100%; height: 100%; position: absolute; top: 0; left: 0;"></div>
      <div class="d-projects-map__zoom d-zoom-map">
        <button class="d-zoom-map__button _plus" @click="zoomMap(1)">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11V5H11V11H5V13H11V19H13V13H19V11H13Z"></path>
          </svg>
        </button>
        <button class="d-zoom-map__button _minus" @click="zoomMap(-1)">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 11V13L19 13V11L5 11Z"></path>
          </svg>
        </button>
      </div>
      <div v-if="currentObject" class="d-projects-map__about d-projects-map-about _show">
        <div class="d-projects-map-about__top">
          <img class="d-projects-map-about__img" :src="currentObject.img" alt="">
          <div v-if="currentObject.labels && currentObject.labels.length" class="d-projects-map-about__actions">
            <div v-for="label in currentObject.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background_color}">
              <div class="d-flat-action__name">{{ label.title }}</div>
              <a v-if="label.link" class="d-flat-action__link" :href="label.link"></a>
            </div>
          </div>
          <div class="d-projects-map-about__title">
            <div class="d-projects-map-about__name" v-html="currentObject.name"></div>
            <div class="d-projects-map-about__price" v-html="currentObject.price"></div>
          </div>
        </div>
        <div class="d-projects-map-about__content" data-simplebar="init">
          <div class="d-projects-map-about__contentInner" data-simplebar>
            <div class="d-projects-map-about__info">
              <div class="d-projects-map-about__address" v-html="currentObject.text"></div>
              <div v-if="currentObject.metro && currentObject.metro.length" class="d-projects-map-about__metro">
                <div v-for="label in currentObject.metro" class="d-metro">
                  <div v-for="color in label.color" class="d-metro__color" :style="{backgroundColor: color}"></div>
                  <div>{{ label.name }}</div>
                </div>
              </div>
              <div v-if="currentObject.time" class="d-projects-map-about__metro">
                <div class="d-metro-way _left"><img src="/assets/blueant/assets/img/d/icon_people.svg">{{ currentObject.time }}</div>
              </div>
              <div class="d-projects-map-about__facts d-object-single-about-facts _projects">
                <div v-if="currentObject.floors" class="d-object-single-about-facts__item">
                  <div class="d-object-single-about-facts__value">{{ currentObject.floors }}</div>
                  <div class="d-object-single-about-facts__label">Этажность</div>
                </div>
                <div v-if="currentObject.area" class="d-object-single-about-facts__item">
                  <div class="d-object-single-about-facts__value">{{ currentObject.area }}</div>
                  <div class="d-object-single-about-facts__label">Площадь квартир</div>
                </div>
                <div v-if="currentObject.ceils" class="d-object-single-about-facts__item">
                  <div class="d-object-single-about-facts__value">{{ currentObject.ceils }}</div>
                  <div class="d-object-single-about-facts__label">Потолки</div>
                </div>
              </div>
            </div>
            <div class="d-projects-map-about__bottom">
              <div class="d-projects-map-about__text" v-html="currentObject.description"></div>
              <a class="d-projects-map-about__more d-button _dark" :href="currentObject.link"><span>Подробнее</span></a>
            </div>
          </div>
        </div>
        <button class="d-projects-map-about__close d-button _close _blue" data-about-map-point-close @click="currentObject = null">
          <img src="/assets/blueant/assets/img/d/icon_close.svg"></button>
      </div>
      <button class="d-projects-map__close d-button _close _small _blue" @click="showFilter"><img src="/assets/blueant/assets/img/d/icon_close.svg"></button>
    </div>
  </div>
</template>

<script>
import {toRaw} from 'vue';
import {debounce as _debounce, isEqual as _isEqual} from 'lodash';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import getTouchDevice from '@utils/getTouchDevice';
import DSelect from '@components/molecules/DSelect.vue';
import DFilterItem from '@components/organisms/DFilterItem.vue';
import DFilterSearchList from '@components/molecules/DFilterSearchList.vue';

export default {
  name: 'DObjectsMap',
  components: {
    DSelect, DFilterItem, DFilterSearchList,
  },
  props: {
    pointsApiUrl: {
      type: String,
      default: '',
    },
    dataCenterPoint: {
      type: [String, Array],
      default: [55.769761, 37.562889],
    },
    dataBaseZoom: {
      type: [String, Number],
      default: 10,
    },
    dataRestrictArea: {
      type: String,
      default: 'false',
    },
    dataFilterParamsApi: {
      type: String,
      default: '/assets/json/home-filter-params.json',
    },
    dataFilterSpecsApi: {
      type: String,
      default: '/assets/json/home-filter-specs.json',
    },
    dataChooseParamsLink: {
      type: String,
      default: 'https://donstroy.moscow/full-search/',
    },
  },
  computed: {
    availFilter() {
      return this.filterParams.available;
    },
    locationTags() {
      return this.tags.filter(el => el.filter === 'location');
    },
    locationItems() {
      return [...this.filters.metro, ...this.filters.district];
    },
    locationFilterActive() {
      return this.filterParamsLocation.length > 0;
    },
    priceFilterActive() {
      return !_isEqual(this.filterParams.price, this.initialFilterParams.price);
    },
  },
  watch: {
    pointsLoaded() {
      this.$nextTick(() => {
        //console.log('%cDMap pointsLoaded() points = ', 'color: lime', this.points);
        ymaps.ready(this.mapInit);
        window.addEventListener('resize', this.onResize);
      });
    },
    isMapLocked() {
      const rawMap = toRaw(this.mapInstance);
      this.mapBehaviorToggle(rawMap);
    },
    filterParams: {
      handler(params) {
        if (!this.initial) {
          this.setTags(params);
          this.sendData();
        }
      },
      deep: true,
    },
    filterParamsLocation: {
      handler(params) {
        this.filterParams.metro = params.filter(x => this.facets.metro.includes(x));
        this.filterParams.district = params.filter(x => this.facets.district.includes(x));
      },
      deep: true,
    },
    filterShow(newValue) {
      if (newValue) this.locationFilterOpen = false;
    },
    locationFilterOpen(newValue) {
      if (newValue) this.locationFilter = '';
    },
  },
  data() {
    return {
      isTouchDevice: null,
      screenSM: null,
      screenXS: null,

      tippyTips: [],
      mapInstance: null,
      showMarkerInfoMobile: false,
      markerInfoMobileData: {
        name: null,
        text: null,
        price: null,
        img: null,
        link: null,
        target: null,
      },
      points: [],
      centerPoint: null,
      isMapLocked: false,
      pointsLoaded: false,
      mapMargins: [
        {
          bottom: 0,
          right: 0,
          height: 5,
          width: 250,
        },
      ],
      zoomMargin: [5, 5, 5, 480],
      zoomMarginSM: [88, 5, 235, 5],
      zoomMarginXS: [120, 5, 80, 5],
      filterParams: {
        metro: [],
        district: [],
        available: true,
      },
      initialFilterParams: {},
      filters: {
        metro: [],
        district: [],
        available: true,
      },
      locationSearch: '',
      locationFilter: '',
      filterParamsLocation: [],
      facets: {},
      tags: [],
      locationFilterOpen: false,
      initial: true,
      currentObject: null,
    };
  },
  created() {
    this.centerPoint = ((typeof this.dataCenterPoint).toLowerCase() === 'string') ? JSON.parse(this.dataCenterPoint) : this.dataCenterPoint;
    this.initFilters();
  },
  mounted() {
    if (this.screenXS) {
      this.mapMargins = [];
      this.zoomMargin = this.zoomMarginXS;
    }
    else if (this.screenSM || this.isTouchDevice) {
      this.mapMargins = [];
      this.zoomMargin = this.zoomMarginSM;
    }
    // костыль, который адекватно зумит карту со всеми метками при переключении табов (список/карта)
    document.addEventListener('showMap', () => {
      const rawMap = toRaw(this.mapInstance);
      rawMap.destroy();
      this.mapInit();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    createTooltip(target) {
      const tpl = target.dataset.tippy;
      const el = target.querySelector('.d-map-point__img');
      if (!el._tippy) tippy(el, {
        // trigger: 'click',
        allowHTML: true,
        //interactive: true,
        //interactiveDebounce: 999999999,
        appendTo: () => document.body,
        //offset: [0, 8],
        touch: false,
        theme: 'objects-map',
        placement: 'top',
        animation: 'fade',
        content: tpl,
        arrow: false,
        triggerTarget: [el, target],
      });
    },
    onResize() {
      this.screenXS = window.matchMedia('(max-width: 767px)').matches;
      this.screenSM = window.matchMedia('(max-width: 1279px)').matches;
      this.isTouchDevice = getTouchDevice();
      if (this.screenXS) this.isMapLocked = false;
    },
    lockToggle() {
      this.isMapLocked = !this.isMapLocked;
    },
    async initFilters() {
      await this.getSpecs();
      this.initFilterParams();
      await this.getFacets();
      this.initial = false;
      this.$nextTick(() => {
        window.tabsWidthStart();
        this.getPoints();
      });
    },
    resetFilter(tag) {
      const [filter, category, value] = [tag.filter, tag.category, tag.pk];
      if (filter === 'location') this.filterParamsLocation = this.filterParamsLocation.filter(v => v.toString() !== value.toString());
      if (category === 'available') this.filterParams.available = false;
    },
    async resetFilters() {
      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));
      this.filterParamsLocation = [];
      this.chooseCategory();
      this.$refs.category_null.click();
      this.tags = [];
      await this.getFacets();
    },
    chooseAvailFilter(type) {
      this.filterParams.available = type;
    },
    chooseLocationFilter(type) {
      this.locationSearch = '';
      this.locationFilter = type;
    },
    chooseCategory(pk = null) {
      this.filterParams.category = pk;
    },
    initFilterParams() {
      this.initialFilterParams = {
        metro: [],
        district: [],
        available: true,
      };
      this.filterParams = Object.assign({}, toRaw(this.initialFilterParams));
      this.filterParamsLocation = [];
      this.tags = [];
    },
    async getSpecs() {
      try {
        let response = await this.axios.get(this.dataFilterSpecsApi);
        const filters = response.data.specs;
        filters.metro = filters.metro.map(el => {
          el.locationType = 'metro';
          return el;
        });
        filters.district = filters.district.map(el => {
          el.locationType = 'district';
          return el;
        });
        this.filters = filters;
        return this.filters;
      } catch (err) {
        console.error(`%c${ this.$options.name } getSpecs() error load getSpecs result`, 'color: yellow', err);
      }
    },
    async getFacets(withFilter = false) {
      //this.setLoading(true);
      try {
        const response = withFilter
                         ? await this.axios.post(this.dataFilterParamsApi, this.filterParams)
                         : await this.axios.get(this.dataFilterParamsApi);

        this.facets = response.data.facets;

        return this.facets;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        //this.setLoading(false);
      }
    },
    mapBehaviorToggle(rawMap) {
      const state = this.isMapLocked ? 'disable' : 'enable';
      rawMap.behaviors.disable('scrollZoom');
      rawMap.behaviors[state]('drag');
      rawMap.behaviors[state]('dblClickZoom');
      rawMap.behaviors[state]('multiTouch');
      rawMap.behaviors[state]('rightMouseButtonMagnifier');
    },
    async getPoints() {
      try {
        let response = await this.axios.get(this.pointsApiUrl);
        this.points = response.data.objects;
        //console.log(`%c${this.$options.name} getPoints() response`, 'color: yellow', response.data.objects);
        this.$nextTick(() => {
          this.pointsLoaded = true;
        });
      } catch (err) {
        console.error(`%c${ this.$options.name } error load map points`, 'color: yellow', err);
      }
    },
    mapInit() {
      const $map = $(this.$refs.map);
      let restrictCoords = false;
      let props = {
        center: this.centerPoint,
        zoom: parseInt(this.dataBaseZoom),
        controls: [],
      };

      this.mapInstance = new ymaps.Map(this.$refs.map,
        props,
        {
          yandexMapDisablePoiInteractivity: true,
          //restrictMapArea: restrictCoords, // хотелось ограничить возможность двигать карту далеко за пределы очерченной области с объектами, но тогда на меньших разрешениях карта отрисовывается без учёта необходимости вписать все обекты с необходимыми отсупами
        });

      const rawMap = this.updatePoints();
      this.mapBehaviorToggle(rawMap);

      this.onResize();

    },
    updatePoints() {
      const rawMap = toRaw(this.mapInstance);
      //console.log('add points', this.points)
      const collection = new ymaps.GeoObjectCollection();
      this.mapMargins.forEach((margin) => rawMap.margin.addArea(margin));
      this.points.forEach((point) => {
        collection.add(this.createMarker(point));
      });
      rawMap.geoObjects.add(collection);
      this.setEvents(collection);

      //  то же самое, масштаб улетает так, что все объекты в одной точке
      rawMap.setBounds(rawMap.geoObjects.getBounds(), {
        zoomMargin: this.isTouchDevice ? [188, 37, 37, 37] : [88, 37, 37, 37], checkZoomRange: true, preciseZoom: true,
      });
      //console.log('%czoomMap mapInit() rawMap.getZoom() = ', 'color: yellow', rawMap.getZoom());

      return rawMap;
    },
    setEvents(collection) {
      collection.events
        .add('mouseenter', (e) => {
          const activeGeoObject = e.get('target');
          $('.d-map-point').removeClass('_hover');
          if (!this.isTouchDevice) {
            const point = document.getElementById(activeGeoObject.properties.get('pointId'));
            $(point).addClass('_hover');
            this.createTooltip(point);
          }

        })
        .add('click', (e) => {
          const activeGeoObject = e.get('target');
          $('.d-map-point').removeClass('_hover').removeClass('_active');
          this.currentObject = activeGeoObject.properties.get('point');
          activeGeoObject.options.set(activeGeoObject.properties.get('activeOptions'));
          const point = document.getElementById(activeGeoObject.properties.get('pointId'));
          $(point).addClass('_active').addClass('_hover');
          //console.log('click', $inner_el)
          collection.each(function(geoObject) {
            if (geoObject !== activeGeoObject) {
              geoObject.options.set(geoObject.properties.get('defaultOptions'));
            }
          });
        });
    },
    createMarker(point) {
      //console.log(`%c${ this.$options.name } createMarker() point = `, 'color: lime', point);
      const coords = Object.values(point.coords);
      let marker_html;

      const pointId = point.coords.join('-');
      const html = `
            <div id="${ pointId }" class="d-map-point _projects" data-map-point data-tippy='<div class="d-map-point-info">${ point.name }</div>'>
              <div class="d-map-point__img"><img src="${ point.img }" alt=""></div>
            </div>`;
      const markerLayout = ymaps.templateLayoutFactory.createClass(html, {

        build: function() {
          markerLayout.superclass.build.call(this);
          marker_html = this.getParentElement().getElementsByClassName('d-map-point')[0];
          this._events = ymaps.domEvent.manager.group(this.getElement().getElementsByClassName('d-map-point')[0]);
        },

        clear: function() {
          this._events.removeAll();
          markerLayout.superclass.clear.call(this);
        },
      });
      const defaultOptions = {
        preset: 'islands#circleDotIcon',
        iconLayout: markerLayout,
        iconImageHref: '/assets/i/blank.gif',
        iconImageSize: [8, 8],
        iconImageOffset: [-4, -8],
        zIndex: 730,
        zIndexActive: 731,
        zIndexHover: 731,
        iconPane: 'overlaps',
      };
      const activeOptions = Object.assign({}, defaultOptions, {
        zIndex: 735,
        zIndexActive: 735,
        zIndexHover: 735,
      });
      return new ymaps.Placemark(coords,
        {
          hintContent: '',
          balloonContent: '',
          defaultOptions,
          activeOptions,
          point,
          pointId,
        }, defaultOptions);
    },
    zoomMap(value) {
      const rawMap = toRaw(this.mapInstance);
      const currentZoom = rawMap.getZoom();
      rawMap.setZoom(currentZoom + value, {duration: 200}).then(() => {
        //console.log('%czoomMap rawMap.getZoom() = ', 'color: yellow', rawMap.getZoom());

      });

    },
    closeMarkerInfoMobile() {
      this.showMarkerInfoMobile = false;
      $('.d-map-point').removeClass('_active');
    },
    showFilter() {
      if (window.matchMedia('(max-width: 1279px)').matches) $('header').removeClass('_hide');
      $('body').removeClass('_noScroll');
      document.dispatchEvent(new CustomEvent('showFilter', {}));
      this.saveStateToURL('list');
    },
    sendData: _debounce(async function() {

      const rawMap = toRaw(this.mapInstance);
      rawMap.geoObjects.removeAll();
      try {
        await this.getFacets(true);
        //this.setLoading(true);
        //console.log(`%c${ this.$options.name } sendData() this.filterParams = `, 'color: yellow', this.filterParams);
        const response = await this.axios.post(this.pointsApiUrl, this.filterParams);
        //console.log('response map points', response.data)
        this.points = response.data.objects;
        //console.log(`%c${ this.$options.name } sendData() this.points = `, 'color: yellow', this.points);
        this.updatePoints();
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        //this.setLoading(false);
      }
    }, 500),
    setTags: _debounce(async function(params) {
      const tags = [];
      if (params.available) {
        tags.push({
          filter: 'available',
          category: 'available',
          tag_title: 'в продаже',
          pk: null,
        });
      }
      if (params.metro.length > 0) {
        const titles = params.metro.map(metroPk => [metroPk, this.filters.metro.find(el => el.pk.toString() === metroPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'location',
            category: 'metro',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }
      if (params.district.length > 0) {
        const titles = params.district.map(metroPk => [metroPk, this.filters.district.find(el => el.pk.toString() === metroPk.toString()).tag_title]);
        titles.forEach((tag) => {
          tags.push({
            filter: 'location',
            category: 'district',
            tag_title: tag[1],
            pk: tag[0],
          });
        });
      }

      this.tags = tags;

    }, 50),
    saveStateToURL(value) {
      //console.log('value', value)
      const url = new URL(location.href);
      url.searchParams.set('type', value);
      history.pushState(null, '', url);
    },
  },
};
</script>

<style scoped>

</style>
