<template>
  <div class="d-gallery-popup">
    <div class="d-gallery-popup__close d-button _close _blue" @click="$emit('close')">
      <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
    </div>
    <div ref="pagination" class="d-gallery-popup__pagination d-slider-pagination"></div>
    <d-gallery-arrows v-if="photos.length > 1" button-class="objectGallery" class="d-gallery-popup__arrows"/>
    <swiper v-if="rendered" ref="slider" v-bind="swiperOpts" class="d-gallery-popup__slider" @swiper="onSwiper" @slideChange="resetPinchZoom" :initialSlide="initialSlide" :pagination="{
              el: pagination,
              type: 'custom',
              renderCustom: function (swiper, current, total) {
                let currentSlide =  current.toString();
                let totalSlide = total.toString();
                return `<span>${currentSlide}&nbsp;-&nbsp;${totalSlide}</span>`;
              }
            }" :navigation="{
              prevEl: '.objectGallery._prev',
              nextEl: '.objectGallery._next',
            }"
      :allowTouchMove="isTouchDevice">
      <swiper-slide v-for="photo in photos" :key="photo" class="d-gallery-popup__slide">
        <div v-if="!isTouchDevice" :ref="setItemRef" class="zoomist-container">
          <div class="zoomist-wrapper">
            <div class="zoomist-image">
              <img :data-src="type === 'furnish' ? photo : photo.img" alt="" class="swiper-lazy" draggable="false"/>
              <div class="swiper-lazy-preloader"></div>
            </div>
          </div>
        </div>
        <template v-else>
          <img :data-src="type === 'furnish' ? photo : photo.img" alt="" class="swiper-lazy">
          <div class="swiper-lazy-preloader"></div>
        </template>
      </swiper-slide>
    </swiper>
    <div class="d-gallery-popup__note" :class="{ _margin: photos.length > 1}">
      <d-disclaimer :text="disclaimer"/>
    </div>
    <div v-if="!swipeInfoHide && isTouchDevice" class="d-gallery-popup__note">
      <div class="d-swipe-info">
        <img src="/assets/blueant/assets/img/d/icon_swipe2.svg" alt="">
        <div>Используйте жесты для масштабирования и&nbsp;прокрутки изображения</div>
        <button @click="closeSwipeInfo">
          <img src="/assets/blueant/assets/img/d/icon_close_pen.svg" alt="">
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination} from 'swiper';
import {ref} from 'vue';
import Cookies from 'js-cookie';
import getTouchDevice from '@utils/getTouchDevice';
import Zoomist from 'zoomist';
import DGalleryArrows from '@components/atoms/DGalleryArrows.vue';
import DDisclaimer from '@components/atoms/DDisclaimer.vue';
import { lock, unlock } from 'tua-body-scroll-lock';

export default {
  name: 'DObjectGalleryPopup',
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    DGalleryArrows,
    DDisclaimer,
  },
  setup() {
    const pagination = ref(null);
    return {
      modules: [Navigation, Pagination],
      pagination,
    };
  },
  data() {
    return {
      swipeInfoHide: true,
      isTouchDevice: false,
      itemRefs: [],
      swiper: null,
      rendered: false,
      disclaimer: 'Визуализация проекта предварительная, возможны изменения.',
    };
  },
  computed: {
    swiperOpts() {
      return {
        allowTouchMove: this.isTouchDevice,
        preloadImages: false,
        lazy: {
          enabled: true,
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        watchSlidesProgress: true,
        loop: this.photos.length > 1,
        slidesPerView: 'auto',
        spaceBetween: 10,
        keyboard: {
          enabled: !this.isTouchDevice,
        },
      };
    },
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closeSwipeObjectGallery')) === 1;
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.rendered = true;
    if(this.isTouchDevice) lock(document.querySelector('body'));
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    if(this.isTouchDevice) unlock(document.querySelector('body'));
  },
  methods: {
    onResize() {
      this.isTouchDevice = getTouchDevice();
    },
    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    closeSwipeInfo() {
      Cookies.set('closeSwipeObjectGallery', 1);
      this.swipeInfoHide = true;
    },
    resetPinchZoom() {
      this.itemRefs.forEach((item) => {
        if (item.zoomist) item.zoomist.reset();
      });
      this.$nextTick(() => this.activateZoomist());
    },
    activateZoomist() {
      if (!this.itemRefs.length || this.isTouchDevice) return;
      const currentIndex = this.swiper.slides.length ? this.swiper.activeIndex : this.initialSlide;
      const currentSlide = this.itemRefs[currentIndex];
      if (currentSlide && !currentSlide.zoomist) {
        new Zoomist(currentSlide);
      }

    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>
