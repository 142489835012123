<template>
  <div class="d-choose-floor__container">
    <div v-if="screenAdaptive && sections.length && buildings.length" class="d-choose-floor__controls" id="choose-floor-controls-adaptive">
      <d-select class="d-choose-floor__controls-select" v-model="form.korpus" :data-options="buildings" data-placeholder="Корпус"/>
      <d-select class="d-choose-floor__controls-select" v-model="form.section" :data-options="sections" data-placeholder="Секция"/>
    </div>
    <Teleport v-else-if="sections.length && buildings.length" to="#choose-floor-controls">
      <d-select class="d-choose-floor__controls-select" v-model="form.korpus" :data-options="buildings" data-placeholder="Корпус"/>
      <d-select class="d-choose-floor__controls-select" v-model="form.section" :data-options="sections" data-placeholder="Секция"/>
    </Teleport>
    <div class="d-choose-floor__floor-wrapper">
      <div class="d-choose-floor__floor"> Этаж {{ form.floor }}</div>
    </div>
    <div class="d-choose-floor__plan-wrapper">
      <d-swipe-zoom-info v-if="!swipeInfoHide && needTip" class="d-visual-genplan__swipe" @close="closeSwipeInfo()">
        Используйте жесты для прокрутки изображения
      </d-swipe-zoom-info>
      <div class="d-choose-floor__plan">
        <d-plan-slider v-if="!loading && !errored && floorsArray.length"
                       :floors="floorsArray"
                       :current-floor-index="currentFloorIndex"
                       @changeFloor="changeFloor"
                       @setWidth="setNeedTip"/>
        <d-loading-spinner v-else-if="loading && !errored" class="floor-card__loading"></d-loading-spinner>
        <div v-if="errored" class="floor-card__errored">Произошла ошибка при загрузке данных.<br>Попробуйте выбрать другой корпус, этаж</div>
      </div>
    </div>
    <div class="d-choose-floor__compass-wrapper">
      <img v-if="info.compass" :src="info.compass" :style="{transform: info.compassDegree ? `rotate(${info.compassDegree}deg)` : 'none'}"/>
    </div>
    <div class="d-choose-floor__quarter-wrapper">
      <img v-if="info.quarter_render" :src="info.quarter_render"/>
    </div>
    <div class="d-choose-floor__floor-wrapper2">
      <div ref="floorNum" class="d-choose-floor__floor">Этаж</div>
    </div>
    <div class="d-choose-floor__pager-wrapper">
      <d-floor-slider v-if="screenSize >= 1280"></d-floor-slider>
      <d-floor-buttons v-else :floors="floorsArray" :current-floor-index="currentFloorIndex" @changeFloor="changeFloor"></d-floor-buttons>
    </div>
  </div>
</template>

<script>
import {debounce as _debounce} from 'lodash';
import DLoadingSpinner from '@components/atoms/DLoadingSpinner.vue';
import DSelect from '@components/molecules/DSelect.vue';
import DPlanSlider from '@components/molecules/DPlanSlider.vue';
import DFloorSlider from '@components/molecules/DFloorSlider.vue';
import DFloorButtons from '@components/molecules/DFloorButtons.vue';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import Cookies from 'js-cookie';

export default {
  name: 'DChooseFloor',
  components: {DPlanSlider, DSelect, DFloorSlider, DFloorButtons, DLoadingSpinner, DSwipeZoomInfo},
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/blueant/assets/json/choose-floor.json',
    },
    dataPage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        section: '',
        korpus: '',
        quarter: null,
        floor: null,
      },
      info: {},
      buildings: [],
      sections: [],
      floors: [],
      screenAdaptive: false,
      screenSize: 1920,
      errored: false,
      loading: true,
      swipeInfoHide: null,
      needTip: false,
      paramsLink: null,
    };
  },
  computed: {
    floorsArray() {
      return this.screenSize <= 1279 ? [...this.floors] : [...this.floors].reverse();
    },
    currentFloorIndex() {
      return this.floorsArray.findIndex(floor => floor.num.toString() === this.form.floor.toString());
    },
  },
  created() {
    this.initFloor();
    this.setScreenSize(false);
    window.addEventListener('resize', this.setScreenSize);
    window.addEventListener('popstate', this.restore);
    this.swipeInfoHide = Number(Cookies.get('closedSwipeInfoChooseFloor')) === 1;
    this.paramsLink = document.querySelector('a.d-visual-header__choose.d-button');
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setScreenSize);
    window.removeEventListener('popstate', this.restore);
  },
  watch: {
    'form.section': function(newVal, oldVal) {
      //console.log(`%c${ this.$options.name } watch() form.section this.loading = `, 'color: lightblue', this.loading);
      if (!this.loading && this.sections.length && newVal) {
        this.submitData();
      }
    },
    'form.korpus': function(newVal, oldVal) {
      //console.log(`%c${ this.$options.name } watch() form.korpus this.loading = `, 'color: yellow', this.loading);
      if (!this.loading && this.buildings.length && newVal) {
        this.submitData();
      }
    },
    'form.floor': function(newVal, oldVal) {
      //console.log(`%c${ this.$options.name } watch() form.floor this.loading = `, 'color: yellow', this.loading);
      this.navigate();
    },
    //currentFloorIndex() {
    //  console.log(`%c${ this.$options.name } watch() currentFloorIndex = `, 'color: yellow', this.currentFloorIndex);
    //},
  },
  methods: {
    closeSwipeInfo() {
      Cookies.set('closedSwipeInfoChooseFloor', 1);
      this.swipeInfoHide = true;
    },
    setNeedTip(width) {
      this.needTip = width > this.screenSize;
    },
    async initFloor() {
      this.form = JSON.parse(this.dataPage);
      //console.log(`%c${ this.$options.name } initFloor() this.form = `, 'color: yellow', this.form);
      await this.loadData();
    },
    changeFloor(floorNum) {
      this.form.floor = floorNum;
      this.$nextTick(() => {
        const button = document.querySelector(`div[role="button"][data-floor="${ floorNum }"]`);
        if (button) this.$refs.floorNum.style.transform = `translateY(${ button.offsetTop + button.offsetHeight / 2 - this.$refs.floorNum.offsetHeight / 2 }px)`;
      });
    },
    loadData: async function(form = null) {
      this.loading = true;
      let stopReload = false;
      //console.log(`%c${ this.$options.name } loadData() this.form = `, 'color: salmon', this.form);

      if (!form) form = this.form;
      try {
        const response = await this.axios.post(this.dataUrlApi, form);
        const result = response.data;
        if (!result.floors || !result.sections) {
          this.errored = true;
          return;
        }

        //console.log(`%c${ this.$options.name } loadData() form.section = `, 'color: salmon', form.section);
        const sectionExists = form.section ? result.sections.find(section => section.id.toString() === form.section.toString()) : false;
        //console.log(`%c${ this.$options.name } loadData() sectionExists = `, 'color: salmon', sectionExists);

        if (!sectionExists) {
          this.form.section = form.section = result.sections[0].id;
          //console.log(`%c${ this.$options.name } loadData() reload with form.section = `, 'color: lime', form.section);
          stopReload = true;
          return this.loadData(form);
        }
        this.sections = result.sections.map(section => {
          section.value = section.id;
          return section;
        });
        this.form.section = form.section;

        this.floors = result.floors.map(floor => {
          floor.name = `Этаж ${ floor.num }`;
          floor.value = floor.num;
          floor.disabled = !floor.flats.filter(flat => flat.available).length;
          return floor;
        });
        const floorExists = result.floors.find(floor => floor.num.toString() === form.floor.toString() && floor.flats.filter(flat => flat.available).length > 0);
        if (!floorExists) this.form.floor = parseInt(result.floors.find(floor => floor.flats.filter(flat => flat.available).length > 0).num);

        this.buildings = result.buildings.map(building => {
          building.value = building.id;
          return building;
        });
        this.form.korpus = form.korpus;

        this.info = result.info;

        const currentBuilding = this.buildings.find(building => this.form.korpus === building.value);
        if (currentBuilding) document.querySelector('.d-visual-header__breadcrumbs div.d-visual-breadcrumbs__item:last-child').innerHTML = currentBuilding.name;

        //console.log(`%c${ this.$options.name } loadData() this.form = `, 'color: lime', this.form);
        //console.log(`%c${ this.$options.name } loadData() this.sections = `, 'color: lime', this.sections);
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        if (!stopReload) {
          this.loading = false;
          this.navigate();
          //console.log(`%c${ this.$options.name } loadData() finally this.floorsArray = `, 'color: lime', this.floorsArray);
          //console.log(`%c${ this.$options.name } loadData() finally this.currentFloorIndex = `, 'color: lime', this.currentFloorIndex);
        }
      }
    },
    submitData: _debounce(async function() {
      this.loading = true;
      await this.loadData();
    }, 500),
    setScreenSize(setLoading = true) {
      if (setLoading) this.loading = true;
      this.screenAdaptive = window.matchMedia('(max-width: 1279px)').matches;
      this.screenSize = window.innerWidth;
      this.$nextTick(() => {if (this.$refs.floorNum) this.$refs.floorNum.style.transform = 'unset'; });
      if (setLoading) this.$nextTick(() => {this.loading = false;});
    },
    navigate(e) {
      //console.log(`%c${ this.$options.name } navigate() this.loading = ${this.loading}; this.form = `, 'color: lime', this.form);
      //console.log(`%c${ this.$options.name } navigate() window.location = `, 'color: lime', window.location.href);
      if (!this.form.section || !this.form.korpus || !this.form.floor || this.loading) return;
      const url = new URL(window.location);
      if (url.pathname.match(/\/korpus\d+\/section\d+\/floor\d+/)) {
        url.pathname = url.pathname
          .replace(/section\d+/, `section${ this.form.section }`)
          .replace(/korpus\d+/, `korpus${ this.form.korpus }`)
          .replace(/floor\d+/, `floor${ this.form.floor }`);
      }
      else {
        url.searchParams.set('korpus', this.form.korpus);
        url.searchParams.set('section', this.form.section);
        url.searchParams.set('floor', this.form.floor);
      }
      if (window.location.href !== url.toString()) {
        //console.log(`%c${ this.$options.name } navigate() pushState to ${ url.toString() } this.loading = `, 'color: #ff5555', this.loading);
        window.history.pushState({}, '', url);
      }
      if (this.paramsLink) {
        let paramsLinkUrl = this.paramsLink.getAttribute('href');
        paramsLinkUrl = paramsLinkUrl
          .replace(/buildings\[\]=\d+/ig,`buildings[]=${this.form.korpus}`)
          .replace(/section\[\]=\d+/ig,`section[]=${this.form.section}`)
          .replace(/floor_number\[\]=\d+/ig,`floor_number[]=${this.form.floor}`);
        this.paramsLink.setAttribute('href', paramsLinkUrl);
      }
    },
    restore() {
      const url = new URL(window.location);
      //console.log(`%c${this.$options.name} restore(${url.toString()})`, 'color: lightblue')
      let matches;
      if ((matches = url.pathname.match(/\/korpus(\d+)\/section(\d+)\/floor(\d+)/)) !== null) {
        this.form.korpus = matches[1];
        this.form.section = matches[2];
        this.form.floor = matches[3];
      }
      else {
        this.form.korpus = Number(url.searchParams.get('korpus'));
        this.form.section = Number(url.searchParams.get('section'));
        this.form.floor = Number(url.searchParams.get('floor'));
      }
    },
  },
};
</script>

<style scoped>

</style>
