<template>
  <div class="d-range-slider" :class="classList">
    <div v-if="title" class="d-range-slider__title" @click="setInputFocus">{{ title }}</div>
    <div class="d-range-slider__content">
      <div class="d-range-slider__col _left">
        <div>{{ fromTitle }}</div>
        <input v-model="inpMin"
               ref="inpMin"
               class="d-range-slider__value"
               type="text"
               inputmode="numeric"
               @change="inpMinChange"
               @keypress="checkInput"
               @keyup.enter="inpMinChange"
               @focus="$emit('focus')"/>
        <div class="d-range-slider__label">{{ unit }}</div>
      </div>
      <div class="d-range-slider__col _right">
        <div>{{ toTitle }}</div>
        <input v-model="inpMax"
               ref="inpMax"
               class="d-range-slider__value"
               type="text"
               inputmode="numeric"
               @change="inpMaxChange"
               @keypress="checkInput"
               @keyup.enter="inpMaxChange"
               @focus="$emit('focus')"/>
        <div class="d-range-slider__label">{{ unit }}</div>
      </div>
      <Vue-slider ref="slider" v-model="values" v-bind="opts" class="d-range-slider-single__slider"></Vue-slider>
    </div>
  </div>
</template>


<script>
import {toRaw} from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';
import {isEqual as _isEqual, debounce as _debounce} from 'lodash';
import additionalClassCSS from '@utils/additionalClassCSS';

export default {
  name: 'DInputSliderRange',
  emits: ['focus', 'update:model-value', 'update:modelValue'],
  components: {
    VueSlider,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [1, 100000000],
    },
    from: {
      type: Number,
      default: 50,
    },
    to: {
      type: Number,
      default: 300,
    },
    fromTitle: {
      type: String,
      default: 'от',
    },
    toTitle: {
      type: String,
      default: 'до',
    },
    step: {
      type: [Number, String],
      default: 0.1,
    },
    type: {
      type: String,
      default: 'number',
    },
    title: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '₽',
    },
    mod: {
      type: [Array, String],
      default: '',
    },
    theme: {
      type: [Array, String],
      default: '',
    },
  },
  data() {
    return {
      values: [],
      inpMin: null,
      inpMax: null,
      opts: {
        min: null,
        max: null,
        interval: this.step,
        tooltip: 'none',
        height: '4px',
        silent: true,
      },
    };
  },
  computed: {
    range() {
      return Number(this.step) < 1
             ? [parseFloat(Math.min(...this.modelValue)), parseFloat(Math.max(...this.modelValue))]
             : [parseInt(Math.min(...this.modelValue)), parseInt(Math.max(...this.modelValue))];
    },
    typeIsPrice() {
      return this.type === 'price';
    },
    typeIsNumber() {
      return this.type === 'number';
    },
    modClass() {
      return additionalClassCSS(this.mod);
    },
    themeClass() {
      return additionalClassCSS(this.theme);
    },
    classList() {
      return Object.assign({}, this.modClass, this.themeClass);
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        if (!_isEqual(toRaw(value), toRaw(oldValue))) {
          this.setDefaultValues();
          if (this.$refs.slider) this.$refs.slider.setValue(this.values);
          this.sendData();
        }
      }
      },
    values(value, oldValue) {
      this.inpMin = this.formatToString(toRaw(value)[0]);
      this.inpMax = this.formatToString(toRaw(value)[1]);
        this.sendData();
      },
    from() {
      this.setDefaultValues();
    },
    to() {
      this.setDefaultValues();
    },
  },
  created() {
    this.setDefaultValues();
  },
  methods: {
    sendData: _debounce(function() {
      this.$emit('update:modelValue', this.values);
    }, 50),
    formatToString(value) {
      if (this.typeIsPrice) {
        return value.toLocaleString('ru-RU').replace(/(\.[^0]+)0\d*$/, '$1');
      }
      if (this.typeIsNumber) {
        return Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          useGrouping: true,
          maximumSignificantDigits: 11,
        }).format(Number(value.toString().replace(',', '.')));
      }

      return value;
    },
    formatToNumber(value) {
      if (this.typeIsPrice) {
        return Number(value.replace(/,/g, '.').replace(/[^\d.]/g, ''));
      }
      if (this.typeIsNumber) {
        return this.step >= 1 ? Number(value.replace(/\D/g, '')) : Number(value.replace(/,/g, '.').replace(/[^\d.]/g, ''));
      }
      return value;
    },
    setDefaultValues() {
      const values = this.range;
      let inpMin = this.formatToString(Math.min(...this.modelValue));
      let inpMax = this.formatToString(Math.max(...this.modelValue));
      if (this.from && this.to) {
        this.opts.min = parseFloat(this.from);
        this.opts.max = parseFloat(this.to);
        if (values[0] < this.opts.min) {
          values[0] = this.opts.min;
          inpMin = this.formatToString(values[0]);
        }
        if (values[1] > this.opts.max) {
          values[1] = this.opts.max;
          inpMax = this.formatToString(values[1]);
        }
      }
      else {
        this.opts.min = parseFloat(Math.min(...this.modelValue));
        this.opts.max = parseFloat(Math.max(...this.modelValue));
      }

      this.inpMin = inpMin;
      this.inpMax = inpMax;
      this.values = values;
    },
    checkInput(e) {
      const hasDot = e.target.value.match(/\./);
      if ((this.typeIsNumber || this.typeIsPrice) && this.step < 1 && e.key.match(/[\d.,]/) && !hasDot) {
        return true;
      }
      if ((this.typeIsNumber || this.typeIsPrice) && e.key.match(/\d/)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    inpMinChange() {
      let currentValue = this.formatToNumber(this.$refs.inpMin.value);
      currentValue = Number((Math.round(currentValue / this.step) * this.step).toFixed(2));
      if (currentValue < this.opts.min) {
        currentValue = this.opts.min;
      }
      if (currentValue > this.opts.max) {
        currentValue = this.opts.max;
      }
      this.inpMin = this.formatToString(currentValue);

      const lastValue = this.values[1];

      this.$refs.slider.setValue([currentValue, lastValue]);
    },
    inpMaxChange() {
      let currentValue = this.formatToNumber(this.$refs.inpMax.value);
      currentValue = Number((Math.round(currentValue / this.step) * this.step).toFixed(2));
      if (currentValue > this.opts.max) {
        currentValue = this.opts.max;
      }
      if (currentValue < this.opts.min) {
        currentValue = this.opts.min;
      }
      this.inpMax = this.formatToString(currentValue);
      const firstValue = this.values[0];
      this.$refs.slider.setValue([firstValue, currentValue]);
    },
  },
};
</script>
