<template>
  <div class="d-commercial-location">
    <div v-for="district in districtsList" :key="district.pk" class="d-checkbox _full">
      <input
          class="d-checkbox__input"
          type="checkbox"
          name="furnish"
          :id="`filter-district${district.pk}-id${id}`"
          v-model="selectedDistricts"
          :value="district.pk"
          @change="sendData"
      >
      <label class="d-checkbox__label" :for="`filter-district${district.pk}-id${id}`">
        {{ district.filter_title }}
        <svg v-if="districtIsActive(district.pk)" class="d-checkbox__close">
          <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-close'></use>
        </svg>
      </label>
    </div>
  </div>
</template>

<script>
import { intersection as _intersection, uniqueId as _uniqueId } from "lodash";

export default {
  name: "DCommercialLocation",
  props: {
    districtsList: {
      type: Array,
      default: () => [],
      require: true
    },
    districts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      id: null,
      selectedDistricts: []
    }
  },
  watch: {
    districts(data) {
      this.selectedDistricts = _intersection(data, this.selectedDistricts)
    }
  },
  mounted() {
    this.id = _uniqueId()
    this.selectedDistricts = this.districts;
  },
  methods: {
    sendData() {
      this.$emit('update:districts', this.selectedDistricts)
    },
    districtIsActive(pk) {
      return this.selectedDistricts.includes(pk)
    }
  }
}
</script>

<style scoped>

</style>