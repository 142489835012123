<template>
  <div class="d-aux-rooms__result-error">
    <div class="d-aux-rooms__result-error_icon _reload"></div>
    <div class="d-aux-rooms__result-error_text">
      При загрузке данных произошла ошибка. Попробуйте&nbsp;обновить страницу
    </div>
    <button class="d-button _dark d-aux-rooms__result-error_button" @click="reload"><span>Обновить страницу</span></button>
  </div>
</template>

<script>
export default {
  name: 'DSearchFilterError',
  methods: {
    reload() {
      window.location.reload()
    }
  }
};
</script>

<style scoped>

</style>
