<template>
  <form v-if="dataMod.includes('footer') && dataMod.includes('regional')" class="d-still-questions__form _regional" @submit.prevent="submit">
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" placeholder="Ваше имя*" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
<!--        <input ref="tel" type="tel" name="phone" placeholder="Ваш телефон*" class="d-input__field _tel" v-model="v$.form.phone.$model" @mouseover="hoverTel" @focus="focusTel"-->
<!--               @blur="v$.form.phone.$touch" :disabled="loading" inputmode="numeric" maxlength="18" @keyup="telInput" :class="{ _filled: form.phone > ''}">-->
        <!--        <span @click="$refs.tel.focus()" class="d-input__tel-prefix">+7&nbsp;</span>-->
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
      </div>
    </div>
    <div class="d-still-questions__item">
      <d-select v-model="v$.form.date.$model" :data-options="dates" data-placeholder="Выберите день" @update:modelValue="changeDay"/>
    </div>
    <div class="d-still-questions__item">
      <d-select v-model="v$.form.time.$model" :data-options="times" data-placeholder="Ближайшее"/>
    </div>
    <div class="d-still-questions__item _agree">
      Нажимая кнопку, вы соглашаетесь&nbsp;с <a :href="dataAgreeLink" target="_blank">условиями обработки персональных данных</a>
      <d-recaptcha-info :errored="errored" :error-text="errorText" />
    </div>
    <div v-if="loading" class="d-still-questions__item">
      <div class="d-still-questions__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-still-questions__item">
      <button class="d-still-questions__button d-button _blue" @click.prevent="submit"><span>Отправить</span></button>
    </div>
    <div class="d-still-questions__item _tip">В форме указано московское время</div>
  </form>
  <form v-else-if="dataMod === 'footer' || dataMod.includes('footer')" class="d-still-questions__form _footer" @submit.prevent="submit">
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" placeholder="Ваше имя*" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
<!--        <input ref="tel" type="tel" name="phone" placeholder="Ваш телефон*" class="d-input__field _tel" v-model="v$.form.phone.$model" @mouseover="hoverTel" @focus="focusTel"-->
<!--               @blur="v$.form.phone.$touch" :disabled="loading" inputmode="numeric" maxlength="18" @keyup="telInput" :class="{ _filled: form.phone > ''}">-->
<!--        &lt;!&ndash;        <span @click="$refs.tel.focus()" class="d-input__tel-prefix">+7&nbsp;</span>&ndash;&gt;-->
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
      </div>
<!--      <input ref="phoneTest" type="text" value="">-->

    </div>
    <div class="d-still-questions__item">
      <d-select v-model="v$.form.date.$model" :data-options="dates" data-placeholder="Выберите день" @update:modelValue="changeDay"/>
    </div>
    <div class="d-still-questions__item">
      <d-select v-model="v$.form.time.$model" :data-options="times" data-placeholder="Ближайшее"/>
    </div>
    <div class="d-still-questions__item _agree">
      Нажимая кнопку, вы соглашаетесь&nbsp;с <a :href="dataAgreeLink" target="_blank">условиями обработки персональных данных</a>
      <d-recaptcha-info :errored="errored" :error-text="errorText" />
    </div>
    <div v-if="loading" class="d-still-questions__item">
      <div class="d-still-questions__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-still-questions__item">
      <button class="d-still-questions__button d-button _blue" @click.prevent="submit"><span>Отправить</span></button>
    </div>
  </form>
  <form v-else-if="dataMod === 'purchase' || dataMod.includes('purchase')" class="d-purchase-form__items" @submit.prevent="submit">
    <div class="d-purchase-form__item">
      <div class="d-input _grey" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" placeholder="Имя*" class="d-input__field" v-model="v$.form.name.$model" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-purchase-form__item">
      <div class="d-input _grey" :class="{ _error: v$.form.phone.$errors.length }">
<!--        <input ref="tel" type="tel" name="phone" placeholder="Телефон*" class="d-input__field _tel" v-model="v$.form.phone.$model" @mouseover="hoverTel" @focus="focusTel" @blur="v$.form.phone.$touch" inputmode="numeric" maxlength="18" @keyup="telInput" :class="{ _filled: form.phone > ''}">-->
<!--        <span @click="$refs.tel.focus()" class="d-input__tel-prefix">+7&nbsp;</span>-->
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" :mod="dataProps.type" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
      </div>
    </div>
    <div class="d-purchase-form__item">
      <div class="d-input _grey" :class="{ _error: v$.form.email.$errors.length }">
        <input v-model="v$.form.email.$model" type="email" name="email" placeholder="E-mail" class="d-input__field">
        <div class="d-input__error" v-for="(error, index) of v$.form.email.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-purchase-form__item">
      <d-select v-model="v$.form.date.$model" :data-options="dates" data-placeholder="Выберите день" class="_grey" @update:modelValue="changeDay"/>
    </div>
    <div class="d-purchase-form__item">
      <d-select v-model="v$.form.time.$model" :data-options="times" data-placeholder="Ближайшее" class="_grey"/>
    </div>
    <div class="d-purchase-form__item _button">
      <div v-if="loading" class="d-callMe__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
      <button v-else-if="dataType === 'mortgage'" class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Получить одобрение</span></button>
      <button v-else class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Отправить</span></button>
    </div>
    <div class="d-purchase-form__item _agree">
      Отправляя заявку, вы соглашаетесь <a :href="dataPoliticsLink" target="_blank">политикой обработки персональных данных</a>
      <d-recaptcha-info :errored="errored" :error-text="errorText" />
    </div>
  </form>
  <form v-else class="d-callMe__content" @submit.prevent="submit">
    <div class="d-callMe__item _item1">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" placeholder="Ваше имя*" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-callMe__item _item2">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
<!--        <input ref="tel" type="tel" name="phone" placeholder="Ваш телефон*" class="d-input__field _tel" v-model="v$.form.phone.$model" @mouseover="hoverTel" @focus="focusTel"-->
<!--               @blur="v$.form.phone.$touch" :disabled="loading" inputmode="numeric" maxlength="18" @keyup="telInput" :class="{ _filled: form.phone > ''}">-->
        <!--        <span @click="$refs.tel.focus()" class="d-input__tel-prefix">+7&nbsp;</span>-->
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
      </div>
    </div>
    <div class="d-callMe__item _item3">
      <d-select v-model="v$.form.date.$model" :data-options="dates" data-placeholder="Выберите день" @update:modelValue="changeDay"/>
    </div>
    <div class="d-callMe__item _item4">
      <d-select v-model="v$.form.time.$model" :data-options="times" data-placeholder="Ближайшее"/>
    </div>
    <div v-if="loading" class="d-callMe__item _item5">
      <div class="d-callMe__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-callMe__item _item5">
      <button v-if="dataType === 'mortgage'" class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Получить одобрение</span></button>
      <button v-else class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Отправить</span></button>
    </div>
    <div class="d-callMe__item _item6">
      <div class="d-callMe__agree d-checkbox _agree">
        <input type="checkbox" name="agree" id="agree_callMe" class="d-checkbox__input" @change="v$.form.agree.$touch()" v-model="v$.form.agree.$model">
        <label for="agree_callMe" class="d-checkbox__label _inline">Согласен с </label><a :href="dataPoliticsLink" target="_blank">политикой обработки персональных данных</a>
      </div>
      <d-recaptcha-info :errored="errored" :error-text="errorText" />
    </div>
  </form>
</template>

<script>
import DSelect from '@components/molecules/DSelect.vue';
import useVuelidate from '@vuelidate/core';
import {email, helpers, required, sameAs} from '@vuelidate/validators';
import getDaysData from '@utils/datetimeUtils';
import phoneHelper from '@utils/phoneHelper';
import {toRaw} from 'vue';
import Inputmask from 'inputmask';
//import {omit as _omit} from 'lodash';
import DPhoneInput from '@components/molecules/DPhoneInput.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: 'DCallMeForm',
  components: {
    DRecaptchaInfo,
    DSelect,
    DPhoneInput
  },
  props: {
    dataActionUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataLeadUrl: {
      type: [String, Boolean],
      default: false,
    },
    dataPoliticsLink: {
      type: String,
      default: '',
    },
    dataMod: {
      type: [String, Array],
      default: 'footer', // popup
    },
    dataType: {
      type: [String, Array],
      default: 'footer', // popup
    },
    dataAgreeLink: {
      type: String,
      default: '#',
    },
    dataProps: {
      type: [Object, Boolean, String],
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        date: '',
        time: '',
        email: '',
        agree: false,
      },
      times: [],
      dates: [],
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
      loading: false,
      phoneParams: {
        mask: '',
        code: ''
      },
      reset: false
    };
  },
  created() {
    this.dates = getDaysData();
    this.times = this.dates[0].hours;
    this.form.date = this.dates[0].value;
    this.form.time = this.times[0].value;
  },
  mounted() {
    this.$nextTick(() => {
      Inputmask('+9 (999) 999-99-99', {jitMasking: true, placeholder: ''}).mask($(this.$refs.tel));
      if(this.dataMod.includes('footer') || this.dataMod.includes('purchase') || this.dataMod === 'footer' || this.dataMod === 'purchase') {
        this.form.agree = true;
        this.v$.form.agree.$touch();
      }
    });
    if (this.dataMod.includes('footer') || this.dataMod === 'footer' || this.dataMod.includes('purchase') || this.dataMod === 'purchase') {
      this.form.agree = true;
      this.v$.form.agree.$touch();
    }
  },
  methods: {
    changeDay(newValue) {
      const selectedDay = this.dates.findIndex(el => el.value === newValue);
      if (selectedDay > -1) this.times = this.dates[selectedDay].hours;
    },
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        //this.sendData({ ...this.form, ..._omit(this.data, ['fields', 'action', 'button_text', 'open_form_button_text', 'time']), ...this.additData });
        let dataProps = toRaw(this.dataProps);
        if (this.dataProps && (typeof this.dataProps).toLowerCase() === 'string') {
          try {
            dataProps = JSON.parse(this.dataProps);
          } catch (e) {}
        }
        if (dataProps && (typeof dataProps).toLowerCase() === 'object' && Object.keys(dataProps).length > 0)
          this.sendData(Object.assign({}, {formType: 'callback'}, {...dataProps, ...this.form}));
        else this.sendData({...this.form, formType: 'callback'});
      }
    },
    async sendData(formdata) {
      formdata.URL = formdata.url = window.location.href;
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        formdata.token = await this.$recaptcha(this.dataType);
        const response = await this.axios.post(this.dataActionUrl, formdata);
        if (response.data.error) {
          this.errored = true;
          this.errorText = response.data.error;
        }
        else if (response.data.success) {
          this.resetForm();
          this.$emit('success');
          window.layoutAapp.$vfm.show({component: 'DPopupSuccess', bind: {content: response.data.success}});
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.name = '';
      this.form.phone = '+7 ';
      this.form.date = '';
      this.form.time = '';
      this.form.email = '';
      this.form.agree = false;
      const self = this;
      this.$nextTick(() => {
        self.v$.$reset();
        if (self.dataMod.includes('footer') || self.dataMod === 'footer' || self.dataMod.includes('purchase') || self.dataMod === 'purchase') {
          self.form.agree = true;
          self.v$.form.agree.$touch();
        }
      });
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.form.phone = phone;
      console.log('setPhone phone = ', phone);
    },
    blurInpPhone() {
      this.v$.form.phone.$touch()
      console.log('touch phone')
    }
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Заполните это поле', required),
        },
        email: {email: helpers.withMessage('Некорректный email', email)},
        date: {},
        time: {},
        phone: {
          required: helpers.withMessage('Заполните это поле', required),
          phoneHelper: helpers.withMessage('Введите номер полностью', phoneHelper(this.phoneParams)),
        },
        agree: {
          required,
          sameAs: sameAs(true),
        },
      },
    };
  },
};
</script>

