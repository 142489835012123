<template>
  <d-result-slider v-if="slider" :slider="slider" :mod="mod"></d-result-slider>
  <div v-if="!noResult" class="d-index-flats__result">
    <div v-if="exclusive && mod === 'objects'" class="d-index-flat _exclusive-small">
      <div class="d-index-flat__inner">
        <div class="d-index-flat__name">{{ exclusive.name }}</div>
      </div>
      <div class="d-index-flat__actions">
        <button v-for="label in exclusive.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background_color}" :data-v-tippy="label.tooltip">
          <div class="d-flat-action__name">{{ label.title }}</div>
          <a v-if="!touchDevice && label.link" class="d-flat-action__link" :href="label.link"></a>
        </button>
      </div>
      <div class="d-index-flat__more d-button"><span>Подробнее</span></div>
      <img class="d-index-flat__img" :src="exclusive.cover_image"><a class="d-index-flat__link" :href="exclusive.link"></a>
    </div>
    <div v-for="item in items" class="d-index-flat">
      <div class="d-index-flat__inner">
        <div class="d-index-flat__name">{{ item.name }}</div>
        <div class="d-index-flat__address">{{ item.address }}</div>
        <div class="d-index-flat__metro">
          <div v-for="metro in item.metro" class="d-metro">
            <div v-for="mcolor in metro.color" class="d-metro__color" :style="{backgroundColor: mcolor}"></div>
            <div>{{ metro.name }}</div>
          </div>
        </div>
        <div class="d-index-flat__price">{{ item.price_from }}</div>
      </div>
      <div class="d-index-flat__actions">
        <button v-for="label in item.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background_color}" :data-v-tippy="label.tooltip">
          <div class="d-flat-action__name">{{ label.title }}</div>
          <a v-if="!touchDevice && label.link" class="d-flat-action__link" :href="label.link"></a>
        </button>
      </div>
      <div class="d-index-flat__more">Подробнее</div>
      <img class="d-index-flat__img" :src="item.cover_image">
      <a class="d-index-flat__link" :href="item.link"></a>
    </div>
  </div>
  <div v-if="exclusive && mod !== 'objects'" class="d-index-flats__exclusive">
    <div class="d-index-flat _exclusive">
      <div class="d-index-flat__inner">
        <div class="d-index-flat__name">{{ exclusive.name }}</div>
      </div>
      <div class="d-index-flat__actions">
        <button v-for="label in exclusive.labels" class="d-flat-action" :style="{color: label.color, backgroundColor: label.background_color}" :data-v-tippy="label.tooltip">
          <div class="d-flat-action__name">{{ label.title }}</div>
          <a v-if="!touchDevice && label.link" class="d-flat-action__link" :href="label.link"></a>
        </button>
      </div>
      <div class="d-index-flat__more d-button"><span>Подробнее</span></div>
      <img class="d-index-flat__img" :src="exclusive.cover_image"><a class="d-index-flat__link" :href="exclusive.link"></a></div>
  </div>
  <div v-if="moreLink && !noResult" class="d-index-flats__more">
    <a :href="moreLink" class="d-button _dark"><span>Смотреть все предложения</span></a>
  </div>
  <div v-if="noResult" class="d-index-flats__result _empty">
    <div class="d-index-flat _callMe">
      <div class="d-index-flat__inner">
        <div class="d-index-flat__title">Появились вопросы?</div>
        <div class="d-index-flat__subtitle">Оставьте свои контактные данные и&nbsp;мы свяжемся с вами</div>
      </div>
      <div class="d-index-flat__more d-button _blue"><span>Заказать звонок</span></div>
      <img class="d-index-flat__img" src="/assets/blueant/assets/img/d/index_flat_8.jpg">
      <button class="d-index-flat__link" data-callMe-show :data-action-url="dataActionUrl" :data-lead-url="dataLeadUrl"></button>
    </div>
    <div class="d-index-flat _choose">
      <div class="d-index-flat__inner">
        <div class="d-index-flat__title">Подберите квартиру по&nbsp;параметрам</div>
        <div class="d-index-flat__subtitle">Воспользуйтесь расширенным фильтром подбора недвижимости</div>
      </div>
      <div class="d-index-flat__more d-button _white"><span>Подобрать по параметрам</span></div>
      <a class="d-index-flat__link" :href="chooseParamsLink"></a></div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import getTouchDevice from '@utils/getTouchDevice';
import DResultSlider from '@components/molecules/DResultSlider.vue';

export default {
  name: 'DIndexFilterResult',
  components: {DResultSlider},
  props: {
    dataItems: {
      type: Array,
      default: [],
    },
    moreLink: {
      type: String,
      default: '',
    },
    chooseParamsLink: {
      type: String,
      default: '/full-search/',
    },
    dataActionUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataLeadUrl: {
      type: [String, Boolean],
      default: false,
    },
    mod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slider: {},
      exclusive: {},
      items: [],
      noResult: false,
      touchDevice: false,
    };
  },
  watch: {
    dataItems() {
      this.refill();
    },
  },
  created() {
    this.touchDevice = getTouchDevice();
    this.refill();
  },
  methods: {
    refill() {
      this.items = this.dataItems.filter(el => !el.is_special).filter(el => !el.slider);
      this.slider = this.dataItems.find(el => el.slider);
      this.exclusive = this.dataItems.find(el => el.is_special);
      this.noResult = !this.dataItems.length;
      const self = this;
      this.$nextTick(() => {

        document.querySelectorAll('[data-v-tippy]').forEach(el => {
          if (el._tippy) el._tippy.destroy();
          if (el.dataset.vTippy > '') {
            tippy(el, {
              trigger: this.touchDevice ? 'click' : 'mouseenter',
              allowHTML: true,
              interactive: true,
              appendTo: () => document.body,
              offset: [0, 8],
              interactiveDebounce: 10,
              theme: this.touchDevice ? 'index-filter-touch' : 'index-filter',
              placement: 'top',
              animation: 'fade',
              content: el.dataset.vTippy,
              arrow: false,
              hideOnClick: true,
              onShow(instance) {
                if(self.touchDevice) instance.popper.querySelector('.tippy-box').addEventListener('click', () => {
                  instance.hide();
                });
              },
              onHide(instance) {
                if(self.touchDevice) instance.popper.querySelector('.tippy-box').removeEventListener('click', () => {
                  instance.hide();
                });
              },
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>

</style>
