<template>
  <div class="chess">
    <button v-if="prevSlideText" ref="prev" @click="navSlide('prev')" class="chess__section-btn _prev">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
      </svg>
      <span>{{ prevSlideText }}</span>
    </button>
    <button v-if="nextSlideText" ref="next" @click="navSlide('next')" class="chess__section-btn _next">
      <span>{{ nextSlideText }}</span>
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
      </svg>
    </button>
    <swiper
        class="chess__slider"
        ref="slider"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @afterInit="initSlider"
        v-bind="swiperOpts"
    >
      <swiper-slide v-for="section in data" :key="section.id" class="chess__slide" :data-section-title="section.title ? section.title : `Секция ${section.num}`">
        <d-chess-section :data="section" :reset-active="!showTabletPopup || !showMobilePopup" @clickedFlat="clickFlat" :open-flat="currentFlatData"/>
      </swiper-slide>
    </swiper>
    <div v-if="currentFlatData" class="chess__popup-tablet" :class="{_show: showTabletPopup}">
      <d-flat-card-chess :data="currentFlatData" @close="closePopup"/>
    </div>
    <d-popup-simple  v-model="showMobilePopup" :with-close="false">
      <d-flat-card-chess v-if="currentFlatData" :data="currentFlatData" @close="closePopup"/>
    </d-popup-simple>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/vue';
import DChessSection from "@components/molecules/DChessSection.vue";
import DFlatCardChess from "@components/molecules/DFlatCardChess.vue";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
export default {
  name: "DChess",
  components: {
    DChessSection,
    Swiper,
    SwiperSlide,
    DFlatCardChess,
    DPopupSimple
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showTabletPopup: false,
      currentFlatData: null,
      showMobilePopup: false,
      swiper: null,
      nextSlideText: null,
      prevSlideText: null,
    }
  },
  computed: {
    swiperOpts() {
      return {
        slidesPerView: 1,
        allowTouchMove: false,
      }
    },
  },
  methods: {
    clickFlat(flat) {
      const isTablet = window.matchMedia("(max-width: 1279px)").matches && window.matchMedia("(pointer:coarse)").matches;
      const isMobile = window.matchMedia("(max-width: 767px)").matches && window.matchMedia("(pointer:coarse)").matches;
      const isDesktop = window.matchMedia("(min-width: 1280px)").matches && window.matchMedia("(pointer:fine)").matches;

      if (isDesktop) {
        window.location.href = flat.url
      }
      if (isTablet) {
        this.currentFlatData = flat
        this.showTabletPopup = true
      }
      if (isMobile) {
        this.currentFlatData = flat
        this.showMobilePopup = true
      }
    },
    closePopup() {
      console.log('close tablet')
      this.showTabletPopup = false
      this.showMobilePopup = false
      this.currentFlatData = null
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    navSlide(dir) {
      dir === 'next' ? this.swiper.slideNext() : this.swiper.slidePrev();
    },
    onSlideChange(swiper) {
      const indexSlide = swiper.realIndex
      this.setSectionTitles(swiper)
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('s', indexSlide)
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.replaceState(null, '', newRelativePathQuery);
    },
    initSlider(swiper) {
      this.setSectionTitles(swiper)
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('s')) {
        const slideIndex = searchParams.get('s')
        swiper.slideTo(Number(slideIndex))
      }
    },
    setSectionTitles(swiper) {
      const indexSlide = swiper.realIndex
      const nextSlide = swiper.slides[indexSlide + 1]
      const prevSlide = swiper.slides[indexSlide - 1]
      this.nextSlideText = nextSlide ? nextSlide.dataset.sectionTitle : null
      this.prevSlideText = prevSlide ? prevSlide.dataset.sectionTitle : null
    },
  }
}
</script>
