import Cookies from 'js-cookie';

global.check_likes = window.check_likes = function check_likes() {
    window.likes = window.likes || {};

    if (Cookies.get('likes')) window.likes = JSON.parse(Cookies.get('likes'));

    $('.d-news-likes[data-id], .d-news-preview-item__likes[data-id]').each((i, like) => {
        $(like).toggleClass('_active', !!window.likes[like.dataset.id]);
    });
    $(document).on('click', '.d-news-likes[data-id], .d-news-preview-item__likes[data-id]', function() {
        toggle_likes(this);
    });

};

function toggle_likes(el) {
    const id = el.dataset.id;
    const active = el.classList.contains('_active');
    const url = el.dataset.url ? el.dataset.url : '/likes/api';

    const d = {
        action: 'saveLike',
        id: id,
        operation: active ? 'del' : 'add',
    };

    $.ajax({
        url,
        cache: false,
        type: 'POST',
        dataType: 'json',
        data: d,
        success: function(data) {
            if (data.success) {
                if (Cookies.get('likes')) {
                    window.likes = JSON.parse(Cookies.get('likes'));
                }
                else {
                    window.likes = {};
                }

                window.likes[id] = !active;

                Cookies.set('likes', JSON.stringify(window.likes), {
                    path: '/',
                    expires: 365,
                });

                const likes_count = data.cntVal;

                const likesElements = $(`[data-id="${ id }"]`);

                likesElements.toggleClass('_active', !!window.likes[id]);
                likesElements.data('likes', likes_count).find('span').text(likes_count);
            }
        },
    });
}

window.getFavorites = function getFavorites() {
    let favorites;
    try {
        favorites = JSON.parse(Cookies.get('favourites') || '[]');
    } catch (e) { favorites = []; }
    return favorites;
};

window.setFavouritesCount = function setFavouritesCount() {
    let favorites = getFavorites();
    const favIcon = document.querySelector('a.d-header__icon._favorite.d-header-icon._icon-favorite');
    if (!favIcon) return;
    if (favorites.length === 0) favIcon.removeAttribute('data-count');
    else if (favorites.length <= 9) favIcon.setAttribute('data-count', favorites.length);
    else favIcon.setAttribute('data-count', '9+');
};

window.addToFavorites = async function addToFavorites(flatId) {
    let favorites = getFavorites();
    const req = await fetch('/api/v1/favorites/add/', {
        method: 'POST',
        body: JSON.stringify({id: flatId}),
    });
    const resp = await req.json();
    if (resp.result === 'success') {
        favorites.push(flatId);
        Cookies.set('favourites', JSON.stringify(favorites), {expires: 180});
        setFavouritesCount();
        return true
    }
    return false
};

window.removeFromFavorites = async function removeFromFavorites(flatId) {
    let favorites = getFavorites();
    const req = await fetch('/api/v1/favorites/delete/', {
        method: 'POST',
        body: JSON.stringify({id: flatId}),
    });
    const resp = await req.json();
    if (resp.result === 'success') {
        favorites = favorites.filter(flat_id => flat_id !== flatId);
        Cookies.set('favourites', JSON.stringify(favorites), {expires: 180});
        setFavouritesCount();
        return true
    }
    return false
};

window.inFavorites = function inFavorites(flatId) {
    let favorites = getFavorites();
    return (flatId && favorites.includes(flatId));
};

$(window).on('load', () => {
    check_likes();
    setFavouritesCount();
    let favorites = getFavorites();
    document.querySelectorAll('[data-favorite-icon]').forEach(el => {
        const flatId = el.dataset.favoriteIcon;
        if (flatId && inFavorites(flatId)) {
            el.classList.add('_active');
            el.querySelector('.d-button__tooltip').innerHTML = 'В избранном';
        }

    });
    $(document).on('click', 'button[data-favorite-icon]', async function(e) {
        const flatId = this.dataset.favoriteIcon;
        if (!flatId) return;
        if (inFavorites(flatId)) {
            const result = await removeFromFavorites(flatId);
            if (result) {
                this.classList.remove('_active');
                this.querySelector('.d-button__tooltip').innerHTML = 'Добавить в избранное';
            }
        }
        else {
            await addToFavorites(flatId);
            if (!inFavorites(flatId)) return;
            this.classList.add('_active');
            this.querySelector('.d-button__tooltip').innerHTML = 'В избранном';
            let title = $(this).parents('.d-flat-card').find('.d-flat-card__title');
            let price = $(this).parents('.d-flat-card').find('.d-flat-card__price');
            if (title.length && price.length) {
                title = title.eq(0).clone();
                title.find('.mobile').remove();
                price = price.eq(0).clone();
                price.find('.d-flat-card__priceOld').remove();
                document.dispatchEvent(new CustomEvent('set-favorite', {
                    detail: {id: this.dataset.favoriteIcon, title: title.text().trim().replace(/[\n\s]+/i, ' '), price: price.text().trim()},
                }));
            }
        }
    });
});
