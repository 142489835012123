<template>
  <div class="chess-section">
    <div class="chess-section__num">
      <template v-if="data.title">{{ data.title }}</template>
      <template v-else>Секция {{ data.num }}</template>
      </div>
    <div class="chess-section__floors _mobile">
      <div v-for="(floor, idx) in reverseFloors" :key="floor.floorNum" class="chess-section__floor" :class="{ _hovered: hoverFloorID === idx }">
        {{ floor.floorNum }}
      </div>
    </div>
    <div class="chess-section__inner">
      <div class="chess-section__floors _desktop">
        <div v-for="(floor, idx) in reverseFloors" :key="floor.floorNum" class="chess-section__floor" :class="{ _hovered: hoverFloorID === idx }">
          {{ floor.floorNum }}
        </div>
      </div>
      <div class="chess-section__flats">
        <div
            v-for="(floor, idx) in reverseFloors"
            :key="floor.floorNum"
            class="chess-section__row"
            @mouseover="hoverFloorID = idx"
            @mouseleave="hoverFloorID = null"
        >
          <div v-for="flat in floor.flats" :key="flat.id" class="chess-section__cell" :class="{ _active: flat.id === activeFlatID }" @mouseover="createTooltip($event, flat)" @mouseleave="currentFlatData = null">
            <template v-if="flat.commercial">
              <div class="chess-section__room _commercial">
                К
              </div>
            </template>
            <template v-else>
              <div
                  class="chess-section__room"
                  :class="{
                  _special: flat.special,
                  _disabled: !flat.free,
                }"
                  @click="flatClickHandler(flat)"
              >
                {{ flat.rooms === 0 ? 'Cт' : flat.rooms }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import getTouchDevice from '@utils/getTouchDevice';

export default {
  name: "DChessSection",
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    resetActive: {
      type: Boolean,
      default: false
    },
    openFlat: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      flatHoverID: null,
      hoverFloorID: null,
      showPopup: false,
      activeFlatID: null,
      currentFlatData: null,
      isTouchDevice: null,
    };
  },
  computed: {
    reverseFloors() {
      const floors = [...this.data.floors];
      return floors.reverse();
    },
  },
  watch: {
    resetActive(value) {
      if (value) {
        this.activeFlatID = null
      }
    },
    openFlat(value) {
      console.log('currentFlat', value)
      if (!value) {
        this.activeFlatID = null
      }
    }
  },
  created() {
    this.isTouchDevice = getTouchDevice();
  },
  methods: {
    flatClickHandler(flat) {
      this.activeFlatID = flat.id;
      this.$emit('clickedFlat', flat);
    },
    createTooltip(e, flat) {
      if (this.isTouchDevice) return;
      const isNewLabel = 'is_new' in flat && flat.is_new ? '<span class="flat-card-chess__sticker _new">new</span>': ''

      let tplFlatLabels = ``;
      if('labels' in flat) flat.labels.forEach(label => tplFlatLabels += `<div class="d-flat-action" style="color: ${ label.color }; background-color: ${ label.background_color }"><div class="d-flat-action__name">${ label.title.replace(' ', ' ') }</div></div>`);

      const tpl = `
      <a class="d-visual-floor-info _d-chess-section" href="${ flat.url }" target="_blank">
        <div class="d-visual-floor-info__title">${ flat.title } ${ isNewLabel }</div>
        <div class="d-visual-floor-info__labels">${ tplFlatLabels }</div>
        <div class="d-visual-floor-info__object">${ flat.project ? flat.project : '' } </div>
        <div class="d-visual-floor-info__plan"><img src="${ flat.plan }" /></div>
        <div class="d-visual-floor-info__location"> ${ flat.location }</div>
        <div class="d-visual-floor-info__area">${ flat.area } м² </div>
        <div class="d-visual-floor-info__price${ flat.price_request && flat.price_request_label? ' _price-request' : '' }">
          <div class="d-visual-floor-info__price-old">${ flat.oldPrice ? flat.oldPrice.toLocaleString('ru-RU') : '' }</div>` +
                (flat.price_request && flat.price_request_label
                 ? `<span>${ flat.price_request_label.replace(' ', ' ') }</span>`
                 : `<span>${ flat.price.toLocaleString('ru-RU') } ₽</span>`) +
                `</div>
      </a>
      `;

      //console.log('e.target', e.target._tippy)

      if (!flat.commercial && flat.free && !e.target._tippy) {
        //console.log(data)
        tippy(e.target, {
          // trigger: 'click',
          allowHTML: true,
          interactive: true,
          appendTo: () => document.body,
          offset: [0, 8],
          // interactiveDebounce: 10,
          theme: 'chess-flat',
          placement: 'left-end',
          animation: 'fade',
          content: tpl,
          arrow: false,
          touch: false,

        });
      }


    },
  },
}
</script>
