<template>
  <div class="d-commercial-purposes">
    <div v-for="item in data" :key="item.pk" class="d-commercial-purposes__item">
      <div class="d-checkbox" :class="{ _disabled: item.disabled}">
        <input
            class="d-checkbox__input"
            type="checkbox" name="furnish"
            :id="`filter-checkbox${item.pk}-id${id}`"
            v-model="selectedPurpose"
            :value="item.pk"
            @change="sendData"
        >
        <label class="d-checkbox__label" :for="`filter-checkbox${item.pk}-id${id}`">{{ item.filter_title }}</label>
      </div>
      <div class="d-commercial-purposes__count">{{ item.count }}</div>
    </div>

  </div>

</template>

<script>
import { intersection as _intersection, uniqueId as _uniqueId } from "lodash";
export default {
  name: "DFilterCheckboxes",
  props: {
    data: {
      type: Array,
      default: () => {},
      require: true
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      id: null,
      selectedPurpose: []
    }
  },
  watch: {
    modelValue(data) {
      this.selectedPurpose = _intersection(data, this.selectedPurpose)
    }
  },
  mounted() {
    this.id = _uniqueId()
    if (this.modelValue.length) {
      this.selectedPurpose = this.modelValue;
    }
  },
  methods: {
    sendData() {
      this.$emit('update:modelValue', this.selectedPurpose)
    }
  }
}
</script>

<style scoped>

</style>