<template>
  <div class="d-arrows-slider">
    <button class="d-arrows-slider__arrow d-button _slider _prev" :class="modClass">
      <span></span>
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"/>
      </svg>
    </button>
    <button class="d-arrows-slider__arrow d-button _slider _next" :class="modClass">
      <span></span>
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DSliderArrows',
  props: {
    name: String,
    mod: {
      type: String,
      default: '',
    },
  },
  computed: {
    modClass() {
      const result = [];

      if (this.mod) {
        if (Array.isArray(this.mod)) {
          this.mod.forEach((mod) => {
            result.push(`_${mod}`)
          });
        } else {
          result.push(`_${this.mod}`)
        }
      }
      if (this.name) result.push(this.name)
      return result;
    },
  },
};
</script>

<style scoped>

</style>
