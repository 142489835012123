<template>
  <d-popup v-slot="{ close }" v-bind="$attrs">
    <div class="d-callMe__success">
      <div class="d-callMe__title">{{ title }}</div>
      <div class="d-callMe__subtitle" v-html="content"></div>
      <img src="/assets/blueant/assets/img/d/callMe_bg_success.jpg" class="d-callMe__bg">
      <button class="d-callMe__button _success d-button _blue" @click="close"><span>{{ buttonTitle }}</span></button>
    </div>
  </d-popup>
</template>

<script>
import DPopup from '@components/molecules/DPopup.vue';

export default {
  name: 'DPopupSuccess',
  components: { DPopup },
  props: {
    content: {
      type: String,
      default: 'Скоро наши менеджеры свяжутся с вами'
    },
    title: {
      type: String,
      default: 'Заявка отправлены'
    },
    buttonTitle: {
      type: String,
      default: 'Хорошо, спасибо'
    },
  }
};
</script>
