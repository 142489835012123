<template>
  <div class="d-drop-sorter d-select" :class="Object.assign({}, { _open: isOpen }, modClass)"
    v-click-outside="onClickOutside">
    <div v-if="singleSelectedEmpty" class="d-select__head" @click="toggleSelect">
      <span>{{ singleSelectedPlaceholder.title }}</span>
    </div>
    <div v-else class="d-select__head d-drop-sorter__head _active" :class="{ _desc: singleSelected.sortOrder === 'desc', _asc: singleSelected.sortOrder === 'asc'  }" @click="toggleSelect">
      <svg>
        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-sort2"></use>
      </svg>
      <span>{{ singleSelected.title }}</span>
    </div>
    <div v-show="isOpen" ref="dropdown" class="d-select__drop">
      <div class="d-select__list" data-simplebar>
        <div v-for="(item, index) in options" :key="index" class="d-select__item d-drop-sorter__item"
          :class="{ _active: checkActiveOption(item), _desc: item.sortOrder === 'desc', _asc: item.sortOrder === 'asc'  }" :data-value="item.title" @click="chooseSelect(item)">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import { isEmpty as _isEmpty, isEqual as _isEqual, cloneDeep as _cloneDeep } from 'lodash';
import eventPath from '@utils/eventPath';
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: 'DDropSorter',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  emits: ['update:model-value', 'update:modelValue'],
  props: {
    dataOptions: {
      type: [String, Array, Boolean],
      required: true,
      default: false,
    },
    modelValue: {
      type: [Array, String, Number, Object, Boolean],
      required: false,
      default: false,
    },
    dataPlaceholder: {
      type: [Number, String],
      default: '',
    },
    dataReturnParam: {
      type: [String, Boolean],
      default: false,
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
    mod: {
      type: [String, Array],
      default: ''
    }
  },
  computed: {
    singleSelectedEmpty() {
      return _isEmpty(this.singleSelected);
    },
    modClass() {
      return additionalClassCSS(this.mod);
    }
  },
  watch: {
    modelValue(newOptions) {
      this.setInitValue();
    },
    singleSelected(newValue) {
      this.sendData();
    },
    dataOptions() {
      this.isOpen = false;
      this.initOptions();
      if (this.modelValue !== false) {
        this.setInitValue();
      }
      else this.singleSelected = this.options[0];
    },
    reset() {
      if (this.reset) {
        this.singleSelected = {};
      }
    },
  },
  created() {
    this.initOptions();
    //console.log(`%c${ this.$options.name } created this.modelValue = `, 'color: yellow', this.modelValue);
    if (this.modelValue !== false) {
      this.setInitValue();
    }
  },
  data() {
    return {
      value: '',
      selectItem: '',
      singleSelected: {},
      singleSelectedPlaceholder: { disabled: false, value: null, name: '' },
      isOpen: false,
      options: [],
    }
  },
  methods: {
    initOptions() {
      if (this.dataOptions && (typeof this.dataOptions).toLowerCase() === 'string') this.options = JSON.parse(this.dataOptions);
      else if (this.dataOptions) this.options = _cloneDeep(this.dataOptions);
      this.singleSelectedPlaceholder.name = this.dataPlaceholder;
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
    },
    chooseSelect(item) {

      //console.log(`%c${this.$options.name} chooseSelect item = `, 'color: yellow', item);
      this.isOpen = !this.isOpen;
      this.singleSelected = item;
      //console.log(`%c${this.$options.name} singleSelected item = `, 'color: yellow', this.singleSelected);
      if (_isEqual(this.singleSelected, item)) {
        //console.log(`%c${this.$options.name} _isEqual(this.singleSelected, item)) = `, 'color: yellow', true);
        this.isActive = true;
      }
    },
    checkActiveOption(item) {
      return this.singleSelected ? _isEqual(this.singleSelected, item) : false;
    },
    onClickOutside(event) {
      const dropDownRef = this.$refs.dropdown;
      const path = eventPath(event);
      if (!path.includes(dropDownRef)) {
        this.isOpen = false;
      }
    },
    setInitValue() {
      //console.log(`%c${ this.$options.name } setInitValue this.modelValue = `, 'color: yellow', this.modelValue);
      this.singleSelected = this.dataReturnParam
        ? this.options.find((item) => _isEqual(item[this.dataReturnParam], this.modelValue))
        : this.options.find((item) => _isEqual(item, this.modelValue));
    },
    getReturnValue() {
      if (_isEmpty(this.singleSelected)) {
        return null;
      }
      else {
        return this.dataReturnParam ? this.singleSelected[this.dataReturnParam] : this.singleSelected;
      }
    },
    sendData() {
      const value = this.getReturnValue();
      if (!_isEqual(this.modelValue, value)) this.$emit('update:modelValue', value);
    },
  }
}
</script>

<style scoped>
::v-deep(svg) {
  fill: currentColor
}
::v-deep(svg path) {
  fill: currentColor
}
</style>
