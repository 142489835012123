<template>
  <div v-if="mode === 'flat'" class="d-favorites__section">
    <div class="d-favorites-section">
      <div class="d-favorites-section__title skeleton-field"></div>
      <div class="d-favorites-section__list">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div v-for="i in [1, 2, 3]" class="swiper-slide">
              <div class="d-flat-card _skeleton">
                <div class="d-flat-card__top">
                  <div class="d-flat-card__title skeleton-field"></div>
                  <div class="d-flat-card__position">
                    <div class="d-flat-card__object skeleton-field"></div>
                    <div class="skeleton-field"></div>
                  </div>
                </div>
                <div class="d-flat-card__content">
                  <div class="d-flat-card__img">
                    <div class="skeleton-field"></div>
                  </div>
                  <div class="d-flat-card__info">
                    <div class="d-flat-card__square skeleton-field"></div>
                    <div class="d-flat-card__price skeleton-field"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-favorites-pdf-info">
        <div class="d-favorites-pdf-info__item _item1">
          <div class="d-favorites-pdf-info__title skeleton-field"></div>
          <div class="d-favorites-pdf-info__size skeleton-field"></div>
        </div>
        <div class="d-favorites-pdf-info__item _item2">
          <span class="d-button _flatIcon _favorites skeleton-field">
          </span>
          <span class="d-button _flatIcon _favorites skeleton-field">
          </span>
          <span class="d-button _flatIcon _favorites skeleton-field">
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-favorites__section">
    <div class="d-favorites-section">
      <div class="d-favorites-section__list _aux">
        <div v-for="i in [1, 2, 3]" :key="i" class="d-aux-rooms-card _auxRoomSkeleton">
          <div class="d-aux-rooms-card__top">
            <div class="d-aux-rooms-card__name skeleton-field"></div>
            <div class="d-aux-rooms-card__buttons">
              <div class="skeleton-field"></div>
              <div class="skeleton-field"></div>
            </div>
          </div>
          <div class="d-aux-rooms-card__info">
            <div class="skeleton-field"></div>
            <div class="skeleton-field"></div>
            <div class="skeleton-field"></div>
            <div class="skeleton-field"></div>
            <div class="skeleton-field"></div>
            <div class="skeleton-field"></div>
          </div>
          <div class="d-aux-rooms-card__bottom">
            <div class="d-aux-rooms-card__price skeleton-field"></div>
            <div class="d-aux-rooms-card__order skeleton-field"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DFavoritesPreloader',
  props: {
    mode: {
      type: String,
      default: 'flat',
    },
  },
};
</script>

<style scoped>

</style>
