<template>
  <div v-if="display === 'card'" class="d-aux-rooms__list">
    <template v-if="showMore || !loading">
      <d-parking-place-card v-for="n in showItems"
                            :currentItem="n"
                            :key="n.id"
                            :is-favorite="isFavorite(n.form_id)"
                            class="d-aux-rooms-card"
                            :class="`_${mod}`"
                            @openItem="openItem"
                            @set-favorite="$emit('set-favorite', $event)"/>
    </template>
    <template v-if="loading || !dataLoaded">
      <d-parking-place-card-skeleton v-for="n in per_page" :key="n" class="d-aux-rooms-card _auxRoomSkeleton"/>
    </template>
    <d-search-pager :current-page-index="result.page" :pages-count="result.pages"/>
  </div>
  <div v-else class="d-aux-rooms__list _list">
    <d-search-sort v-model:sort="currentSort" :columns="sort.line" :options="sort.options" :mod="mod"/>
    <template v-if="showMore || !loading">
      <d-parking-place-line v-for="n in showItems"
                            :key="n.id"
                            :currentItem="n"
                            :is-favorite="isFavorite(n.form_id)"
                            class="d-aux-rooms-line"
                            :class="`_${mod}`"
                            @openItem="openItem"
                            @set-favorite="$emit('set-favorite', $event)"/>
    </template>
    <template v-if="loading|| !dataLoaded">
      <d-parking-place-line-skeleton v-for="n in per_page" :key="n" class="d-aux-rooms-line _carPlaceSkeleton"/>
    </template>
    <d-search-pager :current-page-index="result.page" :pages-count="result.pages"/>
  </div>
</template>

<script>
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore, useItemStore} from '@/stores/search';
import DParkingPlaceCardSkeleton from '@components/atoms/DParkingPlaceCardSkeleton.vue';
import DParkingPlaceLineSkeleton from '@components/atoms/DParkingPlaceLineSkeleton.vue';
import DParkingPlaceCard from '@components/molecules/DParkingPlaceCard.vue';
import DParkingPlaceLine from '@components/molecules/DParkingPlaceLine.vue';
import DSearchSort from '@components/molecules/DSearchSort.vue';
import DSearchPager from '@components/molecules/DSearchPager.vue';

export default {
  name: 'DSearchResultWrapper',
  components: {DSearchPager, DSearchSort, DParkingPlaceCard, DParkingPlaceLine, DParkingPlaceCardSkeleton, DParkingPlaceLineSkeleton},
  emits: ['changePage', 'set-favorite'],
  inheritAttrs: false,
  props: {
    mod: {
      type: String,
      default: '',
    },
    favorites: {
      type: Array,
      default: null,
    },
  },
  computed: {
    currentSort: {
      get() {
        return this.sort.selected;
      },
      set(value) {
        this.sort.selected = value;
      },
    },
    ...mapWritableState(useItemStore, [
      'item', 'showItemPopup',
    ]),
    ...mapWritableState(useSearchStore, [
      'sort',
      'showMore',
      'selectedItemId',
    ]),
    ...mapState(useSearchStore, [
      'result',
      'loading',
      'dataLoaded',
      'errored',
      'display',
      'sort',
      'showMore',
      'per_page',
      'showItems',
      'getItemByFormId',
    ]),
  },
  watch: {
    dataLoaded: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadItemFromUrl();
        }
      },
    },
    getItemByFormId: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.openItem(value);
        }
      },
    },
  },
  methods: {
    loadItemFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const openItemId = urlParams.get('open');
      if (openItemId) {
        this.selectedItemId = openItemId;
      }
    },
    openItem(item) {
      this.showItemPopup = true;
      this.item = item;
    },
    isFavorite(form_id) {
      return window.inFavorites(form_id)
    }
  },
};
</script>
