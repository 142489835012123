<template>
  <div class="d-floor-slider">
    <div ref="container" class="d-floor-slider__inner _buttons">
      <div class="d-floor-slider__pagination swiper-pagination-custom">
        <div v-for="floor in floorsPager" role="button" class="swiper-pagination-bullet"
             :class="{ '_unavail': !floor.avail, '_avail': floor.avail, 'swiper-pagination-bullet-prev': floor.index === prevFloorIndex, 'swiper-pagination-bullet-active': floor.index === currentFloorIndex}"
             :data-floor-index="floor.index" :ref="`floor-${floor.num}`" @click="changeFloor(floor.num)">{{ floor.num }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {pick as _pick} from 'lodash';

export default {
  name: 'DFloorButtons',
  emits: ['changeFloor'],
  props: {
    floors: {
      type: Array,
      default: () => [],
    },
    currentFloorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prevFloorIndex: 0,
    };
  },
  watch: {
    currentFloorIndex(newValue, oldValue) {
      this.prevFloorIndex = oldValue;
      if (newValue >= 0) this.$nextTick(() => {
        this.reposition();
      });
    },
  },
  created() {
    window.addEventListener('resize', this.reposition);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.reposition);
  },
  mounted() {
    this.reposition(false);
  },
  computed: {
    floorsPager() {
      return [...this.floors].map((el, index) => {
        const floor = _pick(el, ['num', 'flats']);
        floor.index = index;
        floor.avail = floor.flats.filter((flat) => flat.available).length > 0;
        delete floor.flats;
        return floor;
      });
    },
  },
  methods: {
    reposition() {
      try {
        const target = this.$refs[ `floor-${ this.floorsPager[this.currentFloorIndex].num }`][0];
        if (target && this.$refs.container) {
          this.$refs.container.scrollLeft = (target.offsetLeft + target.offsetWidth / 2) - this.$refs.container.offsetWidth / 2;
        }
      } catch (e) {}
    },
    changeFloor(num) {
      this.$emit('changeFloor', num);
    },
  },
};
</script>

