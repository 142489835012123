<template>
  <div :class="classList" class="d-checkbox">
    <input :id="id" v-model="checked" :name="name" :type="type" :value="value" :disabled="disabled" class="d-checkbox__input" />
    <label :for="id" class="d-checkbox__label">
      <svg v-if="icon"><use :xlink:href='`/assets/blueant/assets/sprite.svg#sprite-${icon}`'></use></svg>
      <slot name="default"></slot>
    </label>
  </div>
</template>

<script>
import additionalClassCSS from '@utils/additionalClassCSS';

export default {
  name: 'DCheckbox',
  emits: ['update:model-value', 'update:modelValue'],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    mod: {
      type: [Array, String],
      default: '',
    },
    theme: {
      type: [Array, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String, Number, Object, Boolean],
      default: () => [],
    },
    modelValue: {
      type: [String, Number, Object, Boolean],
      default: '',
    },
    reset: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    themeClass() {
      return additionalClassCSS(this.theme);
    },
    disableClass() {
      const obj = {};
      if (this.disabled) {
        obj._disabled = true;
      }
      return obj;
    },
    classList() {
      return Object.assign({}, this.modClass, this.themeClass, this.disableClass);
    },
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  watch: {
    reset() {
      if (this.reset) {
        this.selected = 0;
      }
    },
  },
};
</script>
