const companyCommon = (page) => {
    $(document).ready(function(e) {
        const $tabButtonActive = $('.d-tabs__buttons .d-tabs__button._active');
        if ($tabButtonActive.hasClass('_scroll')) {
            const container = $tabButtonActive.parent().parent();
            const inner = $tabButtonActive.parent();
            if (container.innerWidth() < inner.outerWidth()) {
                container.get(0).scrollLeft =
                    ($tabButtonActive.position().left + $tabButtonActive.outerWidth() / 2) - container.innerWidth() / 2 + inner.get(0).offsetLeft
            }
        }
    });

    function showISButton() {
        $('[data-is-button-wrap]').addClass('_show');
        $('[data-is-button-parent]').addClass('_showISButton');
        $('.d-tabs__buttons').css('opacity', '0')
    }
    function hideISButton() {
        $('[data-is-button-wrap]').removeClass('_show');
        $('[data-is-button-parent]').removeClass('_showISButton');
        $('.d-tabs__buttons').css('opacity', '')
    }
    if (page !== 'vacancy') {
        $(window).on('scroll', function() {
            if (window.matchMedia("(max-width: 1279px)").matches) {
                if ($(window).scrollTop() > $('[data-is-button-parent]').offset().top) {
                    showISButton();
                } else {
                    hideISButton();
                }
            } else {
                hideISButton();
            }
        })
    }

    $('[data-button-up]').on('click', function () {
        $('html, body').animate({
            scrollTop:0
        }, 400);
    });
    $('[data-is-button-show]').on('click', function(e) {
        $('header').css('z-index', '1')
        $('.d-tabs__buttons').addClass('_show')
    })
    $('[data-is-button-close]').on('click', function(e) {
        hideIS()
    })
    function hideIS() {
        $('header').css('z-index', '')
        $('.d-tabs__buttons').removeClass('_show')
    }
}

export default companyCommon;