<template>
  <div class="d-visual-quarters">
    <d-visual-header v-if="headerDataConvertToObject" :data="headerDataConvertToObject" :chess-legend="false" @changeType="changeType"/>
    <div v-if="type === '3d'" class="d-visual-genplan" ref="genplan" :style="`${heightPlan}`">
      <d-swipe-zoom-info v-if="!swipeInfoHide" class="d-visual-genplan__swipe" @close="closeSwipeInfo()">
        Используйте жесты для прокрутки изображения
      </d-swipe-zoom-info>
      <div v-if="premiumAvailable" class="d-object-single-genplan-legend">
        <div class="d-object-single-genplan-legend__item">Бизнес</div>
        <div class="d-object-single-genplan-legend__item">Премиум</div>
      </div>
      <d-disclaimer v-if="disclaimer" class="d-visual-genplan__disclaimer" :text="disclaimer"/>
      <img v-if="genplanRender3d" :src="genplanRender3d.render.compass" class="d-visual-genplan__compass"/>
      <d-fitblock v-if="genplanRender3d" :render="genplanRender3d.render" auto-scroll>
        <template #labels>
          <div v-for="(label, index) in currentLabels"
               :key="label.id"
               :ref="setItemRef"
               data-append-to="genplan"
               :data-tooltip="index"
               :data-label-id="label.id"
               class="d-object-single-genplan-point"
               :class="{_hover:hoverLabelId === label.id,  _premium: label.premium}"
               :style="`left: ${label.position.left}%; top: ${label.position.top}%;`"
               @mouseover="hoverLabelId = label.id"
               @mouseleave="hoverLabelId = null"
               @click="openPopup(label)">
            <div class="d-object-single-genplan-point__name">{{ label.title }}</div>
          </div>
        </template>
        <template #masks>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanRender3d.render.width"
               :height="genplanRender3d.render.height"
               :viewBox="`0 0 ${genplanRender3d.render.width} ${genplanRender3d.render.height}`">
            <path v-for="(label, index) in currentLabels"
                  :data-label-id="label.id"
                  :key="label.id"
                  class="d-visual-genplan__path"
                  :class="{_premium: label.premium, _hover:hoverLabelId === label.id }"
                  :d="label.points"
                  @click="openPopup(label)"/>
          </svg>
        </template>
      </d-fitblock>
    </div>
    <div v-if="type === '2d'" class="d-visual-genplan _2d" ref="genplan2d" :style="`${heightPlan2d}`">
      <d-swipe-zoom-info v-if="!swipeZoomInfoHide" class="d-visual-genplan__swipe" mod="pinchzoom" @close="closeSwipeZoomInfo()">
        Используйте жесты для перемещения и масштабирования изображения
      </d-swipe-zoom-info>
      <div v-if="premiumAvailable" class="d-object-single-genplan-legend">
        <div class="d-object-single-genplan-legend__item">Бизнес</div>
        <div class="d-object-single-genplan-legend__item">Премиум</div>
      </div>
      <img v-if="genplanRender3d" :src="genplanRender3d.render2d.compass" class="d-visual-genplan__compass"/>
      <d-fitblock v-if="genplanRender3d && !screenXS" :render="genplanRender3d.render2d" :autoScroll="true">
        <template #masks>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanRender3d.render2d.width"
               :height="genplanRender3d.render2d.height"
               :viewBox="`0 0 ${genplanRender3d.render2d.width} ${genplanRender3d.render2d.height}`">
            <path v-for="(label, index) in currentLabels"
                  :ref="setItemRef"
                  data-append-to="genplan2d"
                  :data-tooltip="index"
                  :key="label.id"
                  :data-label-id="label.id"
                  class="d-visual-genplan__path"
                  :class="{_premium: label.premium, _hover:hoverLabelId === label.id, _empty: !label.allowFlats }"
                  :d="label.points2d ? label.points2d : coords2poins(label.coords, label.shape)"
                  @click="openPopup(label)"/>
          </svg>
        </template>
      </d-fitblock>
      <pinch-scroll-zoom v-if="genplanRender3d && screenXS && planWidth && planHeight"
                         ref="zoomer"
                         :width="planWidth"
                         :height="planHeight"
                         :scale="zoom"
                         :within="true"
                         :contentWidth="planWidth"
                         :contentHeight="planHeight"
                         class="d-visual-genplan__zoom-wrapper"
                         :minScale="1"
                         :maxScale="20">
        <div class="d-visual-genplan__zoom">
          <img :src="genplanRender3d.render2d.img" alt="" :width="genplanRender3d.render2d.width" :height="genplanRender3d.render2d.height"/>
          <svg class="d-visual-genplan__svg"
               xmlns="http://www.w3.org/2000/svg"
               :width="genplanRender3d.render2d.width"
               :height="genplanRender3d.render2d.height"
               :viewBox="`0 0 ${genplanRender3d.render2d.width} ${genplanRender3d.render2d.height}`">
            <path v-for="(label, index) in currentLabels"
                  :ref="setItemRef"
                  data-append-to="genplan2d"
                  :data-tooltip="index"
                  :key="label.id"
                  :data-label-id="label.id"
                  class="d-visual-genplan__path"
                  :class="{_premium: label.premium, _hover:hoverLabelId === label.id, _empty: !label.allowFlats }"
                  :d="label.points2d ? label.points2d : coords2poins(label.coords, label.shape)"
                  @click="openPopup(label)"/>
          </svg>
        </div>
      </pinch-scroll-zoom>
    </div>
    <d-popup-simple v-if="quarterMobileInfo" v-model="quarterMobilePopupShow" mod="center">
      <div class="d-builds-info">
        <div v-if="quarterMobileInfo.isQuarter" class="d-builds-info__title">Квартал {{ quarterMobileInfo.title }}</div>
        <div v-else class="d-builds-info__title">{{ quarterMobileInfo.title }}</div>
        <div v-if="quarterMobileInfo.buildings" class="d-builds-info__numbers">Корпуса: {{ quarterMobileInfo.buildings }}</div>
        <div class="d-builds-info__about" v-html="quarterMobileInfo.text"></div>
        <div class="d-builds-info__facts">
          <div v-if="quarterMobileInfo.buildCount" class="d-builds-info__factsItem">
            <div class="d-builds-info__factsValue">{{ quarterMobileInfo.buildCount }}</div>
            <div class="d-builds-info__factsLabel">корпусов</div>
          </div>
          <div class="d-builds-info__factsItem">
            <div class="d-builds-info__factsValue">{{ quarterMobileInfo.floors[0] }}-{{ quarterMobileInfo.floors[1] }}</div>
            <div class="d-builds-info__factsLabel">этажей</div>
          </div>
          <div class="d-builds-info__factsItem">
            <div class="d-builds-info__factsValue">{{ quarterMobileInfo.allowFlats }}</div>
            <div class="d-builds-info__factsLabel">свободных квартир</div>
          </div>
          <div class="d-builds-info__factsItem">
            <div class="d-builds-info__factsValue">{{ quarterMobileInfo.areas[0] }}-{{ quarterMobileInfo.areas[1] }}</div>
            <div class="d-builds-info__factsLabel">площадь,&nbsp;м&sup2;</div>
          </div>
        </div>
        <div class="d-builds-info__flats" :class="{_long: quarterMobileInfo.flats.length > 6}">
          <div v-for="flat in quarterMobileInfo.flats" :key="flat.rooms" class="d-builds-info__flat">
            <div class="d-builds-info__flatLabel">{{ flat.rooms === 0 ? 'Студии' : `${ flat.rooms }-комн.` }}</div>
            <div v-if="flat.price_request && flat.price_request_label" class="d-builds-info__flatValue">{{ flat.price_request_label }}</div>
            <div v-else class="d-builds-info__flatValue">от {{ flat.priceMin }} млн ₽</div>
          </div>
        </div>
        <div v-if="quarterMobileInfo.bld_arr" class="d-builds-info__sections">
          <div class="d-choose-builds-info__label">{{ quarterMobileInfo.bld_arr.name }}</div>
          <div class="d-choose-builds-info__list">
            <swiper class="d-choose-builds-info__slider" v-bind="swiperOpts">
              <swiper-slide v-for="item in quarterMobileInfo.bld_arr.data" :key="item.id" class="d-checkbox d-choose-builds-info__checkbox _full">
                <input class="d-checkbox__input" v-model="currentBuilding" type="radio" name="build" :id="`bld-${item.id}`" :value="item.name" :disabled="!item.floors.length"/>
                <label class="d-checkbox__label" :class="{_disabled: !item.floors.length}" :for="`bld-${item.id}`">{{ item.name }}</label>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div v-if="buildingLink" class="d-builds-info__bottom">
          <a class="d-builds-info__button d-button _dark" :href="buildingLink"><span>Выбрать на схеме</span></a>
        </div>
        <div v-if="floorsList" class="d-visual-building-info__sections d-choose-builds-info__floor">
          <div class="d-choose-builds-info__label">Выберите этаж</div>
          <div class="d-choose-builds-info__list">
            <swiper class="d-choose-builds-info__slider" v-bind="swiperOpts">
              <swiper-slide v-for="floor in floorsList" :key="floor.id" class="d-checkbox d-choose-builds-info__checkbox _full" :class="{ _disabled: floor.disabled }">
                <input class="d-checkbox__input" v-model="currentFloor" type="radio" name="floor" :id="`floor-${floor.id}`" :value="floor.name"/>
                <label class="d-checkbox__label" :class="{_disabled: floor.disabled}" :for="`floor-${floor.id}`">{{ floor.name }}</label>
              </swiper-slide>
            </swiper>

          </div>
        </div>
        <div v-if="floorLink" class="d-builds-info__bottom">
          <a class="d-builds-info__button d-button _dark" :href="floorLink"><span>Выбрать на этаже</span></a>
        </div>
        <div v-else class="d-builds-info__bottom">
          <a class="d-builds-info__button d-button _dark" :href="linkPopupToBuilding(quarterMobileInfo.url)"><span>Выбрать квартиру</span></a>
        </div>
      </div>
    </d-popup-simple>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import Cookies from 'js-cookie';
import DVisualHeader from '@components/molecules/DVisualHeader.vue';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import DPopupSimple from '@components/molecules/DPopupSimple.vue';
import DFitblock from '@components/molecules/DFitblock.vue';
import PinchScrollZoom from '@coddicat/vue3-pinch-scroll-zoom';
import coords2path from '@utils/area2svg';
import setPlanHeight from '@utils/setPlanHeight';
import DDisclaimer from '@components/atoms/DDisclaimer.vue';
import { Swiper, SwiperSlide  } from 'swiper/vue';

export default {
  name: 'DVisualQuarters',
  components: {
    DVisualHeader,
    DFitblock,
    DPopupSimple,
    DSwipeZoomInfo,
    PinchScrollZoom,
    DDisclaimer,
    Swiper,
    SwiperSlide,
  },
  props: {
    headerData: {
      type: String,
      default: '',
    },
    data3d: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      type: null,
      genplanRender3d: null,
      labelsData3d: null,
      labelsData2d: null,
      hoverLabelId: null,
      itemRefs: [],
      quarterMobileInfo: null,
      quarterMobilePopupShow: false,
      swipeInfoHide: null,
      swipeZoomInfoHide: null,
      chooseParamsLink: null,
      heightPlan: null,
      heightPlan2d: null,
      currentBuilding: null,
      currentFloor: null,
      disclaimer: null,
      zoom: 1,
      screenXS: false,
      planHeight: 0,
      planWidth: 0,
    };
  },
  computed: {
    headerDataConvertToObject() {
      return JSON.parse(this.headerData);
    },
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    labelsArray() {
      let labelsArray = [];
      if (this.labelsData3d && this.labelsData3d.length) labelsArray = labelsArray.concat(this.labelsData3d);
      if (this.labelsData2d && this.labelsData2d.length) labelsArray = labelsArray.concat(this.labelsData2d);
      return labelsArray;
    },
    currentLabels() {
      if (this.type === '2d' && this.labelsData2d && this.labelsData2d.length) return this.labelsData2d;
      return this.labelsData3d;
    },
    premiumAvailable() {
      if (!this.labelsData3d && !this.labelsData2d) {
        return null;
      }
      return this.labelsArray.find((label) => {
        return label.premium;
      });
    },
    floorsList() {
      if (this.currentBuilding) {
        const building = this.quarterMobileInfo.bld_arr.data.find((bld) => bld.name === this.currentBuilding);
        //console.log('building.floors', building.floors);
        return building.floors;
      }
      return null;
    },
    floorLink() {
      if (this.floorsList) {
        const currentFloor = this.floorsList.find((floor) => floor.name === this.currentFloor);
        //console.log('currentFloor', this.floorsList, this.currentFloor);
        if (currentFloor) return currentFloor.link;

      }
      return null;
    },
    buildingLink() {
      if (this.currentBuilding) {
        const currentBuilding = this.quarterMobileInfo.bld_arr.data.find((bld) => bld.name === this.currentBuilding);
        // if (currentBuilding) return this.type === '3d' ?  currentBuilding.link : currentBuilding.chessLink;
        if (currentBuilding) return currentBuilding.chessLink;
      }
      return null;
    },
    swiperOpts() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 0,
      }
    },
  },
  watch: {
    currentBuilding() {
      if(this.currentBuilding !== null) this.currentFloor = this.setInitFloorSection(this.floorsList);
      //console.log('this floorsList', this.floorsList);
    },
  },
  created() {
    this.getData3d();
  },
  mounted() {
    this.swipeInfoHide = Number(Cookies.get('closedSwipeInfoVisualQuarters')) === 1;
    this.swipeZoomInfoHide = Number(Cookies.get('closedSwipeZoomInfoVisualQuarters')) === 1;
    this.$nextTick(() => {
      this.setHeightPlan(this.type === '3d' && !this.heightPlan);
      window.addEventListener('resize', this.setHeightPlan);
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.setHeightPlan);
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  updated() {
    this.itemRefs.forEach((item) => {
      this.createTooltip(item);
    });
  },
  methods: {
    coords2poins(coords, shape) {
      return coords2path(coords.split(',').map(function(num) { return Number(num);}), shape);
    },
    async getData3d() {
      try {
        let response = await this.axios.get(this.data3d);
        //console.log('response genplan quarter 3d', response);
        this.genplanRender3d = response.data.render;
        this.labelsData3d = response.data.labels;
        this.labelsData2d = response.data.labels2D;
        this.chooseParamsLink = response.data.info.choose_params_link;
        this.disclaimer = response.data.info.disclaimer ? response.data.info.disclaimer : null;
      } catch (err) {
        console.error('error load genplan quarter 3d data', err);
      }
    },
    changeType(value) {
      this.type = value;
      console.log('changeType', value);
      this.setHeightPlan(value === '3d' && !this.heightPlan);
      console.log('changeType() currentLabels', this.currentLabels);
    },

    setItemRef(el) {
      if (el) {
        this.itemRefs.push(el);
      }
    },
    createTooltip(target) {
      const data = this.currentLabels[target.dataset.tooltip];
      const appendTo = target.dataset.appendTo;
      let tplFlats = ``;
      data.flats.forEach((flat) => {
        tplFlats += `
          <div class="d-builds-info__flat">
            <div class="d-builds-info__flatLabel">${ flat.rooms === 0 ? 'Студии' : `${ flat.rooms }-комн.` } </div>`+
          (flat.price_request && flat.price_request_label
          ? `<div class="d-builds-info__flatValue">${ flat.price_request_label }</div>`
          : (data.allowFlats > 1
               ?  `<div class="d-builds-info__flatValue">от ${ flat.priceMin } млн ₽</div>`
               :  `<div class="d-builds-info__flatValue">${ flat.priceMin } млн ₽</div>`)
          ) + `</div>
        `;
      });

      let tpl = '<div class="d-builds-info">';

      if (data.isQuarter) {
        tpl += `<div class="d-builds-info__title">Квартал ${ data.title }</div>`;
      }
      else tpl += `<div class="d-builds-info__title">${ data.title }</div>`;

      if (data.buildings) {
        tpl += `<div class="d-builds-info__numbers">Корпуса: ${ data.buildings }</div>`;
      }

      tpl += `
          <div class="d-builds-info__about">
            ${ data.text }
          </div>`;

      if (data.allowFlats && data.buildCount) {
        tpl += `
          <div class="d-builds-info__facts">
            <div class="d-builds-info__factsItem">
              <div class="d-builds-info__factsValue">${ data.buildCount }</div>
              <div class="d-builds-info__factsLabel">корпусов</div>
            </div>`;
      }
      else if (data.allowFlats) {
        tpl += `
          <div class="d-builds-info__facts">`;
      }
      else
        tpl += `
          <div class="d-builds-info__facts"><p class="_empty">Все квартиры проданы</p></div>
        </div>`;

      if (data.allowFlats)
        tpl += `
            <div class="d-builds-info__factsItem">
              <div class="d-builds-info__factsValue">${ data.floors[0] }-${ data.floors[1] }</div>
              <div class="d-builds-info__factsLabel">этажей</div>
            </div>
            <div class="d-builds-info__factsItem">
              <div class="d-builds-info__factsValue">${ data.allowFlats }</div>
              <div class="d-builds-info__factsLabel">свободных квартир</div>
            </div>
            <div class="d-builds-info__factsItem">
              <div class="d-builds-info__factsValue">${ data.areas[0] }-${ data.areas[1] }</div>
              <div class="d-builds-info__factsLabel">площадь,&nbsp;м&sup2;</div>
            </div>
          </div>
          <div class="d-builds-info__flats${data.flats.length > 6 ? ' _long' : ''}">
              ${ tplFlats }
          </div>

        </div>
      `;

      const labelId = target.dataset.labelId;
      const secondTarget = document.querySelector(`.d-visual-genplan__path[data-label-id="${ labelId }"]`);

      tippy(target, {
        // trigger: 'click',
        allowHTML: true,
        interactive: true,
        //interactiveDebounce: 100000,
        appendTo: () => this.$refs[appendTo],
        offset: [0, 8],
        touch: false,
        theme: 'visual-quarter',
        placement: 'left-end',
        animation: 'fade',
        content: tpl,
        arrow: false,
        triggerTarget: [secondTarget, target],
      });
    },
    openPopup(data) {
      const typeParam = data.url.includes('?type')
      this.currentBuilding = null
      this.currentFloor = null
      if (data.allowFlats) {
        if (this.isTouchDevice) {
          this.quarterMobileInfo = data;
          //console.log('openPopup this.quarterMobileInfo = ', this.quarterMobileInfo)
          this.quarterMobilePopupShow = true;
          //console.log('data', data);
          if (data.bld_arr) {
            //console.log('openPopup data.bld_arr = ', data.bld_arr)
            this.currentBuilding = this.setInitFloorSection(data.bld_arr.data);
            //console.log('openPopup this.currentBuilding = ', this.currentBuilding)
            const currentBuilding = data.bld_arr.data.find((bld) => bld.init);
            if (currentBuilding) this.currentFloor = this.setInitFloorSection(currentBuilding.floors);
            else console.error('Initial building not found in data:', data.bld_arr.data);
          }

        }
        else {
          window.location.href = typeParam ? data.url : `${data.url}?type=${this.type}`;
        }
      }

    },
    closeSwipeInfo() {
      Cookies.set('closedSwipeInfoVisualQuarters', 1);
      this.swipeInfoHide = true;
    },
    closeSwipeZoomInfo() {
      Cookies.set('closedSwipeZoomInfoVisualQuarters', 1);
      this.swipeZoomInfoHide = true;
    },
    setHeightPlan(set3d = true) {
      this.screenXS = window.matchMedia('(max-width: 767px)').matches;

      this.$nextTick(() => {
        if (this.$refs.genplan && set3d) this.heightPlan = setPlanHeight(this.$refs.genplan);
        if (this.$refs.genplan2d) {
          this.heightPlan2d = setPlanHeight(this.$refs.genplan2d) + '!important';
          this.$nextTick(() => {
            this.planHeight = this.$refs.genplan2d.getBoundingClientRect().height;
            this.planWidth = window.innerWidth;
          });
        }
      });
    },
    setInitFloorSection(data) {
      const initItem = data.find((item) => item.init);
      if (initItem) return initItem.name;
      else return data[0].name;
    },
    linkPopupToBuilding(url) {
      const typeParam = url.includes('?type')
      return typeParam ? url : `${url}?type=${this.type}`;
    }
  },

};
</script>
