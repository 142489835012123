<template>
  <div class="d-mortgage__list">
    <div v-if="!empty" class="d-sorter-line _purchase">
      <button class="d-sorter-line__item" :class="{ _active: sortParam==='bank', _asc: sortParam==='bank' && sortOrder==='asc', _desc: sortParam==='bank' && sortOrder==='desc'}"
              @click="setSortParam('bank')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Банк</span>
        <span class="short">Банк</span>
      </button>
      <div class="d-sorter-line__item">Программа</div>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='percent', _asc: sortParam==='percent' && sortOrder==='asc', _desc: sortParam==='percent' && sortOrder==='desc'}"
              @click="setSortParam('percent')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Ставка</span>
        <span class="short">Ставка</span>
      </button>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='initial', _asc: sortParam==='initial' && sortOrder==='asc', _desc: sortParam==='initial' && sortOrder==='desc'}"
              @click="setSortParam('initial')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Первоначальный взнос</span>
        <span class="short">Первонач. взнос</span>
      </button>
      <button class="d-sorter-line__item" :class="{ _active: sortParam==='time', _asc: sortParam==='time' && sortOrder==='asc', _desc: sortParam==='time' && sortOrder==='desc'}"
              @click="setSortParam('time')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Срок кредита, мес.</span>
        <span class="short">Срок&nbsp;кредита, мес.</span>
      </button>
      <button class="d-sorter-line__item"
              :class="{ _active: sortParam==='payment', _asc: sortParam==='payment' && sortOrder==='asc', _desc: sortParam==='payment' && sortOrder==='desc'}"
              @click="setSortParam('payment')">
        <span class="d-sorter-direction _line"><span></span></span>
        <span>Платёж в месяц</span>
        <span class="short">Платёж в&nbsp;месяц</span>
      </button>
      <div class="d-sorter-line__item">&nbsp;</div>
    </div>
    <div v-if="!empty" class="d-sorter" :class="{_open: sortDropOpen}">
      <div class="d-sorter__head">
        <button class="d-sorter__direction d-sorter-direction _active" :class="{ _asc: sortOrder==='asc', _desc: sortOrder==='desc'}" @click="setSortParam(sortParam)"><span></span>
        </button>
        <div class="d-sorter__label" @click="showSortDrop">{{ sortLabel[sortParam] }}
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-down"></use>
          </svg>
        </div>
      </div>
      <div class="d-sorter__drop">
        <div class="d-sorter__list">
          <div class="d-sorter__item" :class="{ _active: sortParam==='bank', _asc: sortParam==='bank' && sortOrder==='asc', _desc: sortParam==='bank' && sortOrder==='desc'}"
               @click="setSortParam('bank')">Банк
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='percent', _asc: sortParam==='percent' && sortOrder==='asc', _desc: sortParam==='percent' && sortOrder==='desc'}"
               @click="setSortParam('percent')">Ставка
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='initial', _asc: sortParam==='initial' && sortOrder==='asc', _desc: sortParam==='initial' && sortOrder==='desc'}"
               @click="setSortParam('initial')">Первоначальный взнос
          </div>
          <div class="d-sorter__item" :class="{ _active: sortParam==='time', _asc: sortParam==='time' && sortOrder==='asc', _desc: sortParam==='time' && sortOrder==='desc'}"
               @click="setSortParam('time')">Срок кредита, мес.
          </div>
          <div class="d-sorter__item"
               :class="{ _active: sortParam==='payment', _asc: sortParam==='payment' && sortOrder==='asc', _desc: sortParam==='payment' && sortOrder==='desc'}"
               @click="setSortParam('payment')">Платёж в месяц
          </div>
        </div>
      </div>
    </div>
    <template v-if="loading">
      <div v-for="i in [0, 1, 2, 3, 4]" class="d-bank _purchase">
        <div class="d-bank__item _item1">
          <div class="skeleton-field _fieldBank1"></div>
        </div>
        <div class="d-bank__item _program">
          <div class="d-bank__label skeleton-label _label1"></div>
          <div class="skeleton-field _fieldBank2"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label2"></div>
          <div class="skeleton-field _fieldBank3"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label3"></div>
          <div class="skeleton-field _fieldBank3 _mobile"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label4"></div>
          <div class="skeleton-field _fieldBank4"></div>
        </div>
        <div class="d-bank__item">
          <div class="d-bank__label skeleton-label _label4"></div>
          <div class="skeleton-field _fieldBank4"></div>
        </div>
        <div class="d-bank__item _item7">
          <div class="d-bank__label skeleton-label _label4"></div>
          <div class="skeleton-field _fieldBank4"></div>
        </div>
      </div>
    </template>
  </div>
  <template v-if="!loading">
    <template v-for="(itemsPortions, bank_slug) in flatternItems" :key="bank_slug">
      <div v-for="(items, portionIndex) in itemsPortions" class="d-mortgage__list _purchase" :key="`${bank_slug}-${portionIndex}`" :data-bank="bank_slug"
           :class="{ _open: bank_slug in banksOpen, _top: portionIndex === 0 }">
        <div v-for="(item, index) in items" :data-bank="item.bank_slug" :key="`${bank_slug}-${portionIndex}-${index}`" class="d-bank _purchase" :class="{_hidden: !item.active, _first: item.first}">
          <div class="d-bank__item _item1">
            <img class="d-bank__logo" :src="item.logo">
          </div>
          <div class="d-bank__item _program">
            <div class="d-bank__name">{{ item.bank }}</div>
            {{ item.name }}
          </div>
          <div v-if="item.percent_second" class="d-bank__item">
            <div class="d-bank__label">Ставка</div>
            от {{ item.percent }}%
          </div>
          <div v-else class="d-bank__item">
            <div class="d-bank__label">Ставка</div>
            {{ item.percent }}%
          </div>
          <div class="d-bank__item">
            <div class="d-bank__label">Первонач. взнос</div>
            от {{ item.initial }}%
          </div>
          <div class="d-bank__item">
            <div class="d-bank__label">Срок кредита</div>
            до {{ item.time * 12 }}
          </div>
          <div v-if="item.percent_second" class="d-bank__item">
            <div class="d-bank__label">Платёж в мес.</div>
            от {{ formatMoney(item.payment) }} ₽
          </div>
          <div v-else class="d-bank__item">
            <div class="d-bank__label">Платёж в мес.</div>
            {{ formatMoney(item.payment) }} ₽
          </div>
          <div class="d-bank__item _item7">
            <template v-if="item.first">
              <button v-show="!(bank_slug in banksOpen) && banksCount[bank_slug] > 1" class="d-bank__count" :class="{ _active: bank_slug in banksOpen }"
                      @click="showBank(bank_slug)" data-v-tippy="Показать другие предложения банка">
                +{{ bank_slug in banksCount ? banksCount[bank_slug] - 1 : '' }}
              </button>
              <button v-show="bank_slug in banksOpen && banksCount[bank_slug] > 1" class="d-bank__count" :class="{ _active: bank_slug in banksOpen }" @click="hideBank(bank_slug)" data-v-tippy="Скрыть другие предложения банка">-
              </button>
            </template>
          </div>
          <button class="d-bank__button" @click="sendRequest($event, item)"/>
        </div>
      </div>
      <div v-if="bank_slug in banksCount && banksCount[bank_slug] > 1" class="d-mortgage__list _purchase">
        <button class="d-mortgage__more _purchase _next d-button _white"
                :class="{_hidden: !(bank_slug in banksCount) || !(bank_slug in banksOpen) || banksCount[bank_slug] > banksOpen[bank_slug].count}"
                @click="hideBank(bank_slug, true)">
          <span>Свернуть предложения банка</span>
        </button>
        <button class="d-mortgage__more _purchase _next d-button _white"
                :class="{_hidden: !(bank_slug in banksCount) || !(bank_slug in banksOpen) || banksCount[bank_slug] <= banksOpen[bank_slug].count}" @click="showBank(bank_slug)">
          <span>{{ bank_slug in banksOpen ? banksOpen[bank_slug].showMore : '' }}</span>
        </button>
      </div>
    </template>
  </template>

</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import _ from 'lodash';
import declOfNum from '@utils/declOfNum'; // optional for styling

export default {
  name: 'DMortgageResults',
  emits: ['request', 'update:sortOrder', 'update:sortParam'],
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
    sortParam: {
      type: String,
      default: 'percent',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
    empty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortLabel: {
        initial: 'Первоначальный взнос',
        percent: 'Ставка',
        bank: 'Банк',
        payment: 'Платёж в месяц',
        time: 'Срок кредита, мес.',
      },
      sortDropOpen: false,
      banksOpen: {},
      increment: 10,
    };
  },
  updated() {
    this.setTooltips();
  },
  mounted() {
    this.setTooltips();
  },
  computed: {
    showMoreText() {
      return this.mobileIsXs ? 'Ещё' : 'Показать ещё';
    },
    mobileIsXs() {
      return window.matchMedia('(max-width: 375px)').matches;
    },
    flatternItems() {
      const items = {};
      Object.entries(_.groupBy(this.items, b => b.bank_slug)).forEach(([bank_slug, suggests]) => {
        items[bank_slug] = [];
        suggests.map((item, index) => {
          item.first = index === 0;
          item.active = item.first || (bank_slug in this.banksOpen && index < this.banksOpen[bank_slug].count);
          if (items[bank_slug].length === 0) {
            items[bank_slug].push([item]);
          }
          else if (items[bank_slug].length === 1) {
            items[bank_slug].push([item]);
          }
          else if (items[bank_slug].length === 2 && items[bank_slug][1].length < 5) {
            items[bank_slug][1].push(item);
          }
          else if (items[bank_slug].length > 2 && items[bank_slug][items[bank_slug].length - 1].length < this.increment) {
            items[bank_slug][items[bank_slug].length - 1].push(item);
          }
          else {
            items[bank_slug].push([]);
            items[bank_slug][items[bank_slug].length - 1].push(item);
          }
        });
      });
      return items;
    },
    banksCount() {
      const bs = {};
      Object.entries(_.groupBy(this.items, b => b.bank_slug)).forEach(([bank_slug, suggests]) => {
        bs[bank_slug] = suggests.length;
      });
      return bs;
    },
  },
  methods: {
    setTooltips() {
      document.querySelectorAll('[data-v-tippy]').forEach(el => {
        if (!el._tippy) tippy(el, {
          appendTo: () => document.body,
          offset: 8,
          theme: 'mortgage',
          placement: 'top',
          animation: 'fade',
          content: el.dataset.vTippy,
          arrow: false,
          touch: false,
        });
      });
    },
    showBank(slug) {
      if (slug in this.banksOpen) {
        this.banksOpen[slug].count += this.increment;
      }
      else {
        this.banksOpen[slug] = {count: 6};
      }
      this.banksOpen[slug].showMoreCount = this.banksCount[slug] - this.banksOpen[slug].count;
      if (this.banksOpen[slug].showMoreCount > this.increment) this.banksOpen[slug].showMoreCount = this.increment;

      this.banksOpen[slug].showMore
          = this.banksOpen[slug].showMoreCount > 0
            ? `${this.showMoreText} ${ this.banksOpen[slug].showMoreCount } ${ declOfNum(this.banksOpen[slug].showMoreCount,
              ['предложение', 'предложения', 'предложений']) } банка`
            : '';

    },
    hideBank(slug, scrollTo) {
      if (scrollTo && window.scrollY > 250) {
        this.$smoothScroll({
          scrollTo: document.querySelector(`[data-bank="${ slug }"]`),
          updateHistory: false,
          duration: 500,
          offset: -250,
        });
        setTimeout(() => {
          delete this.banksOpen[slug];
        }, 500);
      }
      else delete this.banksOpen[slug];
    },
    showSortDrop() {
      this.sortDropOpen = !this.sortDropOpen;
    },
    sendRequest(e, bank_data) {
      if (e.target.dataset.bank || e.target.parentElement.dataset.bank) this.$emit('request', {bank_data});
    },
    setSortParam(param) {
      if (this.sortParam === param) {
        if (this.sortOrder === 'asc') this.$emit('update:sortOrder', 'desc');
        else this.$emit('update:sortOrder', 'asc');
      }
      else {
        this.$emit('update:sortParam', param);
        this.$emit('update:sortOrder', 'asc');
      }
      this.sortDropOpen = false;
    },
    formatMoney(value) {
      if (!value) return value;
      if (typeof value === 'number') {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          useGrouping: true,
          maximumSignificantDigits: 11,
        })
            .format(value)
            .replace(/[ ,]/g, ' ');
      }
      else {
        return value;
      }
    },
  },
};
</script>

<style scoped>

</style>
