<template>
  <div class="d-aux-rooms__switchView">
    <div class="d-switch-view" :class="additionalClass" @click="setValue">
      <button v-for="(display, index) in options" :key="index" class="d-switch-view__button" :class="{ _active: display === currentValue }">
        <svg>
          <use :xlink:href="`/assets/blueant/assets/sprite.svg#sprite-${display}`"></use>
        </svg>
      </button>
      <div class="d-switch-view__buttonBg"></div>
    </div>
  </div>
</template>

<script>
import { isEqual as _isEqual } from 'lodash';

export default {
  name: 'DViewSwitcher',
  emits: ['update:display'],
  props: {
    options: {
      type: Array,
      default: () => ['list', 'card']
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: 'card'
    },
  },
  computed: {
    firstValue() {
      if (!this.options.length) return null
      return this.options[0]
    },
    secondValue() {
      if (!this.options.length) return null
      return this.options[1]
    },
    additionalClass() {
      return _isEqual(this.currentValue, this.firstValue) ? `_${this.firstValue}` : null;
    },
  },
  data() {
    return {
      currentValue: null,
    }
  },
  mounted() {
    this.currentValue = this.value;

    this.$emit('update:display', this.currentValue)
  },
  methods: {
    setValue() {
      if (this.additionalClass) {
        this.currentValue = this.secondValue
      } else {
        this.currentValue = this.firstValue
      }

      this.$emit('update:display', this.currentValue)

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
