<template>
  <div class="d-invest-calc" :class="{ _error: errored, _loading: loading, _empty: empty}">
    <div class="d-invest-calc__col _left">
      <div class="d-invest-calc__item">
        <DInputSliderRange :init-values="filterParams" title="Хочу вложить" :unit="form.tip" :step="form.step" :from="form.from" :to="form.to" v-model="filterParams"/>
      </div>

    </div>
    <div class="d-invest-calc__col _right">
      <template v-if="empty">
        <div class="d-invest-calc-empty">
          <div class="d-invest-calc-empty__text">
            <div>По заданным параметрам ничего не&nbsp;найдено.&nbsp;</div>
            <div>Попробуйте сбросить фильтр или задать другие параметры.</div>
          </div>
          <button class="d-invest-calc-empty__button d-button _dark" @click="setDefaultParams"><span>Сбросить фильтры</span></button>
        </div>
      </template>
      <template v-else-if="errored">
        <div class="d-invest-calc-empty _error">
          <div class="d-invest-calc-empty__text">
            <div>При загрузке данных произошла ошибка.&nbsp;</div>
            <div>Попробуйте обновить страницу</div>
          </div>
          <button class="d-invest-calc-empty__button d-button _dark"><span>Обновить страницу</span></button>
        </div>
      </template>
      <template v-else-if="loading">
        <div class="d-invest-calc__item">
          <div class="d-invest-calc__label"> </div>
          <div class="d-purchase-mortgage-calc__value skeleton-field _field3"></div>
        </div>
        <div class="d-invest-calc__item">
          <div class="d-invest-calc__button skeleton-button d-button _blue">
            <span class="skeleton-button__loader"></span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-invest-calc__item">
          <div class="d-invest-calc__label">Вам будут интересны</div>
          <div class="d-invest-calc__value">{{ result.string }}</div>
        </div>
        <div class="d-invest-calc__item">
          <a :href="result.link" class="d-invest-calc__button d-button _blue"><span>Посмотреть все варианты</span></a>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {debounce as _debounce} from 'lodash';
import DInputSliderRange from '../atoms/DInputSliderRange.vue';
import declOfNum from '../../utils/declOfNum';

export default {
  name: 'DInvestForm',
  components: {
    DInputSliderRange,
  },
  props: {
    dataUrlApi: {
      type: String,
      default: '/assets/json/home-invest-form-api.json',
    },
    dataForm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      result: {
        count: 0,
        string: '',
        stringEmpty: '',
        link: '',
      },
      form: {
        from: 50,
        to: 400,
        initial_from: 50.2,
        initial_to: 104.5,
        step: 0.01,
        tip: 'млн ₽',
      },
      filterParams: [],
      empty: false,
      errored: false,
      loading: true,
    };
  },
  watch: {
    filterParams: {
      handler(params) {
        this.sendData();
      },
      deep: true,
    },
  },
  created() {
    this.setInitParams();
  },
  methods: {
    setInitParams() {

      if (this.dataForm) try {
        this.form = JSON.parse(this.dataForm);
        if (parseFloat(this.form.from) > 1000000) this.form.from = parseFloat((this.form.from / 1000000).toFixed(2));
        if (parseFloat(this.form.initial_from) > 1000000) this.form.initial_from = parseFloat((this.form.initial_from / 1000000).toFixed(2));
        if (parseFloat(this.form.to) > 1000000) this.form.to = parseFloat((this.form.to / 1000000).toFixed(2));
        if (parseFloat(this.form.initial_to) > 1000000) this.form.initial_to = parseFloat((this.form.initial_to / 1000000).toFixed(2));
      } catch (e) {}

      this.filterParams = [this.form.initial_from, this.form.initial_to];

      this.sendData();

    },
    setDefaultParams() {
      this.filterParams = [this.form.initial_from, this.form.initial_to];

      this.sendData();
    },
    sendData: _debounce(async function() {

      this.setLoading(true);
      const formdata = {
        from: this.filterParams[0] * 1000000,
        to: this.filterParams[1] * 1000000,
      };

      try {
        const response = await this.axios.post(this.dataUrlApi, formdata);
        this.result = response.data;
        if (!this.result.string)
          this.result.string = `${ this.result.count } ${ declOfNum(this.result.count, ['предложение', 'предложения', 'предложений']) }`;
        this.empty = parseInt(this.result.count) === 0;
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.setLoading(false);
      }

    }, 500),
    setLoading: _debounce(function(val) {
      this.loading = val;
    }, 500),
  },
};
</script>

<style scoped></style>
