<template>
  <div class="d-commercial-card" :class="classList">
    <template v-if="mod === 'map'">
      <div class="d-commercial-card__content">
        <div class="d-commercial-card__name">Актив Раменки уч.19</div>
        <div class="d-commercial-card__address">
          г. Москва, внутригородское муниципальное образование Лефортово, ул. Золоторожский Вал, вл. 11, стр. 50,56
        </div>
        <div class="d-commercial-card__bottom">
          <div class="d-commercial-card__square">{{ data.area }} м²</div>
          <div v-if="data.price" class="d-commercial-card__price">{{ data.price.toLocaleString('ru-RU') }} ₽</div>
          <div v-else class="d-commercial-card__price">По запросу</div>
          <a href="#" class="d-commercial-card__link d-button _dark">
            <span>Перейти к объекту</span>
          </a>
        </div>
      </div>
      <button class="d-commercial-card__close d-button _close _blue">
        <img src="/assets/blueant/assets/img/d/icon_close.svg" alt="">
      </button>
    </template>
    <template v-else-if="mod === 'skeleton'">
      <div class="d-commercial-card__img">
        <div class="skeleton-field"></div>
      </div>
      <div class="d-commercial-card__content">
        <div class="d-commercial-card__name skeleton-field"></div>
        <div class="d-commercial-card__address">
          <div class="skeleton-field"></div>
          <div class="skeleton-field"></div>
        </div>
        <div class="d-commercial-card__bottom">
          <div class="d-commercial-card__square skeleton-field"></div>
          <div class="d-commercial-card__price skeleton-field"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="data.purpose" class="d-commercial-card__target">
        <div class="d-flat-action _white">
          <div class="d-flat-action__name">{{ data.purpose }}</div>
        </div>
      </div>
      <div class="d-commercial-card__img">
        <img :src="data.img" alt="">
      </div>
      <div class="d-commercial-card__content">
        <div class="d-commercial-card__name">{{ data.name }}</div>
        <div class="d-commercial-card__address">
          {{ data.info }}
        </div>
        <div class="d-commercial-card__bottom">
          <div class="d-commercial-card__square">
            {{ data.area }} м²
          </div>
          <div v-if="data.price" class="d-commercial-card__price">
            {{ data.price.toLocaleString('ru-RU') }} ₽
          </div>
          <div v-else class="d-commercial-card__price">
            По запросу
          </div>
        </div>
      </div>
      <a :href="data.url" class="d-commercial-card__link"></a>
    </template>
  </div>
</template>

<script>
import additionalClassCSS from "@utils/additionalClassCSS";
export default {
  name: "DCommercialCard",
  props: {
    mod: {
      type: [Array, String],
      default: '',
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>