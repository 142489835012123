<template>
  <a :href="item.url" class="d-booklets__item" target="_blank">
    <span class="d-booklets__item-cover">
      <img :src="item.cover" :alt="`Обложка ${item.title}`" loading="lazy">
    </span>
    <span class="d-booklets__item-bottom">
      <span class="d-booklets__item-title">{{ item.title }}</span>
      <span class="d-booklets__item-info">{{ item.pdf }}</span>
    </span>
  </a>
</template>

<script>
export default {
  name: 'DBookletsItem',
  props: {
    item: Object
  }
};
</script>
