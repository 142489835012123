<template>
  <div v-if="item && floor" id="parking-search-popup" class="d-aux-rooms-popup _carPlace _show" data-parking-search-popup data-simplebar>
    <div class="d-aux-rooms-popup__inner">
      <div class="d-aux-rooms-popup__top">
        <div class="d-aux-rooms-popup__selects">
          <div class="d-aux-rooms-popup__select _level">
            <d-select v-if="floors.length" :data-options="floors" v-model="floor"/>
          </div>
        </div>
        <div class="d-aux-rooms-popup__legend">
          <div class="d-aux-rooms-popup__legendItem _free">Свободно</div>
          <div class="d-aux-rooms-popup__legendItem _busy">Занято</div>
          <div class="d-aux-rooms-popup__legendItem">
            <span>Выбранное место</span>
            <span class="phone">Выбрано</span>
          </div>
        </div>
      </div>
      <div class="d-aux-rooms-popup__center">
        <div class="d-aux-rooms-popup__infoTop">
          <div class="d-aux-rooms-popup__title">{{ item.title }}</div>
          <div class="d-aux-rooms-popup__advantages">
            <div v-for="(prop, id) in item.props" :key="`prop-${item.id}-${id}`" class="d-flat-action" :style="{background: prop.bgColor, color: prop.color}">
              <div class="d-flat-action__name">{{ prop.title }}</div>
              <div v-if="prop.tip" class="d-flat-action__toolp _center" v-html="prop.tip"></div>
              <a v-if="prop.link" class="d-flat-action__link" :href="prop.link" target="_blank"></a>
            </div>
          </div>
          <div class="d-aux-rooms-popup__icons">
            <div class="d-button _flatIcon" data-social-share-list="data-social-share-list" hidden>
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-share"></use>
              </svg>
            </div>
            <button class="d-button _flatIcon _favorite" :class="{ _active: isFavorite }" @click="setFavorite(null)">
              <svg>
                <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-favorite"></use>
              </svg>
              <span v-if="isFavorite" class="d-button__tooltip">В избранном</span>
              <span v-else class="d-button__tooltip">Добавить в избранное</span>
            </button>
          </div>
        </div>
        <div v-if="!dimensions && floorImg" class="d-aux-rooms-popup__schemeWrap">
          <div ref="schemePlace" class="d-aux-rooms-popup-scheme">
            <div class="d-aux-rooms-popup-scheme__inner">
              <img ref="mapImg" :src="floorImg" @load="onImageLoad"/>
            </div>
          </div>
        </div>
        <d-place-popup-svg-area v-if="floorImg && dimensions" :src="selectionImg" :mapImg="floorImg" :dimensions="dimensions" :mod="mod"></d-place-popup-svg-area>
        <div class="d-aux-rooms-popup__info">
          <div class="d-aux-rooms-popup-info">
            <div class="d-aux-rooms-popup-info__params">
              <div class="d-flat-info">
                <div class="d-flat-info__row">
                  <div class="d-flat-info__label">Проект</div>
                  <div v-if="itemObject.url" class="d-flat-info__value">
                    <a :href="itemObject.url" target="_blank">
                      {{ itemObject.title }}
                      <svg>
                        <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-arrow"></use>
                      </svg>
                    </a>
                  </div>
                  <div v-else class="d-flat-info__value">
                    {{ itemObject.title }}
                  </div>
                </div>
                <div v-if="item.q" class="d-flat-info__row">
                  <div class="d-flat-info__label">Квартал</div>
                  <div class="d-flat-info__value">{{ data.quarters[item.q] }}</div>
                </div>
                <div v-if="item.b" class="d-flat-info__row">
                  <div class="d-flat-info__label">Корпус</div>
                  <div class="d-flat-info__value">{{ data.buildings[item.b] }}</div>
                </div>
                <div class="d-flat-info__row">
                  <div class="d-flat-info__label">Уровень</div>
                  <div class="d-flat-info__value">{{ item.level }}</div>
                </div>
                <div class="d-flat-info__row">
                  <div class="d-flat-info__label">Площадь, м&sup2;</div>
                  <div class="d-flat-info__value">{{ item.area.toString().replace('.', ',') }}</div>
                </div>
                <div class="d-flat-info__row">
                  <div class="d-flat-info__label">Тип</div>
                  <div class="d-flat-info__value _info" :class="{_notip: !tpl}">
                    {{ data.types[item.t].title }}
                    <div v-if="tpl" ref="sizeInfo" class="d-flat-info__info"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-aux-rooms-popup-info__bottom">
              <div class="d-aux-rooms-popup-info__price">
                <div class="d-flat-price">
                  <div class="d-flat-price__digits">
                    <div v-if="item.price_old" class="d-flat-price__old">{{ item.price_old }} ₽</div>
                    <div class="d-flat-price__now">{{ item.price_formatted }} ₽</div>
                  </div>
                </div>
              </div>
              <div class="d-aux-rooms-popup-info__buttons">
                <a v-if="item.booking_link" class="d-aux-rooms-popup-info__order d-button _dark" :href="item.booking_link" target="_blank"><span>Забронировать онлайн</span></a>
                <button class="d-aux-rooms-popup-info__callMe d-button _blue" data-callMe-show :data-props="placeLeadData"><span>Оставить заявку</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-aux-rooms-popup__bottom">
        <d-parking-recent :mod="mod" :favorites="favorites" @set-favorite="setFavorite"/>
      </div>
      <div class="d-aux-rooms-popup__close d-button _close _blue" @click="close">
        <img src="/assets/blueant/assets/img/d/icon_close.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
import {orderBy as _orderBy} from 'lodash';
import tippy from 'tippy.js';
import {mapWritableState, mapState} from 'pinia';
import {useSearchStore, useItemStore} from '@/stores/search';
import DSwipeZoomInfo from '@components/molecules/DSwipeZoomInfo.vue';
import DSelect from '@components/molecules/DSelect.vue';
import DParkingRecent from '@components/molecules/DParkingRecent.vue';
import Cookies from 'js-cookie';
import DPlacePopupSvgArea from '@components/molecules/DPlacePopupSvgArea.vue';

export default {
  name: 'DParkingPlacePopup',
  components: {DPlacePopupSvgArea, DParkingRecent, DSelect, DSwipeZoomInfo},
  emits: ['close', 'set-favorite'],
  props: {
    mod: {
      type: String,
      default: ''
    },
    favorites: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapWritableState(useItemStore, [
      'item',
    ]),
    ...mapState(useSearchStore, [
      'data',
    ]),
    floorImg() {
      return this.floor ? this.data.floors[this.floor].map : null;
    },
    selectionImg() {
      return this.floor ? this.data.floors[this.floor].map.replace('.svg', '_selection.svg').replace(/^https?:/, window.location.protocol) : null;
    },
    itemObject() {
      if (this.item.o && this.data.objects[this.item.o]) return {
        url: this.data.objects[this.item.o].url,
        title: this.data.objects[this.item.o].title,
      };
      console.error('Object data not found in data; object ID = ', this.item.o, '; item guid: ', this.item.guid, '; data: ', this.data);
      return {
        url: '',
        title: '',
      };
    },
    isTouchDevice() {
      return window.matchMedia('(pointer: coarse)').matches;
    },
    tpl() {
      return !this.item.sizeTip ? null : (this.isTouchDevice ? `<div>${ this.item.sizeTip }</div><img src="/assets/blueant/assets/img/d/icon_close_pen.svg" />` : this.item.sizeTip);
    },
    isFavorite() {
      if (this.favorites) return this.favorites.includes(this.item.form_id);
      else return false;
    },
  },
  data() {
    return {
      floor: null,
      floors: [],
      placeLeadData: {},
      containerSize: {},
      dimensions: null,
      tooltip: null,
    };
  },
  watch: {
    floorImg: {
      immediate: true,
      handler() {
        //console.log(`%c${ this.$options.name } watch floorImg; this.floorImg = `, 'color: yellow', this.floorImg);
        this.dimensions = null;
      },
    },
    item: {
      deep: true,
      immediate: true,
      handler() {
        if (this.item !== null) this.$nextTick(function() {

          this.floors = _orderBy(Object.entries(this.data.floors).filter(([floorId, floor]) => {
            return this.item.b ? floor.b === this.item.b && floor.q === this.item.q : floor.q === this.item.q;
          }).map(([floorId, floor]) => {
            floor.value = floorId;
            if(!floor.id) floor.id = floorId;
            return floor;
          }), ['title'], ['asc']);
          this.floor = this.item.f;
          //console.log(`%c${ this.$options.name } watch item; this.floor = `, 'color: yellow', this.floor);
          //console.log(`%c${ this.$options.name } watch item; this.floorImg = `, 'color: yellow', this.floorImg);
          //console.log(`%c${ this.$options.name } watch item; this.floors = `, 'color: yellow', this.floors);

          this.placeLeadData = JSON.stringify({
            'area': this.item.area,
            'comment': '',
            'email': '',
            'flat': this.item.form_id,
            'floor': this.item.level,
            'formType': 'reserve',
            'housing': this.data.quarters[this.item.q],
            'price': this.item.price,
            'section': this.data.buildings[this.item.b],
            'sourceConversion': '',
            'typeFlat': 'parking',
            'URL': window.location.href || '',
            'utm': Cookies.get('utm_data') || window.utm_str_src || '',
          });
        });
        //else
        //  console.log(`%c${ this.$options.name } watch item; this.item = `, 'color: yellow', this.item);
      },
    },
  },
  mounted() {
    $('body').addClass('_noScroll');
    if (this.item && this.$refs.sizeInfo) {
      this.createTooltip();
    }
  },
  updated() {
    if (this.item && this.$refs.sizeInfo) {
      this.createTooltip();
    }
  },
  beforeUnmount() {
    $('body').removeClass('_noScroll');
  },
  methods: {
    createTooltip() {
      const target = this.$refs.sizeInfo;

      if (this.tooltip) this.tooltip.destroy();
      this.tooltip = tippy(target, {
        allowHTML: true,
        interactive: false,
        //appendTo: () => document.body,
        offset: [0, 12],
        theme: 'd-aux-rooms-size-tip',
        placement: 'top',
        animation: 'fade',
        content: this.tpl,
        arrow: false,
        hideOnClick: true,
        //trigger: touch ? '',
        touch: this.isTouchDevice,

      });

    },
    close() {
      this.$emit('close', true);
    },
    onImageLoad() {
      const image = this.$refs.mapImg;
      if(!image) return;
      //console.log(`%c${ this.$options.name } onImageLoad() image.naturalWidth = `, 'color: yellow', image.naturalWidth);
      const imgWidth = image.naturalWidth;
      const imgHeight = image.naturalHeight;
      const {width, height} = this.$refs.schemePlace.getBoundingClientRect();
      this.dimensions = {
        imgWidth,
        imgHeight,
        contentWidth: imgWidth > width ? imgWidth : null,
        contentHeight: imgHeight > height ? imgHeight : null,
        dataWidth: width,
        dataHeight: height,
      };
      //console.log(`%c${ this.$options.name } onImageLoad() this.dimensions = `, 'color: yellow', this.dimensions);
    },
    setFavorite(data = null) {
      if (!data) {
        const price = this.item.price_request && this.item.price_request_label ? this.item.price_request_label : `${ this.item.price_formatted } ₽`;
        data = {id: this.item.form_id, title: this.item.title, price, show: false};
      }
      this.$emit('set-favorite', data);
    },
  },
};
</script>
