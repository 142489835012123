import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

window.createTabsSlider = function createTabsSlider($wrapper) {
    const $tabsWrapper = $wrapper;
    const $tabsSwiper = $tabsWrapper.find('[data-tabs-slider]');
    const $tabsSwiperItem = $tabsWrapper.find('[data-tabs-slider-item]');
    const $tabsContent = $tabsWrapper.find('[data-tabs-slider-content]');

    const $tabsSwiperInst = new Swiper($tabsSwiper[0], {
        slidesPerView: 'auto',
        freeMode: {
            enabled: true,
        },
        resistance: true,
        enabled: false,
        breakpoints: {
          1280: {
              enabled: true,
          }
        },
        resistanceRatio: 0,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        //centeredSlides: true,
        //centeredSlidesBounds: true,
        on: {
            afterInit: function (swiper) {
                //console.log('swiper', this.slidesSizesGrid)
                setTimeout(() => {
                    const $currentTab = $('[data-tabs-slider-item]._active');
                    //console.log('swiper 2', this.slidesSizesGrid)
                    const $bg = $tabsSwiper.find('[data-tabs-slider-bg]');
                    const translateSwiper = Math.abs(this.translate);
                    const indexTab = $currentTab.parent().index() - 1
                    // console.log('current tab', this.slidesSizesGrid)
                    const width = this.slidesSizesGrid[indexTab];
                    const left = $currentTab.parent().position().left

                    $bg.css({
                        "width": `${width}px`,
                        "left": `${ translateSwiper ? left : left}px`
                    })
                    $tabsSwiperItem.removeClass('_active');
                    $currentTab.addClass('_active');
                }, 500)
            }
        }
    });

    $tabsSwiperItem.on('click', function () {
        const $currentItem = $(this);
        const $bg = $tabsSwiper.find('[data-tabs-slider-bg]');
        const translateSwiper = Math.abs($tabsSwiperInst.translate);
        const indexTab = $currentItem.parent().index() - 1;
        $tabsSwiperInst.slideTo(indexTab);

        const width = $tabsSwiperInst.slidesSizesGrid[indexTab];
        const left = $currentItem.parent().position().left

        $bg.css({
            "width": `${width}px`,
            "left": `${ translateSwiper ? left : left}px`
        })
        $tabsSwiperItem.removeClass('_active');
        $currentItem.addClass('_active');
        const $tabsWrapper = $(this).parents('[data-tabs-slider-wrapper]');
        const $currentContent = $tabsWrapper.find('[data-tabs-slider-content]').eq(indexTab);
        $tabsContent.removeClass('_active');
        $currentContent.addClass('_active');
        //console.log('$currentContent', $tabsWrapper.find('[data-tabs-slider-content]'))
    })
}

$(window).on('load resize', () => {
    const $tabsSliders = $('[data-tabs-slider-wrapper]');
    if ($tabsSliders.length) {
        $tabsSliders.each((idx, el) => {
            createTabsSlider($(el))
        })
    }
});
