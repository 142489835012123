<template>
  <div class="d-object-single-location-legend" :class="classList" data-legend="data-legend">
    <div class="d-object-single-location-legend__close">
      <button class="d-button _close _blue" data-legend-toggle="data-legend-toggle" @click="$emit('close')"><img src="/assets/blueant/assets/img/d/icon_close.svg"/></button>
    </div>

    <div class="d-object-single-location-legend__inner">
      <div class="d-object-single-location-legend__title">Инфраструктура</div>
      <div class="d-object-single-location-legend__title _mobile">
        <button class="d-object-single-location-legend__back" data-legend-toggle="data-legend-toggle" @click="$emit('close')">Фильтр</button>
      </div>
      <div v-if="visibleTypes" class="d-object-single-location-types _desktop">
        <div class="d-object-single-location-types__item _outside">Внешняя</div>
        <div class="d-object-single-location-types__item _inside">Внутренняя</div>
      </div>
      <div class="d-object-single-location-legend__list" data-simplebar>
        <div class="d-object-single-location-legend__item d-object-single-location-legend-item">
          <input ref="allInput" class="d-object-single-location-legend-item__input" type="checkbox" :id="`${id}-full`" value="all" @change="toggleAll($event)"/>
          <label class="d-object-single-location-legend-item__label" :class="{ _lock: disableAllCategory }" :for="`${id}-full`">
              <span class="d-object-single-location-legend-item__icon">
                <svg>
                  <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-location_legend_all'></use>
                </svg>
              </span>
            <span class="d-object-single-location-legend-item__name">Все категории</span>
            <span class="d-object-single-location-legend-item__value">{{ allCount }}</span>
          </label>
        </div>
        <div v-for="category in data" :key="category.id" class="d-object-single-location-legend__item d-object-single-location-legend-item">
          <input v-model="selectedCategories" class="d-object-single-location-legend-item__input" type="checkbox" :id="`${id}-${category.label}`" :value="category.label" @change="changeCategory"/>
          <label class="d-object-single-location-legend-item__label" :for="`${id}-${category.label}`">
              <span class="d-object-single-location-legend-item__icon" v-html="category.icon">

              </span>
            <span class="d-object-single-location-legend-item__name">{{ category.title }}</span>
            <span class="d-object-single-location-legend-item__value">{{ category.count }}</span>
          </label>
        </div>
      </div>
      <div class="d-object-single-location-legend__button">
        <button class="d-button _dark" @click="applyFilter"><span>Применить фильтры</span></button>
        <button class="d-button _blue _mobile" @click="$emit('close')"><span>Показать {{ countObjects }}</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import declOfNum from "../../utils/declOfNum";

export default {
  name: "DMapInfraLegend",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    mod: {
      type: [Array, String],
      default: '',
    },
    visibleTypes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCategories: [],
      disableAllCategory: false,
      id: null,
      saveCategories: false
    }
  },
  computed: {
    allCount() {
      const value = 0;
      if (this.data.length) {
        return this.data.reduce(
            (previousValue, currentValue) => previousValue + currentValue.count,
            value
        );
      }
      return 0
    },
    modClass() {
      const obj = {};
      if (this.mod) {
        if (Array.isArray(this.mod)) {
          this.mod.forEach((mod) => {
            obj[`_${mod}`] = true;
          });
        } else {
          obj[`_${this.mod}`] = true;
        }
      }
      return obj;
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
    countObjects() {
      let value = 0;
      if (this.selectedCategories.length) {
        this.selectedCategories.forEach((categoryLabel) => {
          const needCategory = this.data.filter((category) => category.label === categoryLabel)
          if (needCategory.length) {
            value += needCategory[0].count
          }
        })
      } else {
        value = this.allCount
      }

      const strCount = declOfNum(value, ['объект', 'объекта', 'объектов'])

      return `${value} ${strCount}`
    }
  },
  watch: {
    selectedCategories(value) {
      console.log('value', value)
      if (value.length) {
        this.$refs.allInput.checked = false;
      } else {
        this.$refs.allInput.checked = true;
        this.disableAllCategory = true;
      }
    },
  },
  created() {


  },
  mounted() {
    this.id = this.$.uid
    this.$emit('changeType', this.type)

    this.setInitCategories(this.data)
  },
  methods: {
    changeCategory() {
      this.disableAllCategory = false
      this.$emit('changeCategory', this.selectedCategories)

    },
    toggleAll($event) {
      const isChecked = $event.target.checked
      if (isChecked) {
        this.disableAllCategory = true
      }
      this.selectedCategories = []
      this.$emit('changeCategory', this.selectedCategories)

    },
    setInitCategories(data) {
      data.filter((category) => {
        if (category.init) {
          this.selectedCategories.push(category.label)
        }
      })
      console.log('selectedCategories', this.selectedCategories.length, data.length, data)
      if (this.selectedCategories.length === data.length) {
        this.selectedCategories = []
      }
      if (!this.selectedCategories.length) {
        this.$refs.allInput.checked = true
        this.disableAllCategory = true
      }
      this.$emit('changeCategory', this.selectedCategories)
    },
    applyFilter() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
