import { helpers } from '@vuelidate/validators'
// const phoneHelper = (value) => !helpers.req(value) || value.match(/^\+?\d\s*\(\d{3}\)?\s*\d{3}-?\d{2}-?\d{2}/);
const phoneHelper = (params) => (value) => {
    const codeLength = params.code.length;
    const maskLength = params.mask.length;
    return value.length === codeLength + maskLength + 1 // + 1 потому что между кодом и номером есть пробел
}

export default phoneHelper;

//export default helpers.regex(/^\+?7?\s*\(?\d{3}\)?\s*\d{3}-?\d\d-?\d\d$/);
