<template>
  <form v-if="dataMod === 'footer' || dataMod.includes('footer')" class="d-still-questions__form _footer" @submit.prevent="submit">
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" :placeholder="strings.yourNamePlaceholder" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-still-questions__item">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone" :short-mode="true"/>
      </div>
    </div>
    <div class="d-still-questions__item _agree _national">
      {{ strings.agreeText }} <span data-agreement-show>{{ strings.agreeLinkText }}</span>
      <d-recaptcha-info :errored="errored" :error-text="errorText"/>
    </div>
    <div v-if="loading" class="d-still-questions__item">
      <div class="d-still-questions__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-still-questions__item">
      <button class="d-still-questions__button d-button _blue" @click.prevent="submit"><span>{{ strings.sendForm }}</span></button>
    </div>

  </form>
  <form v-else class="d-callMe__content" @submit.prevent="submit">
    <div class="d-callMe__item _item1">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" :placeholder="strings.yourNamePlaceholder" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div class="d-callMe__item _item2">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone" :short-mode="true"/>
      </div>
    </div>
    <div v-if="loading" class="d-callMe__item _item5">
      <div class="d-callMe__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-callMe__item _item5">
      <button class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>{{ strings.sendForm }}</span></button>
    </div>
    <div class="d-callMe__item _item6">
      <div class="d-callMe__agree _national">
        {{ strings.agreeText }} <span data-agreement-show>{{ strings.agreeLinkText }}</span>
      </div>
      <d-recaptcha-info :errored="errored" :error-text="errorText"/>
    </div>
  </form>
</template>

<script>
import DSelect from '@components/molecules/DSelect.vue';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import {toRaw} from 'vue';
import Inputmask from 'inputmask';

import DPhoneInput from '@components/molecules/DPhoneInput.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: 'DCallMeFormNational',
  components: {
    DRecaptchaInfo,
    DSelect,
    DPhoneInput,
  },
  props: {
    dataActionUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataMod: {
      type: [String, Array],
      default: 'footer', // popup
    },
    dataType: {
      type: [String, Array],
      default: 'footer', // popup
    },
    dataProps: {
      type: [Object, Boolean, String],
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
      },
      errored: false,
      errorText: window.dStrings.recaptchaError,
      loading: false,
      phoneParams: {
        mask: '',
        code: '',
      },
      reset: false,
    };
  },
  created() {
    this.strings = window.dStrings;
  },
  mounted() {
    this.$nextTick(() => {
      Inputmask('+9 (999) 999-99-99', {jitMasking: true, placeholder: ''}).mask($(this.$refs.tel));
    });
  },
  methods: {
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let dataProps = toRaw(this.dataProps);
        if (this.dataProps && (typeof this.dataProps).toLowerCase() === 'string') {
          try {
            dataProps = JSON.parse(this.dataProps);
          } catch (e) {}
        }
        if (dataProps && (typeof dataProps).toLowerCase() === 'object' && Object.keys(dataProps).length > 0)
          this.sendData(Object.assign({}, {formType: 'callback'}, {...dataProps, ...this.form}));
        else this.sendData({...this.form, formType: 'callback'});
      }
    },
    async sendData(formdata) {
      formdata.URL = formdata.url = window.location.href;
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        formdata.token = await this.$recaptcha(this.dataType);
        const response = await this.axios.post(this.dataActionUrl, formdata);
        if (response.data.error) {
          this.errored = true;
          this.errorText = response.data.error;
        }
        else if (response.data.success) {
          this.resetForm();
          this.$emit('success');
          window.layoutAapp.$vfm.show({component: 'DPopupSuccess', bind: {content: response.data.success, title: response.data.title, buttonTitle: this.strings.closeForm}});
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.name = '';
      this.form.phone = this.strings.phoneCode;
      const self = this;
      this.$nextTick(() => {
        self.v$.$reset();
      });
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.form.phone = phone;
    },
    blurInpPhone() {
      this.v$.form.phone.$touch();
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage(window.dStrings.pleaseFillThisField, required),
        },
        phone: {
          required: helpers.withMessage(window.dStrings.pleaseFillThisField, required),
          phoneHelper: helpers.withMessage(window.dStrings.pleaseEnterPhoneNumber, phoneHelper(this.phoneParams)),
        },
      },
    };
  },
};
</script>

