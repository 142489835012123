<template>
  <vue-final-modal v-slot="{ params, close }"
                   v-bind="$attrs"
                   :esc-to-close="true"
                   :lock-scroll="true"
                   :click-to-close="true"
                   :z-index-base="99999"
                   classes="modal-container"
                   :content-class="contentClass"
                   overlay-class="d-overlay"
                   @closed="closed"
                   @before-open="beforeOpen"
                   @before-close="beforeClose">
    <slot :params="params" :close="close"></slot>

    <button v-if="bigClose" class="d-popup__close d-button _close _blue" @click="close">
      <img src="/assets/blueant/assets/img/d/icon_close.svg">
    </button>
    <button v-else class="d-popup__close d-button _close _small _blue" @click="close">
      <img src="/assets/blueant/assets/img/d/icon_close.svg">
    </button>

  </vue-final-modal>
</template>

<script>
import {$vfm, VueFinalModal, ModalsContainer} from 'vue-final-modal';

export default {
  name: 'DPopup',
  inheritAttrs: false,
  components: {
    VueFinalModal,
  },
  props: {
    contentClass: {
      type: String,
      default: 'd-popup',
    },
    bigClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closed() {
      this.$emit('closed');
    },
    beforeOpen() {
      document.querySelector('body').classList.add('_popupOpen');
    },
    beforeClose() {
      document.querySelector('body').classList.remove('_popupOpen');
    }
  },
};
</script>

<style scoped>
::v-deep(.d-overlay) {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  visibility: visible;
  opacity: 1;
  z-index: unset;
}
</style>
