<template>
  <div v-if="has_commercial && screenTablet" class="d-tabs__buttons">
    <div class="d-tabs__buttonsInner">
      <button class="d-tabs__button _click-off" :class="{_active: !type}" @click="setType(null)">Все</button>
      <button class="d-tabs__button _click-off" :class="{_active: type === 'living'}" @click="setType('living')">Жилые</button>
      <button class="d-tabs__button _click-off" :class="{_active: type === 'commercial'}" @click="setType('commercial')">Коммерческие</button>
      <div class="d-tabs__buttonBg"></div>
    </div>
  </div>
  <div class="d-investment__item _item1" ref="calc" id="calc" data-left-column-fix>
    <div class="d-investment-calc">
      <div v-if="has_commercial && !screenTablet" class="d-tabs__buttons">
        <div class="d-tabs__buttonsInner">
          <button class="d-tabs__button _click-off" :class="{_active: !type}" @click="setType(null)">Все</button>
          <button class="d-tabs__button _click-off" :class="{_active: type === 'living'}" @click="setType('living')">Жилые</button>
          <button class="d-tabs__button _click-off" :class="{_active: type === 'commercial'}" @click="setType('commercial')">Коммерческие</button>
          <div class="d-tabs__buttonBg"></div>
        </div>
      </div>
      <div v-element-visibility="onElementVisibility" class="d-investment-calc__row">
        <d-input-slider-range v-if="loaded" title="Бюджет"
                              :modelValue="range"
                              :from="Number((price_min/1000000).toFixed(2))"
                              :to="Number((price_max/1000000).toFixed(2))"
                              :step="0.01"
                              unit="млн ₽"
                              class="_wide"
                              @update:modelValue="setPrice"/>
      </div>
      <div class="d-investment-calc__row">
        <div class="d-investment-calc__label">{{ result.total_prefix }}</div>
        <div v-if="loading" class="d-investment-calc__value skeleton-field"/>
        <div v-else-if="result.total" class="d-investment-calc__value">{{ result.total_string }}</div>
      </div>
    </div>
  </div>
  <div class="d-investment__item _item2">
    <div v-if="loading" class="d-tabs__content _active">
      <div class="d-investment__list">
        <div v-for="i in per_page" :key="i" class="d-investment-card _skeleton">
          <div class="d-investment-card__img">
            <div class="skeleton-field"></div>
          </div>
          <div class="d-investment-card__top">
            <div class="d-investment-card__title skeleton-field"></div>
            <div class="d-investment-card__project skeleton-field"></div>
            <div class="d-investment-card__more skeleton-field"></div>
          </div>
          <div class="d-investment-card__bottom">
            <div class="d-investment-card__info">
              <div class="d-investment-card__infoItem skeleton-field"></div>
              <div class="d-investment-card__infoItem skeleton-field"></div>
              <div class="d-investment-card__infoItem skeleton-field"></div>
            </div>
            <div class="d-investment-card__buttons">
              <div class="d-investment-card__button skeleton-field"></div>
              <div class="d-investment-card__button skeleton-field"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && result.total" class="d-tabs__content _active">
      <div class="d-investment__list">
        <d-invest-item v-for="item in result.items" :key="item.guid" :item="item"/>
      </div>
      <div v-if="showMore" class="d-investment__more">
        <button class="d-button _dark" @click="addItems">
          <span>Показать ещё</span>
        </button>
      </div>
    </div>
  </div>
  <div class="d-investment__buttonFilterWrap" />
  <teleport to=".d-investment__buttonFilterWrap">
    <button v-if="isTouchDevice && filterOutOfView" class="d-investment__buttonFilter" @click="scrollToFilter">
      <img src="/assets/blueant/assets/img/d/icon_filter.svg"/><span>Фильтр</span>
    </button>
  </teleport>
</template>

<script>
import {debounce as _debounce, isEqual as _isEqual} from 'lodash';
import {mapState, mapWritableState} from 'pinia';
import {vElementVisibility} from '@vueuse/components';
import {useInvestStore} from '@/stores/invest';
import DInputSliderRange from '@components/atoms/DInputSliderRange.vue';
import DInvestItem from '../molecules/DInvestItem.vue';

export default {
  name: 'DInvest',
  components: {DInvestItem, DInputSliderRange},
  directives: {elementVisibility: vElementVisibility},
  props: {
    jsonApi: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.screenTablet = window.matchMedia('(max-width: 1279px)').matches;
    this.screenPhone = window.matchMedia('(max-width: 767px)').matches;
    this.getData();
    window.tabsWidthStart();
  },
  computed: {
    ...mapState(useInvestStore, [
      'result',
      'price_min',
      'price_max',
      'has_commercial',
      'per_page',
      'showMore',
    ]),
    ...mapWritableState(useInvestStore, [
      'loading',
      'page',
      'type',
      'price_range',
      'showItems',
    ]),
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      }
      else {
        if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
          return true;
        }
        else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
  },
  setup() {
    const investtore = useInvestStore();
    return {investtore};
  },
  data() {
    return {
      loaded: false,
      errored: false,
      filterOutOfView: false,
      range: [12.42, 55.35],
      screenTablet: false,
      screenPhone: false,
    };
  },
  methods: {
    async getData() {
      try {
        const response = await this.axios.get(this.jsonApi);
        this.range = this.price_range = [Number((response.data.price_min/1000000).toFixed(2)), Number((response.data.price_max/1000000).toFixed(2))];
        await this.investtore.$patch(response.data);
        this.$nextTick(function() {
          this.loading = false;
        });
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loaded = true;
      }
    },
    setType(type) {
      this.type = type;
      this.$nextTick(function() {
        window.tabsWidthStart();
      });
    },
    setPrice: _debounce(function(price) {
      if (_isEqual(price, this.price_range)) return;
      this.loading = true;
      this.filter(price);
    }, 50),
    filter: _debounce(function(price) {
      this.price_range = price;
      this.page = 0;
      this.$nextTick(function() {
        this.loading = false;
      });
    }, 500),
    addItems() {
      this.page += 1;
    },
    onElementVisibility(state) {
      const rect = this.$refs.calc.getBoundingClientRect();
      this.filterOutOfView = !state && rect.top < window.scrollY;
    },
    scrollToFilter() {
      this.$smoothScroll({
        scrollTo: document.querySelector('#calc'),
        updateHistory: false,
        duration: 500,
        offset: -120,
      });
      setTimeout(function() {
        $('header').removeClass('_fixed');
        $('header').addClass('_hide');
      }, 500);
    },
  },
};
</script>

