<template>
  <div>
    <div ref="office" v-if="currentInfoOfficeData" class="d-object-single-easy-to-buy-address" :class="{ _hide: !openMobileInfo }">
      <div class="d-object-single-easy-to-buy-address__swipe" @click="toggleMobileInfo"></div>
      <button class="d-object-single-easy-to-buy-address__back" @click="closeInfoOffice">
        <svg>
          <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-arrow'></use>
        </svg>
        Назад
      </button>
      <div v-if="title" class="d-object-single-easy-to-buy-address__title">
        <div class="d-object-single-easy-to-buy-address__num">{{ currentInfoOfficeData.num }}</div>
        {{ currentInfoOfficeData.name }}
      </div>
      <div class="d-object-single-easy-to-buy-contacts">
        <div class="d-object-single-easy-to-buy-map-info-route">
          <div class="d-object-single-easy-to-buy-map-info-route__note _contacts">Выберите точку на карте или введите адрес для построения маршрута</div>
          <div  class="d-object-single-easy-to-buy-map-info-route__suggest">
            <input ref="suggest" id="suggest" type="text" class="d-object-single-easy-to-buy-map-info-route__suggest-input" placeholder="Введите название улицы">
            <svg>
              <use xlink:href="/assets/blueant/assets/sprite.svg#sprite-location-arrow"></use>
            </svg>
          </div>
        </div>
        <a v-if="callTouchPhone" class="d-object-single-easy-to-buy-contacts__phone" :href="callTouchPhone.href">{{ callTouchPhone.text }}</a>
        <div class="d-object-single-easy-to-buy-contacts__address">
          <template v-if="currentInfoOfficeData.address">
            {{ currentInfoOfficeData.address }}
          </template>
          <template v-if="currentInfoOfficeData.time">
            <br>{{ currentInfoOfficeData.time }}
          </template>
        </div>
        <div class="d-object-single-easy-to-buy-contacts__buttons">
          <button ref="schemeBtn" :data-tooltip-text="currentInfoOfficeData.scheme.tooltip" v-if="currentInfoOfficeData.scheme" class="d-object-single-easy-to-buy-contacts__button" @click="showScheme = true">
            <svg>
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-icon-route'></use>
            </svg>
          </button>
          <a ref="taxiBtn" :data-tooltip-text="currentInfoOfficeData.taxi.tooltip" v-if="currentInfoOfficeData.taxi" :href="getTaxiUrl()" target="_blank" class="d-object-single-easy-to-buy-contacts__button">
            <img src="/assets/blueant/assets/img/d/icon_ya_taxi.svg">
          </a>
          <button ref="qrBtn" :data-tooltip-text="currentInfoOfficeData.qr.tooltip" v-if="currentInfoOfficeData.qr && !isTouchDevice" class="d-object-single-easy-to-buy-contacts__button" @click="showQrPopup = true">
            <svg>
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-icon-link-pen'></use>
            </svg>
          </button>
          <a v-if="currentInfoOfficeData.ymapsLink && isTouchDevice" target="_blank" :href="getYmapLink()" class="d-object-single-easy-to-buy-contacts__button">
            <svg>
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-icon-link-pen'></use>
            </svg>
          </a>
        </div>
        <a v-if="currentInfoOfficeData.showRoom" :href="currentInfoOfficeData.showRoom" target="_blank" class="d-button _dark _small d-object-single-easy-to-buy-contacts__button _show-room">
          <span>Экскурсия по шоуруму</span>
        </a>
      </div>
    </div>
    <div v-else class="d-object-single-easy-to-buy-map-info" :class="pageClass">
      <div class="d-object-single-easy-to-buy-map-info__title">
        {{ title }}
      </div>
      <div class="d-object-single-easy-to-buy-map-info__content" data-simplebar>
        <div v-for="(office, idx) in offices" :key="office.id" class="d-object-single-easy-to-buy-map-info__address" :class="{ _active: hoverId === office.id }" @mouseover="hoverOffice(office.id)" @mouseleave="unHoverOffice" @click="openInfoOffice(office, idx + 1)">
          <div class="d-object-single-easy-to-buy-map-info__addressNum">{{ idx + 1 }}</div>
          <div class="d-object-single-easy-to-buy-map-info__addressTitle">{{ office.name }}</div>
          <div v-if="office.address" class="d-object-single-easy-to-buy-map-info__addressText"> {{ office.address }}</div>
        </div>
      </div>
    </div>
    <d-popup-simple v-if="currentInfoOfficeData" v-model="showScheme" mod="center" :small-close="false">
      <div class="d-object-contacts-scheme-popup">
        <img class="d-object-contacts-scheme-popup__img" :src="currentInfoOfficeData.scheme.img" alt="">
        <div class="d-object-contacts-scheme-popup__buttons">
          <button class="d-object-contacts-scheme-popup__btn" @click="printScheme(currentInfoOfficeData.scheme.img)">
            <svg>
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-print'></use>
            </svg>
            Распечатать
          </button>
          <button class="d-object-contacts-scheme-popup__btn" @click="downloadImage(currentInfoOfficeData.scheme.img)">
            <svg>
              <use xlink:href='/assets/blueant/assets/sprite.svg#sprite-download'></use>
            </svg>
            Скачать
          </button>
        </div>
      </div>
    </d-popup-simple>
    <d-popup-simple v-model="showQrPopup" mod="center" inside-overlay :small-close="false">
      <d-map-contacts-office-qr-popup v-if="currentInfoOfficeData" :data="currentInfoOfficeData" :route-data="routeData" @success="showQrPopup = false"/>
    </d-popup-simple>
  </div>
</template>

<script>

import DPopupSimple from "./DPopupSimple.vue";
import DMapContactsOfficeQrPopup from "@components/molecules/DMapContactsOfficeQrPopup.vue";
import additionalClassCSS from "@utils/additionalClassCSS";
import tippy from "tippy.js";
import {Swipe} from "@utils/swipe";
export default {
  name: "DMapContactsOffices",
  components: {
    DPopupSimple,
    DMapContactsOfficeQrPopup
  },
  props: {
    hoverId: {
      type: [Number, String],
      default: null
    },
   activeId: {
      type: [Number, String],
      default: null
    },
    openMobileInfo: {
      type: Boolean,
      default: null
    },
    title: {
      type: String,
      default: 'Приехать на консультацию'
    },
    offices: {
      type: Array,
      default: () => []
    },
    singleOffice: {
      type: Object,
      default: () => {}
    },
    page: {
      type: String,
      default: ''
    },
    routeData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      currentInfoOfficeData: null,
      showScheme: false,
      showQrPopup: false,
      view: 'contacts', // or routes
      showSwipeInfo: false
    }
  },
  computed: {
    isTouchDevice() {
      if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        if (navigator.maxTouchPoints > 0) {
          return true;
        }
      } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
          return true;
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
          return true;
        }
      }
      return false;
    },
    pageClass() {
      return additionalClassCSS(this.page);
    },
    callTouchPhone() {
      const footerPhoneEl = document.querySelector('.d-footer__phone');
      const res = {};
      if (footerPhoneEl) {
        res.href = footerPhoneEl.getAttribute('href');
        res.text = footerPhoneEl.innerHTML;
        return res
      }
      return null
    }
  },
  watch: {
    currentInfoOfficeData(value) {
      if (value) {
        setTimeout(() => {
        this.$emit('suggest', true);
        }, 100)
        this.$emit('allowRoutes', true)
      } else {
        this.view = 'contacts'
        this.$emit('suggest',false)
        this.$emit('allowRoutes', false)
        this.$emit('update:routeData', null);
      }

      setTimeout(() => {
        const swiper = new Swipe(this.$refs.office);
        swiper.onUp(() => {
          if (!this.openMobileInfo) {
            this.$emit('update:openMobileInfo', true);
          }
        });
        swiper.onDown(() => {
          if (this.openMobileInfo) {
            this.$emit('update:openMobileInfo', false);
          }
        });
        swiper.run();
      }, 100)
    },
    activeId(value) {
      console.log('value', value)
      if (value) {
        const currentOffice = this.offices.find((office) => office.id === value);
        const numOffice = this.offices.findIndex((office) => office.id === value) + 1;
        this.openInfoOffice(currentOffice, numOffice)
      }
    }
  },
  mounted() {
    if (this.singleOffice) {
      this.currentInfoOfficeData = this.singleOffice
    }
  },
  // updated() {
  //   if (this.singleOffice) {
  //     this.$emit('allowRoutes', this.view === 'routes')
  //   } else {
  //     this.$emit('allowRoutes', this.view === 'routes' && this.activeId !== null)
  //   }
  //
  // },
  methods: {
    hoverOffice(id) {
      this.$emit('update:hoverId', id);
    },
    unHoverOffice() {
      this.$emit('update:hoverId', null);
    },
    openInfoOffice(office, num) {
      this.currentInfoOfficeData = Object.assign({}, office, { num : num})

      this.setActiveOfficeId(office.id)
      setTimeout(() => {
        this.createButtonTooltip(this.$refs.schemeBtn)
        this.createButtonTooltip(this.$refs.taxiBtn)
        this.createButtonTooltip(this.$refs.qrBtn)
      }, 0)

    },
    printScheme(url) {
      const w = window.open(url, '_blank');
      w.focus();
      w.print();
    },
    async downloadImage(imageSrc) {
      const image = await fetch(imageSrc)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image file name here'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    changeView(event, value) {
      const target = event.target;
      this.tabsBgOpts.left = target.offsetLeft
      this.tabsBgOpts.width = target.offsetWidth
      this.view = value
    },
    setItemRef(el) {
      if (el) {
        this.tabsRefs.push(el)
      }
    },
    setActiveOfficeId(id) {
      this.$emit('update:activeId', id);
    },
    unsetActiveOfficeId() {
      this.$emit('update:activeId', null);
    },
    closeInfoOffice() {
      this.currentInfoOfficeData = null
      this.unHoverOffice()
      this.unsetActiveOfficeId()
    },
    toggleMobileInfo() {
      this.openMobileInfo ? this.$emit('update:openMobileInfo', false) : this.$emit('update:openMobileInfo', true);
    },
    createButtonTooltip(el) {
      const text = el.dataset.tooltipText
      tippy(el, {
        // trigger: 'click',
        allowHTML: true,
        interactive: true,
        offset: [0, 8],
        theme: 'office-btn',
        placement: 'top',
        animation: 'fade',
        content: text,
        arrow: false,
        touch: false,
      });
    },
    getTaxiUrl() {
      console.log('this.currentInfoOfficeData.taxi', this.currentInfoOfficeData.taxi)
      console.log('this.routeData', this.routeData)
      if (this.routeData) {
        return this.routeData.from && this.routeData.to ? `https://3.redirect.appmetrica.yandex.com/route?start-lat=${this.routeData.from[0]}&start-lon=${this.routeData.from[1]}&end-lat=${this.routeData.to[0]}&end-lon=${this.routeData.to[1]}&ref=donstroy.moscow&appmetrica_tracking_id=1178268795219780156` : this.currentInfoOfficeData.taxi.url
      } else {
        return this.currentInfoOfficeData.taxi.url
      }
    },
    getYmapLink() {
      if (this.routeData) {
        return this.routeData.from && this.routeData.to ? `https://yandex.ru/maps/?rtext=${this.routeData.from[0]},${this.routeData.from[1]}~${this.routeData.to[0]},${this.routeData.to[1]}&rtt=mt` : this.currentInfoOfficeData.taxi.url
      } else {
        return this.currentInfoOfficeData.ymapsLink
      }
    }

  }

}
</script>

<style scoped>

</style>
