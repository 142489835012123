<template>
  <div ref="rangeSliderContainer" class="d-range-slider-single">
    <div class="d-range-slider-single__title" @click="setInputFocus">{{ title }} <span v-if="tip" class="d-range-slider-single__title-tip">{{ tip }}</span></div>
    <div class="d-range-slider-single__content">
      <input
        ref="inputField"
        class="d-range-slider-single__value"
        type="text"
        inputmode="numeric"
        :value="currentValueFormatted"
        @blur="inputBlur"
        @focus="inputFocus"
        @keypress="checkInput"
        @keypress.enter="inputSubmit"
      />
      <div class="d-range-slider-single__label">{{ unit }}</div>
      <Vue-slider ref="slider" v-model="currentValueNumber" v-bind="sliderOpts" @change="changeSlider" class="d-range-slider-single__slider"></Vue-slider>
    </div>
  </div>
</template>



<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';
import declOfNum from '../../utils/declOfNum';

export default {
  name: 'DInputSliderSingle',
  emits: ['update:modelValue', 'update:model-value'],
  components: {
    VueSlider,
  },
  props: {
    mod: {
      type: [Array, String],
      default: '',
    },
    range: {
      type: Array,
      default: () => [10000000, 100000000],
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    type: {
      type: String,
      default: 'price', // month, percent
    },
    formatType: {
      type: String,
      default: 'money', // time
    },
    value: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    percent: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: 'Цена',
    },
    tip: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      currentValueNumber: null,
      currentValueFormatted: '',
    };
  },
  computed: {
    sliderOpts() {
      return {
        min: this.range[0],
        max: this.range[1],
        interval: this.step,
        tooltip: 'none',
      };
    },
    unit() {
      if (this.formatType === 'money') {
        return '₽';
      }
      if (this.formatType === 'year') {
        return declOfNum(this.currentValueNumber, ['год', 'года', 'лет']);
      }
      if (this.formatType === 'percent') {
        return '%';
      }
      if (this.formatType === 'month') {
        return declOfNum(this.currentValueNumber, ['месяц', 'месяца', 'месяцев']);
      }
      return '';
    },
    typeIsPrice() {
      return this.type === 'price';
    },
    typeIsNumber() {
      return this.type === 'number';
    },
  },
  watch: {
    value(value) {
      this.currentValueNumber = value;
    },
    currentValueNumber(newVal) {
      this.currentValueFormatted = this.formatMoney(newVal);
      this.changeSlider();
    },
  },
  created() {
    this.currentValueNumber = this.value;
    this.currentValueFormatted = this.formatMoney(this.currentValueNumber);
  },
  mounted() {
    this.changeSlider();
  },
  methods: {
    changeSlider() {
      this.$emit('update:modelValue', this.currentValueNumber);
    },
    checkInput(e) {
      const hasDot = e.target.value.match(/\./);
      if ((this.typeIsNumber || this.typeIsPrice) && this.step < 1 && e.key.match(/[\d.,]/) && !hasDot) {
        return true;
      }
      if ((this.typeIsNumber || this.typeIsPrice) && e.key.match(/\d/)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    setInputFocus() {
      this.$refs.inputField.focus();
    },
    inputFocus(e) {
      const inputValue = this.$refs.inputField.value;
      this.currentValueFormatted = inputValue.replace(/\D/g, '');
    },
    inputBlur(e) {
      const inputValue = e.currentTarget.value;
      const rawValue = Number(inputValue.replace(/\D/g, ''));
      this.currentValueNumber = Math.round(this.checkValue(rawValue) / this.step) * this.step;
      this.currentValueFormatted = this.formatMoney(this.currentValueNumber);
    },
    inputSubmit(e) {
      const input = e.currentTarget;
      input.blur();
    },
    checkValue(value) {
      if (value > this.range[1]) return this.range[1];
      if (value < this.range[0]) return this.range[0];
      return value;
    },
    formatMoney(value) {
      if (!value) return value;
      let newValue = null;
      if (typeof value === 'number') {
        newValue = Number(value.toString().replace(/\D/g, ''));
      } else {
        newValue = Number(value.replace(/[^\d.-]/g, ''));
      }

      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true,
        maximumSignificantDigits: 11,
      })
        .format(newValue)
        .replace(/,/g, ' ');
    },
  },
};
</script>
