<template>
  <div class="d-aux-rooms__count skeleton-text"></div>
  <div class="d-aux-rooms__sorter skeleton-field"></div>
  <div class="d-aux-rooms__switchView  skeleton-field">
      <div class="skeleton-field"></div>
      <div class="skeleton-field"></div>
  </div>
</template>

<script>
  export default {
    name: 'DSearchControlSkeleton',
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style lang="sass" scoped>
</style>
