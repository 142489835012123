export function circlePath(points) {
    const {cx, cy, r} = points;
    return 'M ' + cx + ' ' + cy + ' m -' + r + ', 0 a ' + r + ',' + r + ' 0 1,0 ' + (r * 2) + ',0 a ' + r +
        ',' + r + ' 0 1,0 -' + (r * 2) + ',0';
}

export function rectPath(points) {
    return 'M' + points[0] + ' ' + points[1] + 'L' + points[2] + ' ' + points[1] + 'L' + points[2] + ' ' +
        points[3] + 'L' + points[0] + ' ' + points[3] + ' z';
}

export function polyPath(points) {
    var newpoints = 'M' + points[0] + ' ' + points[1];
    for (var i = 0; i < points.length; i += 2) {
        newpoints += 'L' + points[i] + ' ' + points[i + 1];
    }
    newpoints += ' z';
    return newpoints;
}

function coords2path(points, shape) {
    switch(shape) {
        case 'circle': return circlePath(points);
        case 'rect': return rectPath(points);
        default: return polyPath(points);
    }
}


export default coords2path;
