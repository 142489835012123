<template>
  <div class="d-commercial-object-photo" :class="classList">
    <swiper
        class="d-commercial-object-photo__slider"
        ref="slider"
        v-bind="swiperOpts"
        :pagination="{
                  el: pagination,
                  type: 'custom',
                  renderCustom: function (swiper, current, total) {
                  let currentSlide =  current.toString();
                  let totalSlide = total.toString();
                  return `<span>${currentSlide}&nbsp;-&nbsp;${totalSlide}</span>`;
                  }
                }"
        :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
    >
      <swiper-slide v-for="img in images" :key="img.preview" class="d-commercial-object-photo__slide swiper-slide">
        <img class="swiper-lazy d-commercial-object-photo__img" :src="img.preview" alt="">
        <div class="swiper-lazy-preloader"></div>
      </swiper-slide>
    </swiper>
    <div ref="pagination" class="d-commercial-object-photo__pagination d-slider-pagination"></div>
    <div class="d-commercial-object-photo__arrows">
      <div class="d-arrows-slider">
        <button ref="prev" class="d-arrows-slider__arrow d-button _slider _prev">
          <span></span>
          <svg  width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
          </svg>
        </button>

        <button ref="next" class="d-arrows-slider__arrow d-button _slider _next">
          <span></span>
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9675 10.0001L6.66406 15.3035L8.17902 16.8185L14.9974 10.0001L8.17902 3.18173L6.66406 4.69668L11.9675 10.0001Z"></path>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="disclaimer" class="d-commercial-object-photo__disclaimer">
      <d-disclaimer :text="disclaimer"/>
    </div>

  </div>
</template>

<script>
import {ref} from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Lazy } from 'swiper';
import DDisclaimer from "@components/atoms/DDisclaimer.vue";
import additionalClassCSS from '@utils/additionalClassCSS';
export default {
  name: "DCommercialObjectSlider",
  components: {
    DDisclaimer,
    Swiper,
    SwiperSlide
  },
  props: {
    images: {
      type: Array,
      default: []
    },
    disclaimer: {
      type: String,
      default: ''
    },
    mod: {
      type: [Array, String],
      default: '',
    },
  },
  data() {
    return {
      swiperOpts: {
        loop: true,
        preloadImages: false,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
    }
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const pagination = ref(null)
    return {
      modules: [Navigation, Pagination, Lazy],
      prev,
      next,
      pagination
    };
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
  }
}
</script>

<style scoped>

</style>