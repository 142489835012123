<template>
  <form class="d-callMe__content" @submit.prevent="submit">
    <div class="d-callMe__item" :class="{_full : formType === 'callMe'}">
      <div class="d-input" :class="{ _error: v$.form.name.$errors.length }">
        <input type="text" name="name" placeholder="Ваше имя*" class="d-input__field" v-model="v$.form.name.$model" :disabled="loading" @blur="v$.form.name.$touch">
        <div class="d-input__error" v-for="(error, index) of v$.form.name.$errors" :key="index">{{ error.$message }}</div>
      </div>
    </div>
    <div v-if="formType === 'thanks'" class="d-callMe__item">
      <d-select mod="contacts"
                v-model="v$.form.managers.$model"
                :data-options="managers"
                data-placeholder="Выберите менеджера*"
                :class="{ _error: v$.form.managers.$errors.length }"
                @blur="v$.form.managers.$touch"/>
      <div class="d-input__error" v-for="(error, index) of v$.form.managers.$errors" :key="index">{{ error.$message }}</div>
    </div>

    <div class="d-callMe__item ">
      <div class="d-input" :class="{ _error: v$.form.email.$errors.length }">
        <input ref="email" type="email" name="email" placeholder="Ваш e-mail" class="d-input__field" v-model="v$.form.email.$model" @blur="v$.form.email.$touch" :disabled="loading" inputmode="email">
        <div class="d-input__error" v-for="(error, index) of v$.form.email.$errors" :key="index">Введите корректный email</div>
      </div>
    </div>

    <div class="d-callMe__item _item2">
      <div class="d-input" :class="{ _error: v$.form.phone.$errors.length }">
        <div class="d-input__error" v-for="(error, index) of v$.form.phone.$errors" :key="index">{{ error.$message }}</div>
        <d-phone-input @change-phone="setPhone" @change-mask="updatePhoneParams" :reset="reset" @blur="blurInpPhone"/>
      </div>
    </div>

    <div class="d-callMe__item _item4 _full">
      <div class="d-input _textarea">
        <textarea name="text" class="d-input__field" placeholder="Ваше сообщение" v-model="v$.form.text.$model" :disabled="loading" @blur="v$.form.text.$touch"></textarea>
      </div>
    </div>

    <div v-if="loading" class="d-callMe__item _item5">
      <div class="d-callMe__button skeleton-button d-button _blue">
        <span class="skeleton-button__loader"></span>
      </div>
    </div>
    <div v-else class="d-callMe__item _item5">
      <button class="d-callMe__button d-button _blue" :class="{_disable: v$.form.$invalid}" @click.prevent="submit"><span>Отправить</span></button>
    </div>
    <div class="d-callMe__item _item6">
      <div class="d-callMe__agree d-checkbox _agree">
        <input type="checkbox" name="agree" id="agree_callMe" class="d-checkbox__input" @change="v$.form.agree.$touch()" v-model="v$.form.agree.$model">
        <label for="agree_callMe" class="d-checkbox__label _inline">Согласен с </label><a :href="dataPoliticsLink" target="_blank">политикой обработки персональных данных</a>
      </div>
      <d-recaptcha-info :errored="errored" :error-text="errorText"/>
    </div>
  </form>
</template>

<script>
import DSelect from '@components/molecules/DSelect.vue';
import useVuelidate from '@vuelidate/core';
import {required, sameAs, helpers, email} from '@vuelidate/validators';
import phoneHelper from '@utils/phoneHelper';
import {toRaw} from 'vue';
//import declOfNum from '@utils/declOfNum';
//import {omit as _omit} from 'lodash';
import DPhoneInput from '@components/molecules/DPhoneInput.vue';
import DRecaptchaInfo from '@components/atoms/DRecaptchaInfo.vue';

export default {
  name: 'DCallMeForm',
  components: {
    DSelect,
    DPhoneInput,
    DRecaptchaInfo,
  },
  props: {
    dataActionUrl: {
      type: String,
      required: true,
      default: '',
    },
    dataPoliticsLink: {
      type: String,
      default: '',
    },
    dataMod: {
      type: String,
      default: 'footer', // popup
    },
    dataAgreeLink: {
      type: String,
      default: '#',
    },
    dataProps: {
      type: [Object, Boolean, String],
      default: false,
    },
    formType: {
      type: String,
      default: 'callback',
    },
    managers: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        text: '',
        managers: null,
        agree: false,
      },
      phoneParams: {
        mask: '',
        code: '',
      },
      reset: false,
      errored: false,
      errorText: 'Ошибка при инициализации reCAPTCHA, попробуйте перезагрузить страницу',
      loading: false,
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {
    changeDay(newValue) {
      const selectedDay = this.dates.findIndex(el => el.value === newValue);
      if (selectedDay > -1) this.times = this.dates[selectedDay].hours;
    },
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        //this.sendData({ ...this.form, ..._omit(this.data, ['fields', 'action', 'button_text', 'open_form_button_text', 'time']), ...this.additData });
        let dataProps = toRaw(this.dataProps);
        if (this.dataProps && (typeof this.dataProps).toLowerCase() === 'string') {
          try {
            dataProps = JSON.parse(this.dataProps);
          } catch (e) {}
        }
        if (dataProps && (typeof dataProps).toLowerCase() === 'object' && Object.keys(dataProps).length > 0)
          this.sendData(Object.assign({}, {formType: this.formType}, {...this.form, ...dataProps}));
        else this.sendData({...this.form, formType: this.formType});
      }
    },
    async sendData(formdata) {
      formdata.URL = formdata.url = window.location.href;
      this.loading = true;
      try {
        await this.$recaptchaLoaded();
        formdata.token = await this.$recaptcha(this.dataType);
        const response = await this.axios.post(this.dataActionUrl, formdata);
        if (response.data.error) {
          this.errored = true;
          this.errorText = response.data.error;
        }
        else if (response.data.success) {
          this.resetForm();
          this.$emit('success', response.data.success);
        }
        else {
          console.error(response.data);
          this.errored = true;
        }
      } catch (error) {
        console.error(error);
        this.errored = true;
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.v$.form.$reset();
      this.form.name = '';
      this.form.phone = '+7';
      this.form.email = '';
      this.form.text = '';
      this.form.managers = null;
      this.form.agree = false;
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.reset = true;
      setTimeout(() => {this.reset = false;}, 1000);
    },
    updatePhoneParams(data) {
      this.phoneParams.mask = data.mask;
      this.phoneParams.code = data.code;
    },
    setPhone(phone) {
      this.form.phone = phone;
    },
    blurInpPhone() {
      this.v$.form.phone.$touch();
      console.log('touch phone');
    },
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Заполните это поле', required),
        },
        email: {email},
        text: {},
        managers: this.formType === 'thanks' ? {
          required: helpers.withMessage('Выберите менеджера', required),
        } : {},
        phone: {
          required: helpers.withMessage('Заполните это поле', required),
          phoneHelper: helpers.withMessage('Введите номер полностью', phoneHelper(this.phoneParams)),
        },
        agree: {
          required,
          sameAs: sameAs(true),
        },
      },
    };
  },
};
</script>
