<template>
  <div class="d-company-awards__app">
    <template v-if="awardsData">
      <div class="d-company-awards__year">
        <d-select :data-options="years" v-model="currentYear" data-placeholder="Год"></d-select>
      </div>
      <div class="d-company-awards__list" data-awards-opts="{&quot;perPageNum&quot;: 3}">

        <div v-for="award in awardsPerPageList" :key="award.id" class="d-company-award" @click="openPopup(award)">
          <div class="d-company-award__logo">
            <img :src="award.logo" />
          </div>
          <div class="d-company-award__content" :class="{ _full: !award.photo.length }">
            <div class="d-company-award__info" :class="{ _notext: !award.info }">
              <div class="d-company-award__name">{{ award.name }}</div>
              <div class="d-company-award__objects">
                <div v-for="label in award.objects" :key="label" class="d-flat-action _blue2">
                  <div class="d-flat-action__name">{{ label }}</div>
                </div>
              </div>
            </div>
            <div class="d-company-award__note" :class="{ _nophoto: !award.photo.length, _notext: !award.info, _photo:award.photo.length  }">
              <div v-if="award.info" v-html="award.info"></div>
              <div v-if="award.photo.length" class="d-company-award__photo">
                <img v-for="photo in award.photo" :key="photo" :src="award.photo[0]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="remainAwards > 0" class="d-company-awards__more">
        <button class="d-button _dark" @click="moreAwards">
          <span>Показать еще</span>
        </button>
      </div>
      <d-popup-simple v-if="openedAward" v-model="showPopup" @closed="openedAward = null" :with-close="false">
        <d-awards-popup :photos="openedAward.photo" @close="showPopup = false"/>
      </d-popup-simple>
    </template>
    <template v-else>
      <div class="d-company-awards__list">
        <div v-for="item in [1,2,3.4]" :key="item" class="d-company-award _skeleton">
          <div class="d-company-award__logo">
            <div class="skeleton-field" />
          </div>
          <div class="d-company-award__content">
            <div class="d-company-award__info">
              <div class="d-company-award__name skeleton-field" />
              <div class="d-company-award__objects skeleton-field" />
            </div>
            <div class="d-company-award__note">
              <div>
                <div v-for="n in [1,2,3]" :key="n" class="skeleton-field _note" />
              </div>
              <div class="d-company-award__photo">
                <div class="skeleton-field" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import DSelect from "@components/molecules/DSelect.vue";
import { uniqBy as _uniqBy, sortBy as _sortBy } from "lodash";
import DPopupSimple from "@components/molecules/DPopupSimple.vue";
import DAwardsPopup from "@components/molecules/DAwardsPopup.vue";

export default {
  name: "DCompanyAwards",
  components: {
    DSelect,
    DPopupSimple,
    DAwardsPopup
  },
  props: {
    apiUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      awardsData: null,
      awards: [
        {
          "id": 1,
          "logo":"assets/img/d/awards/logo_1.jpg",
          "name":"Премия MOVE Realty Awards",
          "objects":[
            "Оливковый дом"
          ],
          "info":"Жилой комплекс «Событие» выиграл ежегодную профессиональную премию в сфере недвижимости Move Realty Awards 2022 в номинации «Проект года бизнес-класса Москвы».",
          "photo":[
            "assets/img/d/awards/photo_1.jpg"
          ]
        },
        {
          "id": 2,
          "logo":"assets/img/d/awards/logo_2.jpg",
          "name":"Green Property Awards",
          "objects":[
            "Остров"
          ],
          "info":"Клубный проект класса luxe «Оливковый дом» девелопера Донстрой стал победителем сразу в двух номинациях премии Green Property Awards: «Лучший проект в номинации «Жилая недвижимость премиум-класса» и GREEN ZOOM проект года.",
          "photo":[
            "assets/img/d/awards/photo_2.jpg"
          ]
        },
        {
          "id": 3,
          "logo":"assets/img/d/awards/logo_3.jpg",
          "name":"Urban Awards",
          "objects":[
            "Сердце Столицы"
          ],
          "info":"«Донстрой» получил награду «За реализацию стратегии экологичного девелопмента»",
          "photo":[
            "assets/img/d/awards/photo_1.jpg"
          ]
        },
        {
          "id": 4,
          "logo":"assets/img/d/awards/logo_4.jpg",
          "name":"BIM технологии 2021/2022",
          "objects":[
            "Событие"
          ],
          "info":"BIM-модель второй очереди жилого квартала «События» стала победителем конкурса «BIM технологии 2021/2022», который проходит по инициативе НОТИМ и Комиссии по цифровизации строительной отрасли Общественного совета при Минстрое России. Проект компании Донстрой завоевал «серебро» в номинации «Информационное моделирование жилых зданий».",
          "photo":[
            "assets/img/d/awards/photo_2.jpg"
          ]
        },
        {
          "id": 5,
          "logo":"assets/img/d/awards/logo_5.jpg",
          "name":"Премия Правительства Москвы",
          "objects":[
            "Дом на Мосфильмовской"
          ],
          "info":"Шелепихинская набережная, граничащая с кварталом «Сердце Столицы», стала победителем Премии Правительства Москвы 2020 года как лучший проект комплексного благоустройства природных и озелененных территорий города Москвы",
          "photo":[
            "assets/img/d/awards/photo_1.jpg"
          ]
        },
        {
          "id": 6,
          "logo":"assets/img/d/awards/logo_6.jpg",
          "name":"MUF Community Awards 2020",
          "objects":[
            "Зеленая река",
            "Река"
          ],
          "info":"Парк «Зеленая река» признан лучшим по версии MUF Community Awards 2020 в номинации «Городской дизайн»",
          "photo":[
            "assets/img/d/awards/photo_2.jpg",
            "assets/img/d/awards/photo_1.jpg"
          ]
        }
      ],
      currentYear: null,
      years: null,
      awardsPerPage: null,
      awardsFrom: 0,
      awardsTo: null,
      showPopup: false,
      openedAward: null
    }
  },
  computed: {
    awardsList() {
      if (this.awardsData && this.awardsData.awards) return this.awardsData.awards
      return null
    },
    filteredAwards() {
      if (!this.awardsList) {
        return null
      }

      if (this.currentYear === 'all') return this.awardsList;
      return this.awardsList.filter((award) => award.year === this.currentYear)
    },
    awardsPerPageList() {
      return this.filteredAwards.slice(this.awardsFrom, this.awardsTo)
    },
    remainAwards() {
      if (!this.filteredAwards) return null
      return this.filteredAwards.length - this.awardsTo
    },

  },
  created() {
    if (this.apiUrl) {
      this.getData()
    }
  },
  methods: {
    async getData() {
      try {
        let response = await this.axios.get(this.apiUrl);
        console.log('response company awards', response.data)
        this.awardsData = response.data;
        this.setYearsList();
        this.awardsPerPage = this.awardsData.perPage || 6;
        this.awardsTo = this.awardsPerPage;
      } catch(err) {
        console.log('error load company awards data', err);
      }
    },
    setYearsList() {
      const uniqYears = _sortBy(_uniqBy(this.awardsData.awards, 'year'), 'year').map((award) => award.year).reverse();
      const yearsSelectData = [];
      yearsSelectData.push(
          {
            id: 99999999999,
            name: 'Все года',
            value: 'all',
          }
      )
      uniqYears.forEach((year, idx) => {
        console.log('year', year)
        yearsSelectData.push({
          id: idx,
          value: year,
          name: year,
        })
      })

      this.years = yearsSelectData;
      this.currentYear = this.years[0].value // ставим по умолчанию включенным all, он всегда первый в списке
    },
    moreAwards() {
      this.awardsTo += this.awardsPerPage
    },
    openPopup(award) {
      if (award.photo.length) {
        this.openedAward = award;
        this.showPopup = true
      }
    }
  }
}
</script>

<style scoped>

</style>