<template>
  <div class="d-index-filter-item" data-filter :class="Object.assign({}, { _active: isActive, _open: alwaysOpen || isOpen}, classList)" v-click-outside="close">
    <div v-if="dataTitle" class="d-index-filter-item__head" @click="openToggle">{{ dataTitle }}</div>
    <div v-show="alwaysOpen || isOpen" class="d-index-filter-item__drop">
      <slot>
      </slot>
    </div>
    <div v-if="tags.length && !isOpen && !alwaysOpen" class="d-index-filter-item__choose d-index-filter-choose _popup _active">
      <div class="d-index-filter-choose__list">
        <div v-for="(tag, index) in tags" :key="`${tag.filter}-${tag.category}-${index}`" class="d-index-filter-choose-item">
          <div class="d-index-filter-choose-item__name">{{ tag.tag_title }}</div>
          <button class="d-index-filter-choose-item__del" @click="resetFilter(tag)">
            <svg><use xlink:href="/assets/blueant/assets/sprite.svg#sprite-close"></use></svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import DInputSliderRange from '@components/atoms/DInputSliderRange.vue';
import additionalClassCSS from "@utils/additionalClassCSS";

export default {
  name: 'DFilterItem',
  emits: ['filter:open', 'update:alwaysOpen', 'resetFilter'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { DInputSliderRange },
  props: {
    dataTitle: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: true,
    },
    screenXs: {
      type: Boolean,
      default: false,
    },
    mod: {
      type: [Array, String],
      default: '',
    },
    setClosed: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    modClass() {
      return additionalClassCSS(this.mod);
    },
    classList() {
      return Object.assign({}, this.modClass);
    },
  },
  watch: {
    isOpen(newVal) {
      this.$emit('update:alwaysOpen', newVal);
    },
    alwaysOpen(value) {
      this.isOpen = this.alwaysOpen;
    },
    setClosed(value) {
      if(value) this.isOpen = false;
    },
  },
  mounted() {
    this.isOpen = this.alwaysOpen;
  },
  updated() {
    // this.$nextTick( () => window.tabsWidthStart());
    setTimeout(() => {
      window.tabsWidthStart()
    }, 100)
  },
  data() {
    return {
      isOpen: false,
      filterParams: [25, 250],
    };
  },
  methods: {
    openToggle() {
      this.isOpen = !this.isOpen;
      this.$emit('filter:open', this.isOpen)
    },
    close() {
      if( (!this.screenXs && !this.alwaysOpen) || (this.mod.includes('choose') && !this.screenXs) ) this.isOpen = false;
    },
    resetFilter(tag) {
      this.$emit('resetFilter', tag);
    },
  },
};
</script>

<style scoped>

</style>
